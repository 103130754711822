import * as React from 'react'
import { Billboard, Line, Text, Plane, Shape as DreiShape } from "@react-three/drei";
import { FC, useMemo } from "react";
import { Color, DoubleSide, ExtrudeGeometry, Shape, Vector2, Vector3 } from "three";
import Clipper from '@doodle3d/clipper-js';

export type FloorProps = {
  polylines: Vector3[][];
  height: number;
  floorHeight: number;
  balcony?: Vector2[][];
  floorIndex: number;
  maxFloor: number;
  border:Vector3[]
};

const Floor: FC<FloorProps> = ({
  polylines,
  height,
  floorHeight,
  balcony,
  floorIndex,
  maxFloor,
  border
}) => {
  const position = useMemo(() => {
    return new Vector3(0, 0, height);
  }, [height]);

  const balconyPosition = useMemo(() => {
    return position.clone().add(new Vector3(0, 0, 1e1));
  }, [position]);

  const pivot = useMemo(() => {
    const pivot: Vector3[] = []
    const points = border.flatMap((line) => line);
    const x = points.reduce((pre, cur) => {
      return Math.min(pre, cur.x);
    }, Number.POSITIVE_INFINITY);
    const y = points.reduce((pre, cur) => {
      return Math.min(pre, cur.y);
    }, Number.POSITIVE_INFINITY);
    if (height != 0) {
      pivot.push(new Vector3(x, y, height));
    }
    if (floorIndex == maxFloor) {
      pivot.push(new Vector3(x, y, height + floorHeight))
    }
    return pivot;
  }, [polylines, height, floorHeight]);

  const formatHeight = useMemo(() => {
    const formatHeight: string[] = [];
    const precision = 1e2;
    formatHeight.push(`${(Math.floor((height) * 1e-3 * precision) / precision).toFixed(2)}m　${floorIndex}SL`);
    if (floorIndex == maxFloor) {
      formatHeight.push(`${(Math.floor((height + floorHeight) * 1e-3 * precision) / precision).toFixed(2)}m`);
    }
    return formatHeight;
  }, [height, floorHeight]);

  const balconies = useMemo(() => {
    if (balcony === undefined) return [];
    const src = new Clipper(polylines.map((poly) => {
      return poly.map((p) => {
        return {
          X: p.x,
          Y: p.y
        };
      });
    }));
    const dst = new Clipper(balcony.map((poly) => {
      return poly.map((p) => {
        return {
          X: p.x,
          Y: p.y
        };
      });
    }));
    const intersection = src.intersect(dst);
    const { paths } = intersection;
    return paths.map((path) => {
      return new Shape(path.map((p) => {
        return new Vector2(p.X, p.Y);
      }));
    });
  }, [balcony, polylines]);

  return <group>
    {
      pivot.map((position, index) => {
        return <Billboard key={`height-${index}`} position={position} name="delFromMap-FoorHeight">
          <Text color='black' anchorX={'left'} anchorY={'bottom'} scale={1e2 * 2 * 2}>
            { formatHeight[index] }
          </Text>
          <Plane args={[2550, 20]}>
            <meshStandardMaterial color="black" />
          </Plane>
        </Billboard>
      })
    }

    {
      balconies.map((shape, i) => {
        return <DreiShape key={`balcony-${i}`} args={[shape]} position={balconyPosition}>
          <meshBasicMaterial color={new Color(186 / 255, 213 / 255, 58 / 255)} transparent opacity={0.65} side={DoubleSide} depthTest={false} />
        </DreiShape>;
      })
    }
    {
      polylines.map((polyline, i) => {
        const upper = polyline.map((p) => {
          return new Vector3(p.x, p.y, p.z + floorHeight);
        });
        const shape = new Shape(polyline.map((p) => new Vector2(p.x, p.y)));
        const extruded = new ExtrudeGeometry(shape, {
          depth: floorHeight,
        });
        return <group key={`line-${i}`}>
          <Line points={polyline} color={'cornflowerblue'} />
          <Line points={upper} color={'cornflowerblue'} />
          <Line points={polyline.flatMap((p, i) => {
            return [
              p,
              upper[i]
            ];
          })} segments color={'cornflowerblue'} />
          <mesh geometry={extruded} position={position}>
            <meshBasicMaterial color={'cornflowerblue'} transparent opacity={0.25} />
          </mesh>
        </group>;
      })
    }
  </group>;
};

export {
  Floor
}
