import * as React from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { Button, AnchorButton } from '@/components/Button'
import ClearIcon from '@material-ui/icons/Clear'
import RefreshIcon from '@material-ui/icons/Refresh'
import { Input } from '@/components/Input'
import { Row, Th, Td } from '@/components/Table'
import BorderColorOutlinedIcon from '@mui/icons-material/BorderColorOutlined'
import LockOpenIcon from '@material-ui/icons/LockOpen'
import LockIcon from '@material-ui/icons/Lock'

type Props = {
  title: string
  property: any
  idx: number
  nextIdx: number
  latlngA: number[]
  latlngB: number[]
  authenticity_token: string
  changeParameters?: () => void
}

export const VolumeCheckRoadModal: React.FC<Props> = ({
  title,
  property,
  idx,
  nextIdx,
  latlngA,
  latlngB,
  authenticity_token,
  changeParameters,
}) => {
  const fromPoint = `点${idx + 1}`
  const toPoint = `点${nextIdx + 1}`
  const [isOpen, setIsOpen] = React.useState(false)
  const [isLockedA, setIsLockedA] = React.useState(false)
  const [isLockedB, setIsLockedB] = React.useState(false)
  const [clickDisabled, setClickDisabled] = React.useState(false)
  const [newLength, setNewLength] = React.useState('')
  const [paramsValue, setParamsValue] = React.useState('')
  const formRef = React.useRef(null)
  let url = `/properties/${property.hashid}/volume_check_requests/update_request_parameters`
  const ref = new URLSearchParams(window.location.search).get('ref')
  if (ref) {
    url += `?ref=${ref}`
  }

  function closeModal() {
    setIsOpen(false)
  }

  function openModal() {
    setIsOpen(true)
  }

  React.useEffect(() => {
    if (isOpen) {
      const element = document.getElementById('volume_check_request_parameters')
      if (element) {
        changeParameters && changeParameters()
        const params = (element as HTMLInputElement).value
        setParamsValue(params)
      }
    }
  }, [isOpen])

  const [currentLength, setCurrentLength] = React.useState('')

  React.useEffect(() => {
    const element = document.getElementById(`length_${idx}`)
    if (element) {
      const getLength = (element as HTMLInputElement).value
      setCurrentLength(getLength)
    }
  }, [isOpen])

  React.useEffect(() => {
    if ((isLockedA && isLockedB) || newLength === '') {
      setClickDisabled(true)
    } else {
      setClickDisabled(false)
    }
  }, [isLockedA, isLockedB, newLength])

  const handleSubmit = () => {
    const lat1 = latlngA[0]
    const lng1 = latlngA[1]
    const lat2 = latlngB[0]
    const lng2 = latlngB[1]
    const currentLengthFloat = parseFloat(currentLength)

    const dx = lng2 - lng1
    const dy = lat2 - lat1
    const dx_unit = dx / currentLengthFloat
    const dy_unit = dy / currentLengthFloat

    const newLengthFloat = parseFloat(newLength)
    const dx_new = dx_unit * newLengthFloat
    const dy_new = dy_unit * newLengthFloat

    let lat1_new = lat1
    let lng1_new = lng1
    let lat2_new = lat2
    let lng2_new = lng2

    if (isLockedA) {
      lng2_new = lng1 + dx_new
      lat2_new = lat1 + dy_new
    } else if (isLockedB) {
      lng1_new = lng2 - dx_new
      lat1_new = lat2 - dy_new
    } else {
      const delta = (newLengthFloat - currentLengthFloat) / 2
      const dx_delta = dx_unit * delta
      const dy_delta = dy_unit * delta
      lng1_new = lng1 - dx_delta
      lat1_new = lat1 - dy_delta
      lng2_new = lng2 + dx_delta
      lat2_new = lat2 + dy_delta
    }

    const paramsValueObj = JSON.parse(paramsValue)

    paramsValueObj.borders[idx].from.lat = lat1_new
    paramsValueObj.borders[idx].from.lng = lng1_new
    paramsValueObj.borders[idx].to.lat = lat2_new
    paramsValueObj.borders[idx].to.lng = lng2_new

    if (idx === 0) {
      paramsValueObj.borders[paramsValueObj.borders.length - 1].to.lat = lat1_new
      paramsValueObj.borders[paramsValueObj.borders.length - 1].to.lng = lng1_new
      paramsValueObj.borders[nextIdx].from.lat = lat2_new
      paramsValueObj.borders[nextIdx].from.lng = lng2_new
    } else if (idx === paramsValueObj.borders.length - 1) {
      paramsValueObj.borders[idx - 1].to.lat = lat1_new
      paramsValueObj.borders[idx - 1].to.lng = lng1_new
      paramsValueObj.borders[0].from.lat = lat2_new
      paramsValueObj.borders[0].from.lng = lng2_new
    } else {
      paramsValueObj.borders[idx - 1].to.lat = lat1_new
      paramsValueObj.borders[idx - 1].to.lng = lng1_new
      paramsValueObj.borders[nextIdx].from.lat = lat2_new
      paramsValueObj.borders[nextIdx].from.lng = lng2_new
    }

    const updatedParamsValue = JSON.stringify(paramsValueObj)

    const adjustedParametersInput = document.getElementById('parameters')
    if (adjustedParametersInput) {
      ;(adjustedParametersInput as HTMLInputElement).value = updatedParamsValue
    }

    closeModal()
  }

  return (
    <>
      <div className="">
        <button type="button" onClick={openModal}>
          <AnchorButton
            className="self-center text-primary-font"
            prefix={<BorderColorOutlinedIcon fontSize="inherit" />}
            outline
            size="tiny"
            variant="primary"
          >
            <span className="hidden md:inline">道路端部設定</span>
          </AnchorButton>
        </button>
      </div>

      <Transition appear show={isOpen} as={React.Fragment}>
        <Dialog className="relative z-10" onClose={closeModal}>
          <Transition.Child
            as={React.Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center text-center">
              <Transition.Child
                as={React.Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-screen md:max-w-[625px] transform overflow-hidden rounded-2xl bg-white text-left align-middle shadow-xl transition-all">
                  <Dialog.Title className="text-base font-medium p-4 leading-6 text-gray-700">
                    {title}
                    <ClearIcon className="float-right cursor-pointer" onClick={closeModal} />
                  </Dialog.Title>
                  <div className="border-t border-gray-150"></div>
                  <form
                    ref={formRef}
                    id="length_edit"
                    action={url}
                    acceptCharset="UTF-8"
                    method="post"
                  >
                    <input type="hidden" name="authenticity_token" value={authenticity_token} />
                    <input type="hidden" name="latlngA" value={latlngA.toString()} />
                    <input type="hidden" name="latlngB" value={latlngB.toString()} />
                    <input type="hidden" name="currentLength" value={currentLength} />
                    <input type="hidden" name="isLockedA" value={isLockedA.toString()} />
                    <input type="hidden" name="isLockedB" value={isLockedB.toString()} />
                    <input type="hidden" name="parameters" id="parameters" />
                    <div className="mb-2 mx-4">
                      <div className="px-4 py-3 flex">
                        <div className="flex flex-row items-center">
                          <span className="text-lg md:text-2xl font-extrabold text-gray-500">
                            {fromPoint}
                          </span>
                          <div
                            className="pl-1 text-primary"
                            onClick={() => setIsLockedA(!isLockedA)}
                          >
                            {isLockedA ? (
                              <LockIcon fontSize="small" />
                            ) : (
                              <LockOpenIcon fontSize="small" />
                            )}
                          </div>
                        </div>
                        <div className="self-start">
                          <span className="text-lg md:text-2xl font-extrabold text-gray-500 mx-4 md:mx-10">
                            -
                          </span>
                        </div>
                        <div className="flex flex-row items-center">
                          <span className="text-lg md:text-2xl font-extrabold text-gray-500">
                            {toPoint}
                          </span>
                          <div
                            className="pl-1 text-primary"
                            onClick={() => setIsLockedB(!isLockedB)}
                          >
                            {isLockedB ? (
                              <LockIcon fontSize="small" />
                            ) : (
                              <LockOpenIcon fontSize="small" />
                            )}
                          </div>
                        </div>
                        <div className="flex flex-col ml-4 md:ml-10 mt-[7px]">
                          <span className="text-sm md:text-base text-gray-500">
                            現在寸法: {currentLength}
                          </span>
                        </div>
                      </div>
                      <Row label={<Th>新寸法入力</Th>}>
                        <Td>
                          <Input
                            name="newLength"
                            title="修正後"
                            suffix="m"
                            value={newLength}
                            onChange={(e) => {
                              const value = e.target.value
                              if (/^\d*\.?\d*$/.test(value)) {
                                setNewLength(value)
                              }
                            }}
                          />
                        </Td>
                      </Row>
                    </div>
                    <div className="border-t border-gray-150"></div>
                    <div className="flex gap-2 px-6 py-3 justify-end">
                      <Button
                        className="text-white w-[120px]"
                        prefix={<BorderColorOutlinedIcon fontSize="small" />}
                        size="small"
                        disabled={clickDisabled}
                        variant="primary"
                        onClick={(e) => {
                          e.preventDefault()
                          handleSubmit()
                          formRef.current?.submit()
                        }}
                      >
                        修正実施
                      </Button>
                    </div>
                  </form>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  )
}

export default VolumeCheckRoadModal
