const csrfToken: HTMLMetaElement = document.head.querySelector('meta[name="csrf-token"]')
const basePath = '/property_document_formats'

// 新規作成
export const createFormat = async (propertyDocumentFormatName: string) => {
  const response = await fetch(basePath, {
    method: 'POST',
    body: JSON.stringify({ name: propertyDocumentFormatName }),
    headers: {
      'Content-Type': 'application/json',
      'X-CSRF-Token': csrfToken.content,
    },
  })
  if (!response.ok) {
    return Promise.reject(new Error(`${response.status}: ${response.statusText}`))
  } else {
    return response.json()
  }
}

// 更新
export const updateFormat = async (id: number, params: object) => {
  const response = await fetch(`${basePath}/${id}`, {
    method: 'PATCH',
    body: JSON.stringify(params),
    headers: {
      'Content-Type': 'application/json',
      'X-CSRF-Token': csrfToken.content,
    },
  })
  if (!response.ok) {
    return Promise.reject(new Error(`${response.status}: ${response.statusText}`))
  } else {
    return response.json()
  }
}

// 削除
export const deleteFormat = async (id: number) => {
  const response = await fetch(`${basePath}/${id}`, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      'X-CSRF-Token': csrfToken.content,
    },
  })
  if (!response.ok) {
    return Promise.reject(new Error(`${response.status}: ${response.statusText}`))
  } else {
    return response.json()
  }
}

export const uploadFooterImage = async (id: number, formData: formData) => {
  const response = await fetch(`${basePath}/${id}/attach`, {
    method: 'PATCH',
    body: formData,
    headers: {
      'X-CSRF-Token': csrfToken.content,
    },
  })
  if (!response.ok) {
    return Promise.reject(new Error(`${response.status}: ${response.statusText}`))
  } else {
    return response.json()
  }
}
