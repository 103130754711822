import { useEffect, useRef, useState, useMemo, FC } from 'react';
import * as React from 'react'
import * as THREE from 'three';
import { Vector3 } from "three";
import DxfParser from 'dxf-parser';
import { Buffer } from 'buffer';

interface DxfObject {
  start: Vector3;
  end: Vector3;
}

const BirdCage: FC<{ dxfContent: string }> = ({ dxfContent }) => {
  const geometry = useMemo(() => {
    const parser = new DxfParser();
    const dxfData = parser.parseSync(Buffer.from(dxfContent, 'base64').toString('utf-8'));

    if (dxfData) {
      const newObjects: DxfObject[] = dxfData.entities.filter(entity => entity.type === '3DFACE').map((entity: any) => {
        return {
          start: new THREE.Vector3(entity.vertices[0].x, entity.vertices[0].y, entity.vertices[0].z),
          end: new THREE.Vector3(entity.vertices[1].x, entity.vertices[1].y, entity.vertices[1].z)
        };
      });

      const positions = new Float32Array(newObjects.length * 3 * 2);
      for (let i = 0; i < newObjects.length; i++) {
        const { start, end } = newObjects[i];
        positions[i * 6] = start.x;
        positions[i * 6 + 1] = start.y;
        positions[i * 6 + 2] = start.z;
        positions[i * 6 + 3] = end.x;
        positions[i * 6 + 4] = end.y;
        positions[i * 6 + 5] = end.z;
      }
      const bufferGeometry = new THREE.BufferGeometry();
      bufferGeometry.setAttribute('position', new THREE.BufferAttribute(positions, 3));
      return bufferGeometry;
    }

  }, [dxfContent]);

  return <group name='delFromMap-BirdCage'>
    <lineSegments geometry={geometry}>
      <lineBasicMaterial color='black' transparent opacity={0.25} />
    </lineSegments>
  </group>;
}

export { BirdCage }