import * as React from 'react'
import { dataPrecisionSetup } from '@/utils/cammedFormat'
import { Table, Row, Th, Td } from '@/components/Table'
import { Input } from '@/components/Input'
import { Button } from '@/components/Button'
import { searchTransportaiton } from '@/utils/searchTransportaiton'

type TransportationRowProps = {
  index: number
  transportation: object
  data: any
  setData: (data: object) => void
  dataLatLng: LatLng
  defaultTransportationData: object
  requestParamName: string
  projectsLayouts?: string[]
}

export const TransportationRow: React.FC<TransportationRowProps> = (props) => {
  const {
    index, transportation, data, setData, dataLatLng, defaultTransportationData,
    requestParamName, projectsLayouts
  } = props
  const onChangeData = (value, index, column) => {
    const transportations = [...data.transportations]
    transportations.splice(index, 1, {
      ...transportations[index],
      [column]: value,
    })
    setData({...data, transportations})
  }
  const onClickAddTransportationBlock = (e) => {
    e.preventDefault()
    setData({
      ...data,
      transportations: [...data.transportations, defaultTransportationData],
    })
    // 交通機関を検索
    searchTransportaiton(data, setData, dataLatLng)
  }
  const onCLickDeleteTransportationBlock = (e) => {
    e.preventDefault()
    const newTransportations = [...data.transportations]
    newTransportations.splice(index, 1)
    setData({
      ...data,
      transportations: newTransportations,
    })
  }
  // TEMPORARY: replacing "mins" because after OCR released, "mins" automatically attached as suffix in form value.
  const replaceOcrInsertString = (value) => {
    return value ? value.toString().replace(/mins|min/, '').trim() : ''
  }

  return (
    <Row label={projectsLayouts ?
      <Th projects_layouts={projectsLayouts}>交通機関{index > 0 && ` ${index + 1}`}</Th>:
      <Th>交通機関{index > 0 && ` ${index + 1}`}</Th>
    }>
      <Td>
        <div className="py-1 flex gap-4 flex-col md:flex-row">
          <Input
            multiple
            className="md:w-[300px]"
            value={transportation.transportation || ''}
            name={`${requestParamName}[transportation][]`}
            id={`${requestParamName}_transportation`}
            suffix="線"
            onChange={(e) => onChangeData(e.target.value, index, 'transportation')}
          />
          <Input
            multiple
            className="md:w-[300px]"
            value={transportation.nearest_station || ''}
            name={`${requestParamName}[nearest_station][]`}
            id={`${requestParamName}_nearest_station`}
            suffix="駅"
            onChange={(e) => onChangeData(e.target.value, index, 'nearest_station')}
          />
        </div>
        <div className="py-1 flex gap-4 flex-col md:flex-row">
          <Input
            multiple
            className="md:w-[300px]"
            value={replaceOcrInsertString(transportation.walking)}
            name={`${requestParamName}[walking][]`}
            id={`${requestParamName}_walking`}
            prefix="徒歩"
            suffix="分"
            onChange={(e) => onChangeData(e.target.value, index, 'walking')}
            onBlur={(e) => onChangeData(dataPrecisionSetup(e.target.value, 10, 0), index, 'walking')}
          />
          <Input
            multiple
            className="md:w-[300px]"
            value={transportation.bus || ''}
            name={`${requestParamName}[bus][]`}
            id={`${requestParamName}_bus`}
            prefix="バス"
            suffix="分"
            onChange={(e) => onChangeData(e.target.value, index, 'bus')}
            onBlur={(e) => onChangeData(dataPrecisionSetup(e.target.value, 10, 0), index, 'bus')}
          />
        </div>
        <div className="py-1 flex gap-4 flex-col md:flex-row">
          <Input
            multiple
            className="md:w-[300px]"
            value={transportation.minutes_on_foot || ''}
            name={`${requestParamName}[minutes_on_foot][]`}
            id={`${requestParamName}_minutes_on_foot`}
            prefix="下車徒歩"
            suffix="分"
            onChange={(e) => onChangeData(e.target.value, index, 'minutes_on_foot')}
            onBlur={(e) => onChangeData(dataPrecisionSetup(e.target.value, 10, 0), index, 'minutes_on_foot')}
          />
        </div>
        <div className="flex gap-2 mt-2">
          {data?.transportations.length !== 1 && (
            <Button
              outline
              variant="red"
              size="small"
              onClick={(e) => onCLickDeleteTransportationBlock(e)}
            >
              削除
            </Button>
          )}
          {data?.transportations.length === index + 1 && (
            <Button
              outline
              size="small"
              onClick={(e) => onClickAddTransportationBlock(e)}
            >
              追加
            </Button>
          )}
        </div>
      </Td>
    </Row>
  )
}
