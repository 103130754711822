import * as React from 'react'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import Base from '@/components/Layout/Base'
import { Breadcrumb } from '@/components/Breadcrumb'
import { Button, AnchorButton } from '@/components/Button'
import type { Tag } from '@/types/tag'
import { Select } from '@/components/Select'
import { User } from '@/types/user'
import { Card } from '@/components/SortableList/BusinessTypes/Card'

type Props = {
  current_user: User
  business_type: Tag
  columns_selected_values: { number: string }
  column_list: { value: string; text: string }[]
  authenticity_token: string
}

const BusinessTypesLayoutPage: React.FC<Props> = ({
  current_user,
  business_type,
  columns_selected_values,
  column_list,
  authenticity_token,
}) => {
  const title = 'レイアウト設定'

  const [layoutColumnValues, setLayoutColumnValues] = React.useState(
    Array.from({ length: 28 }, (_, index) => columns_selected_values[index] || '')
  )

  const handleLayoutColumnValues = (value, index) => {
    layoutColumnValues[index] = value
    setLayoutColumnValues([...layoutColumnValues])
  }
  const moveCardDouble = (dragIndex: number, hoverIndex: number) => {
    moveCard(dragIndex, hoverIndex)
    moveCard(dragIndex + 14, hoverIndex + 14)
  }
  const moveCard = React.useCallback(
    (dragIndex: number, hoverIndex: number) => {
      setLayoutColumnValues((prevCards: LayoutColumnValue[]) => {
        const dragCard = prevCards[dragIndex]
        const newCards = [...prevCards]
        newCards.splice(dragIndex, 1)
        newCards.splice(hoverIndex, 0, dragCard)
        return newCards
      })
    },
    [setLayoutColumnValues]
  )

  return (
    <Base
      current_user={current_user}
      title={title}
      breadcrumb={<Breadcrumb link_texts={['事業形態設定', 'レイアウト設定']} />}
      submenuCategory="settings"
    >
      <form
        className="mt-8"
        action={`/business_types/${business_type.id}/upsert_layout`}
        acceptCharset="UTF-8"
        method="post"
      >
        <input type="hidden" name="_method" value="post" />
        <input type="hidden" name="authenticity_token" value={authenticity_token} />

        <div className="h-[calc(100vh_-_182px)] md:h-[calc(100vh_-_195px)]">
          <div>事業形態：{business_type.name}</div>
          <div className="py-6 whitespace-nowrap overflow-x-scroll">
            <div className="flex flex-row gap-1">
              <div className="flex border-solid border-[1px] border-gray-border rounded-sm px-3 items-center justify-center">
                No.
              </div>
              <div className="flex border-solid border-[1px] border-gray-border rounded-sm px-3 items-center justify-center">
                案件名
              </div>
              <div className="flex border-solid border-[1px] border-gray-border rounded-sm px-3 items-center justify-center">
                タグ
              </div>
              <DndProvider backend={HTML5Backend}>
                {[...Array(14)].map((_, i) => (
                  <Card
                    key={i}
                    index={i}
                    id={i}
                    column_list={column_list}
                    layoutColumnValues={layoutColumnValues}
                    onChangeLayoutColumnValues={handleLayoutColumnValues}
                    moveCardDouble={moveCardDouble}
                  />
                ))}
              </DndProvider>
              <div className="flex border-solid border-[1px] border-gray-border rounded-sm px-3 items-center justify-center">
                進捗
              </div>
              <div className="flex border-solid border-[1px] border-gray-border rounded-sm px-3 items-center justify-center">
                進捗メモ更新日
              </div>
            </div>
          </div>
        </div>
        <div className="bg-white flex justify-center items-center w-full left-0 md:gap-4 border-t border-gray-250 pt-2">
          <AnchorButton
            className="w-full md:w-40 md:h-auto text-primary-font border-white"
            outline
            variant="text"
            href="/business_types"
          >
            キャンセル
          </AnchorButton>
          <Button className="w-full md:w-40 md:h-auto">編集を保存</Button>
        </div>
      </form>
    </Base>
  )
}

export default BusinessTypesLayoutPage
