import { Loading } from '@/components/Loading';
import { usageAreaCode } from '@/models/property';
import { EventEmitter } from "events";
import * as React from 'react';
import { Root, createRoot } from "react-dom/client";
import { area_colors } from "./utils/areaColors";
import { isTokyoArea } from '../../utils/isTokyoArea'

export class YoutoChiiki {
  private map: google.maps.Map
  private lat: number | string = ""
  private lng: number | string = ""
  private polygons_api_base_url: string = ""

  private infoMarkers = []
  readonly emitter = new EventEmitter();

  private polygonsHash = {
    youto_chiiki: {},
    bouka_chiiki: {},
    koudo_chiku: {},
    shikichi: {},
    hikage_kisei: {},
    kouzu_map: {},
  }

  hasUsageInfo = false
  hasAntifireInfo = false
  hasHeightInfo = false
  hasHikageInfo = false

  private chibanMarkers = []
  private selectedInfoWindows = []
  private centerCursors = []
  // privateにしたい
  youtoInfo = {}
  private readonly cityPlanItems = ['youto_chiiki', 'bouka_chiiki', 'koudo_chiku', 'hikage_kisei']
  private readonly cadastralItems = ['kouzu_map']
  private loadingRoot: Root

  constructor(map: google.maps.Map, lat, lng, polygons_api_base_url: string) {
    this.map = map
    this.lat = lat
    this.lng = lng
    this.polygons_api_base_url = polygons_api_base_url

    this.setupViews()
  }

  // MEMO: ビュー関連のセットアップはここに書く
  setupViews() { }

  // layerButtonStates: WIP
  async idle(layerButtonStates) {
    this.clearCityPlanItems()
    if (this.polygons_api_base_url == '' || this.map.getZoom() < 17) {
      this.emitter.emit("setShowInfoTable", false)
      return
    }

    this.removeAllMarkers()
    this.setCenterCursor()

    this.setUsageInfos({
      usageArea: '取得中',
      buildingCoverageRatio: '取得中',
      floorAreaRatio: '取得中',
    })
    this.setAntifireInfo('取得中')
    this.setHeightInfos({
      heightInfo: '取得中',
      heightMax: '取得中',
      heightMin: '取得中',
    })
    this.setShadeInfos({
      shade5m: '取得中',
      shade10m: '取得中',
      shadeHeight: '取得中',
    })

    // 用途地域のポリゴンの処理中は地図の操作を受け付けない
    this.setMapAcceptOperation(false)

    this.hasUsageInfo = false
    this.hasAntifireInfo = false
    this.hasHeightInfo = false
    this.hasHikageInfo = false

    let polygonTypes = []
    if (layerButtonStates.showPolygonsClicked) {
      polygonTypes = polygonTypes.concat(this.cityPlanItems)
    }

    if (layerButtonStates.showKouzuMapClicked) {
      polygonTypes = polygonTypes.concat(this.cadastralItems)
      this.loadingRoot = this.createLoadingIcon('kouzuMapButton', 'kouzuMapButtonLoadingIcon')
    }

    for (const area of polygonTypes) {
      try {
        await this.setPolygons(area)
      } catch (error) {
        console.error(error)
      } finally {
        this.removeLoadingIcon(this.loadingRoot, 'kouzuMapButtonLoadingIcon')

        if (area == 'youto_chiiki' && !this.hasUsageInfo) {
          this.setUsageInfos({
            usageArea: 'N/A',
            buildingCoverageRatio: 'N/A',
            floorAreaRatio: 'N/A',
          })
        } else if (area == 'bouka_chiiki' && !this.hasAntifireInfo) {
          isTokyoArea(this.map).then((result) => {
            if (result) {
              this.setAntifireInfo('-')
            } else {
              this.setAntifireInfo('N/A')
            }
          })
        } else if (area == 'koudo_chiku' && !this.hasHeightInfo) {
          isTokyoArea(this.map).then((result) => {
            if (result) {
              this.setHeightInfos({
                heightInfo: '-',
                heightMax: '-',
                heightMin: '-',
              })
            } else {
              this.setHeightInfos({
                heightInfo: 'N/A',
                heightMax: 'N/A',
                heightMin: 'N/A',
              })
            }
          })
        } else if (area == 'hikage_kisei' && !this.hasHikageInfo) {
          this.setShadeInfos({
            shade5m: 'N/A',
            shade10m: 'N/A',
            shadeHeight: 'N/A',
          })
        }
        this.setMapAcceptOperation(true)
      }
    }
  }

  // FIXME: 呼ばれ方が異常なので詳しく見たほうがいいかも
  clearCadastralItems() {
    this.clearItems(this.cadastralItems)
    this.chibanMarkers.forEach((chibanMarker) => {
      chibanMarker.setMap(null)
    })
  }

  clearCityPlanItems() {
    this.clearItems(this.cityPlanItems)
    this.infoMarkers.forEach((infoMarker) => {
      infoMarker.setMap(null)
    })
    this.centerCursors.forEach((centerCursor) => {
      centerCursor.setMap(null)
    })
    this.setUsageInfos({
      usageArea: '-',
      buildingCoverageRatio: '-',
      floorAreaRatio: '-',
    })
    this.setAntifireInfo('-')
    this.setHeightInfos({
      heightInfo: '-',
      heightMax: '-',
      heightMin: '-',
    })
    this.setShadeInfos({
      shade5m: '-',
      shade10m: '-',
      shadeHeight: '-',
    })
  }

  // MARK: private
  private clearItems(areas) {
    areas.forEach((area) => {
      Object.keys(this.polygonsHash[area]).forEach((key) => {
        this.polygonsHash[area][key].setMap()
        delete this.polygonsHash[area][key]
      })
    })
  }

  private removeAllMarkers() {
    this.infoMarkers.forEach((infoMarker) => {
      infoMarker.setMap(null)
    })
    this.chibanMarkers.forEach((chibanMarker) => {
      chibanMarker.setMap(null)
    })
    this.selectedInfoWindows.forEach((infoWindow) => {
      infoWindow.close()
    })
    this.selectedInfoWindows = []
    this.centerCursors.forEach((centerCursor) => {
      centerCursor.setMap(null)
    })
  }

  // 十字カーソルを中心に表示
  private setCenterCursor() {
    let centerCursor = new google.maps.Marker({
      position: this.map.getCenter(),
      map: this.map,
      icon: {
        url: '/center_cursor.png',
        scaledSize: new google.maps.Size(35, 35),
      },
    })
    this.centerCursors.push(centerCursor)

  }

  private createLoadingIcon(buttonId, iconId) {
    const button = document.getElementById(buttonId)
    if (!button) return

    let loadingIconContainer = document.createElement('span')
    loadingIconContainer.id = iconId
    loadingIconContainer.style.display = 'inline-block'
    loadingIconContainer.style.marginLeft = '5px'
    button.appendChild(loadingIconContainer)

    let loadingRoot = createRoot(loadingIconContainer)
    loadingRoot.render(<Loading height={12} width={12} />)

    return loadingRoot
  }

  // 用途地域のポリゴンの処理中は地図の操作を受け付けない。on off
  private setMapAcceptOperation(accept: boolean) {
    this.map.setOptions({
      draggable: accept,
      scrollwheel: accept,
      disableDoubleClickZoom: true,
      zoomControl: accept,
      scaleControl: accept,
      panControl: accept,
    })
  }

  private async setPolygons(area) {
    let selectedPolygons = []
    let originalColors = []
    let selectedChibanInfos = []

    const json = await this.fetchPolygons(area)

    // jsonのキーにpolygonsがない場合
    if (Object.keys(json).indexOf('polygons') === -1) {
      return
    }

    if (json.layer === 'cadastral' && json.polygons.length === 0) {
      this.removeLoadingIcon(this.loadingRoot, 'kouzuMapButtonLoadingIcon')
    }

    Object.keys(this.polygonsHash[area]).forEach((key) => {
      this.polygonsHash[area][key].setMap()
      delete this.polygonsHash[area][key]
    })

    for (const data of json.polygons) {
      let points = data.lines.map((point) =>
        new google.maps.LatLng(Number(point.lat), Number(point.lng))
      )
      let framePoints = points
      const innerPoints = data.innerlines.map((path) => {
        return path.map((point) => {
          return new google.maps.LatLng(Number(point.lat), Number(point.lng))
        })
      })
      if (innerPoints.length > 0) {
        innerPoints.unshift(points)
        points = innerPoints
      }

      const options = this.getPolygonOptions(area, points, data)

      const polygon = new google.maps.Polygon(options)

      let minLatLng = this.map.getBounds().getSouthWest()
      let maxLatLng = this.map.getBounds().getNorthEast()
      let pad_lat_range = (Number(maxLatLng.lat()) - Number(minLatLng.lat())) * 0.1
      let pad_lng_range = (Number(maxLatLng.lng()) - Number(minLatLng.lng())) * 0.1

      // TODO: forの中に入れたくない。
      const displayInfoMarker = (data, center) => {
        this.displayLayerMarker(
          17,
          [130, 60],
          '12px',
          this.labelContent,
          this.infoMarkers,
          data,
          center,
          '/info_label.png'
        )
      }

      const displayChibanMarker = (data, center) => {
        this.displayLayerMarker(
          20,
          [40, 40],
          '11px',
          (d) => {
            const chomeMatch = d.types['丁目名']?.match(/[\uFF10-\uFF19]+/)
            const chomeNumber = chomeMatch ? `${this.convertFullwidthToHalfwidth(chomeMatch[0])}-` : ''
            return `${chomeNumber}${d.types['地番']}`
          },
          this.chibanMarkers,
          data,
          center,
          '/info_label2.png'
        )
      }

      // ===============

      const processPolygons = (displayMarkerFunc) => {
        const targetPoints = []
        framePoints.forEach((point) => {
          if (
            Number(point.lat()) > Number(minLatLng.lat()) &&
            Number(point.lat()) < Number(maxLatLng.lat()) &&
            Number(point.lng()) > Number(minLatLng.lng()) &&
            Number(point.lng()) < Number(maxLatLng.lng())
          ) {
            targetPoints.push(point)
          }
        })
        // 地図枠内にパスがあるポリゴンのみ表示
        if (targetPoints.length > 0) {
          let bounds = new google.maps.LatLngBounds()
          targetPoints.forEach((point) => {
            bounds.extend(point)
          })
          let center = bounds.getCenter()
          // ポリゴンの中心が地図の外側に出ている場合、地図の内側に収まるように調整
          if (center.lat() < minLatLng.lat()) {
            center = new google.maps.LatLng(
              minLatLng.lat() + pad_lat_range,
              center.lng()
            )
          }
          if (center.lat() > maxLatLng.lat()) {
            center = new google.maps.LatLng(
              maxLatLng.lat() - pad_lat_range * 2,
              center.lng()
            )
          }
          if (center.lng() < minLatLng.lng()) {
            center = new google.maps.LatLng(
              center.lat(),
              minLatLng.lng() + pad_lng_range
            )
          }
          if (center.lng() > maxLatLng.lng()) {
            center = new google.maps.LatLng(
              center.lat(),
              maxLatLng.lng() - pad_lng_range * 2
            )
          }
          // ポリゴンの中心点がポリゴンの外側にある場合（L状のようなポリゴンとか）、ポリゴンの内側に収まるように調整
          if (google.maps.geometry.poly.containsLocation(center, polygon)) {
            displayMarkerFunc(data, center)
          } else {
            pad_lat_range = pad_lat_range / 2
            pad_lng_range = pad_lng_range / 2
            let minLat = minLatLng.lat() + pad_lat_range
            let minLng = minLatLng.lng() + pad_lng_range
            this.lng = Number(minLng)
            this.lat = Number(minLat)
            while (!google.maps.geometry.poly.containsLocation(center, polygon)) {
              if (center.lng() > this.map.getBounds().getNorthEast().lng()) {
                this.lat = this.lat + Number(pad_lat_range)
                this.lng = this.map.getBounds().getSouthWest().lng() + Number(pad_lng_range)
              }
              center = new google.maps.LatLng(Number(this.lat), Number(this.lng))
              if (
                center.lat() > this.map.getBounds().getNorthEast().lat() &&
                center.lng() > this.map.getBounds().getNorthEast().lng()
              ) {
                break
              }
              this.lng = Number(this.lng) + Number(pad_lng_range)
            }
            displayMarkerFunc(data, center)
          }
        }
      }

      const containsLocation = google.maps.geometry.poly.containsLocation(this.map.getCenter(), polygon)
      switch (area) {
        case 'youto_chiiki':
          // 0番用途地域でない場合、ラベルを貼る
          if (data.types.youto_chiiki != 0) {
            processPolygons(displayInfoMarker)
          }
          if (containsLocation) {
            this.getUsageInfo(data, this.setYoutoInfo)
            this.hasUsageInfo = true
          }
          break

        case 'bouka_chiiki':
          if (containsLocation) {
            this.getAntifireInfo(data, this.setYoutoInfo)
            this.hasAntifireInfo = true
          }
          break

        case 'koudo_chiku':
          if (containsLocation) {
            this.getHeightInfo(data, this.setYoutoInfo)
            this.hasHeightInfo = true
          }
          break

        case 'hikage_kisei':
          if (containsLocation) {
            this.getHikageInfo(data, this.setYoutoInfo)
            this.hasHikageInfo = true
          }
          break

        case 'kouzu_map':
          if (this.map.getZoom() == 20) {
            processPolygons(displayChibanMarker)
            google.maps.event.addListener(polygon, 'click', (mouseEvent) => {
              let index = selectedPolygons.indexOf(polygon)
              if (index !== -1) {
                polygon.setOptions({ fillColor: originalColors[index] })
                selectedPolygons.splice(index, 1)
                originalColors.splice(index, 1)
                selectedChibanInfos.splice(index, 1)
                if (this.setChibanInfo) {
                  this.setChibanInfo(selectedChibanInfos.join(', '))
                }
                if (this.selectedInfoWindows[index]) {
                  this.selectedInfoWindows[index].close()
                  this.selectedInfoWindows.splice(index, 1)
                }
              } else {
                originalColors.push(polygon.get('fillColor'))
                polygon.setOptions({ fillColor: '#FF0000' })
                selectedPolygons.push(this)
                const chomeMatch = data.types['丁目名']?.match(/[\uFF10-\uFF19]+/)
                const chomeNumber = chomeMatch
                  ? `${this.convertFullwidthToHalfwidth(chomeMatch[0])}-`
                  : ''
                const chibanInfo = `${chomeNumber}${data.types['地番']}`
                selectedChibanInfos.push(chibanInfo)
                this.setChibanInfo?.(selectedChibanInfos.join(', '))

                const infoWindow = new google.maps.InfoWindow({
                  content: `地番：${chibanInfo}`,
                  position: mouseEvent.latLng,
                })
                infoWindow.open(this.map)
                this.selectedInfoWindows.push(infoWindow)
              }
            })
          } else {
            this.chibanMarkers.forEach((chibanMarker) => {
              chibanMarker.setMap(null)
            })
          }
          break

        default:
          console.log('Unknown area: ', area)
      }

      polygon.setMap(this.map)
      this.polygonsHash[area][data.id] = polygon
    }
  }

  private async fetchPolygons(area): Promise<any> {
    const center = this.map.getCenter()

    const query = new URLSearchParams({
      lat: `${center.lat()}`,
      lng: `${center.lng()}`,
      polygon_type: area,
    })
    const res = await fetch(`${this.polygons_api_base_url}?${query}`)

    if (!res.ok) {
      throw new Error(`HTTP error! ${res.status} ${res.statusText}`)
    }

    return res.json()
  }

  private getPolygonOptions(area, points, data) {
    var option = {
      paths: points,
      strokeColor: '#000000',
      strokeOpacity: 0,
      strokeWeight: 0,
      fillColor: '#000000',
      fillOpacity: 0,
    }

    if (area == 'youto_chiiki' || area == 'bouka_chiiki') {
      const color = area_colors[area][`${data.types[area]}`]
      option.strokeColor = color
      option.fillColor = color
      if (data.types[area] === 0) {
        option.fillOpacity = 0
        option.strokeOpacity = 0
      } else {
        option.strokeOpacity = area == 'youto_chiiki' ? 0.1 : 0
        option.strokeWeight = area == 'youto_chiiki' ? 0.5 : 0
        option.fillOpacity = area == 'youto_chiiki' ? 0.3 : 0.1
      }
    } else if (area == 'koudo_chiku') {
      option.strokeColor = '#0066F9'
      option.strokeOpacity = data.types.koudo_chiku == 0 ? 0 : 0.5
      option.strokeWeight = 1
    } else if (area == 'hikage_kisei') {
      option.strokeOpacity = 0.15
      option.strokeWeight = 2
    } else if (area == 'kouzu_map') {
      option.strokeColor = '#125690'
      option.strokeOpacity = 0.3
      option.strokeWeight = 1
      option.fillColor = '#e1f0fc'
      option.fillOpacity = 0.1
    }
    return option
  }

  private removeLoadingIcon(loadingRoot: Root, iconId) {
    let loadingIconContainer = document.getElementById(iconId)
    if (loadingIconContainer) {
      loadingRoot.unmount()
      loadingIconContainer.parentNode.removeChild(loadingIconContainer)
    }
  }

  private displayLayerMarker(
    zoomLevel,
    markerSize,
    fontSize,
    contentFunc,
    markerArray,
    data,
    center,
    iconURL
  ) {
    if (this.map.getZoom() >= zoomLevel) {
      const contentString = contentFunc(data)
      if (typeof contentString === 'undefined') {
        return
      }
      let marker = new google.maps.Marker({
        position: center,
        map: this.map,
        icon: {
          url: iconURL,
          scaledSize: new google.maps.Size(markerSize[0], markerSize[1]),
        },
        label: {
          text: contentString,
          color: '#125690',
          fontSize: fontSize,
          fontWeight: 'bold',
        },
        clickable: false,
      })
      markerArray.push(marker)
    }
  }

  private labelContent(data: any) {
    const acronym = {
      '1': '1低',
      '2': '2低',
      '3': '1中',
      '4': '2中',
      '5': '1住',
      '6': '2住',
      '7': '準住',
      '8': '近商',
      '9': '商業',
      '10': '準工',
      '11': '工業',
      '12': '工専',
      '0': '-',
    }
    let content = `${acronym[data.types.youto_chiiki]}:${data.types.building_coverage_ratio}:${data.types.floor_area_ratio
      }`
    return content
  }

  private getUsageInfo(data, setYoutoInfo) {
    if (data.types.youto_chiiki == 0) {
      const content = {
        usageArea: 'N/A',
        buildingCoverageRatio: 'N/A',
        floorAreaRatio: 'N/A',
      }
      this.setUsageInfos(content)

      if (setYoutoInfo) {
        this.youtoInfo['usage'] = content
        setYoutoInfo(this.youtoInfo)
      }
    } else {
      const content = {
        usageArea: usageAreaCode[data.types.youto_chiiki],
        buildingCoverageRatio: `${data.types.building_coverage_ratio}%`,
        floorAreaRatio: `${data.types.floor_area_ratio}%`,
      }
      this.setUsageInfos(content)

      if (setYoutoInfo) {
        this.youtoInfo['usage'] = content
        setYoutoInfo(this.youtoInfo)
      }
    }
  }

  private getAntifireInfo(data, setYoutoInfo) {
    const acronym = { '10': '防火地域', '20': '準防火地域', '0': '指定なし' }
    let content = acronym[data.types.bouka_chiiki]
    this.setAntifireInfo(content)

    if (setYoutoInfo) {
      this.youtoInfo['antifire'] = content
      setYoutoInfo(this.youtoInfo)
    }
  }

  private getHeightInfo(data, setYoutoInfo) {
    if (data.types.koudo_chiku == 0) {
      const content = {
        heightInfo: '指定なし',
        heightMax: '指定なし',
        heightMin: '指定なし',
      }
      this.setHeightInfos(content)

      if (setYoutoInfo) {
        this.youtoInfo['height'] = content
        setYoutoInfo(this.youtoInfo)
      }
    } else if (data.types.koudo_chiku == 4) {
      const content = {
        heightInfo: '指定なし',
        heightMax: data.types.max_height == 0 ? '指定なし' : `${data.types.max_height}m`,
        heightMin: data.types.min_height == 0 ? '指定なし' : `${data.types.min_height}m`,
      }
      this.setHeightInfos(content)

      if (setYoutoInfo) {
        this.youtoInfo['height'] = content
        setYoutoInfo(this.youtoInfo)
      }
    } else {
      const content = {
        heightInfo: `第${data.types.koudo_chiku}種高度地区`,
        heightMax: data.types.max_height == 0 ? '指定なし' : `${data.types.max_height}m`,
        heightMin: data.types.min_height == 0 ? '指定なし' : `${data.types.min_height}m`,
      }
      this.setHeightInfos(content)

      if (setYoutoInfo) {
        this.youtoInfo['height'] = content
        setYoutoInfo(this.youtoInfo)
      }
    }
  }

  private getHikageInfo(data, setYoutoInfo) {
    if (data.types.hikage == 'I') {
      const content = {
        shade5m: '指定なし',
        shade10m: '指定なし',
        shadeHeight: '指定なし',
      }
      this.setShadeInfos(content)

      if (setYoutoInfo) {
        this.youtoInfo['shade'] = content
        setYoutoInfo(this.youtoInfo)
      }
    } else {
      const content = {
        shade5m: data.types.shade_5 == 0 ? '指定なし' : `${data.types.shade_5}時間`,
        shade10m: data.types.shade_10 == 0 ? '指定なし' : `${data.types.shade_10}時間`,
        shadeHeight: data.types.shade_height == 0 ? '指定なし' : `${data.types.shade_height}m`,
      }
      this.setShadeInfos(content)

      if (setYoutoInfo) {
        this.youtoInfo['shade'] = content
        setYoutoInfo(this.youtoInfo)
      }
    }
  }

  // MARK: private utils
  private convertFullwidthToHalfwidth(str) {
    return str.replace(/[\uFF10-\uFF19]/g, function (m) {
      return String.fromCharCode(m.charCodeAt(0) - 0xfee0)
    })
  }

  // MARK: State
  private setUsageInfos(f: {
    usageArea?: string,
    buildingCoverageRatio?: string,
    floorAreaRatio?: string
  }) {
    this.emitter.emit('setUsageInfos', f)
  }
  private setAntifireInfo(f: string) {
    this.emitter.emit('setAntifireInfo', f)
  }
  private setHeightInfos(f: {
    heightInfo?: string,
    heightMax?: string,
    heightMin?: string,
  }) {
    this.emitter.emit('setHeightInfos', f)
  }
  private setShadeInfos(f: {
    shade5m?: string,
    shade10m?: string,
    shadeHeight?: string,
  }) {
    this.emitter.emit('setShadeInfos', f)
  }

  private setYoutoInfo = (key: string, info: any) => {
    this.emitter.emit('setYoutoInfo', key, info)
  }

  private setChibanInfo(info: string) {
    this.emitter.emit('setChibanInfo', info)
  }
}
