import { ChevronDoubleLeft, ChevronDoubleRight } from '@/components/SvgIcon'
import { Pagy } from '@/types/pagy'
import { colors } from '@/utils/colors'
import * as React from 'react'

type Props = {
  pagy: Pagy
  navigate?: (page: number) => void
}

export const Pagination: React.FC<Props> = ({ pagy, navigate }) => {
  // 現在のページと前後合わせて5ページ
  const from = pagy.page - 2
  const to = pagy.page + 2
  const pages = Array.from(new Array(pagy.pages))
    .map((v, i) => i + 1)
    .filter((page) => {
      if (pagy.page <= 4) {
        return page <= 5
      } else if (pagy.page >= pagy.last - 3) {
        return page >= pagy.last - 4
      } else {
        return page >= from && page <= to
      }
    })
  const searchParams = new URLSearchParams(window.location.search)
  searchParams.delete('page')
  const query = searchParams.toString() !== '' ? `&${searchParams.toString()}` : ''

  return (
    <div className="flex justify-center">
      <ul className={styles.row}>
        {pagy.prev ? (
          <li
            className={`${styles.cell} md:py-1`}
            onClick={() => {
              const page = pagy.page - 1

              if (navigate) {
                navigate(page)
              } else {
                const path = `${window.location.pathname.match(/^\/\w*/)[0]}?page=${page}`
                window.location.href = query ? `${path}${query}` : path
              }
            }}
          >
            <ChevronDoubleLeft size={18} fill={colors.primary} />
          </li>
        ) : (
          <li className={`${styles.cellDisabled} md:py-1`}>
            <ChevronDoubleLeft size={18} fill={colors.lightGray} />
          </li>
        )}
        {pages[0] >= 3 && (
          <>
            <li
              className={`${styles.cell} border-l md:py-1`}
              key={1}
              onClick={() => {
                const page = 1

                if (navigate) {
                  navigate(page)
                } else {
                  const path = `${window.location.pathname.match(/^\/\w*/)[0]}`
                  window.location.href = query ? `${path}?page=1${query}` : path
                }
              }}
            >
              1
            </li>
            <li className={`${styles.cellDisabled} border-l md:py-1`} key={2}>
              ...
            </li>
          </>
        )}
        {pages.map((page) => (
          <li
            className={`${page === pagy.page ? styles.current : styles.cell} border-l md:py-1`}
            key={page}
            onClick={() => {
              if (page === pagy.page) {
                return
              }

              if (navigate) {
                navigate(page)
              } else {
                window.location.href = `${
                  window.location.pathname.match(/^\/\w*/)[0]
                }?page=${page}${query}`
              }
            }}
          >
            {page}
          </li>
        ))}
        {pages[pages.length - 1] <= pagy.last - 2 && (
          <>
            <li className={`${styles.cellDisabled} border-l md:py-1`} key={pagy.last - 1}>
              ...
            </li>
            <li
              className={`${styles.cell} border-l md:py-1`}
              key={pagy.last}
              onClick={() => {
                const page = pagy.last

                if (navigate) {
                  navigate(page)
                } else {
                  const path = `${window.location.pathname.match(/^\/\w*/)[0]}`
                  window.location.href = query
                    ? `${path}?page=${page}${query}`
                    : `${path}?page=${page}`
                }
              }}
            >
              {pagy.last}
            </li>
          </>
        )}
        {pagy.next ? (
          <li
            className={`${styles.cell} border-l md:py-1`}
            onClick={() => {
              const page = pagy.page + 1

              if (navigate) {
                navigate(page)
              } else {
                const path = `${window.location.pathname.match(/^\/\w*/)[0]}?page=${page}`
                window.location.href = query ? `${path}${query}` : path
              }
            }}
          >
            <ChevronDoubleRight size={18} fill={colors.primary} />
          </li>
        ) : (
          <li className={`${styles.cellDisabled} border-l md:py-1`}>
            <ChevronDoubleRight size={18} fill={colors.lightGray} />
          </li>
        )}
      </ul>
    </div>
  )
}

const styles = {
  row: `
    flex
    items-center
    border
    text-sm
    md:text-base
  `,
  cell: `
    cursor-pointer
    text-primary
    font-medium
    px-3
    leading-[20px]
    hover:bg-gray-100
  `,
  cellDisabled: `
    text-gray-200
    font-medium
    px-3
    leading-[20px]
  `,
  current: `
    text-white
    bg-primary
    font-medium
    px-3
    leading-[20px]
  `,
}
