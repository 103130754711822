import * as React from 'react'
import { dataPrecisionSetup } from '@/utils/cammedFormat'
import { Table, Row, Th, Td } from '@/components/Table'
import { Input } from '@/components/Input'
import { Button } from '@/components/Button'
import { Select } from '@/components/Select'
import {
  zoningAreaCodes,
  areaOfUseCodes,
  fireProtectionCodes,
  altitudeAreaCodes,
  shadowAreaCodes,
  shadeInfoSet,
  getTextFromCode,
  getCodeFromText,
} from '@/models/areaInfoCodeshashToArray'

type AreaInfoHandRowProps = {
  index: number
  areainfo: object
  data: any
  setData: (data: object) => void
  dataLatLng: LatLng
  defaultAreaInfoHandData: object
  requestParamName: string
  projectsLayouts?: string[]
}

export const AreaInfoHandRow: React.FC<AreaInfoHandRowProps> = (props) => {
  const {
    index,
    areainfo,
    data,
    setData,
    dataLatLng,
    defaultAreaInfoHandData,
    requestParamName,
    projectsLayouts,
  } = props

  const onChangeData = (value, index, column) => {
    if (column === 'zoning_area' || column === 'other_info') return

    const areainfos = [...data.area_info_hands]
    areainfos.splice(index, 1, {
      ...areainfos[index],
      [column]: value,
    })
    setData({
      ...data,
      area_info_hands: areainfos,
    })
  }

  const onChangeSharedValue = (value, field) => {
    const areainfos = [...data.area_info_hands].map((info) => ({
      ...info,
      [field]: value,
    }))
    setData({
      ...data,
      area_info_hands: areainfos,
    })
  }
  const onChangeZoningArea = (value) => {
    const zoningAreaValue = value ? getTextFromCode(Number(value), zoningAreaCodes) : null
    onChangeSharedValue(zoningAreaValue, 'zoning_area')
  }
  const onChangeOtherInfo = (value) => {
    onChangeSharedValue(value, 'other_info')
  }

  const onClickAddAreaInfoBlock = (e) => {
    e.preventDefault()

    if (!data || !Array.isArray(data.area_info_hands)) {
      console.error('data or data.area_info_hands is not properly initialized')
      return
    }

    if (data.area_info_hands.length >= 4) {
      alert('エリアの最大数（4）に達しました。これ以上エリアを追加できません。')
      return
    }

    const currentZoningArea = data.area_info_hands[0]?.zoning_area || null
    const currentOtherInfo = data.area_info_hands[0]?.other_info || null
    setData({
      ...data,
      area_info_hands: [...data.area_info_hands, defaultAreaInfoHandData],
    })
  }

  const onCLickDeleteAreaInfoBlock = (e) => {
    e.preventDefault()
    const newAreaInfos = [...data.area_info_hands]
    newAreaInfos.splice(index, 1)
    setData({
      ...data,
      area_info_hands: newAreaInfos,
    })
  }
  // TEMPORARY: replacing "mins" because after OCR released, "mins" automatically attached as suffix in form value.
  const replaceOcrInsertString = (value) => {
    return value
      ? value
          .toString()
          .replace(/mins|min/, '')
          .trim()
      : ''
  }

  return (
    <Row
      label={
        projectsLayouts ? (
          <Th projects_layouts={projectsLayouts}>法令制限{index > 0 && ` ${index + 1}`}</Th>
        ) : (
          <Th>法令制限{index > 0 && ` ${index + 1}`}</Th>
        )
      }
    >
      <Td>
        {index === 0 && (
          <div className="relative md:w-[300px] flex-shrink-0">
            <Select
              className="w-full"
              value={getCodeFromText(areainfo.zoning_area || '', zoningAreaCodes)}
              name={`${requestParamName}[zoning_area][]`}
              id={`${requestParamName}_zoning_area`}
              options={zoningAreaCodes.map((code) => ({ value: code.value, text: code.text }))}
              hasBlank
              blankLabel="(未設定)"
              prefix="区域区分"
              onChange={(e) => onChangeZoningArea(e.target.value)}
            />
          </div>
        )}
        <div className="py-1 flex gap-4 flex-col md:flex-row">
          <div className="relative md:w-[300px] flex-shrink-0">
            <Select
              className="w-full"
              value={getCodeFromText(areainfo.youto_chiiki_hand || '', areaOfUseCodes)}
              name={`${requestParamName}[youto_chiiki_hand][]`}
              id={`${requestParamName}_youto_chiiki_hand`}
              options={areaOfUseCodes.map((code) => ({ value: code.value, text: code.text }))}
              hasBlank
              blankLabel="(未設定)"
              prefix="用途地域"
              onChange={(e) =>
                onChangeData(
                  e.target.value ? getTextFromCode(Number(e.target.value), areaOfUseCodes) : null,
                  index,
                  'youto_chiiki_hand'
                )
              }
            />
          </div>
          <div className="relative md:w-[300px] flex-shrink-0">
            <Select
              className="w-full"
              value={getCodeFromText(areainfo.bouka_chiiki_hand || '', fireProtectionCodes)}
              name={`${requestParamName}[bouka_chiiki_hand][]`}
              id={`${requestParamName}_bouka_chiiki_hand`}
              options={fireProtectionCodes.map((code) => ({ value: code.value, text: code.text }))}
              hasBlank
              blankLabel="(未設定)"
              prefix="防火指定"
              onChange={(e) =>
                onChangeData(
                  e.target.value
                    ? getTextFromCode(Number(e.target.value), fireProtectionCodes)
                    : null,
                  index,
                  'bouka_chiiki_hand'
                )
              }
            />
          </div>
        </div>
        <div className="py-1 flex gap-4 flex-col md:flex-row">
          <div className="relative md:w-[300px] flex-shrink-0">
            <Select
              className="w-full"
              value={getCodeFromText(areainfo.koudo_chiku_hand || '', altitudeAreaCodes)}
              name={`${requestParamName}[koudo_chiku_hand][]`}
              id={`${requestParamName}_koudo_chiku_hand`}
              options={altitudeAreaCodes.map((code) => ({
                value: code.value,
                text: code.text,
              }))}
              hasBlank
              blankLabel="(未設定)"
              prefix="高度地区"
              onChange={(e) =>
                onChangeData(
                  e.target.value
                    ? getTextFromCode(Number(e.target.value), altitudeAreaCodes)
                    : null,
                  index,
                  'koudo_chiku_hand'
                )
              }
            />
          </div>
          <div className="relative md:w-[300px] flex-shrink-0">
            <Select
              className="w-full"
              value={getCodeFromText(areainfo.shadow_area_hand || '', shadowAreaCodes)}
              name={`${requestParamName}[shadow_area_hand][]`}
              id={`${requestParamName}_shadow_area_hand`}
              options={shadowAreaCodes.map((code) => ({
                value: code.value,
                text: code.text,
              }))}
              hasBlank
              blankLabel="(未設定)"
              prefix="日影規制"
              onChange={(e) =>
                onChangeData(
                  e.target.value ? getTextFromCode(Number(e.target.value), shadowAreaCodes) : null,
                  index,
                  'shadow_area_hand'
                )
              }
            />
          </div>
        </div>
        <div className="py-1 flex gap-4 flex-col md:flex-row">
          <div className="relative md:w-[300px] flex-shrink-0">
            <Input
              multiple
              className="w-full"
              value={areainfo.building_coverage_ratio_hand}
              name={`${requestParamName}[building_coverage_ratio_hand][]`}
              id={`${requestParamName}_building_coverage_ratio_hand`}
              prefix="建ぺい率"
              suffix="%"
              onChange={(e) => onChangeData(e.target.value, index, 'building_coverage_ratio_hand')}
              onBlur={(e) =>
                onChangeData(
                  dataPrecisionSetup(e.target.value, 5, 2),
                  index,
                  'building_coverage_ratio_hand'
                )
              }
            />
          </div>
          <div className="relative md:w-[300px] flex-shrink-0">
            <Input
              multiple
              className="w-full "
              value={areainfo.floor_area_ratio_hand}
              name={`${requestParamName}[floor_area_ratio_hand][]`}
              id={`${requestParamName}_floor_area_ratio_hand`}
              prefix="容積率"
              suffix="%"
              onChange={(e) => onChangeData(e.target.value, index, 'floor_area_ratio_hand')}
              onBlur={(e) =>
                onChangeData(
                  dataPrecisionSetup(e.target.value, 6, 2),
                  index,
                  'floor_area_ratio_hand'
                )
              }
            />
          </div>
        </div>
        {index === 0 && (
          <div className="relative md:w-[300px] flex-shrink-0">
            <Input
              className="w-full"
              value={areainfo.other_info}
              name={`${requestParamName}[other_info][]`}
              id={`${requestParamName}_other_info`}
              prefix="その他"
              onChange={(e) => onChangeOtherInfo(e.target.value)}
            />
          </div>
        )}
        <div className="flex gap-2 mt-2">
          {data?.area_info_hands.length !== 1 && (
            <Button
              outline
              variant="red"
              size="small"
              onClick={(e) => onCLickDeleteAreaInfoBlock(e)}
            >
              削除
            </Button>
          )}
          {data?.area_info_hands.length === index + 1 && (
            <Button outline size="small" onClick={(e) => onClickAddAreaInfoBlock(e)}>
              追加
            </Button>
          )}
        </div>
      </Td>
    </Row>
  )
}
