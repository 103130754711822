import * as React from 'react'
import Base from '@/components/Layout/Base'
import { Table } from '@/components/Table'
import type { User } from '@/types/user'
import type { Company } from '@/types/company'
import { pricingType } from '@/config/langs/company'
import { Breadcrumb } from '@/components/Breadcrumb'

type Props = {
  current_user: User
  company: Company
}

const formatZipCode = (code: string): string => {
  return code.slice(0, 3) + '-' + code.slice(3, code.length)
}

const CompaniesDetailPage: React.FC<Props> = ({ current_user, company }) => {
  // バリデーションに引っかかった場合に文字列を配列に変換する
  const global_ip = Array.isArray(company.global_ip)
    ? company.global_ip
    : String(company.global_ip)
        .replace(/[[\]\"]/g, '')
        .split(',')
  const title = '企業詳細'

  return (
    <Base
      current_user={current_user}
      title={title}
      breadcrumb={<Breadcrumb link_texts={['利用企業設定', '企業詳細']} />}
      submenuCategory="settings"
    >
      <Table className="mt-8">
        <tbody>
          <tr className="bg-gray-light">
            <th
              scope="row"
              className="py-5 px-4 md:text-right whitespace-nowrap text-black-base font-medium w-1/4"
            >
              会社名
            </th>
            <td className="py-2 px-4 w-3/4">{company.name}</td>
          </tr>
          <tr className="bg-white">
            <th
              scope="row"
              className="py-5 px-4 md:text-right whitespace-nowrap text-black-base font-medium w-1/4"
            >
              郵便番号
            </th>
            <td className="py-2 px-4 w-3/4">{formatZipCode(String(company.zipcode))}</td>
          </tr>
          <tr className="bg-gray-light">
            <th
              scope="row"
              className="py-5 px-4 md:text-right whitespace-nowrap text-black-base font-medium w-1/4"
            >
              都道府県
            </th>
            <td className="py-2 px-4 w-3/4">{company.prefecture}</td>
          </tr>
          <tr className="bg-white">
            <th
              scope="row"
              className="py-5 px-4 md:text-right whitespace-nowrap text-black-base font-medium w-1/4"
            >
              市区町村
            </th>
            <td className="py-2 px-4 w-3/4">{company.city}</td>
          </tr>
          <tr className="bg-gray-light">
            <th
              scope="row"
              className="py-5 px-4 md:text-right whitespace-nowrap text-black-base font-medium w-1/4"
            >
              その他住所
            </th>
            <td className="py-2 px-4 w-3/4">{company.address}</td>
          </tr>
          <tr className="bg-white">
            <th
              scope="row"
              className="py-5 px-4 md:text-right whitespace-nowrap text-black-base font-medium align-top w-1/4"
            >
              グローバルIPアドレス
            </th>
            <td className="py-2 pb-5 px-4 w-3/4">
              {global_ip.map((ip, ipIndex) => (
                <div key={ipIndex} className="mt-3">
                  {ip}
                </div>
              ))}
            </td>
          </tr>
          <tr className="bg-gray-light">
            <th
              scope="row"
              className="py-5 px-4 md:text-right whitespace-nowrap text-black-base font-medium w-1/4"
            >
              電話番号
            </th>
            <td className="py-2 px-4 w-3/4">{company.phone_number}</td>
          </tr>
          <tr className="bg-white">
            <th
              scope="row"
              className="py-5 px-4 md:text-right whitespace-nowrap text-black-base font-medium w-1/4"
            >
              料金タイプ
            </th>
            <td className="py-2 px-4 w-3/4">
              <div className="flex gap-4">{pricingType[company.pricing_type]}</div>
            </td>
          </tr>
          <tr className="bg-gray-light">
            <th
              scope="row"
              className="py-5 px-4 md:text-right whitespace-nowrap text-black-base font-medium w-1/4"
            >
              入手先の表示
            </th>
            <td className="py-2 px-4 w-3/4">
              <div className="flex gap-4">{company.hide_source ? '非表示' : '表示'}</div>
            </td>
          </tr>
        </tbody>
      </Table>
    </Base>
  )
}

export default CompaniesDetailPage
