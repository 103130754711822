import * as React from 'react'

interface Props extends Omit<React.InputHTMLAttributes<HTMLInputElement>, 'prefix'> {
  id?: string
  suffix?: React.ReactNode
  prefix?: React.ReactNode
  maxCalendarYear?: number
}

export const Input: React.FC<Props> = React.forwardRef<HTMLInputElement, Props>(({
  type = 'text',
  id,
  name,
  placeholder = '',
  required = false,
  className,
  suffix,
  prefix,
  readOnly,
  onChange,
  datalist,
  list,
  maxCalendarYear,
  ...props
}, refArg) => {
  const ref: React.MutableRefObject<HTMLInputElement> = refArg ? refArg : React.useRef<HTMLInputElement>(null)

  let calendarYearProps = {}

  // maxCalendarYearの年数分まで表示する
  if (maxCalendarYear && !props.max) {
    const onClickCalendar = (e) => {
      const value = ref.current.value
      const currentYear = (value ? new Date(value) : new Date()).getFullYear()
      ref.current.max =
        type === 'datetime-local'
          ? `${currentYear + maxCalendarYear}-12-31T23:59`
          : `${currentYear + maxCalendarYear}-12-31`
    }

    const onClick = props.onClick
      ? (e) => {
          props.onClick(e)
          onClickCalendar(e)
        }
      : onClickCalendar

    const onBlurCalendar = (e) => {
      ref.current.max = null
    }

    const onBlur = props.onBlur
      ? (e) => {
          props.onBlur(e)
          onBlurCalendar(e)
        }
      : onBlurCalendar

    calendarYearProps = {
      onClick: onClick,
      onBlur: onBlur,
    }
  }

  return (
    <>
      {ref?.current?.validationMessage && <div className='text-red'>{ref.current?.validationMessage}</div> }
      <div className={[styles.inputWrap, className].join(' ')}>
        {prefix && <span className={[styles.box, 'rounded-r-none'].join(' ')}>{prefix}</span>}
        <input
          ref={ref}
          type={type}
          id={id}
          name={name}
          placeholder={placeholder}
          required={required}
          className={[
            styles.input,
            prefix && styles.prefix,
            suffix && styles.suffix,
            readOnly && 'bg-gray-100 pointer-events-none',
          ].join(' ')}
          onChange={onChange}
          onInvalid={e => e.preventDefault()}
          list={list}
          {...props}
          {...calendarYearProps}
        />
        {suffix && <span className={[styles.box, 'rounded-l-none'].join(' ')}>{suffix}</span>}
        {datalist && (
          <datalist id={list}>
            {datalist.map((data) => (
              <option value={data} />
            ))}
          </datalist>
        )}
      </div>
    </>
  )
})

const styles = {
  inputWrap: `
    relative
    flex
    items-strech
  `,
  input: `
    flex
    flex-auto
    border
    rounded-sm
    bg-white
    outline-none
    py-2
    px-4
    text-sm
    leading-normal
    text-black-base
    border-gray-border
    placeholder-gray-250
    placeholder:text-sm
    disabled:text-gray-300
    disabled:bg-gray-light
    disabled:cursor-not-allowed
    disabled:opacity-70
    invalid:border-red
  `,
  suffix: `
    w-full
    rounded-r-none
    border-r-0
    text-sm
  `,
  prefix: `
    w-full
    rounded-l-none
    border-l-0
    text-sm
  `,
  box: `
    flex
    items-center
    border
    border-gray-border
    rounded-sm
    bg-gray-100
    py-2
    px-4
    text-sm
    leading-normal
    text-black-base
    whitespace-nowrap
  `,
}
