import * as React from 'react'

interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  text: string
}
const Heading: React.FC<Props> = ({ text, ...other }) => (
  <button className="rounded-md bg-indigo-600 py-2 px-3 my-4 mx-4" {...other}>
    {text}
  </button>
)

export default Heading
