import * as React from 'react'
import TasukiTechLogo from 'tasuki_tech_logo.png'
import { CaretDownIcon, ListIcon } from '@/components/SvgIcon'
import { Menu, Transition } from '@headlessui/react'
import { MobileMenu } from '@/components/MobileMenu'
import { UserContext } from '@/components/Layout/Base'
import { ArrowRightIcon } from '@/components/SvgIcon'
import { roleType } from '@/config/langs/user'
import { OCRLogout } from '@/components/Ocr/auth/token'
import { AnnouncementsModal } from '@/components/AnnouncementsModal/AnnouncementsModal'
import { AnnouncementsEditModal } from '@/components/AnnouncementsEditModal/AnnouncementsEditModal'

type Props = {
  title: string
  spMenuOpen?: boolean
  setSpMenuOpen?: (value: boolean) => void
  city: string
}

const Header: React.FC<Props> = ({ title, spMenuOpen, setSpMenuOpen, city }) => {
  return (
    <>
      <DesktopHeader title={title} />
      <MobileHeader title={title} spMenuOpen={spMenuOpen} setSpMenuOpen={setSpMenuOpen} city={city} />
    </>
  )
}
export default Header

const DesktopHeader: React.FC<Props> = ({ title }) => {
  const { user } = React.useContext(UserContext)
  const [refreshAnnouncements, setRefreshAnnouncements] = React.useState(false)
  const handleAnnouncementUpdate = () => {
    setRefreshAnnouncements(true)
  }
  return (
    <header className="hidden md:flex items-center pl-14 pr-6 py-1">
      <img
        className="w-[7.5rem] h-auto mr-16"
        src={user?.logo_image ? user?.logo_image : TasukiTechLogo}
        alt="Tasuki Tech"
      />
      <span className="text-gray-300 text-lg">{title}</span>
      <div className="ml-auto flex items-center">
        {user.pricing_type === 'owner' && user.role === 'system_admin' && (
          <div className="mt-[-1px] mr-4">
            <AnnouncementsEditModal onAnnouncementUpdate={handleAnnouncementUpdate} />
          </div>
        )}
        <div className="mt-[-1px] mr-2">
          <AnnouncementsModal refresh={refreshAnnouncements} setRefresh={setRefreshAnnouncements} />
        </div>
        <UserMenu />
      </div>
    </header>
  )
}

const MobileHeader: React.FC<Props> = ({ title, spMenuOpen, setSpMenuOpen, city }) => {
  const isOpen = spMenuOpen
  const { user } = React.useContext(UserContext)
  const [refreshAnnouncements, setRefreshAnnouncements] = React.useState(false)
  const handleAnnouncementUpdate = () => {
    setRefreshAnnouncements(true)
  }
  return (
    <div className={['md:hidden w-full', isOpen && 'fixed z-20'].join(' ')}>
      <header className="flex md:hidden items-center px-4 py-3">
        <img
          className="w-[5.5rem] h-auto mr-4"
          src={user?.logo_image ? user?.logo_image : TasukiTechLogo}
          alt="Tasuki Tech"
        />
        <span className="text-gray-300 text-lg pl-2">{title}</span>
        <div className="ml-auto flex items-center">
          {user.pricing_type === 'owner' && user.role === 'system_admin' && (
            <div className="mt-[-2px] mr-3">
              <AnnouncementsEditModal onAnnouncementUpdate={handleAnnouncementUpdate} />
            </div>
          )}
          <div className="mt-[-2px]">
            <AnnouncementsModal
              refresh={refreshAnnouncements}
              setRefresh={setRefreshAnnouncements}
            />
          </div>
          <div
            className="ml-4"
            onClick={() => {
              setSpMenuOpen(!isOpen)
            }}
          >
            <ListIcon className={['', isOpen && 'hidden'].join(' ')} fill="#616569" size={32} />
            <ArrowRightIcon
              className={['', !isOpen && 'hidden'].join(' ')}
              fill="#616569"
              size={32}
            />
          </div>
        </div>
      </header>
      <div
        className={[
          'md:hidden w-full',
          isOpen && 'bg-black/40 duration-100 w-screen z-20 h-screen',
        ].join(' ')}
      >
        <MobileMenu
          city={city}
          className={['h-[calc(100vh_-_72px)] overflow-y-scroll'].join(' ')}
          isOpen={isOpen}
        />
      </div>
    </div>
  )
}

type MenuProps = {
  className?: string
}

const UserMenu: React.FC<MenuProps> = ({ className }) => {
  const { user } = React.useContext(UserContext)

  return (
    <Menu as="div" className={[className, 'relative z-10 text-left'].join(' ')}>
      <div>
        <Menu.Button className="inline-flex w-full justify-center items-center gap-2 px-4 py-[15px] text-gray-300 text-sm">
          {user.name}（{roleType[user.role]}）
          <CaretDownIcon fill="#6C757D" />
        </Menu.Button>
      </div>
      <Transition
        as={React.Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0"
        enterTo="transform opacity-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100"
        leaveTo="transform opacity-0"
      >
        <Menu.Items className="absolute right-0 w-48 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5">
          <div className="py-2 text-gray-700">
            <Menu.Item>
              {({ active }) => (
                <a
                  href={`/users/${user.id}/edit`}
                  className={`${
                    active ? 'bg-gray-light' : 'text-brack-base'
                  } w-full text-left text-sm px-4 py-3.5 leading-none inline-block`}
                >
                  アカウント設定
                </a>
              )}
            </Menu.Item>
            {user.pricing_type !== 'owner' && (
              <Menu.Item>
                {({ active }) => (
                  <a
                    href={`/file_capacities`}
                    className={`${
                      active ? 'bg-gray-light' : 'text-brack-base'
                    } w-full text-left text-sm px-4 py-3.5 leading-none inline-block`}
                  >
                    ファイル容量確認
                  </a>
                )}
              </Menu.Item>
            )}
            <Menu.Item>
              {({ active }) => (
                <a
                  href={`/static_pages/privacy`}
                  className={`${
                    active ? 'bg-gray-light' : 'text-brack-base'
                  } w-full text-left text-sm px-4 py-3.5 leading-none inline-block`}
                >
                  プライバシーポリシー
                </a>
              )}
            </Menu.Item>
            <Menu.Item>
              {({ active }) => (
                <a
                  href={`/static_pages/terms_of_service`}
                  className={`${
                    active ? 'bg-gray-light' : 'text-brack-base'
                  } w-full text-left text-sm px-4 py-3.5 leading-none inline-block`}
                >
                  利用規約
                </a>
              )}
            </Menu.Item>
            <Menu.Item>
              {({ active }) => (
                <a
                  href={`/static_pages/user_manual`}
                  className={`${
                    active ? 'bg-gray-light' : 'text-brack-base'
                  } w-full text-left text-sm px-4 py-3.5 leading-none inline-block`}
                >
                  利用マニュアル
                </a>
              )}
            </Menu.Item>
            <Menu.Item>
              {({ active }) => (
                <a
                  href={`https://zised.ai/`}
                  className={`${
                    active ? 'bg-gray-light' : 'text-brack-base'
                  } w-full text-left text-sm px-4 py-3.5 leading-none inline-block`}
                >
                  運営会社
                </a>
              )}
            </Menu.Item>
            <Menu.Item>
              {({ active }) => (
                <a
                  data-method="delete"
                  href="/users/sign_out"
                  className={`${
                    active ? 'bg-gray-light' : 'text-brack-base'
                  } w-full text-left text-sm px-4 py-3.5 leading-none inline-block`}
                  onClick={OCRLogout}
                >
                  ログアウト
                </a>
              )}
            </Menu.Item>
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  )
}
