import { Breadcrumb } from '@/components/Breadcrumb'
import { AnchorButton, Button } from '@/components/Button'
import Base from '@/components/Layout/Base'
import { Modal } from '@/components/Modal'
import ProposalsModal from '@/components/Page/Proposals/ProposalsModal'
import { Pagination } from '@/components/Pagination'
import { Select } from '@/components/Select'
import { Table } from '@/components/Table'
import { tagColorCodes } from '@/models/tagColorCodes'
import { Pagy } from '@/types/pagy'
import { Tag } from '@/types/tag'
import { User } from '@/types/user'
import CloudDownloadIcon from '@material-ui/icons/CloudDownload'
import { ellipsisString } from '@/utils/ellipsisString'
import * as React from 'react'
import './proposals.css'

type Props = {
  current_user: User
  department_options: { value: string; label: string }[]
  user_options: { value: string; label: string }[]
  tag_options: { value: string; text: string }[]
  business_type_options: { value: string; text: string }[]
  prefecture_options: { value: string; label: string }[]
  table_columns: string[]
  proposals: [{ path: string; contains: string[]; editable: boolean }]
  pagy: Pagy
}

const ProposalsIndexPage: React.FC<Props> = ({
  current_user,
  table_columns,
  proposals,
  user_options,
  department_options,
  tag_options,
  business_type_options,
  prefecture_options,
  pagy,
}) => {
  const title = '入手情報一覧'
  const csvPath = `${location.pathname}.csv${location.search}`
  const searchParams = new URLSearchParams(location.search)
  const tagIndex = table_columns.indexOf('タグ')
  const noteIndex = table_columns.indexOf('備考')

  return (
    <Base
      current_user={current_user}
      title={title}
      breadcrumb={<Breadcrumb link_texts={['入手情報一覧']} />}
    >
      <div className="flex flex-wrap items-end justify-between m-1">
        <div className="flex flex-wrap items-center gap-1 whitespace-nowrap">
          <span className="text-primary-font text-xl font-medium">
            {pagy.count.toLocaleString()}
          </span>
          <span className="text-gray-800 text-sm mt-0.5">
            件中 {pagy.from}～{pagy.to}件 表示
          </span>
          <span className="text-gray-800 text-sm mt-0.5 ml-2">表示件数：</span>
          <Select
            className="w-[100px] h-9"
            options={[
              {
                text: '20件',
                value: 20,
              },
              {
                text: '50件',
                value: 50,
              },
              {
                text: '100件',
                value: 100,
              },
            ]}
            value={searchParams.get('page_length') || '20'}
            onChange={(e) => {
              const searchParams = new URLSearchParams(location.search)
              searchParams.set('page_length', e.target.value)
              location.search = searchParams.toString()
            }}
          />
          <span className="ml-2">
            <Pagination pagy={pagy} />
          </span>
        </div>

        <div className="flex ml-auto gap-1 pt-1 md:gap-2 md:pt-0">
          <ProposalsModal
            title="条件絞り込み"
            department_options={department_options}
            user_options={user_options}
            tag_options={tag_options}
            business_type_options={business_type_options}
            prefecture_options={prefecture_options}
          />

          <AnchorButton
            className="text-primary-font"
            prefix={<CloudDownloadIcon fontSize="small" />}
            outline
            size="small"
            variant="primary"
            href={csvPath}
          >
            <span className="hidden md:inline">CSVダウンロード</span>
          </AnchorButton>
        </div>
      </div>
      <Table
        className={
          window.innerHeight > 920
            ? 'h-[760px] md:h-[760px]'
            : 'h-[calc(100vh_-_145px)] md:h-[calc(100vh_-_155px)]'
        }
        topScrollbar={window.innerWidth > 768}
      >
        <thead className="bg-gray-150 text-sm sticky top-0 z-10">
          <tr className="whitespace-nowrap">
            {table_columns.map((column, index) => {
              if (index === 1) {
                return
              }

              return (
                <th
                  key={index}
                  scope="col"
                  className={[
                    'py-2 px-4 font-medium',
                    index === 0 && 'md:sticky md:left-0 md:bg-gray-150',
                  ].join(' ')}
                >
                  {index === 0 ? (
                    <>
                      {table_columns[0]} <span className="pl-2">{table_columns[1]}</span>
                    </>
                  ) : (
                    column
                  )}
                </th>
              )
            })}
          </tr>
        </thead>
        <tbody className="whitespace-nowrap">
          {proposals.map((proposal, rowIndex) => (
            <tr
              key={rowIndex}
              className={`${
                rowIndex % 2
                  ? 'bg-gray-light [&>td:first-child]:hover:bg-targetLine'
                  : 'bg-white [&>td:first-child]:hover:bg-targetLine'
              } hover:bg-targetLine cursor-pointer`}
            >
              {proposal.contains.map((item: string | number, index: React.Key) => {
                if (index === 1) {
                  return
                } else if (index === tagIndex) {
                  return (
                    <td
                      key={index}
                      className={[
                        'text-sm',
                        index === 0 &&
                          'md:sticky md:left-0 md:overflow-hidden md:overflow-ellipsis md:max-w-[280px]',
                        index === 0 ? (rowIndex % 2 ? 'bg-gray-light' : 'bg-white') : '',
                      ].join(' ')}
                    >
                      <TagItem
                        tags={current_user.company?.tags || []}
                        currentTagName={item.toString()}
                        editable={proposal.editable || false}
                        path={proposal.path}
                      />
                    </td>
                  )
                } else if (index === noteIndex) {
                  return (
                    <td
                      key={index}
                      className={[
                        'text-sm',
                        index === 0 &&
                          'md:sticky md:left-0 md:overflow-hidden md:overflow-ellipsis md:max-w-[280px]',
                        index === 0 ? (rowIndex % 2 ? 'bg-gray-light' : 'bg-white') : '',
                      ].join(' ')}
                    >
                      <div
                        key={index}
                        className={'whitespace-pre-wrap break-all text-left mr-2 min-w-[400px]'}
                      >
                        {ellipsisString(item.toString(), 200)}
                      </div>
                    </td>
                  )
                }
                return (
                  <td
                    key={index}
                    className={[
                      'text-sm',
                      index === 0 &&
                        'md:sticky md:left-0 md:overflow-hidden md:overflow-ellipsis md:max-w-[280px]',
                      index === 0 ? (rowIndex % 2 ? 'bg-gray-light' : 'bg-white') : '',
                    ].join(' ')}
                  >
                    <a
                      href={`${proposal.path}?tab=property_details`}
                      className={[
                        'block',
                        'w-full',
                        'h-full',
                        'py-2',
                        'px-4',
                        index === 2 ? 'text-ellipsis' : '',
                      ].join(' ')}
                    >
                      {index === 0 ? (
                        <>
                          {proposal.contains[0]}.{' '}
                          <span className="pl-2">{proposal.contains[1]}</span>
                        </>
                      ) : item != null && item.toString() !== '' ? (
                        item
                      ) : (
                        '　'
                      )}
                    </a>
                  </td>
                )
              })}
            </tr>
          ))}
        </tbody>
      </Table>
    </Base>
  )
}

const TagItem = ({
  tags,
  currentTagName,
  editable,
  path,
}: {
  tags: Tag[]
  currentTagName: string
  editable: boolean
  path: string
}) => {
  const [tagChangeModal, setTagChangeModal] = React.useState(false)
  const tagColor = '#FFF'
  const [tag, setTag] = React.useState<Tag | undefined>()
  const tagChangeFormRef = React.useRef<HTMLFormElement>(null)

  const changeTagCallback = React.useCallback((event: React.ChangeEvent<HTMLSelectElement>) => {
    const tagId = Number(event.target.value)
    const tag = tags.find((t) => t.id === tagId)
    setTag(tag)
    setTagChangeModal(true)
  }, [])

  const onTagChangeModalClose = React.useCallback(() => {
    setTagChangeModal(false)
    const tag = tags.find((t) => t.name === currentTagName)
    setTag(tag)
  }, [tagChangeModal])

  return (
    <>
      {editable && (
        <select
          name="property[tag_id]"
          onChange={changeTagCallback}
          className={[
            'my-2 empty:hidden inline-flex text-sm ml-2 px-3 py-[4px] rounded-full border-[1px] border-[#EEE] border-transparent text-black',
            tagColor,
          ].join(' ')}
          value={tag?.id}
        >
          <option value="">タグ未設定</option>
          {tags.map((t) => (
            <option
              key={t.id}
              value={t.id}
              className={tagColorCodes[t.id]?.bg}
              selected={currentTagName == t.name}
            >
              {t.name}
            </option>
          ))}
        </select>
      )}
      {!editable && (
        <span
          className={[
            'my-2 empty:hidden inline-flex text-sm ml-2 px-3 py-[1px] rounded-xl border-[2px] border-transparent',
            tagColor,
          ].join(' ')}
        >
          {currentTagName ? currentTagName : 'タグ未設定'}
        </span>
      )}

      <Modal open={tagChangeModal} onClose={onTagChangeModalClose}>
        <div className="px-6 py-6 leading-10">
          タグを{currentTagName && `${currentTagName}から`}
          {tag?.name}変更します。よろしいですか？
        </div>

        <form
          action={path}
          acceptCharset="UTF-8"
          method="post"
          encType="multipart/form-data"
          ref={tagChangeFormRef}
        >
          <input
            type="hidden"
            name="authenticity_token"
            value={document.querySelector<HTMLMetaElement>('meta[name=csrf-token]')?.content}
          />
          <input type="hidden" name="_method" value={'patch'} />
          <input type="hidden" name="property[tag_id]" value={tag?.id} />
          <input
            type="hidden"
            name="redirect_to"
            value={window.location.pathname + window.location.search}
          />
        </form>

        <AnchorButton
          className="text-white md:w-[120px] w-full h-[36px]"
          size="small"
          variant="primary"
          type="submit"
          onClick={() => {
            tagChangeFormRef.current?.submit()
          }}
        >
          はい
        </AnchorButton>
        <Button
          className="text-primary-deep border-white md:w-[120px] w-full h-[36px]"
          outline
          size="small"
          variant="primary"
          onClick={onTagChangeModalClose}
        >
          閉じる
        </Button>
      </Modal>
    </>
  )
}

export default ProposalsIndexPage
