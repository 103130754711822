import * as React from 'react'

type Props = {}

const StaticPagePrivacyPage: React.FC<Props> = ({}) => {
  const title = 'プライバシーポリシー'

  return (
    <div>
      <header className="hidden md:flex items-center pl-14 pr-6 py-1">
        <img
          className="w-[7.5rem] h-auto mr-16"
          src="/packs/media/images/tasuki_tech_logo-8a313e2c796da53b8c2ec442ad7e47eb.png"
          alt="Tasuki Tech"
        />
        <span className="text-gray-300 text-lg">{title}</span>
        <div className="ml-auto relative z-10 text-left"></div>
      </header>
      <div className="md:hidden w-full false">
        <header className="flex md:hidden items-center px-4 py-3">
          <img
            className="w-[5.5rem] h-auto mr-4"
            src="/packs/media/images/tasuki_tech_logo-8a313e2c796da53b8c2ec442ad7e47eb.png"
            alt="Tasuki Tech"
          />
          <span className="text-gray-300 text-lg pl-2">プライバシーポリシー</span>
        </header>
      </div>
      <div className="flex flex-auto">
        <div className="pt-1 hidden md:block bg-primary transition-[width] z-0 w-12"></div>
        <div className="w-full false z-0 md:mt-0 md:w-[calc(100vw_-_3rem)]">
          <div className="w-full border-t border-t-gray-200 bg-gray-light pl-6 p-2 md:pl-10 md:p-3">
            <ul className="text-sm select-none">
              <li className="inline-block">
                <a className="font-medium text-primary-font" href="/">
                  HOME
                </a>
                <span className="mx-1.5">/</span>
              </li>
            </ul>
          </div>
          <div className="h-full w-full flex flex-col">
            <main className="flex-1 px-4 py-0 md:px-10 overflow-y-scroll">
              <div className="h-full flex items-center">
                <div className="mx-auto p-4 flex flex-col relative z-10 mb-40">
                  <div className="h-[calc(100vh_-_182px)] md:h-[calc(100vh_-_195px)]">
                    <div className="py-2 md:flex item-center">
                      <div>
                        <h2 className="text-center">TASUKI TECH プライバシーポリシー</h2>
                        <br />
                        <p>
                          当社は、TASUKI
                          TECHサービスを展開していく上で、個人情報の保護、適正な管理が重要な社会的責務であることを認識し、以下のとおりTASUKI
                          TECH プライバシーポリシーを定め、これを実行、維持してまいります。
                        </p>
                        <br />
                        <p>1.　基本方針</p>
                        <p>
                          当社は、個人情報の保護に関する法令と社会秩序を尊重・遵守し、個人情報の適正な取扱いと保護に努めます。
                        </p>
                        <br />
                        <p>2.　個人情報の取得</p>
                        <p>
                          当社は、以下の場合に、利用目的を明確にした上で適切な方法、手段を用いて個人情報を取得します。
                        </p>
                        <p>
                          ①
                          各種取引に伴い、お客様からご提供頂く情報（氏名、メールアドレス、その他当社が定める入力フォームにお客様が入力する情報）
                        </p>
                        <p>
                          ②
                          当社が実施するアンケートや当社へのお問い合わせ等により、お客様からご提供頂く情報
                        </p>
                        <p>
                          ③
                          お客様が当社の運営するサービスを利用するにあたって当社が収集する個人情報等（サービス利用状況、クッキー、アクセスログ、機器情報等を含みます。）を取得
                        </p>
                        <br />
                        <p>3.　個人情報の利用</p>
                        <p>
                          当社は、収集した個人情報を以下の各項の目的でそれぞれ利用することができるものとします。
                        </p>
                        <p>（１） TASUKI TECHサービスのユーザの個人情報</p>
                        <p>
                          ①
                          サービス等の企画立案、開発、提供（保守、監視、サービス利用料の算定を含みますがこれらに限られません。）、改善
                        </p>
                        <p>② 本人確認および認証サービス</p>
                        <p>③ サービス等に関する重要なお知らせ等、必要に応じた連絡</p>
                        <p>④ お問い合わせ、ご依頼等への対応</p>
                        <p>
                          ⑤
                          個人を識別できないように加工した統計情報の作成・分析し、当該統計情報を用いたマーケティング・ニーズの調査、当社サービスの改善や新サービスの開発
                        </p>
                        <p>
                          ⑥
                          当社の商品、サービス、キャンペーン、イベント等のご案内（電話、電子メール、ダイレクトメール等）
                        </p>
                        <p>⑦ サービス等向上のためのアンケートの実施</p>
                        <p>⑧ メールマガジンの送信</p>
                        <p>⑨ お客様の反社会的勢力の該当性についての判断</p>
                        <p>
                          ⑩
                          取得したサービス利用状況、アクセスログ等の情報を分析し、趣味・趣向に応じた商品、サービス等に関する広告を表示するため
                        </p>
                        <p>⑪ その他、上記の利用目的に付随する目的のため</p>
                        <p>（２） 前項以外の者の個人情報</p>
                        <p>TASUKI TECHサービスの保守、運用、監視その他のサービス提供</p>
                        <br />
                        <p>４.　個人情報の第三者への提供</p>
                        <p>
                          当社が保有する個人情報は、以下の場合に、第三者に提供することができるものとします。
                        </p>
                        <p>① ご本人の同意がある場合</p>
                        <p>② 法令によって許されている場合で、お客様との契約の履行に必要な場合</p>
                        <p>③ 保険金請求のために保険会社に開示する場合</p>
                        <p>
                          ④
                          上記３「個人情報の利用」に定める目的の達成に必要な範囲で当社が行う業務の全部または一部を第三者に委託する場合
                        </p>
                        <br />
                        <p>5.　 外国にある第三者への個人情報の提供</p>
                        <p>
                          当社は、クラウドサービス提供事業者その他の日本国外に所在する事業者に対して個人情報を提供する場合があります。この場合、当社は、以下のいずれかに該当する場合を除き、あらかじめお客様の同意を取得するものとします。
                        </p>
                        <p>
                          ①
                          第三者が、日本と同等の水準の個人情報保護制度を有している国として法令に定められる国にある場合
                        </p>
                        <p>
                          ②
                          第三者が、日本の個人情報取扱事業者が講ずべき措置に相当する措置を継続的に講ずるために必要な体制を構築している場合
                        </p>
                        <p>
                          なお、②の場合、当社は、第三者の相当措置の継続的な実施を確保するために必要かつ適切な措置を講じます。当該措置の内容を確認されたい場合は、後記10「個人情報についてのお問合せ窓口」からお問い合わせください。
                        </p>
                        <br />
                        <p>6.　個人情報の安全管理措置</p>
                        <p>
                          当社は、当社が有する個人情報を適正かつ慎重に管理し、個人情報への不正アクセス、漏洩、紛失、改竄等を防止するため、必要かつ適切な安全管理措置を講じます。安全管理措置の概要について確認されたい場合、後記10「個人情報についてのお問合せ窓口」からお問い合わせください。
                        </p>
                        <br />
                        <p>7.　個人情報の取扱いの委託</p>
                        <p>
                          当社が有する個人情報について、その取扱いを外部に委託する場合があります。その場合は、個人情報の保護に十分な措置を講じている者を選定し、委託先に対し、必要かつ適切な監督を行います。
                        </p>
                        <br />
                        <p>8.　Google Analyticsの利用</p>
                        <p>
                          <span>
                            当社は、当社のウェブサイトにおいて、より使いやすく有用な情報サービスをお客様に継続的にご提供するために、アクセス解析ソフトのGoogle
                            Analyticsを利用して、お客様からの訪問回数や訪問時間等の統計データを集計しております。このため、一部のページにおいてGoogle
                            Analyticsから提供されるクッキーを使用しています。この統計データは匿名で収集されており、個人を特定できるものではありません。クッキーの使用に関する説明、クッキーによって収集される情報について詳しくお知りになりたい方は、
                          </span>
                          <a
                            href="https://marketingplatform.google.com/about/analytics/terms/jp/"
                            className="font-medium text-primary-font"
                          >
                            Googleアナリティクス利用規約
                          </a>
                          <span>および</span>
                          <a
                            href="https://policies.google.com/technologies/ads?hl=ja"
                            className="font-medium text-primary-font"
                          >
                            Googleプライバシーポリシー
                          </a>
                          <span>をご確認ください。</span>
                        </p>
                        <p>
                          なお、お客様は、ブラウザのアドオン設定でGoogle
                          Analyticsを無効にすることにより、当社のGoogle
                          Analyticsの利用によるお客様に関する統計データの収集を停止することが可能です。
                        </p>
                        <br />
                        <p>9.　個人情報の開示・訂正等の手続き</p>
                        <p>
                          当社は、ご本人から、個人情報の開示または第三者提供記録の開示を求められたときは、ご本人に対し、遅滞なく開示します。ただし、開示することにより以下のいずれかに該当する場合は、その全部または一部を開示しないこともあり、開示しない決定をした場合には、その旨を遅滞なく通知します。
                        </p>
                        <p>
                          ①
                          ご本人または第三者の生命、身体、財産その他の権利利益を害するおそれがある場合
                        </p>
                        <p>② 当社の業務の適正な実施に著しい支障を及ぼすおそれがある場合</p>
                        <p>③ 他の法令に違反することとなる場合</p>
                        <p>
                          また、お客様ご本人より利用目的の通知、当該本人の個人情報の訂正、追加削除、利用の停止消去、第三者への提供の停止その他の法令上認められる権利に基づく請求を求められた場合には、お客様ご本人であることを確認させていただいた上で合理的な期間内に対応いたします。
                        </p>
                        <p>
                          上記の開示・訂正等のお手続きの詳細につきましては、後記10「個人情報についてのお問合せ窓口」からお問い合わせください。
                        </p>
                        <br />
                        <p>10.　個人情報についてのお問合せ窓口</p>
                        <p>
                          このプライバシーポリシーおよび当社の個人情報の取扱いに関するご意見、苦情、ご質問等はこちらまでご連絡ください。
                        </p>
                        <p>株式会社ZISEDAI</p>
                        <p>〒107-0061東京都港区北青山2-7-9日昭ビル2F</p>
                        <p>TEL : 03-6447-4833（9:00 – 18:00 土日祝日、その他当社休業日を除く）</p>
                        <p>Mail : info@zised.ai</p>
                        <br />
                        <p>11.　プライバシーポリシーの改定について</p>
                        <p>
                          当社は、必要に応じてこのプライバシーポリシーを改定することがあります。このプライバシーポリシーを改定する場合、当社は、変更後のプライバシーポリシーの施行時期および内容を本サービスのウェブサイトへの掲載その他の適切な方法によって周知し、またはお客様に通知します。
                        </p>
                        <br />
                        <p>以　　上</p>
                        <br />
                        <br />
                        <p>制定日：2022年12月12日</p>
                        <p>改定日：2024年4月8日</p>
                        <div className="text-center">
                          <ul className="text-sm select-none">
                            <li className="inline-block">
                              <a className="font-medium text-primary-font" href="/">
                                トップ
                              </a>
                              <span className="mx-1.5">/</span>
                            </li>
                            <li className="inline-block">
                              <a
                                className="font-medium text-primary-font"
                                href="/static_pages/privacy"
                              >
                                プライバシーポリシー
                              </a>
                              <span className="mx-1.5">/</span>
                            </li>
                            <li className="inline-block">
                              <a
                                className="font-medium text-primary-font"
                                href="/static_pages/terms_of_service"
                              >
                                利用規約
                              </a>
                              <span className="mx-1.5">/</span>
                            </li>
                            <li className="inline-block">
                              <a className="font-medium text-primary-font" href="https://zised.ai/">
                                会社情報
                              </a>
                            </li>
                          </ul>
                          <small className="text-muted">&copy; zised.ai</small>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </main>
          </div>
        </div>
      </div>
    </div>
  )
}

export default StaticPagePrivacyPage
