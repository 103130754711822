import * as React from 'react'
import Base from '@/components/Layout/Base'
import type { User } from '@/types/user'
import { AnchorButton, Button } from '@/components/Button'
import AddOutlinedIcon from '@material-ui/icons/AddOutlined'
import { Breadcrumb } from '@/components/Breadcrumb'
import { ConfirmDialog } from '@/components/ConfirmDialog'
import { Modal } from '@/components/Modal'
import UsersModal from '@/components/Page/Users/UsersModal'
import MailOutlineIcon from '@mui/icons-material/MailOutline'
import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { Container } from '@/components/SortableList/User/Container'

type Props = {
  current_user: User
  users: User[]
  company_id?: string
  company_options: { value: string; text: string }[]
  authenticity_token: string
}

const UsersIndexPage: React.FC<Props> = ({
  current_user,
  users,
  company_id,
  company_options,
  authenticity_token,
}) => {
  const title = 'ユーザー設定'

  const [containerUsers, setContainerUsers] = React.useState<User[]>(users)
  const [showConfirmationButton, setShowConfirmationButton] = React.useState<boolean>(false)
  const [dialogOpen, setDialogOpen] = React.useState<boolean>(false)
  const [modalOpen, setModalOpen] = React.useState<boolean>(false)
  const [yesButtonDisabled, setYesButtonDisabled] = React.useState<boolean>(false)
  const [confirmationUserIds, setConfirmationUserIds] = React.useState<number[]>([])
  const [clearAfterConfirmation, setClearAfterConfirmation] = React.useState<boolean>(false)

  const onCloseDialog = (result: boolean) => {
    if (result) {
      setYesButtonDisabled(true)
      setClearAfterConfirmation(false)

      fetch('/users/send_confirmations', {
        method: 'POST',
        body: JSON.stringify({ user_ids: confirmationUserIds }),
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': authenticity_token,
        },
      })
        .then((res) => {
          return res.json()
        })
        .then((json) => {
          if (json.status === 'OK') {
            const users = [...containerUsers]
            const confirmationSentAt = new Date().toLocaleString()

            users.forEach((user) => {
              if (confirmationUserIds.includes(user.id)) {
                user.confirmation_sent_at = confirmationSentAt
              }
            })

            // 選択したユーザを送信済みにする
            setContainerUsers(users)
            // ユーザーの選択を解除
            setConfirmationUserIds([])
            setClearAfterConfirmation(true)

            // 送信後の確認のモーダル
            setModalOpen(true)
          } else if (json.status === 'NG') {
            alert(json.message)
          } else {
            alert('更新に失敗しました')
          }
        })
        .catch((e) => {
          alert('更新に失敗しました')
        })
        .finally(() => {
          setYesButtonDisabled(false)
        })
    }

    setDialogOpen(false)
  }

  const lockedUserCount = React.useMemo(() => {
    return users.filter((user) => user.locked_at).length
  }, [])

  const activeUserCount = React.useMemo(() => {
    return users.length - lockedUserCount
  }, [])

  return (
    <Base
      current_user={current_user}
      title={title}
      breadcrumb={<Breadcrumb link_texts={['ユーザー設定']} />}
      submenuCategory="settings"
    >
      <div className="flex flex-wrap items-end justify-between m-1">
        <div className="text-sm">
          アクティブユーザー数
          <span className="text-primary-font text-xl font-medium ml-2">
            {activeUserCount.toLocaleString()}
          </span>{' '}
          名（総登録数 {users.length.toLocaleString()}名 ー アカウントロック数{' '}
          {lockedUserCount.toLocaleString()}名）
        </div>
        {(current_user.role === 'system_admin' || current_user.role === 'admin') && (
          <div className="flex gap-2">
            {current_user.role === 'system_admin' && (
              <UsersModal title="条件絞り込み" company_options={company_options} />
            )}
            <AnchorButton
              className="text-primary-font"
              prefix={<AddOutlinedIcon fontSize="small" />}
              outline
              size="small"
              variant="primary"
              href="/users/new"
            >
              <span className="hidden md:inline">新規ユーザー登録</span>
            </AnchorButton>
            {showConfirmationButton && (
              <Button
                className="text-primary-font w-[160px]"
                prefix={<MailOutlineIcon fontSize="small" />}
                outline
                size="small"
                variant="primary"
                onClick={() => {
                  if (confirmationUserIds.length === 0) {
                    alert('確認メールを送信したいユーザーをチェックしてください。')
                  } else {
                    setDialogOpen(true)
                  }
                }}
              >
                確認メール送信
              </Button>
            )}
          </div>
        )}
      </div>
      <DndProvider backend={HTML5Backend}>
        <Container
          current_user={current_user}
          users={containerUsers}
          setShowConfirmationButton={setShowConfirmationButton}
          confirmationUserIds={confirmationUserIds}
          setConfirmationUserIds={setConfirmationUserIds}
          clearAfterConfirmation={clearAfterConfirmation}
          setClearAfterConfirmation={setClearAfterConfirmation}
          topScrollbar={true}
        />
      </DndProvider>

      {/* メール送信の確認ダイアログ */}
      <ConfirmDialog
        open={dialogOpen}
        yesButtonDisabled={yesButtonDisabled}
        onClose={onCloseDialog}
      >
        <div className="text-black-base text-sm text-center">
          <p>{`${confirmationUserIds.length}人のユーザーに確認メールを送信します。`}</p>
          <p>よろしいですか？</p>
        </div>
      </ConfirmDialog>

      {/* メール送信のモーダル */}
      <Modal
        open={modalOpen}
        onClose={() => {
          setModalOpen(false)
        }}
        ok={true}
        clear={false}
      >
        <div className="py-6">
          <p className="py-2">確認メールの送信を行いました。</p>
          <p className="py-2">
            送信対象のユーザーが多い場合は送信完了まで時間がかかることがあります。
          </p>
        </div>
      </Modal>
    </Base>
  )
}

export default UsersIndexPage
