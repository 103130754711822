import * as React from 'react'
import { ButtonInner } from './ButtonInner'
import type { BaseProps } from './types'

type ElementProps = Omit<React.AnchorHTMLAttributes<HTMLAnchorElement>, keyof BaseProps>

// eslint-disable-next-line react/display-name
export const AnchorButton = React.forwardRef<HTMLAnchorElement, BaseProps & ElementProps>(
  (
    { children, className, wide, outline, variant, size, prefix, suffix, square, ...props },
    ref
  ) => {
    return (
      <ButtonInner
        as="link"
        className={`cursor-pointer ${className}`}
        wide={wide}
        outline={outline}
        variant={variant}
        size={size}
        prefix={prefix}
        suffix={suffix}
        anchorref={ref}
        square={square}
        {...props}
      >
        {children}
      </ButtonInner>
    )
  }
)
