import * as React from 'react'

import FloodIcon from '@/images/icons/flood.svg'
import TidalIcon from '@/images/icons/tidal.svg'
import LandSlideIcon from '@/images/icons/landslide.svg'
import VolcanoIcon from '@/images/icons/volcano.svg'

export const PropertyHazard: React.FC<{
  hazard_links?: { [key: string]: string }
}> = ({ hazard_links }) => {
  return (
    <div className="mb-2">
      {row('洪水', FloodIcon, hazard_links['flood'])}
      {row('内水', FloodIcon, hazard_links['naisui'])}
      {row('ため池', FloodIcon, hazard_links['tameike'])}
      {row('高潮', TidalIcon, hazard_links['takashio'])}
      {row('津波', TidalIcon, hazard_links['tsunami'])}
      {row('土砂災害', LandSlideIcon, hazard_links['landslide'])}
      {row('火山', VolcanoIcon, hazard_links['volcano'])}
    </div>
  )
}

const row = (type: string, icon, url: string) => {
  const text = `${type}ハザードマップ`

  return (
    <div className="border-b border-gray-150">
      <a className={`flex items-center py-2.5 ${url && 'text-primary'}`} href={url} target="_blank">
        <span className="px-2">
          <img src={icon} alt={type} {...imgStyle} />
        </span>
        <span className="px-2 w-full">{url ? text : `${text}はありません`}</span>
      </a>
    </div>
  )
}

const imgStyle = {
  style: { maxWidth: 'initial' },
  width: '32px',
  height: '32px',
}
