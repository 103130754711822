export function setupLocationButton(map: google.maps.Map, onClick: () => void) {
  const locationButton = document.createElement('button')

  locationButton.textContent = '現在地'
  locationButton.classList.add('custom-map-control-button')
  locationButton.setAttribute('type', 'button')
  locationButton.style.fontSize = '14px'

  map.controls[google.maps.ControlPosition.LEFT_TOP].push(locationButton)

  locationButton.addEventListener('click', onClick)
}