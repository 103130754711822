import * as React from 'react'

type Props = {}

const StaticPageTermsOfServicePage: React.FC<Props> = ({}) => {
  const title = '利用マニュアル'

  return (
    <div>
      <header className="hidden md:flex items-center pl-14 pr-6 py-1">
        <img
          className="w-[7.5rem] h-auto mr-16"
          src="/packs/media/images/tasuki_tech_logo-8a313e2c796da53b8c2ec442ad7e47eb.png"
          alt="Tasuki Tech"
        />
        <span className="text-gray-300 text-lg">{title}</span>
        <div className="ml-auto relative z-10 text-left"></div>
      </header>
      <div className="md:hidden w-full false">
        <header className="flex md:hidden items-center px-4 py-3">
          <img
            className="w-[5.5rem] h-auto mr-4"
            src="/packs/media/images/tasuki_tech_logo-8a313e2c796da53b8c2ec442ad7e47eb.png"
            alt="Tasuki Tech"
          />
          <span className="text-gray-300 text-lg pl-2">利用規約</span>
        </header>
      </div>
      <div className="flex flex-auto">
        <div className="pt-1 hidden md:block bg-primary transition-[width] z-0 w-12"></div>
        <div className="w-full false z-0 md:mt-0 md:w-[calc(100vw_-_3rem)]">
          <div className="w-full border-t border-t-gray-200 bg-gray-light pl-6 p-2 md:pl-10 md:p-3">
            <ul className="text-sm select-none">
              <li className="inline-block">
                <a className="font-medium text-primary-font" href="/">
                  HOME
                </a>
                <span className="mx-1.5">/</span>
              </li>
            </ul>
          </div>
          <div className="h-full w-full flex flex-col">
            <main className="flex-1 px-4 py-0 md:px-10 overflow-y-scroll">
              <div className="h-full flex items-center">
                <div className="mx-auto p-4 flex flex-col relative z-10 mb-40">
                  <div className="h-[calc(100vh_-_182px)] md:h-[calc(100vh_-_195px)]">
                    <div className="py-2 md:flex item-center">
                      <div>
                        <h3 className="text-center">TASUKI TECH　利用マニュアル</h3>
                        <br />
                        <p>1.　用途地域</p>
                        <p>
                          各市区町村が策定した都市計画図（区域区分、用途地域、建ぺい率、容積率、防火地域及び準防火地域、日影規制等）の情報をデータ化して表現しています。
                        </p>
                        <p className="font-bold">
                          ※本データは、おおよそ市街図～住宅地図レベルで利用できます。
                        </p>
                        <p>　　1-1.　提供地区</p>
                        <p>　　　本データは、契約地区以外も含め全国で利用可能です。</p>
                        <p>　　1-2.　注意事項</p>
                        <p>
                          　　　用途地域データ（以下、本データという）については、下記の事項を十分にご確認いただき、ご了解の上ご使用ください。
                        </p>
                        <p>
                          　　　①
                          本データはデータ提供会社が各市区町村発行の都市計画図をトレースして各種属性情報を入力しています。そのため、データ作成時、調写の際の入力による「誤記」、あるいは「位置ズレ」が発生する事があります。また、基本的に都市計画図発行時点のデータであるため、現況とは異なる場合があります。
                        </p>
                        <p>
                          　　　②
                          市区町村ごとに、発行する都市計画図の地図縮尺は異なります。都市計画道路線や用途地域界が住宅地図と一致しないケースがあります。
                          また、地図縮尺が異なる地区が隣接した地域では、用途地域界が接合しない場合があります。
                        </p>
                        <p>
                          　　　③
                          時点の違いにより、各市区町村発行の都市計画図の用途地域情報とは一致しない場合があります。
                        </p>
                        <p>
                          　　　④
                          用途地域界、区域区分界は東京都都市整備局の都市計画決定情報GISデータと各市区町村発行都市計画図のトレースしたデータを採用しており、各市区町村発行の都市計画図と異なる場合があります。
                        </p>
                        <p>
                          　　　⑤
                          行政界は基本的に東京都都市整備局の都市計画決定情報GISデータと各市区町村発行都市計画図のトレースデータを採用しており、各市区町村発行の都市計画図と異なる場合があります。
                          これにより、境界線付近の都市計画情報を、隣接する市区町村の都市計画図から取得している場合があります。
                        </p>
                        <p>
                          　　　⑥
                          路線防火地域は、道路を含め防火地域として作成しているため、実際の都市計画図と異なる場合があります。
                        </p>
                        <p>
                          　　　⑦ 建ぺい率及び容積率は、用途地域の指定による値を収録しています。
                        </p>
                        <p>　　　⑧ 本データ提供地区のご契約地区のみ閲覧いただけます。</p>
                        <p>
                          　　　⑨
                          本データを使用したことにより、使用者及び第三者に何らかの損害が生じたとしても、
                          弊社及びデータ提供会社はいかなる場合においても、損害賠償その他の一切の責任を負いません。
                        </p>
                        <br />
                        <p>2.　ハザード情報</p>
                        <p>
                          国や都道府県等の関係各機関が作成した災害リスク情報をデータ化して表現しています。
                        </p>
                        <p>
                          本データは、国土交通省国土地理院様が管理する
                          <a
                            href="https://disaportal.gsi.go.jp/maps/index.html?ll=35.690903,139.838791&z=12&base=pale&vs=c1j0l0u0t0h0z0"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="font-medium text-primary-font"
                          >
                            「重ねるハザードマップ」のデータ
                          </a>
                          を使用しています。
                        </p>
                        <p className="font-bold">
                          ※本データは、おおよそ市街図～住宅地図レベルで利用できます。
                        </p>
                        <p>　　2-1　提供地区</p>
                        <p>　　　本データは、契約地区以外も含め全国で利用可能です。</p>
                        <p>
                          　　　詳しくは、
                          <a
                            href="https://disaportal.gsi.go.jp/hazardmap/copyright/opendata.html"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="font-medium text-primary-font"
                          >
                            「重ねるハザードマップ」のコンテンツ情報
                          </a>
                          を参照ください。
                        </p>
                        <p>　　2-2　注意事項</p>
                        <p>
                          　　　ハザード情報（以下、本データという）については、下記の事項を十分にご確認いただき、ご了解の上ご使用ください。
                        </p>
                        <p>
                          　　　本データの利用にあたっては、重ねるハザードマップの利用規約（本データ提供元の関係各機関が個別に定める利用規約を含む）に基づき、ユーザーご自身の判断に基づいて参考情報としてご活用ください。
                        </p>
                        <p>
                          　　　本データは、整備状況により一部地区が表示されないことがあります。
                        </p>
                        <p>
                          　　　本データは、重ねるハザードマップから配信されているため、予告なく配信中断・停止される場合があります。
                        </p>
                        <p>
                          　　　本データを使用したことにより、使用者及び第三者に何らかの損害が生じたとしても、
                          弊社及びデータ提供機関はいかなる場合においても、損害賠償その他の一切の責任を負いません。
                        </p>
                        <p className="font-bold">
                          　　　※詳しくは
                          <a
                            href="https://disaportal.gsi.go.jp/hazardmap/copyright/copyright.html"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="font-bold text-primary-font"
                          >
                            「重ねるハザードマップ」の利用規約
                          </a>
                          を参照ください。
                        </p>
                        <br />
                        <p>3.　API連携</p>
                        <p>HeartRails Express</p>
                        <br />
                        <p>改定日：2023年8月1日</p>
                        <p>株式会社ZISEDAI</p>
                        <br />
                        <p>所在地：　〒107-0061東京都港区北青山2-7-9日昭ビル2F</p>
                        <p>会社名：　株式会社ZISEDAI</p>
                        <p>代表者：　代表取締役社長　柏村　雄</p>
                        <br />
                        <br />
                        <div className="text-center">
                          <ul className="text-sm select-none">
                            <li className="inline-block">
                              <a className="font-medium text-primary-font" href="/">
                                トップ
                              </a>
                              <span className="mx-1.5">/</span>
                            </li>
                            <li className="inline-block">
                              <a
                                className="font-medium text-primary-font"
                                href="/static_pages/privacy"
                              >
                                プライバシーポリシー
                              </a>
                              <span className="mx-1.5">/</span>
                            </li>
                            <li className="inline-block">
                              <a
                                className="font-medium text-primary-font"
                                href="/static_pages/terms_of_service"
                              >
                                利用規約
                              </a>
                              <span className="mx-1.5">/</span>
                            </li>
                            <li className="inline-block">
                              <a className="font-medium text-primary-font" href="https://zised.ai/">
                                会社情報
                              </a>
                            </li>
                          </ul>
                          <small className="text-muted">&copy; zised.ai</small>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </main>
          </div>
        </div>
      </div>
    </div>
  )
}

export default StaticPageTermsOfServicePage
