import * as React from 'react'
import { API, GRAPHQL_AUTH_MODE, GraphQLSubscription } from '@aws-amplify/api'
import {
  OnUpdateJobSubscription,
  Status as OcrJobStatus,
  ModelSubscriptionJobFilterInput,
} from '@/api/ocr/aws/src/API'
import { onUpdateJob } from '@/api/ocr/aws/src/graphql/subscriptions'
import { SubscriptionRequestParams } from '@/components/Ocr/api/params'

export type ocrExecution = {
  extractedItems: { [key: string]: any }
  jobId: string
  jobStatus: 'preview' | 'fileUpload' | OcrJobStatus | 'finish' | 'validationError' | 'uploadOnly'
  localFile: File
  localFileValidity: Boolean
  blobSignedId: string
  remoteFileKey: string
  remoteFileURL: string // URL?
  blobOcrSignedId: string
  remoteOcrFileKey: string
  remoteOcrFileURL: string
  isSkipSearchTra: boolean
}

export type Props = {
  ocr: ocrExecution
  setOcr: React.Dispatch<React.SetStateAction<ocrExecution>>
  token: string
}

export const OCRSubscription: React.FC<Props> = (props) => {
  const { ocr, setOcr, token } = props
  const input: ModelSubscriptionJobFilterInput = {
    id: { eq: ocr.jobId },
    status: { in: [OcrJobStatus.successed, OcrJobStatus.failed] },
  }
  React.useEffect(() => {
    const reqParams: SubscriptionRequestParams = {
      filter: input,
      auth: {
        jobId: ocr.jobId,
        operation: 'subscribe',
      },
    }
    const subscription = API.graphql<GraphQLSubscription<OnUpdateJobSubscription>>(
      {
        authMode: GRAPHQL_AUTH_MODE.AWS_LAMBDA,
        query: onUpdateJob,
        variables: reqParams,
        authToken: token,
      }
      //      graphqlOperation(onUpdateJob, reqParams, token),
    ).subscribe({
      start: () => {},
      next: (prop) => {
        const { result } = prop.value.data.onUpdateJob
        const ocrObject = result
          ? {
              ...ocr,
              jobStatus: OcrJobStatus.successed,
              extractedItems: JSON.parse(result.result),
            }
          : {
              ...ocr,
              jobStatus: OcrJobStatus.failed,
              extractedItems: null,
            }
        setOcr(ocrObject)
      },
      error: (error) => {
        console.error(error)
        setOcr({
          ...ocr,
          jobStatus: OcrJobStatus.failed,
          extractedItems: null,
        })
      },
    })
    return () => {
      subscription.unsubscribe()
    }
  }, [ocr.jobId, ocr.jobStatus])

  return null
}
