import * as React from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { Button } from '@/components/Button'

type Props = {
  children?: React.ReactNode
  title?: string
  yes?: string
  no?: string
  open: boolean
  yesButtonDisabled?: boolean
  onClose: (result: boolean) => void
}

export const ConfirmDialog: React.FC<Props> = ({
  children,
  title,
  yes,
  no,
  open,
  onClose,
  yesButtonDisabled = false,
}) => {
  const closeDialogYes = () => {
    onClose(true)
  }

  const closeDialogNo = () => {
    onClose(false)
  }

  return (
    <Transition appear show={open} as={React.Fragment}>
      <Dialog as="div" className="relative z-10" onClose={closeDialogNo}>
        <Transition.Child
          as={React.Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center text-center">
            <Transition.Child
              as={React.Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-screen md:max-w-[625px] mx-4 md:mx-auto transform overflow-hidden rounded-2xl bg-white text-left align-middle shadow-xl transition-all">
                {title && (
                  <>
                    <Dialog.Title
                      as="h3"
                      className="text-base font-medium p-4 leading-6 text-gray-700"
                    >
                      {title}
                    </Dialog.Title>
                    <div className="border-t border-gray-150"></div>
                  </>
                )}

                <div className="px-6 pt-6">{children}</div>

                <div className="flex gap-2 px-6 py-6 justify-center">
                  <Button
                    className="w-full md:w-40 md:h-auto"
                    disabled={yesButtonDisabled}
                    onClick={closeDialogYes}
                  >
                    {yes || 'はい'}
                  </Button>
                  <Button
                    className="w-full md:w-40 md:h-auto text-gray-300"
                    variant="text"
                    onClick={closeDialogNo}
                  >
                    {no || 'いいえ'}
                  </Button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  )
}
