import * as React from "react";
import { FC, useMemo } from 'react';
import { DrawingBorderLine, DrawingFloor } from '../Drawing';
import { Vector2 } from 'three';
import {
  formatMeasurementValue,
  textReadableDegree,
} from '../../helpers/DrawingHelper';
import { RAD2DEG } from 'three/src/math/MathUtils';

export type FloorBoundingBoxProps = {
  borderLines: DrawingBorderLine[];
  floor: DrawingFloor;
  strokeWidth?: number;
  fontSize?: number;
};

type MeasurementLineProps = {
  length: number;
  position: Vector2;
  xAxis: Vector2;
  yAxis: Vector2;
  strokeColor: string;
  strokeWidth: number;
  pointColor: string;
  pointRadius: number;
  fontSize: number;
};

const MeasurementLine: FC<MeasurementLineProps> = ({
  length,
  position,
  xAxis,
  yAxis,
  strokeColor,
  strokeWidth,
  pointColor,
  pointRadius,
  fontSize,
}) => {
  const points = useMemo(() => {
    const half = length * 0.5;
    const xOffset = xAxis.clone().multiplyScalar(1000);
    const p1 = position.clone().add(yAxis.clone().multiplyScalar(-half));
    const p0 = p1.clone().add(xOffset);
    const p2 = position.clone().add(yAxis.clone().multiplyScalar(half));
    const p3 = p2.clone().add(xOffset);
    return [p0, p1, p2, p3];
  }, [length, position, xAxis, yAxis]);

  const polylinePoints = useMemo(() => {
    return points.map((p) => `${p.x},${p.y}`).join(' ');
  }, [points]);

  const measurementTransform = useMemo(() => {
    const p1 = points[1];
    const p2 = points[2];
    const normal = p2.clone().sub(p1).normalize();
    return {
      position: p1.clone().add(p2).divideScalar(2),
      angle: textReadableDegree(normal.angle() * RAD2DEG),
      // angle: normal.angle() * RAD2DEG,
    };
  }, [points]);

  return (
    <g>
      <polyline
        stroke={strokeColor}
        strokeWidth={strokeWidth}
        fill="none"
        points={polylinePoints}
      />
      {[points[1], points[2]].map((p, idx) => {
        return (
          <circle
            key={`point-${idx}`}
            cx={p.x}
            cy={p.y}
            r={pointRadius}
            fill={pointColor}
            stroke="none"
          />
        );
      })}
      <text
        fontSize={fontSize}
        textAnchor="middle"
        alignmentBaseline="after-edge"
        x={measurementTransform.position.x}
        y={measurementTransform.position.y}
        transform={`rotate(${measurementTransform.angle}, ${measurementTransform.position.x}, ${measurementTransform.position.y})`}
      >
        {formatMeasurementValue(length)}
      </text>
    </g>
  );
};

const FloorBoundingBox: FC<FloorBoundingBoxProps> = ({
  borderLines,
  floor,
  strokeWidth,
  fontSize,
}) => {
  const obb = useMemo(() => {
    const { bounding_box: obb } = floor;
    const { x_axis, y_axis } = obb;
    const dx = new Vector2(x_axis[0], x_axis[1]);
    const dy = new Vector2(y_axis[0], y_axis[1]);
    return {
      dx,
      dy,
    };
  }, [floor]);

  const aligned = useMemo(() => {
    const { dx, dy } = obb;
    const pts = borderLines
      .flatMap((b) => {
        if ('Road' in b) {
          if (b.Road.set_back_outer_segment !== null) {
            return [
              b.Road.set_back_outer_segment.a,
              b.Road.set_back_outer_segment.b,
            ];
          }
          return [b.Road.center_segment.a, b.Road.center_segment.b];
        } else {
          return [b.Neighbor.a, b.Neighbor.b];
        }
      })
      .map((p) => new Vector2(p[0], p[1]));
    const mapped = pts.map((o) => {
      const x = dx.dot(o);
      const y = dy.dot(o);
      return new Vector2(x, y);
    });
    const xs = mapped.map((o) => o.x);
    const ys = mapped.map((o) => o.y);
    return {
      min: new Vector2(Math.min(...xs), Math.min(...ys)),
      max: new Vector2(Math.max(...xs), Math.max(...ys)),
    };
  }, [obb, borderLines]);

  const worldCenter = useMemo(() => {
    const { bounding_box } = floor;
    const { min: omin, max: omax } = bounding_box;
    const { dx, dy } = obb;
    const worldMin = dx
      .clone()
      .multiplyScalar(omin[0])
      .add(dy.clone().multiplyScalar(omin[1]));
    const worldMax = dx
      .clone()
      .multiplyScalar(omax[0])
      .add(dy.clone().multiplyScalar(omax[1]));
    return worldMin.add(worldMax).divideScalar(2);
  }, [obb, floor]);

  const margin = useMemo(() => 1e3 * 4, []);

  const xAxis = useMemo(() => {
    const { bounding_box } = floor;
    const { size } = bounding_box;
    const { dx, dy } = obb;
    const { max } = aligned;
    const worldOffset = dy.clone().multiplyScalar(max.y + margin);
    return {
      length: size[0],
      position: worldCenter.clone().add(worldOffset),
      xAxis: dy.clone().negate(),
      yAxis: dx,
    };
  }, [floor, worldCenter, obb, aligned, margin]);

  const yAxis = useMemo(() => {
    const { bounding_box } = floor;
    const { size } = bounding_box;
    const { dx, dy } = obb;
    const { max } = aligned;
    const worldOffset = dx.clone().multiplyScalar(max.x + margin);
    return {
      length: size[1],
      position: worldCenter.clone().add(worldOffset),
      xAxis: dx.clone().negate(),
      yAxis: dy,
    };
  }, [floor, worldCenter, obb, aligned, margin]);

  return (
    <g>
      <MeasurementLine
        {...xAxis}
        strokeColor="#ff00ff"
        strokeWidth={strokeWidth ?? 40}
        pointColor="#00ffff"
        pointRadius={80}
        fontSize={fontSize ?? 200}
      />
      <MeasurementLine
        {...yAxis}
        strokeColor="#ff00ff"
        strokeWidth={strokeWidth ?? 40}
        pointColor="#00ffff"
        pointRadius={80}
        fontSize={fontSize ?? 200}
      />
    </g>
  );
};

export { FloorBoundingBox };
