import * as React from "react";
import type { User } from "@/types/user";
import { Breadcrumb } from "@/components/Breadcrumb";
import Base from '@/components/Layout/Base'
import type { timeFrame } from '@/types/timeFrame'
import TimeTable from './TimeTable'
import type { Property } from "@/types/property";
import { Button } from "@/components/Button";

type Props = {
  current_user: User
  authenticity_token: string
  time_frames: timeFrame[]
  property: Property
}
const TimeFrameIndexPage: React.FC<Props> = ({
  current_user,
  authenticity_token,
  time_frames,
  property
}) => {

  const title = 'ボリューム計算時間帯設定'
  const bodyRef = React.useRef<HTMLElement>(null)
  const [isError, setIsError] = React.useState<boolean>(false)
  const [del_frames, setDel_Frames] = React.useState<timeFrame[]>([])
  const [monday, setMonday] = React.useState<timeFrame[]>(time_frames.filter(item => item.day_of_week === 1))
  const setMonTime = (index: number, type: string, value: any) => {
    switch (type) {
      case 'add':
        if (monday.length >= 5) return
        const time_frame: timeFrame = {
          id: null,
          day_of_week: 1,
          start_time: '00:00:00',
          end_time: '23:59:00',
          max_task_count: 0,
          created_at: null,
          updated_at: null,
          hashid: null
        }
        setMonday(monday.concat(time_frame))
        break
      case 'del':
        setDel_Frames(del_frames.concat(monday.filter((value, i) => i === index && value.id !== null)))
        setMonday(monday.filter((value, i) => i !== index))
        break
      case 'start':
        setMonday(monday.map((item, i) => {
          if (i === index) {
            item.start_time = value
          }
          return item
        }))
        break
      case 'end':
        setMonday(monday.map((item, i) => {
          if (i === index) {
            item.end_time = value
          }
          return item
        }))
        break
      case 'max':
        setMonday(monday.map((item, i) => {
          if (i === index) {
            item.max_task_count = Number(value)
          }
          return item
        }))
        break
    }
  }
  const [tuesday, setTuesday] = React.useState<timeFrame[]>(time_frames.filter(item => item.day_of_week === 2))
  const setTuesTime = (index: number, type: string, value: any) => {
    switch (type) {
      case 'add':
        if (tuesday.length >= 5) return
        const time_frame: timeFrame = {
          id: null,
          day_of_week: 2,
          start_time: '00:00:00',
          end_time: '23:59:00',
          max_task_count: 0,
          created_at: null,
          updated_at: null,
          hashid: null
        }
        setTuesday(tuesday.concat(time_frame))
        break
      case 'del':
        setDel_Frames(del_frames.concat(tuesday.filter((value, i) => i === index && value.id !== null)))
        setTuesday(tuesday.filter((value, i) => i !== index))
        break
      case 'start':
        setTuesday(tuesday.map((item, i) => {
          if (i === index) {
            item.start_time = value
          }
          return item
        }))
        break
      case 'end':
        setTuesday(tuesday.map((item, i) => {
          if (i === index) {
            item.end_time = value
          }
          return item
        }))
        break
      case 'max':
        setTuesday(tuesday.map((item, i) => {
          if (i === index) {
            item.max_task_count = Number(value)
          }
          return item
        }))
        break
    }
  }
  const [wednesday, setWednesday] = React.useState<timeFrame[]>(time_frames.filter(item => item.day_of_week === 3))
  const setWednesTime = (index: number, type: string, value: any) => {
    switch (type) {
      case 'add':
        if (wednesday.length >= 5) return
        const time_frame: timeFrame = {
          id: null,
          day_of_week: 3,
          start_time: '00:00:00',
          end_time: '23:59:00',
          max_task_count: 0,
          created_at: null,
          updated_at: null,
          hashid: null
        }
        setWednesday(wednesday.concat(time_frame))
        break
      case 'del':
        setDel_Frames(del_frames.concat(wednesday.filter((value, i) => i === index && value.id !== null)))
        setWednesday(wednesday.filter((value, i) => i !== index))
        break
      case 'start':
        setWednesday(wednesday.map((item, i) => {
          if (i === index) {
            item.start_time = value
          }
          return item
        }))
        break
      case 'end':
        setWednesday(wednesday.map((item, i) => {
          if (i === index) {
            item.end_time = value
          }
          return item
        }))
        break
      case 'max':
        setWednesday(wednesday.map((item, i) => {
          if (i === index) {
            item.max_task_count = Number(value)
          }
          return item
        }))
        break
    }
  }
  const [thursday, setThursday] = React.useState<timeFrame[]>(time_frames.filter(item => item.day_of_week === 4))
  const setThursTime = (index: number, type: string, value: any) => {
    switch (type) {
      case 'add':
        if (thursday.length >= 5) return
        const time_frame: timeFrame = {
          id: null,
          day_of_week: 4,
          start_time: '00:00:00',
          end_time: '23:59:00',
          max_task_count: 0,
          created_at: null,
          updated_at: null,
          hashid: null
        }
        setThursday(thursday.concat(time_frame))
        break
      case 'del':
        setDel_Frames(del_frames.concat(thursday.filter((value, i) => i === index && value.id !== null)))
        setThursday(thursday.filter((value, i) => i !== index))
        break
      case 'start':
        setThursday(thursday.map((item, i) => {
          if (i === index) {
            item.start_time = value
          }
          return item
        }))
        break
      case 'end':
        setThursday(thursday.map((item, i) => {
          if (i === index) {
            item.end_time = value
          }
          return item
        }))
        break
      case 'max':
        setThursday(thursday.map((item, i) => {
          if (i === index) {
            item.max_task_count = Number(value)
          }
          return item
        }))
        break
    }
  }
  const [friday, setFriday] = React.useState<timeFrame[]>(time_frames.filter(item => item.day_of_week === 5))
  const setFriTime = (index: number, type: string, value: any) => {
    switch (type) {
      case 'add':
        if (friday.length >= 5) return
        const time_frame: timeFrame = {
          id: null,
          day_of_week: 5,
          start_time: '00:00:00',
          end_time: '23:59:00',
          max_task_count: 0,
          created_at: null,
          updated_at: null,
          hashid: null
        }
        setFriday(friday.concat(time_frame))
        break
      case 'del':
        setDel_Frames(del_frames.concat(friday.filter((value, i) => i === index && value.id !== null)))
        setFriday(friday.filter((value, i) => i !== index))
        break
      case 'start':
        setFriday(friday.map((item, i) => {
          if (i === index) {
            item.start_time = value
          }
          return item
        }))
        break
      case 'end':
        setFriday(friday.map((item, i) => {
          if (i === index) {
            item.end_time = value
          }
          return item
        }))
        break
      case 'max':
        setFriday(friday.map((item, i) => {
          if (i === index) {
            item.max_task_count = Number(value)
          }
          return item
        }))
        break
    }
  }
  const [saturday, setSaturday] = React.useState<timeFrame[]>(time_frames.filter(item => item.day_of_week === 6))
  const setSaturTime = (index: number, type: string, value: any) => {
    switch (type) {
      case 'add':
        if (saturday.length >= 5) return
        const time_frame: timeFrame = {
          id: null,
          day_of_week: 6,
          start_time: '00:00:00',
          end_time: '23:59:00',
          max_task_count: 0,
          created_at: null,
          updated_at: null,
          hashid: null
        }
        setSaturday(saturday.concat(time_frame))
        break
      case 'del':
        setDel_Frames(del_frames.concat(saturday.filter((value, i) => i === index && value.id !== null)))
        setSaturday(saturday.filter((value, i) => i !== index))
        break
      case 'start':
        setSaturday(saturday.map((item, i) => {
          if (i === index) {
            item.start_time = value
          }
          return item
        }))
        break
      case 'end':
        setSaturday(saturday.map((item, i) => {
          if (i === index) {
            item.end_time = value
          }
          return item
        }))
        break
      case 'max':
        setSaturday(saturday.map((item, i) => {
          if (i === index) {
            item.max_task_count = Number(value)
          }
          return item
        }))
        break
    }
  }
  const [sunday, setSunday] = React.useState<timeFrame[]>(time_frames.filter(item => item.day_of_week === 0))
  const setSunTime = (index: number, type: string, value: any) => {
    switch (type) {
      case 'add':
        if (sunday.length >= 5) return
        const time_frame: timeFrame = {
          id: null,
          day_of_week: 0,
          start_time: '00:00:00',
          end_time: '23:59:00',
          max_task_count: 0,
          created_at: null,
          updated_at: null,
          hashid: null
        }
        setSunday(sunday.concat(time_frame))
        break
      case 'del':
        setDel_Frames(del_frames.concat(sunday.filter((value, i) => i === index)))
        setSunday(sunday.filter((value, i) => i !== index))
        break
      case 'start':
        setSunday(sunday.map((item, i) => {
          if (i === index) {
            item.start_time = value
          }
          return item
        }))
        break
      case 'end':
        setSunday(sunday.map((item, i) => {
          if (i === index) {
            item.end_time = value
          }
          return item
        }))
        break
      case 'max':
        setSunday(sunday.map((item, i) => {
          if (i === index) {
            item.max_task_count = Number(value)
          }
          return item
        }))
        break
    }
  }
  return (
    <Base
      current_user={current_user}
      title={title}
      city={property.prefecture + property.city}
      submenuCategory="properties"
      breadcrumb={<Breadcrumb link_texts={['物件管理', 'VolCheck時間帯設定']} />}
      customRef={bodyRef}
    >
      <form action={`/time_frame?property_id=${property.hashid}`}
        acceptCharset="UTF-8"
        encType="multipart/form-data"
        method="post">
        <input type="hidden" name="authenticity_token" value={authenticity_token} />
        <input type="hidden" name="del_frames" value={JSON.stringify(del_frames)} />
        <input type="hidden" name="monday" value={JSON.stringify(monday)} />
        <input type="hidden" name="tuesday" value={JSON.stringify(tuesday)} />
        <input type="hidden" name="wednesday" value={JSON.stringify(wednesday)} />
        <input type="hidden" name="thursday" value={JSON.stringify(thursday)} />
        <input type="hidden" name="friday" value={JSON.stringify(friday)} />
        <input type="hidden" name="saturday" value={JSON.stringify(saturday)} />
        <input type="hidden" name="sunday" value={JSON.stringify(sunday)} />
        <div className="sticky top-0 w-full bg-white pt-10 z-20">
          <div className="border-b-[1px] border-primary">
            <span
              className="inline-flex bg-primary text-white text-sm py-2 px-8">ボリューム計算時間帯設定</span>
          </div>
        </div>
        <div className='flex flex-col gap-8 pb-10 mt-10 overflow-auto h-[calc(100vh_-_200px_-_5rem)]'>
          <TimeTable time_frames={monday} title={'月'} setTimeFrames={setMonTime} setIsError={setIsError} />
          <TimeTable time_frames={tuesday} title={'火'} setTimeFrames={setTuesTime} setIsError={setIsError} />
          <TimeTable time_frames={wednesday} title={'水'} setTimeFrames={setWednesTime} setIsError={setIsError} />
          <TimeTable time_frames={thursday} title={'木'} setTimeFrames={setThursTime} setIsError={setIsError} />
          <TimeTable time_frames={friday} title={'金'} setTimeFrames={setFriTime} setIsError={setIsError} />
          <TimeTable time_frames={saturday} title={'土'} setTimeFrames={setSaturTime} setIsError={setIsError} />
          <TimeTable time_frames={sunday} title={'日'} setTimeFrames={setSunTime} setIsError={setIsError} />
        </div>
        <div
          className="bg-white flex justify-center items-center w-full left-0 md:gap-4 border-t border-gray-250 py-2">
          <Button className="w-full md:w-40 md:h-auto" disabled={isError}>
            保存
          </Button>
        </div>
      </form>
    </Base>
  )
}

export default TimeFrameIndexPage