import { Breadcrumb } from '@/components/Breadcrumb'
import { AnchorButton } from '@/components/Button'
import Base from '@/components/Layout/Base'
import ProjectsModal from '@/components/Page/PorjectsModal/ProjectsModal'
import PDFSettingModal from '@/components/Page/PorjectsModal/PDFSettingModal'
import { Pagination } from '@/components/Pagination'
import TagItem from '@/components/ProjectsTag'
import { AddMemoModal } from '@/components/Page/PorjectsModal/AddMemoModal'
import { Table } from '@/components/Table'
import { Pagy } from '@/types/pagy'
import { User } from '@/types/user'
import type { Property, Memo } from '@/types/property'
import CloudDownloadIcon from '@material-ui/icons/CloudDownload'
import { PDFDownloadSupplement, sortOptionsDeffault } from '@/models/project'
import { fetchSortOptions } from '@/utils/fetchSortOptions'
import { ellipsisString } from '@/utils/ellipsisString'
import BookmarkAddIcon from '@mui/icons-material/BookmarkAdd'
import ManageSearchIcon from '@mui/icons-material/ManageSearch'
import EditIcon from '@mui/icons-material/Edit'
import Tooltip from '@mui/material/Tooltip'
import { format, parseISO } from 'date-fns'
import * as React from 'react'

type Props = {
  current_user: User
  data_container: {
    header: string[][]
    type: { name: string; id: string }
    tab: { name: string; id: string }
    count: string
    contains: { path: string; tbody: string[][]; property: Property; memos: Memo[]; new_memo: boolean }[]
  }[]
  business_type_options: { value: string; text: string }[]
  tag_options: { value: string; text: string }[]
  source_department_options: { value: string; label: string }[]
  department_options: { value: string; label: string }[]
  user_options: { value: string; label: string }[]
  prefecture_options: { value: string; label: string }[]
  pagy?: Pagy
  single_table: boolean
}
type searchQParams = {
  key: string
  value: number
}

const ProjectsDepartmentsIndexPage: React.FC<Props> = ({
  current_user,
  data_container,
  business_type_options,
  tag_options,
  source_department_options,
  department_options,
  user_options,
  prefecture_options,
  pagy,
  single_table,
}) => {
  const convertSortOptions = data_container
    ? fetchSortOptions(data_container, sortOptionsDeffault)
    : sortOptionsDeffault
  const title = '案件担当部署別'

  function getSearchParams() {
    return new URLSearchParams(window.location.search)
  }

  const searchParams = getSearchParams()
  const pdfQuery = searchParams.toString() !== '' ? `&${searchParams.toString()}` : ''

  return (
    <Base
      current_user={current_user}
      title={title}
      breadcrumb={<Breadcrumb link_texts={['案件担当部署別']} />}
      submenuCategory="projects"
    >
      <div className="flex flex-wrap items-end justify-between m-1">
        <div className="flex flex-wrap items-center gap-1 whitespace-nowrap">
          {pagy != null && (
            <>
              <span className="text-primary-font text-xl font-medium">
                {pagy.count.toLocaleString()}
              </span>
              <span className="text-gray-800 text-sm mt-0.5">
                件中 {pagy.from}～{pagy.to}件 表示
              </span>
              <span className="ml-2">
                <Pagination pagy={pagy} />
              </span>
            </>
          )}
        </div>

        <div className="flex ml-auto gap-1 pt-1 md:gap-2 md:pt-0">
          <ProjectsModal
            title="条件絞り込み"
            sub_tab="department_name"
            department_options={department_options}
            source_department_options={source_department_options}
            user_options={user_options}
            tag_options={tag_options}
            business_type_options={business_type_options}
            prefecture_options={prefecture_options}
            sort_options={convertSortOptions}
          />

          <Tooltip title={single_table ? '' : PDFDownloadSupplement}>
            <AnchorButton
              className="text-primary-font"
              prefix={<CloudDownloadIcon fontSize="small" />}
              outline
              size="small"
              variant="primary"
              href={`/projects.pdf?group_type=department${pdfQuery}`}
            >
              <span className="hidden md:inline">PDFダウンロード</span>
            </AnchorButton>
          </Tooltip>
          {!single_table && (
            <div>
              <PDFSettingModal
                title="PDFダウンロード オプション"
                sub_tab="department_name"
                department_options={department_options}
                user_options={user_options}
              />
            </div>
          )}
        </div>
      </div>
      <div className="overflow-scroll h-[calc(100vh_-_145px)] md:h-[calc(100vh_-_155px)]">
        {data_container.length === 0 ? (
          <div className="text-center text-[#777] py-8">データがありません</div>
        ) : (
          data_container.map((data, index) => (
            <DataItem
              tags={current_user.company?.tags || []}
              single_table={single_table}
              data={data}
              index={index}
            />
          ))
        )}
      </div>
    </Base>
  )
}

const DataItem = ({ tags, single_table, data, index }) => {
  function setQParams(qParams: searchQParams[]) {
    const searchParams = new URLSearchParams(window.location.search)

    qParams.forEach((params: searchQParams) => {
      params.value === 0
        ? searchParams.set(`q[${params.key}_null]`, 'true')
        : searchParams.set(`q[${params.key}_eq_any][]`, params.value.toString())
    })

    const newQuery = searchParams.toString()
    return '/projects_departments?' + newQuery
  }

  const tagIndex = data.header.flat().indexOf('タグ')
  const memoIndex = data.header.flatMap((h) => Array.isArray(h) ? h.join("") : h).indexOf('進捗')

  return (
    <div key={index} className="pb-8">
      <div className="flex text-xs ">
        <div className="flex px-10 md:px-20 pt-[6px] bg-gray-150 border-b border-gray-200 text-[#555]">
          {data.type.name}
        </div>
        <div className="text-[#777] flex px-6 md:px-12 py-[4px] mt-1 bg-[#f4f5f6] shadow-[inset_2px_-2px_2px_rgba(0,0,0,0.1)]">
          {data.tab.name}
        </div>
      </div>
      <Table
        className={single_table ? 'h-[calc(100vh_-_155px)] md:h-[calc(100vh_-_165px)]' : ''}
        topScrollbar={window.innerWidth > 768}
      >
        <thead className="bg-[#f4f5f6] text-sm sticky top-0 z-10">
          <tr className="whitespace-nowrap">
            {data.header.map((row, index) => {
              if (index === 1) {
                return
              }
              return (
                <th
                  key={index}
                  scope="col"
                  className={[
                    'py-2 px-4 font-medium text-center',
                    index === 0 && 'md:sticky md:left-0 bg-[#f4f5f6]',
                  ].join(' ')}
                >
                  {index === 0 ? (
                    <div>
                      {data.header[0]}
                      <span className="pl-2">{data.header[1]}</span>
                    </div>
                  ) : (
                    row.map((col, index) => <div key={index}>{col}</div>)
                  )}
                </th>
              )
            })}
          </tr>
        </thead>
        <tbody className="whitespace-nowrap">
          {data.contains.map((content, index) => (
            <tr
              key={index}
              className="bg-white border-b border-gray-100 hover:bg-targetLine cursor-pointer"
            >
              {content.tbody.map((row, index) => {
                if (index === 1) {
                  return
                } else if (index === tagIndex) {
                  return <TagItem tags={tags} currentTagName={row?.[0]} path={content.path} />
                } else if ([memoIndex, memoIndex + 1].includes(index)) {
                  if((memoIndex + 1) === index){ return }
                  const [isOpen, setIsOpen] = React.useState(false)
                  const onClose = () => {
                    setIsOpen(false)
                  }
                  const [memos, setMemos] = React.useState<Memo[]>(content.memos)
                  const setListMemos = (memos: Memo[]) => {
                    setMemos(memos)
                  }
                  return (
                    <>
                      <td
                        key={index}
                        className={'text-center'}
                        onClick={() => {
                          setIsOpen(true)
                          setMemos(memos)
                        }}>
                        <div className='flex w-full h-full py-2 px-4'>
                          <div
                            key={index}
                            className={'whitespace-pre-wrap break-all text-left mr-2 min-w-[400px]'}>
                              {ellipsisString(memos[0]?.content, 150)}
                          </div>
                          <div className='ml-auto'>
                            <EditIcon fontSize="small" />
                          </div>
                        </div>
                      </td>
                      <td
                        key={index + 1}
                        className={'text-center'}
                        onClick={() => {
                          setIsOpen(true)
                          setMemos(memos)
                        }}>
                        <div className='block w-full h-full py-2 px-4'>
                          <div
                            key={index + 1}
                            className={'whitespace-pre text-left'}>
                              {memos[0]?.updated_at && format(parseISO(memos[0]?.updated_at), 'yyyy年MM月dd日')}
                          </div>
                        </div>
                      </td>
                      <AddMemoModal
                        memos={memos}
                        property={content.property}
                        is_open={isOpen}
                        on_close={onClose}
                        set_list_memos={setListMemos}
                      />
                    </>
                  )
                }
                return (
                  <td
                    key={index}
                    className={[
                      'text-center',
                      index === 0 &&
                        'md:sticky md:left-0 md:overflow-hidden md:overflow-ellipsis md:max-w-[280px] bg-white',
                    ].join(' ')}
                  >
                    <a
                      href={`${content.path}?tab=property_details`}
                      className="block w-full h-full py-2 px-4"
                    >
                      {index === 0 ? (
                        <div key={0} className={'whitespace-pre text-left'}>
                          {content.tbody[0]}. <span className="pl-2">{content.tbody[1]}</span>
                        </div>
                      ) : (
                        row.map((col, idx) => (
                          <div key={idx} className={'whitespace-pre text-left'}>
                            {col != null && col.toString() !== '' ? col : '　'}
                            {index == 1 && content.new_memo && (
                              <span className="align-top inline-block text-[10px] text-targetLine-warning">
                                <BookmarkAddIcon fontSize="small" />
                                進捗UP
                              </span>
                            )}
                          </div>
                        ))
                      )}
                    </a>
                  </td>
                )
              })}
            </tr>
          ))}
        </tbody>
      </Table>
      {!single_table && (
        <div className="pt-2">
          <AnchorButton
            className="text-primary-font border-white"
            prefix={<ManageSearchIcon fontSize="small" />}
            variant="text"
            outline
            size="small"
            href={setQParams([
              { key: 'user_department_id', value: data.type.id },
              { key: 'business_type_id', value: data.tab.id },
            ])}
          >
            <>全部{data.count}件を見る</>
          </AnchorButton>
        </div>
      )}
    </div>
  )
}

export default ProjectsDepartmentsIndexPage
