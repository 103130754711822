import * as React from "react";
import { FC, useMemo } from 'react';
import { DrawingFloor } from '../Drawing';
import { formatMeasurementValue } from '../../helpers/DrawingHelper';

export type FloorProps = {
  floorNumber: number;
  floor: DrawingFloor;
  strokeWidth?: number;
  fontSize?: number;
};

const Floor: FC<FloorProps> = ({
  floorNumber,
  floor,
  strokeWidth,
  fontSize,
}) => {
  const width = strokeWidth ?? 40;

  const inners = useMemo(() => {
    return floor.constructable_area.shapes.map(({ inner }) => {
      return inner.points
        .map((p) => {
          return `${p[0]},${p[1]}`;
        })
        .join(' ');
    });
  }, [floor]);

  const outers = useMemo(() => {
    return floor.constructable_area.shapes.map(({ outer }) => {
      return outer.points
        .map((p) => {
          return `${p[0]},${p[1]}`;
        })
        .join(' ');
    });
  }, [floor]);

  return (
    <g>
      <text
        fontSize={fontSize ?? 200}
        textAnchor="middle"
        alignmentBaseline="baseline"
        dominantBaseline="central"
        x={floor.constructable_area.center[0]}
        y={floor.constructable_area.center[1]}
      >
        {`${floorNumber}階 施工可能面積 ${formatMeasurementValue(
          floor.constructable_area.area * 1e-6,
          2,
        )}㎡`}
      </text>
      {inners.map((points, idx) => {
        return (
          <polyline
            key={`inner-${idx}`}
            stroke="#00edffd0"
            strokeWidth={width}
            fill="none"
            points={points}
          />
        );
      })}
      {outers.map((points, idx) => {
        return (
          <polyline
            key={`outer-${idx}`}
            stroke="#00edffd0"
            strokeWidth={width}
            fill="none"
            points={points}
          />
        );
      })}
    </g>
  );
};

export { Floor };
