type Containers = {
  header: string[][]
  type: { name: string; id: string }
  tab: { name: string; id: string }
  count: string
  contains: { path: string; tbody: string[][]; new_memo: boolean }[]
}

type Options = { value: string; text: string }

// 設定されている案件一覧のカラムから、並び替えの項目を動的な選択肢にする
export function fetchSortOptions(dataContainer: Containers[], sortOptions: Options[]): Options[] {
  const headers = []
  dataContainer.forEach((container) => {
    const containerHeaders = container.header.flatMap((data) => {
      return data
    })
    headers.push(containerHeaders)
  })
  return formatSortOptions(headers, sortOptions)
}

export function formatSortOptions(headers: any[], sortOptions: Options[]): Options[] {
  const flatHeaders = headers.flatMap((data: string) => {
    return data
  })
  const res = sortOptions.filter((item) => flatHeaders.some((value) => item.text.includes(value)))
  res.unshift({ value: '', text: '（なし）' })
  return res
}
