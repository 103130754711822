
/// 用途地域区分コード(データベース定義書参照)
export const YoutoTypeCodeMap = {
  Undefined: 0,
  /// 1: 第１種低層住居専用地域
  Cat1ExclusiveLowriseResidential: 1,
  /// 2: 第２種低層住居専用地域
  Cat2ExclusiveLowriseResidential: 2,
  /// 3: 第１種中高層住居専用地域
  Cat1MidHighriseResidential: 3,
  /// 4: 第２種中高層住居専用地域
  Cat2MidHighriseResidential: 4,
  /// 5: 第１種住居地域
  Cat1Residential: 5,
  /// 6: 第２種住居地域
  Cat2Residential: 6,
  /// 7: 準住居地域
  QuasiResidential: 7,
  /// 8: 近隣商業地域
  NeighborhoodCommertial: 8,
  /// 9: 商業地域
  Commercial: 9,
  /// 10: 準工業地域
  QuasiIndustrial: 10,
  /// 11: 工業地域
  Industrial: 11,
  /// 12: 工業専用地域
  ExclusiveIndustrial: 12,
} as const;
export type YoutoTypeCode = (typeof YoutoTypeCodeMap)[keyof typeof YoutoTypeCodeMap];
export const YoutoTypeCodeNameMap: Record<YoutoTypeCode, string> = {
  [YoutoTypeCodeMap.Undefined]: '無',
  [YoutoTypeCodeMap.Cat1ExclusiveLowriseResidential]: '第１種低層住居専用地域',
  [YoutoTypeCodeMap.Cat2ExclusiveLowriseResidential]: '第２種低層住居専用地域',
  [YoutoTypeCodeMap.Cat1MidHighriseResidential]: '第１種中高層住居専用地域',
  [YoutoTypeCodeMap.Cat2MidHighriseResidential]: '第２種中高層住居専用地域',
  [YoutoTypeCodeMap.Cat1Residential]: '第１種住居地域',
  [YoutoTypeCodeMap.Cat2Residential]: '第２種住居地域',
  [YoutoTypeCodeMap.QuasiResidential]: '準住居地域',
  [YoutoTypeCodeMap.NeighborhoodCommertial]: '近隣商業地域',
  [YoutoTypeCodeMap.Commercial]: '商業地域',
  [YoutoTypeCodeMap.QuasiIndustrial]: '準工業地域',
  [YoutoTypeCodeMap.Industrial]: '工業地域',
  [YoutoTypeCodeMap.ExclusiveIndustrial]: '工業専用地域',
};

export const BoukaTypeCodeMap = {
  // 防火地域
  FireProofArea: 10,
  // 準防火地域
  SemiFireProofArea: 20,
} as const;
export type BoukaTypeCode = (typeof BoukaTypeCodeMap)[keyof typeof BoukaTypeCodeMap];
export const BoukaTypeCodeNameMap: Record<BoukaTypeCode, string> = {
  [BoukaTypeCodeMap.FireProofArea]: '防火地域',
  [BoukaTypeCodeMap.SemiFireProofArea]: '準防火地域',
};

export const KoudoTypeCodeMap = {
  // 第一種高度地区
  Cat1HeightControlDistrict: 1,
  // 第二種高度地区
  Cat2HeightControlDistrict: 2,
  // 第三種高度地区
  Cat3HeightControlDistrict: 3,
  // 高さ制限のみ
  OnlyHeightLimit: 4,
} as const;
export type KoudoTypeCode = (typeof KoudoTypeCodeMap)[keyof typeof KoudoTypeCodeMap];
export const KoudoTypeCodeNameMap: Record<KoudoTypeCode, string> = {
  [KoudoTypeCodeMap.Cat1HeightControlDistrict]: '第一種高度地区',
  [KoudoTypeCodeMap.Cat2HeightControlDistrict]: '第二種高度地区',
  [KoudoTypeCodeMap.Cat3HeightControlDistrict]: '第三種高度地区',
  [KoudoTypeCodeMap.OnlyHeightLimit]: '無',
};

