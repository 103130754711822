import * as React from 'react'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { Card } from './Card'

type Props<T extends { id: number }> = {
  children?: React.ReactNode
  className?: string
  renderItem: (card: T) => void
  canMove?: (card: T) => boolean
  cards: T[]
  moveCard: (dragIndex: number, hoverIndex: number) => void
  dropCard: () => void
}

export const SortableList: React.FC<Props<T>> = ({
  children,
  className,
  renderItem,
  canMove,
  cards,
  moveCard,
  dropCard,
  deleteCard,
}) => {
  {
    const renderCard = React.useCallback(
      (card: T, index: number) => {
        return (
          <Card
            key={card.id}
            id={card.id}
            index={index}
            canMove={canMove ? canMove(card) : true}
            moveCard={moveCard}
            dropCard={dropCard}
          >
            {renderItem(card)}
          </Card>
        )
      },
      [moveCard, dropCard]
    )

    return (
      <DndProvider backend={HTML5Backend}>
        <table className={['w-full', className].join(' ')}>
          <tbody>
            {cards.map((card, i) => renderCard(card, i))}
            {children}
          </tbody>
        </table>
      </DndProvider>
    )
  }
}
