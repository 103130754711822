import * as React from 'react'
import { Table, Row, Th, Td } from '@/components/Table'
import { unit_pct, unit_int_pct, unit_m2 } from '../VolumeCheckRequests/volumeUnitFormat'
import {
  areaOfUseCodes,
  fireProtectionCodes,
  altitudeAreaCodes,
  shadeInfoSet,
} from '@/models/areaInfoCodes'

const AreaInfo = ({ responseAreaData, propertyData, prefectureName }) => {
  let renderComponent
  if (responseAreaData || propertyData?.area_info) {
    renderComponent = (
      <AreaInfoTable
        responseAreaData={responseAreaData}
        propertyData={propertyData}
        prefectureName={prefectureName}
      />
    )
  } else if (propertyData?.ocr_area_info) {
    renderComponent = <OCRAreaInfoTable ocr_area_info={propertyData.ocr_area_info} />
  } else if (propertyData?.instant_city_plannings.length > 0) {
    renderComponent = <InstantCityPlanningTable propertyData={propertyData} />
  } else {
    null
  }
  return renderComponent
}

const OCRAreaInfoTable = ({ ocr_area_info }) => {
  return (
    <>
      <Table
        className="border-t border-b block mb-16"
        header={
          <div className="flex justify-between items-center bg-primary text-white py-2 px-4 text-sm">
            都市計画関係
          </div>
        }
      >
        <Row
          className="w-full"
          label={
            <th
              scope="row"
              className={`pt-4 pb-2 md:py-5 px-4 md:text-right whitespace-nowrap text-black-base font-medium md:w-1/4 block md:table-cell`}
            ></th>
          }
        >
          <td className={`py-2 px-4 md:w-1875 block md:table-cell`}>領域1</td>
          <td className={`py-2 px-4 md:w-1875 block md:table-cell`}>領域2</td>
          <td className={`py-2 px-4 md:w-1875 block md:table-cell`}>領域3</td>
          <td className={`py-2 px-4 md:w-1875 block md:table-cell`}>領域4</td>
        </Row>
        <Row
          className="w-full"
          label={
            <th
              scope="row"
              className={`pt-4 pb-2 md:py-5 px-4 md:text-right whitespace-nowrap text-black-base font-medium md:w-1/4 block md:table-cell`}
            >
              用途地域
            </th>
          }
        >
          <td className={`py-2 px-4 md:w-1875 block md:table-cell`}>
            {ocr_area_info.city_planning.merged_areas[0]?.['area_of_use']?.['youto_chiiki']}
          </td>
          <td className={`py-2 px-4 md:w-1875 block md:table-cell`}>
            {ocr_area_info.city_planning.merged_areas[1]?.['area_of_use']?.['youto_chiiki']}
          </td>
          <td className={`py-2 px-4 md:w-1875 block md:table-cell`}>
            {ocr_area_info.city_planning.merged_areas[2]?.['area_of_use']?.['youto_chiiki']}
          </td>
          <td className={`py-2 px-4 md:w-1875 block md:table-cell`}>
            {ocr_area_info.city_planning.merged_areas[3]?.['area_of_use']?.['youto_chiiki']}
          </td>
        </Row>
        <Row
          className="w-full"
          label={
            <th
              scope="row"
              className={`pt-4 pb-2 md:py-5 px-4 md:text-right whitespace-nowrap text-black-base font-medium md:w-1/4 block md:table-cell`}
            >
              建ぺい率
            </th>
          }
        >
          <td className={`py-2 px-4 md:w-1875 block md:table-cell`}>
            {unit_int_pct(
              ocr_area_info.city_planning.merged_areas[0]?.['area_of_use']?.[
                'building_coverage_ratio'
              ]
            )}
          </td>
          <td className={`py-2 px-4 md:w-1875 block md:table-cell`}>
            {unit_int_pct(
              ocr_area_info.city_planning.merged_areas[1]?.['area_of_use']?.[
                'building_coverage_ratio'
              ]
            )}
          </td>
          <td className={`py-2 px-4 md:w-1875 block md:table-cell`}>
            {unit_int_pct(
              ocr_area_info.city_planning.merged_areas[2]?.['area_of_use']?.[
                'building_coverage_ratio'
              ]
            )}
          </td>
          <td className={`py-2 px-4 md:w-1875 block md:table-cell`}>
            {unit_int_pct(
              ocr_area_info.city_planning.merged_areas[3]?.['area_of_use']?.[
                'building_coverage_ratio'
              ]
            )}
          </td>
        </Row>
        <Row
          className="w-full"
          label={
            <th
              scope="row"
              className={`pt-4 pb-2 md:py-5 px-4 md:text-right whitespace-nowrap text-black-base font-medium md:w-1/4 block md:table-cell`}
            >
              容積率
            </th>
          }
        >
          <td className={`py-2 px-4 md:w-1875 block md:table-cell`}>
            {unit_int_pct(
              ocr_area_info.city_planning.merged_areas[0]?.['area_of_use']?.['floor_area_ratio']
            )}
          </td>
          <td className={`py-2 px-4 md:w-1875 block md:table-cell`}>
            {unit_int_pct(
              ocr_area_info.city_planning.merged_areas[1]?.['area_of_use']?.['floor_area_ratio']
            )}
          </td>
          <td className={`py-2 px-4 md:w-1875 block md:table-cell`}>
            {unit_int_pct(
              ocr_area_info.city_planning.merged_areas[2]?.['area_of_use']?.['floor_area_ratio']
            )}
          </td>
          <td className={`py-2 px-4 md:w-1875 block md:table-cell`}>
            {unit_int_pct(
              ocr_area_info.city_planning.merged_areas[3]?.['area_of_use']?.['floor_area_ratio']
            )}
          </td>
        </Row>
      </Table>
      <div className="hidden md:block md:text-xs px-4 mb-14 whitespace-normal text-primary font-small"></div>
    </>
  )
}

const AreaInfoTable = ({ responseAreaData, propertyData, prefectureName }) => {
  return (
    <>
      <div className="hidden md:block">
        <Table
          className="border-t border-b block mb-2"
          header={
            <div className="flex justify-between items-center bg-primary text-white py-2 px-4 text-sm">
              都市計画関係
            </div>
          }
        >
          <Row
            className="w-full"
            label={
              <th
                scope="row"
                className={`pt-4 pb-2 md:py-5 px-4 md:text-right whitespace-nowrap text-black-base font-medium md:w-1/4 block md:table-cell`}
              ></th>
            }
          >
            <td className={`py-2 px-4 md:w-1875 block md:table-cell`}>領域1</td>
            <td className={`py-2 px-4 md:w-1875 block md:table-cell`}>領域2</td>
            <td className={`py-2 px-4 md:w-1875 block md:table-cell`}>領域3</td>
            <td className={`py-2 px-4 md:w-1875 block md:table-cell`}>領域4</td>
          </Row>
          <Row
            className="w-full"
            label={
              <th
                scope="row"
                className={`pt-4 pb-2 md:py-5 px-4 md:text-right whitespace-nowrap text-black-base font-medium md:w-1/4 block md:table-cell`}
              >
                領域面積
              </th>
            }
          >
            <td className={`py-2 px-4 md:w-1875 block md:table-cell`}>
              {unit_m2(responseAreaData.city_planning.merged_areas[0]?.['area'])}
            </td>
            <td className={`py-2 px-4 md:w-1875 block md:table-cell`}>
              {unit_m2(responseAreaData.city_planning.merged_areas[1]?.['area'])}
            </td>
            <td className={`py-2 px-4 md:w-1875 block md:table-cell`}>
              {unit_m2(responseAreaData.city_planning.merged_areas[2]?.['area'])}
            </td>
            <td className={`py-2 px-4 md:w-1875 block md:table-cell`}>
              {unit_m2(responseAreaData.city_planning.merged_areas[3]?.['area'])}
            </td>
          </Row>
          <Row
            className="w-full"
            label={
              <th
                scope="row"
                className={`pt-4 pb-2 md:py-5 px-4 md:text-right whitespace-nowrap text-black-base font-medium md:w-1/4 block md:table-cell`}
              >
                用途地域
              </th>
            }
          >
            <td className={`py-2 px-4 md:w-1875 block md:table-cell`}>
              {responseAreaData.city_planning.merged_areas[0] !== undefined
                ? areaOfUseCodes[
                    responseAreaData.city_planning.merged_areas[0]?.['area_of_use']?.[
                      'youto_chiiki'
                    ]
                  ] || 'N/A'
                : ''}
            </td>
            <td className={`py-2 px-4 md:w-1875 block md:table-cell`}>
              {responseAreaData.city_planning.merged_areas[1] !== undefined
                ? areaOfUseCodes[
                    responseAreaData.city_planning.merged_areas[1]?.['area_of_use']?.[
                      'youto_chiiki'
                    ]
                  ] || 'N/A'
                : ''}
            </td>
            <td className={`py-2 px-4 md:w-1875 block md:table-cell`}>
              {responseAreaData.city_planning.merged_areas[2] !== undefined
                ? areaOfUseCodes[
                    responseAreaData.city_planning.merged_areas[2]?.['area_of_use']?.[
                      'youto_chiiki'
                    ]
                  ] || 'N/A'
                : ''}
            </td>
            <td className={`py-2 px-4 md:w-1875 block md:table-cell`}>
              {responseAreaData.city_planning.merged_areas[3] !== undefined
                ? areaOfUseCodes[
                    responseAreaData.city_planning.merged_areas[3]?.['area_of_use']?.[
                      'youto_chiiki'
                    ]
                  ] || 'N/A'
                : ''}
            </td>
          </Row>
          <Row
            className="w-full"
            label={
              <th
                scope="row"
                className={`pt-4 pb-2 md:py-5 px-4 md:text-right whitespace-nowrap text-black-base font-medium md:w-1/4 block md:table-cell`}
              >
                防火地域
              </th>
            }
          >
            <td className={`py-2 px-4 md:w-1875 block md:table-cell`}>
              {responseAreaData.city_planning.merged_areas[0] !== undefined
                ? fireProtectionCodes[
                    responseAreaData.city_planning.merged_areas[0]?.['fire_protection']?.[
                      'bouka_chiiki'
                    ]
                  ] || (prefectureName === '東京都' ? '-' : 'N/A')
                : ''}
            </td>
            <td className={`py-2 px-4 md:w-1875 block md:table-cell`}>
              {responseAreaData.city_planning.merged_areas[1] !== undefined
                ? fireProtectionCodes[
                    responseAreaData.city_planning.merged_areas[1]?.['fire_protection']?.[
                      'bouka_chiiki'
                    ]
                  ] || (prefectureName === '東京都' ? '-' : 'N/A')
                : ''}
            </td>
            <td className={`py-2 px-4 md:w-1875 block md:table-cell`}>
              {responseAreaData.city_planning.merged_areas[2] !== undefined
                ? fireProtectionCodes[
                    responseAreaData.city_planning.merged_areas[2]?.['fire_protection']?.[
                      'bouka_chiiki'
                    ]
                  ] || (prefectureName === '東京都' ? '-' : 'N/A')
                : ''}
            </td>
            <td className={`py-2 px-4 md:w-1875 block md:table-cell`}>
              {responseAreaData.city_planning.merged_areas[3] !== undefined
                ? fireProtectionCodes[
                    responseAreaData.city_planning.merged_areas[3]?.['fire_protection']?.[
                      'bouka_chiiki'
                    ]
                  ] || (prefectureName === '東京都' ? '-' : 'N/A')
                : ''}
            </td>
          </Row>
          <Row
            className="w-full"
            label={
              <th
                scope="row"
                className={`pt-4 pb-2 md:py-5 px-4 md:text-right whitespace-nowrap text-black-base font-medium md:w-1/4 block md:table-cell`}
              >
                高度地区
              </th>
            }
          >
            <td className={`py-2 px-4 md:w-1875 block md:table-cell`}>
              {responseAreaData.city_planning.merged_areas[0] !== undefined
                ? altitudeAreaCodes[
                    responseAreaData.city_planning.merged_areas[0]?.['altitude_area']?.[
                      'koudo_chiku'
                    ]
                  ] || (prefectureName === '東京都' ? '-' : 'N/A')
                : ''}
            </td>
            <td className={`py-2 px-4 md:w-1875 block md:table-cell`}>
              {responseAreaData.city_planning.merged_areas[1] !== undefined
                ? altitudeAreaCodes[
                    responseAreaData.city_planning.merged_areas[1]?.['altitude_area']?.[
                      'koudo_chiku'
                    ]
                  ] || (prefectureName === '東京都' ? '-' : 'N/A')
                : ''}
            </td>
            <td className={`py-2 px-4 md:w-1875 block md:table-cell`}>
              {responseAreaData.city_planning.merged_areas[2] !== undefined
                ? altitudeAreaCodes[
                    responseAreaData.city_planning.merged_areas[2]?.['altitude_area']?.[
                      'koudo_chiku'
                    ]
                  ] || (prefectureName === '東京都' ? '-' : 'N/A')
                : ''}
            </td>
            <td className={`py-2 px-4 md:w-1875 block md:table-cell`}>
              {responseAreaData.city_planning.merged_areas[3] !== undefined
                ? altitudeAreaCodes[
                    responseAreaData.city_planning.merged_areas[3]?.['altitude_area']?.[
                      'koudo_chiku'
                    ]
                  ] || (prefectureName === '東京都' ? '-' : 'N/A')
                : ''}
            </td>
          </Row>
          <Row
            className="w-full"
            label={
              <th
                scope="row"
                className={`pt-4 pb-2 md:py-5 px-4 md:text-right whitespace-nowrap text-black-base font-medium md:w-1/4 block md:table-cell`}
              >
                日影規制
              </th>
            }
          >
            <td className={`py-2 px-4 md:w-1875 block md:table-cell`}>
              {responseAreaData.city_planning.merged_areas[0] !== undefined
                ? shadeInfoSet(responseAreaData.city_planning.merged_areas[0]?.['shadow_area']) ||
                  'N/A'
                : ''}
            </td>
            <td className={`py-2 px-4 md:w-1875 block md:table-cell`}>
              {responseAreaData.city_planning.merged_areas[1] !== undefined
                ? shadeInfoSet(responseAreaData.city_planning.merged_areas[1]?.['shadow_area']) ||
                  'N/A'
                : ''}
            </td>
            <td className={`py-2 px-4 md:w-1875 block md:table-cell`}>
              {responseAreaData.city_planning.merged_areas[2] !== undefined
                ? shadeInfoSet(responseAreaData.city_planning.merged_areas[2]?.['shadow_area']) ||
                  'N/A'
                : ''}
            </td>
            <td className={`py-2 px-4 md:w-1875 block md:table-cell`}>
              {responseAreaData.city_planning.merged_areas[3] !== undefined
                ? shadeInfoSet(responseAreaData.city_planning.merged_areas[3]?.['shadow_area']) ||
                  'N/A'
                : ''}
            </td>
          </Row>
          <Row
            className="w-full"
            label={
              <th
                scope="row"
                className={`pt-4 pb-2 md:py-5 px-4 md:text-right whitespace-nowrap text-black-base font-medium md:w-1/4 block md:table-cell`}
              >
                建ぺい率
              </th>
            }
          >
            <td className={`py-2 px-4 md:w-1875 block md:table-cell`}>
              {unit_int_pct(
                responseAreaData.city_planning.merged_areas[0]?.['area_of_use']?.[
                  'building_coverage_ratio'
                ]
              )}
            </td>
            <td className={`py-2 px-4 md:w-1875 block md:table-cell`}>
              {unit_int_pct(
                responseAreaData.city_planning.merged_areas[1]?.['area_of_use']?.[
                  'building_coverage_ratio'
                ]
              )}
            </td>
            <td className={`py-2 px-4 md:w-1875 block md:table-cell`}>
              {unit_int_pct(
                responseAreaData.city_planning.merged_areas[2]?.['area_of_use']?.[
                  'building_coverage_ratio'
                ]
              )}
            </td>
            <td className={`py-2 px-4 md:w-1875 block md:table-cell`}>
              {unit_int_pct(
                responseAreaData.city_planning.merged_areas[3]?.['area_of_use']?.[
                  'building_coverage_ratio'
                ]
              )}
            </td>
          </Row>
          <Row
            className="w-full"
            label={
              <th
                scope="row"
                className={`pt-4 pb-2 md:py-5 px-4 md:text-right whitespace-nowrap text-black-base font-medium md:w-1/4 block md:table-cell`}
              >
                容積率
              </th>
            }
          >
            <td className={`py-2 px-4 md:w-1875 block md:table-cell`}>
              {unit_int_pct(
                responseAreaData.city_planning.merged_areas[0]?.['area_of_use']?.[
                  'floor_area_ratio'
                ]
              )}
            </td>
            <td className={`py-2 px-4 md:w-1875 block md:table-cell`}>
              {unit_int_pct(
                responseAreaData.city_planning.merged_areas[1]?.['area_of_use']?.[
                  'floor_area_ratio'
                ]
              )}
            </td>
            <td className={`py-2 px-4 md:w-1875 block md:table-cell`}>
              {unit_int_pct(
                responseAreaData.city_planning.merged_areas[2]?.['area_of_use']?.[
                  'floor_area_ratio'
                ]
              )}
            </td>
            <td className={`py-2 px-4 md:w-1875 block md:table-cell`}>
              {unit_int_pct(
                responseAreaData.city_planning.merged_areas[3]?.['area_of_use']?.[
                  'floor_area_ratio'
                ]
              )}
            </td>
          </Row>
          <Row
            className="w-full"
            label={
              <th
                scope="row"
                className={`pt-4 pb-2 md:py-5 px-4 md:text-right whitespace-nowrap text-black-base font-medium md:w-1/4 block md:table-cell`}
              >
                採用建ぺい率（平均）
              </th>
            }
          >
            <td className={`py-2 px-4 md:w-4/5 block md:table-cell`} colSpan={4}>
              {unit_pct(responseAreaData.city_planning?.['adopted_building_area_ratio'])}
            </td>
          </Row>
          <Row
            className="w-full"
            label={
              <th
                scope="row"
                className={`pt-4 pb-2 md:py-5 px-4 md:text-right whitespace-nowrap text-black-base font-medium md:w-1/4 block md:table-cell`}
              >
                採用容積率（平均）
              </th>
            }
          >
            <td className={`py-2 px-4 md:w-4/5 block md:table-cell`} colSpan={4}>
              {unit_pct(responseAreaData.city_planning?.['adopted_floor_area_ratio'])}
            </td>
          </Row>
          <input
            type="hidden"
            value={propertyData?.area_info}
            name="property[area_info]"
            id="property_area_info"
          />
        </Table>
        <div className="block text-xs px-4 mb-14 whitespace-normal text-primary font-small">
          ※
          敷地面積、領域面積は小数点第3位切り捨てとし、敷地面積と各領域面積の合計が異なる場合があります。また、情報データが提供されていない地域の場合は、表内に"N/A"と表示されます。
        </div>
      </div>
      {/* -- SP -- */}
      <div className="block md:hidden">
        <Table
          className="border-t border-b block mb-2"
          header={
            <div className="flex justify-between items-center bg-primary text-white py-2 px-4 text-sm">
              都市計画関係
            </div>
          }
        >
          {/* Area1 */}
          <Row
            className="w-full"
            label={
              <th
                scope="row"
                className={`pt-4 pb-2 md:py-5 px-4 md:text-right whitespace-nowrap text-black-base font-medium md:w-1/4 table-cell`}
              ></th>
            }
          >
            <td className={`py-2 px-4 md:w-1875 table-cell`}>領域1</td>
          </Row>
          <Row
            className="w-full"
            label={
              <th
                scope="row"
                className={`pt-4 pb-2 md:py-5 px-4 md:text-right whitespace-nowrap text-black-base font-medium md:w-1/4 table-cell`}
              >
                領域面積
              </th>
            }
          >
            <td className={`py-2 px-4 md:w-1875 table-cell`}>
              {unit_m2(responseAreaData.city_planning.merged_areas[0]?.['area'])}
            </td>
          </Row>
          <Row
            className="w-full"
            label={
              <th
                scope="row"
                className={`pt-4 pb-2 md:py-5 px-4 md:text-right whitespace-nowrap text-black-base font-medium md:w-1/4 table-cell`}
              >
                用途地域
              </th>
            }
          >
            <td className={`py-2 px-4 md:w-1875 table-cell`}>
              {responseAreaData.city_planning.merged_areas[0] !== undefined
                ? areaOfUseCodes[
                    responseAreaData.city_planning.merged_areas[0]?.['area_of_use']?.[
                      'youto_chiiki'
                    ]
                  ] || 'N/A'
                : ''}
            </td>
          </Row>
          <Row
            className="w-full"
            label={
              <th
                scope="row"
                className={`pt-4 pb-2 md:py-5 px-4 md:text-right whitespace-nowrap text-black-base font-medium md:w-1/4 table-cell`}
              >
                防火地域
              </th>
            }
          >
            <td className={`py-2 px-4 md:w-1875 table-cell`}>
              {responseAreaData.city_planning.merged_areas[0] !== undefined
                ? fireProtectionCodes[
                    responseAreaData.city_planning.merged_areas[0]?.['fire_protection']?.[
                      'bouka_chiiki'
                    ]
                  ] || (prefectureName === '東京都' ? '-' : 'N/A')
                : ''}
            </td>
          </Row>
          <Row
            className="w-full"
            label={
              <th
                scope="row"
                className={`pt-4 pb-2 md:py-5 px-4 md:text-right whitespace-nowrap text-black-base font-medium md:w-1/4 table-cell`}
              >
                高度地区
              </th>
            }
          >
            <td className={`py-2 px-4 md:w-1875 table-cell`}>
              {responseAreaData.city_planning.merged_areas[0] !== undefined
                ? altitudeAreaCodes[
                    responseAreaData.city_planning.merged_areas[0]?.['altitude_area']?.[
                      'koudo_chiku'
                    ]
                  ] || (prefectureName === '東京都' ? '-' : 'N/A')
                : ''}
            </td>
          </Row>
          <Row
            className="w-full"
            label={
              <th
                scope="row"
                className={`pt-4 pb-2 md:py-5 px-4 md:text-right whitespace-nowrap text-black-base font-medium md:w-1/4 table-cell`}
              >
                日影規制
              </th>
            }
          >
            <td className={`py-2 px-4 md:w-1875 table-cell`}>
              {responseAreaData.city_planning.merged_areas[0] !== undefined
                ? shadeInfoSet(responseAreaData.city_planning.merged_areas[0]?.['shadow_area']) ||
                  'N/A'
                : ''}
            </td>
          </Row>
          <Row
            className="w-full"
            label={
              <th
                scope="row"
                className={`pt-4 pb-2 md:py-5 px-4 md:text-right whitespace-nowrap text-black-base font-medium md:w-1/4 table-cell`}
              >
                建ぺい率
              </th>
            }
          >
            <td className={`py-2 px-4 md:w-1875 table-cell`}>
              {unit_int_pct(
                responseAreaData.city_planning.merged_areas[0]?.['area_of_use']?.[
                  'building_coverage_ratio'
                ]
              )}
            </td>
          </Row>
          <Row
            className="w-full"
            label={
              <th
                scope="row"
                className={`pt-4 pb-2 md:py-5 px-4 md:text-right whitespace-nowrap text-black-base font-medium md:w-1/4 table-cell`}
              >
                容積率
              </th>
            }
          >
            <td className={`py-2 px-4 md:w-1875 table-cell`}>
              {unit_int_pct(
                responseAreaData.city_planning.merged_areas[0]?.['area_of_use']?.[
                  'floor_area_ratio'
                ]
              )}
            </td>
          </Row>
          {/* Area2 */}
          <Row
            className="w-full"
            label={
              <th
                scope="row"
                className={`pt-4 pb-2 md:py-5 px-4 md:text-right whitespace-nowrap text-black-base font-medium md:w-1/4 table-cell`}
              ></th>
            }
          >
            <td className={`py-2 px-4 md:w-1875 table-cell`}>領域2</td>
          </Row>
          <Row
            className="w-full"
            label={
              <th
                scope="row"
                className={`pt-4 pb-2 md:py-5 px-4 md:text-right whitespace-nowrap text-black-base font-medium md:w-1/4 table-cell`}
              >
                領域面積
              </th>
            }
          >
            <td className={`py-2 px-4 md:w-1875 table-cell`}>
              {unit_m2(responseAreaData.city_planning.merged_areas[1]?.['area'])}
            </td>
          </Row>
          <Row
            className="w-full"
            label={
              <th
                scope="row"
                className={`pt-4 pb-2 md:py-5 px-4 md:text-right whitespace-nowrap text-black-base font-medium md:w-1/4 table-cell`}
              >
                用途地域
              </th>
            }
          >
            <td className={`py-2 px-4 md:w-1875 table-cell`}>
              {responseAreaData.city_planning.merged_areas[1] !== undefined
                ? areaOfUseCodes[
                    responseAreaData.city_planning.merged_areas[1]?.['area_of_use']?.[
                      'youto_chiiki'
                    ]
                  ] || 'N/A'
                : ''}
            </td>
          </Row>
          <Row
            className="w-full"
            label={
              <th
                scope="row"
                className={`pt-4 pb-2 md:py-5 px-4 md:text-right whitespace-nowrap text-black-base font-medium md:w-1/4 table-cell`}
              >
                防火地域
              </th>
            }
          >
            <td className={`py-2 px-4 md:w-1875 table-cell`}>
              {responseAreaData.city_planning.merged_areas[1] !== undefined
                ? fireProtectionCodes[
                    responseAreaData.city_planning.merged_areas[1]?.['fire_protection']?.[
                      'bouka_chiiki'
                    ]
                  ] || (prefectureName === '東京都' ? '-' : 'N/A')
                : ''}
            </td>
          </Row>
          <Row
            className="w-full"
            label={
              <th
                scope="row"
                className={`pt-4 pb-2 md:py-5 px-4 md:text-right whitespace-nowrap text-black-base font-medium md:w-1/4 table-cell`}
              >
                高度地区
              </th>
            }
          >
            <td className={`py-2 px-4 md:w-1875 table-cell`}>
              {responseAreaData.city_planning.merged_areas[1] !== undefined
                ? altitudeAreaCodes[
                    responseAreaData.city_planning.merged_areas[1]?.['altitude_area']?.[
                      'koudo_chiku'
                    ]
                  ] || (prefectureName === '東京都' ? '-' : 'N/A')
                : ''}
            </td>
          </Row>
          <Row
            className="w-full"
            label={
              <th
                scope="row"
                className={`pt-4 pb-2 md:py-5 px-4 md:text-right whitespace-nowrap text-black-base font-medium md:w-1/4 table-cell`}
              >
                日影規制
              </th>
            }
          >
            <td className={`py-2 px-4 md:w-1875 table-cell`}>
              {responseAreaData.city_planning.merged_areas[1] !== undefined
                ? shadeInfoSet(responseAreaData.city_planning.merged_areas[1]?.['shadow_area']) ||
                  'N/A'
                : ''}
            </td>
          </Row>
          <Row
            className="w-full"
            label={
              <th
                scope="row"
                className={`pt-4 pb-2 md:py-5 px-4 md:text-right whitespace-nowrap text-black-base font-medium md:w-1/4 table-cell`}
              >
                建ぺい率
              </th>
            }
          >
            <td className={`py-2 px-4 md:w-1875 table-cell`}>
              {unit_int_pct(
                responseAreaData.city_planning.merged_areas[1]?.['area_of_use']?.[
                  'building_coverage_ratio'
                ]
              )}
            </td>
          </Row>
          <Row
            className="w-full"
            label={
              <th
                scope="row"
                className={`pt-4 pb-2 md:py-5 px-4 md:text-right whitespace-nowrap text-black-base font-medium md:w-1/4 table-cell`}
              >
                容積率
              </th>
            }
          >
            <td className={`py-2 px-4 md:w-1875 table-cell`}>
              {unit_int_pct(
                responseAreaData.city_planning.merged_areas[1]?.['area_of_use']?.[
                  'floor_area_ratio'
                ]
              )}
            </td>
          </Row>
          {/* Area3 */}
          <Row
            className="w-full"
            label={
              <th
                scope="row"
                className={`pt-4 pb-2 md:py-5 px-4 md:text-right whitespace-nowrap text-black-base font-medium md:w-1/4 table-cell`}
              ></th>
            }
          >
            <td className={`py-2 px-4 md:w-1875 table-cell`}>領域3</td>
          </Row>
          <Row
            className="w-full"
            label={
              <th
                scope="row"
                className={`pt-4 pb-2 md:py-5 px-4 md:text-right whitespace-nowrap text-black-base font-medium md:w-1/4 table-cell`}
              >
                領域面積
              </th>
            }
          >
            <td className={`py-2 px-4 md:w-1875 table-cell`}>
              {unit_m2(responseAreaData.city_planning.merged_areas[2]?.['area'])}
            </td>
          </Row>
          <Row
            className="w-full"
            label={
              <th
                scope="row"
                className={`pt-4 pb-2 md:py-5 px-4 md:text-right whitespace-nowrap text-black-base font-medium md:w-1/4 table-cell`}
              >
                用途地域
              </th>
            }
          >
            <td className={`py-2 px-4 md:w-1875 table-cell`}>
              {responseAreaData.city_planning.merged_areas[2] !== undefined
                ? areaOfUseCodes[
                    responseAreaData.city_planning.merged_areas[2]?.['area_of_use']?.[
                      'youto_chiiki'
                    ]
                  ] || 'N/A'
                : ''}
            </td>
          </Row>
          <Row
            className="w-full"
            label={
              <th
                scope="row"
                className={`pt-4 pb-2 md:py-5 px-4 md:text-right whitespace-nowrap text-black-base font-medium md:w-1/4 table-cell`}
              >
                防火地域
              </th>
            }
          >
            <td className={`py-2 px-4 md:w-1875 table-cell`}>
              {responseAreaData.city_planning.merged_areas[2] !== undefined
                ? fireProtectionCodes[
                    responseAreaData.city_planning.merged_areas[2]?.['fire_protection']?.[
                      'bouka_chiiki'
                    ]
                  ] || (prefectureName === '東京都' ? '-' : 'N/A')
                : ''}
            </td>
          </Row>
          <Row
            className="w-full"
            label={
              <th
                scope="row"
                className={`pt-4 pb-2 md:py-5 px-4 md:text-right whitespace-nowrap text-black-base font-medium md:w-1/4 table-cell`}
              >
                高度地区
              </th>
            }
          >
            <td className={`py-2 px-4 md:w-1875 table-cell`}>
              {responseAreaData.city_planning.merged_areas[2] !== undefined
                ? altitudeAreaCodes[
                    responseAreaData.city_planning.merged_areas[2]?.['altitude_area']?.[
                      'koudo_chiku'
                    ]
                  ] || (prefectureName === '東京都' ? '-' : 'N/A')
                : ''}
            </td>
          </Row>
          <Row
            className="w-full"
            label={
              <th
                scope="row"
                className={`pt-4 pb-2 md:py-5 px-4 md:text-right whitespace-nowrap text-black-base font-medium md:w-1/4 table-cell`}
              >
                日影規制
              </th>
            }
          >
            <td className={`py-2 px-4 md:w-1875 table-cell`}>
              {responseAreaData.city_planning.merged_areas[2] !== undefined
                ? shadeInfoSet(responseAreaData.city_planning.merged_areas[2]?.['shadow_area']) ||
                  'N/A'
                : ''}
            </td>
          </Row>
          <Row
            className="w-full"
            label={
              <th
                scope="row"
                className={`pt-4 pb-2 md:py-5 px-4 md:text-right whitespace-nowrap text-black-base font-medium md:w-1/4 table-cell`}
              >
                建ぺい率
              </th>
            }
          >
            <td className={`py-2 px-4 md:w-1875 table-cell`}>
              {unit_int_pct(
                responseAreaData.city_planning.merged_areas[2]?.['area_of_use']?.[
                  'building_coverage_ratio'
                ]
              )}
            </td>
          </Row>
          <Row
            className="w-full"
            label={
              <th
                scope="row"
                className={`pt-4 pb-2 md:py-5 px-4 md:text-right whitespace-nowrap text-black-base font-medium md:w-1/4 table-cell`}
              >
                容積率
              </th>
            }
          >
            <td className={`py-2 px-4 md:w-1875 table-cell`}>
              {unit_int_pct(
                responseAreaData.city_planning.merged_areas[2]?.['area_of_use']?.[
                  'floor_area_ratio'
                ]
              )}
            </td>
          </Row>
          {/* Area4 */}
          <Row
            className="w-full"
            label={
              <th
                scope="row"
                className={`pt-4 pb-2 md:py-5 px-4 md:text-right whitespace-nowrap text-black-base font-medium md:w-1/4 table-cell`}
              ></th>
            }
          >
            <td className={`py-2 px-4 md:w-1875 table-cell`}>領域4</td>
          </Row>
          <Row
            className="w-full"
            label={
              <th
                scope="row"
                className={`pt-4 pb-2 md:py-5 px-4 md:text-right whitespace-nowrap text-black-base font-medium md:w-1/4 table-cell`}
              >
                領域面積
              </th>
            }
          >
            <td className={`py-2 px-4 md:w-1875 table-cell`}>
              {unit_m2(responseAreaData.city_planning.merged_areas[3]?.['area'])}
            </td>
          </Row>
          <Row
            className="w-full"
            label={
              <th
                scope="row"
                className={`pt-4 pb-2 md:py-5 px-4 md:text-right whitespace-nowrap text-black-base font-medium md:w-1/4 table-cell`}
              >
                用途地域
              </th>
            }
          >
            <td className={`py-2 px-4 md:w-1875 table-cell`}>
              {responseAreaData.city_planning.merged_areas[3] !== undefined
                ? areaOfUseCodes[
                    responseAreaData.city_planning.merged_areas[3]?.['area_of_use']?.[
                      'youto_chiiki'
                    ]
                  ] || 'N/A'
                : ''}
            </td>
          </Row>
          <Row
            className="w-full"
            label={
              <th
                scope="row"
                className={`pt-4 pb-2 md:py-5 px-4 md:text-right whitespace-nowrap text-black-base font-medium md:w-1/4 table-cell`}
              >
                防火地域
              </th>
            }
          >
            <td className={`py-2 px-4 md:w-1875 table-cell`}>
              {responseAreaData.city_planning.merged_areas[3] !== undefined
                ? fireProtectionCodes[
                    responseAreaData.city_planning.merged_areas[3]?.['fire_protection']?.[
                      'bouka_chiiki'
                    ]
                  ] || (prefectureName === '東京都' ? '-' : 'N/A')
                : ''}
            </td>
          </Row>
          <Row
            className="w-full"
            label={
              <th
                scope="row"
                className={`pt-4 pb-2 md:py-5 px-4 md:text-right whitespace-nowrap text-black-base font-medium md:w-1/4 table-cell`}
              >
                高度地区
              </th>
            }
          >
            <td className={`py-2 px-4 md:w-1875 table-cell`}>
              {responseAreaData.city_planning.merged_areas[3] !== undefined
                ? altitudeAreaCodes[
                    responseAreaData.city_planning.merged_areas[3]?.['altitude_area']?.[
                      'koudo_chiku'
                    ]
                  ] || (prefectureName === '東京都' ? '-' : 'N/A')
                : ''}
            </td>
          </Row>
          <Row
            className="w-full"
            label={
              <th
                scope="row"
                className={`pt-4 pb-2 md:py-5 px-4 md:text-right whitespace-nowrap text-black-base font-medium md:w-1/4 table-cell`}
              >
                日影規制
              </th>
            }
          >
            <td className={`py-2 px-4 md:w-1875 table-cell`}>
              {responseAreaData.city_planning.merged_areas[3] !== undefined
                ? shadeInfoSet(responseAreaData.city_planning.merged_areas[3]?.['shadow_area']) ||
                  'N/A'
                : ''}
            </td>
          </Row>
          <Row
            className="w-full"
            label={
              <th
                scope="row"
                className={`pt-4 pb-2 md:py-5 px-4 md:text-right whitespace-nowrap text-black-base font-medium md:w-1/4 table-cell`}
              >
                建ぺい率
              </th>
            }
          >
            <td className={`py-2 px-4 md:w-1875 table-cell`}>
              {unit_int_pct(
                responseAreaData.city_planning.merged_areas[3]?.['area_of_use']?.[
                  'building_coverage_ratio'
                ]
              )}
            </td>
          </Row>
          <Row
            className="w-full"
            label={
              <th
                scope="row"
                className={`pt-4 pb-2 md:py-5 px-4 md:text-right whitespace-nowrap text-black-base font-medium md:w-1/4 table-cell`}
              >
                容積率
              </th>
            }
          >
            <td className={`py-2 px-4 md:w-1875 table-cell`}>
              {unit_int_pct(
                responseAreaData.city_planning.merged_areas[3]?.['area_of_use']?.[
                  'floor_area_ratio'
                ]
              )}
            </td>
          </Row>

          <Row
            className="w-full"
            label={
              <th
                scope="row"
                className={`pt-4 pb-2 md:py-5 px-4 md:text-right whitespace-nowrap text-black-base font-medium md:w-1/4 table-cell`}
              >
                採用建ぺい率（平均）
              </th>
            }
          >
            <td className={`py-2 px-4 md:w-4/5 table-cell`} colSpan={4}>
              {unit_pct(responseAreaData.city_planning?.['adopted_building_area_ratio'])}
            </td>
          </Row>
          <Row
            className="w-full"
            label={
              <th
                scope="row"
                className={`pt-4 pb-2 md:py-5 px-4 md:text-right whitespace-nowrap text-black-base font-medium md:w-1/4 table-cell`}
              >
                採用容積率（平均）
              </th>
            }
          >
            <td className={`py-2 px-4 md:w-4/5 table-cell`} colSpan={4}>
              {unit_pct(responseAreaData.city_planning?.['adopted_floor_area_ratio'])}
            </td>
          </Row>
          <input
            type="hidden"
            value={propertyData?.area_info}
            name="property[area_info]"
            id="property_area_info"
          />
        </Table>
        <div className="block text-xs px-4 mb-14 whitespace-normal text-primary font-small">
          ※
          敷地面積、領域面積は小数点第3位切り捨てとし、敷地面積と各領域面積の合計が異なる場合があります。また、情報データが提供されていない地域の場合は、表内に"N/A"と表示されます。
        </div>
      </div>
    </>
  )
}

const InstantCityPlanningTable = ({ propertyData }) => {
  return (
    <>
      <div className="hidden md:block">
        <Table
          className="border-t border-b block mb-2"
          header={
            <div className="flex justify-between items-center bg-primary text-white py-2 px-4 text-sm">
              都市計画関係
            </div>
          }
        >
          <Row
            className="w-full"
            label={
              <th
                scope="row"
                className={`pt-4 pb-2 md:py-5 px-4 md:text-right whitespace-nowrap text-black-base font-medium md:w-1/4 block md:table-cell`}
              ></th>
            }
          >
            <td className={`py-2 px-4 md:w-1875 block md:table-cell`}>領域1</td>
            <td className={`py-2 px-4 md:w-1875 block md:table-cell`}>領域2</td>
            <td className={`py-2 px-4 md:w-1875 block md:table-cell`}>領域3</td>
            <td className={`py-2 px-4 md:w-1875 block md:table-cell`}>領域4</td>
          </Row>
          <Row
            className="w-full"
            label={
              <th
                scope="row"
                className={`pt-4 pb-2 md:py-5 px-4 md:text-right whitespace-nowrap text-black-base font-medium md:w-1/4 block md:table-cell`}
              >
                用途地域
              </th>
            }
          >
            <td className={`py-2 px-4 md:w-1875 block md:table-cell`}>
              {propertyData.instant_city_plannings[0] !== undefined
                ? propertyData.instant_city_plannings[0]?.['youto_chiiki'] || 'N/A'
                : ''}
            </td>
            <td className={`py-2 px-4 md:w-1875 block md:table-cell`}>
              {propertyData.instant_city_plannings[1] !== undefined
                ? propertyData.instant_city_plannings[1]?.['youto_chiiki'] || 'N/A'
                : ''}
            </td>
            <td className={`py-2 px-4 md:w-1875 block md:table-cell`}>
              {propertyData.instant_city_plannings[2] !== undefined
                ? propertyData.instant_city_plannings[2]?.['youto_chiiki'] || 'N/A'
                : ''}
            </td>
            <td className={`py-2 px-4 md:w-1875 block md:table-cell`}>
              {propertyData.instant_city_plannings[3] !== undefined
                ? propertyData.instant_city_plannings[3]?.['youto_chiiki'] || 'N/A'
                : ''}
            </td>
          </Row>
          <Row
            className="w-full"
            label={
              <th
                scope="row"
                className={`pt-4 pb-2 md:py-5 px-4 md:text-right whitespace-nowrap text-black-base font-medium md:w-1/4 block md:table-cell`}
              >
                建ぺい率
              </th>
            }
          >
            <td className={`py-2 px-4 md:w-1875 block md:table-cell`}>
              {unit_int_pct(propertyData.instant_city_plannings[0]?.['building_coverage_ratio'])}
            </td>
            <td className={`py-2 px-4 md:w-1875 block md:table-cell`}>
              {unit_int_pct(propertyData.instant_city_plannings[1]?.['building_coverage_ratio'])}
            </td>
            <td className={`py-2 px-4 md:w-1875 block md:table-cell`}>
              {unit_int_pct(propertyData.instant_city_plannings[2]?.['building_coverage_ratio'])}
            </td>
            <td className={`py-2 px-4 md:w-1875 block md:table-cell`}>
              {unit_int_pct(propertyData.instant_city_plannings[3]?.['building_coverage_ratio'])}
            </td>
          </Row>
          <Row
            className="w-full"
            label={
              <th
                scope="row"
                className={`pt-4 pb-2 md:py-5 px-4 md:text-right whitespace-nowrap text-black-base font-medium md:w-1/4 block md:table-cell`}
              >
                容積率
              </th>
            }
          >
            <td className={`py-2 px-4 md:w-1875 block md:table-cell`}>
              {unit_int_pct(propertyData.instant_city_plannings[0]?.['floor_area_ratio'])}
            </td>
            <td className={`py-2 px-4 md:w-1875 block md:table-cell`}>
              {unit_int_pct(propertyData.instant_city_plannings[1]?.['floor_area_ratio'])}
            </td>
            <td className={`py-2 px-4 md:w-1875 block md:table-cell`}>
              {unit_int_pct(propertyData.instant_city_plannings[2]?.['floor_area_ratio'])}
            </td>
            <td className={`py-2 px-4 md:w-1875 block md:table-cell`}>
              {unit_int_pct(propertyData.instant_city_plannings[3]?.['floor_area_ratio'])}
            </td>
          </Row>
        </Table>
      </div>
      {/* -- SP -- */}
      <div className="block md:hidden">
        <Table
          className="border-t border-b block mb-2"
          header={
            <div className="flex justify-between items-center bg-primary text-white py-2 px-4 text-sm">
              都市計画関係
            </div>
          }
        >
          {/* Area1 */}
          <Row
            className="w-full"
            label={
              <th
                scope="row"
                className={`pt-4 pb-2 md:py-5 px-4 md:text-right whitespace-nowrap text-black-base font-medium md:w-1/4 table-cell`}
              ></th>
            }
          >
            <td className={`py-2 px-4 md:w-1875 table-cell`}>領域1</td>
          </Row>
          <Row
            className="w-full"
            label={
              <th
                scope="row"
                className={`pt-4 pb-2 md:py-5 px-4 md:text-right whitespace-nowrap text-black-base font-medium md:w-1/4 table-cell`}
              >
                用途地域
              </th>
            }
          >
            <td className={`py-2 px-4 md:w-1875 table-cell`}>
              {propertyData.instant_city_plannings[0] !== undefined
                ? propertyData.instant_city_plannings[0]?.['youto_chiiki'] || 'N/A'
                : ''}
            </td>
          </Row>
          <Row
            className="w-full"
            label={
              <th
                scope="row"
                className={`pt-4 pb-2 md:py-5 px-4 md:text-right whitespace-nowrap text-black-base font-medium md:w-1/4 table-cell`}
              >
                建ぺい率
              </th>
            }
          >
            <td className={`py-2 px-4 md:w-1875 table-cell`}>
              {unit_int_pct(propertyData.instant_city_plannings[0]?.['building_coverage_ratio'])}
            </td>
          </Row>
          <Row
            className="w-full"
            label={
              <th
                scope="row"
                className={`pt-4 pb-2 md:py-5 px-4 md:text-right whitespace-nowrap text-black-base font-medium md:w-1/4 table-cell`}
              >
                容積率
              </th>
            }
          >
            <td className={`py-2 px-4 md:w-1875 table-cell`}>
              {unit_int_pct(propertyData.instant_city_plannings[0]?.['floor_area_ratio'])}
            </td>
          </Row>
          {/* Area2 */}
          <Row
            className="w-full"
            label={
              <th
                scope="row"
                className={`pt-4 pb-2 md:py-5 px-4 md:text-right whitespace-nowrap text-black-base font-medium md:w-1/4 table-cell`}
              ></th>
            }
          >
            <td className={`py-2 px-4 md:w-1875 table-cell`}>領域2</td>
          </Row>
          <Row
            className="w-full"
            label={
              <th
                scope="row"
                className={`pt-4 pb-2 md:py-5 px-4 md:text-right whitespace-nowrap text-black-base font-medium md:w-1/4 table-cell`}
              >
                用途地域
              </th>
            }
          >
            <td className={`py-2 px-4 md:w-1875 table-cell`}>
              {propertyData.instant_city_plannings[1] !== undefined
                ? propertyData.instant_city_plannings[1]?.['youto_chiiki'] || 'N/A'
                : ''}
            </td>
          </Row>
          <Row
            className="w-full"
            label={
              <th
                scope="row"
                className={`pt-4 pb-2 md:py-5 px-4 md:text-right whitespace-nowrap text-black-base font-medium md:w-1/4 table-cell`}
              >
                建ぺい率
              </th>
            }
          >
            <td className={`py-2 px-4 md:w-1875 table-cell`}>
              {unit_int_pct(propertyData.instant_city_plannings[1]?.['building_coverage_ratio'])}
            </td>
          </Row>
          <Row
            className="w-full"
            label={
              <th
                scope="row"
                className={`pt-4 pb-2 md:py-5 px-4 md:text-right whitespace-nowrap text-black-base font-medium md:w-1/4 table-cell`}
              >
                容積率
              </th>
            }
          >
            <td className={`py-2 px-4 md:w-1875 table-cell`}>
              {unit_int_pct(propertyData.instant_city_plannings[1]?.['floor_area_ratio'])}
            </td>
          </Row>
          {/* Area3 */}
          <Row
            className="w-full"
            label={
              <th
                scope="row"
                className={`pt-4 pb-2 md:py-5 px-4 md:text-right whitespace-nowrap text-black-base font-medium md:w-1/4 table-cell`}
              ></th>
            }
          >
            <td className={`py-2 px-4 md:w-1875 table-cell`}>領域3</td>
          </Row>
          <Row
            className="w-full"
            label={
              <th
                scope="row"
                className={`pt-4 pb-2 md:py-5 px-4 md:text-right whitespace-nowrap text-black-base font-medium md:w-1/4 table-cell`}
              >
                用途地域
              </th>
            }
          >
            <td className={`py-2 px-4 md:w-1875 table-cell`}>
              {propertyData.instant_city_plannings[2] !== undefined
                ? propertyData.instant_city_plannings[2]?.['youto_chiiki'] || 'N/A'
                : ''}
            </td>
          </Row>
          <Row
            className="w-full"
            label={
              <th
                scope="row"
                className={`pt-4 pb-2 md:py-5 px-4 md:text-right whitespace-nowrap text-black-base font-medium md:w-1/4 table-cell`}
              >
                建ぺい率
              </th>
            }
          >
            <td className={`py-2 px-4 md:w-1875 table-cell`}>
              {unit_int_pct(propertyData.instant_city_plannings[2]?.['building_coverage_ratio'])}
            </td>
          </Row>
          <Row
            className="w-full"
            label={
              <th
                scope="row"
                className={`pt-4 pb-2 md:py-5 px-4 md:text-right whitespace-nowrap text-black-base font-medium md:w-1/4 table-cell`}
              >
                容積率
              </th>
            }
          >
            <td className={`py-2 px-4 md:w-1875 table-cell`}>
              {unit_int_pct(propertyData.instant_city_plannings[2]?.['floor_area_ratio'])}
            </td>
          </Row>
          {/* Area4 */}
          <Row
            className="w-full"
            label={
              <th
                scope="row"
                className={`pt-4 pb-2 md:py-5 px-4 md:text-right whitespace-nowrap text-black-base font-medium md:w-1/4 table-cell`}
              ></th>
            }
          >
            <td className={`py-2 px-4 md:w-1875 table-cell`}>領域4</td>
          </Row>
          <Row
            className="w-full"
            label={
              <th
                scope="row"
                className={`pt-4 pb-2 md:py-5 px-4 md:text-right whitespace-nowrap text-black-base font-medium md:w-1/4 table-cell`}
              >
                用途地域
              </th>
            }
          >
            <td className={`py-2 px-4 md:w-1875 table-cell`}>
              {propertyData.instant_city_plannings[3] !== undefined
                ? propertyData.instant_city_plannings[3]?.['youto_chiiki'] || 'N/A'
                : ''}
            </td>
          </Row>
          <Row
            className="w-full"
            label={
              <th
                scope="row"
                className={`pt-4 pb-2 md:py-5 px-4 md:text-right whitespace-nowrap text-black-base font-medium md:w-1/4 table-cell`}
              >
                建ぺい率
              </th>
            }
          >
            <td className={`py-2 px-4 md:w-1875 table-cell`}>
              {unit_int_pct(propertyData.instant_city_plannings[3]?.['building_coverage_ratio'])}
            </td>
          </Row>
          <Row
            className="w-full"
            label={
              <th
                scope="row"
                className={`pt-4 pb-2 md:py-5 px-4 md:text-right whitespace-nowrap text-black-base font-medium md:w-1/4 table-cell`}
              >
                容積率
              </th>
            }
          >
            <td className={`py-2 px-4 md:w-1875 table-cell`}>
              {unit_int_pct(propertyData.instant_city_plannings[3]?.['floor_area_ratio'])}
            </td>
          </Row>
        </Table>
      </div>
    </>
  )
}

export default AreaInfo
