/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-call */
import * as React from 'react'

import { Table } from '@/components/Table'

type Props = {
  yearmonth_list: any[]
  source_ranking_datas: any[]
  target_to: any
}

const AnalysesSourceRankingChart: React.FC<Props> = ({
  yearmonth_list,
  source_ranking_datas,
  target_to,
}) => {
  const target_to_date = new Date(target_to)
  return (
    <Table topScrollbar={true}>
      <thead className="bg-gray-150 text-sm sticky top-0">
        <tr className="whitespace-nowrap">
          <th scope="col" className="py-2 px-4 font-medium">
            順位(
            {`${String(target_to_date.getFullYear()).substring(2, 4)}年${String(
              target_to_date.getMonth() + 1
            ).padStart(2, '0')}月`}
            )
          </th>
          {yearmonth_list.map((yearmonth, index) => (
            <th key={index} scope="col" className="py-2 px-4 font-medium">
              {yearmonth[0]}年{yearmonth[1]}月
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {source_ranking_datas.map((source_ranking_data, dataIndex) => {
          const values: any[] = Object.values(source_ranking_data.data)
          return (
            <>
              <tr
                className={`border-b border-gray-150 ${
                  dataIndex % 2 ? 'bg-gray-light' : 'bg-white'
                }`}
              >
                <td className="text-ellipsis overflow-hidden whitespace-nowrap max-w-[240px] p-2">
                  <b>
                    {source_ranking_data.rank}位.{source_ranking_data.label}
                  </b>
                </td>
                {values.map((data, index) => (
                  <td key={index} className="text-right p-2">
                    <b>{data['計']}</b>
                  </td>
                ))}
              </tr>
              {source_ranking_data.users.map((user, index) => (
                <tr
                  key={index}
                  className={`border-b border-gray-150 ${
                    dataIndex % 2 ? 'bg-gray-light' : 'bg-white'
                  }`}
                >
                  <td className="text-right  p-2">{user[0].name}</td>
                  {values.map((data, index) => (
                    <td key={index} className="text-right p-2">
                      <span>{data['内訳'][user[0].id]}</span>
                    </td>
                  ))}
                </tr>
              ))}
            </>
          )
        })}
      </tbody>
    </Table>
  )
}

export default AnalysesSourceRankingChart
