export const pricingTypeOptions: PricingTypeElement[] = [
  {
    text: 'フリー・プラン',
    value: 'basic',
  },
  {
    text: 'スタンダード',
    value: 'standard',
  },
  {
    text: 'エンタープライズ',
    value: 'enterprise',
  },
  {
    text: 'オーナー',
    value: 'owner',
  },
]

type PricingTypeElement = {
  text: string
  value: 'basic' | 'standard' | 'enterprise' | 'owner'
}

export type PricingTypeName = PricingTypeElement['value']