import * as React from 'react'
import Base from '@/components/Layout/Base'
import { Breadcrumb } from '@/components/Breadcrumb'
import { Button, AnchorButton } from '@/components/Button'
import { Select } from '@/components/Select'
import { Input } from '@/components/Input'
import type { PropertyDocumentFormatDetail } from '@/types/propertyDocumentFormatDetail'
import type { PropertyDocument } from '@/types/propertyDocument'
import type { User } from '@/types/user'

const defaultFormatDetailData = {
  column_name_1: '',
  column_name_2: '',
  element_name: '',
}

type Props = {
  current_user: User
  property: Property
  format_details?: PropertyDocumentFormatDetail
  property_document: PropertyDocument
  column_list: { value: string; text: string }[]
  authenticity_token: string
}

const PropertyDocumentFormatsDetailPage: React.FC<Props> = ({
  current_user,
  property,
  format_details,
  property_document,
  column_list,
  authenticity_token,
}) => {
  const title = '補正設定'
  const param = window.location.search
  const property_id = new URLSearchParams(param).get('property_id')
  const [formatDetailData, setFormatDetailData] = React.useState<PropertyDocumentFormatDetail>(format_details)
  const onChangeFormatDetailData = (e, index, column) => {
    const format_details = [...formatDetailData]
    format_details.splice(index, 1, {
      ...format_details[index],
      property_document_correction: {[column]: e.target.value},
    })
    setFormatDetailData(format_details)
  }

  return (
    <Base
      current_user={current_user}
      title={title}
      breadcrumb={
        <Breadcrumb
          link_texts={['物件管理', '帳票作成', title]}
          property_id={property_id}
          tab="property_documents"
        />
      }
      submenuCategory="properties"
    >
      <form
        className="mt-8"
        action={`/property_documents/${property_document.id}/upsert_correction`}
        acceptCharset="UTF-8"
        method="post"
      >
        <input type="hidden" name="_method" value="post" />
        <input type="hidden" name="authenticity_token" value={authenticity_token} />

        <div>
          <div>帳票設定名：{property_document.name} </div>
          <div className="py-6">
            <table className="flex flex-col gap-1">
              {formatDetailData.map((format_detail, i) => (
                <tr className="flex">
                  {format_detail.colspan ?
                    <td colspan="{format_detail.colspan}" className="w-2/5">{format_detail.column_name_1}</td>:
                    <>
                      <td className="w-1/5">{format_detail.column_name_1}</td>
                      <td className="w-1/5">{format_detail.column_name_2}</td>
                    </>
                  }
                  <td className="w-1/5">{format_detail.element_name}</td>
                  <td className="w-1/5 mr-2">
                    <Input
                      value={format_detail.property_document_correction && format_detail.property_document_correction.correction_text}
                      name={`property_document_correction[${i}][correction_text]`}
                      id="property_document_correction_correction_text"
                      className="w-full"
                      placeholder="(補正テキスト)"
                      onChange={(e) => onChangeFormatDetailData(e, i, 'correction_text')}
                    />
                  </td>
                  <td className="w-1/5">
                    <Select
                      value={format_detail.property_document_correction && format_detail.property_document_correction.position}
                      name={`property_document_correction[${i}][position]`}
                      id="property_document_correction_position"
                      className="text-sm"
                      options={column_list}
                      onChange={(e) => onChangeFormatDetailData(e, i, 'position')}
                    />
                    <input type="hidden" name={`property_document_correction[${i}][property_document_format_detail_id]`} value={format_detail.id} />
                  </td>
                </tr>
              ))}
            </table>
          </div>
        </div>
        <div className="bg-white flex justify-center items-center w-full left-0 md:gap-4 border-t border-gray-250 pt-2 mb-3">
          <AnchorButton
            className="w-full md:w-40 md:h-auto text-primary-font border-white"
            outline
            variant="text"
            href={`/properties/${property.hashid}?tab=property_documents`}
          >
            キャンセル
          </AnchorButton>
          <Button className="w-full md:w-40 md:h-auto">編集を保存</Button>
        </div>
      </form>
    </Base>
  )
}

export default PropertyDocumentFormatsDetailPage
