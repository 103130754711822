import * as React from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { Button, AnchorButton } from '@/components/Button'
import { Stage, Layer, Image as KonvaImage, Line, Circle, Text, Rect } from 'react-konva'
import '@/components/GoogleMapVolume/style.css'
import { Input } from '@/components/Input'
import { UserContext } from "@/components/Layout/Base";
import { convertPointToLatlng } from "./helpers/ConvertPointToLatlng"
import { Select } from '@/components/Select'
import ClearIcon from "@material-ui/icons/Clear"
import LoadingModal from "./LoadingModal"
import Loading from "./Loading"
import {dataPrecisionSetup} from "@/utils/cammedFormat";
import * as pdfjsLib from 'pdfjs-dist';
import { PDFDocumentProxy, RenderTask } from 'pdfjs-dist/types/src/display/api';
import pdfWorker from 'pdfjs-dist/build/pdf.worker.entry';
import { debounce } from 'lodash'

pdfjsLib.GlobalWorkerOptions.workerSrc = pdfWorker

type Props = {
  isOpen: boolean,
  closeModal: () => void,
  volume_check_detail,
  ref_volume_check_parameters,
  plan_number,
  authenticity_token,
}
const setOptions = [
  {
    value: '0',
    text: '測量図 - 形状',
  },
  {
    value: '1',
    text: '測量図 - 座標',
  },
  {
    value: '2',
    text: '公図',
  },
]

const roadNumber = Array.from({ length: 50 }, (_, i) => `(${i + 1})`);

export const VolumeCheckChooseModal: React.FC<Props> = ({
    isOpen,
    closeModal,
    volume_check_detail,
    ref_volume_check_parameters,
    plan_number,
    authenticity_token,
}) => {
  const size = 2
  const [previewImage, setPreviewImage] = React.useState<string | null>(null)
  const [image, setImage] = React.useState(null);
  const formRef = React.useRef(null)
  const [containerDimensions, setContainerDimensions] = React.useState({ width: 0, height: 0 });
  const containerRef = React.useRef(null);
  const [plottingMode, setPlottingMode] = React.useState("");
  const [checkboxValues, setCheckboxValues] = React.useState('0');
  const [imageSize, setImageSize] = React.useState({width: 0, height: 0})
  let url = `/properties/${volume_check_detail.property.hashid}/volume_check_requests/update_request_parameters`
  const ref = new URLSearchParams(window.location.search).get('ref')
  const { user } = React.useContext(UserContext)
  const [showMagnifier, setShowMagnifier] = React.useState(false);
  const [[imgWidth, imgHeight], setSizes] = React.useState([0, 0]);
  const [[x, y], setXY] = React.useState([0, 0]);
  const [isZoom, setIsZoom] = React.useState(false);
  const fileInputRef = React.useRef(null)
  const [isImageLoaded, setIsImageLoaded] = React.useState(false);
  const [loading, setLoading] = React.useState(false)
  const [paramsJson, setParamsJson] = React.useState(false)
  const [formData, setFormData] = React.useState(null)
  const [selection, setSelection] = React.useState(null);
  const [scopeFlg, setScopeFlg] = React.useState(false);
  const [isSelecting, setIsSelecting] = React.useState(false);
  const [isSelectionEnabled, setIsSelectionEnabled] = React.useState(false);
  const [selectionStart, setSelectionStart] = React.useState(null)
  const [loadMessage, setLoadMessage] = React.useState('処理中…')
  const magnifierHeight = 100
  const magnifieWidth = 100
  const zoomLevel = 3.5
  const closeThreshold = 5;
  const [currentPage, setCurrentPage] = React.useState(0);
  const [totalPages, setTotalPages] = React.useState(0);
  const [showArrows, setShowArrows] = React.useState(false);
  const renderTaskRef = React.useRef<RenderTask | null>(null);
  const [pdfFile, setPdfFile] = React.useState(null)
  const pageRef = React.useRef<HTMLDivElement>(null)
  const [loadedImage, setLoadedImage] = React.useState([])
  const [errorMessage, setErrorMessage] = React.useState(0)
  const [outLinesTmp, setOutLinesTmp] = React.useState([])
  if (ref) {
    url += `?ref=${ref}`
  }
  React.useEffect(() => {
    if (containerRef.current) {
      setContainerDimensions({
        width: containerRef.current.offsetWidth,
        height: containerRef.current.offsetHeight,
      });
    }
  }, [containerRef.current]);
  React.useEffect(() => {
    if (previewImage) {
      const img = new window.Image();
      img.src = previewImage;
      img.onload = () => {
        setImage(img);
        const width = img.width
        const height = img.height
        let pageHeight = 0
        if (loadedImage.length > 0) {
          pageHeight = 24
        }
        const ratioWidth = containerDimensions.width / width
        const imageWidth1 = width * ratioWidth
        const imageHeight1 = height * ratioWidth
        const ratioHeight = (containerDimensions.height  - pageHeight) / height
        const imageWidth2 = width * ratioHeight
        const imageHeight2 = height * ratioHeight
        if (imageWidth1 <= containerDimensions.width && imageHeight1 <= containerDimensions.height  - pageHeight) {
          setImageSize({width: imageWidth1, height: imageHeight1})
          setSizes([imageWidth1, imageHeight1])
        } else {
          setImageSize({width: imageWidth2, height: imageHeight2})
          setSizes([imageWidth2, imageHeight2])
        }
        setIsImageLoaded(true)
      };
    }
  }, [previewImage]);

  React.useEffect(() => {
    if (previewImage && containerDimensions) {
      const img = new window.Image();
      img.src = previewImage;
      img.onload = () => {
        setImage(img);
        const width = img.width
        const height = img.height
        let pageHeight = 0
        if (loadedImage.length > 0) {
          pageHeight = 24
        }
        const ratioWidth = containerDimensions.width / width
        const imageWidth1 = width * ratioWidth
        const imageHeight1 = height * ratioWidth
        const ratioHeight = (containerDimensions.height  - pageHeight) / height
        const imageWidth2 = width * ratioHeight
        const imageHeight2 = height * ratioHeight
        if (imageWidth1 <= containerDimensions.width && imageHeight1 <= containerDimensions.height  - pageHeight) {
          setImageSize({width: imageWidth1, height: imageHeight1})
          setSizes([imageWidth1, imageHeight1])
        } else {
          setImageSize({width: imageWidth2, height: imageHeight2})
          setSizes([imageWidth2, imageHeight2])
        }
        setIsImageLoaded(true)
      };
    }
  }, [containerDimensions]);

  React.useEffect(() => {
    const handleResize = debounce(() => {
      if (containerRef.current) {
        setContainerDimensions({
          width: containerRef.current.offsetWidth,
          height: containerRef.current.offsetHeight,
        });
      }
    }, 200);

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);


  React.useEffect(() => {
    const loadPdf = async () => {
      if (!pdfFile) {
        setPdfFile(null)
        return
      }

      if (renderTaskRef.current) {
        renderTaskRef.current.cancel();
      }

      const pdfUrl = URL.createObjectURL(pdfFile);
      const pdf: PDFDocumentProxy = await pdfjsLib.getDocument(pdfUrl).promise;
      const numPages = pdf.numPages;
      setTotalPages(numPages);
      const loadedImages: HTMLImageElement[] = [];

      for (let pageNum = 1; pageNum <= numPages; pageNum++) {
        const page = await pdf.getPage(pageNum);

        const scale = 1.5;
        const viewport = page.getViewport({ scale });

        const canvas = document.createElement('canvas');
        const context = canvas.getContext('2d');

        if (context) {
          canvas.height = viewport.height;
          canvas.width = viewport.width;

          const renderContext = {
            canvasContext: context,
            viewport: viewport,
          };

          renderTaskRef.current = page.render(renderContext);

          try {
            await renderTaskRef.current.promise;
            const img = new window.Image();
            img.src = canvas.toDataURL('image/png');
            img.onload = () => {
              loadedImages.push(img);
              if (loadedImages.length === numPages) {
                setLoadedImage(loadedImages)
                setPreviewImage(loadedImages[currentPage].getAttribute('src'));
              }
            };
          } catch (error) {
            console.error('An error occurred while retrieving the contents of the file');
          }
        }
      }
    };
    if (pdfFile) {
      loadPdf();
      return () => {
        if (renderTaskRef.current) {
          renderTaskRef.current.cancel();
        }
      };
    }
  }, [pdfFile])

  const [lines, setLines] = React.useState([]);
  const [startPoint, setStartPoint] = React.useState(null);
  const [tempLine, setTempLine] = React.useState(null);
  const [drawingEnabled, setDrawingEnabled] = React.useState(false);
  const stageRef = React.useRef(null);
  const [dragStartPos , setDragStartPos] = React.useState(null)
  const [measuringScale, setMeasuringScale] = React.useState("");
  const coordinates = React.useRef()

  const getMousePos = (stage) => {
    const pointerPosition = stage.getPointerPosition();
    return [pointerPosition.x, pointerPosition.y];
  };

  const handleMouseDown = (e) => {
    if (isSelectionEnabled && !isSelecting) {
      const pos = getMousePos(e.target.getStage());
      setSelectionStart(pos);
      setSelection(null);
      setIsSelecting(true);
    }
    if (!drawingEnabled || startPoint !== null) return;
    const pos = e.target.getStage().getPointerPosition();
    const stage = stageRef.current;
    const scale = stage.scaleX();
    const mousePos = {
      x: (pos.x - stage.x()) / scale,
      y: (pos.y - stage.y()) / scale
    };

    if (mousePos.x < 0) mousePos.x = 0;
    if (mousePos.y < 0) mousePos.y = 0;
    if (mousePos.x > stage.width()) mousePos.x = stage.width();
    if (mousePos.y > stage.height()) mousePos.y = stage.height();

    setStartPoint({ x: mousePos.x, y: mousePos.y });
  };

  const handleTouchStart = (e) => {
    handleMouseDown({ target: { getStage: () => stageRef.current }, ...e });
  }

  const handleMouseMove = (e) => {
    const pos = e.target.getStage().getPointerPosition();
    if (isSelectionEnabled && isSelecting) {
      const stage = e.target.getStage();
      const endPos = getMousePos(stage);
      if (selectionStart) {
        setSelection({
          x: Math.min(selectionStart[0], endPos[0]),
          y: Math.min(selectionStart[1], endPos[1]),
          width: Math.abs(endPos[0] - selectionStart[0]),
          height: Math.abs(endPos[1] - selectionStart[1]),
        });
      }
    } else {
      setXY([pos.x, pos.y])
    }
    if (!startPoint) return;

    const stage = stageRef.current;
    const scale = stage.scaleX();
    const mousePos = {
      x: (pos.x - stage.x()) / scale,
      y: (pos.y - stage.y()) / scale
    };

    if (mousePos.x < 0) mousePos.x = 0;
    if (mousePos.y < 0) mousePos.y = 0;
    if (mousePos.x > stage.width()) mousePos.x = stage.width();
    if (mousePos.y > stage.height()) mousePos.y = stage.height();

    const currentEndPoint = { x: mousePos.x, y: mousePos.y };

    setTempLine({ start: startPoint, end: currentEndPoint });
  };

  const handleTouchMove = (e) => {
    handleMouseMove({ target: { getStage: () => stageRef.current }, ...e });
  };


  const handleMouseUp = () => {
    if (isSelectionEnabled && isSelecting) {
      setIsSelecting(false);
      setIsSelectionEnabled(false);
    }
    if (!drawingEnabled || !tempLine) return;
    const isFirstPointClosed = lines.length > 0 &&
        Math.sqrt(Math.pow(tempLine.end.x - lines[0].start.x, 2) +
            Math.pow(tempLine.end.y - lines[0].start.y, 2)) < closeThreshold;

    if (isFirstPointClosed) {
      setLines([...lines, { ...tempLine, end: lines[0].start }]);
      setStartPoint(null);
      setDrawingEnabled(false);
    } else {
      setLines([...lines, tempLine]);
      setStartPoint(tempLine.end);
    }
    setTempLine(null);
  };

  const handleTouchEnd = () => {
    handleMouseUp();
  };

  const handleStartDrawing = () => {
    const isClosedShape = lines.length > 2 && Math.sqrt(Math.pow(lines[0].start.x - lines[lines.length - 1].end.x, 2) + Math.pow(lines[0].start.y - lines[lines.length - 1].end.y, 2)) < closeThreshold;

    if (isClosedShape) {
      clearDrawing();
    }
    setDrawingEnabled(true);
  };

  const clearDrawing = () => {
    setLines([]);
    setDrawingEnabled(false);
    setStartPoint(null);
    setTempLine(null);
  };


  const handleDragStart = (e) => {
    const pos = e.target.getStage().getPointerPosition();
    setDragStartPos({ x: pos.x, y: pos.y });
  };
  const handleTouchDragStart = (e) => {
    handleDragStart({ target: { getStage: () => stageRef.current }, ...e });
  };

  const handleDragMove = (e) => {
    if (!dragStartPos) return;

    const pos = e.target.getStage().getPointerPosition();
    const deltaX = pos.x - dragStartPos.x;
    const deltaY = pos.y - dragStartPos.y;

    const stage = stageRef.current;
    stage.position({
      x: stage.x() + deltaX,
      y: stage.y() + deltaY
    });
    stage.batchDraw();

    setDragStartPos({ x: pos.x, y: pos.y });
  };

  const handleTouchDragMove = (e) => {
    handleDragMove({ target: { getStage: () => stageRef.current }, ...e });
  };

  const handleDragEnd = () => {
    setDragStartPos(null);
  };

  const handleTouchDragEnd = () => {
    handleDragEnd();
  };

  const isShapeClosed = () => {
    return lines.length >= 2 &&
        Math.sqrt(Math.pow(lines[0].start.x - lines[lines.length - 1].end.x, 2) +
            Math.pow(lines[0].start.y - lines[lines.length - 1].end.y, 2)) < closeThreshold;
  };

  const getPosition = () => {
    const position = []
    if (outLinesTmp.length > 0) {
      outLinesTmp.map((line)=> {
        if (stageRef.current) {
          position.push([line.start.x + stageRef.current.container().getBoundingClientRect().left, -(line.start.y + stageRef.current.container().getBoundingClientRect().top)])
        } else {
          position.push([line.start.x , -line.start.y])
        }
      })
    } else {
      lines.map((line)=> {
        if (stageRef.current) {
          position.push([line.start.x + stageRef.current.container().getBoundingClientRect().left, -(line.start.y + stageRef.current.container().getBoundingClientRect().top)])
        } else {
          position.push([line.start.x , -line.start.y])
        }
      })
    }

    coordinates.current = JSON.parse(convertPointToLatlng(position, Number(volume_check_detail.property.address_geocoding[0]), Number(volume_check_detail.property.address_geocoding[1]), Number(measuringScale)))
  }

  const handleCapture = () => {
    const stage = stageRef.current.getStage();
    const currentSelection = selection;
    setSelection(null);
    let dataURL
    setTimeout(async () => {
      if (currentSelection) {
        const canvas = document.createElement('canvas');
        const context = canvas.getContext('2d');
        const stageCanvas = stage.toCanvas();

        canvas.width = currentSelection.width;
        canvas.height = currentSelection.height;

        context.drawImage(
            stageCanvas,
            currentSelection.x,
            currentSelection.y,
            currentSelection.width,
            currentSelection.height,
            0,
            0,
            currentSelection.width,
            currentSelection.height
        );

        dataURL = canvas.toDataURL();
      } else {
        dataURL = stage.toDataURL();
      }

      setSelection(currentSelection);
      const formDatas = new FormData();
      formDatas.append("image", dataURL)
      formDatas.append("optionsType", checkboxValues)
      const response = await fetch(`/properties/${volume_check_detail.property.hashid}/volume_check_requests/upload_image_json`, {
        headers: {
          'X-CSRF-Token': authenticity_token
        },
        method: 'POST',
        body: formDatas
      });
      if (!response.ok) {
        setLoadMessage('システムエラーのため、改めて試してください。')
      } else {
        const pointsTmp = []
        const json = await response.json();
        if (json.polygons.length === 0 || ((checkboxValues === "0" || checkboxValues === "2") && json.polygons[0].points.length === 0) || (checkboxValues === "1" && json.polygons[0].coordinates.length === 0)) {
          setLoadMessage('データ正しく取得できませんでした。改めて試してください。')
        } else {
          if (checkboxValues === "0" || checkboxValues === "2") {
            for (let i = 0; i < json.polygons.length; i++) {
              const polygonsTmp = []
              for (let j = 0; j < json.polygons[i].points.length; j++) {
                if (j < 10) {
                  const indexX = 'X00' + (j + 1).toString()
                  const indexY = 'Y00' + (j + 1).toString()
                  if (j === json.polygons[i].points.length - 1) {
                    polygonsTmp.push(
                        {
                          'start': {'x': json.polygons[i].points[j][indexX], 'y': json.polygons[i].points[j][indexY]},
                          'end': {'x': json.polygons[i].points[0]['X001'], 'y': json.polygons[i].points[0]['Y001']}
                        })
                  } else {
                    const indexX2 = 'X00' + (j + 2).toString()
                    const indexY2 = 'Y00' + (j + 2).toString()
                    polygonsTmp.push(
                        {
                          'start': {'x': json.polygons[i].points[j][indexX], 'y': json.polygons[i].points[j][indexY]},
                          'end': {'x': json.polygons[i].points[j + 1][indexX2], 'y': json.polygons[i].points[j + 1][indexY2]}
                        })
                  }
                } else {
                  const indexX = 'X0' + (j + 1).toString()
                  const indexY = 'Y0' + (j + 1).toString()
                  if (j === json.polygons[i].points.length - 1) {
                    polygonsTmp.push(
                        {
                          'start': {'x': json.polygons[i].points[j][indexX], 'y': json.polygons[i].points[j][indexY]},
                          'end': {'x': json.polygons[i].points[0]['X001'], 'y': json.polygons[i].points[0]['Y001']}
                        })
                  } else {
                    const indexX2 = 'X0' + (j + 2).toString()
                    const indexY2 = 'Y0' + (j + 2).toString()
                    polygonsTmp.push(
                        {
                          'start': {'x': json.polygons[i].points[j][indexX], 'y': json.polygons[i].points[j][indexY]},
                          'end': {'x': json.polygons[i].points[j + 1][indexX2], 'y': json.polygons[i].points[j + 1][indexY2]}
                        })
                  }
                }
              }
              pointsTmp[i] = polygonsTmp
            }
          } else if (checkboxValues === "1") {
            for (let i = 0; i < json.polygons[0].coordinates.length; i++) {
              if (i < 10) {
                const indexX = 'X00' + (i + 1).toString()
                const indexY = 'Y00' + (i + 1).toString()
                if (i === json.polygons[0].coordinates.length - 1) {
                  pointsTmp.push(
                      {
                        'start': {'x': json.polygons[0].coordinates[i][indexX], 'y': json.polygons[0].coordinates[i][indexY]},
                        'end': {'x': json.polygons[0].coordinates[0]['X001'], 'y': json.polygons[0].coordinates[0]['Y001']}
                      })
                } else {
                  const indexX2 = 'X00' + (i + 2).toString()
                  const indexY2 = 'Y00' + (i + 2).toString()
                  pointsTmp.push(
                      {
                        'start': {'x': json.polygons[0].coordinates[i][indexX], 'y': json.polygons[0].coordinates[i][indexY]},
                        'end': {'x': json.polygons[0].coordinates[i + 1][indexX2], 'y': json.polygons[0].coordinates[i + 1][indexY2]}
                      })
                }
              } else {
                const indexX = 'X0' + (i + 1).toString()
                const indexY = 'Y0' + (i + 1).toString()
                if (i === json.polygons[0].coordinates.length - 1) {
                  pointsTmp.push(
                      {
                        'start': {'x': json.polygons[0].coordinates[i][indexX], 'y': json.polygons[0].coordinates[i][indexY]},
                        'end': {'x': json.polygons[0].coordinates[0]['X001'], 'y': json.polygons[0].coordinates[0]['Y001']}
                      })
                } else {
                  const indexX2 = 'X0' + (i + 2).toString()
                  const indexY2 = 'Y0' + (i + 2).toString()
                  pointsTmp.push(
                      {
                        'start': {'x': json.polygons[0].coordinates[i][indexX], 'y': json.polygons[0].coordinates[i][indexY]},
                        'end': {'x': json.polygons[0].coordinates[i + 1][indexX2], 'y': json.polygons[0].coordinates[i + 1][indexY2]}
                      })
                }
              }
            }
          }
        }
        setLines(pointsTmp)
        setParamsJson(true)
      }
      clearSelection()
    }, 0);
  };

  const clearSelection = () => {
    setSelection(null);
    setIsSelecting(false);
    setIsSelectionEnabled(false);
  };

  const handlePrevPage = () => {
    setCurrentPage((prevPage) => (prevPage === 0 ? totalPages - 1 : prevPage - 1));
    setPreviewImage(loadedImage[currentPage === 0 ? totalPages - 1 : currentPage - 1].getAttribute('src'))
  };

  const handleNextPage = () => {
    setCurrentPage((prevPage) => (prevPage === totalPages - 1 ? 0 : prevPage + 1));
    setPreviewImage(loadedImage[currentPage === totalPages - 1 ? 0 : currentPage + 1].getAttribute('src'))
  };

  const handleSubmit = () => {
    getPosition()
    const parameters = ref_volume_check_parameters ? JSON.parse(ref_volume_check_parameters) : null
    const ref_unit_area = parameters ? parameters?.unit_setting?.unit_area : null
    const ref_plan_name = parameters ? parameters?.plan_setting?.plan_name : null
    const ref_plan_number = parameters ? parameters.plan_number : null
    const ref_building_use = parameters ? parameters.building_use : null
    const ref_building_structure = parameters ? parameters.building_structure : null
    const ref_manager_name = parameters ? parameters.manager_name : null
    const ref_project_name = parameters ? parameters.project_name : null
    const params = {
      center: {
        lat: Number((document.getElementById('center_lat') as HTMLInputElement).value),
        lng: Number((document.getElementById('center_lng') as HTMLInputElement).value),
      },
      borders: [],
      plan_setting: {
        chome: (document.getElementById('chome') as HTMLInputElement).value,
        prefecture_name: (document.getElementById('prefecture_name') as HTMLInputElement).value,
        city_name: (document.getElementById('city_name') as HTMLInputElement).value,
        plan_name: ref_plan_name ? ref_plan_name : '',
        administrative_name: (document.getElementById('administrative_name') as HTMLInputElement)
            .value,
        plan_number: ref_plan_number ? ref_plan_number : plan_number,
        building_use: ref_building_use ? ref_building_use : '共同住宅',
        building_structure: ref_building_structure ? ref_building_structure : 'RC',
        manager_name: ref_manager_name ? ref_manager_name : user.name,
        project_name: ref_project_name ? ref_project_name : volume_check_detail.property.name || volume_check_detail.property.city + volume_check_detail.property.town + volume_check_detail.property.chome,
        customization: {
          shade_regulation: 'false',
          compact_residence: 'false',
        },
      },
      city_planning_setting: {
        guideline: 1,
        district_planning: 1,
        city_planning_road: 1,
      },
      surroundings: [],
      building_setting: {
        floors: [],
        floors_above_ground: 15,
        floors_under_ground: 0,
        max_height: 50,
        floor_height: 3,
        gf_slab_level: 0,
        design_ground_level: 0,
        min_offset_road: 0.6,
        min_offset_neighbor: 0.6,
        sky_factor: 1,
        inverse_shadow: 2,
        number_of_units: 20,
        setting_building_area_ratio: '0, -10, -20, -30',
      },
      unit_setting: {
        unit_area: ref_unit_area ? ref_unit_area : '25',
        dwelling_unit_frontage: 3,
        balcony_width: 1.1,
        hallway_width: 1.2,
        entrance_area: 0,
        admin_room: 1,
        waste_storage: 1,
        elevator_number: 1,
      },
    }
    for (let i = 0; i < coordinates.current.length; i++) {
      params.borders.push({
        id: i + 1,
        to: i === coordinates.current.length - 1 ? {lat: Number(coordinates.current[0].lat), lng: Number(coordinates.current[0].lng)} : { lat: Number(coordinates.current[i + 1].lat), lng: Number(coordinates.current[i + 1].lng) },
        from: {lat: Number(coordinates.current[i].lat), lng: Number(coordinates.current[i].lng), height: 0 },
        border_type_code:4,
        set_back_method: 0,
        width: 0,
      })
      params.surroundings.push({
        border_id: i + 1,
        border_type_code: 4,
        width: 0,
      })
    }
    for (let i = 0; i < 15; i++) {
      params.building_setting.floors.push({
        floor_number: i + 1,
        max_floor_height: 3,
      })
    }
    const updatedParamsValue = JSON.stringify(params)
    const adjustedParametersInput = document.getElementById('parameters')
    if (adjustedParametersInput) {
      ;(adjustedParametersInput as HTMLInputElement).value = updatedParamsValue
    }

    closeModal()
  }

  return (
    <Transition appear show={isOpen} as={React.Fragment}>
      <Dialog className="relative z-10" onClose={() =>{}}>
        <Transition.Child
          as={React.Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center text-center">
            <Transition.Child
              as={React.Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel
                  className="w-[60vw] transform rounded-2xl bg-white text-left align-middle shadow-xl transition-all">
                <Dialog.Title
                    as="h3"
                    className="text-base font-medium p-6 leading-6 text-gray-700"
                >
                  <ClearIcon className="float-right cursor-pointer" onClick={() => {
                    closeModal()
                    const timer = setTimeout(() => {
                      setPreviewImage(null)
                      setLoadedImage([])
                      setErrorMessage(0)
                      setIsImageLoaded(false)
                      setIsZoom(false)
                      setPlottingMode("")
                      setCheckboxValues("0")
                      setMeasuringScale("")
                      clearDrawing()
                      if (fileInputRef.current) {
                        fileInputRef.current.value = '';
                      }
                      clearSelection()
                      setParamsJson(false)
                      setScopeFlg(false)
                    }, 300)
                    return () => clearTimeout(timer)
                  }} />
                </Dialog.Title>
                <div className="border-t border-gray-150"></div>
                <form
                    ref={formRef}
                    id="length_edit"
                    action={url}
                    acceptCharset="UTF-8"
                    method="post"
                >
                  <input type="hidden" name="parameters" id="parameters"/>
                  <input type="hidden" name="authenticity_token" value={authenticity_token}/>
                  <div ref={containerRef}  className="flex items-center justify-center h-[80vh]"
                       style={{flexDirection: 'column', backgroundColor: '#F8F8F8'}}>
                    {isImageLoaded && previewImage ? (
                        <div style={{position: 'relative'}}
                             onMouseEnter={() => setShowArrows(true)}
                             onMouseLeave={() => setShowArrows(false)}
                        >
                          <LoadingModal isOpen={loading}>
                            <Loading
                                loadMessage={loadMessage}
                                points={lines}
                                types={checkboxValues}
                                setLoading={setLoading}
                                setLines={setLines}
                                setPreviewImage={setPreviewImage}
                                setLoadedImage={setLoadedImage}
                                setErrorMessage={setErrorMessage}
                                setIsImageLoaded={setIsImageLoaded}
                                setIsZoom={setIsZoom}
                                setPlottingMode={setPlottingMode}
                                setMeasuringScale={setMeasuringScale}
                                setLoadMessage={setLoadMessage}
                                clearDrawing={clearDrawing}
                                clearSelection={clearSelection}
                                setParamsJson={setParamsJson}
                                setValue={setCheckboxValues}
                                measuringScale={measuringScale}
                                formRef={formRef}
                                handleSubmit={handleSubmit}
                                setOutLinesTmp={setOutLinesTmp}
                            />
                          </LoadingModal>
                          <Stage
                              key={previewImage}
                              width={imageSize.width}
                              height={imageSize.height}
                              onMouseEnter={() => {
                                if (isZoom) {
                                  setShowMagnifier(true);
                                }
                              }}
                              onMouseLeave={() => {
                                setShowMagnifier(false);
                                if (isZoom && isShapeClosed()) {
                                  setIsZoom(false)
                                }
                              }}
                              onMouseDown={handleMouseDown}
                              onTouchStart={handleTouchStart}
                              onTouchMove={handleTouchMove}
                              onTouchEnd={handleTouchEnd}
                              onMouseUp={handleMouseUp}
                              onMouseMove={handleMouseMove}
                              onTouchDragStart={handleTouchDragStart}
                              onTouchDragMove={handleTouchDragMove}
                              onTouchDragEnd={handleTouchDragEnd}
                              ref={stageRef}
                              onDragStart={handleDragStart}
                              onDragMove={handleDragMove}
                              onDragEnd={handleDragEnd}
                          >
                            {plottingMode === 'assign' ?(
                              <Layer>
                                <KonvaImage image={image} width={imageSize.width}
                                            height={imageSize.height}/>
                                {lines.map((line, i) => (
                                    <Line
                                        key={i}
                                        points={[line.start.x, line.start.y, line.end.x, line.end.y]}
                                        stroke="#0779a3"
                                    />
                                ))}
                                {tempLine && (
                                    <Line
                                        points={[tempLine.start.x, tempLine.start.y, tempLine.end.x, tempLine.end.y]}
                                        stroke="#0779a3"
                                    />
                                )}
                                {lines.map((line, i) => (
                                    <React.Fragment key={i}>
                                      <Circle
                                          x={line.start.x}
                                          y={line.start.y}
                                          radius={3}
                                          fill="#0707A3"
                                      />
                                      <Text
                                          x={line.start.x + 5}
                                          y={line.start.y + 5}
                                          text={roadNumber[i]}
                                          fontSize={15}
                                          fill="#0b2cb3"
                                      />
                                    </React.Fragment>
                                ))}
                                {tempLine && (
                                    <React.Fragment>
                                      <Circle
                                          x={tempLine.start.x}
                                          y={tempLine.start.y}
                                          radius={3}
                                          fill="#0707A3"
                                      />
                                      <Text
                                          x={tempLine.start.x + 5}
                                          y={tempLine.start.y + 5}
                                          text={roadNumber[lines.length]}
                                          fontSize={15}
                                          fill="#0b2cb3"
                                      />
                                    </React.Fragment>
                                )}
                                {selection && (
                                    <Rect
                                        x={selection.x}
                                        y={selection.y}
                                        width={selection.width}
                                        height={selection.height}
                                        stroke="#2d6a8c"
                                        strokeWidth={2}
                                        fill="rgba(45,106,140,0.1)"
                                    />
                                )}
                              </Layer>
                            ):(<Layer>
                              <KonvaImage image={image} width={imageSize.width}
                                          height={imageSize.height}/>
                              {selection && (
                                  <Rect
                                      x={selection.x}
                                      y={selection.y}
                                      width={selection.width}
                                      height={selection.height}
                                      stroke="#2d6a8c"
                                      strokeWidth={2}
                                      fill="rgba(45,106,140,0.1)"
                                  />
                              )}
                            </Layer>)}

                          </Stage>
                          {loadedImage.length > 0 && showArrows && (
                              <>
                                <button
                                    type='button'
                                    className="arrow-button left-arrow"
                                    onClick={handlePrevPage}
                                    disabled={loadedImage.length === 0}
                                >
                                  ◀
                                </button>
                                <button
                                    type='button'
                                    className="arrow-button right-arrow"
                                    onClick={handleNextPage}
                                    disabled={loadedImage.length === 0}
                                >
                                  ▶
                                </button>
                              </>
                          )}
                          {loadedImage.length > 0 && (
                              <div className="page-info" ref={pageRef}>
                                {currentPage + 1} / {totalPages}
                              </div>
                          )}
                          {plottingMode === 'assign' ? (
                              <div
                                  style={{
                                    display: showMagnifier ? "" : "none",
                                    position: "absolute",

                                    pointerEvents: "none",
                                    height: `${magnifierHeight}px`,
                                    width: `${magnifieWidth}px`,
                                    top: `${y - magnifieWidth / 2}px`,
                                    left: `${x - magnifieWidth / 2}px`,
                                    opacity: "1",
                                    border: "1px solid lightgray",
                                    backgroundColor: "white",
                                    backgroundImage: `url('${previewImage}')`,
                                    backgroundRepeat: "no-repeat",

                                    backgroundSize: `${imgWidth * zoomLevel}px ${
                                        imgHeight * zoomLevel
                                    }px`,

                                    backgroundPositionX: `${-x * zoomLevel + magnifieWidth / 2}px`,
                                    backgroundPositionY: `${-y * zoomLevel + magnifierHeight / 2}px`
                                  }}
                              >
                                <Stage width={magnifieWidth} height={magnifierHeight} scaleX={zoomLevel} scaleY={zoomLevel} x={-x * zoomLevel + magnifieWidth / 2} y={-y * zoomLevel + magnifierHeight / 2}>
                                  <Layer>
                                    {lines.map((line: any, i: number) => (
                                        <Line
                                            key={i}
                                            points={[line.start.x, line.start.y, line.end.x, line.end.y]}
                                            stroke="#025e80"
                                        />
                                    ))}
                                    {tempLine && (
                                        <Line
                                            points={[tempLine.start.x, tempLine.start.y, tempLine.end.x, tempLine.end.y]}
                                            stroke="#025e80"
                                        />
                                    )}
                                    {lines.map((line: any, i: number) => (
                                        <React.Fragment key={i}>
                                          <Circle
                                              x={line.start.x}
                                              y={line.start.y}
                                              radius={3}
                                              fill="#0707A3"
                                          />
                                          <Text
                                              x={line.start.x + 5}
                                              y={line.start.y + 5}
                                              text={roadNumber[i]}
                                              fontSize={15}
                                              fill="#0b2cb3"
                                          />
                                        </React.Fragment>
                                    ))}
                                    {tempLine && (
                                        <React.Fragment>
                                          <Circle
                                              x={tempLine.start.x}
                                              y={tempLine.start.y}
                                              radius={3}
                                              fill="#0707A3"
                                          />
                                          <Text
                                              x={tempLine.start.x + 5}
                                              y={tempLine.start.y + 5}
                                              text={roadNumber[lines.length]}
                                              fontSize={15}
                                              fill="#0b2cb3"
                                          />
                                        </React.Fragment>
                                    )}
                                  </Layer>
                                </Stage>
                              </div>
                          ):(<></>)}

                        </div>
                    ) : (
                        <>
                          {errorMessage === 1 && (
                              <>
                                <span className="text-lg text-red-700">選択されたファイルの種類が正しくありません。</span>
                                <span className="text-lg text-red-700">画像ファイルまたはPDFファイルを選択してください。</span>
                              </>
                          )}
                          {errorMessage === 2 && (
                              <>
                                <span className="text-lg text-red-700">PNGファイルのサイズを2MB以下にしてください。</span>
                              </>
                          )}
                          {errorMessage === 3 && (
                              <>
                                <span className="text-lg text-red-700">PNGファイルの横幅および縦幅は5000ピクセル以下にしてください。</span>
                              </>
                          )}
                          <label htmlFor="volume_area" className={styles.fileButton}>
                            <input
                                type="file"
                                accept="image/*,application/pdf"
                                name="volume[area]"
                                id="volume_area"
                                ref={fileInputRef}
                                className="absolute w-0 h-0 opacity-0 hidden"
                                onChange={(e) => {
                                  const file = e.target.files[0]
                                  setErrorMessage(0)
                                  if (file) {
                                    if (file.type.startsWith('image/')) {
                                      if (file.size > size * 1024 * 1024) {
                                        fileInputRef.current.value = ""
                                        setErrorMessage(2)
                                        return
                                      }
                                      const reader = new FileReader()
                                      const transfer = new DataTransfer()
                                      transfer.items.add(file)
                                      fileInputRef.current.files = transfer.files
                                      reader.onload = () => {
                                        const imageData = reader.result as string
                                        const img = new Image();
                                        img.src = imageData;
                                        img.onload = () => {
                                          if (img.width > 5000 || img.height > 5000) {
                                            fileInputRef.current.value = "";
                                            setErrorMessage(3);
                                          } else {
                                            // 正常处理预览
                                            setPreviewImage(imageData);
                                          }
                                        };
                                      }
                                      reader.readAsDataURL(file)
                                      fileInputRef.current.value = ""
                                    } else if (file.type.startsWith('application/pdf')) {
                                      const reader = new FileReader()
                                      const transfer = new DataTransfer()
                                      transfer.items.add(file)
                                      fileInputRef.current.files = transfer.files
                                      reader.onload = () => {
                                        setPdfFile(file)
                                      }
                                      reader.readAsDataURL(file)
                                      fileInputRef.current.value = ""
                                    } else {
                                      setErrorMessage(1)
                                      fileInputRef.current.value = ""
                                    }
                                  }
                                }}
                            />
                            画像ファイルまたはPDFファイルを選択してください
                          </label>
                        </>
                    )}
                  </div>
                  <div className="flex justify-center py-4">
                    <div className={plottingMode === "" ? '': 'row_hidden'}>
                      <Button
                          type="button"
                          className="mr-4"
                          disabled={!previewImage}
                          onClick={() => {
                            setIsZoom(true)
                            setPlottingMode("assign")
                            handleStartDrawing()
                            setShowMagnifier(true);
                          }}
                      >
                        手動形状指定
                      </Button>
                      <Button
                          type="button"
                          className="mr-4"
                          disabled={!previewImage}
                          onClick={() => {
                            setPlottingMode("read")
                            setShowMagnifier(false)
                            setIsSelectionEnabled(true)
                            setParamsJson(false)
                            setScopeFlg(false)
                          }}
                      >
                        図面読み取り
                      </Button>
                    </div>
                    <div className={(plottingMode === "assign" && isShapeClosed()) || paramsJson? 'flex justify-center': 'row_hidden'}>
                      <span className="mr-4 flex items-center">点1-点2の長さ</span>
                      <Input
                          className="mr-4"
                          suffix="m"
                          value={measuringScale}
                          placeholder="実際の長さを入力"
                          onChange={(e) => {
                            setMeasuringScale(e.target.value)
                          }}
                          onBlur={(e) => {
                            const value = dataPrecisionSetup(e.target.value, 12, 3)
                            setMeasuringScale(value.toString())
                          }}
                      ></Input>
                      <Button
                          className={plottingMode === "" ? "row_hidden" : "mr-4"}
                          disabled={measuringScale === "" && (paramsJson || lines.length > 2)}
                          onClick={(e) => {
                            e.preventDefault()
                            handleSubmit()
                            formRef.current?.submit()
                          }}
                      >
                        形状確定
                      </Button>
                      <AnchorButton
                          type="button"
                          outline={true}
                          onClick={() => {
                            setIsZoom(false)
                            clearDrawing()
                            setMeasuringScale("")
                            setParamsJson(false)
                            setCheckboxValues("0")
                            setLoading(false)
                            setParamsJson(false)
                            setScopeFlg(false)
                          }}
                      >
                        キャンセル
                      </AnchorButton>
                    </div>
                    <div className={plottingMode === "assign" && !isShapeClosed() ? '': 'row_hidden'}>
                      <Button
                          type="button"
                          className="mr-4"
                          onClick={() => {
                            setIsZoom(true)
                            handleStartDrawing()
                            setShowMagnifier(!showMagnifier);
                          }}
                      >
                        手動形状指定
                      </Button>
                      <AnchorButton
                          type="button"
                          outline={true}
                          onClick={() => {
                            setIsZoom(false)
                            clearDrawing()
                            setPlottingMode("")
                            setShowMagnifier(false);
                            setParamsJson(false)
                          }}
                      >
                        キャンセル
                      </AnchorButton>
                    </div>
                    <div className={plottingMode === "read" && !paramsJson && !scopeFlg ? 'loading-container' : 'row_hidden'}>
                      <span className={selection ? 'row_hidden' : 'text-primary'}>読み取り範囲を選択してください</span>
                      <div>
                        <Button
                            type="button"
                            className="mr-4"
                            disabled={!selection}
                            onClick={() => {
                              setScopeFlg(true)
                            }}
                        >
                          選択された範囲を確定
                        </Button>
                        <AnchorButton
                            type="button"
                            outline={true}
                            onClick={() => {
                              setPlottingMode("")
                              setSelection(null)
                            }}
                        >
                          キャンセル
                        </AnchorButton>
                      </div>
                    </div>
                    <div
                        className={plottingMode === "read" && selection && !paramsJson && scopeFlg ? 'flex items-center' : 'row_hidden'}>
                      <span className='mr-4'>タイプ指定</span>
                      <Select
                          className="md:max-w-[246px]"
                          options={setOptions}
                          value={checkboxValues}
                          onChange={(e) => {
                            setCheckboxValues(e.target.value)
                          }}
                      />
                      <Button
                          type="button"
                          disabled={loading}
                          className={plottingMode === "" ? "row_hidden" : "mr-4 ml-4"}
                          onClick={() => {
                            setLoading(true)
                            handleCapture()
                          }}
                      >
                        読取開始
                      </Button>
                      <AnchorButton
                          type="button"
                          outline={true}
                          onClick={() => {
                            setPlottingMode("")
                            setCheckboxValues("0")
                            clearSelection()
                            setParamsJson(false)
                            setScopeFlg(false)
                          }}
                      >
                        キャンセル
                      </AnchorButton>
                    </div>
                  </div>
                </form>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  )
}

export default VolumeCheckChooseModal

const styles = {
  fileButton: `
    flex
    md:inline-flex
    align
    items-center
    justify-center
    gap-1.5
    rounded-sm
    leading-none
    duration-200
    transition-opacity
    hover:opacity-80
    rounded-sm
    border
    box-border
    border-primary-font
    text-white
    py-[5px]
    md:py-[7px]
    px-4
    text-sm
    cursor-pointer
    bg-primary-font
    mt-4
  `,
}
