import { Breadcrumb } from '@/components/Breadcrumb'
import Base from '@/components/Layout/Base'
import { BusinessType } from '@/types/businessType'
import { User } from '@/types/user'
import * as React from 'react'

import 'react-datepicker/dist/react-datepicker.css'

import DepartmentsComparingMonthlyChart from '@/components/Chart/Analyses/DepartmentsComparingMonthlyChart'
import DepartmentsMonthlyChart from '@/components/Chart/Analyses/DepartmentsMonthlyChart'
import MonthlyChart from '@/components/Chart/Analyses/MonthlyChart'
import PurchaceAppliedContractChart from '@/components/Chart/Analyses/PurchaceAppliedContractChart'
import SourceRankingChart from '@/components/Chart/Analyses/SourceRankingChart'
import UsersTotallingChart from '@/components/Chart/Analyses/UsersTotallingChart'
import { SearchButton } from './SearchButton'

type Props = {
  current_user: User
  year_from: number
  month_from: number
  year_to: number
  month_to: number
  target: any[]
  type: PageType
  target_period_type: string
  target_period_types: Array<[string, string]>
  departments: Array<[string, number]>
  users: Array<[string, number]>
  new_count: number
  existing_count: number
  source_user_id: number
  source_department_id: number
  property_type: string
  business_type_id: number
  yearmonth_list: any[]
  target_to: any
  departments_comparing_monthly_datas: any[]
  source_ranking_datas: any[]
  business_types: BusinessType[]
}

export type Params = {
  from: Date
  to: Date
  year_from: number
  month_from: number
  year_to: number
  month_to: number
  source_user_id: number
  source_user_ids: number[]
  source_department_id: number
  source_department_ids: number[]
  target_period_type: string
  property_type: string
  business_type_id: string
  aggregation_target: "all" | "department" | "individual"
}

type PageType =
  | 'monthly'
  | 'departments_comparing_monthly'
  | 'departments_monthly'
  | 'users_totalling'
  | 'source_ranking'
  | 'purchace_applied_contract'

const pageTitle = {
  monthly: '月次集計',
  departments_comparing_monthly: '部門別前月比',
  departments_monthly: '部門別集計',
  users_totalling: '個人別集計',
  source_ranking: '入手先トップ30',
  purchace_applied_contract: '買付・仕入件数',
}

const defaultParam: Params = {
  from: null,
  to: null,
  year_from: new Date().getFullYear(),
  month_from: new Date().getMonth() + 1,
  year_to: new Date().getFullYear(),
  month_to: new Date().getMonth() + 1,
  source_user_id: 0,
  source_department_id: 0,
  target_period_type: '',
  property_type: '',
  business_type_id: '',
  source_user_ids: [],
  source_department_ids: [],
  aggregation_target: 'all'
}

const AnalysesIndexPage: React.FC<Props> = ({
  current_user,
  year_from,
  month_from,
  year_to,
  month_to,
  target,
  type,
  target_period_type,
  target_period_types,
  departments,
  users,
  new_count,
  existing_count,
  source_user_id,
  source_department_id,
  property_type,
  business_type_id,
  yearmonth_list,
  target_to,
  departments_comparing_monthly_datas,
  source_ranking_datas,
  business_types,
}) => {
  const title = pageTitle[type] ?? '月次集計表示'
  const [params, setParams] = React.useState<Params>(defaultParam)

  const [showChart, setShowChart] = React.useState(false)

  React.useEffect(() => {
    const sp = new URLSearchParams(location.search)
    setParams({
      from: new Date(`${year_from}/${month_from}`),
      to: new Date(`${year_to}/${month_to}`),
      year_from,
      month_from,
      year_to,
      month_to,
      source_user_id,
      source_department_id,
      target_period_type,
      property_type,
      source_user_ids: [],
      source_department_ids: [],
      business_type_id: business_type_id && business_type_id.toString(),
      aggregation_target: sp.get('aggregation_target') as 'all' | 'department' | 'individual' ?? 'all',
    })
    setShowChart(true)
  }, [])

  return (
    <Base
      current_user={current_user}
      title={title}
      breadcrumb={<Breadcrumb link_texts={[title]} />}
      submenuCategory="analyses"
    >
      <div className={'flex justify-end pt-2'}>
        <p className="text-primary text-sm py-2 px-4">
          集計期間： {year_from}/{month_from} ~ {year_to}/{month_to}
        </p>

        <SearchButton
          title={`${pageTitle[type]} 絞り込み`}
          type={type}
          target_period_types={target_period_types}
          params={params}
          setParams={setParams}
          departments={departments}
          users={users}
          business_types={business_types}
        />
      </div>

      <div className="border-b-[1px] border-primary mb-4">
        <div className="inline-flex bg-primary text-white text-sm py-2 px-8">{title}</div>
        {type !== 'purchace_applied_contract' && (
          <p className="text-primary text-sm inline-flex py-2 ml-3">
            件数: {new_count + existing_count}件（新規: {new_count}件　既存: {existing_count}件）
          </p>
        )}
      </div>
      {showChart && (
        <div className={'h-[calc(100vh_-_220px)]'}>
          {type === 'monthly' && (
            <MonthlyChart
              year_from={year_from}
              month_from={month_from}
              year_to={year_to}
              month_to={month_to}
              source_department_id={source_department_id}
              source_user_id={source_user_id}
              target_period_type={target_period_type}
              property_type={property_type}
              business_type_id={business_type_id}
            />
          )}
          {type === 'departments_comparing_monthly' && (
            <DepartmentsComparingMonthlyChart
              yearmonth_list={yearmonth_list}
              departments_comparing_monthly_datas={departments_comparing_monthly_datas}
            />
          )}
          {type === 'departments_monthly' && (
            <DepartmentsMonthlyChart
              year_from={year_from}
              month_from={month_from}
              year_to={year_to}
              month_to={month_to}
              source_department_id={source_department_id}
              source_user_id={source_user_id}
              target_period_type={target_period_type}
              property_type={property_type}
              business_type_id={business_type_id}
            />
          )}
          {type === 'users_totalling' && (
            <UsersTotallingChart
              year_from={year_from}
              month_from={month_from}
              year_to={year_to}
              month_to={month_to}
              source_department_id={source_department_id}
              source_user_id={source_user_id}
              target_period_type={target_period_type}
              property_type={property_type}
              business_type_id={business_type_id}
            />
          )}
          {type === 'source_ranking' && (
            <SourceRankingChart
              yearmonth_list={yearmonth_list}
              source_ranking_datas={source_ranking_datas}
              target_to={target_to}
            />
          )}
          {type === 'purchace_applied_contract' && (
            <PurchaceAppliedContractChart
              year_from={year_from}
              month_from={month_from}
              year_to={year_to}
              month_to={month_to}
              source_department_id={source_department_id}
              source_user_id={source_user_id}
              target_period_type={target_period_type}
              property_type={property_type}
              business_type_id={business_type_id}
            />
          )}
        </div>
      )}
    </Base>
  )
}

export default AnalysesIndexPage
