import * as React from 'react'
import { format, formatDistanceStrict, parseISO } from 'date-fns'
import { ja } from 'date-fns/locale'

import { Table } from '@/components/Table'
import type { Property } from '@/types/property'
import type { File } from '@/types/file'

import { AnchorButton } from '@/components/Button'
import { buildingStructureType } from '@/config/langs/building_structure'
import BorderColorOutlinedIcon from '@mui/icons-material/BorderColorOutlined'
import { DisplayItem } from '@/types/displayItem'

import DeleteOutlined from '@material-ui/icons/DeleteOutlined'
import { Row, Td, Th } from '@/components/Page/Properties/Detail/Form'
import { cammedFormat, formatNumber } from '@/utils/cammedFormat'
import Linkify from 'react-linkify'
import { componentDecorator } from './linkComponentDecorator'
import { unit_pct, unit_int_pct, unit_m2 } from '../../VolumeCheckRequests/volumeUnitFormat'
import {
  areaOfUseCodes,
  fireProtectionCodes,
  altitudeAreaCodes,
  shadeInfoSet,
} from '@/models/areaInfoCodes'
import Tooltip from '@mui/material/Tooltip'
import { PropertyBusinessInfomation } from '@/components/Page/Properties/Detail/PropertyBusinessInfomation'
import { PropertyGetInfomation } from '@/components/Page/Properties/Detail/PropertyGetInfomation'


const PropetyDetails: React.FC<{
  property: Property
  source_display: { [key: number]: boolean }
  excel_file?: File
  is_enable_edit_detail?: boolean
  display_item_data: DisplayItem[]
}> = ({ property, source_display, excel_file, is_enable_edit_detail , display_item_data}) => {
  // タブごとの表示項目をフィルタリング
  const is_get_infomaion_items = display_item_data?.filter(item => item.display_infomation_category === "0") || []
  const is_bidding_infomaion_items = display_item_data?.filter(item => item.display_infomation_category === "1") || []
  const is_business_infomaion_items = display_item_data?.filter(item => item.display_infomation_category === "2")|| []

  // タブごとの初期データを用意
  const initialDisplayItems = {
    0: is_get_infomaion_items?.reduce((acc, item) => ({ ...acc, [item.display_item_id]: item.value }), {}),
    1: is_bidding_infomaion_items?.reduce((acc, item) => ({ ...acc, [item.display_item_id]: item.value }), {}),
    2: is_business_infomaion_items?.reduce((acc, item) => ({ ...acc, [item.display_item_id]: item.value }), {}),
  };
  const responseAreaData = property.area_info ? JSON.parse(property.area_info) : null
  const isPropertyLand = property?.property_type === '土地'
  return (
    <div className="mt-10 mb-10">
      <div className="border-b-[1px] border-primary mb-2">
        <span className="inline-flex bg-primary text-white text-sm py-2 px-8">物件詳細</span>
      </div>
      {excel_file && (
        <div className="mb-2">
          <Table borderLine={false}>
            <thead className="bg-gray-150 text-sm sticky top-0">
              <tr className="whitespace-nowrap">
                <th scope="col" className="py-2 px-4 font-medium">
                  ファイル名
                </th>
                <th scope="col" className="py-2 px-4 font-medium">
                  登録日時
                </th>
                <th scope="col" className="py-2 px-4 font-medium"></th>
              </tr>
            </thead>
            <tbody className="whitespace-nowrap">
              <tr className="bg-white">
                <td className="py-2 px-4">
                  <a
                    href={excel_file.url}
                    target="_blank"
                    rel="noreferrer"
                    className="text-primary"
                  >
                    {excel_file.name}
                  </a>
                </td>
                <td className="py-2 px-4">
                  {format(parseISO(excel_file.created_at), 'yyyy/MM/dd HH:mm:ss')}
                </td>
                <td className="py-2 px-4 text-right">
                  <a
                    className="inline-block w-[30px] hover:text-deleteHover"
                    data-confirm={`\u0022${excel_file.name}\u0022を削除しますか？`}
                    rel="nofollow"
                    data-method="delete"
                    href={`/properties/${property.hashid}/detach_excel`}
                  >
                    <DeleteOutlined fontSize="small" />
                  </a>
                </td>
              </tr>
            </tbody>
          </Table>
        </div>
      )}
      {is_enable_edit_detail && (
        <div className="text-right mb-2">
          <AnchorButton
            className="text-primary-font"
            prefix={<BorderColorOutlinedIcon fontSize="small" />}
            outline
            size="small"
            variant="primary"
            href={`/properties/${property.hashid}/edit?tab=property_details`}
          >
            <span className="hidden md:inline">物件編集</span>
          </AnchorButton>
        </div>
      )}
      <div>
        <PropertyGetInfomation
            property={property}
            display_item_data={is_get_infomaion_items}
            source_display = {source_display}
            tab_display_items = {initialDisplayItems[0]}

        />
      </div>
      {(property.instant_city_plannings.length > 0 || responseAreaData) && (
        <div className="mt-5">
          <div className="flex justify-between items-center bg-primary text-white py-1 px-4 text-sm">
            都市計画関係
          </div>
          <Table className="border-t border-b border-[#3885B0]">
            <div>
              <Row className="w-full" label={<Th left={true} column={1}></Th>}>
                <Td column={4}>領域1</Td>
                <Td column={4}>領域2</Td>
                <Td column={4}>領域3</Td>
                <Td column={4}>領域4</Td>
              </Row>
              {responseAreaData && (
                <>
                  <Row
                    className="w-full"
                    label={
                      <Th left={true} column={1}>
                        領域面積
                      </Th>
                    }
                  >
                    <Td column={4}>
                      {unit_m2(responseAreaData.city_planning.merged_areas[0]?.['area'])}
                    </Td>
                    <Td column={4}>
                      {unit_m2(responseAreaData.city_planning.merged_areas[1]?.['area'])}
                    </Td>
                    <Td column={4}>
                      {unit_m2(responseAreaData.city_planning.merged_areas[2]?.['area'])}
                    </Td>
                    <Td column={4}>
                      {unit_m2(responseAreaData.city_planning.merged_areas[3]?.['area'])}
                    </Td>
                  </Row>
                </>
              )}
              <Row
                className="w-full"
                label={
                  <Th left={true} column={1}>
                    用途地域
                  </Th>
                }
              >
                {responseAreaData && (
                  <>
                    <Td column={4}>
                      {responseAreaData.city_planning.merged_areas[0] !== undefined
                        ? areaOfUseCodes[
                            responseAreaData.city_planning.merged_areas[0]?.['area_of_use']?.[
                              'youto_chiiki'
                            ]
                          ] || 'N/A'
                        : ''}
                    </Td>
                    <Td column={4}>
                      {responseAreaData.city_planning.merged_areas[1] !== undefined
                        ? areaOfUseCodes[
                            responseAreaData.city_planning.merged_areas[1]?.['area_of_use']?.[
                              'youto_chiiki'
                            ]
                          ] || 'N/A'
                        : ''}
                    </Td>
                    <Td column={4}>
                      {responseAreaData.city_planning.merged_areas[2] !== undefined
                        ? areaOfUseCodes[
                            responseAreaData.city_planning.merged_areas[2]?.['area_of_use']?.[
                              'youto_chiiki'
                            ]
                          ] || 'N/A'
                        : ''}
                    </Td>
                    <Td column={4}>
                      {responseAreaData.city_planning.merged_areas[3] !== undefined
                        ? areaOfUseCodes[
                            responseAreaData.city_planning.merged_areas[3]?.['area_of_use']?.[
                              'youto_chiiki'
                            ]
                          ] || 'N/A'
                        : ''}
                    </Td>
                  </>
                )}
                {property.instant_city_plannings.length > 0 && responseAreaData === null && (
                  <>
                    <Td column={4}>
                      {property.instant_city_plannings[0] !== undefined
                        ? property.instant_city_plannings[0]?.['youto_chiiki'] || 'N/A'
                        : ''}
                    </Td>
                    <Td column={4}>
                      {property.instant_city_plannings[1] !== undefined
                        ? property.instant_city_plannings[1]?.['youto_chiiki'] || 'N/A'
                        : ''}
                    </Td>
                    <Td column={4}>
                      {property.instant_city_plannings[2] !== undefined
                        ? property.instant_city_plannings[2]?.['youto_chiiki'] || 'N/A'
                        : ''}
                    </Td>
                    <Td column={4}>
                      {property.instant_city_plannings[3] !== undefined
                        ? property.instant_city_plannings[3]?.['youto_chiiki'] || 'N/A'
                        : ''}
                    </Td>
                  </>
                )}
              </Row>
              {responseAreaData && (
                <>
                  <Row
                    className="w-full"
                    label={
                      <Th left={true} column={1}>
                        防火地域
                      </Th>
                    }
                  >
                    <Td column={4}>
                      {responseAreaData.city_planning.merged_areas[0] !== undefined
                        ? fireProtectionCodes[
                            responseAreaData.city_planning.merged_areas[0]?.['fire_protection']?.[
                              'bouka_chiiki'
                            ]
                          ] || (property.prefecture === '東京都' ? '-' : 'N/A')
                        : ''}
                    </Td>
                    <Td column={4}>
                      {responseAreaData.city_planning.merged_areas[1] !== undefined
                        ? fireProtectionCodes[
                            responseAreaData.city_planning.merged_areas[1]?.['fire_protection']?.[
                              'bouka_chiiki'
                            ]
                          ] || (property.prefecture === '東京都' ? '-' : 'N/A')
                        : ''}
                    </Td>
                    <Td column={4}>
                      {responseAreaData.city_planning.merged_areas[2] !== undefined
                        ? fireProtectionCodes[
                            responseAreaData.city_planning.merged_areas[2]?.['fire_protection']?.[
                              'bouka_chiiki'
                            ]
                          ] || (property.prefecture === '東京都' ? '-' : 'N/A')
                        : ''}
                    </Td>
                    <Td column={4}>
                      {responseAreaData.city_planning.merged_areas[3] !== undefined
                        ? fireProtectionCodes[
                            responseAreaData.city_planning.merged_areas[3]?.['fire_protection']?.[
                              'bouka_chiiki'
                            ]
                          ] || (property.prefecture === '東京都' ? '-' : 'N/A')
                        : ''}
                    </Td>
                  </Row>
                  <Row
                    className="w-full"
                    label={
                      <Th left={true} column={1}>
                        高度地区
                      </Th>
                    }
                  >
                    <Td column={4}>
                      {responseAreaData.city_planning.merged_areas[0] !== undefined
                        ? altitudeAreaCodes[
                            responseAreaData.city_planning.merged_areas[0]?.['altitude_area']?.[
                              'koudo_chiku'
                            ]
                          ] || (property.prefecture === '東京都' ? '-' : 'N/A')
                        : ''}
                    </Td>
                    <Td column={4}>
                      {responseAreaData.city_planning.merged_areas[1] !== undefined
                        ? altitudeAreaCodes[
                            responseAreaData.city_planning.merged_areas[1]?.['altitude_area']?.[
                              'koudo_chiku'
                            ]
                          ] || (property.prefecture === '東京都' ? '-' : 'N/A')
                        : ''}
                    </Td>
                    <Td column={4}>
                      {responseAreaData.city_planning.merged_areas[2] !== undefined
                        ? altitudeAreaCodes[
                            responseAreaData.city_planning.merged_areas[2]?.['altitude_area']?.[
                              'koudo_chiku'
                            ]
                          ] || (property.prefecture === '東京都' ? '-' : 'N/A')
                        : ''}
                    </Td>
                    <Td column={4}>
                      {responseAreaData.city_planning.merged_areas[3] !== undefined
                        ? altitudeAreaCodes[
                            responseAreaData.city_planning.merged_areas[3]?.['altitude_area']?.[
                              'koudo_chiku'
                            ]
                          ] || (property.prefecture === '東京都' ? '-' : 'N/A')
                        : ''}
                    </Td>
                  </Row>
                  <Row
                    className="w-full"
                    label={
                      <Th left={true} column={1}>
                        日影規制
                      </Th>
                    }
                  >
                    <Td column={4}>
                      {responseAreaData.city_planning.merged_areas[0] !== undefined
                        ? shadeInfoSet(
                            responseAreaData.city_planning.merged_areas[0]?.['shadow_area']
                          ) || 'N/A'
                        : ''}
                    </Td>
                    <Td column={4}>
                      {responseAreaData.city_planning.merged_areas[1] !== undefined
                        ? shadeInfoSet(
                            responseAreaData.city_planning.merged_areas[1]?.['shadow_area']
                          ) || 'N/A'
                        : ''}
                    </Td>
                    <Td column={4}>
                      {responseAreaData.city_planning.merged_areas[2] !== undefined
                        ? shadeInfoSet(
                            responseAreaData.city_planning.merged_areas[2]?.['shadow_area']
                          ) || 'N/A'
                        : ''}
                    </Td>
                    <Td column={4}>
                      {responseAreaData.city_planning.merged_areas[3] !== undefined
                        ? shadeInfoSet(
                            responseAreaData.city_planning.merged_areas[3]?.['shadow_area']
                          ) || 'N/A'
                        : ''}
                    </Td>
                  </Row>
                </>
              )}
              <Row
                className="w-full"
                label={
                  <Th left={true} column={1}>
                    建ぺい率
                  </Th>
                }
              >
                {responseAreaData && (
                  <>
                    <Td column={4}>
                      {unit_int_pct(
                        responseAreaData.city_planning.merged_areas[0]?.['area_of_use']?.[
                          'building_coverage_ratio'
                        ]
                      )}
                    </Td>
                    <Td column={4}>
                      {unit_int_pct(
                        responseAreaData.city_planning.merged_areas[1]?.['area_of_use']?.[
                          'building_coverage_ratio'
                        ]
                      )}
                    </Td>
                    <Td column={4}>
                      {unit_int_pct(
                        responseAreaData.city_planning.merged_areas[2]?.['area_of_use']?.[
                          'building_coverage_ratio'
                        ]
                      )}
                    </Td>
                    <Td column={4}>
                      {unit_int_pct(
                        responseAreaData.city_planning.merged_areas[3]?.['area_of_use']?.[
                          'building_coverage_ratio'
                        ]
                      )}
                    </Td>
                  </>
                )}
                {property.instant_city_plannings.length > 0 && responseAreaData === null && (
                  <>
                    <Td column={4}>
                      {unit_int_pct(
                        property.instant_city_plannings[0]?.['building_coverage_ratio']
                      )}
                    </Td>
                    <Td column={4}>
                      {unit_int_pct(
                        property.instant_city_plannings[1]?.['building_coverage_ratio']
                      )}
                    </Td>
                    <Td column={4}>
                      {unit_int_pct(
                        property.instant_city_plannings[2]?.['building_coverage_ratio']
                      )}
                    </Td>
                    <Td column={4}>
                      {unit_int_pct(
                        property.instant_city_plannings[3]?.['building_coverage_ratio']
                      )}
                    </Td>
                  </>
                )}
              </Row>
              <Row
                className="w-full"
                label={
                  <Th left={true} column={1}>
                    容積率
                  </Th>
                }
              >
                {responseAreaData && (
                  <>
                    <Td column={4}>
                      {unit_int_pct(
                        responseAreaData.city_planning.merged_areas[0]?.['area_of_use']?.[
                          'floor_area_ratio'
                        ]
                      )}
                    </Td>
                    <Td column={4}>
                      {unit_int_pct(
                        responseAreaData.city_planning.merged_areas[1]?.['area_of_use']?.[
                          'floor_area_ratio'
                        ]
                      )}
                    </Td>
                    <Td column={4}>
                      {unit_int_pct(
                        responseAreaData.city_planning.merged_areas[2]?.['area_of_use']?.[
                          'floor_area_ratio'
                        ]
                      )}
                    </Td>
                    <Td column={4}>
                      {unit_int_pct(
                        responseAreaData.city_planning.merged_areas[3]?.['area_of_use']?.[
                          'floor_area_ratio'
                        ]
                      )}
                    </Td>
                  </>
                )}
                {property.instant_city_plannings.length > 0 && responseAreaData === null && (
                  <>
                    <Td column={4}>
                      {unit_int_pct(property.instant_city_plannings[0]?.['floor_area_ratio'])}
                    </Td>
                    <Td column={4}>
                      {unit_int_pct(property.instant_city_plannings[1]?.['floor_area_ratio'])}
                    </Td>
                    <Td column={4}>
                      {unit_int_pct(property.instant_city_plannings[2]?.['floor_area_ratio'])}
                    </Td>
                    <Td column={4}>
                      {unit_int_pct(property.instant_city_plannings[3]?.['floor_area_ratio'])}
                    </Td>
                  </>
                )}
              </Row>
              {responseAreaData && (
                <>
                  <Row
                    className="w-full"
                    label={
                      <Th left={true} column={1}>
                        採用建ぺい率（平均）
                      </Th>
                    }
                  >
                    <Td>
                      {unit_pct(responseAreaData.city_planning['adopted_building_area_ratio'])}
                    </Td>
                  </Row>
                  <Row
                    className="w-full"
                    label={
                      <Th left={true} column={1}>
                        採用容積率（平均）
                      </Th>
                    }
                  >
                    <Td>{unit_pct(responseAreaData.city_planning['adopted_floor_area_ratio'])}</Td>
                  </Row>
                </>
              )}
            </div>
          </Table>
          <div className="block text-xs px-4 pt-1 pb-2 whitespace-normal text-primary font-small">
            ※
            敷地面積、領域面積は小数点第3位切り捨てとし、敷地面積と各領域面積の合計が異なる場合があります。また、情報データが提供されていない地域の場合は、表内に"N/A"と表示されます。
          </div>
        </div>
      )}

      <div className="mt-5">
          <PropertyBusinessInfomation
              property={property}
              display_item_data={is_business_infomaion_items}
              source_display = {source_display}
              tab_display_items = {initialDisplayItems[2]}
          ></PropertyBusinessInfomation>
      </div>

      <div className="mt-5">
        <div className="flex justify-between items-center bg-primary text-white py-1 px-4 text-sm">
          進捗メモ
        </div>
        <Table className="border-t border-b border-[#3885B0]">
          <div>
            {property.memos
              .toSorted((a, b) => (a.updated_at < b.updated_at ? 1 : -1))
              .map((memo, index) => (
                <Row
                  key={index}
                  label={
                    <Th left={true} className={'w-32 md:w-32'}>
                      {format(parseISO(memo.updated_at), 'yyyy/MM/dd')}
                    </Th>
                  }
                >
                  <Td>
                    <p className="whitespace-pre">
                      {memo?.user?.name ?? '不明'} :{' '}
                      <Linkify componentDecorator={componentDecorator}>{memo.content}</Linkify>
                    </p>
                  </Td>
                </Row>
              ))}
          </div>
        </Table>
      </div>
    </div>
  )
}

export default PropetyDetails
