export const area_colors = {
  youto_chiiki: {
    '1': '#00C58E', // '第一種低層住居専用地域'
    '2': '#51E7C7', // '第二種低層住居専用地域'
    '3': '#7ED043', // '第一種中高層住居専用地域'
    '4': '#BCE69A', // '第二種中高層住居専用地域'
    '5': '#FFE395', // '第一種住居地域'
    '6': '#FFA4A7', // '第二種住居地域'
    '7': '#FFB92F', // '準住居地域'
    '8': '#FF8EC3', // '近隣商業地域'
    '9': '#FF5C5F', // '商業地域'
    '10': '#9359F5', // '準工業地域'
    '11': '#8CBBE1', // '工業地域'
    '12': '#7FA1D5', // '工業専用地域'
    '0': '#000000', // '指定なし
  },
  bouka_chiiki: {
    '10': '#000000',
    '20': '#888888',
    '0': '#000000',
  },
}