import * as React from 'react'
import Base from '@/components/Layout/Base'
import type { User } from '@/types/user'
import type { PropertyDocumentFormat } from '@/types/propertyDocumentFormat'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { Container } from '@/components/SortableList/PropertyDocumentFormat/Container'
import { Breadcrumb } from '@/components/Breadcrumb'

type Props = {
  current_user: User
  property_document_formats: PropertyDocumentFormat[]
}

const PropertyDocumentFormatIndexPage: React.FC<Props> = ({ current_user, property_document_formats }) => {
  const title = '物件概要書フォーマット一覧'

  return (
    <Base
      current_user={current_user}
      title={title}
      breadcrumb={<Breadcrumb link_texts={['物件概要書フォーマット設定']} />}
      submenuCategory="settings"
    >
      <div>
        <DndProvider backend={HTML5Backend}>
          <Container items={property_document_formats} />
        </DndProvider>
      </div>
    </Base>
  )
}

export default PropertyDocumentFormatIndexPage
