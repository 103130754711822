import * as React from 'react'
import { Disclosure } from '@headlessui/react'
import { CaretDownIcon } from '@/components/SvgIcon'

type Props = {
  icon: React.ReactNode
  menu: string
  children?: React.ReactNode
}

export const MenuAccordion: React.FC<Props> = ({ icon, menu, children }) => {
  return (
    <Disclosure>
      {({ open }) => (
        <>
          <Disclosure.Button className="py-[15px] flex w-full hover:bg-primary-light">
            <div className="flex items-center text-primary-font hover:bg-primary-light">
              <div className="pl-5">{icon}</div>
              <div className="pl-2.5">{menu}</div>
              <CaretDownIcon
                fill="#0A689D"
                className={`ml-2 transition duration-100 ease-in-out ${
                  open ? 'rotate-180 transform' : ''
                }`}
              />
            </div>
          </Disclosure.Button>
          <Disclosure.Panel>{children}</Disclosure.Panel>
        </>
      )}
    </Disclosure>
  )
}
