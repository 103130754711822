import Drawing, { VerticalAlignment } from 'dxf-writer';

export function svg2dxf(svg: SVGSVGElement, flipY: boolean = false): Drawing {
  const dwg = new Drawing();

  svg.childNodes.forEach((child) => {
    convert({ node: child, dwg, matrix: new DOMMatrix(), flipY });
  });

  return dwg;
}

function convert(props: {
  node: ChildNode;
  dwg: Drawing;
  matrix: DOMMatrix;
  flipY: boolean;
}) {
  const { node, dwg, matrix: parent, flipY } = props;
  if (node.nodeName === 'g') {
    const g = node as SVGGElement;
    const matrix = new DOMMatrix(getComputedStyle(g).transform);
    const multiplied = parent.multiply(matrix);
    g.childNodes.forEach((child) => {
      convert({
        node: child,
        dwg,
        matrix: multiplied,
        flipY,
      });
    });
  } else {
    switch (node.nodeName) {
      case 'polyline': {
        drawPolyline({
          polyline: node as SVGPolylineElement,
          dwg,
          matrix: parent,
          flipY,
        });
        break;
      }
      case 'circle': {
        drawCircle({
          circle: node as SVGCircleElement,
          dwg,
          matrix: parent,
          flipY,
        });
        break;
      }
      case 'rect': {
        break;
      }
      case 'line': {
        drawLine({
          line: node as SVGLineElement,
          dwg,
          matrix: parent,
          flipY,
        });
        break;
      }
      case 'text': {
        drawText({
          text: node as SVGTextElement,
          dwg,
          matrix: parent,
          flipY,
        });
        break;
      }
    }
  }
}

type DwgProps = {
  dwg: Drawing;
  matrix: DOMMatrix;
  flipY: boolean;
};

type LineType = 'CONTINUOUS' | 'DOTTED';

function hexStringToNumber(hex: string): number {
  return parseInt(hex.replace('#', ''), 16);
}

function insertLayer(
  dwg: Drawing,
  hex: string,
  lineTypeName: LineType = 'CONTINUOUS',
) {
  const idx = Object.values(dwg.layers).length;
  const layer = `layer-${idx}`;
  dwg.addLayer(layer, 0, lineTypeName);
  dwg.setActiveLayer(layer);

  // https://github.com/tarikjabiri/js-dxf/blob/master/examples/true_color.js
  dwg.setTrueColor(hexStringToNumber(hex));
}

function drawPolyline(
  props: {
    polyline: SVGPolylineElement;
  } & DwgProps,
) {
  const { dwg, matrix, polyline, flipY } = props;
  const stroke = polyline.getAttribute('stroke');
  const fill = polyline.getAttribute('fill');
  const name = polyline.getAttribute('name');
  if (name?.includes('3d')) {
    const pts = polyline.getAttribute('points') ?? '';
    const scaleX = Math.sqrt(matrix.a * matrix.a + matrix.b * matrix.b);
    const points: [number, number, number][] = pts
      .split(/(\s+)/)
      .filter((pt) => pt.length > 1)
      .map((pt) => {
        const xyz = pt.split(',').map((v) => Number(v));
        const p = matrix.transformPoint({ x: xyz[0], y: xyz[1] });
        return [p.x, flipY ? -p.y : p.y, xyz[2] * scaleX];
      });
    if (fill !== null && fill !== 'none') {
      insertLayer(dwg, fill);
      dwg.drawPolyline3d(points);
    }
    if (stroke !== null && stroke !== 'none') {
      insertLayer(dwg, stroke);
      dwg.drawPolyline3d(points);
    }
  } else {
    const points: [number, number][] = Array.from(polyline.points).map((pt) => {
      const p = matrix.transformPoint(pt);
      return [p.x, flipY ? -p.y : p.y];
    });
    if (fill !== null && fill !== 'none') {
      insertLayer(dwg, fill);
      dwg.drawPolyline(points, true);
    }
    if (stroke !== null && stroke !== 'none') {
      insertLayer(dwg, stroke);
      dwg.drawPolyline(points, false);
    }
  }
}

function drawCircle(
  props: {
    circle: SVGCircleElement;
  } & DwgProps,
) {
  const { circle, dwg, matrix, flipY } = props;
  const { cx, cy, r } = circle;
  const p = matrix.transformPoint({ x: cx.baseVal.value, y: cy.baseVal.value });

  const stroke = circle.getAttribute('stroke');
  if (stroke !== null && stroke !== 'none') {
    insertLayer(dwg, stroke);
    dwg.drawCircle(p.x, flipY ? -p.y : p.y, r.baseVal.value * 1e-2);
  }
}

function drawRect(
  props: {
    rect: SVGRectElement;
  } & DwgProps,
) {}

function drawLine(
  props: {
    line: SVGLineElement;
  } & DwgProps,
) {
  const { line, dwg, matrix, flipY } = props;
  const { x1, y1, x2, y2 } = line;
  const p1 = matrix.transformPoint({
    x: x1.baseVal.value,
    y: y1.baseVal.value,
  });
  const p2 = matrix.transformPoint({
    x: x2.baseVal.value,
    y: y2.baseVal.value,
  });
  const stroke = line.getAttribute('stroke');
  if (stroke !== null && stroke !== 'none') {
    insertLayer(dwg, stroke);
    dwg.drawLine(p1.x, flipY ? -p1.y : p1.y, p2.x, flipY ? -p2.y : p2.y);
  }
}

type AlignmentBaseline = 'baseline' | 'before-edge' | 'after-edge';

function getVerticalAlignment(baseline: AlignmentBaseline): VerticalAlignment {
  switch (baseline) {
    case 'after-edge':
      return 'bottom';
    case 'baseline':
      return 'baseline';
    case 'before-edge':
      return 'top';
  }
}

function drawText(
  props: {
    text: SVGTextElement;
  } & DwgProps,
) {
  const { text, dwg, matrix, flipY } = props;
  const m = new DOMMatrix(getComputedStyle(text).transform);
  const current = matrix.multiply(m);
  const { x, y, textContent } = text;
  const anchor = text.getAttribute('text-anchor');
  const baseline = text.getAttribute(
    'alignment-baseline',
  ) as AlignmentBaseline | null;
  const p = current.transformPoint({
    x: x.baseVal[0]?.value ?? 0,
    y: y.baseVal[0]?.value ?? 0,
  });
  const { rotation } = decomposeMatrix(current);
  dwg.drawText(
    p.x,
    flipY ? -p.y : p.y,
    8,
    flipY ? -rotation : rotation,
    textContent ?? '',
    'center',
    getVerticalAlignment(baseline ?? 'baseline'),
  );
}

function deltaTransformPoint(
  matrix: DOMMatrix,
  point: {
    x: number;
    y: number;
  },
) {
  var dx = point.x * matrix.a + point.y * matrix.c + 0;
  var dy = point.x * matrix.b + point.y * matrix.d + 0;
  return { x: dx, y: dy };
}

function decomposeMatrix(matrix: DOMMatrix) {
  // https://gist.github.com/2052247
  const px = deltaTransformPoint(matrix, { x: 0, y: 1 });
  const py = deltaTransformPoint(matrix, { x: 1, y: 0 });

  const skewX = (180 / Math.PI) * Math.atan2(px.y, px.x) - 90;
  const skewY = (180 / Math.PI) * Math.atan2(py.y, py.x);

  return {
    translateX: matrix.e,
    translateY: matrix.f,
    scaleX: Math.sqrt(matrix.a * matrix.a + matrix.b * matrix.b),
    scaleY: Math.sqrt(matrix.c * matrix.c + matrix.d * matrix.d),
    skewX: skewX,
    skewY: skewY,
    rotation: skewX, // rotation is the same as skew x
  };
}
