import { Button } from '@/components/Button'
import { Row } from '@/components/Table'
import { Property } from "@/types/property"
import { PropertyDocument } from "@/types/propertyDocument"
import { Tab } from '@headlessui/react'
import { Table } from '@material-ui/core'
import CloudDownloadIcon from '@material-ui/icons/CloudDownload'
import FindInPageIcon from '@mui/icons-material/FindInPage'
import { format } from "date-fns"
import * as React from "react"
import Map from "./Map"

type FormatDetail = {
  colspan: number
  column_name_1: string
  column_name_2: string
  correction_text: string
  created_at: string
  element_name: string
  id: number
  order: number
  position: string
  public_file_detail_id: number
  rowspan: number
  updated_at: string
}

type AttachedFile = {
  filename: string
  preview_url: string
  download_url: string
}

type Props = {
  property: Property
  property_document: PropertyDocument
  property_image: string // URL
  format_details: FormatDetail[]
  attached_files: AttachedFile[]
  google_maps_api_key: string
}

type TabType = 'files' | 'info'

const Show: React.FC<Props> = (props) => {
  const [tab, setTab] = React.useState<TabType>('info')

  const onChangeTab = (index: number) => {
    setTab(index === 0 ? 'info' : 'files')
  }

  return <div className="bg-gray-50 lg:flex block"><div className="bg-white p-2 pt-4 sm:p-16 lg:p-8 lg:mx-auto w-[100%] max-w-[1600px]a lg:max-w-[800px]">
    <h1 className="text-3xl text-center">物件概要書</h1>

    <div className="flex justify-between flex-wrap my-4 md:my-16">
      <div className="font-bold">{props.property.prefecture} {props.property.city} {props.property.town} {props.property.chome}</div>
      <div>{format(new Date(props.property_document.updated_at), 'yyyy年MM月dd日')}</div>
    </div>

    <Tab.Group onChange={onChangeTab}>
      <div className="flex text-sm overflow-x-auto whitespace-nowrap">
        <Tab.List className="flex items-center border-b-[1px] border-primary mb-2 md:w-full">
          <Tab className={`${styles['tab']} ${styles[tab === 'info' ? 'true' : 'false']} border-l-[1px]`}>
            物件概要
          </Tab>
          <Tab className={`${styles['tab']} ${styles[tab === 'files' ? 'true' : 'false']}`}>
            物件資料
          </Tab>
        </Tab.List>
      </div>
      <Tab.Panels>
        <Tab.Panel unmount={false}>
          <Details details={props.format_details} />
        </Tab.Panel>
        <Tab.Panel unmount={false}>
          <Files files={props.attached_files} />
        </Tab.Panel>
      </Tab.Panels>
    </Tab.Group>

  </div>
    <div className={`w-full lg:h-screen ${props.property_image ? 'h-[1020px] gap-4' : 'h-[620px]'}`}>
      <div className={`w-full ${props.property_image ? 'h-[50%]' : 'h-full'}`}>
        <Map
          property={props.property}
          google_maps_api_key={props.google_maps_api_key}
          error_messages={[]}
        />
      </div>
      {props.property_image && <img src={props.property_image} className='w-full h-[50%] object-contain' />}
    </div>
  </div>
}

const styles = {
  tab: `
    inline-flex
    outline-none
    select-none
    text-sm
    py-2
    px-8
    border-t-[1px]
    border-r-[1px]
    border-primary
  `,
  true: `
    bg-primary
    text-white
  `,
  false: `
    text-primary-font
    border-t-[1px]
    border-r-[1px]
    border-primary
  `,
}

type DetailsProps = { details: FormatDetail[] }
const Details = (props: DetailsProps) => {
  return <table className="w-full text-sm text-left text-black-base border-t border-t-gray-200 border-b border-b-gray-20 table-auto">
    {props.details.map((fd, i) => {
      return <Row key={i} className=' border-y-2'>
        {fd.column_name_1 && <th rowSpan={fd.rowspan} colSpan={fd.colspan} className='p-4 min-w-[120px] max-w-1/3'>{fd.column_name_1}</th>}
        {fd.column_name_2 && <th className='p-4 min-w-[120px] max-w-1/3'>{fd.column_name_2}</th>}
        <td className="w-full p-4">{fd.element_name}</td>
      </Row>
    })}
  </table>
}

type FilesProps = { files: AttachedFile[] }
const Files = (props: FilesProps) => {
  const [selectedFile, setSelectedFile] = React.useState<AttachedFile | null>(null)
  return <div className="flex flex-wrap gap-4">
    <div className="overflow-x-scroll">
      <Table className="py-8">
        <thead className="bg-gray-150 text-sm sticky">
          <tr className="whitespace-nowrap">
            <th scope="col" className="py-2 px-4 font-medium">資料名</th>
            <th scope="col" colSpan={2} className="py-2 px-4 font-medium sticky right-0">
              操作<br />
              <p className="text-xs text-gray-300">※プレビューはpdf/png/jpegのみご利用可能です</p>
            </th>
          </tr>
        </thead>
        <tbody className="whitespace-nowrap">
          {props.files.map((file, i) => {
            return <tr className={`whitespace-nowrap ${selectedFile?.filename === file.filename && "bg-targetLine"}`} key={i}>
              <td scope="col" className="py-2 px-4 font-medium w-full">{file.filename}</td>
              <td className="text-center sticky xs:right-6 md:right-[134px] xs:w-6 w-full md:bg-white"><a href={file.download_url} target="_blank">
                <Button
                  className="text-primary-font m-0 bg-white"
                  outline
                  size="small"
                  variant="primary"
                  prefix={<CloudDownloadIcon fontSize="small" />}
                >
                  <span className="hidden md:inline">ダウンロード</span>
                </Button></a></td>
              <td className="text-center xs:w-6 w-full sticky md:right-0 xs:right-[-38px] md:bg-white">
                <Button
                  className="text-primary-font m-0 bg-white"
                  outline
                  size="small"
                  variant="primary"
                  onClick={() => setSelectedFile(file)}
                  prefix={<FindInPageIcon fontSize="small" />}
                >
                  <span className="hidden md:inline">プレビュー</span>
                </Button>
              </td>
            </tr>
          })}
        </tbody>
      </Table>
    </div>
    {selectedFile && <iframe src={selectedFile?.preview_url} className="px-[-32px] w-full h-[1000px]" />}
  </div>
}

export default Show