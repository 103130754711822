import { MarketDatum } from '@/types/marketDatum';
import { formatColumn } from '@/utils/marketDatumFormatColumn';
import * as React from 'react';
import * as ReactDOMServer from 'react-dom/server';

export function Balloon(marketData: MarketDatum) {
  const forSale = (new URL(window.location.href).searchParams.get('data_type') || "for_sale") == "for_sale"
  const isClosed = marketData?.status === "close"

  const jsx = (
    <div style={{
      width: '100%',
      height: '100%',
      display: 'block',
      justifyContent: 'center',
      alignItems: 'center',
    }}>
      <p style={{ padding: 0, margin: "2px" }}>{marketData?.no} {marketData?.status_text}</p>
      <p style={{ background: "gray", padding: 0, margin: 0, width: "100%", height: "0.1px" }} />
      {forSale
        ? <p style={{ padding: 0, margin: "2px", marginTop: "4px", marginLeft: "4px" }}>{formatColumn(marketData, isClosed ? 'building_price' : 'sale_price')} {formatColumn(marketData, 'building_structure_short')}</p>
        : <p style={{ padding: 0, margin: "2px", marginTop: "4px", marginLeft: "4px" }}>{formatColumn(marketData, 'rent')} {formatColumn(marketData, 'rent_maintenance_fee_unit_price_tsubo')}</p>
      }
      {forSale
        ? <p style={{ padding: 0, margin: "2px", marginLeft: "4px" }}>{formatColumn(marketData, isClosed ? 'prospective_yield' : 'sale_prospective_yield')} {formatColumn(marketData, 'build_age')}</p>
        : <p style={{ padding: 0, margin: "2px", marginLeft: "4px" }}>{formatColumn(marketData, 'occupied_area')} {formatColumn(marketData, 'build_age')}</p>
      }
    </div>
  );
  return jsxToHtml(jsx);
}

function jsxToHtml(jsx: React.ReactElement): string {
  return ReactDOMServer.renderToStaticMarkup(jsx);
}