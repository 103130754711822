/** @type {import('tailwindcss').Config} */
module.exports = {
  content: ['./app/helpers/**/*.rb', './app/javascript/**/*.{js,jsx,ts,tsx}', './app/views/**/*'],
  theme: {
    screens: {
      xs: '376px', // iPhone SE
      sm: '640px',
      md: '768px',
      lg: '1100px',
      xl: '1280px',
      '2xl': '1536px',
    },
    fontFamily: {
      sans: ['sans-serif'],
    },
    extend: {
      colors: {
        primary: {
          DEFAULT: '#3885B0',
          light: '#C3DEEE',
          font: '#0A689D',
        },
        black: {
          DEFAULT: '#000000',
          base: '#212529',
        },
        gray: {
          border: '#ADB5BD',
          light: '#F8F9FA',
          800: '#343A40',
          700: '#495057',
          300: '#6C757D',
          200: '#CED4DA',
          150: '#DEE2E6',
          100: '#E9ECEF',
        },
        targetLine: {
          DEFAULT: '#FDF7EA',
          warning: '#E74500',
        },
        deleteHover: {
          DEFAULT: '#C72424',
        },
        red: {
          DEFAULT: '#F13800',
          error: '#DA501A',
          light: '#FFE5DB',
          200: '#F79B7F',
          100: '#FEECE6',
        },
        success: {
          DEFAULT: '#0f5132',
          light: '#d1e7dd',
          dark: '#badbcc',
        },
        error: {
          DEFAULT: '#842029',
          light: '#f8d7da',
          dark: '#f5c2c7',
        },
        alert: {
          DEFAULT: '#664d03',
          light: '#fff3cd',
          dark: '#ffecb5',
        },
        notice: {
          DEFAULT: '#055160',
          light: '#cff4fc',
          dark: '#b6effb',
        },
      },
      maxWidth: {
        'login-form': '342px',
      },
      minHeight: {
        'login-form': '564px',
      },
      width: {
        '1875': '18.75%',
      },
      // backgroundImage: {
      //   'checkbox': "url('~checkbox_icon.svg')",
      //   'select': "url('/img/footer-texture.png')",
      // }
    },
  },
  plugins: [],
}
