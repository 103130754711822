
export const formatMeasurementValue = (length: number, digits: number = 0) => {
  const s = length.toLocaleString();
  const [integer, fractional] = s.split('.');
  if (digits <= 0) {
    return integer;
  }
  if (fractional === undefined) {
    return `${integer}`;
  }
  const frac = fractional.length < digits ? fractional.padEnd(digits, '0') : fractional.slice(0, digits);
  return `${integer}.${frac}`;
};

export const shouldRotateDegree = (degree: number) => {
  const offset = 30;
  return ((90 + offset) < degree && degree < (270 - offset));
};

export const textReadableDegree = (degree: number) => {
  return shouldRotateDegree(degree) ? degree + 180 : degree;
};