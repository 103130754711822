import * as React from 'react'
import { useDragAndDrop } from '@/components/SortableList/useDragAndDrop'
import { Button } from '@/components/Button'
import DragIndicatorOutlined from '@material-ui/icons/DragIndicatorOutlined'

export interface CardProps {
  children?: React.ReactNode
  id: number
  index: number
  canMove: boolean
  moveCard: (dragIndex: number, hoverIndex: number) => void
  dropCard: () => void
}

export const Card: React.FC<CardProps> = ({
  children,
  id,
  index,
  canMove = true,
  moveCard,
  dropCard,
}) => {
  const [ref, isDragging, handlerId] = useDragAndDrop(index, moveCard, dropCard)
  const opacity = isDragging ? 0 : 1

  if (canMove) {
    return (
      <tr
        key={id}
        ref={ref}
        style={{ opacity }}
        data-handler-id={handlerId}
        className={['bg-white border-t border-gray-150'].join(' ')}
      >
        <td className="py-2 md:px-2 w-12 md:w-24">
          <Button
            className="text-primary-font cursor-move"
            prefix={<DragIndicatorOutlined fontSize="small" />}
            outline
            size="small"
            variant="primary"
          >
            <span className="text-primary-font  hidden md:block">移動</span>
          </Button>
        </td>
        {children}
      </tr>
    )
  } else {
    return (
      <tr className={['bg-white border-t border-gray-150'].join(' ')}>
        <td className="py-2 md:px-2 w-12 md:w-24"></td>
        {children}
      </tr>
    )
  }
}
