import { MarketDatum } from "@/types/marketDatum";
import * as d3 from "d3";
import { Balloon } from "./utils/balloonDom";

export type LatLng = {
  lat: number,
  lng: number,
}

type LatLngWithId = {
  id: string,
} & LatLng

export interface BalloonOverlayImpl {
  setMap(map: google.maps.Map)
  update(bounds: google.maps.LatLngBounds, marketData: MarketDatum[])
  onSelected?: (marketDatum: MarketDatum, onSelected: boolean) => void;
  toggleDOM(map: google.maps.Map)
  reset(map: google.maps.Map)
}

export function BalloonOverlay(map: google.maps.Map): BalloonOverlayImpl {
  // GoogleMapを読み込んだあとでないとextends google.maps.OverlayViewが使えないため
  class _BalloonOverlay extends google.maps.OverlayView implements BalloonOverlayImpl {
    private bounds: google.maps.LatLngBounds;
    private div?: HTMLElement;
    private latlngs: LatLngWithId[];
    shiftPositions = new Map<string, { x: number, y: number }>();
    private boxHeight = 64;
    private boxWidth = 120;
    private dragging = false;
    private marketData: MarketDatum[];
    private currentSelectedId: string | null = null;
    // 一回drawしたことがあるか
    private drawed = false;
    onSelected?: (marketData: MarketDatum, selected: boolean) => void;

    constructor(map: google.maps.Map) {
      super();

      map.addListener("dragstart", () => {
        this.dragging = true;
      })
      map.addListener("dragend", () => {
        this.dragging = false;
      })
    }

    update(bounds: google.maps.LatLngBounds, marketData: MarketDatum[]) {
      this.bounds = bounds;
      this.latlngs = marketData.map(data => ({ id: data.id + "", lat: Number(data.lat), lng: Number(data.lng) }))
      this.latlngs.forEach(latlng => (this.shiftPositions.set(latlng.id, { x: latlng.lat, y: latlng.lng })))
      this.marketData = marketData
      this.drawed = false
    }

    onAdd() {
      document.querySelectorAll(".D3Overlay").forEach((e) => {
        e.remove()
      })
      document.querySelectorAll(".SvgOverlay").forEach((e) => {
        e.remove()
      })
      this.div = document.createElement("div");
      this.div.style.borderStyle = "none";
      this.div.style.borderWidth = "2px";
      this.div.style.position = "absolute";
      this.div.className = "D3Overlay"

      const panes = this.getPanes()!;

      panes.overlayMouseTarget.appendChild(this.div);
    }

    draw() {
      if (this.dragging) { return }
      const overlayProjection = this.getProjection();
      if (!this.bounds?.getSouthWest() || !this.bounds?.getNorthEast()) return
      const sw = overlayProjection.fromLatLngToDivPixel(
        this.bounds.getSouthWest()
      )!;
      const ne = overlayProjection.fromLatLngToDivPixel(
        this.bounds.getNorthEast()
      )!;

      this.div?.childNodes.forEach((e) => {
        e.remove()
      })

      let pixels: { id: string, position: { x: number, y: number }, parentPosition: { x: number, y: number } }[] = []
      this.shiftPositions.forEach((v, k) => {
        const position = overlayProjection.fromLatLngToDivPixel({ lat: v.x, lng: v.y })!
        pixels.push({
          id: k,
          position: { x: position.x, y: position.y },
          parentPosition: overlayProjection.fromLatLngToDivPixel(this.latlngs.find(dt => dt.id === k)!)!,
        })
      })

      d3
        .select(".D3Overlay")
        .append("svg")
        .attr("class", "SvgOverlay")
        .attr("width", "100%")
        .attr("height", "100%")
        .style("position", "absolute")
        .style('transform', `translate(-50%,50%)`)

      type Data = {
        id: string,
        x: number,
        y: number,
        isPin?: boolean,
        marketData?: MarketDatum
      }
      let data: Data[] = pixels.map((pix) => ({ id: pix.id, x: pix?.position?.x || 0, y: pix?.position?.y || 0, marketData: this.marketData.find(md => md.id === Number(pix.id))! }))
      // ピンの上に重ならないように（表示はされない）
      const circleData = this.latlngs.map((latlng) => {
        const position = overlayProjection.fromLatLngToDivPixel(latlng)!
        return { id: latlng.id, x: position.x || 0, y: position.y || 0, isPin: true }
      })

      // if (!this.drawed) {
      //   const numBoxes = this.marketData.length;

      //   // 円形配置用のデータ作成（上下50°を開けて配置）
      //   data = this.marketData.map((md, i) => {
      //     const position = this.calcPosition(i, numBoxes)
      //     return {
      //       id: md.id + "",
      //       x: position.x,
      //       y: position.y,
      //       marketData: md,
      //     };
      //   });
      // }

      const dragstarted = (event, d) => {
        if (!event.active) simulation.alphaTarget(.03).restart();
        d.fx = d.x;
        d.fy = d.y;
      }
      const dragged = (event, d) => {
        d.fx = event.x;
        d.fy = event.y;
      }
      const dragended = (event, d) => {
        if (!event.active) simulation.alphaTarget(.03);
        const latlng = overlayProjection.fromDivPixelToLatLng(new google.maps.Point(d.x, d.y))
        this.shiftPositions.set(d.id, { x: parseFloat(latlng!.lat().toFixed(5)), y: parseFloat(latlng!.lng().toFixed(5)) })
        d.fx = null;
        d.fy = null;
      }

      const nodeGroup = d3.select(".SvgOverlay")
        .selectAll("g")
        .data(data)
        .enter()
        .append("g")
        .call(d3.drag()
          .on("start", dragstarted)
          .on("drag", dragged)
          .on("end", dragended));

      nodeGroup
        .append("polygon")
        .attr("class", d => d.isPin || !d.marketData?.no ? "remove" : "line")
        .style('transform', `translate(50%,50%)`)
        .style("opacity", 0.6)
        .attr("stroke", d => statusToColor(d.marketData?.status))
        .attr("stroke-width", 2)
        .attr("points", d => {
          if (d.isPin) return
          const point = pixels.find(dt => dt.id === d.id)
          try {
            // 箱の中心座標, ピンの座標（-12はピンのサイズ24/2。そもそもピンがずれているが真ん中にするやり方が分からなかったので線をずらしている)
            return `${d.x - sw.x} ${d.y - sw.y},\
           ${point!.parentPosition!.x - sw.x} ${point!.parentPosition!.y - sw.y - 12}`
          } catch (e) { }
        })
        .style("position", "absolute")
        .style("z-index", 0)

      nodeGroup
        .append("circle")
        .attr("class", d => `circle circle-${d.id} ${!d.marketData?.no && "remove"}`)
        .attr("r", d => d.id === this.currentSelectedId ? 12 : 11)
        .attr("fill", d => d.id === this.currentSelectedId ? "black" : statusToColor(d.marketData?.status))
        .attr("stroke", "white")
        .attr("stroke-width", 2)
        .style('cursor', 'pointer')
        .style('transform', `translate(50%,50%)`)
        .attr("cx", d => {
          const point = pixels.find(dt => dt.id === d.id)
          return point?.parentPosition?.x - sw.x
        })
        .attr("cy", d => {
          const point = pixels.find(dt => dt.id === d.id)
          return point?.parentPosition?.y - sw.y - 12
        })
        .on("click", (event, d) => {
          d3.selectAll(".circle")
            .attr("fill", d => statusToColor((d as Data).marketData?.status))
            .attr("r", 11)

          if (this.currentSelectedId === d.id) {
            this.currentSelectedId = null
            this.onSelected?.(d.marketData!, false)
            return
          }
          d3.select(`.circle-${d.id}`)
            .attr("fill", "black")
            .attr("r", 12)

          this.onSelected?.(d.marketData!, true)

          this.currentSelectedId = d.id
        })

      nodeGroup
        .append("text")
        .attr("class", d => !d.marketData?.no ? "remove" : "")
        .attr("fill", "#fff")
        .attr("stroke", "none")
        .attr("font-size", 12)
        .attr("background", "#000")
        .attr('text-anchor', 'middle')
        .attr('alignment-baseline', 'middle')
        .attr('dominant-baseline', 'middle')
        .style("pointer-events", "none")
        .style('transform', `translate(50%,50%)`)
        .attr("x", d => {
          const point = pixels.find(dt => dt.id === d.id)
          return point?.parentPosition?.x - sw.x
        })
        .attr("y", d => {
          const point = pixels.find(dt => dt.id === d.id)
          return point?.parentPosition?.y - sw.y - 11
        })
        .text(d => d.marketData?.no)
        .style('z-index', 2)

      const dom = nodeGroup.append("foreignObject")
        .attr("class", d => d.isPin || !d.id || !d.marketData?.no ? "remove" : "box")
        .style('transform', `translate(50%,50%)`)
        .attr("x", d => {
          if (d.isPin) return 0
          const latlng = this.shiftPositions.get(d.id)
          const { x, } = overlayProjection.fromLatLngToDivPixel({ lat: latlng.x, lng: latlng.y })
          return x - sw.x - this.boxWidth / 2
        })
        .attr("y", d => {
          if (d.isPin) return 0
          const latlng = this.shiftPositions.get(d.id)
          const { y } = overlayProjection.fromLatLngToDivPixel({ lat: latlng.x, lng: latlng.y })
          return y - sw.y - this.boxHeight / 2
        })
        .attr("width", this.boxWidth)
        .attr("height", this.boxHeight)
        .style("background", "white")
        .style("border", d => `2px solid ${d.marketData?.status === 'open' ? '#3f51b5' : '#ff9800'}`)
        .style("border-radius", "4px")
        .style('z-index', 1)

      nodeGroup.selectAll(".remove").remove()
      dom.html(d => Balloon(this.marketData.find(md => md.id === Number(d.id))))

      const simulationTick = () => {
        nodeGroup.selectAll(".remove").remove()

        nodeGroup
          .select(".circle")
          .attr("x", d => {
            const latlng = overlayProjection.fromDivPixelToLatLng(new google.maps.Point(d.x, d.y))
            this.shiftPositions.set(d.id, { x: parseFloat(latlng!.lat().toFixed(5)), y: parseFloat(latlng!.lng().toFixed(5)) })
            return d.x
          })

        nodeGroup
          .select(".box")
          .attr("x", d => {
            if (d.isPin) return
            return d['x']! - sw.x - this.boxWidth / 2
          })
          .attr("y", d => {
            if (d.isPin) return
            return d['y']! - sw.y - this.boxHeight / 2
          })

        nodeGroup
          .select(".line")
          .attr("points", d => {
            if (d.isPin) return
            const point = pixels.find(dt => dt.id === d.id)
            try {
              // 箱の中心座標, ピンの座標（-12はピンのサイズ24/2。そもそもピンがずれているが真ん中にするやり方が分からなかったので線をずらしている)
              return `${d.x - sw.x} ${d.y - sw.y},\
             ${point!.parentPosition!.x - sw.x} ${point!.parentPosition!.y - sw.y - 12}`
            } catch (e) { }
          })
      }

      let collide = d3.forceCollide()
        .radius((d: Data) => d.isPin ? 15 : this.boxHeight / 2)
        .iterations(2);

      if (!this.drawed) {
        collide = collide.strength(5)
        this.drawed = true
      }
      const simulation = d3.forceSimulation()
        .force("collide", collide)
        .nodes(data.concat(circleData) as any)
        .on("tick", simulationTick)

      if (this.div) {
        this.div.style.left = sw.x + "px";
        this.div.style.top = ne.y + "px";
        this.div.style.width = ne.x - sw.x + "px";
        this.div.style.height = sw.y - ne.y + "px";
      }
    }

    onRemove() {
      if (this.div) {
        (this.div.parentNode as HTMLElement).removeChild(this.div);
        delete this.div;
      }
    }
    hide() {
      if (this.div) {
        this.div.style.visibility = "hidden";
      }
    }

    show() {
      if (this.div) {
        this.div.style.visibility = "visible";
      }
    }

    toggle() {
      if (this.div) {
        if (this.div.style.visibility === "hidden") {
          this.show();
        } else {
          this.hide();
        }
      }
    }

    toggleDOM(map: google.maps.Map) {
      if (this.getMap()) {
        this.setMap(null);
      } else {
        this.setMap(map);
      }
    }

    reset(map: google.maps.Map) {
      this.setMap(null)
      this.setMap(map)
      this.update(this.bounds, this.marketData)
    }

    // private calcPosition(i, length) {
    //   const radius = 220; // 半径を画面サイズに応じて調整
    //   // 配置角度を-130°から130°の範囲に制限（50°を開ける）
    //   const angle = (-130 + (260 * i) / (length - 1)) * (Math.PI / 180); // 角度をラジアンに変換

    //   return {
    //     x: radius * Math.cos(angle), // 左右方向の配置
    //     y: -radius * Math.sin(angle), // 上下方向の配置（符号を反転）
    //   };
    // }

  }

  return new _BalloonOverlay(map)
}

function statusToColor(status: string) {
  return status === 'open' ? '#3f51b5' : '#ff9800'
}