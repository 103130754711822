import * as React from 'react'
import { useDragAndDrop } from '@/components/SortableList/useDragAndDrop'
import { ItemTypes } from './ItemTypes'
import { Button } from '@/components/Button'
import DragIndicatorOutlined from '@material-ui/icons/DragIndicatorOutlined'
import DeleteOutlined from '@material-ui/icons/DeleteOutlined'
import { tagColorCodes } from '@/models/tagColorCodes'

export interface CardProps {
  id: number
  name: string
  index: number
  colorIndex: number
  moveCard: (dragIndex: number, hoverIndex: number) => void
  dropCard: () => void
  deleteCard: (id: number) => void
}

interface DragItem {
  index: number
  id: string
  type: string
}

export const Card: React.FC<CardProps> = ({
  id,
  name,
  index,
  colorIndex,
  moveCard,
  dropCard,
  deleteCard,
}) => {
  const tagName = name ?? ''
  const [ref, isDragging, handlerId] = useDragAndDrop(index, moveCard, dropCard)
  const opacity = isDragging ? 0 : 1

  return (
    <tr
      key={id}
      ref={ref}
      style={{ opacity }}
      data-handler-id={handlerId}
      className={['bg-white border-t border-gray-150'].join(' ')}
    >
      <td className="py-2 md:px-2 w-12 md:w-24">
        <Button
          className="text-primary-font cursor-move"
          prefix={<DragIndicatorOutlined fontSize="small" />}
          outline
          size="small"
          variant="primary"
        >
          <span className="text-primary-font  hidden md:block">移動</span>
        </Button>
      </td>
      <td className="p-2 w-full flex gap-2 md:gap-4 items-center">
        <div
          className="w-full px-3 py-[9px] text-sm rounded cursor-pointer border border-[#aeb5b7]"
          onClick={() => (location.href = `/tags/${id}/edit`)}
        >
          <span
            className={`p-2 rounded-lg text-white ${
              colorIndex ? tagColorCodes[colorIndex - 1].bg : tagColorCodes[0].bg
            }`}
          >
            {tagName}
          </span>
        </div>
        <Button
          className="text-primary-font"
          prefix={<DeleteOutlined fontSize="small" />}
          size="small"
          outline
          variant="primary"
          onClick={() => {
            deleteCard(id)
          }}
        >
          <span className="text-primary-font hidden md:block">削除</span>
        </Button>
      </td>
    </tr>
  )
}
