import type { Transportation } from '@/types/property'

const SEARCH_RADIUS = 2000 // 検索半径（メートル）

export const stationSearch = (
  lat: number,
  lng: number,
  transportations: Transportation[],
  callback
) => {
  if (!transportations) {
    return
  }

  const latlng = new google.maps.LatLng(lat, lng)

  // 鉄道駅を検索
  stationSearchHeartRails(latlng, transportations, callback)
}

const stationSearchHeartRails = (latlng, transportations, callback) => {
  // 最寄駅情報取得 API
  // https://express.heartrails.com/api.html#nearest
  const path = `https://express.heartrails.com/api/json?method=getStations&x=${latlng.lng()}&y=${latlng.lat()}`

  fetch(path, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  })
    .then((res) => res.json())
    .then(async (json) => {
      if (!json.response || !json.response.station || json.response.station.length === 0) {
        return
      }

      const stations = json.response.station
        .map((station) => {
          const lineLast = station.line[station.line.length - 1]

          return {
            name: station.name,
            line: lineLast === '線' ? station.line.slice(0, station.line.length - 1) : station.line, // 線を削る
            location: new google.maps.LatLng(station.y, station.x),
          }
        })
        .filter((station) => {
          return !transportations.some((transportation) => {
            return (
              transportation['nearest_station'] === station['name'] &&
              transportation['transportation'].split('/').includes(station['line'])
            )
          })
        })

      if (stations.length > 0) {
        // 移動時間（徒歩）を検索
        const walkingTimes = await walkingTimeSearch(latlng, stations)

        // 早い順に最大3件まで
        // 徒歩分数と駅名が同じ場合は路線名を'/'で区切る
        callback(joinLineNames(walkingTimes).slice(0, 3))
      } else {
        callback([])
      }
    })
    .catch((e) => {
      console.log(`trainSearchHeartRails error`)
    })
}

const trainSearch = (latlng, service, transportationNames, callback, trainSearchCallback) => {
  // Nearby Search リクエスト
  // https://developers.google.com/maps/documentation/javascript/places?hl=ja#place_search_requests
  service.nearbySearch(
    {
      location: latlng,
      radius: SEARCH_RADIUS, // 検索半径（メートル）
      type: ['train_station'], // 駅のタイプ（鉄道）
    },
    async (results, status) => {
      if (status === 'OK') {
        const stations = results
          .map((place) => {
            const nameLast = place.name[place.name.length - 1]

            return {
              name: nameLast === '駅' ? place.name.slice(0, place.name.length - 1) : place.name, // 駅を削る
              location: place.geometry.location,
            }
          })
          .filter((station) => !transportationNames.includes(station['name']))

        trainSearchCallback(latlng, service, transportationNames, callback, stations)
      } else {
        console.log(`trainSearch error: status=${status}`)
      }
    }
  )
}

const subwaySearch = (latlng, service, transportationNames, callback, subwaySearchCallback) => {
  // Nearby Search リクエスト
  // https://developers.google.com/maps/documentation/javascript/places?hl=ja#place_search_requests
  service.nearbySearch(
    {
      location: latlng,
      radius: SEARCH_RADIUS, // 検索半径（メートル）
      type: ['subway_station'], // 駅のタイプ（地下鉄）
    },
    async (results, status) => {
      if (status === 'OK') {
        const stations = results
          .map((place) => {
            const nameLast = place.name[place.name.length - 1]

            return {
              name: nameLast === '駅' ? place.name.slice(0, place.name.length - 1) : place.name, // 駅を削る
              location: place.geometry.location,
            }
          })
          .filter((station) => !transportationNames.includes(station['name']))

        subwaySearchCallback(latlng, service, transportationNames, callback, stations)
      } else {
        console.log(`subwaySearch error: status=${status}`)
      }
    }
  )
}

const walkingTimeSearch = async (latlng, stations): Transportation[] => {
  const directionsService = new google.maps.DirectionsService()
  const locations = Object.fromEntries(
    stations
      .map((station) => station['location'])
      .map((location) => [`${location.lat()}_${location.lng()}`, location])
  )
  const results = await Promise.all(
    Object.values(locations).map((location) => {
      // ルートサービス
      // https://developers.google.com/maps/documentation/javascript/directions?hl=ja
      return directionsService.route({
        origin: latlng, // 開始位置
        destination: location, // 終了位置
        travelMode: google.maps.TravelMode.WALKING, // 移動手段（徒歩）
      })
    })
  )

  // 移動時間
  const durations = Object.fromEntries(
    results.map((result) => {
      const location = result.request.destination.location
      return [`${location.lat()}_${location.lng()}`, result.routes[0].legs[0].duration]
    })
  )

  const stationDurations = stations.map((station) => {
    const location = station['location']
    const duration = durations[`${location.lat()}_${location.lng()}`]

    return {
      name: station['name'], // 駅名（e.g. 武蔵小杉）
      line: station['line'], // 駅名（e.g. 東急東横）
      duration_text: duration.text, // 移動時間（分数の文字列）
      duration_value: duration.value, // 移動時間（秒数）
    }
  })

  return stationDurations
    .sort((a, b) => a['duration_value'] - b['duration_value'])
    .map((station) => {
      let walkTime = station['duration_text'];
      // 徒歩時間内「時間」を含む場合、「分」に変換する（e.g. 北海道富良野市老節布周辺）
      if(walkTime.includes('時間')){
        let timeParts = walkTime.split('時間');
        let hours = parseInt(timeParts[0]);
        let minutes = timeParts[1] ? parseInt(timeParts[1].split('分')[0]) : 0;
        walkTime = (hours * 60 + minutes).toString() + '分';
      }
      return {
        nearest_station: station['name'],
        transportation: station['line'],
        walking: walkTime.split('分')[0], // 分を削る
      }
    })
}

const joinLineNames = (transportations: Transportation[]): Transportation[] => {
  const keys = Array.from(new Set(transportations.map((t) => transportationKey(t))))
  const lines = {}

  if (keys.length === transportations.length) {
    return transportations
  }

  keys.forEach((key) => {
    if (!lines[key]) {
      lines[key] = []
    }
  })

  transportations.forEach((transportation) => {
    const key = transportationKey(transportation)

    lines[key].push(transportation.transportation)
  })

  const newTransportations = keys.map((key) => {
    const [nearest_station, walking] = key.split('_')

    return {
      nearest_station,
      transportation: lines[key].join('/'),
      walking,
    }
  })

  return newTransportations
}

const transportationKey = (transportation: Transportation): string => {
  return `${transportation.nearest_station}_${transportation.walking}`
}
