import type { MarketDatum } from '@/types/marketDatum'
import { generateCircularIconDataURI } from './utils/CircularIcon'

export const marketDataSetup = (
  map,
  marketData: MarketDatum[],
  prevMarkers: any[],
  clickMarker?: (marketDatum: MarketDatum, filtered: boolean) => void,
  filteredMarketDatum?: MarketDatum
): google.maps.Marker[] => {
  let markerList = new Map<number, google.maps.Marker>()

  let selectedMarketDatum: MarketDatum | undefined = undefined

  marketData.forEach((marketDatum: MarketDatum) => {
    const marker = Marker(map, marketDatum, filteredMarketDatum)

    marker.setMap(map)
    markerList[marketDatum.no] = marker

    marker.addListener('click', () => {
      if (selectedMarketDatum !== marketDatum) {
        // 他のピンを元に戻す
        if (selectedMarketDatum) {
          markerList[selectedMarketDatum.no].setIcon(
            Icon(selectedMarketDatum.no, selectedMarketDatum.status)
          )
        }

        // ピンを目立たせる
        marker.setIcon(IconClicked(marketDatum.no))
        // 絞り込み
        clickMarker(marketDatum, true)
        selectedMarketDatum = marketDatum
      } else {
        selectedMarketDatum = undefined
        // ピンを元に戻す
        marker.setIcon(Icon(marketDatum.no, marketDatum.status))
        // 絞り込み解除
        clickMarker(marketDatum, false)
      }
    })
  })
  return Object.values(markerList)
}

const Marker = (
  map,
  marketDatum: MarketDatum,
  filteredMarketDatum: MarketDatum
): google.maps.Marker => {
  const filtered = filteredMarketDatum && marketDatum.id === filteredMarketDatum.id

  const options = {
    map: map,
    position: new google.maps.LatLng(Number(marketDatum.lat), Number(marketDatum.lng)),
    icon: filtered ? IconClicked(marketDatum.no) : Icon(marketDatum.no, marketDatum.status),
    optimized: false,
  }

  return new google.maps.Marker(options)
}

const Icon = (no: number, status: string) => {
  return {
    url: generateCircularIconDataURI(status === 'open' ? '#3f51b5' : '#ff9800', `${no}`),
    scaledSize: new google.maps.Size(24, 24),
  }
}
const IconClicked = (no: number) => {
  return {
    url: generateCircularIconDataURI('black', `${no}`),
    scaledSize: new google.maps.Size(28, 28),
  }
}