import { stationSearch } from '@/components/GoogleMapViewer'

export const searchTransportaiton = (data, setData, dataLatLng) => {
  if (dataLatLng.lat && dataLatLng.lng) {
    const dataTransportations = [...data.transportations]
    // 交通機関を検索
    stationSearch(
      dataLatLng.lat,
      dataLatLng.lng,
      dataTransportations,
      (walkingTimes: Transportation[]) => {
        if (walkingTimes.length > 0) {
          const last = dataTransportations[dataTransportations.length - 1]

          // 最後の交通機関が空の場合は削除する
          if (
            !last.transportation &&
              !last.nearest_station &&
              !last.walking &&
              !last.bus &&
              !last.minutes_on_foot
          ) {
            dataTransportations.pop()
          }
          const newTransportations = [...dataTransportations, walkingTimes[0]]
          setData({
            ...data,
            transportations: newTransportations,
          })
        }
      }
    )
  }
}
