import * as React from 'react'

import { Checkbox } from '@/components/Checkbox'
import type { User } from '@/types/user'

interface Props {
  user: User
  current_user: User
  checkedDefault: boolean
  confirmationUserIds: number[]
  setConfirmationUserIds: (confirmationUserIds: number[]) => void
  clearAfterConfirmation: boolean
}

export const ConfirmationState: React.FC<Props> = ({
  user,
  current_user,
  checkedDefault,
  confirmationUserIds,
  setConfirmationUserIds,
  clearAfterConfirmation,
}) => {
  const userLink =
    current_user.role == 'admin' && user.role == 'system_admin'
      ? `/users/${user.id}`
      : `/users/${user.id}/edit`

  const [checked, setChecked] = React.useState<boolean>(false)

  React.useEffect(() => {
    const value = checkedDefault
      ? checked
        ? checked
        : !user.confirmed_at && !user.confirmation_sent_at
      : checkedDefault

    setChecked(value)
  }, [checkedDefault, clearAfterConfirmation])

  const onCheckedChange = (nextState: boolean) => {
    setChecked(nextState)

    if (nextState) {
      // 正の場合は追加
      setConfirmationUserIds(confirmationUserIds.concat([user.id]))
    } else {
      // 負の場合は取り除く
      setConfirmationUserIds(confirmationUserIds.filter((id) => id !== user.id))
    }
  }

  if (confirmationSendable(user, current_user)) {
    return (
      <span className="flex justify-between items-center border border-primary-font rounded-sm text-primary">
        <span
          className="block w-full h-full py-2 px-4 text-sm"
          onClick={(e) => {
            onCheckedChange(!checked)
          }}
        >
          {confirmedState(user)}
        </span>
        {user.confirmation_sent_at && !checked ? (
          <span></span>
        ) : (
          <Checkbox
            className="justify-end pr-[14px]"
            classNameBox={'m-0-i'}
            border={'gray-300'}
            checked={checked}
            onChange={(e) => {
              onCheckedChange(!checked)
            }}
          />
        )}
      </span>
    )
  } else {
    return (
      <span className="flex justify-between items-center border border-primary-font rounded-sm text-primary">
        <span className="block w-full h-full py-2 px-4 text-sm">{confirmedState(user)}</span>
        <span></span>
      </span>
    )
  }
}

const confirmationSendable = (user: User, current_user: User): boolean => {
  return user.company_id === current_user.company_id && !user.confirmed_at
}

const confirmedState = (user: User): string => {
  if (user.confirmed_at) {
    return '設定完了'
  } else if (user.confirmation_sent_at) {
    return '送信済み'
  } else {
    return <span>　</span>
  }
}
