export const proposalStatusOptions = [
  { value: 'ongoing', text: '検討中' },
  { value: 'yet', text: '検討終了' },
]

export const negotiationMethodOptions = [
  { value: '入札', label: '入札' },
  { value: '相対', label: '相対' },
  { value: '不明', label: '不明' },
]
