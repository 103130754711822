import * as React from 'react'
import Base from '@/components/Layout/Base'
import { Table } from '@/components/Table'
import { Input } from '@/components/Input'
import { Button, AnchorButton } from '@/components/Button'
import { Select } from '@/components/Select'
import { User } from '@/types/user'
import { Company } from '@/types/company'
import { Department } from '@/types/department'
import { Breadcrumb } from '@/components/Breadcrumb'

type DepartmentCount = {
  department_id: string
  department_name: string
  count: string
  price: string
}

type Summary = {
  summaries: DepartmentCount[]
  total_count: string
  total_price: string
}

type Props = {
  current_user: User
  year_month: string
  year_month_options: any[]
  company_id: string
  company: Company
  company_options?: any[]
  ocr_usage_department_counts: Summary 
  authenticity_token: string
  error_messages: string[]
}

const OcrUsageDetailsShowPage: React.FC<Props> = ({
  current_user,
  year_month,
  year_month_options,
  company_id,
  company,
  company_options,
  ocr_usage_department_counts,
  authenticity_token,
  error_messages,
}) => {
  const title = 'OCR利用明細'
  const initialPath = '/ocr_usage_detail'

  const [selectedCompanyId, setSelectedCompanyId] = React.useState(company_id)
  const [selectedYearMonth, setSelectedYearMonth] = React.useState(year_month)
  const [path, setPath] = React.useState(initialPath)

  return (
    <Base
      current_user={current_user}
      title={title}
      breadcrumb={<Breadcrumb link_texts={[title]} />}
      submenuCategory="settings"
    >
      <form className="mt-8" action={path} acceptCharset="UTF-8" method="get">
        {error_messages.length > 0 && (
          <ul className="mb-6 text-sm text-red">
            {error_messages.map((message, i) => (
              <li key={i} className="mb-2 break-all">
                {message}
              </li>
            ))}
          </ul>
        )}

        <div className="flex items-center text-sm">
          <div>会社名：</div>
          <div>
            {current_user.role === 'system_admin' ? (
              <Select
                name="company_id"
                id="ompany_id"
                value={selectedCompanyId}
                options={company_options}
                onChange={(e) => setSelectedCompanyId(e.target.value)}
              />
            ) : (
              <>{company.name}</>
            )}
          </div>
        </div>

        <div className="md:flex items-center justify-between mt-6">
          <div className="flex items-center h-[39px]">
            <div className="flex items-center justify-center w-1/2 md:w-fit h-full bg-primary text-white text-sm py-2 px-5 md:px-8">
              利用年月
            </div>
            <Select
              className="w-1/2 md:w-[190px] text-center rounded-l-none rounded-r-none border-l-0 border-r-0"
              name="year_month"
              id="year_month"
              value={selectedYearMonth}
              options={year_month_options}
              onChange={(e) => {
                const value = e.target.value
                const params = new URLSearchParams({ year_month: value })
                const path = `${initialPath}?${params.toString()}`

                setSelectedYearMonth(value)
                setPath(path)
              }}
            />
            <Button className="text-white h-full" variant="primary">
              検索
            </Button>
          </div>

          <div className="flex items-center h-[39px] mt-6 md:mt-0">
            <div className="flex items-center justify-center w-1/2 md:w-fit h-full bg-primary text-white text-sm py-2 px-5 md:px-8">
              利用合計金額（円・税抜き）
            </div>
            <div className="flex items-center justify-center w-1/2 md:w-[190px] h-full text-end border border-l-0 text-sm font-bold px-8">
              {formatter.format(ocr_usage_department_counts['total_price'])}
            </div>
          </div>
        </div>
      </form>

      <table className="w-full mt-6 mb-8">
        <thead>
          <tr className="block md:table-row bg-primary text-white">
            <td className="inline-block md:table-cell py-2 md:px-2 w-1/3 md:w-1/5">
              <span className="py-[5px] md:py-[7px] px-2 md:px-4 text-sm">部署名</span>
            </td>
            <td className="inline-block md:table-cell py-2 md:px-2 w-2/3 md:w-1/5 text-right md:text-left">
              <span className="py-[5px] md:py-[7px] px-2 md:px-4 text-sm">利用件数(件)</span>
            </td>
            <td className="inline-block md:table-cell py-2 md:px-2 w-1/3 md:w-1/5">
              <span className="py-[5px] md:py-[7px] px-2 md:px-4 text-sm">利用金額(円・税抜き)</span>
            </td>
          </tr>
        </thead>
        <tbody className="block md:table-row-group ">
          {ocr_usage_department_counts['summaries'].map((summary: DepartmentCount, i) => (
            <>
              <tr
                className={`block md:table-row border-gray-150 ${i !== 0 ? 'border-t' : ''}`}
                key={i}
              >
                <td className="inline-block md:table-cell py-2 md:px-2 w-1/3 md:w-1/5">
                  <span className="py-[5px] md:py-[7px] px-2 md:px-4 text-sm text-black-base">
                    {summary['department_name']}
                  </span>
                </td>
                <td className="inline-block md:table-cell py-2 md:px-2 w-2/3 md:w-1/5 text-right md:text-left">
                  <span className="py-[5px] md:py-[7px] px-2 md:px-4 text-sm text-black-base">
                    {summary['count']}
                  </span>
                </td>
                <td className="inline-block md:table-cell py-2 md:px-2 w-1/3 md:w-1/5 text-right">
                  <span className="py-[5px] md:py-[7px] px-2 md:px-4 text-sm text-black-base font-bold">
                    {formatter.format(summary['price'])}
                  </span>
                </td>
              </tr>
            </>
          ))}
        </tbody>
      </table>
    </Base>
  )
}

export default OcrUsageDetailsShowPage
const formatter = new Intl.NumberFormat('ja-JP')
