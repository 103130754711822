export const formatLatLngs = (latlngsFromPropertyShape, formType = 'ExtendsForm') => {
  return latlngsFromPropertyShape.map((latlng) => {
    if (formType === 'BordersForm') {
      return {
        latlng: [latlng.lat, latlng.lng],
        isChecked: false,
      }
    } else {
      return [latlng.lat, latlng.lng]
    }
  })
}
