import * as React from 'react'
import { Disclosure } from '@headlessui/react'
import { CaretDownIcon } from '@/components/SvgIcon'
import { UserContext } from '@/components/Layout/Base'

type Props = {
  children?: React.ReactNode
}

export const Accordion: React.FC<Props> = ({ children }) => {
  const { user } = React.useContext(UserContext)

  return (
    <Disclosure>
      {({ open }) => (
        <>
          <Disclosure.Button className="py-[15px] flex w-full overflow-scroll hover:bg-primary-light">
            <div className="flex w-full items-center justify-between text-left">
              <div>
                <div className="pl-6 mb-2 text-primary-font text-sm font-medium leading-none">
                  {user.name}
                </div>
                <div className="pl-12 text-xs text-primary-font font-medium leading-none">
                  {user.email}
                </div>
              </div>
              <CaretDownIcon
                fill="#0A689D"
                className={`mr-[18px] transition duration-100 ease-in-out ${
                  open ? 'rotate-180 ' : ''
                }`}
              />
            </div>
          </Disclosure.Button>
          <Disclosure.Panel>{children}</Disclosure.Panel>
        </>
      )}
    </Disclosure>
  )
}
