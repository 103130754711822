export const AcceptableMimeTypes = {
  'image/jpeg': [],
  'image/png': [],
  'application/pdf': [],
}

export type FileData = {
  file_name: string
  file_base64_data: string
}
