import * as React from 'react'
import Base from '@/components/Layout/Base'
import type { FileCapacity } from '@/types/fileCapacity'
import { Breadcrumb } from '@/components/Breadcrumb'

type Props = {
  current_user: User
  file_capacity: FileCapacity
}

const FileCapacitiesIndexPage: React.FC<Props> = ({ current_user, file_capacity }) => {
  const title = 'ファイル容量確認'

  return (
    <Base
      current_user={current_user}
      title={title}
      breadcrumb={<Breadcrumb link_texts={[title]} />}
      submenuCategory="settings"
    >
      <div className="mt-8 py-5 pl-8 bg-gray-light font-medium">
        <p className="text-sm">{`上限${file_capacity.limit_with_unit}中、現在${file_capacity.usage_with_unit}まで利用中`}</p>
        <p className="pt-5 text-sm">{`残り${file_capacity.usage_remain_with_unit}まで使えます`}</p>
        {file_capacity.usage_updated_at && (
          <p className="pt-5 ml-2 text-xs">{`※${file_capacity.usage_updated_at_text} 時点の数値となります`}</p>
        )}
      </div>
    </Base>
  )
}

export default FileCapacitiesIndexPage
