import * as React from 'react'
import Base from '@/components/Layout/Base'
import { Button, AnchorButton } from '@/components/Button'
import { Checkbox } from '@/components/Checkbox'
import { User } from '@/types/user'
import { DisplayItem } from '@/types/displayItem'
import { RequiredItem } from '@/types/requiredItem'
import { Breadcrumb } from '@/components/Breadcrumb'
import { Table } from '@/components/Table'
import { Row, Td, Th } from '@/components/Page/Properties/Detail/Form'
import Tooltip from '@mui/material/Tooltip'
import type { Property } from '@/types/property'
import { cammedFormat, formatNumber } from '@/utils/cammedFormat'
import { buildingStructureType } from '@/config/langs/building_structure'

type Props = {
    current_user: User
    property: Property
    display_item_data: DisplayItem[]
    required_item_data: RequiredItem[]
    authenticity_token: string
    error_messages: string[]
  }
  export const PropertyBiddingInfomaion: React.FC<Props> = ({
    property,
    current_user,
    display_item_data,
    required_item_data,
    authenticity_token,
    error_messages,
  }) => {
    const isPropertyLand = property?.property_type === '土地'
    return (
        <>
          <div className="flex justify-between items-center bg-primary text-white py-1 px-4 text-sm">
            入札情報
          </div>

        </>
    )
  }