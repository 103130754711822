import * as React from "react";
import {
  FC,
  forwardRef,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import Clipper from '@doodle3d/clipper-js';
import { useCanvasTransform } from '../../hooks/useCanvasTransform';
import { GridPattern } from './GridPattern';
import {
  Drawing,
  computeBoundingBox,
  flipY as flipYDrawing,
  reverseBorderLine,
  reverseSurroundings,
} from '../Drawing';
import { BorderLine } from './BorderLine';
import { Surroundings } from './Surroundings';
import { Vector2 } from 'three';
import { Floor } from './Floor';
import { FloorBoundingBox } from './FloorBoundingBox';
import { Region } from './Region';
import { Balcony } from './Balcony';
import { SkyFactor } from "./SkyFactor";

export type DrawingViewProps = {
  drawing: Drawing;
  floorIndex?: number;
  flipY?: boolean;
  selectedSkyFactor?: number[];
};

const Drawing2dView = forwardRef<
  {
    svg: SVGSVGElement;
  },
  DrawingViewProps
>(({ drawing, floorIndex: inputFloorIndex, flipY, selectedSkyFactor }, ref) => {
  const [root, setRoot] = useState<HTMLDivElement | null>(null);
  const [size, setSize] = useState({
    width: 0,
    height: 0,
  });

  const flipped = useMemo(() => {
    if (flipY) {
      return flipYDrawing(drawing);
    } else {
      return drawing;
    }
  }, [drawing, flipY]);

  const clockwised = useMemo(() => {
    const { border_lines } = flipped;
    const points = border_lines.map((b) => {
      if ('Road' in b) {
        return b.Road.center_segment.a;
      } else {
        return b.Neighbor.a;
      }
    });
    let n = points.length;
    let s = 0.0;
    for (let i = 0; i < n - 1; i++) {
      s += points[i][0] * points[i + 1][1] - points[i + 1][0] * points[i][1];
    }
    if (s < 0) {
      // is clockwised
      return border_lines.map((b) => reverseBorderLine(b));
    } else {
      return border_lines;
    }
  }, [flipped]);

  const surroundings_clockwised = useMemo(() => {
    const { surroundings } = flipped;
    const points = surroundings?.map((s) => {
      let point;
      if ('River' in s) {
        point = s.River.outer_segment.a;
        return point;
      }
      if ('Park' in s) {
        point = s.Park.outer_segment.a;
        return point;
      }
      if ('RailWay' in s) {
        point = s.RailWay.outer_segment.a;
        return point;
      }
    });
    const n = points?.length;
    let s = 0.0;
    for (let i = 0; i < n - 1; i++) {
      s += points[i][0] * points[i + 1][1] - points[i + 1][0] * points[i][1];
    }
    if (s < 0) {
      // is clockwised
      return surroundings.map((s) => reverseSurroundings(s));
    } else {
      return surroundings;
    }
  }, [flipped]);

  const points = useMemo(() => {
    return flipped.border_lines.map((b) => {
      if ('Road' in b) {
        return b.Road.center_segment.a;
      } else {
        return b.Neighbor.a;
      }
    });
  }, [flipped]);

  const boundingBox = useMemo(() => {
    return computeBoundingBox(flipped.border_lines);
  }, [flipped]);

  const azimithSize = 2500;
  const azimithPadding = 500;
  const azimithPosition = useMemo(() => {
    return new Vector2(
      boundingBox.min.x - azimithSize - azimithPadding,
      boundingBox.min.y - azimithSize - azimithPadding,
    );
  }, [boundingBox, azimithSize, azimithPadding]);

  const initial = useMemo(() => {
    const accum = points.reduce(
      (a, b) => {
        return [a[0] + b[0], a[1] + b[1]];
      },
      [0, 0],
    );
    const n = points.length;
    const { width, height } = root?.getBoundingClientRect() ?? {
      width: 0,
      height: 0,
    };
    const offset = new Vector2(width / 2, height / 2);
    const center = new Vector2(accum[0], accum[1]).divideScalar(n);
    const scale = 0.05;
    return {
      position: offset.sub(center.multiplyScalar(scale)),
      scale,
    };
  }, [root, points]);

  const { transform, dragging } = useCanvasTransform({
    initial,
    element: root,
    zoomSpeed: 0.0035,
    minScale: 0.005,
    maxScale: 1.0,
  });

  const sectionalGridId = useMemo(() => {
    return 'sectionalGrid';
  }, []);

  const divGridUnit = useMemo(() => {
    return 100;
  }, []);

  const sectionalGridUnit = useMemo(() => {
    return divGridUnit * 5;
  }, [divGridUnit]);

  useEffect(() => {
    const observer = new ResizeObserver((entries) => {
      const [entry] = entries;
      setSize({
        width: entry.contentRect.width,
        height: entry.contentRect.height,
      });
    });
    if (root !== null) {
      observer.observe(root);
    }
    return () => observer.disconnect();
  }, [root]);

  const rootTransform = useMemo(() => {
    const { position, scale } = transform;
    return `translate(${position.x}px, ${position.y}px) scale(${scale})`;
  }, [transform]);

  const cursor = useMemo(() => {
    return dragging ? 'grabbing' : 'grab';
  }, [dragging]);

  const floorIndex = useMemo(() => {
    return inputFloorIndex ?? 0;
  }, [inputFloorIndex]);

  const floorNumber = useMemo(() => {
    return floorIndex + 1;
  }, [floorIndex]);

  const unders = useMemo(() => {
    if (floorIndex > 0) {
      const indices = Array.from(Array(floorIndex).keys()).reverse();
      return indices.flatMap((i) => {
        const f = flipped.floors[i];
        const di = floorIndex - i;
        return f.constructable_area.shapes.map(({ outer }, j) => {
          return (
            <polyline
              key={`under-floor-${i}-${j}`}
              points={outer.points
                .map((p) => {
                  return `${p[0]},${p[1]}`;
                })
                .join(' ')}
              stroke="#000000a0"
              strokeWidth={25}
              strokeDasharray={di <= 1 ? '' : '100 100'}
              strokeLinejoin="miter"
              fill="none"
            />
          );
        });
      });
    }
    return [];
  }, [flipped, floorIndex]);

  const floor = useMemo(() => {
    return flipped.floors[floorIndex];
  }, [flipped, floorIndex]);

  const balcony = useMemo(() => {
    const balconyPoints =
      flipped.balcony?.map(({ points }) => {
        return points.map(([x, y]) => {
          return new Vector2(x, y);
        });
      }) ?? [];
    const { shapes } = floor.constructable_area;
    const src = new Clipper(
      shapes.map(({ inner: poly }) => {
        return poly.points.map((pt) => {
          return {
            X: pt[0],
            Y: pt[1],
          };
        });
      }),
    );
    const dst = new Clipper(
      balconyPoints.map((poly) => {
        return poly.map((p) => {
          return {
            X: p.x,
            Y: p.y,
          };
        });
      }),
    );
    const intersection = src.intersect(dst);
    const { paths } = intersection;
    return paths.map((path) => {
      return path.map((p) => {
        return new Vector2(p.X, p.Y);
      });
    });
  }, [flipped, floor]);

  const setRef = useCallback(
    (svg: SVGSVGElement) => {
      if (ref !== null && typeof ref === 'object') {
        ref.current = {
          svg,
        };
      } else if (typeof ref === 'function') {
        ref({
          svg,
        });
      }
    },
    [ref],
  );

  const dxfPolylines3d = useMemo(() => {
    let accumlatedHeight = 0;
    return flipped.floors.flatMap((floor, idx) => {
      const area = floor.constructable_area.shapes.map(({ inner }) => {
        return inner.points
          .map((p) => {
            return `${p[0]},${p[1]},${accumlatedHeight}`;
            // return `${p[0]},${p[1]}`;
          })
          .join(' ');
      });
      const el = area.map((points, j) => {
        return (
          <polyline
            key={`dxf-polyline3d-${idx}-${j}`}
            name="dxf-polyline3d"
            points={points}
            stroke="black"
            style={{
              visibility: 'hidden',
            }}
          />
        );
      });
      accumlatedHeight += floor.height;
      return el;
    });
  }, [flipped]);

  const skyFactor = useMemo(() => {
    return selectedSkyFactor ?? [0];
  }, [selectedSkyFactor]);

  return (
    <div
      ref={setRoot}
      style={{
        width: '100%',
        height: '100%',
        cursor,
      }}
    >
      <div
        style={{
          display: 'block',
          width: '100%',
          height: '100%',
        }}
      >
        <svg width={'100%'} height={'100%'} style={{}} ref={setRef}>
          <GridPattern
            transform={transform}
            sectionalGridId={sectionalGridId}
            divGridId={'divGrid'}
            divGridStrokeWidth={2.0}
            divGridUnit={divGridUnit}
            sectionalGridStrokeWidth={3.5}
            sectionalGridUnit={sectionalGridUnit}
          />
          <rect
            fill={`url(#${sectionalGridId})`}
            width={'100%'}
            height={'100%'}
          />
          <g
            style={{
              transformOrigin: '0px 0px 0px',
              transform: rootTransform,
            }}
          >
            <image
              href="/images/azimith.png"
              x={azimithPosition.x}
              y={azimithPosition.y}
              width={azimithSize}
              height={azimithSize}
              transform={
                !flipY
                  ? `rotate(180, ${azimithPosition.x + azimithSize / 2}, ${
                      azimithPosition.y + azimithSize / 2
                    })`
                  : ''
              }
            />
            <Region regions={flipped.regions ?? []} boundingBox={boundingBox} />
            <Balcony path={balcony} />
            {floorIndex === 0 && (
              <SkyFactor skyFactor={flipped.sky_factor} selected={skyFactor} />
            )}
            {clockwised.map((borderLine, index) => {
              return (
                <BorderLine
                  key={`border-line-${index}`}
                  borderLine={borderLine}
                  fontSize={320}
                  displayText={floorIndex === 0}
                  displayRoadWidth={floorIndex === 0}
                />
              );
            })}
            {surroundings_clockwised?.map((surrounding, index) => {
              return (
                <>
                  {'River' in surrounding && (
                    <Surroundings
                    key={`River-${index}`}
                    surrounding={surrounding.River}
                    label="水路"
                    fontSize={320}
                    displayText={floorIndex === 0}
                    displayWidth={floorIndex === 0}
                    />
                  )}
                  {'Park' in surrounding && (
                    <Surroundings
                    key={`Park-${index}`}
                    surrounding={surrounding.Park}
                    label="公園"
                    fontSize={320}
                    displayText={floorIndex === 0}
                    displayWidth={floorIndex === 0}
                    />
                  )}
                  {'RailWay' in surrounding && (
                    <Surroundings
                    key={`RailWay-${index}`}
                    surrounding={surrounding.RailWay}
                    label="線路"
                    fontSize={320}
                    displayText={floorIndex === 0}
                    displayWidth={floorIndex === 0}
                    />
                  )}
                </>
              );
            })}
            {unders}
            {flipped.floors.length > 0 && (
              <Floor floorNumber={floorNumber} floor={floor} fontSize={400} />
            )}
            {dxfPolylines3d}
            {flipped.floors.length > 0 && (
              <FloorBoundingBox
                borderLines={clockwised}
                floor={floor}
                fontSize={400}
              />
            )}
          </g>
        </svg>
      </div>
    </div>
  );
});

export { Drawing2dView };
