import FloodIcon from '@/images/icons/flood.svg'
import TidalIcon from '@/images/icons/tidal.svg'
import LandSlideIcon from '@/images/icons/landslide.svg'

import ShinsuiGuideImg from '@/images/hazard/shinsui_legend2-1.png'
import TsunamiGuideImg from '@/images/hazard/tsunami_newlegend.png'
import KeikaiKyukeisyaGuideImg from '@/images/hazard/keikai_kyukeisya.png'
import KeikaiDosekiryuGuideImg from '@/images/hazard/keikai_dosekiryu.png'
import KeikaiJisuberiGuideImg from '@/images/hazard/keikai_jisuberi.png'
import KikenDosekiryuGuideImg from '@/images/hazard/kiken_dosekiryu.png'
import KikenKyukeisyaGuideImg from '@/images/hazard/kiken_kyukeisya.png'
import KikenJisuberiGuideImg from '@/images/hazard/kiken_jisuberi.png'
import KikenNadareGuideImg from '@/images/hazard/kiken_nadare.png'

const tileUrl = {
  kouzuiSaidaiUrl: 'https://disaportaldata.gsi.go.jp/raster/01_flood_l2_shinsuishin_data/',
  kouzuiKeikakuUrl:
    'https://disaportaldata.gsi.go.jp/raster/01_flood_l1_shinsuishin_newlegend_data/',
  tsunamiUrl: 'https://disaportaldata.gsi.go.jp/raster/04_tsunami_newlegend_data/',
  takashioUrl: 'https://disaportaldata.gsi.go.jp/raster/03_hightide_l2_shinsuishin_data/',
  dosyaDosekiryuUrl: 'https://disaportaldata.gsi.go.jp/raster/05_dosekiryukeikaikuiki/',
  dosyaKyukeishaUrl: 'https://disaportaldata.gsi.go.jp/raster/05_kyukeishakeikaikuiki/',
  dosyaJisuberiUrl: 'https://disaportaldata.gsi.go.jp/raster/05_jisuberikeikaikuiki/',
  dosekiryukikenkeiryuUrl: 'https://disaportaldata.gsi.go.jp/raster/05_dosekiryukikenkeiryu/',
  kyukeisyachihoukaiUrl: 'https://disaportaldata.gsi.go.jp/raster/05_kyukeisyachihoukai/',
  jisuberikikenkasyoUrl: 'https://disaportaldata.gsi.go.jp/raster/05_jisuberikikenkasyo/',
  nadarekikenkasyoUrl: 'https://disaportaldata.gsi.go.jp/raster/05_nadarekikenkasyo/',
}

export const hazardButtonLayers = [
  {
    buttonName: 'kouzuiSaidaiButton',
    buttonState: 'kouzuiSaidaiButtonClicked',
    icon: FloodIcon,
    label: '洪水（最大規模）',
    tileName: 'kouzuiSaidai',
    tileUrl: tileUrl.kouzuiSaidaiUrl,
    guideImg: ShinsuiGuideImg,
  },
  {
    buttonName: 'kouzuiKeikakuButton',
    buttonState: 'kouzuiKeikakuButtonClicked',
    icon: FloodIcon,
    label: '洪水（計画規模）',
    tileName: 'kouzuiKeikaku',
    tileUrl: tileUrl.kouzuiKeikakuUrl,
    guideImg: ShinsuiGuideImg,
  },
  {
    buttonName: 'tsunamiButton',
    buttonState: 'tsunamiButtonClicked',
    icon: TidalIcon,
    label: '津波浸水想定',
    tileName: 'tsunami',
    tileUrl: tileUrl.tsunamiUrl,
    guideImg: TsunamiGuideImg,
  },
  {
    buttonName: 'takashioButton',
    buttonState: 'takashioButtonClicked',
    icon: TidalIcon,
    label: '高潮浸水想定区域',
    tileName: 'takashio',
    tileUrl: tileUrl.takashioUrl,
    guideImg: TsunamiGuideImg,
  },
  {
    buttonName: 'dosyaButton',
    buttonState: 'dosyaButtonClicked',
    icon: LandSlideIcon,
    label: '土砂災害警戒区域',
    tileName: 'dosya',
    tileUrl: '',
    guideImg: null,
  },
]

export const dosyaLayers = [
  {
    name: 'dosyaDosekiryu',
    url: tileUrl.dosyaDosekiryuUrl,
    label: '・土砂災害警戒区域（土石流）',
    guideLabel: '急傾斜地の崩壊</br>(黄は警戒区域、赤は特別警戒区域)',
    guideImg: KeikaiDosekiryuGuideImg,
  },
  {
    name: 'dosyaKyukeisha',
    url: tileUrl.dosyaKyukeishaUrl,
    label: '・土砂災害警戒区域（急傾斜地の崩壊）',
    guideLabel: '土石流</br>(黄は警戒区域、赤は特別警戒区域)',
    guideImg: KeikaiKyukeisyaGuideImg,
  },
  {
    name: 'dosyaJisuberi',
    url: tileUrl.dosyaJisuberiUrl,
    label: '・土砂災害警戒区域（地すべり）',
    guideLabel: '地すべり</br>(黄は警戒区域、赤は特別警戒区域)',
    guideImg: KeikaiJisuberiGuideImg,
  },
  {
    name: 'dosekiryukikenkeiryu',
    url: tileUrl.dosekiryukikenkeiryuUrl,
    label: '・土石流危険渓流',
    guideLabel: '</br>土石流危険渓流',
    guideImg: KikenDosekiryuGuideImg,
  },
  {
    name: 'kyukeisyachihoukai',
    url: tileUrl.kyukeisyachihoukaiUrl,
    label: '・急傾斜地崩壊危険箇所',
    guideLabel: '</br>急傾斜地崩壊危険箇所',
    guideImg: KikenKyukeisyaGuideImg,
  },
  {
    name: 'jisuberikikenkasyo',
    url: tileUrl.jisuberikikenkasyoUrl,
    label: '・地すべり危険箇所',
    guideLabel: '</br>地すべり危険箇所',
    guideImg: KikenJisuberiGuideImg,
  },
  {
    name: 'nadarekikenkasyo',
    url: tileUrl.nadarekikenkasyoUrl,
    label: '・雪崩危険箇所',
    guideLabel: '</br>雪崩危険箇所',
    guideImg: KikenNadareGuideImg,
  },
]
