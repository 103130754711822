import * as React from 'react'
import Base from '@/components/Layout/Base'
import { Breadcrumb } from '@/components/Breadcrumb'
import { Button, AnchorButton } from '@/components/Button'
import { Select } from '@/components/Select'
import { Input } from '@/components/Input'
import type { PropertyDocumentFormatDetail } from '@/types/propertyDocumentFormatDetail'
import type { PropertyDocument } from '@/types/propertyDocument'
import type { User } from '@/types/user'
import type { File } from '@/types/file'
import SelectAllTransferList from '@/components/SelectAllTransferList';

type Props = {
  current_user: User
  property: Property
  property_document: PropertyDocument
  attached_files: File[]
  authenticity_token: string
}

const PropertyDocumentFormatsDetailPage: React.FC<Props> = ({
  current_user,
  property,
  property_document,
  attached_files,
  authenticity_token,
}) => {
  const title = '関連資料選択'
  const param = window.location.search
  const property_id = new URLSearchParams(param).get('property_id')

  return (
    <Base
      current_user={current_user}
      title={title}
      breadcrumb={
        <Breadcrumb
          link_texts={['物件管理', '帳票作成', title]}
          property_id={property_id}
          tab="property_documents"
        />
      }
      submenuCategory="properties"
    >
      <form
        className="mt-8"
        action={`/property_documents/${property_document.id}/combine_pdf`}
        acceptCharset="UTF-8"
        method="post"
      >
        <input type="hidden" name="_method" value="post" />
        <input type="hidden" name="authenticity_token" value={authenticity_token} />

        <div>
          <div>帳票設定名：{property_document.name} </div>
          <div className="py-6">
            <SelectAllTransferList
              attachments={attached_files}
            />
          </div>
        </div>
        <div className="bg-white flex justify-center items-center w-full left-0 md:gap-4 border-t border-gray-250 pt-2 mb-3">
          <AnchorButton
            className="w-full md:w-40 md:h-auto text-primary-font border-white"
            outline
            variant="text"
            href={`/properties/${property.hashid}?tab=property_documents`}
          >
            キャンセル
          </AnchorButton>
          <Button className="w-full md:w-40 md:h-auto">PDF作成</Button>
        </div>
      </form>
    </Base>
  )
}

export default PropertyDocumentFormatsDetailPage
