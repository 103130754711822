import { Status as OcrJobStatus } from '@/api/ocr/aws/src/API'
import { OcrFormat } from '@/components/Ocr/formats'

const endpoint = '/api/ocr/save_job_status'

export class OCRJobLogger {
  jobId: string
  docType: 'rent' | 'sale' | 'acquisition'
  status: 'success' | 'failure' // used as request parameter at API request
  constructor(jobId, docType, status)  {
    this.jobId = jobId
    this.setDocType(docType)
    this.setJobStatus(status)
  }

  setDocType(doc: OcrFormat) {
    switch (doc) {
      case 'rent':
        this.docType = 'rent';
        break;
      case 'purchase':
        this.docType = 'sale';
        break;
      case 'acquisition':
        this.docType = 'acquisition';
        break;
      default:
        throw new Error('invalid doc Type is given')
        break;
    }
  }

  setJobStatus(status: OcrJobStatus) {
    switch (status) {
      case OcrJobStatus.successed:
        this.status = 'success';
        break;
      case OcrJobStatus.failed:
        this.status = 'failure';
        break;
      default:
        throw new Error('invalid job status is given')
        break;
    }
  }

  async save(csrfToken: string) {
    const response = await fetch(endpoint, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': csrfToken
      },
      body: JSON.stringify({
        ocr: {
          jobId: this.jobId,
          docType: this.docType,
          status: this.status
        }
      })
    })
    if (!response.ok) {
      throw new Error('save job request is failed')
    }
  }
}
