import { useEffect, useState } from 'react'

export function useURLParams<T>(defaultValue: T, params: string) {
  const searchParams = new URLSearchParams(window.location.search)

  const [url, setUrl] = useState<T>(
    ((typeof defaultValue === 'boolean'
      ? searchParams.get(params)?.toLowerCase() === 'true'
      : searchParams.get(params)) as T) || defaultValue
  )

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search)
    searchParams.set(params, `${url}`)
    window.history.replaceState({}, '', `${window.location.pathname}?${searchParams.toString()}`)
  }, [url])

  return [url, setUrl] as const
}
