import * as React from 'react'

export function componentDecorator(href, text, key) {
  return (
    <a
      href={href}
      key={key}
      target="_blank"
      rel="noopener noreferrer"
      className="text-primary-font hover:text-[#03476e]"
    >
      {text}
    </a>
  )
}
