import * as React from 'react'
import { Card } from './Card'
import type { User } from '@/types/user'
import { Checkbox } from '@/components/Checkbox'
import { Table } from '@/components/Table'

export interface ContainerState {
  current_user: User
  cards: User[]
}

type Props = {
  current_user: User
  users: User[]
  setShowConfirmationButton: () => void
  confirmationUserIds: number[]
  setConfirmationUserIds: (userIds: number[]) => void
  clearAfterConfirmation: boolean
  setClearAfterConfirmation: () => void
  topScrollbar?: boolean
}

export const Container: React.FC<Props> = ({
  current_user,
  users,
  setShowConfirmationButton,
  confirmationUserIds,
  setConfirmationUserIds,
  clearAfterConfirmation,
  setClearAfterConfirmation,
  topScrollbar = false,
}) => {
  {
    const sortUsers = [...users]

    const [cards, setCards] = React.useState(sortUsers)
    const [checked, setChecked] = React.useState<boolean>(false)

    const csrfToken: HTMLMetaElement = document.head.querySelector('meta[name="csrf-token"]')

    const unsentUserIds = users
      .filter(
        (user) =>
          user.company_id === current_user.company_id &&
          !user.confirmed_at &&
          !user.confirmation_sent_at
      )
      .map((user) => user.id)

    const moveCard = React.useCallback(
      (dragIndex: number, hoverIndex: number) => {
        setCards((prevCards: User[]) => {
          const dragCard = prevCards[dragIndex]
          const newCards = [...prevCards]
          newCards.splice(dragIndex, 1)
          newCards.splice(hoverIndex, 0, dragCard)
          return newCards
        })
      },
      [setCards]
    )

    const dropCard = React.useCallback(() => {
      cards.forEach((user, userIndex) => {
        if (user.company_id == current_user.company_id) {
          fetch(`/users/${user.id}/update_json`, {
            method: 'PATCH',
            body: JSON.stringify({ name: user.name, order: userIndex + 1 }),
            headers: {
              'Content-Type': 'application/json',
              'X-CSRF-Token': csrfToken.content,
            },
          }).catch(() => {
            alert('更新に失敗しました')
          })
        }
      })
    }, [cards])

    const renderCard = React.useCallback(
      (card: User, index: number, current_user: User, checked: boolean) => {
        return (
          <Card
            key={card.id}
            index={index}
            id={card.id}
            user={card}
            current_user={current_user}
            moveCard={moveCard}
            dropCard={dropCard}
            checkedDefault={checked}
            confirmationUserIds={confirmationUserIds}
            setConfirmationUserIds={setConfirmationUserIds}
            clearAfterConfirmation={clearAfterConfirmation}
          />
        )
      },
      [moveCard, dropCard, checked, confirmationUserIds, clearAfterConfirmation]
    )

    React.useEffect(() => {
      if (clearAfterConfirmation) {
        // メール送信後にチェック状態をクリアする
        setChecked(false)
        setShowConfirmationButton(false)

        // 送信後にクリア状態にするフラグをリセットする
        setClearAfterConfirmation(false)
      } else if (confirmationUserIds.length > 0) {
        setShowConfirmationButton(true)
      } else if (!checked) {
        setShowConfirmationButton(false)
      }
    }, [confirmationUserIds, clearAfterConfirmation])

    return (
      <Table
        className={
          window.innerHeight > 920
            ? 'h-[760px] md:h-[760px]'
            : 'h-[calc(100vh_-_145px)] md:h-[calc(100vh_-_155px)]'
        }
        borderLine={false}
        topScrollbar={topScrollbar}
      >
        <thead className="bg-gray-150 text-sm sticky top-0">
        <tr className="whitespace-nowrap">
          {(current_user.role === 'system_admin' || current_user.role === 'admin') && (
              <th scope="col" className="py-2 px-4 font-medium"></th>
          )}
          <th scope="col" className="py-2 px-4 font-medium">
            所属会社
          </th>
          <th scope="col" className="py-2 px-4 font-medium">
            部署
          </th>
          <th scope="col" className="py-2 px-4 font-medium">
            氏名
          </th>
          <th scope="col" className="py-2 px-4 font-medium">
            氏名（カナ）
          </th>
          <th scope="col" className="py-2 px-4 font-medium">
            メールアドレス
          </th>
          <th scope="col" className="py-2 px-4 font-medium">
            登録日時
          </th>
          <th scope="col" className="py-2 px-4 font-medium">
            更新日時
          </th>
          <th scope="col" className="py-2 px-4 font-medium">
            権限
          </th>
          <th scope="col" className="py-2 px-4 font-medium">
            volume check
          </th>
          {(current_user.role === 'system_admin' || current_user.role === 'admin') && (
              <>
                <th scope="col" className="py-2 px-4 font-medium">
                  アカウントロック
                </th>
                <th scope="col" className="py-2 px-4 font-medium">
                  <span className="flex justify-between items-center">
                    <span className="mr-2">確認メール</span>
                    <span>
                      <Checkbox
                          border={'gray-700'}
                          classNameBox={'m-0-i'}
                          checked={checked}
                          onChange={(e) => {
                            const nextState = !checked

                            setChecked(nextState)

                            // 確認メール送信ボタンを表示/非表示
                            setShowConfirmationButton(nextState)

                            if (nextState) {
                              const userIds = new Set(confirmationUserIds.concat(unsentUserIds))
                              setConfirmationUserIds([...userIds])
                            } else {
                              setConfirmationUserIds([])
                            }
                          }}
                      />
                    </span>
                  </span>
                </th>
              </>
          )}
          <th scope="col" className="py-2 px-4 font-medium"></th>
        </tr>
        </thead>
        <tbody className="whitespace-nowrap">
        {cards.map((card, i) => renderCard(card, i, current_user, checked))}
        </tbody>
      </Table>
    )
  }
}
