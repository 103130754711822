import * as React from 'react'
import type { BaseProps } from './types'

type ButtonAsButton = BaseProps & {
  buttonref?: React.ForwardedRef<HTMLButtonElement>
  as: 'button'
}
type ButtonAsAnchor = BaseProps & {
  anchorref?: React.ForwardedRef<HTMLAnchorElement>
  as: 'link'
}

type ButtonProps = ButtonAsButton &
  Omit<React.ButtonHTMLAttributes<HTMLButtonElement>, keyof ButtonAsButton>
type AnchorProps = ButtonAsAnchor &
  Omit<React.AnchorHTMLAttributes<HTMLAnchorElement>, keyof ButtonAsAnchor>

type Props = ButtonProps | AnchorProps

export const ButtonInner: React.FC<Props> = ({
  children,
  className,
  wide,
  outline,
  variant = 'primary',
  size = 'default',
  prefix,
  suffix,
  square,
  ...props
}) => {
  const style = outline ? styles.outline : styles.default
  const variatStyle =
    variant === 'text' ? styles.text : outline ? styles[variant].outline : styles[variant].default
  const sizeStyle = styles.size[size]
  const squareStyle = square ? styles.square : ''

  const buttonStyles = [
    styles.base,
    style,
    wide && 'w-full',
    variant && variatStyle,
    sizeStyle,
    squareStyle,
    className,
  ].join(' ')

  return props.as === 'link' ? (
    <a {...props} className={buttonStyles} ref={props.anchorref}>
      {prefix}
      {children}
      {suffix}
    </a>
  ) : (
    <button {...props} className={buttonStyles} ref={props.buttonref}>
      {prefix}
      {children}
      {suffix}
    </button>
  )
}

const styles = {
  base: `
    inline-flex
    align
    items-center
    justify-center
    gap-1.5
    rounded-sm
    leading-none
    whitespace-nowrap
    duration-200
    transition-opacity
    hover:opacity-80
    disabled:opacity-70
    disabled:cursor-not-allowed
  `,
  primary: {
    default: `
      bg-primary-font
      text-white
    `,
    outline: `
      border-primary-font
      text-primary-font
    `,
  },
  primaryLight: {
    default: `
      bg-primary-light
      text-primary
    `,
    outline: `
      bg-primary-light
      border-primary
      text-primary
    `,
  },
  red: {
    default: `
      bg-red
      text-white
    `,
    outline: `
      border-red
      text-red
    `,
  },
  redLight: {
    default: `
      bg-red-100
      text-red
    `,
    outline: `
      bg-red-100
      border-red-200
      text-red
    `,
  },
  gray: {
    default: `
      bg-gray-700
      text-white
    `,
    outline: `
      border-gray-700
      text-gray-700
    `,
  },
  grayLight: {
    default: `
      bg-gray-100
      text-gray-300
    `,
    outline: `
      bg-gray-100
      border-gray-300
      text-gray-300
    `,
  },
  text: `
    bg-transparent
  `,
  default: `
    rounded-sm
    border
    border-transparent
    box-border
  `,
  outline: `
    rounded-sm
    border
    box-border
  `,
  size: {
    default: `
      py-[11px]
      md:py-[13px]
      px-4
      text-sm
    `,
    small: `
      py-[5px]
      md:py-[7px]
      px-4
      text-sm
    `,
    tiny: `
      py-[4px]
      md:py-[4px]
      px-2
      text-xs
    `,
  },
  square: 'rounded-none',
}
