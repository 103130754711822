import type { MarketDatum } from '@/types/marketDatum'
import { generateCircularIconDataURI } from './utils/CircularIcon'

type Marker = any

export const marketDataReportSetup = (
  map: google.maps.Map,
  marketData: MarketDatum[]
): Marker[] => {
  let markerList = []

  marketData.forEach((marketDatum: MarketDatum) => {
    const marker = Marker(map, marketDatum) as google.maps.Marker & { marketDatum: MarketDatum }
    marker.marketDatum = marketDatum
    marker.setMap(map)
    markerList.push(marker)
  })

  return markerList
}

const Marker = (map, marketDatum: MarketDatum): google.maps.Marker => {
  const options = {
    map: map,
    position: new google.maps.LatLng(Number(marketDatum?.lat || 0), Number(marketDatum?.lng || 0)),
    icon: Icon(marketDatum.no, marketDatum.status),
    optimized: false,
  }

  return new google.maps.Marker(options)
}

const Icon = (no: number, status: string) => {
  return {
    url: generateCircularIconDataURI(status === 'open' ? '#3f51b5' : '#ff9800', `${no}`),
    scaledSize: new google.maps.Size(24, 24),
  }
}