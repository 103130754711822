import { dosyaLayers, hazardButtonLayers } from '@/components/GoogleMapViewer/utils/hazardData'
import { LatLng } from '@/types/property'
import { User } from '@/types/user'
import { EventEmitter } from 'events'
import { processParamValue } from '../../utils/processParamValue'
import { EmitterEventType, PropertyMarker } from './PropertyMarker'
import { YoutoChiiki } from './YoutoChiiki'
import { MapModeType } from './types/MapModeType'
import { Params } from './types/PropertiesParams'

export class LayerButton {
  readonly emitter = new EventEmitter()

  private map: google.maps.Map
  private user: User
  private mode: MapModeType
  private youtoChiiki: YoutoChiiki
  private propertyMarker: PropertyMarker
  private params: Params = {} as Params

  private overlays = {
    kouzuiSaidai: null,
    kouzuiKeikaku: null,
    tsunami: null,
    takashio: null,
    dosyaDosekiryu: null,
    dosyaKyukeisha: null,
    dosyaJisuberi: null,
    dosekiryukikenkeiryu: null,
    kyukeisyachihoukai: null,
    jisuberikikenkasyo: null,
    nadarekikenkasyo: null,
  }

  private layerButtonStates = {
    showPolygonsClicked: false,
    showKouzuMapClicked: false,
    showOtherPropertiesClicked: false,
    kouzuiSaidaiButtonClicked: false,
    kouzuiKeikakuButtonClicked: false,
    tsunamiButtonClicked: false,
    takashioButtonClicked: false,
    dosyaButtonClicked: false,
  }

  // 凡例表示非表示
  private showUsageGuide = false

  constructor({
    map,
    mode,
    user,
    lat,
    lng,
    polygons_api_base_url,
    property_id,
    propertyLat,
    propertyLng,
    propertyShape,
    areaTextFromResponse,
    siteArea,
    areaShape,
    csrfToken,
  }: {
    map: google.maps.Map
    mode: MapModeType
    user: User
    lat: number
    lng: number
    polygons_api_base_url: string
    property_id: number
    propertyLat?: number
    propertyLng?: number
    propertyShape?: LatLng[]
    areaTextFromResponse?
    siteArea?
    areaShape?
    csrfToken: string
  }) {
    this.map = map
    this.user = user
    this.mode = mode

    this.youtoChiiki = new YoutoChiiki(map, lat, lng, polygons_api_base_url)
    this.youtoChiiki.emitter.on('setShowInfoTable', (f) => this.emitter.emit('setShowInfoTable', f))
    this.youtoChiiki.emitter.on('setUsageInfos', (f) => this.emitter.emit('setUsageInfos', f))
    this.youtoChiiki.emitter.on('setAntifireInfo', (f) => this.emitter.emit('setAntifireInfo', f))
    this.youtoChiiki.emitter.on('setHeightInfos', (f) => this.emitter.emit('setHeightInfos', f))
    this.youtoChiiki.emitter.on('setShadeInfos', (f) => this.emitter.emit('setShadeInfos', f))
    this.youtoChiiki.emitter.on('setYoutoInfo', (f) => this.emitter.emit('setYoutoInfo', f))
    this.youtoChiiki.emitter.on('setChibanInfo', (f) => this.emitter.emit('setChibanInfo', f))

    this.propertyMarker = new PropertyMarker({
      map,
      mode,
      property_id,
      propertyLat,
      propertyLng,
      propertyShape,
      areaTextFromResponse,
      siteArea,
      areaShape,
      csrfToken,
    })

    this.layerButtonStates.showOtherPropertiesClicked = mode === 'property_edit' // 自社物件登録はデフォルトで有効
  }

  setupView() {
    if (this.user.company.pricing_type !== 'basic') {
      // レイヤーボタン
      this.setupLayerButton()
      // 凡例ボタン
      this.setupUserGuideButton()
      // 用途地域ボタン
      this.setupYoutoChiikiButton()
      // 自社登録物件ボタン
      if (this.mode != 'list') {
        this.setupPropertyButton()
      }

      // 公図ボタン
      if (this.user.company.pricing_type === 'owner' && this.user.role === 'system_admin') {
        this.setupKozuButton()
      }

      // ハザードボタン
      this.setupHazardButton()
    }
    // 物件管理一覧の場合はmapクリック時にマーカーを追加
    if (this.mode === 'list') {
      this.propertyMarker.addClickMarker()
    }

    // 物件編集画面ではDrawingManagerを表示
    if (this.mode === "property_edit") {
      this.propertyMarker.setupDrawingManager()
      this.propertyMarker.addClickMarker()
    }

    this.propertyMarker.setupMarker()

    google.maps.event.addListener(this.map, 'bounds_changed', this.boundsChanged.bind(this))
  }

  idle() {
    const isDiff = this.updateParams()
    if (!isDiff && !this.propertyMarker.markerRefresh) {
      return
    }

    if (this.mode === 'list') {
      // 自社物件一覧の場合はデフォルトで一覧を表示する
      this.propertyMarker.idle(this.params, true)
    } else {
      this.propertyMarker.idle(this.params, this.layerButtonStates.showOtherPropertiesClicked)
    }

    if (this.map.getZoom() >= 17) {
      if (
        this.layerButtonStates.showPolygonsClicked ||
        this.layerButtonStates.showKouzuMapClicked
      ) {
        this.youtoChiiki.idle(this.layerButtonStates)
      }

      if (this.layerButtonStates.showPolygonsClicked) {
        this.setShowInfoTable(true)
      } else {
        this.youtoChiiki.clearCityPlanItems()
        this.setShowInfoTable(false)
      }

      if (!this.layerButtonStates.showKouzuMapClicked) {
        this.youtoChiiki.clearCadastralItems()
      }
    } else {
      this.setShowInfoTable(false)
      this.youtoChiiki.clearCityPlanItems()
      this.youtoChiiki.clearCadastralItems()
    }
  }

  boundsChanged() {
    if (this.map.getZoom() < 17) {
      this.youtoChiiki.clearCityPlanItems()
      this.youtoChiiki.clearCadastralItems()
    } else {
      if (!this.layerButtonStates.showPolygonsClicked) {
        this.youtoChiiki.clearCityPlanItems()
      }

      if (!this.layerButtonStates.showKouzuMapClicked) {
        this.youtoChiiki.clearCadastralItems()
      }
    }
  }

  /** `this.propertyMarker.emitter.on`のtype-safe */
  propertyMarkerEmitterOn(eventName: EmitterEventType, listener?: (...args: any[]) => void) {
    return listener && this.propertyMarker.emitter.on(eventName, listener)
  }

  /** マーカーをリロード */
  reloadMarker() {
    this.propertyMarker.reloadMarker()
  }

  /** ピンや敷地形状を初期表示に戻す */
  renderProperty() {
    this.propertyMarker.renderProperty()
  }

  /** ピンや敷地形状をクリア */
  clearProperty() {
    this.propertyMarker.clearProperty()
  }

  setShowInfoTable(show: boolean) {
    this.emitter.emit('setShowInfoTable', show)
  }

  showAreaText(polygon, areaValue = '') {
    this.propertyMarker.showAreaText(polygon, areaValue)
  }

  async setPin(address: string) {
    this.propertyMarker.setPin(address)
  }

  // MARK: private

  // MARK: レイヤーボタン
  private setupLayerButton() {
    // レイヤーボタン
    const layerButtonSvgPath =
      'm11.99 18.54-7.37-5.73L3 14.07l9 7 9-7-1.63-1.27-7.38 5.74zM12 16l7.36-5.73L21 9l-9-7-9 7 1.63 1.27L12 16z'
    const svgAttributes = {
      class: 'MuiSvgIcon-root MuiSvgIcon-fontSizeSmall',
      focusable: 'false',
      'aria-hidden': 'true',
      viewBox: '0 0 24 24',
    }
    const layerButton = this.createLayerButtons(
      'svg',
      layerButtonSvgPath,
      'レイヤー',
      () => {
        this.map.setCenter(this.map.getCenter())
        document.querySelectorAll('.polygons-button').forEach((elem) => {
          ; (elem as HTMLElement).style.display =
            (elem as HTMLElement).style.display === 'none' ? 'block' : 'none'
        })
      },
      svgAttributes
    )
    this.map.controls[google.maps.ControlPosition.RIGHT_TOP].push(layerButton)
  }

  // MARK: 凡例ボタン
  private setupUserGuideButton() {
    const button = document.createElement('button')
    button.classList.add('custom-map-control-button')
    button.classList.add('usage-guide-button')
    button.setAttribute('type', 'button')
    button.style.fontSize = '14px'
    button.style.color = 'black'
    button.style.display = 'hidden'
    button.textContent = '凡例'
    button.style.zIndex = 'unset'
    this.map.controls[google.maps.ControlPosition.RIGHT_BOTTOM].push(button)

    this.userGuideButtonHidden(
      !hazardButtonLayers.some((layer) => this.layerButtonStates[layer.buttonState]),
      button
    )

    button.addEventListener('click', () => {
      const isVisible = hazardButtonLayers.some(
        (layer) => this.layerButtonStates[layer.buttonState]
      )
      if (!this.showUsageGuide && isVisible) {
        this.updateUserGuideImgs(false)
        this.showUsageGuide = true
        button.style.color = 'red'
      } else {
        this.updateUserGuideImgs(true)
        this.showUsageGuide = false
        button.style.color = 'black'
      }
    })
  }

  // MARK: 自社登録物件ボタン（現状システム管理者のみ閲覧可能）
  private setupPropertyButton() {
    const otherPropertiesSvgAttributes = {
      class: 'MuiSvgIcon-root MuiSvgIcon-fontSizeSmall',
      focusable: 'false',
      'aria-hidden': 'true',
      viewBox: '0 0 25 28',
    }
    const otherPropertiesSvgPath =
      'M19.3 16.9c.4-.7.7-1.5.7-2.4 0-2.5-2-4.5-4.5-4.5S11 12 11 14.5s2 4.5 4.5 4.5c.9 0 1.7-.3 2.4-.7l3.2 3.2 1.4-1.4-3.2-3.2zm-3.8.1c-1.4 0-2.5-1.1-2.5-2.5s1.1-2.5 2.5-2.5 2.5 1.1 2.5 2.5-1.1 2.5-2.5 2.5zM12 20v2C6.48 22 2 17.52 2 12S6.48 2 12 2c4.84 0 8.87 3.44 9.8 8h-2.07c-.64-2.46-2.4-4.47-4.73-5.41V5c0 1.1-.9 2-2 2h-2v2c0 .55-.45 1-1 1H8v2h2v3H9l-4.79-4.79C4.08 10.79 4 11.38 4 12c0 4.41 3.59 8 8 8z'
    const otherPropertiesButtonFunction = () => {
      this.layerButtonStates.showOtherPropertiesClicked =
        !this.layerButtonStates.showOtherPropertiesClicked
      otherPropertiesButton.style.color = this.layerButtonStates.showOtherPropertiesClicked
        ? 'red'
        : 'black'
      if (this.layerButtonStates.showOtherPropertiesClicked) {
        otherPropertiesButton.style.color = 'red'
      } else {
        otherPropertiesButton.style.color = 'black'
      }
      let currentCenter = this.map.getCenter()
      this.map.panTo(
        new google.maps.LatLng(currentCenter.lat() + 0.000001, currentCenter.lng() + 0.000001)
      )
    }
    const otherPropertiesButton = this.createLayerButtons(
      'svg',
      otherPropertiesSvgPath,
      '自社登録物件',
      otherPropertiesButtonFunction,
      otherPropertiesSvgAttributes
    )
    if (this.mode != 'property_edit') {
      otherPropertiesButton.classList.add('polygons-button')
      otherPropertiesButton.style.display = 'none'
    }

    otherPropertiesButton.style.color = this.layerButtonStates.showOtherPropertiesClicked
      ? 'red'
      : 'black'
    this.map.controls[google.maps.ControlPosition.RIGHT_TOP].push(otherPropertiesButton)
  }

  private userGuideButtonHidden(isHidden: boolean, button?: HTMLButtonElement) {
    const guideBtn = button || document.querySelector<HTMLButtonElement>('.usage-guide-button')
    if (guideBtn) {
      guideBtn.style.display = isHidden ? 'none' : 'block'
    }
  }

  private updateUserGuideImgs(isHidden: boolean) {
    // 少しちらつくが、実装がシンプルなので一旦消して再生成する
    document.querySelector('#guide-container')?.remove()
    if (isHidden) {
      return
    }

    const guideContainer = document.createElement('div')
    guideContainer.style.position = 'absolute'
    guideContainer.id = 'guide-container'
    guideContainer.style.width = '100%'

    const guideDiv = document.createElement('div')
    guideDiv.className =
      'absolute h-[196px] bottom-[-75px] left-[100px] flex bg-white opacity-60 rounded overflow-x-scroll max-w-[calc(100%-160px)]'

    const addGuideItem = (label: string, image: string) => {
      const guideItem = document.createElement('div')
      guideItem.className = 'flex flex-col space-between m-[10px]'

      const guideTitle = document.createElement('div')
      guideTitle.innerHTML = label
      guideTitle.className = 'text-center mb-[5px] text-sm w-[140px] h-full'

      const img = document.createElement('img')
      img.src = image
      img.style.width = '140px'
      img.style.objectFit = 'contain'

      guideItem.appendChild(guideTitle)
      guideItem.appendChild(img)
      guideDiv.appendChild(guideItem)
    }

    hazardButtonLayers.forEach((layer) => {
      if (!this.layerButtonStates[layer.buttonState]) return
      if (layer.tileName === 'dosya') {
        Object.entries(this.overlays).forEach(([key, value]) => {
          if (!value || hazardButtonLayers.some((layer) => layer.tileName === key)) return

          const item = dosyaLayers.find((layer) => layer.name === key)
          if (!item) return
          addGuideItem(item.guideLabel, item.guideImg)
        })
      } else {
        addGuideItem(layer.label, layer.guideImg)
      }
    })

    guideContainer.appendChild(guideDiv)

    this.map.controls[google.maps.ControlPosition.LEFT_BOTTOM].push(guideContainer)
  }

  // MARK: 用途地域ボタン
  private setupYoutoChiikiButton() {
    const usageSvgAttributes = {
      class: 'MuiSvgIcon-root MuiSvgIcon-fontSizeSmall',
      focusable: 'false',
      'aria-hidden': 'true',
      viewBox: '0 0 25 28',
    }
    const usageSvgPath =
      'M17 11V3H7v4H3v14h8v-4h2v4h8V11h-4zM7 19H5v-2h2v2zm0-4H5v-2h2v2zm0-4H5V9h2v2zm4 4H9v-2h2v2zm0-4H9V9h2v2zm0-4H9V5h2v2zm4 8h-2v-2h2v2zm0-4h-2V9h2v2zm0-4h-2V5h2v2zm4 12h-2v-2h2v2zm0-4h-2v-2h2v2z'
    const usageButtonFunction = () => {
      const newLayerButtonStates = {
        ...this.layerButtonStates,
        showPolygonsClicked: !this.layerButtonStates.showPolygonsClicked,
      }
      this.handleLayerButtonStatesChange(newLayerButtonStates)

      this.emitter.emit('setShowYoutoInfo', newLayerButtonStates.showPolygonsClicked)
      if (!newLayerButtonStates.showPolygonsClicked) {
        usageButton.style.color = 'black'
        this.setShowInfoTable(false)
        this.map.setOptions({
          draggable: true,
          scrollwheel: true,
          disableDoubleClickZoom: false,
          zoomControl: true,
          scaleControl: true,
          panControl: true,
          minZoom: 0,
          maxZoom: 20,
        })

        this.youtoChiiki.youtoInfo = {}
        this.emitter.emit('setYoutoInfo', null)
      } else {
        usageButton.style.color = 'red'
      }
    }
    const usageButton = this.createLayerButtons(
      'svg',
      usageSvgPath,
      '用途地域',
      usageButtonFunction,
      usageSvgAttributes
    )
    usageButton.classList.add('polygons-button')
    usageButton.style.display = 'none'
    usageButton.style.color = this.layerButtonStates.showPolygonsClicked ? 'red' : 'black'
    this.map.controls[google.maps.ControlPosition.RIGHT_TOP].push(usageButton)
  }

  // MARK: 公図ボタン
  private setupKozuButton() {
    const kouzuMapSvgAttributes = {
      class: 'MuiSvgIcon-root MuiSvgIcon-fontSizeSmall',
      focusable: 'false',
      'aria-hidden': 'true',
      viewBox: '0 0 25 28',
    }
    const kouzuMapSvgPath =
      'M3 21h2v-2H3v2zm4 0h2v-2H7v2zM5 7H3v2h2V7zM3 17h2v-2H3v2zM9 3H7v2h2V3zM5 3H3v2h2V3zm12 0h-2v2h2V3zm2 6h2V7h-2v2zm0-6v2h2V3h-2zm-4 18h2v-2h-2v2zM13 3h-2v8H3v2h8v8h2v-8h8v-2h-8V3zm6 18h2v-2h-2v2zm0-4h2v-2h-2v2z'
    const kouzuMapButtonFunction = () => {
      let newLayerButtonStates = {
        ...this.layerButtonStates,
        showKouzuMapClicked: !this.layerButtonStates.showKouzuMapClicked,
      }
      this.handleLayerButtonStatesChange(newLayerButtonStates)

      kouzuMapButton.style.display = 'block'
      if (newLayerButtonStates.showKouzuMapClicked) {
        kouzuMapButton.style.color = 'red'
      } else {
        kouzuMapButton.style.color = 'black'
      }
    }
    const kouzuMapButton = this.createLayerButtons(
      'svg',
      kouzuMapSvgPath,
      '公図情報',
      kouzuMapButtonFunction,
      kouzuMapSvgAttributes
    )
    kouzuMapButton.id = 'kouzuMapButton'
    kouzuMapButton.classList.add('polygons-button')
    kouzuMapButton.style.display = 'none'
    this.map.controls[google.maps.ControlPosition.RIGHT_TOP].push(kouzuMapButton)
  }

  // MARK: ハザードボタン系
  private setupHazardButton() {
    let dosyaPanelDiv = document.createElement('div')
    dosyaPanelDiv.classList.add('dosya-control-panel')
    dosyaPanelDiv.title = '土砂災害パーネル'
    const _this = this
    dosyaLayers.forEach((layer) => {
      let p = document.createElement('p')
      p.classList.add('panel-line')
      p.innerText = layer.label
      p.id = layer.name
      p.addEventListener('click', () => {
        if (_this.overlays[layer.name] === null) {
          p.style.backgroundColor = '#eee'
          _this.setTile(layer.name, layer.url)
        } else {
          p.style.backgroundColor = 'initial'
          _this.removeTile(layer.name)
        }
        _this.updateUserGuideImgs(!_this.showUsageGuide)
      })
      p.addEventListener('mouseover', () => {
        p.style.backgroundColor = '#eee'
      })
      p.addEventListener('mouseout', () => {
        if (_this.overlays[layer.name] === null) {
          p.style.backgroundColor = 'initial'
        }
      })
      dosyaPanelDiv.appendChild(p)
    })
    this.map.controls[google.maps.ControlPosition.LEFT_CENTER].push(dosyaPanelDiv)

    hazardButtonLayers.forEach((data) => {
      const button = this.createLayerButtons('img', data.icon, data.label, () => {
        _this.layerButtonStates[data.buttonState] = !_this.layerButtonStates[data.buttonState]
        if (this.layerButtonStates[data.buttonState]) {
          button.style.color = 'red'
          if (data.tileUrl) {
            _this.setTile(data.tileName, data.tileUrl)
          }
        } else {
          button.style.color = 'black'
          if (data.tileUrl) {
            _this.removeTile(data.tileName)
          }
        }
        if (data.buttonName === 'dosyaButton') {
          if (_this.layerButtonStates[data.buttonState]) {
            dosyaPanelDiv.style.display = 'block'
          } else {
            dosyaPanelDiv.style.display = 'none'
          }
        }

        const userGuideIsHidden = !hazardButtonLayers.some(
          (layer) => _this.layerButtonStates[layer.buttonState]
        )
        _this.userGuideButtonHidden(userGuideIsHidden)
        _this.updateUserGuideImgs(!_this.showUsageGuide || userGuideIsHidden)
      })
      button.classList.add('polygons-button')
      button.style.display = 'none'
      this.map.controls[google.maps.ControlPosition.RIGHT_TOP].push(button)
    })
  }

  private createLayerButtons(type, imageSource, buttonText, buttonFunction, svgAttributes = {}) {
    const button = document.createElement('button')
    button.classList.add('custom-map-control-button')
    button.setAttribute('type', 'button')
    button.style.fontSize = '14px'
    button.style.margin = '4px 4px 0 0'
    button.style.color = 'black'
    if (type === 'svg') {
      const svg = document.createElementNS('http://www.w3.org/2000/svg', 'svg')
      Object.entries(svgAttributes).forEach(([key, value]) => {
        svg.setAttribute(key, String(value))
      })
      const path = document.createElementNS('http://www.w3.org/2000/svg', 'path')
      path.setAttribute('d', imageSource)
      svg.appendChild(path)
      button.appendChild(svg)
    } else if (type === 'img') {
      const img = document.createElement('img')
      img.src = imageSource
      img.height = 15
      img.width = 15
      img.style.display = 'inline-block'
      img.style.verticalAlign = 'middle'
      img.style.marginRight = '5px'
      button.appendChild(img)
    }
    const span = document.createElement('span')
    span.textContent = buttonText
    button.appendChild(span)
    button.addEventListener('click', buttonFunction)
    return button
  }

  private handleLayerButtonStatesChange(newLayerButtonStates) {
    const oldLayerButtonStates = { ...this.layerButtonStates }
    this.layerButtonStates = newLayerButtonStates

    if (newLayerButtonStates.showPolygonsClicked !== oldLayerButtonStates.showPolygonsClicked) {
      if (newLayerButtonStates.showPolygonsClicked) {
        this.setShowInfoTable(true)
        this.youtoChiiki.idle(this.layerButtonStates)
      } else {
        this.youtoChiiki.clearCityPlanItems()
      }
    }

    if (newLayerButtonStates.showKouzuMapClicked !== oldLayerButtonStates.showKouzuMapClicked) {
      if (newLayerButtonStates.showKouzuMapClicked) {
        this.youtoChiiki.idle(this.layerButtonStates)
      } else {
        this.youtoChiiki.clearCadastralItems()
      }
    }
    this.userGuideButtonHidden(
      !hazardButtonLayers.some((layer) => this.layerButtonStates[layer.buttonState])
    )
  }

  private setTile(name, url) {
    if (this.overlays[name] === null) {
      let option: google.maps.MapType = {
        tileSize: new google.maps.Size(256, 256),
        // isPng: true, いらない説？
        getTile: function (coord, zoom, ownerDocument) {
          let fullUrl = `${url}${zoom}/${coord.x}/${coord.y}.png`
          let img = ownerDocument.createElement('img')
          img.style.opacity = '0.8'
          let tempImg = new Image()
          tempImg.onload = function () {
            img.src = tempImg.src
          }
          tempImg.src = fullUrl
          return img
        },
        alt: '',
        maxZoom: 0,
        minZoom: 0,
        name: '',
        projection: undefined,
        radius: 0,
        releaseTile: () => { },
      }
      let currentLength = this.map.overlayMapTypes.getLength()
      this.map.overlayMapTypes.insertAt(currentLength, option)
      this.overlays[name] = currentLength
    }
  }

  private removeTile(name) {
    let overlayIndex = this.overlays[name]
    if (overlayIndex !== null) {
      this.map.overlayMapTypes.removeAt(overlayIndex)
      for (let key in this.overlays) {
        if (this.overlays[key] !== null && this.overlays[key] > overlayIndex) {
          this.overlays[key]--
        }
      }
      this.overlays[name] = null
    }
  }

  // MARK: パラメータ更新 返り値は更新があったかどうか
  private updateParams(): boolean {
    const latlngBounds = this.map.getBounds()
    const swLatlng = latlngBounds.getSouthWest()
    const neLatlng = latlngBounds.getNorthEast()
    const currentParams = new URLSearchParams(window.location.search)

    if (
      this.params &&
      this.params.lat1 == swLatlng.lat() &&
      this.params.lng1 == swLatlng.lng() &&
      this.params.lat2 == neLatlng.lat() &&
      this.params.lng2 == neLatlng.lng()
    ) {
      return false
    }
    this.params = {
      lat1: swLatlng.lat(),
      lng1: swLatlng.lng(),
      lat2: neLatlng.lat(),
      lng2: neLatlng.lng(),
      with_shape: this.map.getZoom() >= 17,
      'q[prefecture_eq]': currentParams.get('q[map_prefecture_eq]') || '',
      'q[city_eq]': currentParams.get('q[map_city_eq]') || '',
      'q[town_eq]': currentParams.get('q[map_town_eq]') || '',
      'q[user_id_eq]': currentParams.get('q[property_user_id_eq]') || '', // 情報入手者
      'q[proposals_source_user_id_eq]': currentParams.get('q[source_user_id_eq]') || '', // 案件担当者
      'q[proposals_source_user_department_name_eq]':
        currentParams.get('q[user_department_name_eq]') || '', // 情報入手部署
      'q[user_department_name_eq]': currentParams.get('q[user_department_name_eq]') || '', // 案件入手部署
      'q[proposals_sourced_at_gteq]': currentParams.get('q[sourced_at_gteq]') || '', // 入手日from
      'q[proposals_sourced_at_lteq]': currentParams.get('q[sourced_at_lteq]') || '', // 入手日to
      'q[purchace_applied_at_gteq]': currentParams.get('q[property_purchace_applied_at_gteq]'), // 買付提出日from
      'q[purchace_applied_at_lteq]': currentParams.get('q[property_purchace_applied_at_lteq]'), // 買付提出日to
      'q[purchace_contract_at_gteq]':
        currentParams.get('q[property_purchace_contract_at_gteq]') || '', // 仕入契約日from
      'q[purchace_contract_at_lteq]':
        currentParams.get('q[property_purchace_contract_at_lteq]') || '', // 仕入れ契約日to
      'q[proposals_order_gteq]': currentParams.get('q[proposal_order_gteq]') || '', // 順番from
      'q[proposals_order_lteq]': currentParams.get('q[proposal_order_lteq]') || '', // 順番to
      'q[proposals_ongoing_eq]': currentParams.get('q[ongoing_eq]') || '', // 進捗
      'q[tag_name_eq]': currentParams.get('q[property_tag_name_eq]') || '', // タグ
      'q[property_type_eq]': currentParams.get('q[property_property_type_eq]') || '', // 物件種目
      'q[suggested_total_price_gteq]': currentParams.get('q[suggested_total_price_gteq]') || '',// 売主希望総額 from
      'q[suggested_total_price_lteq]': currentParams.get('q[suggested_total_price_lteq]') || '',// 売主希望総額 to
      'q[current_prospective_yield_gteq]': currentParams.get('q[current_prospective_yield_gteq]') || '',// 現況表面利回り from
      'q[current_prospective_yield_lteq]': currentParams.get('q[current_prospective_yield_lteq]') || '',// 現況表面利回り to
      'q[area_m3_gteq]': currentParams.get('q[property_area_m3_gteq]') || '', // 敷地面積from
      'q[area_m3_lteq]': currentParams.get('q[property_area_m3_lteq]') || '', // 敷地面積to
      'q[occupied_area_gteq]': currentParams.get('q[property_occupied_area_gteq]') || '', // 建物面積from
      'q[occupied_area_lteq]': currentParams.get('q[property_occupied_area_lteq]') || '', // 建物面積to
      'q[business_type_name_eq]': currentParams.get('q[property_business_type_eq]') || '', // 事業形態
      'q[proposals_by_freeword]': currentParams.get('q[by_freeword]') || '', // フリーワード(入手情報)
      'q[property_by_freeword_from_proerties]':
        currentParams.get('q[property_by_freeword_from_proerties]') || '', // フリーワード(物件検索)
      'q[source_cont]': currentParams.get('q[source_cont]') || '', // 入手先
      searchAddressList: currentParams.get('searchAddressList') || '',
      'q[source_user_id_in]': JSON.stringify(
        currentParams.getAll('q[source_user_id_in][]').filter((v) => v !== '')
      ),
      'q[property_user_id_in]': JSON.stringify(
        currentParams.getAll('q[property_user_id_in][]').filter((v) => v !== '')
      ),
      'q[source_user_department_name_in]': JSON.stringify(
        currentParams.getAll('q[source_user_department_name_in][]').filter((v) => v !== '')
      ),
      'q[property_user_department_name_in]': JSON.stringify(
        currentParams.getAll('q[property_user_department_name_in][]').filter((v) => v !== '')
      ),
      'q[ongoing_in]': JSON.stringify(currentParams.getAll('q[ongoing_in][]') || []),
      'q[property_tag_name_in]': JSON.stringify(
        processParamValue(currentParams.getAll('q[property_tag_name_in][]'))
      ),
      'q[property_property_type_in]': JSON.stringify(
        currentParams.getAll('q[property_property_type_in][]') || []
      ),
      'q[property_business_type_name_in]': JSON.stringify(
        processParamValue(currentParams.getAll('q[property_business_type_name_in][]'))
      ),
    }

    return true
  }
}
