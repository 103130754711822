import * as React from 'react';
import jwtDecode from 'jwt-decode';
import { OCRAuthError, OCRAuthErrorList } from '@/components/Ocr/auth/auth';

export async function fetchToken(setToken?: React.Dispatch<React.SetStateAction<string>> , setError?: React.Dispatch<React.SetStateAction<OCRAuthError>>, csrfToken?: string ) : Promise<string> {
  const response = await fetch('/api/auth/token', {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'X-CSRF-Token': csrfToken
    }
  })
  // StatusCode!=200~299
  if (!response.ok) {
    setError(OCRAuthErrorList.OCRTokenFetchError)
  }
  const token = response.headers.get('Authorization')
  if (setToken) {
    setToken(token)
  }
  return token
}

export async function OCRLogout() {
  const csrfToken = document.head.querySelector('meta[name="csrf-token"]').textContent
  try {
    const token = await fetchToken()
    await revokeToken(token, csrfToken)
  } catch (err) {
    console.error(err)
  }
}
async function revokeToken(token: string, csrfToken?: string ) {
  const response = await fetch('/users/sign_out', {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': token,
      'X-CSRF-Token': csrfToken,
    }
  })
  if (!response.ok) {
    throw OCRAuthErrorList.LogoutError
  }
}

export function shouldRefreshToken(token: string) : boolean {
  const decodedToken: object = jwtDecode(token)
  const unixtime = decodedToken["exp"]
  const expiresAt = unixtime*1000
  const now = Date.now() as number

  // ttl切れなので token refresh させる
  // ここでのチェックから実際のサーバー検証までのタイムラグを考慮して多少時間的に余裕を持たせておく
  const bufferSecond = 5
  if (now > expiresAt-bufferSecond*1000) {
    return true
  }
  // token is still available
  return false
}