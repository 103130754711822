import * as React from 'react'
import InputForm from './InputForm'
import { formatLatLngs } from './formatLatLngs'

type Props = {
  property: any
  propertyShape: any
  onChangeParameters?: () => void
  ref_volume_check_parameters?: any
  checkboxValues: any
  onCheckboxChange?: (name: string, newValue: boolean) => void
  inverseShadow: string
  onInverseShadowChange: (newValue: string) => void
  env_debug?: boolean
}

type LatLng = number[]

type DefaultSettings = {
  floor_height: string
  floors_above_ground: string
  floors_under_ground: string
  max_height: string
  gf_slab_level: string
  design_ground_level: string
  balcony_width: string
}

const ExtendsForm: React.FC<Props> = ({
  property,
  propertyShape,
  onChangeParameters,
  ref_volume_check_parameters,
  checkboxValues,
  onCheckboxChange,
  inverseShadow,
  onInverseShadowChange,
  env_debug,
}) => {
  let latlngs: LatLng[]

  const [useRefVolumeParams, setUseRefVolumeParams] = React.useState(true)

  const refBuildingSetting = ref_volume_check_parameters
    ? JSON.parse(ref_volume_check_parameters).building_setting
    : null

  const ref_floor_height = refBuildingSetting?.floor_height || null
  const ref_floors_above_ground = refBuildingSetting?.floors_above_ground || null
  const ref_floors_under_ground = refBuildingSetting?.floors_under_ground || null
  const ref_max_height = refBuildingSetting?.max_height || null
  const ref_gf_slab_level = refBuildingSetting?.gf_slab_level || null
  const ref_design_ground_level = refBuildingSetting?.design_ground_level || null
  const ref_balcony_width = refBuildingSetting?.balcony_width || null
  const ref_dwelling_unit_frontage = refBuildingSetting?.dwelling_unit_frontage || null
  const ref_hallway_width = refBuildingSetting?.hallway_width || null
  const ref_entrance_area = refBuildingSetting?.entrance_area || null
  const ref_min_offset_neighbor = refBuildingSetting?.min_offset_neighbor || null
  const ref_min_offset_road = refBuildingSetting?.min_offset_road || null
  const ref_number_of_units = refBuildingSetting?.number_of_units || null
  const ref_elevator_number = refBuildingSetting?.elevator_number ?? null
  const ref_admin_room = refBuildingSetting?.admin_room ?? null
  const ref_waste_storage = refBuildingSetting?.waste_storage ?? null
  const ref_guideline = refBuildingSetting?.guideline ?? null
  const ref_sky_factor = refBuildingSetting?.sky_factor ?? null
  const ref_district_planning = refBuildingSetting?.district_planning ?? null
  const ref_city_planning_road = refBuildingSetting?.city_planning_road ?? null
  const ref_unit_area = refBuildingSetting?.unit_area || '25'

  if (propertyShape) {
    const latlngsFromPropertyShape = JSON.parse(propertyShape)
    latlngs = formatLatLngs(latlngsFromPropertyShape, 'ExtendsForm')
  } else {
    const parameters = ref_volume_check_parameters ? JSON.parse(ref_volume_check_parameters) : null

    if (parameters && parameters.borders) {
      latlngs = parameters.borders
        .sort((a, b) => a.id - b.id)
        .map((border) => [border.to.lat, border.to.lng])
    } else {
      latlngs = (property?.shape?.latlngs ?? [])
        .sort((a, b) => a.no - b.no)
        .map((latlng) => [latlng.lat, latlng.lng])
    }
  }

  const [buildingSettings, setBuildingSettings] = React.useState({
    floor_height: '',
    floors_above_ground: '',
    floors_under_ground: '',
    max_height: '',
    gf_slab_level: '',
    design_ground_level: '',
    balcony_width: '',
    setting_building_area_ratio: '0',
  })

  const [extendsSettings, setExtendsSettings] = React.useState({
    dwelling_unit_frontage: ref_dwelling_unit_frontage
      ? ref_dwelling_unit_frontage
      : ref_unit_area
      ? `${(3 / 25) * ref_unit_area}`
      : '3',
    hallway_width: ref_hallway_width ? ref_hallway_width : '1.2',
    entrance_area: ref_entrance_area ? ref_entrance_area : '',
    min_offset_neighbor: ref_min_offset_neighbor ? ref_min_offset_neighbor : '0.6',
    min_offset_road: ref_min_offset_road ? ref_min_offset_road : '0.6',
    number_of_units: ref_number_of_units ? ref_number_of_units : '20',
    elevator_number: ref_elevator_number ? `${ref_elevator_number}` : '1',
    admin_room: ref_admin_room !== null && ref_admin_room !== undefined ? `${ref_admin_room}` : '1',
    waste_storage:
      ref_waste_storage !== null && ref_waste_storage !== undefined ? `${ref_waste_storage}` : '1',
    guideline: ref_guideline !== null && ref_guideline !== undefined ? `${ref_guideline}` : '1',
    sky_factor: ref_sky_factor !== null && ref_sky_factor !== undefined ? `${ref_sky_factor}` : '1',
    district_planning:
      ref_district_planning !== null && ref_district_planning !== undefined
        ? `${ref_district_planning}`
        : '1',
    city_planning_road:
      ref_city_planning_road !== null && ref_city_planning_road !== undefined
        ? `${ref_city_planning_road}`
        : '1',
  })

  let defaultSettings: DefaultSettings
  React.useEffect(() => {
    if (checkboxValues.compactResidence && checkboxValues.shadeRegulation) {
      defaultSettings = {
        floor_height: '2.62',
        floors_above_ground: '4',
        floors_under_ground: '0',
        max_height: '9.98',
        gf_slab_level: '-0.5',
        design_ground_level: '0',
        balcony_width: '0',
      }
    } else if (checkboxValues.compactResidence) {
      defaultSettings = {
        floor_height: '2.58',
        floors_above_ground: '5',
        floors_under_ground: '0',
        max_height: '13.00',
        gf_slab_level: '0.1',
        design_ground_level: '0',
        balcony_width: '0',
      }
    } else if (checkboxValues.shadeRegulation) {
      defaultSettings = {
        floor_height: '2.62',
        floors_above_ground: '4',
        floors_under_ground: '0',
        max_height: '9.98',
        gf_slab_level: '-0.5',
        design_ground_level: '0',
        balcony_width: '1.1',
      }
    } else {
      defaultSettings = {
        floor_height: '3',
        floors_above_ground: '15',
        floors_under_ground: '0',
        max_height: '50',
        gf_slab_level: '0',
        design_ground_level: '0',
        balcony_width: '1.1',
      }
    }

    setBuildingSettings({
      floor_height:
        useRefVolumeParams && ref_floor_height ? ref_floor_height : defaultSettings.floor_height,
      floors_above_ground:
        useRefVolumeParams && ref_floors_above_ground
          ? ref_floors_above_ground
          : defaultSettings.floors_above_ground,
      floors_under_ground:
        useRefVolumeParams && ref_floors_under_ground
          ? ref_floors_under_ground
          : defaultSettings.floors_under_ground,
      max_height:
        useRefVolumeParams && ref_max_height ? ref_max_height : defaultSettings.max_height,
      gf_slab_level:
        useRefVolumeParams && ref_gf_slab_level ? ref_gf_slab_level : defaultSettings.gf_slab_level,
      design_ground_level:
        useRefVolumeParams && ref_design_ground_level
          ? ref_design_ground_level
          : defaultSettings.design_ground_level,
      balcony_width:
        useRefVolumeParams && ref_balcony_width ? ref_balcony_width : defaultSettings.balcony_width,
      setting_building_area_ratio: '0',
    })
  }, [checkboxValues, useRefVolumeParams, ref_volume_check_parameters])

  const editableFields = {
    compactResidence: ['max_height'],
    shadeRegulation: ['balcony_width'],
    common: ['floor_height', 'gf_slab_level'],
  }

  const isEditable = (field) => {
    if (!checkboxValues.compactResidence && !checkboxValues.shadeRegulation) return true

    if (checkboxValues.compactResidence && checkboxValues.shadeRegulation) {
      return editableFields.common.includes(field)
    }

    if (checkboxValues.shadeRegulation && !checkboxValues.compactResidence) {
      return editableFields.shadeRegulation.includes(field) || editableFields.common.includes(field)
    }

    if (checkboxValues.compactResidence && !checkboxValues.shadeRegulation) {
      return (
        editableFields.compactResidence.includes(field) || editableFields.common.includes(field)
      )
    }

    return false
  }

  const handleOffsetNumber = (num: string) => {
    num = num.replace(/[^\d.]/g, '')
    if (Number(num) > 6) {
      num = "6"
    }
    if (num.length > 0 && Number(num) > 0 && Number(num) < 0.3) {
      num = "0.3"
    }
    return num
  }

  const handleOffsetZero = (num: string) => {
    if (Number(num) == 0) {
      num = "0.3"
    }
    return num
  }

  return (
    <div>
      <div className="mt-2">
        <input
          name="compact_residence"
          type="checkbox"
          checked={checkboxValues.compactResidence}
          onChange={(e) => {
            onCheckboxChange('compactResidence', e.target.checked)
            setUseRefVolumeParams(false)
            onChangeParameters()
          }}
        />
        <span className="text-sm ml-2">コンパクトレジデンス</span>
        <br />
        <input
          name="shade_regulation"
          type="checkbox"
          checked={checkboxValues.shadeRegulation}
          onChange={(e) => {
            onCheckboxChange('shadeRegulation', e.target.checked)
            setUseRefVolumeParams(false)
            onChangeParameters()
          }}
        />
        <span className="text-sm ml-2">
          日影規制対象外
          <br />
          　※低層住居専用地域を除く
        </span>
        {env_debug && (
          <>
            <hr className="my-2" />
            <div>
              <input
                type="radio"
                id="inverse_shadow_true"
                name="inverse_shadow"
                value="0"
                checked={inverseShadow === '0'}
                onChange={(e) => {
                  onInverseShadowChange(e.target.value)
                  onChangeParameters()
                }}
              />
              <label htmlFor="inverse_shadow_true"> 高層</label>
              &nbsp;&nbsp;&nbsp;&nbsp;
              <input
                type="radio"
                id="inverse_shadow_false"
                name="inverse_shadow"
                value="1"
                checked={inverseShadow === '1'}
                onChange={(e) => {
                  onInverseShadowChange(e.target.value)
                  onChangeParameters()
                }}
              />
              <label htmlFor="inverse_shadow_false"> 低層</label>
              &nbsp;&nbsp;&nbsp;&nbsp;
              <input
                type="radio"
                id="inverse_shadow_both"
                name="inverse_shadow"
                value="2"
                checked={inverseShadow === '2'}
                onChange={(e) => {
                  onInverseShadowChange(e.target.value)
                  onChangeParameters()
                }}
              />
              <label htmlFor="inverse_shadow_both"> 両方</label>
            </div>
            <hr className="my-2" />
            <InputForm
              name="setting_building_area_ratio"
              title="設定建ぺい率（検証用）"
              unit="%"
              value={buildingSettings.setting_building_area_ratio}
              placeholder="例 -10, -20, -40"
              onChange={(e) => {
                const value = e.target.value
                if (/^[-,\d]*$/.test(value)) {
                  console.log('=== in if ===')
                  setBuildingSettings({ ...buildingSettings, setting_building_area_ratio: value }),
                    onChangeParameters()
                }
              }}
            />
          </>
        )}
      </div>
      <hr className="my-4" />
      <input type="hidden" id="count_borders" name="count_borders" value={latlngs.length} />
      <div>
        <InputForm
          name="floors_above_ground"
          title="階数(地上)"
          unit="階"
          value={buildingSettings.floors_above_ground}
          readOnly={!isEditable('floors_above_ground')}
          onChange={(e) => {
            const value = e.target.value
            if (/^\d*$/.test(value)) {
              setBuildingSettings({ ...buildingSettings, floors_above_ground: value }),
                onChangeParameters()
            }
          }}
        />
        <InputForm
          name="floors_under_ground"
          title="階数(地下)"
          unit="階"
          value={buildingSettings.floors_under_ground}
          readOnly={!isEditable('floors_under_ground')}
          onChange={(e) => {
            const value = e.target.value
            if (/^\d*$/.test(value)) {
              setBuildingSettings({ ...buildingSettings, floors_under_ground: value })
              onChangeParameters()
            }
          }}
        />
        <InputForm
          name="max_height"
          title="建物の最大高さ"
          unit="m"
          value={buildingSettings.max_height}
          readOnly={!isEditable('max_height')}
          onChange={(e) => {
            setBuildingSettings({ ...buildingSettings, max_height: e.target.value })
            onChangeParameters()
          }}
        />
        <InputForm
          name="max_floor_height"
          title="階高"
          unit="m"
          value={buildingSettings.floor_height}
          readOnly={!isEditable('floor_height')}
          onChange={(e) => {
            setBuildingSettings({ ...buildingSettings, floor_height: e.target.value })
            onChangeParameters()
          }}
        />
        <InputForm
          name="gf_slab_level"
          title="1F Slab Level"
          unit="m"
          value={buildingSettings.gf_slab_level}
          readOnly={!isEditable('gf_slab_level')}
          onChange={(e) => {
            setBuildingSettings({ ...buildingSettings, gf_slab_level: e.target.value })
            onChangeParameters()
          }}
        />
        <InputForm
          name="design_ground_level"
          title="設計Ground Level"
          unit="m"
          value={buildingSettings.design_ground_level}
          readOnly={!isEditable('design_ground_level')}
          onChange={(e) => {
            setBuildingSettings({ ...buildingSettings, design_ground_level: e.target.value })
            onChangeParameters()
          }}
        />
        <InputForm
          name="balcony_width"
          title="バルコニー奥行"
          unit="m"
          value={buildingSettings.balcony_width}
          readOnly={!isEditable('balcony_width')}
          onChange={(e) => {
            setBuildingSettings({ ...buildingSettings, balcony_width: e.target.value })
            onChangeParameters()
          }}
        />
        <InputForm
          name="dwelling_unit_frontage"
          title="希望住戸面積間口"
          unit="㎡"
          value={extendsSettings.dwelling_unit_frontage}
          onChange={(e) => {
            setExtendsSettings({ ...extendsSettings, dwelling_unit_frontage: e.target.value })
            onChangeParameters()
          }}
        />
        <InputForm
          name="hallway_width"
          title="廊下幅員"
          unit="m"
          value={extendsSettings.hallway_width}
          onChange={(e) => {
            setExtendsSettings({ ...extendsSettings, hallway_width: e.target.value })
            onChangeParameters()
          }}
        />
        <InputForm
          name="entrance_area"
          title="エントランス"
          unit="戸"
          value={extendsSettings.entrance_area}
          onChange={(e) => {
            const value = e.target.value
            if (/^\d*$/.test(value)) {
              setExtendsSettings({ ...extendsSettings, entrance_area: value })
              onChangeParameters()
            }
          }}
        />
        <InputForm
          name="min_offset_neighbor"
          title="隣地境界線からの最小離隔距離"
          unit="m"
          value={extendsSettings.min_offset_neighbor}
          onChange={(e) => {
            setExtendsSettings({ ...extendsSettings, min_offset_neighbor: handleOffsetNumber(e.target.value) })
            onChangeParameters()
          }}
          onBlur={(e) => {
            setExtendsSettings({ ...extendsSettings, min_offset_neighbor: handleOffsetZero(e.target.value) })
            onChangeParameters()
          }}
        />
        <InputForm
          name="min_offset_road"
          title="道路境界線からの最小離隔距離"
          unit="m"
          value={extendsSettings.min_offset_road}
          onChange={(e) => {
            setExtendsSettings({ ...extendsSettings, min_offset_road: handleOffsetNumber(e.target.value) })
            onChangeParameters()
          }}
          onBlur={(e) => {
            setExtendsSettings({ ...extendsSettings, min_offset_road: handleOffsetZero(e.target.value) })
            onChangeParameters()
          }}
        />
        <InputForm
          name="number_of_units"
          title="住戸／階"
          unit="戸"
          value={extendsSettings.number_of_units}
          onChange={(e) => {
            const value = e.target.value
            if (/^\d*$/.test(value)) {
              setExtendsSettings({ ...extendsSettings, number_of_units: value })
              onChangeParameters()
            }
          }}
        />
        {/* 以下checkbox */}
        <div className="my-2 flex justify-between items-center gap-4">
          <div className="w-1/3 flex justify-end">
            <input
              name="elevator_number"
              id="elevator_number"
              type="checkbox"
              checked={extendsSettings.elevator_number === '1'}
              onChange={(e) => {
                setExtendsSettings({
                  ...extendsSettings,
                  elevator_number: e.target.checked ? '1' : '0',
                })
                onChangeParameters()
              }}
            />
          </div>
          <div className="text-xs relative flex items-strech w-2/3">
            <span className="flex flex-auto py-2 px-4 text-sm leading-normal w-full border rounded-sm border-[#eee]">
              EV台数
            </span>
          </div>
        </div>
        <div className="my-2 flex justify-between items-center gap-4">
          <div className="w-1/3 flex justify-end">
            <input
              name="admin_room"
              id="admin_room"
              type="checkbox"
              checked={extendsSettings.admin_room === '1'}
              onChange={(e) => {
                setExtendsSettings({ ...extendsSettings, admin_room: e.target.checked ? '1' : '0' })
                onChangeParameters()
              }}
            />
          </div>
          <div className="text-xs relative flex items-strech w-2/3">
            <span className="flex flex-auto py-2 px-4 text-sm leading-normal w-full border rounded-sm border-[#eee]">
              管理人室設置
            </span>
          </div>
        </div>
        <div className="my-2 flex justify-between items-center gap-4">
          <div className="w-1/3 flex justify-end">
            <input
              name="waste_storage"
              id="waste_storage"
              type="checkbox"
              checked={extendsSettings.waste_storage === '1'}
              onChange={(e) => {
                setExtendsSettings({
                  ...extendsSettings,
                  waste_storage: e.target.checked ? '1' : '0',
                })
                onChangeParameters()
              }}
            />
          </div>
          <div className="text-xs relative flex items-strech w-2/3">
            <span className="flex flex-auto py-2 px-4 text-sm leading-normal w-full border rounded-sm border-[#eee]">
              ゴミ置場設置
            </span>
          </div>
        </div>
        <div className="my-2 flex justify-between items-center gap-4">
          <div className="w-1/3 flex justify-end">
            <input
              name="guideline"
              id="guideline"
              type="checkbox"
              checked={extendsSettings.guideline === '1'}
              onChange={(e) => {
                setExtendsSettings({ ...extendsSettings, guideline: e.target.checked ? '1' : '0' })
                onChangeParameters()
              }}
            />
          </div>
          <div className="text-xs relative flex items-strech w-2/3">
            <span className="flex flex-auto py-2 px-4 text-sm leading-normal w-full border rounded-sm border-[#eee]">
              指導要綱適用
            </span>
          </div>
        </div>
        <div className="my-2 flex justify-between items-center gap-4">
          <div className="w-1/3 flex justify-end">
            <input
              name="sky_factor"
              id="sky_factor"
              type="checkbox"
              checked={extendsSettings.sky_factor === '1'}
              onChange={(e) => {
                setExtendsSettings({ ...extendsSettings, sky_factor: e.target.checked ? '1' : '0' })
                onChangeParameters()
              }}
            />
          </div>
          <div className="text-xs relative flex items-strech w-2/3">
            <span className="flex flex-auto py-2 px-4 text-sm leading-normal w-full border rounded-sm border-[#eee]">
              天空率適用
            </span>
          </div>
        </div>
        <div className="my-2 flex justify-between items-center gap-4">
          <div className="w-1/3 flex justify-end">
            <input
              name="district_planning"
              id="district_planning"
              type="checkbox"
              checked={extendsSettings.district_planning === '1'}
              onChange={(e) => {
                setExtendsSettings({
                  ...extendsSettings,
                  district_planning: e.target.checked ? '1' : '0',
                })
                onChangeParameters()
              }}
            />
          </div>
          <div className="text-xs relative flex items-strech w-2/3">
            <span className="flex flex-auto py-2 px-4 text-sm leading-normal w-full border rounded-sm border-[#eee]">
              地区計画適用
            </span>
          </div>
        </div>
        <div className="my-2 flex justify-between items-center gap-4">
          <div className="w-1/3 flex justify-end">
            <input
              name="city_planning_road"
              id="city_planning_road"
              type="checkbox"
              checked={extendsSettings.city_planning_road === '1'}
              onChange={(e) => {
                setExtendsSettings({
                  ...extendsSettings,
                  city_planning_road: e.target.checked ? '1' : '0',
                })
                onChangeParameters()
              }}
            />
          </div>
          <div className="text-xs relative flex items-strech w-2/3">
            <span className="flex flex-auto py-2 px-4 text-sm leading-normal w-full border rounded-sm border-[#eee]">
              都市計画道路適用
            </span>
          </div>
        </div>
        <h5 className="mt-6 mb-2 text-lg font-medium">道路、隣地の高さ</h5>
        {latlngs.map((latlng, i) => (
          <InputForm
            key={i}
            name={`height_${i}`}
            title={`点${i + 1}`}
            unit="m"
            onChange={onChangeParameters}
          />
        ))}
      </div>
    </div>
  )
}

export default ExtendsForm
