import * as React from 'react'

import {
  LatDefault,
  LngDefault,
} from '@/models/property'
import type { Property } from '@/types/property'

import { MapHandles } from '@/components/GoogleMapViewer'
import { GoogleMapViewer } from '@/components/GoogleMapViewer/PropertyPublicFiles'
import type { LatLng } from '@/types/property'
import { useJsApiLoader } from '@react-google-maps/api'


type Props = {
  google_maps_api_key: string
  property: Property
  error_messages: string[]
  style?: React.CSSProperties
}

const MapPage: React.FC<Props> = ({
  google_maps_api_key,
  property,
  style,
  error_messages,
}) => {
  const csrfToken: HTMLMetaElement = document.head.querySelector('meta[name="csrf-token"]')

  const mapRef = React.useRef<MapHandles>(null)
  const latLng: LatLng = {
    lat: property?.address_geocoding[0] || LatDefault,
    lng: property?.address_geocoding[1] || LngDefault,
  }
  const dataLatLng: LatLng = {
    lat: property?.address_geocoding[0],
    lng: property?.address_geocoding[1],
  }

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: google_maps_api_key,
    libraries: ['geometry', 'drawing', 'places'],
  })
  const propertyShape = property?.shape?.latlngs.map(latlng => ({ lat: latlng.lat, lng: latlng.lng }))

  return (
    <>
      {isLoaded ? (
        <GoogleMapViewer
          csrfToken={csrfToken.content}
          property_id={Number(property?.id)}
          lat={latLng.lat}
          lng={latLng.lng}
          propertyLat={dataLatLng.lat}
          propertyLng={dataLatLng.lng}
          propertyShape={propertyShape}
          ref={mapRef}
          style={style || {
            width: '100%',
            height: '100%',
          }}
        />
      ) : <></>}
    </>
  )
}

export default MapPage
