import * as React from 'react'
import { textLinkMap } from '@/config/langs/breadcrumb'

type Props = {
  link_texts?: string[]
  property_id?: string
  tab?: string
}

export const Breadcrumb: React.FC<Props> = ({ link_texts, property_id = null, tab = null }) => {
  function linkSet(text: string, index: string | number) {
    if (index === link_texts?.length - 1) {
      return (
        <li className="inline-block" key={index}>
          <span className="font-medium text-gray-300">{text}</span>
        </li>
      )
    } else if (['入手情報', '物件詳細', 'Volume Check', '帳票作成', '補正設定', '公開ページ'].includes(text)) {
      return (
        <li className="inline-block" key={index}>
          <a
            className="font-medium text-primary-font"
            href={`${textLinkMap[text]}/${property_id}?tab=${tab}`}
          >
            {text}
          </a>
          <span className="mx-1.5">/</span>
        </li>
      )
    } else {
      return (
        <li className="inline-block" key={index}>
          <a className="font-medium text-primary-font" href={textLinkMap[text]}>
            {text}
          </a>
          <span className="mx-1.5">/</span>
        </li>
      )
    }
  }

  return (
    <div className="w-full border-t border-t-gray-200 bg-gray-light pl-6 p-2 md:pl-10 md:p-3">
      <ul className="text-sm select-none">
        <li className="inline-block">
          <a className="font-medium text-primary-font" href="/">
            HOME
          </a>
          <span className="mx-1.5">/</span>
        </li>
        {link_texts?.map((text, index) => linkSet(text, index))}
      </ul>
    </div>
  )
}
