import * as React from 'react';
import { fetchToken, shouldRefreshToken } from '@/components/Ocr/auth/token';

type OCRAuthErrorKeys =
  | "OCRTokenFetchError"
  | "OCRTokenTTLError"
  | "LogoutError"
export interface OCRAuthError extends Error {
  name: OCRAuthErrorKeys;
}

export const OCRAuthErrorList: {[key in OCRAuthErrorKeys]: OCRAuthError} = {
  OCRTokenFetchError: {name: "OCRTokenFetchError", message: "認証に失敗しました"},
  OCRTokenTTLError: {name: "OCRTokenTTLError", message: "JWTTokenのTTLが切れました"},
  LogoutError: {name: "LogoutError", message: "ログアウト処理リクエストが失敗しました"}
}
export const useAuth = ({csrfToken}) => {
  const [ token, setToken ] = React.useState<string>('');
  const [ error, setError ] = React.useState<OCRAuthError>(undefined);
  // tokenが存在しない or 存在するがttl切れの場合は tokenを取得させる
  React.useEffect(()=>{
    if (!token ||
      (token && shouldRefreshToken(token))) {
      fetchToken(setToken, setError, csrfToken )
    };
  })
  return {token, error}
}

export const OCRAuth: React.FC<{children: (token: string) => React.ReactNode, csrfToken: string} > = (props) => {
  const [ token, setToken ] = React.useState<string>('');
  const [ error, setError ] = React.useState<OCRAuthError>(undefined);

  // tokenが存在しない or 存在するがttl切れの場合は tokenを取得させる
  if (!token ||
    (token && shouldRefreshToken(token))) {
    fetchToken(setToken, setError, props.csrfToken )
  };
  return (
    <>
      {error
        ? <div>{error.message}</div>
        : props.children(token)
      }
    </>
  )
}