import { Breadcrumb } from '@/components/Breadcrumb'
import { AnchorButton } from '@/components/Button'
import Base from '@/components/Layout/Base'
import ProjectsModal from '@/components/Page/PorjectsModal/ProjectsModal'
import { Pagination } from '@/components/Pagination'
import TagItem from '@/components/ProjectsTag'
import { AddMemoModal } from '@/components/Page/PorjectsModal/AddMemoModal'
import { Table } from '@/components/Table'
import { Pagy } from '@/types/pagy'
import { User } from '@/types/user'
import type { Property, Memo } from '@/types/property'
import { sortOptionsDeffault } from '@/models/project'
import { formatSortOptions } from '@/utils/fetchSortOptions'
import { ellipsisString } from '@/utils/ellipsisString'
import CloudDownloadIcon from '@material-ui/icons/CloudDownload'
import BookmarkAddIcon from '@mui/icons-material/BookmarkAdd'
import EditIcon from '@mui/icons-material/Edit'
import { format, parseISO } from 'date-fns'
import * as React from 'react'

type Props = {
  current_user: User
  table_columns: [][]
  projects: [{ p_id: string; path: string; contains: string[][]; new_memo: boolean; memos: Memo[]; property: Property }]
  business_type_options: { value: string; text: string }[]
  tag_options: { value: string; text: string }[]
  source_department_options: { value: string; label: string }[]
  department_options: { value: string; label: string }[]
  user_options: { value: string; label: string }[]
  prefecture_options: { value: string; label: string }[]
  pagy: Pagy
}

const ProjectsTotalsIndexPage: React.FC<Props> = ({
  current_user,
  table_columns,
  projects,
  business_type_options,
  tag_options,
  source_department_options,
  department_options,
  user_options,
  prefecture_options,
  pagy,
}) => {
  const convertSortOptions = formatSortOptions(table_columns, sortOptionsDeffault)
  const title = '一括表示'
  const searchParams = new URLSearchParams(window.location.search)
  const pdfQuery = searchParams.toString() !== '' ? `&${searchParams.toString()}` : ''
  const tagIndex = table_columns.flat().indexOf('タグ')
  const memoIndex = table_columns.flatMap((h) => Array.isArray(h) ? h.join("") : h).indexOf('進捗')

  const [isOpen, setIsOpen] = React.useState(false)
  const onClose = () => {
    setIsOpen(false)
  }
  const [projectId, setProjectId] = React.useState(null)
  const [memos, setMemos] = React.useState({})
  const setListMemos = (newMemos: Memo[]) => {
    const obj = {[projectId]: newMemos}
    setMemos({...memos, ...obj})
  }
  const [property, setProperty] = React.useState<Property>()

  React.useEffect(() => {
    setMemos(projects.reduce((accumulator, value, index) => { return {...accumulator, [value.p_id]: value.memos} }, {}))
  }, [projects])

  return (
    <Base
      current_user={current_user}
      title={title}
      breadcrumb={<Breadcrumb link_texts={['一括表示']} />}
      submenuCategory="projects"
    >
      <div className="flex flex-wrap items-end justify-between m-1">
        <div className="flex flex-wrap items-center gap-1 whitespace-nowrap">
          <span className="text-primary-font text-xl font-medium">
            {pagy.count.toLocaleString()}
          </span>
          <span className="text-gray-800 text-sm mt-0.5">
            件中 {pagy.from}～{pagy.to}件 表示
          </span>
          <span className="ml-2">
            <Pagination pagy={pagy} />
          </span>
        </div>

        <div className="flex ml-auto gap-1 pt-1 md:gap-2 md:pt-0">
          <ProjectsModal
            title="条件絞り込み"
            sub_tab="reset"
            department_options={department_options}
            source_department_options={source_department_options}
            user_options={user_options}
            tag_options={tag_options}
            business_type_options={business_type_options}
            prefecture_options={prefecture_options}
            sort_options={convertSortOptions}
          />

          <AnchorButton
            className="text-primary-font"
            prefix={<CloudDownloadIcon fontSize="small" />}
            outline
            size="small"
            variant="primary"
            href={`/projects.pdf?group_type=reset${pdfQuery}`}
          >
            <span className="hidden md:inline">PDFダウンロード</span>
          </AnchorButton>
        </div>
      </div>
      {projects[0] == undefined ? (
        <div className="text-center text-[#777] py-8">データがありません</div>
      ) : (
        <Table
          className="h-[calc(100vh_-_155px)] md:h-[calc(100vh_-_165px)]"
          topScrollbar={window.innerHeight > 920}
        >
          <thead className="bg-gray-150 text-sm sticky top-0 z-10">
            <tr className="whitespace-nowrap">
              {table_columns.map((row: string[], index) => {
                if (index === 1) {
                  return
                }
                return (
                  <th
                    key={index}
                    scope="col"
                    className={[
                      'py-2 px-4 font-medium text-center bg-gray-150',
                      index === 0 && 'md:sticky md:left-0',
                    ].join(' ')}
                  >
                    {index === 0 ? (
                      <div>
                        {table_columns[0]}
                        <span className="pl-2">{table_columns[1]}</span>
                      </div>
                    ) : (
                      row.map((col, index) => <div key={index}>{col}</div>)
                    )}
                  </th>
                )
              })}
            </tr>
          </thead>
          <tbody className="whitespace-nowrap">
            {projects.map((project, index) => (
              <tr
                key={index}
                className="bg-white border-b border-gray-100 hover:bg-targetLine cursor-pointer"
              >
                {project.contains.map((row, index) => {
                  if (index === 1) {
                    return
                  } else if (index === tagIndex) {
                    return (
                      <TagItem
                        tags={current_user.company?.tags || []}
                        currentTagName={row?.[0]}
                        path={project.path}
                      />
                    )
                  } else if ([memoIndex, memoIndex + 1].includes(index)) {
                    return (
                      <td
                        key={index}
                        className={'text-center'}
                        onClick={() => {
                          setIsOpen(true)
                          setProjectId(project.p_id)
                          setProperty(project.property)
                        }}>
                        <div className='flex w-full h-full py-2 px-4'>
                          <div
                            key={index}
                            className={index === memoIndex ? 'whitespace-pre-wrap break-all text-left mr-2 min-w-[400px]' : 'whitespace-pre text-left'}>
                              {index === memoIndex && memos[project.p_id]?.[0]?.content && ellipsisString(memos[project.p_id][0].content, 150)}
                              {(index === memoIndex + 1) && memos[project.p_id]?.[0] && format(parseISO(memos[project.p_id][0]?.updated_at), 'yyyy年MM月dd日')}
                          </div>
                          {index === memoIndex && (
                            <div className='ml-auto'>
                              <EditIcon fontSize="small" />
                            </div>
                          )}
                        </div>
                      </td>
                    )
                  }
                  return (
                    <td
                      key={index}
                      className={[
                        'text-center',
                        index === 0 &&
                          'md:sticky md:left-0 md:overflow-hidden md:overflow-ellipsis md:max-w-[280px] bg-white',
                      ].join(' ')}
                    >
                      <a
                        href={`${project.path}?tab=property_details`}
                        className="block w-full h-full py-2 px-4"
                      >
                        {index === 0 ? (
                          <div key={0} className={'whitespace-pre text-left'}>
                            {project.contains[0]}.{' '}
                            <span className="pl-2">{project.contains[1]}</span>
                          </div>
                        ) : (
                          row.map((col, idx) => (
                            <div key={idx} className={'whitespace-pre text-left'}>
                              {col != null && col.toString() !== '' ? col : '　'}
                              {index == 1 && project.new_memo && (
                                <span className="align-top inline-block text-[10px] text-targetLine-warning">
                                  <BookmarkAddIcon fontSize="small" />
                                  進捗UP
                                </span>
                              )}
                            </div>
                          ))
                        )}
                      </a>
                    </td>
                  )
                })}
              </tr>
            ))}
          </tbody>
        </Table>
      )}
      <AddMemoModal
        memos={memos[projectId]}
        property={property ?? projects?.[0].property}
        is_open={isOpen}
        on_close={onClose}
        set_list_memos={setListMemos}
      />
    </Base>
  )
}

export default ProjectsTotalsIndexPage
