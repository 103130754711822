const csrfToken: HTMLMetaElement = document.head.querySelector('meta[name="csrf-token"]')
const basePath = '/property_documents'

// 新規作成
export const createDocument = async (params: object) => {
  const response = await fetch(basePath, {
    method: 'POST',
    body: JSON.stringify(params),
    headers: {
      'Content-Type': 'application/json',
      'X-CSRF-Token': csrfToken.content,
    },
  })
  if (!response.ok) {
    return Promise.reject(Error(`${response.status}: ${response.statusText}`))
  } else {
    return response.json()
  }
}

// 更新
export const updateDocument = async (id: number, params: object) => {
  const response = await fetch(`${basePath}/${id}`, {
    method: 'PATCH',
    body: JSON.stringify(params),
    headers: {
      'Content-Type': 'application/json',
      'X-CSRF-Token': csrfToken.content,
    },
  })
  if (!response.ok) {
    return Promise.reject(Error(`${response.status}: ${response.statusText}`))
  } else {
    return response.json()
  }
}

// 削除
export const deleteDocument = async (id: number) => {
  const response = await fetch(`${basePath}/${id}`, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      'X-CSRF-Token': csrfToken.content,
    },
  })
  if (!response.ok) {
    return Promise.reject(Error(`${response.status}: ${response.statusText}`))
  } else {
    return response.json()
  }
}

// pdfダウンロード
export const downloadDocument = async (id: number) => {
  const response = await fetch(`${basePath}/${id}/pdf`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'X-CSRF-Token': csrfToken.content,
    },
  })
  if (!response.ok) {
    return Promise.reject(Error(`${response.status}: ${response.statusText}`))
  } else {
    return response.blob()
  }
}

// excelダウンロード
export const downloadExcel = async (id: number) => {
  const response = await fetch(`${basePath}/${id}/excel`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'X-CSRF-Token': csrfToken.content,
    },
  })
  if (!response.ok) {
    return Promise.reject(Error(`${response.status}: ${response.statusText}`))
  } else {
    return response.blob()
  }
}
