export function setupResetButton(map: google.maps.Map, onClick: () => void) {
  // リセットボタンを追加
  const resetButton = document.createElement('button')
  resetButton.textContent = 'リセット'
  resetButton.classList.add('custom-map-control-button')
  resetButton.setAttribute('type', 'button')
  resetButton.style.fontSize = '14px'
  resetButton.style.margin = '4px 8px'

  map.controls[google.maps.ControlPosition.RIGHT_TOP].push(resetButton)

  resetButton.addEventListener('click', onClick)
}