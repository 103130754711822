import * as React from 'react'
import Base from '@/components/Layout/Base'
import { Table } from '@/components/Table'
import type { User } from '@/types/user'
import { Select } from '@/components/Select'
import { Input } from '@/components/Input'
import { Button, AnchorButton } from '@/components/Button'
import { roleOptions } from '@/models/role'
import { roleType } from '@/config/langs/user'
import { Breadcrumb } from '@/components/Breadcrumb'
import { Checkbox } from '@/components/Checkbox'
import { ConfirmDialog } from '@/components/ConfirmDialog'
import { Modal } from '@/components/Modal'
import { RadioButton } from '@/components/RadioButton'

type Props = {
  current_user: User
  user: User
  company_options: { value: string; text: string }[]
  department_options: { value: string; text: string }[]
  company_department_options?: { [key: number]: { value: string; text: string } }[]
  authenticity_token: string
  error_messages: string[]
}

const UsersEditPage: React.FC<Props> = ({
  current_user,
  user,
  company_options,
  department_options,
  company_department_options,
  authenticity_token,
  error_messages,
}) => {
  const [company_id, setCompanyId] = React.useState(user.company_id ?? '')
  const [department_id, setDepartmentId] = React.useState(user.department_id ?? '')
  const [name, setName] = React.useState(user.name ?? '')
  const [nameKana, setNameKana] = React.useState(user.name_kana ?? '')
  const [email, setEmail] = React.useState(user.email ?? '')
  const [password, setPassword] = React.useState('')
  const [password_confirmation, setPasswordConfirmation] = React.useState('')
  const [role, setRole] = React.useState(user.role ?? '')
  const [selectedDepartmentOptions, setSelectedDepartmentOptions] =
    React.useState(department_options)
  const [locked, setLocked] = React.useState(!!user.locked_at)
  const [dialogOpen, setDialogOpen] = React.useState(false)
  const [modalOpen, setModalOpen] = React.useState(false)
  const [hideConfirmation, setHideConfirmation] = React.useState(false)
  const [isVolumeCheck, setIsVolumeCheck] = React.useState(user.is_volume_check ?? false)
  const [isAutoPing, setAutoPing] = React.useState(user.is_auto_ping ?? false)
  
  const title = user.id ? 'ユーザー編集' : 'ユーザー登録'
  const proxyUser = user.proxy_company_id
  
  const setRoleOptions = (current_user: User) => {
    if (current_user.company.pricing_type === 'owner' && current_user.role === 'system_admin') {
      return roleOptions
    } else {
      return roleOptions.filter((option) => option.value !== 'system_admin')
    }
  }


  const setDepartmentOptions = (company_id) => {
    const departmentOptions = [{ value: 0, text: '(なし)' }]

    if (company_department_options && company_department_options[company_id]) {
      departmentOptions.push(...company_department_options[company_id])
    }

    setSelectedDepartmentOptions(departmentOptions)
  }

  const onCloseDialog = (result: boolean) => {
    if (result) {
      fetch(`/users/${user.id}/send_confirmation`, {
        method: 'POST',
        headers: {
          'X-CSRF-Token': authenticity_token,
        },
      })
        .then((res) => res.json())
        .then((json) => {
          setDialogOpen(false)

          if (json.status === 'OK') {
            setHideConfirmation(true)
            setModalOpen(true)
          } else {
            alert(json.message)
          }
        })
        .catch((error) => {
          setDialogOpen(false)
          alert('エラーが発生しました')
        })
    } else {
      setDialogOpen(false)
    }
  }

  return (
    <Base
      current_user={current_user}
      title={title}
      breadcrumb={<Breadcrumb link_texts={['ユーザー設定', title]} />}
      submenuCategory="settings"
    >
      <form
        className="mt-8"
        action={user.id ? `/users/${user.id}` : '/users'}
        acceptCharset="UTF-8"
        method="post"
      >
        <input type="hidden" name="_method" value={user.id ? 'patch' : 'post'} />
        <input type="hidden" name="authenticity_token" value={authenticity_token} />

        {error_messages.length > 0 && (
          <ul className="mb-6 text-sm text-red">
            {error_messages.map((message, i) => (
              <li key={i} className="mb-2 break-all">
                {message}
              </li>
            ))}
          </ul>
        )}
        <Table className="block h-[calc(100vh_-_182px)] md:h-[calc(100vh_-_195px)]">
          <tbody className="table-row-group">
            <tr className="bg-gray-light table-row">
              <th
                scope="row"
                className="py-5 px-4 text-right whitespace-nowrap text-black-base font-medium w-1/4 table-cell"
              >
                所属会社
              </th>
              <td className="py-2 px-4 md:w-3/4 table-cell">
                {proxyUser ? (
                  <>
                    <p>{`${user.proxy_company.name}（代理）`}</p>
                    <input type="hidden" name="user[company_id]" value={user.company_id} />
                    <input
                      type="hidden"
                      name="user[proxy_company_id]"
                      value={user.proxy_company_id}
                    />
                  </>
                ) : user.id ? (
                  <p>{user.company.name}</p>
                ) : (
                  <Select
                    className="max-w-[246px]"
                    value={company_id}
                    name="user[company_id]"
                    id="user_company_id"
                    options={company_options}
                    onChange={(e) => {
                      setDepartmentOptions(Number(e.target.value))
                      setCompanyId(e.target.value)
                    }}
                  />
                )}
              </td>
            </tr>
            <tr className="bg-white table-row">
              <th
                scope="row"
                className="py-5 px-4 text-right whitespace-nowrap text-black-base font-medium w-1/4 table-cell"
              >
                部署
              </th>
              <td className="py-2 px-4 md:w-3/4 table-cell">
                <Select
                  className="max-w-[246px]"
                  value={department_id}
                  name="user[department_id]"
                  id="user_department_id"
                  options={selectedDepartmentOptions}
                  onChange={(e) => setDepartmentId(e.target.value)}
                />
              </td>
            </tr>
            <tr className="bg-gray-light table-row">
              <th
                scope="row"
                className="py-5 px-4 text-right whitespace-nowrap text-black-base font-medium w-1/4 table-cell"
              >
                氏名
              </th>
              <td className="py-2 px-4 md:w-3/4 table-cell">
                <Input
                  className="max-w-[246px]"
                  value={name}
                  name="user[name]"
                  id="user_name"
                  onChange={(e) => setName(e.target.value)}
                />
              </td>
            </tr>
            <tr className="bg-gray-light table-row">
              <th
                scope="row"
                className="py-5 px-4 text-right whitespace-nowrap text-black-base font-medium w-1/4 table-cell"
              >
                氏名（カナ）
              </th>
              <td className="py-2 px-4 md:w-3/4 table-cell">
                <Input
                  className="max-w-[246px]"
                  value={nameKana}
                  name="user[name_kana]"
                  id="user_name_kana"
                  onChange={(e) => setNameKana(e.target.value)}
                />
              </td>
            </tr>
            <tr className="bg-white table-row">
              <th
                scope="row"
                className="py-5 px-4 text-right whitespace-nowrap text-black-base font-medium w-1/4 table-cell"
              >
                メールアドレス
              </th>
              <td className="py-2 px-4 md:w-3/4 table-cell">
                {user.id ? (
                  <p>{user.email}</p>
                ) : (
                  <Input
                    className="max-w-[246px]"
                    value={email}
                    name="user[email]"
                    id="user_email"
                    onChange={(e) => setEmail(e.target.value)}
                  />
                )}
              </td>
            </tr>
            <tr className="bg-gray-light table-row">
              <th
                scope="row"
                className="py-5 px-4 text-right whitespace-nowrap text-black-base font-medium w-1/4 table-cell"
              >
                パスワード
              </th>
              <td className="py-2 px-4 md:w-3/4 table-cell">
                <Input
                  className="max-w-[246px]"
                  value={password}
                  name="user[password]"
                  id="user_password"
                  type="password"
                  onChange={(e) => setPassword(e.target.value)}
                />
              </td>
            </tr>
            <tr className="bg-white table-row">
              <th
                scope="row"
                className="py-5 px-4 text-right whitespace-nowrap text-black-base font-medium w-1/4 table-cell"
              >
                パスワード（確認用）
              </th>
              <td className="py-2 px-4 md:w-3/4 table-cell">
                <Input
                  className="max-w-[246px]"
                  value={password_confirmation}
                  name="user[password_confirmation]"
                  id="user_password_confirmation"
                  type="password"
                  onChange={(e) => setPasswordConfirmation(e.target.value)}
                />
              </td>
            </tr>
            <tr className="bg-gray-light table-row">
              <th
                scope="row"
                className="py-5 px-4 text-right whitespace-nowrap text-black-base font-medium w-1/4 table-cell"
              >
                権限
              </th>
              <td className="py-2 px-4 md:w-3/4 table-cell">
                {(user.proxy_company && user.role === 'admin') ||
                (current_user.company.id === user.company?.id && current_user.role === 'admin') ||
                current_user.role === 'system_admin' ||
                !user.id ? (
                  <Select
                    className="max-w-[246px]"
                    name="user[role]"
                    id="user_role"
                    value={role}
                    options={setRoleOptions(current_user)}
                    onChange={(e) => setRole(e.target.value)}
                  />
                ) : (
                  <div>{roleType[role]}</div>
                )}
              </td>
            </tr>
            <tr className="bg-white table-row">
              <th
                scope="row"
                className="py-5 px-4 text-right whitespace-nowrap text-black-base font-medium w-1/4 table-cell"
              >
                volume check
              </th>
              <td className="py-2 px-4 md:w-3/4 table-cell">
                {current_user.role === 'system_admin' || current_user.role === 'admin' ? (
                  <div className="flex gap-4">
                    <RadioButton
                      name="user[is_volume_check]"
                      id="user_is_volume_check_true"
                      text="あり"
                      value="true"
                      checked={isVolumeCheck === true}
                      onClick={() => setIsVolumeCheck(true)}
                    />
                    <RadioButton
                      name="user[is_volume_check]"
                      id="user_is_volume_check_false"
                      text="なし"
                      value="false"
                      checked={isVolumeCheck === false}
                      onClick={() => setIsVolumeCheck(false)}
                    />
                  </div>
                ) : (
                  <span className="py-2 px-4">{user.is_volume_check ? 'あり' : 'なし'}</span>
                )}
              </td>
            </tr>
            <tr className="bg-gray-light table-row">
              <th
                scope="row"
                className="py-5 px-4 text-right whitespace-nowrap text-black-base font-medium w-1/4 table-cell"
              >
                ピンの自動表示
              </th>
              <td className="py-2 px-4 md:w-3/4 table-cell">
                <div className="flex gap-4">
                <RadioButton
                  name="user[is_auto_ping]"
                  id="user_is_auto_ping_true"
                  text="あり"
                  value="true"
                  checked={isAutoPing === true}
                  onClick={() => {
                    if (window.confirm("ピンが対象物件を正しく示しているか、必ずご自身でご確認ください。")) {
                      setAutoPing(true);
                    }
                  }}
                  className={`${current_user.company.is_auto_ping ? "" : "hidden"}`}
                />
                  {current_user.company.is_auto_ping ? (
                    <RadioButton
                      name="user[is_auto_ping]"
                      id="user_is_auto_ping_false"
                      text="なし"
                      value="false"
                      checked={isAutoPing === false}
                      onClick={() => setAutoPing(false)}
                      className=""
                    />
                  ) : (
                    <span className="py-2 px-4">なし</span>
                  )}
                </div>
              </td>
            </tr>
            {(current_user.role === 'system_admin' || current_user.role === 'admin') &&
              current_user.id !== user.id && (
                <>
                  <tr className="bg-gray-light table-row">
                    <th
                      scope="row"
                      className="py-5 px-4 text-right whitespace-nowrap text-black-base font-medium w-1/4 table-cell"
                    >
                      アカウントロック
                    </th>
                    <td className="py-2 px-4 md:w-3/4 table-cell">
                      <Checkbox
                        name="locked_at"
                        text="ロックする"
                        value="1"
                        checked={locked}
                        onChange={(e) => {
                          setLocked(!locked)
                        }}
                      />
                    </td>
                  </tr>
                  {!hideConfirmation && !user.locked_at && user.confirmation_period_expired && (
                    <tr className="bg-gray-light table-row">
                      <th
                        scope="row"
                        className="py-5 px-4 text-right whitespace-nowrap text-black-base font-medium w-1/4 table-cell"
                      >
                        確認メールの再送
                      </th>
                      <td className="py-2 px-4 md:w-3/4 table-cell">
                        <Button
                          className="w-full md:w-40 md:h-auto"
                          onClick={(e) => {
                            e.preventDefault()
                            setDialogOpen(true)
                          }}
                        >
                          送信
                        </Button>
                      </td>
                    </tr>
                  )}
                </>
              )}
          </tbody>
        </Table>

        {/* 確認メール送信のダイアログ */}
        <ConfirmDialog open={dialogOpen} onClose={onCloseDialog}>
          <div className="text-black-base text-sm text-center">
            <p>確認メールを再送しますか？</p>
          </div>
        </ConfirmDialog>

        {/* 確認メール送信結果のモーダル */}
        <Modal title={'確認メール送信'} open={modalOpen}>
          <div className="px-6 py-6 leading-10">
            <p>確認メールを送信しました。</p>
          </div>
          <div className="border-t border-gray-150" />
          <div className="modal-footer">
            <div className="flex justify-end">
              <div className="px-6 py-3">
                <Button
                  className="text-primary-deep border-white w-[120px] h-[36px]"
                  outline
                  size="small"
                  variant="primary"
                  onClick={() => setModalOpen(false)}
                >
                  閉じる
                </Button>
              </div>
            </div>
          </div>
        </Modal>

        <div className="bg-white flex justify-center items-center w-full left-0 md:gap-4 border-t border-gray-250 pt-2">
          <AnchorButton
            className="w-full md:w-40 md:h-auto text-primary-font border-white"
            outline
            variant="text"
            href="/users"
          >
            キャンセル
          </AnchorButton>
          <Button className="w-full md:w-40 md:h-auto">{user.id ? '編集を保存' : '登録'}</Button>
        </div>
      </form>
    </Base>
  )
}

export default UsersEditPage
