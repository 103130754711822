import * as React from 'react'
import { Card } from './Card'
import type { PropertyDocumentFormat } from '@/types/propertyDocumentFormat'
import { Input } from '@/components/Input'
import { Button } from '@/components/Button'
import AddOutlinedIcon from '@material-ui/icons/AddOutlined'
import * as PropertyDocumentFormatAction from '@/actions/PropertyDocumentFormatAction'
import { redirect } from '@/utils/errors'
export interface ContainerState {
  cards: PropertyDocumentFormat[]
}

type Props = {
  items: PropertyDocumentFormat[]
}

export const Container: React.FC<Props> = ({ items }) => {
  {
    const sortPropertyDocumentFormats = [...items]
    sortPropertyDocumentFormats.sort((prev, next) => prev.order - next.order)

    const [newPropertyDocumentFormatName, setNewPropertyDocumentFormatName] = React.useState('')
    const [cards, setCards] = React.useState(sortPropertyDocumentFormats)

    const moveCard = React.useCallback(
      (dragIndex: number, hoverIndex: number) => {
        setCards((prevCards: PropertyDocumentFormat[]) => {
          const dragCard = prevCards[dragIndex]
          const newCards = [...prevCards]
          newCards.splice(dragIndex, 1)
          newCards.splice(hoverIndex, 0, dragCard)
          return newCards
        })
      },
      [setCards]
    )

    const dropCard = React.useCallback(() => {
      cards.forEach(async (propertyDocumentFormat, propertyDocumentFormatIndex) => {
        const params = {name: propertyDocumentFormat, order: propertyDocumentFormatIndex + 1}
        try {
          await PropertyDocumentFormatAction.updateFormat(propertyDocumentFormat.id, params)
        } catch(error) {
          redirect(error, '/property_document_formats/index_with_notice', '更新に失敗しました。')
        }
      })
    }, [cards])

    const createPropertyDocumentFormat = React.useCallback(
      async (propertyDocumentFormatName: string) => {
        if (!propertyDocumentFormatName) {
          alert('物件概要書フォーマット名を入力してください')
          return
        }
        try {
          // 新規作成
          const response = await PropertyDocumentFormatAction.createFormat(propertyDocumentFormatName)
          setCards([...cards, response.property_document_format])
          setNewPropertyDocumentFormatName('')
        } catch(error) {
          redirect(error, '/property_document_formats/index_with_notice',
                   '登録に失敗しました。同一の名称がすでに存在するようです。')
        }
      },
      [cards]
    )

    const deleteCard = React.useCallback(
      async (id: number) => {
        const deleteItem = cards.find((card) => card.id === id)
        if (!confirm(deleteItem.name + 'を削除してもよろしいですか？')) {
          return
        }
        try {
          await PropertyDocumentFormatAction.deleteFormat(id)
          const newCards = [...cards]
          const deleteIndex = cards.findIndex((card) => card.id === id)
          newCards.splice(deleteIndex, 1)
          setCards(newCards)
        } catch(error) {
          redirect(error, '/property_document_formats/index_with_notice',
                   '削除に失敗しました。この物件概要書フォーマット名を使用している物件が存在するようです。')
        }
      },
      [cards]
    )

    const updateCard = React.useCallback(
      (id: number, name: string) => {
        const newCards = [...cards].map((card) =>
          card.id === id
            ? {
                ...card,
                name,
              }
            : card
        )
        setCards(newCards)
      },
      [cards]
    )

    const renderCard = React.useCallback(
      (card: { id: number; name: string }, index: number) => {
        return (
          <Card
            key={card.id}
            index={index}
            id={card.id}
            name={card.name}
            moveCard={moveCard}
            dropCard={dropCard}
            deleteCard={deleteCard}
            updateCard={updateCard}
          />
        )
      },
      [moveCard, dropCard]
    )

    return (
      <table className="w-full mt-8">
        <tbody>
          {cards.map((card, i) => renderCard(card, i))}
          <tr className="border-t border-b border-gray-150">
            <td></td>
            <td className="p-2 w-full flex gap-2 md:gap-4 items-center">
              <Input
                className="w-full"
                placeholder="物件概要書フォーマット名を入力してください"
                value={newPropertyDocumentFormatName}
                onChange={(e) => setNewPropertyDocumentFormatName(e.target.value)}
              />
              <Button
                className="text-white"
                prefix={<AddOutlinedIcon fontSize="small" />}
                size="small"
                variant="primary"
                onClick={() => {
                  createPropertyDocumentFormat(newPropertyDocumentFormatName)
                }}
              >
                <span className="text-white hidden md:block">登録</span>
              </Button>
            </td>
          </tr>
        </tbody>
      </table>
    )
  }
}
