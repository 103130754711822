import * as React from 'react'
import { formatLatLngs } from './formatLatLngs'
import VolumeCheckModal from './VolumeCheckModal'
import VolumeCheckRoadModal from './VolumeCheckRoadModal'
import { Row, Td, Th } from '@/components/Table'
import { Input } from '@/components/Input'
import type { User } from '@/types/user'
import FlagIcon from '@mui/icons-material/Flag'
import { dataPrecisionSetup } from '@/utils/cammedFormat'

type Props = {
  property: any
  propertyShape: any
  onChangeParameters?: () => void
  ref_volume_check_parameters?: any
  authenticity_token: string
  disabledGroup: any
  current_user: User
  widthLength: string[]
}

const borderTypeCodeOptions = [
  {
    value: '4',
    text: '隣地',
  },
  {
    value: '2',
    text: '私道(隣地)',
  },
  {
    value: '1',
    text: '道路',
  },
  {
    value: '3',
    text: '私道(道路)',
  },
  {
    value: '5',
    text: '線路',
  },
  {
    value: '6',
    text: '水路',
  },
  {
    value: '7',
    text: '公園',
  },
]

const borderTypeCodeOptionsTemp = [
  {
    value: '4',
    text: '隣地',
  },
  {
    value: '1',
    text: '道路',
  },
]

const setBackMethodOptions = [
  {
    value: '0',
    text: '適用外',
  },
  {
    value: '1',
    text: '中心後退',
  },
  {
    value: '2',
    text: '一方後退(敷地内)',
  },
  {
    value: '3',
    text: '一方後退(敷地外)',
  },
]

type LatLng = number[]

const BordersForm3: React.FC<Props> = ({
  property,
  propertyShape,
  onChangeParameters,
  ref_volume_check_parameters,
  authenticity_token,
  disabledGroup,
  current_user,
  widthLength,
}) => {
  let latlngs: { latlng: LatLng }[]

  if (propertyShape) {
    const latlngsFromPropertyShape = JSON.parse(propertyShape)
    latlngs = formatLatLngs(latlngsFromPropertyShape)
  } else {
    const parameters = ref_volume_check_parameters ? JSON.parse(ref_volume_check_parameters) : null

    if (parameters && parameters.borders) {
      latlngs = parameters.borders
        .sort((a, b) => a.id - b.id)
        .map((border) => {
          return [border.to.lat, border.to.lng]
        })
    } else {
      latlngs = (property?.shape?.latlngs ?? [])
        .sort((a, b) => a.no - b.no)
        .map((latlng) => {
          return [latlng.lat, latlng.lng]
        })
    }
  }

  return (
    <>
      <input type="hidden" id="count_borders" name="count_borders" value={latlngs.length} />
      <>
        {latlngs.map((item, i) => {
          const next_i = i == latlngs.length - 1 ? 0 : i + 1
          return (
            <>
              <Row
                label={
                  <Th>
                    <FlagIcon fontSize="small" className="text-primary-font" />点{i + 1} - 点
                    {next_i + 1}
                  </Th>
                }
              >
                <Td>
                  <div className="flex gap-4 flex-col md:flex-row">
                    <div className="flex items-center">
                      <input
                        type="text"
                        id={`length_${i}`}
                        name={`length_${i}`}
                        className="py-1 px-3 w-full text-sm md:w-[100px]"
                        value={widthLength !== null ? widthLength[i]: ''}
                        onChange={onChangeParameters}
                      />
                    </div>
                    <span
                      className="text-sm"
                      style={{ flexShrink: 0, whiteSpace: 'nowrap', flexDirection: 'row' }}
                    >
                      <VolumeCheckModal
                        title="寸法修正"
                        property={property}
                        idx={i}
                        nextIdx={next_i}
                        latlngA={item}
                        latlngB={latlngs[next_i]}
                        authenticity_token={authenticity_token}
                        changeParameters={onChangeParameters}
                      />
                    </span>
                    <span
                      className={["text-sm", current_user.role === "system_admin" ? '':'row_hidden'].join(' ')}
                      style={{ flexShrink: 0, whiteSpace: 'nowrap', flexDirection: 'row' }}
                    >
                      <VolumeCheckRoadModal
                        title="道路端部設定"
                        property={property}
                        idx={i}
                        nextIdx={next_i}
                        latlngA={item}
                        latlngB={latlngs[next_i]}
                        authenticity_token={authenticity_token}
                        changeParameters={onChangeParameters}
                      />
                    </span>
                    <input
                      type="hidden"
                      id={`from_${i}`}
                      name={`from_${i}`}
                      value={item.join(',')}
                    />
                    <input
                      type="hidden"
                      id={`to_${next_i}`}
                      name={`to_${next_i}`}
                      value={latlngs[next_i].join(',')}
                    />
                  </div>
                </Td>
              </Row>
              <Row label={<Th>タイプ</Th>}>
                <Td>
                  <div className="flex gap-4 flex-col md:flex-row">
                    <div className="relative flex items-strech md:w-[300px]">
                      <select
                        id={`border_${i}`}
                        name={`border_${i}`}
                        className={[styles.select, 'md:w-[300px]'].join(' ')}
                        onChange={onChangeParameters}
                      >
                        {current_user.role === 'system_admin' ? borderTypeCodeOptions.map((option, i) => (
                            <option
                                key={i}
                                value={option.value}
                            >
                              {option.text}
                            </option>
                        )): borderTypeCodeOptionsTemp.map((option, i) => (
                          <option
                            key={i}
                            value={option.value}
                          >
                            {option.text}
                          </option>
                        ))}
                      </select>
                    </div>
                    <Input
                      className="md:w-[300px]"
                      id={`width_${i}`}
                      name={`width_${i}`}
                      prefix="幅員"
                      suffix="m"
                      disabled={
                        disabledGroup.length < latlngs.length ? true : disabledGroup[i].width
                      }
                      onChange={onChangeParameters}
                      onBlur={(e) => {
                        const value = dataPrecisionSetup(e.target.value, 8, 2)
                        ;(document.getElementById(`width_${i}`) as HTMLInputElement).value = value.toString()
                        onChangeParameters()
                      }}
                    />
                  </div>
                </Td>
              </Row>
              <Row label={<Th></Th>}>
                <Td>
                  <div className="flex gap-4 flex-col md:flex-row">
                    <div className="md:w-[300px]"></div>
                    <div className={[styles.inputWrap, 'md:w-[300px]'].join(' ')}>
                      <span className={[styles.box, 'rounded-r-none'].join(' ')}>後退方法</span>
                      <select
                        id={`setback_${i}`}
                        name={`setback_${i}`}
                        className={[
                          styles.backSelect,
                          styles.size['default'],
                          'md:w-[300px]',
                          styles.prefix,
                        ].join(' ')}
                        disabled={
                          disabledGroup.length < latlngs.length ? true : disabledGroup[i].setback
                        }
                        onChange={onChangeParameters}
                      >
                        {setBackMethodOptions.map((option, i) => (
                          <option key={i} value={option.value}>
                            {option.text}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </Td>
              </Row>
              <Row label={<Th>周辺環境の設定</Th>}>
                <Td>
                  <div className="flex gap-4 flex-col md:flex-row">
                    <div className="relative flex items-strech md:w-[300px]">
                      <select
                        id={`surroundings_${i}`}
                        name={`surroundings_${i}`}
                        className={[styles.select, 'md:w-[300px]'].join(' ')}
                        onChange={onChangeParameters}
                        disabled={current_user.role !== 'system_admin'}
                      >
                        {borderTypeCodeOptions.map((option, i) => (
                          <option key={i} value={option.value}>
                            {option.text}
                          </option>
                        ))}
                      </select>
                    </div>
                    <Input
                      className="md:w-[300px]"
                      id={`surroundings_${i}_width`}
                      name={`surroundings_${i}_width`}
                      prefix="幅員"
                      suffix="m"
                      //課題242暫定対応↓
                      //disabled={disabledGroup.length < latlngs.length ? true : disabledGroup[i].surroundings}
                      disabled={
                        current_user.role !== 'system_admin' || disabledGroup.length < latlngs.length
                          ? true
                          : disabledGroup[i].surroundings
                      }
                      //課題242暫定対応↑
                      onChange={onChangeParameters}
                    />
                  </div>
                </Td>
              </Row>
            </>
          )
        })}
      </>
      <input type="hidden" id="count_borders" name="count_borders" value={latlngs.length} />
    </>
  )
}

export default BordersForm3

const styles = {
  select: `
    w-full
    py-2
    pl-4
    pr-10
    text-sm
    appearance-none
    bg-no-repeat
    bg-center
    border
    rounded-sm
    bg-white
    outline-none
    leading-normal
    text-black-base
    border-gray-border
    placeholder-gray-300
    bg-[url('~icons/caret_down.svg')]
    bg-[center_right_12px]
    disabled:text-gray-300
    disabled:bg-gray-light
    disabled:cursor-not-allowed
    disabled:opacity-70
  `,
  checkbox: `
    relative
    inline-block
    appearance-none
    h-3.5
    w-3.5
    border
    focus:outline-none
    mt-0.5
    text-base
    align-top
    bg-no-repeat
    bg-center
    mr-2
    cursor-pointer
    rounded-sm
  `,
  checked: `
    bg-primary
    border-primary
    bg-[url('~icons/checkbox_icon.svg')]
  `,
  box: `
    flex
    items-center
    border
    border-gray-border
    rounded-sm
    bg-gray-100
    py-2
    px-4
    text-sm
    leading-normal
    text-black-base
    whitespace-nowrap
  `,
  inputWrap: `
    relative
    flex
    items-strech
  `,
  prefix: `
    rounded-l-none
    border-l-0
  `,
  size: {
    default: `
      py-2
      pl-4
      pr-10
      text-sm
    `,
  },
  backSelect: `
    w-full
    appearance-none
    bg-no-repeat
    bg-center
    border
    rounded-sm
    bg-white
    outline-none
    leading-normal
    text-black-base
    border-gray-border
    placeholder-gray-300
    bg-[url('~icons/caret_down.svg')]
    bg-[center_right_12px]
    disabled:text-gray-300
    disabled:bg-gray-light
    disabled:cursor-not-allowed
    disabled:opacity-70
  `,
}
