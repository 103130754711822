import * as React from 'react'
import Base from '@/components/Layout/Base'
import { Table } from '@/components/Table'
import type { User } from '@/types/user'
import type { Proposal } from '@/types/proposal'
import { Breadcrumb } from '@/components/Breadcrumb'
import { cammedFormat } from '@/utils/cammedFormat'

type Props = {
  current_user: User
  user: User
  proposal: Proposal
  source_display: string
  proposals_row_numbers: Record<number, number>
}

const ProposalsDetailPage: React.FC<Props> = ({
  current_user,
  proposal,
  source_display,
  proposals_row_numbers,
}) => {
  const title = '入手情報詳細'
  const param = window.location.search
  const property_id = new URLSearchParams(param).get('property_id')

  return (
    <Base
      current_user={current_user}
      title={title}
      breadcrumb={
        <Breadcrumb
          link_texts={['物件管理', '入手情報', '入手情報詳細']}
          property_id={property_id}
          tab="proposals"
        />
      }
      city={proposal.property.prefecture + proposal.property.city}
      submenuCategory="properties"
    >
      <Table className="mt-8">
        <tbody>
          <tr className="bg-gray-light">
            <th
              scope="row"
              className="py-5 px-4 md:text-right whitespace-nowrap text-black-base font-medium w-1/4"
            >
              物件
            </th>
            <td className="py-2 px-4 w-3/4">
              住所表示：{proposal.property.prefecture} {proposal.property.city}{' '}
              {proposal.property.town} {proposal.property.chome}　地番：{proposal.property.chiban}
            </td>
          </tr>
          <tr className="bg-white">
            <th
              scope="row"
              className="py-5 px-4 md:text-right whitespace-nowrap text-black-base font-medium w-1/4"
            >
              順番
            </th>
            <td className="py-2 px-4 w-3/4">{proposals_row_numbers[proposal.id]}</td>
          </tr>
          <tr className="bg-gray-light">
            <th
              scope="row"
              className="py-5 px-4 md:text-right whitespace-nowrap text-black-base font-medium w-1/4"
            >
              入手日時
            </th>
            <td className="py-2 px-4 w-3/4">{proposal.recepted_at}</td>
          </tr>
          <tr className="bg-white">
            <th
              scope="row"
              className="py-5 px-4 md:text-right whitespace-nowrap text-black-base font-medium w-1/4"
            >
              情報入手者 / 情報部署
            </th>
            <td className="py-2 px-4 w-3/4">{proposal.source_user_and_department}</td>
          </tr>
          <tr className="bg-gray-light">
            <th
              scope="row"
              className="py-5 px-4 md:text-right whitespace-nowrap text-black-base font-medium w-1/4"
            >
              入手先
            </th>
            <td className="py-2 px-4 w-3/4">{source_display}</td>
          </tr>
          <tr className="bg-white">
            <th
              scope="row"
              className="py-5 px-4 md:text-right whitespace-nowrap text-black-base font-medium w-1/4"
            >
              売主
            </th>
            <td className="py-2 px-4 w-3/4">{proposal.seller}</td>
          </tr>
          <tr className="bg-gray-light">
            <th
              scope="row"
              className="py-5 px-4 md:text-right whitespace-nowrap text-black-base font-medium w-1/4"
            >
              売主希望総額
            </th>
            <td className="py-2 px-4 w-3/4">
              {proposal.suggested_total_price &&
                `${cammedFormat(Number(proposal.suggested_total_price ?? 0), 0)}円`}
            </td>
          </tr>
          <tr className="bg-white">
            <th
              scope="row"
              className="py-5 px-4 md:text-right whitespace-nowrap text-black-base font-medium w-1/4"
            >
              売主希望坪単価
            </th>
            <td className="py-2 px-4 w-3/4">
              {proposal.suggested_unit_price &&
                `${cammedFormat(Number(proposal.suggested_unit_price ?? 0), 0)}円`}
            </td>
          </tr>
          <tr className="bg-gray-light">
            <th
              scope="row"
              className="py-5 px-4 md:text-right whitespace-nowrap text-black-base font-medium w-1/4"
            >
              備考
            </th>
            <td className="py-2 px-4 w-3/4">
              <p className="whitespace-pre">{proposal.note}</p>
            </td>
          </tr>
        </tbody>
      </Table>
    </Base>
  )
}

export default ProposalsDetailPage
