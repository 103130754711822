import * as React from 'react'
import { Table } from '@/components/Table'
import type { Property, FileWithUrl } from '@/types/property'
import { User } from '@/types/user'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import { AnchorButton } from '@/components/Button'
import RefreshIcon from '@mui/icons-material/Refresh'
import FileDownloadIcon from '@mui/icons-material/FileDownload'
import FileDownloadOffIcon from '@mui/icons-material/FileDownloadOff'
import DoneIcon from '@mui/icons-material/Done'
import ReportProblemIcon from '@mui/icons-material/ReportProblem'
import Tooltip from '@mui/material/Tooltip'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import SaveIcon from '@mui/icons-material/Save'

const PropertyTouki: React.FC<{
  property: Property
  user: User
}> = ({ property, user }) => {
  const statusTexts = {
    processing: '取得中',
    completed: '取得済',
    error: 'エラー',
  }

  const refreshPage = () => {
    window.location.reload()
  }

  const [expandedRows, setExpandedRows] = React.useState({})
  const [selectedRows, setSelectedRows] = React.useState({})
  const [selectAll, setSelectAll] = React.useState(false)
  const rowCheckboxRefs = React.useRef({}).current
  const csrfToken: HTMLMetaElement = document.head.querySelector('meta[name="csrf-token"]')

  const updateChildSelections = (selectedRows, rowIndex, files, isChecked) => {
    const updatedSelections = { ...selectedRows }
    files.forEach((file) => {
      const childId = `child-${rowIndex}-${file.blob_id}`
      updatedSelections[childId] = isChecked
    })
    return updatedSelections
  }

  const updateParentRowSelection = (rowIndex, selectedRows) => {
    const rowFiles = property.touki_registration_request_result[rowIndex]?.files
    if (!Array.isArray(rowFiles)) return { ...selectedRows }

    const updatedSelections = { ...selectedRows }
    const allChecked = rowFiles.every(
      (file) => updatedSelections[`child-${rowIndex}-${file.blob_id}`]
    )
    updatedSelections[`row-${rowIndex}`] = allChecked

    return updatedSelections
  }

  const handleCheckboxChange = (id, isChecked) => {
    const parts = id.split('-')
    const rowType = parts[0]
    const rowIndex = parseInt(parts[1], 10)

    let newSelectedRows = { ...selectedRows, [id]: isChecked }
    let newExpandedRows = { ...expandedRows }

    if (rowType === 'row') {
      const rowFiles = property.touki_registration_request_result[rowIndex]?.files
      if (Array.isArray(rowFiles) && rowFiles.length > 1) {
        newExpandedRows = { ...newExpandedRows, [rowIndex]: isChecked }
        newSelectedRows = updateChildSelections(newSelectedRows, rowIndex, rowFiles, isChecked)
      }
    } else if (rowType === 'child') {
      newSelectedRows = updateParentRowSelection(rowIndex, newSelectedRows)
    }

    setSelectedRows(newSelectedRows)
    setExpandedRows(newExpandedRows)
  }

  const handleSelectAllChange = (isChecked) => {
    let newSelectedRows = {}
    let newExpandedRows = {}

    property.touki_registration_request_result.forEach((result, idx) => {
      if (!Array.isArray(result.files)) {
        return
      }
      const rowId = `row-${idx}`
      newSelectedRows[rowId] = isChecked

      if (Array.isArray(result.files)) {
        if (result.files.length > 1) {
          newExpandedRows[idx] = isChecked
          newSelectedRows = updateChildSelections(newSelectedRows, idx, result.files, isChecked)
        } else if (result.files.length === 1) {
          const childId = `child-${idx}-${result.files[0].blob_id}`
          newSelectedRows[childId] = isChecked
        }
      }
    })

    setSelectAll(isChecked)
    setSelectedRows(newSelectedRows)
    setExpandedRows(newExpandedRows)
  }

  const collectSelectedBlobIds = () => {
    const selectedBlobIds = []

    Object.keys(selectedRows).forEach((key) => {
      if (selectedRows[key]) {
        const parts = key.split('-')
        if (parts[0] === 'child') {
          selectedBlobIds.push(parts[2])
        } else if (parts[0] === 'row') {
          const rowIndex = parseInt(parts[1], 10)
          const rowFiles = property.touki_registration_request_result[rowIndex]?.files
          if (Array.isArray(rowFiles) && rowFiles.length === 1) {
            selectedBlobIds.push(rowFiles[0].blob_id)
          } else if (!Array.isArray(rowFiles)) {
          }
        }
      }
    })

    return selectedBlobIds
  }

  const handleProcessSelectedFiles = () => {
    const selectedBlobIds = collectSelectedBlobIds()
    const postData = {
      blobIds: selectedBlobIds,
      propertyId: property.id,
    }

    sendPostRequest(postData)
  }

  const sendPostRequest = async (data) => {
    const url = `/touki_file_save_to_attachment`
    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': csrfToken.content,
        },
        body: JSON.stringify(data),
      })

      if (response.ok) {
        const result = await response.json()
        window.location.href = result.redirect_url
      }
    } catch (error) {
      console.error('Network error:', error)
    }
  }

  const isAnyCheckboxSelected = () => {
    return Object.values(selectedRows).some((status) => status)
  }

  React.useEffect(() => {
    property.touki_registration_request_result.forEach((result, idx) => {
      if (Array.isArray(result.files)) {
        const anyChecked = result.files.some((file) => selectedRows[`child-${idx}-${file.blob_id}`])
        const allChecked = result.files.every(
          (file) => selectedRows[`child-${idx}-${file.blob_id}`]
        )

        const checkbox = rowCheckboxRefs[`row-${idx}`]
        if (checkbox) {
          checkbox.indeterminate = anyChecked && !allChecked
        }
      }
    })
  }, [selectedRows, property.touki_registration_request_result, rowCheckboxRefs])

  return (
    <div className="mt-10">
      <h3 className="border-b-[1px] border-primary mb-2">
        <span className="inline-flex bg-primary text-white text-sm py-2 px-8">登記情報</span>
      </h3>
      <div className="text-right mb-2">
        {isAnyCheckboxSelected() && !user.proxy_company_id && (
          <AnchorButton
            prefix={<SaveIcon fontSize="small" />}
            outline
            size="small"
            variant="primary"
            onClick={handleProcessSelectedFiles}
          >
            関連資料に保存
          </AnchorButton>
        )}
        &nbsp;
        <AnchorButton
          prefix={<ContentCopyIcon fontSize="small" />}
          outline
          size="small"
          variant="primary"
          href={`/properties/${property.hashid}/touki_registration_requests/new`}
        >
          新規取得
        </AnchorButton>
      </div>
      <div className="text-gray-700 text-sm">
        ※ステータスが「取得中」である請求情報は、「請求済」に変わるまで「
        <span className="border px-1 py-1 cursor-pointer" onClick={refreshPage}>
          <RefreshIcon style={{ color: '#3885B0', display: 'inline-block' }} />
          &nbsp;最新表示
        </span>
        」をクリックしてください。
      </div>
      <div className="text-gray-300 text-[10px]">
        ※ダウンロード時に文字化けする場合は、ブラウザの拡張機能のPDF Viewer（例：Adobe
        Acrobat）の無効化をお試しください。
      </div>
      <Table
        className={
          window.innerHeight > 920
            ? 'h-[760px] md:h-[760px]'
            : 'h-[calc(100vh_-_145px)] md:h-[calc(100vh_-_155px)]'
        }
        borderLine={false}
      >
        <thead className="bg-gray-150 text-sm sticky top-0">
          <tr className="whitespace-nowrap">
            <th scope="col" className="py-2 px-4 font-medium text-center">
              <input
                type="checkbox"
                checked={selectAll}
                onChange={(e) => handleSelectAllChange(e.target.checked)}
              />
            </th>
            <th scope="col" className="py-2 px-4 font-medium text-center">
              No
            </th>
            <th scope="col" className="py-2 px-4 font-medium text-left">
              種別
            </th>
            <th scope="col" className="py-2 px-4 font-medium text-left">
              所在地（地番）
            </th>
            <th scope="col" className="py-2 px-4 font-medium text-center">
              ステータス
            </th>
            <th scope="col" className="py-2 px-4 font-medium text-center">
              請求日時
            </th>
            <th scope="col" className="py-2 px-4 font-medium text-center">
              ファイル
            </th>
            {user.role == 'system_admin' && user.company.pricing_type === 'owner' && (
              <th scope="col" className="py-2 px-4 font-medium text-center">
                （シス管専用ログ）
              </th>
            )}
          </tr>
        </thead>
        <tbody className="whitespace-nowrap">
          {property.touki_registration_request_result.map(
            (touki_registration_request_result, idx) => (
              <>
                <tr className="border-b border-gray-200 text-sm" key={idx}>
                  <td className="py-2 px-4 text-center">
                    <input
                      type="checkbox"
                      ref={(el) => (rowCheckboxRefs[`row-${idx}`] = el)}
                      checked={!!selectedRows[`row-${idx}`]}
                      onChange={(e) => handleCheckboxChange(`row-${idx}`, e.target.checked)}
                      disabled={!Array.isArray(touki_registration_request_result.files)}
                    />
                  </td>
                  <td className="py-2 px-4 text-center">{idx + 1}</td>
                  <td className="py-2 px-4 text-left">
                    {touki_registration_request_result.touki_type_option}
                  </td>
                  <td className="py-2 px-4 text-left">
                    {user.proxy_company_id ? (
                      <>**********************</>
                    ) : (
                      <>{touki_registration_request_result.address_chiban}</>
                    )}
                  </td>
                  <td className="py-2 px-4 text-center">
                    {statusTexts[touki_registration_request_result.status] ||
                      touki_registration_request_result.status}
                    {touki_registration_request_result.status === 'processing' && (
                      <RefreshIcon
                        fontSize="small"
                        onClick={refreshPage}
                        style={{ cursor: 'pointer', color: '#3885B0' }}
                      />
                    )}
                  </td>
                  <td className="py-2 px-4 text-center">
                    {touki_registration_request_result.start_at}
                  </td>
                  <td
                    className={[
                      'py-2 px-4 text-center',
                      !user.proxy_company_id ? '' : 'pointer-events-none',
                    ].join(' ')}
                  >
                    {Array.isArray(touki_registration_request_result.files) &&
                    !user.proxy_company_id ? (
                      touki_registration_request_result.files.length === 1 ? (
                        <a
                          href={touki_registration_request_result.files[0].url}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <FileDownloadIcon fontSize="small" style={{ color: '#3885B0' }} />
                        </a>
                      ) : (
                        <button
                          onClick={() =>
                            setExpandedRows({ ...expandedRows, [idx]: !expandedRows[idx] })
                          }
                        >
                          {expandedRows[idx] ? (
                            <ExpandLessIcon fontSize="small" style={{ color: '#3885B0' }} />
                          ) : (
                            <ExpandMoreIcon fontSize="small" style={{ color: '#3885B0' }} />
                          )}
                        </button>
                      )
                    ) : (
                      <Tooltip title={touki_registration_request_result.files}>
                        <FileDownloadOffIcon
                          fontSize="small"
                          style={{ color: '#aaa', cursor: 'help' }}
                        />
                      </Tooltip>
                    )}
                  </td>
                  {user.role == 'system_admin' && user.company.pricing_type === 'owner' && (
                    <td className="py-2 px-4 text-center">
                      {touki_registration_request_result.system_log != '' ? (
                        <Tooltip title={touki_registration_request_result.system_log}>
                          <ReportProblemIcon
                            fontSize="small"
                            style={{ color: '#C72424', cursor: 'help' }}
                          />
                        </Tooltip>
                      ) : (
                        <Tooltip title="異常検出なし">
                          <DoneIcon fontSize="small" style={{ color: '#349E8C' }} />
                        </Tooltip>
                      )}
                    </td>
                  )}
                </tr>
                {expandedRows[idx] &&
                  Array.isArray(touki_registration_request_result.files) &&
                  touki_registration_request_result.files.map((file, fileIdx) => (
                    <tr className="border-b border-gray-200 text-sm" key={`${idx}-${fileIdx}`}>
                      <td className="py-2 px-4 text-center">
                        <input
                          type="checkbox"
                          checked={!!selectedRows[`child-${idx}-${file.blob_id}`]}
                          onChange={(e) =>
                            handleCheckboxChange(`child-${idx}-${file.blob_id}`, e.target.checked)
                          }
                        />
                      </td>
                      <td className="py-2 px-4 text-center"></td>
                      {!user.proxy_company_id ? (
                        <>
                          <td colSpan={4} className="py-2 px-4 text-left">
                            &emsp;↳ {file.name}
                          </td>
                          <td className="py-2 px-4 text-center">
                            <a href={file.url} target="_blank" rel="noopener noreferrer">
                              <FileDownloadIcon fontSize="small" style={{ color: '#3885B0' }} />
                            </a>
                          </td>
                        </>
                      ) : (
                        <>
                          <td colSpan={4} className="py-2 px-4 text-left">
                            &emsp;↳ **********************
                          </td>
                          <td className="py-2 px-4 text-center pointer-events-none">
                            <FileDownloadOffIcon
                              fontSize="small"
                              style={{ color: '#aaa', cursor: 'help' }}
                            />
                          </td>
                        </>
                      )}
                    </tr>
                  ))}
              </>
            )
          )}
        </tbody>
      </Table>
    </div>
  )
}

export default PropertyTouki
