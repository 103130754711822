import * as React from 'react'
import Base from '@/components/Layout/Base'
import type { User } from '@/types/user'
import { Breadcrumb } from '@/components/Breadcrumb'
import { menusPricingType } from '@/models/menu'
import { MarketDatumModal } from '@/components/Page/MarketData/MarketDatumModal'

type Props = {
  current_user: User
}

const TopPage: React.FC<Props> = ({ current_user }) => {
  const [modalOpen, setModalOpen] = React.useState<boolean>(false)

  const Menus = menusPricingType['enterprise'].map((menu) => {
    if (
      menu.title === '設定' &&
      (current_user.role === 'leader' || current_user.role === 'general')
    ) {
      return {
        title: menu.title,
        path: `/users/${current_user.id}/edit`,
        icon: menu.icon,
      }
    } else {
      return menu
    }
  })
  const menuTitles = menusPricingType[current_user.company.pricing_type].map((menu) => menu.title)

  return (
    <Base current_user={current_user} title="" breadcrumb={<Breadcrumb link_texts={[]} />}>
      <div className="align-middle h-full flex items-center justify-center px-6">
        <div className="w-full md:w-[640px] mx-auto md:mb-10">
          <ul className="grid md:grid-cols-2 gap-[1.25rem] xs:gap-8">
            {Menus.map((menu, index) => Menu(menu, index, current_user, menuTitles, setModalOpen))}
          </ul>
        </div>
      </div>

      <MarketDatumModal open={modalOpen} onClose={() => setModalOpen(false)} path={path} onPropertyDocumentLink={false}  />
    </Base>
  )
}

const path = '/market_data/new'

const Menu = (menu, index, current_user, menuTitles, setModalOpen) => {
  const availableBtn = (title: string) => {
    if (title === '分析情報' && current_user.role === 'general') {
      return false
    } else if (
      title === 'CSVインポート' &&
      (current_user.role === 'leader' || current_user.role === 'general')
    ) {
      return false
    } else if (menuTitles.includes(title)) {
      return true
    } else {
      return false
    }
  }

  const available = availableBtn(menu.title)

  return available ? (
    menu.title === '周辺事例登録' ? (
      <li key={index}>
        <a
          className="cursor-pointer flex gap-2 justify-center items-center font-medium w-full px-4 border-[1px]  md:py-6 py-4 border-primary text-primary hover:bg-primary hover:text-white"
          onClick={() => setModalOpen(true)}
        >
          {menu.icon}
          {menu.title}
        </a>
      </li>
    ) : (
      <li key={index}>
        <a
          href={menu.path}
          className="flex gap-2 justify-center items-center font-medium w-full px-4 border-[1px]  md:py-6 py-4 border-primary text-primary hover:bg-primary hover:text-white"
        >
          {menu.icon}
          {menu.title}
        </a>
      </li>
    )
  ) : (
    <li key={index}>
      <a className="flex gap-2 justify-center items-center font-medium w-full px-4 border-[1px] md:py-6 py-4 border-primary-light text-primary-light hover:bg-white hover:test-primary-light cursor-default">
        {menu.icon}
        {menu.title}
      </a>
    </li>
  )
}

export default TopPage
