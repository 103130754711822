import * as React from 'react';
type IntroductionProps = {
    summary_text?: string;
    introduction_message: string[];
    details_style?: string;
    text_style?: string;
    use_point_style_view_name?: string;
  };
export const Introduction : React.FC<IntroductionProps> = ({
    summary_text = "説明",
    introduction_message,
    details_style = "my-8",
    text_style = "text-sm my-2",
    use_point_style_view_name,
 }) => {
    // 特定のテキストの変更を指定して行いたい場合はuse_point_style_view_nameを利用して以下の例の様にnth-childを利用してください
    // 例
    // const pointStyle = `details p:nth-child(1) {
    //     color: blue;
    //   }`
    return (
      <details className={`${details_style}`}>
        <summary>{summary_text}</summary>
          {introduction_message.map((message, index) => (
              <p key={index} className={`${text_style}`}>{message}</p>
            )
          )
        }
      </details>
    );
};