import * as React from "react";
import "./Loading.css"

interface Props {
  isOpen: boolean;
  children: React.ReactNode;
}
export const LoadingModal: React.FC<Props> = ({
  isOpen,
  children,
}) => {
  if (!isOpen) return null;
  return (
      <div className="modal-overlay">
        <div className="modal-content">
          {children}
        </div>
      </div>
  )
}
export default LoadingModal