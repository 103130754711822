import * as React from 'react'
import Base from '@/components/Layout/Base'
import { Table } from '@/components/Table'
import { Input } from '@/components/Input'
import { Button, AnchorButton } from '@/components/Button'
import { User } from '@/types/user'
import { Department } from '@/types/department'
import { Breadcrumb } from '@/components/Breadcrumb'

type Props = {
  current_user: User
  department: Department
  authenticity_token: string
  error_messages: string[]
}

const DepartmentsEditPage: React.FC<Props> = ({
  current_user,
  department,
  authenticity_token,
  error_messages,
}) => {
  const [name, setName] = React.useState(department.name ?? '')

  const title = '部署編集・登録'
  return (
    <Base
      current_user={current_user}
      title={title}
      breadcrumb={<Breadcrumb link_texts={['部署設定', '部署編集・登録']} />}
      submenuCategory="settings"
    >
      <form
        className="mt-8"
        action={department.id ? `/departments/${department.id}` : '/departments'}
        acceptCharset="UTF-8"
        method="post"
      >
        <input type="hidden" name="_method" value={department.id ? 'patch' : 'post'} />
        <input type="hidden" name="authenticity_token" value={authenticity_token} />
        <input type="hidden" name="department[company_id]" value={department.company.id} />

        {error_messages.length > 0 && (
          <ul className="mb-6 text-sm text-red">
            {error_messages.map((message, i) => (
              <li key={i} className="mb-2 break-all">
                {message}
              </li>
            ))}
          </ul>
        )}
        <Table className="block h-[calc(100vh_-_182px)] md:h-[calc(100vh_-_195px)]">
          <tbody className="table-row-group">
            <tr className="bg-gray-light table-row">
              <th
                scope="row"
                className="py-5 px-4 text-right whitespace-nowrap text-black-base font-medium w-1/4 table-cell"
              >
                部署名
              </th>
              <td className="py-2 px-4 w-3/4 table-cell">
                <Input
                  className="max-w-[246px]"
                  value={name}
                  name="department[name]"
                  id="department_name"
                  onChange={(e) => setName(e.target.value)}
                />
              </td>
            </tr>
          </tbody>
        </Table>
        <div className="bg-white flex justify-center items-center w-full left-0 md:gap-4 border-t border-gray-250 pt-2">
          <AnchorButton
            className="w-full md:w-40 md:h-auto text-primary-font border-white"
            variant="text"
            outline
            href="/departments"
          >
            キャンセル
          </AnchorButton>
          <Button className="w-full md:w-40 md:h-auto">編集を保存</Button>
        </div>
      </form>
    </Base>
  )
}

export default DepartmentsEditPage
