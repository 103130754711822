function unit_format(value: any, unit: string, precision: number = 2) {
  if (typeof value === 'number') {
    if (Math.ceil(value) - value < Math.pow(10, -precision)) {
      value = Math.ceil(value)
    } else {
      value = Math.floor(value * Math.pow(10, precision)) / Math.pow(10, precision)
    }
    value = value.toFixed(precision)
  }
  return !value ? '' : `${value}${unit}`
}

export function unit_num(value: any) {
  return unit_format(value, '')
}

export function unit_pct(value: any) {
  return unit_format(value, '%')
}

export function unit_int_pct(value) {
  if (typeof value === 'string' && value.trim() === '') {
    return ''
  }
  if (typeof value === 'number') {
    value = Math.floor(value)
  }
  return value == null ? '' : `${value}%`
}

export function unit_m2(value: any) {
  return unit_format(value, 'm²')
}

export function unit_meter(value: any) {
  return unit_format(value, 'm')
}

export function unit_tsubo(value: any) {
  return unit_format(value, '坪')
}
