import * as React from 'react'
import { Dialog, Transition } from '@headlessui/react'
import ClearIcon from '@material-ui/icons/Clear'
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf'
import ContactMailIcon from '@mui/icons-material/ContactMail'
import SendIcon from '@mui/icons-material/Send'
import VisibilityIcon from '@mui/icons-material/Visibility'

type Props = {
  onAnnouncementUpdate: () => void
}

export const AnnouncementsEditModal: React.FC<Props> = ({ onAnnouncementUpdate }) => {
  const modalTitle = 'お知らせ管理センター（運営者専用）'
  const csrfToken: HTMLMetaElement = document.head.querySelector('meta[name="csrf-token"]')
  const [isOpen, setIsOpen] = React.useState(false)
  const [announcements, setAnnouncements] = React.useState([])

  const [title, setTitle] = React.useState('')
  const [body, setBody] = React.useState('')
  const [isEditing, setIsEditing] = React.useState(false)
  const [editingAnnouncementId, setEditingAnnouncementId] = React.useState(null)
  const [file, setFile] = React.useState(null)
  const [inputKey, setInputKey] = React.useState(Date.now())
  const [pdfMetadata, setPdfMetadata] = React.useState({ name: '', url: '' })

  function closeModal() {
    setIsOpen(false)
  }

  function openModal() {
    setIsOpen(true)
    fetchAnnouncements()
  }

  const fetchAnnouncements = () => {
    fetch('/announcements/all_announcements')
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok')
        }
        return response.json()
      })
      .then((data) => {
        setAnnouncements(data)
      })
      .catch((error) => {
        console.error('Error fetching announcements:', error)
      })
  }

  const handleFileChange = (e) => {
    const file = e.target.files[0]
    if (file) {
      if (file.type !== 'application/pdf') {
        alert('PDFファイルのみアップロード可能です。')
        e.target.value = ''
        return
      }
      setFile(file)
    }
  }

  const handleClearFile = () => {
    setFile(null)
    setInputKey(Date.now())
  }

  const handleDeleteExistingPdf = () => {
    setPdfMetadata({ name: '', url: '' })
    setInputKey(Date.now())
  }

  const handleSubmit = async (event) => {
    event.preventDefault()
    let isConfirmed = true
    if (isEditing) {
      isConfirmed = confirm('お知らせの修正を実行してもよろしいですか？')
    }
    if (!isConfirmed) {
      return
    }

    const formData = new FormData()
    formData.append('title', title)
    formData.append('body', body)

    if (file) {
      formData.append('announcement_pdf', file)
    } else if (!pdfMetadata.name && isEditing) {
      formData.append('delete_pdf', 'true')
    }

    let requestOptions = {
      method: isEditing ? 'PUT' : 'POST',
      headers: {
        'X-CSRF-Token': csrfToken.content,
      },
      body: formData,
    }

    const url = isEditing ? `/announcements/${editingAnnouncementId}` : '/announcements'

    try {
      const response = await fetch(url, requestOptions)

      if (response.ok) {
        await response.json()
        fetchAnnouncements()
        handleReset()
      } else {
        console.error('Failed to submit announcement')
      }
    } catch (error) {
      console.error('Error submitting form:', error)
    }
  }

  const handleDeleteClick = async (announcementId) => {
    const isConfirmed = confirm(
      '削除を実行すると、このお知らせはユーザーのお知らせ一覧から削除されます。よろしいですか？'
    )
    if (!isConfirmed) {
      return
    }
    const requestOptions = {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': csrfToken.content,
      },
    }

    try {
      const response = await fetch(`/announcements/${announcementId}`, requestOptions)

      if (response.ok) {
        setAnnouncements((prevAnnouncements) =>
          prevAnnouncements.filter((announcement) => announcement.id !== announcementId)
        )
        onAnnouncementUpdate()
      } else {
        console.error('Failed to delete announcement')
      }
    } catch (error) {
      console.error('Error deleting announcement:', error)
    }
  }

  const handlePublishClick = async (announcementId) => {
    const isConfirmed = confirm('このお知らせを正式に配信します。よろしいですか？')
    if (!isConfirmed) {
      return
    }
    const requestOptions = {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': csrfToken.content,
      },
    }

    try {
      const response = await fetch(`/announcements/${announcementId}/publish`, requestOptions)
      if (response.ok) {
        await response.json()
        fetchAnnouncements()
        onAnnouncementUpdate()
      } else {
        console.error('Failed to publish announcement')
      }
    } catch (error) {
      console.error('Error publishing announcement:', error)
    }
  }

  const handleTitleChange = (e) => {
    setTitle(e.target.value)
  }

  const handleBodyChange = (e) => {
    setBody(e.target.value)
  }

  const handleEditClick = (announcement) => {
    setIsEditing(true)
    setEditingAnnouncementId(announcement.id)
    setTitle(announcement.title)
    setBody(announcement.body)

    if (announcement.announcement_pdf) {
      setPdfMetadata({
        name: announcement.announcement_pdf.name,
        url: announcement.announcement_pdf.url,
      })
    }
  }

  const handleReset = () => {
    setTitle('')
    setBody('')
    setFile(null)
    setIsEditing(false)
    setEditingAnnouncementId(null)
    setPdfMetadata({ name: '', url: '' })
    setInputKey(Date.now())
  }

  return (
    <>
      <div className="">
        <button type="button" onClick={openModal}>
          <ContactMailIcon style={{ color: '#646f75' }} fontSize="medium" />
        </button>
      </div>

      <Transition appear show={isOpen} as={React.Fragment}>
        <Dialog className="relative z-10" onClose={closeModal}>
          <Transition.Child
            as={React.Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center text-center">
              <Transition.Child
                as={React.Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-screen md:max-w-[625px] transform overflow-hidden rounded-2xl bg-white text-left align-middle shadow-xl transition-all">
                  <Dialog.Title className="text-base font-medium p-4 leading-6 text-gray-700">
                    {modalTitle}
                    <ClearIcon className="float-right cursor-pointer" onClick={closeModal} />
                  </Dialog.Title>
                  <div className="border-t border-gray-150"></div>

                  <div className="p-4">
                    <form onSubmit={handleSubmit}>
                      <div className="mb-3">
                        <label
                          htmlFor="タイトル"
                          className="block text-sm font-medium text-gray-700"
                        >
                          タイトル
                        </label>
                        <input
                          type="text"
                          id="title"
                          value={title}
                          onChange={handleTitleChange}
                          className={`mt-1 p-2 block w-full border-[1px] ${
                            isEditing ? 'border-[#75a5be]' : 'border-[#bbb]'
                          } focus:outline-none sm:text-sm`}
                          placeholder="お知らせタイトルを入力してくだい"
                        />
                      </div>
                      <div className="mb-3">
                        <label htmlFor="body" className="block text-sm font-medium text-gray-700">
                          正文
                        </label>
                        <textarea
                          id="body"
                          value={body}
                          onChange={handleBodyChange}
                          rows={3}
                          className={`mt-1 p-2 block w-full border-[1px] ${
                            isEditing ? 'border-[#75a5be]' : 'border-[#bbb]'
                          } focus:outline-none sm:text-sm min-h-[78px]`}
                          placeholder="お知らせ正文を入力してください"
                        ></textarea>
                      </div>
                      <div className="flex justify-between w-full items-center">
                        <div className="flex">
                          {pdfMetadata.name ? (
                            <div className="flex items-center">
                              <div className="ml-2 text-sm text-primary-font">
                                <a href={pdfMetadata.url} target="_blank" rel="noopener noreferrer">
                                  {pdfMetadata.name}
                                </a>
                              </div>
                              <button
                                type="button"
                                onClick={handleDeleteExistingPdf}
                                className="px-2 py-[1px] text-red-700"
                              >
                                <ClearIcon style={{ fontSize: '1rem' }} />
                              </button>
                            </div>
                          ) : (
                            <>
                              <input
                                key={inputKey}
                                type="file"
                                id="pdf"
                                accept="application/pdf"
                                onChange={handleFileChange}
                                className="block w-full text-sm text-primary-font
                                  file:mr-4 file:py-1 file:px-4
                                  file:border file:border-primary-font file:text-primary-font
                                  file:bg-transparent
                                  hover:file:bg-primary-font hover:file:text-white cursor-pointer"
                              />
                              {file && (
                                <div className="flex items-center">
                                  <button
                                    type="button"
                                    onClick={handleClearFile}
                                    className="px-2 py-[1px] text-red-700"
                                  >
                                    <ClearIcon style={{ fontSize: '1rem' }} />
                                  </button>
                                </div>
                              )}
                            </>
                          )}
                        </div>
                        <div className="flex space-x-2">
                          <button
                            type="button"
                            onClick={handleReset}
                            className="inline-flex justify-center py-1 px-3 text-sm font-medium text-primary-font hover:text-primary"
                          >
                            {isEditing ? '修正中止' : '入力リセット'}
                          </button>
                          <button
                            type="submit"
                            disabled={!title.trim() || !body.trim()}
                            className={`inline-flex justify-center border border-transparent py-1 px-3 text-sm font-medium ${
                              !title.trim() || !body.trim()
                                ? 'bg-[#86b6d1] text-white cursor-not-allowed'
                                : 'bg-primary-font hover:bg-primary text-white'
                            }`}
                          >
                            {isEditing ? '修正実行' : '下書き作成'}
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>

                  <div className="border-t border-gray-150"></div>
                  <div className="h-[50vh] overflow-y-auto">
                    {announcements.map((announcement, index) => (
                      <React.Fragment key={index}>
                        <div className="px-8 py-4 text-[#333]">
                          <div className="flex justify-between items-center">
                            <div className="flex items-center flex-grow">
                              <h3 className="font-bold text-[#4a4a4a]">{announcement.title}</h3>
                              {announcement.announcement_pdf &&
                                announcement.announcement_pdf.url && (
                                  <a
                                    href={announcement.announcement_pdf.url}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    <PictureAsPdfIcon
                                      className="ml-2"
                                      fontSize="small"
                                      style={{ color: '#4a7891' }}
                                    />
                                  </a>
                                )}
                              {!announcement.published && (
                                <span className="text-xs text-[#aaa]">（配信待ち）</span>
                              )}
                            </div>
                          </div>
                          <div className="flex justify-between items-center mb-1">
                            <div>
                              {!announcement.published ? (
                                <button
                                  onClick={() => handlePublishClick(announcement.id)}
                                  className="inline-flex items-center px-4 py-[1px] border border-transparent text-xs text-white bg-primary-font hover:bg-primary"
                                >
                                  配信実行
                                  <SendIcon className="ml-2" style={{ fontSize: '0.75rem' }} />
                                </button>
                              ) : (
                                <span className="text-xs text-[#aaa] bg-[#f4f4f4] px-4 py-1 flex items-center">
                                  {new Date(announcement.created_at).toLocaleString('ja-JP', {
                                    year: 'numeric',
                                    month: '2-digit',
                                    day: '2-digit',
                                    hour: '2-digit',
                                    minute: '2-digit',
                                    second: '2-digit',
                                    hour12: false,
                                  })}
                                  <SendIcon className="mx-2" style={{ fontSize: '0.75rem' }} />
                                  <span className="border-l border-r border-[#ddd] px-2 flex items-center">
                                    <span className="mx-1">配信済 ✔︎</span>
                                  </span>
                                  <span className="ml-2 flex items-center">
                                    <VisibilityIcon
                                      className="mx-1"
                                      style={{ fontSize: '0.9rem', verticalAlign: 'middle' }}
                                    />
                                    <span className="ml-[1px]">{announcement.read_count}</span>
                                  </span>
                                </span>
                              )}
                            </div>
                            <div className="ml-5 flex space-x-2">
                              <button
                                onClick={() => handleEditClick(announcement)}
                                className="border border-primary-font text-primary-font hover:text-white hover:bg-primary-font text-xs py-[1px] px-2"
                              >
                                修正
                              </button>
                              <button
                                onClick={() => handleDeleteClick(announcement.id)}
                                className="border border-red-700 text-red-700 hover:text-white hover:bg-red-700 text-xs py-[1px] px-2"
                              >
                                削除
                              </button>
                            </div>
                          </div>
                          <div
                            className="pt-[2px] text-sm"
                            dangerouslySetInnerHTML={{
                              __html: announcement.body.replace(/\n/g, '<br>'),
                            }}
                          ></div>
                        </div>
                        <hr className="w-9/10 mx-auto border-[#e7e7e7]" />
                      </React.Fragment>
                    ))}
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  )
}

export default AnnouncementsEditModal
