import type { User } from '@/types/user'
import type { Tag } from '@/types/tag'
import type { BusinessType } from '@/types/businessType'
import type { FileCapacity } from '@/types/fileCapacity'
import type { PricingTypeName } from '@/models/pricingType'

export type Company = {
  id: number
  name: string
  users: User[]
  proxy_user?: User
  phone_number: number
  global_ip: string[]
  prefecture: string
  city: string
  address: string
  zipcode: number
  pricing_type: PricingTypeName
  hide_source: "source_visible" | "source_hidden" | "source_all_visible"
  is_ip_restricted: boolean
  is_volume_check: boolean
  account_max: number
  is_auto_ping: boolean
  ocr_enabled: boolean
  touki_enabled: boolean
  logo_image?: {
    blob?: {
      filename: string
    }
  }
  tags: Tag[]
  business_types: BusinessType[]
  file_capacity?: FileCapacity
  file_limit_with_unit?: string
  file_usage_with_unit?: string
}

export const isOverEnterprise = ( pricingType: PricingTypeName) => {
  return ['enterprise', 'owner'].includes(pricingType)
}