import * as React from 'react';
import Base from '@/components/Layout/Base';
import { Table } from '@/components/Table';
import { CustomizeTable } from '@/components/Table/CustomizeTable';
import { Introduction } from '@/components/Layout/Introduction';
import type { User } from '@/types/user';
import type { Company } from '@/types/company';
import { Breadcrumb } from '@/components/Breadcrumb';
import { Button, AnchorButton } from '@/components/Button'
import VisibilityIcon from '@mui/icons-material/Visibility';


type Props = {
  current_user: User;
  company: Company;
  authenticity_token: string;
  error_messages: string[];
  display_order_setting_master_hash: { [key: string]: string };
  company_display_order_setting_master_hash: { [key: string]: string };
  company_hidden_send_columns: string[];
  company_not_display_columns: boolean[]
};

const CompanyDisplayOrderSetting: React.FC<Props> = ({
  current_user,
  company,
  authenticity_token,
  error_messages,
  display_order_setting_master_hash,
  company_display_order_setting_master_hash,
  company_hidden_send_columns,
  company_not_display_columns
}) => {
  const title = "入手情報カラム順設定";
  const action = company.id ? 'patch' : 'post';
  const introduction_message = [
    '◾️ 入手情報一覧画面のカラムの順番を変更できます。',
    '◾️ 右矢印をクリックすると右にカラムが移動し、左矢印をクリックすると左に移動します。',
    '◾️ PC幅の場合、No.と所在地(住居表示)の位置は固定です。',
    '◾️ 目のアイコンをクリックすると表示/非表示の切り替えができます。'
  ];
  const column_labels={
    id: 'No.',
    location: '所在地（住居表示）',
    name: '名前'
  }
  const head_sticky_styles={
    0: 'md:sticky md:left-0 px-12 z-10',
    1: 'md:sticky md:left-[80px] px-12 z-10',
  }
  const body_sticky_styles={
    0: 'md:sticky left-0 z-10',
    1: 'md:sticky left-[80px] z-10',
  }
  const [useColumn, setUseColumn] = React.useState(Object.keys(company_display_order_setting_master_hash));
  const [isDummyValue, setDummyValue] = React.useState(Object.values(company_display_order_setting_master_hash));
  const [isCompanyHiddenSendColumns, setCompanyHiddenSendColumns] = React.useState(company_hidden_send_columns);
  const [isCompanyNotDisplayColumns, setCompanyNotDisplayColumns] = React.useState(company_not_display_columns);
  const dummy_tag = ["タグ未設定", "タグ１", "タグ２", "タグ３", "タグ4", "タグ5"];
  const swapCompanyItems = (index: number, direction: number) => {
    const newColumns = [...useColumn];
    const newValues = [...isDummyValue];
    const sendColumns = [...isCompanyHiddenSendColumns];
    const companyNotDisplayColumns = [...isCompanyNotDisplayColumns];
  
    [newColumns[index], newColumns[index + direction]] = [newColumns[index + direction], newColumns[index]];
    [newValues[index], newValues[index + direction]] = [newValues[index + direction], newValues[index]];
    [sendColumns[index], sendColumns[index + direction]] = [sendColumns[index + direction], sendColumns[index]];
    [companyNotDisplayColumns[index], companyNotDisplayColumns[index + direction]] = [companyNotDisplayColumns[index + direction], companyNotDisplayColumns[index]];
  
    setUseColumn(newColumns);
    setDummyValue(newValues);
    setCompanyHiddenSendColumns(sendColumns);
    setCompanyNotDisplayColumns(companyNotDisplayColumns);
  };
  
  const moveLeft = (index: number) => {
    if (index > 2) {
      swapCompanyItems(index, -1);
    }
  };
  
  const moveRight = (index: number) => {
    if (index < useColumn.length - 1) {
      swapCompanyItems(index, 1);
    }
  };
  
  return (
    <Base
    current_user={current_user}
      title={title}
      breadcrumb={<Breadcrumb link_texts={['入手情報カラム順設定']} />}
      submenuCategory="settings"
    >
      <ul className={['mb-6 text-sm text-red'].join(' ')}>
        {error_messages.map((message, i) => (
          <li key={i} className="mb-2 break-all">
            {message}
          </li>
        ))}
      </ul>
      <form
        className="mt-8"
        encType="multipart/form-data"
        action={`/companies/${company.id}/company_display_order_setting_update`}
        acceptCharset="UTF-8"
        method="post"
      >
        <input type="hidden" name="_method" value={action} />
        <input type="hidden" name="authenticity_token" value={authenticity_token} />
        <input type="hidden" name="is_company_hidden_send_columns" value={JSON.stringify(isCompanyHiddenSendColumns)} />
        <input type="hidden" name="is_company_not_display_columns" value={JSON.stringify(isCompanyNotDisplayColumns)} />
        <p className='my-4'>※ LAND デフォルト</p>
        <CustomizeTable
          use_view_name={'company_display_order_setting'}
          display_keys={Object.keys(display_order_setting_master_hash)}
          display_values={Object.values(display_order_setting_master_hash)}
          dummy_tag={dummy_tag}
          column_labels={column_labels}
          head_sticky_styles={head_sticky_styles}
          body_sticky_styles={body_sticky_styles}
        />
        <hr className='mt-12' />
        <p className='my-6'>※ {company.name}様の設定</p>
        <Introduction introduction_message={introduction_message} />
        <CustomizeTable
          use_view_name={'company_display_order_setting'}
          display_keys={useColumn}
          display_values={isDummyValue}
          dummy_tag={dummy_tag}
          not_display_columns={isCompanyNotDisplayColumns}
          setNotDisplayColumns={setCompanyNotDisplayColumns}
          moveLeft={moveLeft}
          moveRight={moveRight}
          custom_visibility_flg={true}
          custom_order_flg={true}
          column_labels={column_labels}
          head_sticky_styles={head_sticky_styles}
          body_sticky_styles={body_sticky_styles}
        />
        <div className="bg-white flex justify-center items-center w-full left-0 md:gap-4 border-t border-gray-250 pt-2 ms:mt-5 md:mt-16">
          <AnchorButton
            className="w-full md:w-40 md:h-auto text-primary-font border-white mt-6"
            outline
            variant="text"
            href={`/companies/${company.id}/company_display_order_setting`}
          >
            キャンセル
          </AnchorButton>
          <Button className="w-full md:w-40 md:h-auto mt-6">編集を保存</Button>
        </div>
      </form>
    </Base>
  );
};

export default CompanyDisplayOrderSetting;
