import * as React from 'react'
import { Card } from './Card'
import type { BusinessType } from '@/types/businessType'
import { Input } from '@/components/Input'
import { Button } from '@/components/Button'
import AddOutlinedIcon from '@material-ui/icons/AddOutlined'
export interface ContainerState {
  cards: BusinessType[]
}

type Props = {
  items: BusinessType[]
}

export const Container: React.FC<Props> = ({ items }) => {
  {
    const sortBusinessTypes = [...items]
    sortBusinessTypes.sort((prev, next) => prev.order - next.order)

    const [newBusinessTypeName, setNewBusinessTypeName] = React.useState('')
    const [cards, setCards] = React.useState(sortBusinessTypes)
    const csrfToken: HTMLMetaElement = document.head.querySelector('meta[name="csrf-token"]')

    const moveCard = React.useCallback(
      (dragIndex: number, hoverIndex: number) => {
        setCards((prevCards: BusinessType[]) => {
          const dragCard = prevCards[dragIndex]
          const newCards = [...prevCards]
          newCards.splice(dragIndex, 1)
          newCards.splice(hoverIndex, 0, dragCard)
          return newCards
        })
      },
      [setCards]
    )

    const dropCard = React.useCallback(() => {
      cards.forEach((businessType, businessTypeIndex) => {
        fetch(`/business_types/${businessType.id}`, {
          method: 'PATCH',
          body: JSON.stringify({ name: businessType.name, order: businessTypeIndex + 1 }),
          headers: {
            'Content-Type': 'application/json',
            'X-CSRF-Token': csrfToken.content,
          },
        }).catch(() => {
          alert('更新に失敗しました')
        })
      })
    }, [cards])

    const createBusinessType = React.useCallback(
      (businessTypeName: string) => {
        if (!businessTypeName) {
          alert('事業形態を入力してください')
          return
        }

        // 新規作成
        fetch('/business_types', {
          method: 'POST',
          body: JSON.stringify({ name: businessTypeName }),
          headers: {
            'Content-Type': 'application/json',
            'X-CSRF-Token': csrfToken.content,
          },
        })
          .then((res) => res.json())
          .then(({ business_type }) => {
            setCards([...cards, business_type])
            setNewBusinessTypeName('')
          })
          .catch(() => {
            alert('作成に失敗しました')
          })
      },
      [cards]
    )

    const deleteCard = React.useCallback(
      (id: number) => {
        const deleteItem = cards.find((card) => card.id === id)
        if (!confirm(deleteItem.name + 'を削除してもよろしいですか？')) {
          return
        }
        fetch(`/business_types/${id}`, {
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/json',
            'X-CSRF-Token': csrfToken.content,
          },
        })
          .then((res) => res.json())
          .then(() => {
            const newCards = [...cards]
            const deleteIndex = cards.findIndex((card) => card.id === id)
            newCards.splice(deleteIndex, 1)
            setCards(newCards)
          })
          .catch(() => {
            location.href =
              '/business_types/index_with_notice?message=削除に失敗しました。この事業形態を使用している物件が存在するようです。'
          })
      },
      [cards]
    )

    const updateCard = React.useCallback(
      (id: number, name: string) => {
        const newCards = [...cards].map((card) =>
          card.id === id
            ? {
                ...card,
                name,
              }
            : card
        )
        setCards(newCards)
      },
      [cards]
    )

    const renderCard = React.useCallback(
      (card: { id: number; name: string }, index: number) => {
        return (
          <Card
            key={card.id}
            index={index}
            id={card.id}
            name={card.name}
            moveCard={moveCard}
            dropCard={dropCard}
            deleteCard={deleteCard}
            updateCard={updateCard}
          />
        )
      },
      [moveCard, dropCard]
    )

    return (
      <table className="w-full mt-8">
        <tbody>
          {cards.map((card, i) => renderCard(card, i))}
          <tr className="border-t border-b border-gray-150">
            <td></td>
            <td className="p-2 w-full flex gap-2 md:gap-4 items-center">
              <Input
                className="w-full"
                placeholder="事業形態を入力してください"
                value={newBusinessTypeName}
                onChange={(e) => setNewBusinessTypeName(e.target.value)}
              />
              <Button
                className="text-white"
                prefix={<AddOutlinedIcon fontSize="small" />}
                size="small"
                variant="primary"
                onClick={() => {
                  createBusinessType(newBusinessTypeName)
                }}
              >
                <span className="text-white hidden md:block">登録</span>
              </Button>
            </td>
          </tr>
        </tbody>
      </table>
    )
  }
}
