/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-call */
import * as React from 'react'

import { Table } from '@/components/Table'

type Props = {
  yearmonth_list: any[]
  departments_comparing_monthly_datas: any[]
}

const AnalysesDepartmentsComparingMonthlyChart: React.FC<Props> = ({
  yearmonth_list,
  departments_comparing_monthly_datas,
}) => {
  const categories = { 新規: 'new_count', 既存: 'existing_count', 計: 'total_count' }
  return (
    <Table topScrollbar={true}>
      <thead className="bg-gray-150 text-sm sticky top-0">
        <tr className="whitespace-nowrap">
          <th scope="col" className="py-2 px-4 font-medium">
            部署名
          </th>
          <th scope="col" className="py-2 px-4 font-medium">
            情報
          </th>
          {yearmonth_list.map((yearmonth, index) => (
            <th key={index} scope="col" className="py-2 px-4 font-medium">
              {yearmonth[0]}年{yearmonth[1]}月(前月比)
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {departments_comparing_monthly_datas.map((department_data, dataIndex) => {
          const values: any[] = Object.values(department_data.data)
          return (
            <>
              {['新規', '既存', '計'].map((label, index) => (
                <tr key={index} className={`${dataIndex % 2 ? 'bg-gray-light' : 'bg-white'}`}>
                  {label === '新規' && (
                    <th
                      scope="row"
                      rowSpan={3}
                      className="py-2 px-4 md:text-right whitespace-nowrap text-black-base font-medium"
                    >
                      {department_data.label}
                    </th>
                  )}
                  <th
                    scope="row"
                    className="py-2 px-4 md:text-right whitespace-nowrap text-black-base font-medium"
                  >
                    {label}
                  </th>
                  {values.map((data, index) => {
                    const pre_month_value = index === 0 ? 0 : values[index - 1][label]
                    return (
                      <td key={index} className="py-2 px-4 w-3/4">
                        <span className={categories[label]}>{data[label]}</span> (
                        {pre_month_value === 0
                          ? '-'
                          : `${Math.round((data[label] * 1000) / pre_month_value) / 10}%`}
                        )
                      </td>
                    )
                  })}
                </tr>
              ))}
            </>
          )
        })}
      </tbody>
    </Table>
  )
}

export default AnalysesDepartmentsComparingMonthlyChart
