import * as React from "react";
import type {User} from "@/types/user";
import type {Property} from "@/types/property";
import DatePicker, {registerLocale} from "react-datepicker";
import {AnchorButton} from "@/components/Button";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";

import ja from 'date-fns/locale/ja'
import 'react-datepicker/dist/react-datepicker.css'
import CalendarToday from "@material-ui/icons/CalendarToday";
import {colors} from "@/utils/colors";

const TimeFrame: React.FC<{
  user: User
  property: Property
}> = ({ property, user }) => {
  registerLocale('ja', ja)

  const Today = new Date()
  const [from, setFrom] = React.useState<Date>(Today)
  const [to, setTo] = React.useState<Date>(Today)

  return (
    <div className="mt-10">
    <div className="flex flex-wrap md:flex-nowrap items-center gap-5 py-10 w-1/2">
      <div className="w-full">
        <style>
          {`
  .react-datepicker__navigation-icon {
    top: 3px;
  }
  .react-datepicker__navigation-icon::before {
    box-sizing: content-box;
  }
  .react-datepicker__month-text {
    padding: 8px;
  }
`}
        </style>
        <DatePicker
          selected={from}
          locale="ja"
          maxDate={to}
          dateFormat="yyyy年MM月"
          showMonthYearPicker
          onChange={(selectedDate) => {
            setFrom(selectedDate)
          }}
          customInput={<CustomInput/>}
        />
      </div>
      <div className="text-sm">~</div>
      <div className="w-full">
        <DatePicker
          selected={to}
          locale="ja"
          minDate={from}
          maxDate={Today}
          dateFormat="yyyy年MM月"
          showMonthYearPicker
          onChange={(selectedDate) => {
            setTo(selectedDate)
          }}
          customInput={<CustomInput/>}
        />
      </div>
      <AnchorButton
        className="text-primary-font"
        prefix={<CloudDownloadIcon fontSize="small" />}
        outline
        size="small"
        variant="primary"
        href={`/time_frame/export?year_from=${from.getFullYear()}&month_from=${from.getMonth()}&year_to=${to.getFullYear()}&month_to=${to.getMonth()}`}
      >
        <span className="hidden md:inline">CSVダウンロード</span>
      </AnchorButton>
    </div>
    </div>
  )
}

const customInputStyle = `
  className="appearance-none
  bg-no-repeat
  bg-center
  border
  rounded-sm
  bg-white
  outline-none
  leading-normal
  text-black-base
  border-gray-border
  placeholder-gray-300
  w-full
  py-2
  pl-4
  pr-3
  text-sm
  flex
  items-center
`
// eslint-disable-next-line react/display-name
const CustomInput = React.forwardRef(
  (
    {
      value,
      onClick,
    }: {
      value?: string
      onClick?: React.MouseEventHandler<HTMLButtonElement>
    },
    ref
  ) => {
    return (
      <button
        className={customInputStyle}
        onClick={(e) => {
          e.preventDefault()
          onClick(e)
        }}
      >
        {value}
        <CalendarToday className="ml-auto" fontSize="small" htmlColor={colors.gray} />
      </button>
    )
  }
)

export default TimeFrame