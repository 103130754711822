import * as React from 'react'
import { CaretDownIcon } from '@/components/SvgIcon'
import { colors } from '@/utils/colors'

type Props = {
  title?: React.ReactNode
  body?: React.ReactNode
  show?: boolean
  onClick?: () => void
}

const Accordion: React.FC<Props> = ({ title = '', body = '', show = false, onClick }) => {
  return (
    <div className={[styles.wrap].join('')}>
      <div className={[styles.header].join('')} onClick={onClick}>
        <CaretDownIcon
          fill={colors.gray}
          className={`${
            show
              ? 'rotate-0 transition duration-100 transform'
              : '-rotate-90 transition duration-100 transform'
          }`}
        />
        <div className="ml-3 leading-none">{title}</div>
      </div>
      <div className={[styles.body, show ? 'block' : 'hidden'].join('')}>{body}</div>
    </div>
  )
}

export default Accordion

const styles = {
  wrap: `
    border
    rounded
  `,
  header: `
    flex
    w-full
    items-center
    p-4
    text-left
    cursor-pointer
  `,
  body: `
    px-4
    border-t
  `,
}
