import * as React from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { Button, AnchorButton } from '@/components/Button'
import SearchIcon from '@material-ui/icons/Search'
import ClearIcon from '@material-ui/icons/Clear'
import DeleteOutlined from '@material-ui/icons/DeleteOutlined'
import RefreshIcon from '@material-ui/icons/Refresh'
import { Input } from '@/components/Input'
import { Select } from '@/components/Select'
import { displayDeffaultNumber } from '@/models/project'
import { DropDown, Option } from '@/components/DropDown/Index'

type Props = {
  title: string
  sub_tab: string
  business_type_options: { value: string; text: string }[]
  tag_options: { value: string; text: string }[]
  department_options: { value: string; label: string }[]
  source_department_options: { value: string; label: string }[]
  user_options: { value: string; label: string }[]
  prefecture_options: { value: string; label: string }[]
  sort_options: { value: string; text: string }[]
}

type TownJson = {
  town: string
  town_kana: string
}[]

export const ProjectsModal: React.FC<Props> = ({
  title,
  sub_tab,
  business_type_options,
  tag_options,
  source_department_options,
  department_options,
  user_options,
  prefecture_options,
  sort_options,
}) => {
  const [isOpen, setIsOpen] = React.useState(false)
  const [city_options, setCityOptions] = React.useState([])
  const [town_options, setTownOptions] = React.useState([])

  const [projectsSearchParams, setProjectsSearchParams] = React.useState({
    propertyPrefecture: { value: '', label: '' },
    propertyCity: { value: '', label: '' },
    propertyTown: { value: '', label: '' },
    propertyChome: '',
    propertyMapInformation: '',
    businessTypes: [],
    tags: [],
    sourceUserDepartment: [],
    userDepartments: [],
    sourceUsers: [],
    users: [],
    proposalSourcedFrom: '',
    proposalSourcedTo: '',
    proposalSourcedBetween: '',
    purchaceAppliedAtGteq: '',
    propertyPurchaceAppliedAtLteq: '',
    purchaceContractAtGteq: '',
    propertyPurchaceContractAtLteq: '',
    memoUpdatedFrom: '',
    memoUpdatedTo: '',
    memoUpdatedBetween: '',
    groupType: '',
    sortQuery: '',
    displayOption: 'specify', //案件表示初期値は数指定にする
    displayNum: displayDeffaultNumber.toString(),
  })

  React.useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search)
    const prof = searchParams.get('q[property_prefecture_eq]') || ''
    const city = searchParams.get('q[property_city_eq]') || ''
    const town = searchParams.get('q[property_town_eq]') || ''

    getCity(prefecture_options[prof]?.label ?? '')
    getTown(prefecture_options[prof]?.label ?? '', city)

    const searchAddressListParam = searchParams.get('searchAddressList')
    const initialSearchAddressList = searchAddressListParam
      ? JSON.parse(searchAddressListParam)
      : []
    setAddressList(initialSearchAddressList)

    const sourceUserDepartment =
      searchParams
        .getAll('q[ongoing_proposal_source_user_department_name_eq_any][]')
        .filter((v) => v !== '')
        .map((v) => ({ value: v, label: v })) || []

    const userDepartments =
      searchParams
        .getAll('q[user_department_id_eq_any][]')
        .filter((v) => v !== '')
        .map((v) => department_options.find((d) => d.value.toString() === v)) || []

    const sourceUsers =
      searchParams
        .getAll('q[ongoing_proposal_source_user_id_eq_any][]')
        .filter((v) => v !== '')
        .map((v) => user_options.find((d) => d.value.toString() === v)) || []

    const users =
      searchParams
        .getAll('q[user_id_eq_any][]')
        .filter((v) => v !== '')
        .map((v) => user_options.find((d) => d.value.toString() === v)) || []

    setProjectsSearchParams({
      propertyPrefecture: prefecture_options[prof] || { value: '', label: '' },
      propertyCity: { value: city, label: city },
      propertyTown: { value: town, label: town },
      propertyChome: searchParams.get('q[property_chome_cont]') || '',
      propertyMapInformation: searchParams.get('q[property_map_information_cont]') || '',
      businessTypes: searchParams.getAll('q[business_type_id_eq_any][]') || [],
      tags: searchParams.getAll('q[tag_id_eq_any][]') || [],
      sourceUserDepartment: sourceUserDepartment,
      userDepartments: userDepartments,
      sourceUsers: sourceUsers,
      users: users,
      proposalSourcedFrom: searchParams.get('proposal_sourced_from') || '',
      proposalSourcedTo: searchParams.get('proposal_sourced_to') || '',
      proposalSourcedBetween: searchParams.get('q[proposal_sourced_between]') || '',
      purchaceAppliedAtGteq: searchParams.get('q[purchace_applied_at_gteq]') || '',
      propertyPurchaceAppliedAtLteq: searchParams.get('q[purchace_applied_at_lteq]') || '',
      purchaceContractAtGteq: searchParams.get('q[purchace_contract_at_gteq]') || '',
      propertyPurchaceContractAtLteq: searchParams.get('q[purchace_contract_at_lteq]') || '',
      memoUpdatedFrom: searchParams.get('memo_updated_from') || '',
      memoUpdatedTo: searchParams.get('memo_updated_to') || '',
      memoUpdatedBetween: searchParams.get('q[memo_updated_between]') || '',
      groupType: searchParams.get('type') || '',
      sortQuery: searchParams.get('q[s]') || '',
      displayOption: searchParams.get('display_option') || 'specify', //案件表示初期値は数指定にする
      displayNum: searchParams.get('display_num') || displayDeffaultNumber.toString(),
    })
    if (prefecture_options[prof]?.label) {
      getCity(prefecture_options[prof]?.label)
    }
    if (prefecture_options[prof]?.label && city) {
      getTown(prefecture_options[prof]?.label, city)
    }
  }, [])

  function getCity(prefecture: string) {
    if (prefecture === '') {
      setProjectsSearchParams({
        ...projectsSearchParams,
        propertyCity: { value: '', label: '' },
        propertyTown: { value: '', label: '' },
      })
      setCityOptions([])
      setTownOptions([])
      return
    }

    if (prefecture != '---' && prefecture != '') {
      fetch('/japanese-addresses/ja.json')
        .then((response) => response.json())
        .then((json) => {
          const citys: string[] = json[prefecture]
          const city_options = citys.map((city: string) => {
            const option = { value: city, label: city }
            return option
          })
          setCityOptions(city_options)
        })
        .catch((e) => {
          console.error(e)
          alert('市区町村データの取得に失敗しました。')
        })
    } else {
      setCityOptions([])
      setTownOptions([])
    }
  }

  function getTown(prefecture: string, city: string) {
    if (prefecture !== '' && city !== '') {
      fetch(`/japanese-addresses/ja/${prefecture}/${city}.json`)
        .then((response) => response.json())
        .then((json: TownJson) => {
          const town_options = json.map((town) => {
            const option = { value: town['town'], label: town['town'] }
            return option
          })
          setTownOptions(town_options)
        })
        .catch(() => {
          alert('町名データの取得に失敗しました。')
        })
    } else {
      setTownOptions([])
    }
  }

  const sortOptions = [
    { value: '', text: '（なし）' },
    { value: 'tag_name', text: 'タグ' },
    { value: 'ongoing_proposal_source_user_id', text: '情報入手者' },
    { value: 'user_id', text: '案件担当者' },
    { value: 'ongoing_proposal_sourced_at', text: '入手日（昇順）' },
    { value: 'ongoing_proposal_sourced_at desc', text: '入手日（降順）' },
    { value: 'purchace_applied_at', text: '買付提出日（昇順）' },
    { value: 'purchace_applied_at desc', text: '買付提出日（降順）' },
    { value: 'purchace_contract_at', text: '仕入契約日（昇順）' },
    { value: 'purchace_contract_at desc', text: '仕入契約日（降順）' },
    { value: 'memo_updated_date', text: '進捗メモ更新日（昇順）' },
    { value: 'memo_updated_date desc', text: '進捗メモ更新日（降順）' },
  ]

  const subPage = {
    reset: '/projects_totals',
    business_type: '/projects_business_types',
    tag: '/projects_tags',
    department_name: '/projects_departments',
    user: '/projects_users',
  }

  function closeModal() {
    setIsOpen(false)
  }

  function openModal() {
    setIsOpen(true)
  }

  function onChangePref(value: Option | null) {
    setCityOptions([])
    setTownOptions([])
    if (value === null) {
      setProjectsSearchParams({
        ...projectsSearchParams,
        propertyPrefecture: {
          value: '',
          label: '',
        },
        propertyCity: {
          value: '',
          label: '',
        },
        propertyTown: {
          value: '',
          label: '',
        },
        propertyChome: '',
        propertyMapInformation: '',
      })
    } else {
      setProjectsSearchParams({
        ...projectsSearchParams,
        propertyPrefecture: {
          value: value.value.toString(),
          label: value.label,
        },
      })
      getCity(value && value.label)
    }
  }

  function onChangeCity(value: Option | null) {
    setTownOptions([])

    if (value === null) {
      setProjectsSearchParams({
        ...projectsSearchParams,
        propertyCity: {
          value: '',
          label: '',
        },
        propertyTown: {
          value: '',
          label: '',
        },
        propertyChome: '',
        propertyMapInformation: '',
      })
    } else {
      setProjectsSearchParams({
        ...projectsSearchParams,
        propertyCity: {
          value: value.value.toString(),
          label: value.label,
        },
      })
      getTown(projectsSearchParams.propertyPrefecture.label, value.value.toString())
    }
  }

  function onChangeTown(value: Option | null) {
    setProjectsSearchParams({
      ...projectsSearchParams,
      propertyTown: {
        value: value ? value.value.toString() : '',
        label: value ? value.label : '',
      },
    })
  }

  const [searchAddressList, setAddressList] = React.useState([])

  const addSearchCondition = () => {
    const newCondition = {
      prefecture: projectsSearchParams.propertyPrefecture.label,
      city: projectsSearchParams.propertyCity.label,
      town: projectsSearchParams.propertyTown.label,
      chome: projectsSearchParams.propertyChome,
      mapInfomation: projectsSearchParams.propertyMapInformation,
    }

    const isEmptyCondition = Object.values(newCondition).every((value) => !value)
    if (isEmptyCondition) {
      return
    }

    const updatedSearchAddressList = [...searchAddressList, newCondition]
    setAddressList(updatedSearchAddressList)
    setProjectsSearchParams((prevState) => ({
      ...prevState,
      propertyPrefecture: { value: '', label: '' },
      propertyCity: { value: '', label: '' },
      propertyTown: { value: '', label: '' },
      propertyChome: '',
      propertyMapInformation: '',
    }))
    onChangePref(null)
  }

  const removeSearchCondition = (index) => {
    const updatedList = searchAddressList.filter((_, i) => i !== index)
    setAddressList(updatedList)
  }

  return (
    <>
      <div className="">
        <button type="button" onClick={openModal}>
          <AnchorButton
            className="text-primary-font"
            prefix={<SearchIcon fontSize="small" />}
            outline
            size="small"
            variant="primary"
          >
            <span className="hidden md:inline">絞り込み</span>
          </AnchorButton>
        </button>
      </div>

      <Transition appear show={isOpen} as={React.Fragment}>
        <Dialog className="relative z-10" onClose={closeModal}>
          <Transition.Child
            as={React.Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center text-center">
              <Transition.Child
                as={React.Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-screen md:max-w-[625px] transform overflow-hidden rounded-2xl bg-white text-left align-middle shadow-xl transition-all">
                  <Dialog.Title className="text-base font-medium p-4 leading-6 text-gray-700">
                    {title}
                    <ClearIcon className="float-right cursor-pointer" onClick={closeModal} />
                  </Dialog.Title>
                  <div className="border-t border-gray-150"></div>

                  <form
                    id="proposal_search"
                    action={subPage[sub_tab]}
                    acceptCharset="UTF-8"
                    method="get"
                  >
                    <div className="mt-2">
                      <div className="p-4">
                        <div className="bg-primary text-white text-sm font-medium py-2 px-4">
                          所在地
                        </div>
                        <div className="flex items-center">
                          <div className="text-sm md:text-sm py-5 px-4 text-right whitespace-normal text-black-base font-medium w-1/4">
                            都道府県
                          </div>
                          <div className="py-2 px-4 w-3/4">
                            <DropDown
                              className="text-sm"
                              name="q[property_prefecture_eq]"
                              id="q_property_prefecture_eq"
                              options={prefecture_options}
                              placeholder="---"
                              value={
                                projectsSearchParams.propertyPrefecture?.label !== '' &&
                                projectsSearchParams.propertyPrefecture
                              }
                              onChange={onChangePref}
                            />
                          </div>
                        </div>
                        <div className="flex items-center">
                          <div className="text-sm md:text-sm py-5 px-4 text-right whitespace-normal text-black-base font-medium w-1/4">
                            市区町村
                          </div>
                          <div className="py-2 px-4 w-3/4">
                            <DropDown
                              className="text-sm"
                              name="q[property_city_eq]"
                              id="q_property_city_eq"
                              hasBlank
                              blankLabel="---"
                              options={city_options}
                              value={
                                projectsSearchParams.propertyCity.label !== '' &&
                                projectsSearchParams.propertyCity
                              }
                              onChange={onChangeCity}
                            />
                          </div>
                        </div>
                        <div className="flex items-center">
                          <div className="text-sm md:text-sm py-5 px-4 text-right whitespace-normal text-black-base font-medium w-1/4">
                            町名
                          </div>
                          <div className="py-2 px-4 w-3/4">
                            <DropDown
                              className="text-sm"
                              name="q[property_town_eq]"
                              id="q_property_town_eq"
                              hasBlank
                              blankLabel="---"
                              options={town_options}
                              value={
                                projectsSearchParams.propertyTown.label !== '' &&
                                projectsSearchParams.propertyTown
                              }
                              onChange={onChangeTown}
                            />
                          </div>
                        </div>
                        <div className="flex items-center">
                          <div className="text-sm md:text-sm py-5 px-4 text-right whitespace-normal text-black-base font-medium w-1/4">
                            丁目以降（住居表示）
                          </div>
                          <div className="py-2 px-4 w-3/4">
                            <div className="flex">
                              <Input
                                value={projectsSearchParams.propertyChome}
                                name="q[property_chome_cont]"
                                id="q_property_chome_cont"
                                className={'w-full'}
                                onChange={(e) =>
                                  setProjectsSearchParams({
                                    ...projectsSearchParams,
                                    propertyChome: e.target.value,
                                  })
                                }
                              />
                            </div>
                          </div>
                        </div>
                        <div className="flex items-center">
                          <div className="text-sm md:text-sm py-5 px-4 text-right whitespace-normal text-black-base font-medium w-1/4">
                            建物名
                          </div>
                          <div className="py-2 px-4 w-3/4">
                            <div className="flex">
                              <Input
                                value={projectsSearchParams.propertyMapInformation}
                                name="q[property_map_information_cont]"
                                id="q_property_map_information_cont"
                                className={'w-full'}
                                onChange={(e) =>
                                  setProjectsSearchParams({
                                    ...projectsSearchParams,
                                    propertyMapInformation: e.target.value,
                                  })
                                }
                              />
                            </div>
                          </div>
                        </div>
                        <Button
                          className="w-[100px] mb-2 self-center"
                          size="tiny"
                          type="button"
                          onClick={addSearchCondition}
                        >
                          所在地追加
                        </Button>
                        <div>
                          {searchAddressList.map((condition, index) => (
                            <div className="flex items-center justify-between" key={index}>
                              <div className="text-sm py-2 px-4 text-right whitespace-normal text-[#888] font-medium w-1/4">
                                所在地条件{index + 1}
                              </div>
                              <div className="flex justify-between items-center py-2 px-4 w-3/4">
                                <span className="text-sm text-[#888]">
                                  {condition.prefecture} {condition.city} {condition.town}{' '}
                                  {condition.chome} {condition.mapInfomation}
                                </span>
                                <button
                                  type="button"
                                  className="align-middle text-[#777]"
                                  onClick={() => removeSearchCondition(index)}
                                >
                                  <DeleteOutlined fontSize="small" />
                                </button>
                              </div>
                            </div>
                          ))}
                        </div>
                        <input
                          type="hidden"
                          name="searchAddressList"
                          value={JSON.stringify(searchAddressList)}
                        />
                        <div>
                          <div className="bg-primary text-white text-sm font-medium py-2 px-4">
                            条件
                          </div>
                          <div className="hidden md:block md:text-xs px-4 pt-1 pb-2 whitespace-normal text-primary font-small">
                            ※
                            複数選択項目では、Ctrlキーを押しながらクリックすることで、選択解除や複数選択が可能です。
                          </div>
                          <div className="flex items-center">
                            <div className="text-sm md:text-sm py-5 px-4 text-right whitespace-normal text-black-base font-medium w-1/4">
                              事業形態
                            </div>
                            <div className="py-2 px-4 w-3/4">
                              <Select
                                multiple
                                className="text-sm bg-none"
                                name="q[business_type_id_eq_any][]"
                                id="q_business_type_id_eq_any"
                                options={business_type_options}
                                value={projectsSearchParams.businessTypes}
                                onChange={(e) => {
                                  const values = Array.from(
                                    e.target.selectedOptions,
                                    (option) => option.value
                                  )
                                  setProjectsSearchParams({
                                    ...projectsSearchParams,
                                    businessTypes: values,
                                  })
                                }}
                              />
                            </div>
                          </div>
                          <div className="flex items-center">
                            <div className="text-sm md:text-sm py-5 px-4 text-right whitespace-normal text-black-base font-medium w-1/4">
                              タグ
                            </div>
                            <div className="py-2 px-4 w-3/4">
                              <Select
                                multiple
                                className="text-sm bg-none"
                                name="q[tag_id_eq_any][]"
                                id="q_tag_id_eq_any"
                                options={tag_options}
                                value={projectsSearchParams.tags}
                                onChange={(e) => {
                                  const values = Array.from(
                                    e.target.selectedOptions,
                                    (option) => option.value
                                  )
                                  setProjectsSearchParams({
                                    ...projectsSearchParams,
                                    tags: values,
                                  })
                                }}
                              />
                            </div>
                          </div>
                          <div className="flex items-center">
                            <div className="text-sm md:text-sm py-5 px-4 text-right whitespace-normal text-black-base font-medium w-1/4">
                              情報入手部署
                            </div>
                            <div className="py-2 px-4 w-3/4">
                              <DropDown
                                isMulti
                                className="text-sm bg-none basic-multi-select"
                                name="q[ongoing_proposal_source_user_department_name_eq_any][]"
                                id="q_ongoing_proposal_source_user_department_name_eq_any"
                                options={source_department_options}
                                defaultValue={projectsSearchParams.sourceUserDepartment}
                                onChange={(e) => {
                                  const value = e?.value
                                  let values = [...projectsSearchParams.sourceUserDepartment]
                                  if (values.includes(value)) {
                                    values = values.filter((v) => v !== value)
                                  } else {
                                    values.push(value)
                                  }
                                  setProjectsSearchParams({
                                    ...projectsSearchParams,
                                    sourceUserDepartment: values,
                                  })
                                }}
                              />
                            </div>
                          </div>
                          <div className="flex items-center">
                            <div className="text-sm md:text-sm py-5 px-4 text-right whitespace-normal text-black-base font-medium w-1/4">
                              案件担当部署
                            </div>
                            <div className="py-2 px-4 w-3/4">
                              <DropDown
                                isMulti
                                className="text-sm bg-none"
                                name="q[user_department_id_eq_any][]"
                                id="q_user_department_id_eq_any"
                                options={department_options}
                                defaultValue={projectsSearchParams.userDepartments}
                                onChange={(e) => {
                                  const value = e?.value
                                  let values = [...projectsSearchParams.userDepartments]
                                  if (values.includes(value)) {
                                    values = values.filter((v) => v !== value)
                                  } else {
                                    values.push(value)
                                  }
                                  setProjectsSearchParams({
                                    ...projectsSearchParams,
                                    userDepartments: values,
                                  })
                                }}
                              />
                            </div>
                          </div>
                          <div className="flex items-center">
                            <div className="text-sm md:text-sm py-5 px-4 text-right whitespace-normal text-black-base font-medium w-1/4">
                              情報入手者
                            </div>
                            <div className="py-2 px-4 w-3/4">
                              <DropDown
                                isMulti
                                className="text-sm bg-none"
                                name="q[ongoing_proposal_source_user_id_eq_any][]"
                                id="q_ongoing_proposal_source_user_id_eq_any"
                                options={user_options}
                                defaultValue={projectsSearchParams.sourceUsers}
                                onChange={(e) => {
                                  const value = e?.value
                                  let values = [...projectsSearchParams.sourceUsers]
                                  if (values.includes(value)) {
                                    values = values.filter((v) => v !== value)
                                  } else {
                                    values.push(value)
                                  }
                                  setProjectsSearchParams({
                                    ...projectsSearchParams,
                                    sourceUsers: values,
                                  })
                                }}
                              />
                            </div>
                          </div>
                          <div className="flex items-center">
                            <div className="text-sm md:text-sm py-5 px-4 text-right whitespace-normal text-black-base font-medium w-1/4">
                              案件担当者
                            </div>
                            <div className="py-2 px-4 w-3/4">
                              <DropDown
                                isMulti
                                className="text-sm bg-none"
                                name="q[user_id_eq_any][]"
                                id="q_user_id_eq_any"
                                options={user_options}
                                defaultValue={projectsSearchParams.users}
                                onChange={(e) => {
                                  const value = e?.value
                                  let values = [...projectsSearchParams.users]
                                  if (values.includes(value)) {
                                    values = values.filter((v) => v !== value)
                                  } else {
                                    values.push(value)
                                  }
                                  setProjectsSearchParams({
                                    ...projectsSearchParams,
                                    users: values,
                                  })
                                }}
                              />
                            </div>
                          </div>
                          <div className="flex items-center">
                            <div className="text-sm md:text-sm py-5 px-4 text-right whitespace-normal text-black-base font-medium w-1/4">
                              入手日
                            </div>
                            <div className="py-2 px-4 w-3/4 overflow-x-scroll">
                              <div className="flex items-center">
                                <Input
                                  style={{ width: '190px' }}
                                  value={projectsSearchParams.proposalSourcedFrom}
                                  type="date"
                                  name="proposal_sourced_from"
                                  id="proposal_sourced_from"
                                  onChange={(e) =>
                                    setProjectsSearchParams({
                                      ...projectsSearchParams,
                                      proposalSourcedFrom: e.target.value,
                                      proposalSourcedBetween: `${e.target.value},${projectsSearchParams.proposalSourcedTo}`,
                                    })
                                  }
                                  maxCalendarYear={3}
                                />
                                <span className="mx-2">〜</span>
                                <Input
                                  style={{ width: '190px' }}
                                  value={projectsSearchParams.proposalSourcedTo}
                                  type="date"
                                  name="proposal_sourced_to"
                                  id="proposal_sourced_to"
                                  onChange={(e) =>
                                    setProjectsSearchParams({
                                      ...projectsSearchParams,
                                      proposalSourcedTo: e.target.value,
                                      proposalSourcedBetween: `${projectsSearchParams.proposalSourcedFrom},${e.target.value}`,
                                    })
                                  }
                                  maxCalendarYear={3}
                                />
                                <Input
                                  value={projectsSearchParams.proposalSourcedBetween}
                                  type="hidden"
                                  name="q[proposal_sourced_between]"
                                  id="q_proposal_sourced_between"
                                />
                              </div>
                            </div>
                          </div>
                          <div className="flex items-center">
                            <div className="text-sm md:text-sm py-5 px-4 text-right whitespace-normal text-black-base font-medium w-1/4">
                              買付提出日
                            </div>
                            <div className="py-2 px-4 w-3/4 overflow-x-scroll">
                              <div className="flex items-center">
                                <Input
                                  style={{ width: '190px' }}
                                  value={projectsSearchParams.purchaceAppliedAtGteq}
                                  type="date"
                                  name="q[purchace_applied_at_gteq]"
                                  id="q_purchace_applied_at_gteq"
                                  onChange={(e) =>
                                    setProjectsSearchParams({
                                      ...projectsSearchParams,
                                      purchaceAppliedAtGteq: e.target.value,
                                    })
                                  }
                                  maxCalendarYear={3}
                                />
                                <span className="mx-2">〜</span>
                                <Input
                                  style={{ width: '190px' }}
                                  value={projectsSearchParams.propertyPurchaceAppliedAtLteq}
                                  type="date"
                                  name="q[purchace_applied_at_lteq]"
                                  id="q_purchace_applied_at_lteq"
                                  onChange={(e) =>
                                    setProjectsSearchParams({
                                      ...projectsSearchParams,
                                      propertyPurchaceAppliedAtLteq: e.target.value,
                                    })
                                  }
                                  maxCalendarYear={3}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="flex items-center">
                            <div className="text-sm md:text-sm py-5 px-4 text-right whitespace-normal text-black-base font-medium w-1/4">
                              仕入契約日
                            </div>
                            <div className="py-2 px-4 w-3/4 overflow-x-scroll">
                              <div className="flex items-center">
                                <Input
                                  style={{ width: '190px' }}
                                  value={projectsSearchParams.purchaceContractAtGteq}
                                  type="date"
                                  name="q[purchace_contract_at_gteq]"
                                  id="q_purchace_contract_at_gteq"
                                  onChange={(e) =>
                                    setProjectsSearchParams({
                                      ...projectsSearchParams,
                                      purchaceContractAtGteq: e.target.value,
                                    })
                                  }
                                  maxCalendarYear={3}
                                />
                                <span className="mx-2">〜</span>
                                <Input
                                  style={{ width: '190px' }}
                                  value={projectsSearchParams.propertyPurchaceContractAtLteq}
                                  type="date"
                                  name="q[purchace_contract_at_lteq]"
                                  id="q_purchace_contract_at_lteq"
                                  onChange={(e) =>
                                    setProjectsSearchParams({
                                      ...projectsSearchParams,
                                      propertyPurchaceContractAtLteq: e.target.value,
                                    })
                                  }
                                  maxCalendarYear={3}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="flex items-center">
                            <div className="text-sm md:text-sm py-5 px-4 text-right whitespace-normal text-black-base font-medium w-1/4">
                              進捗メモ更新日
                            </div>
                            <div className="py-2 px-4 w-3/4 overflow-x-scroll">
                              <div className="flex items-center">
                                <Input
                                  style={{ width: '190px' }}
                                  value={projectsSearchParams.memoUpdatedFrom}
                                  type="date"
                                  name="memo_updated_from"
                                  id="memo_updated_from"
                                  onChange={(e) =>
                                    setProjectsSearchParams({
                                      ...projectsSearchParams,
                                      memoUpdatedFrom: e.target.value,
                                      memoUpdatedBetween: `${e.target.value},${projectsSearchParams.memoUpdatedTo}`,
                                    })
                                  }
                                  maxCalendarYear={3}
                                />
                                <span className="mx-2">〜</span>
                                <Input
                                  style={{ width: '190px' }}
                                  value={projectsSearchParams.memoUpdatedTo}
                                  type="date"
                                  name="memo_updated_to"
                                  id="memo_updated_to"
                                  onChange={(e) =>
                                    setProjectsSearchParams({
                                      ...projectsSearchParams,
                                      memoUpdatedTo: e.target.value,
                                      memoUpdatedBetween: `${projectsSearchParams.memoUpdatedFrom},${e.target.value}`,
                                    })
                                  }
                                  maxCalendarYear={3}
                                />
                                <Input
                                  value={projectsSearchParams.memoUpdatedBetween}
                                  type="hidden"
                                  name="q[memo_updated_between]"
                                  id="q_memo_updated_between"
                                />
                                <Input
                                  value={sub_tab}
                                  type="hidden"
                                  name="group_type"
                                  id="group_type"
                                />
                              </div>
                            </div>
                          </div>
                          <div className="flex items-center">
                            <div className="text-sm md:text-sm py-5 px-4 text-right whitespace-normal text-black-base font-medium w-1/4">
                              並べ替え
                            </div>
                            <div className="py-2 px-4 w-3/4">
                              <Select
                                className="text-sm"
                                value={projectsSearchParams.sortQuery}
                                name="q[s]"
                                id="q_s"
                                options={sort_options}
                                onChange={(e) =>
                                  setProjectsSearchParams({
                                    ...projectsSearchParams,
                                    sortQuery: e.target.value,
                                  })
                                }
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="border-t border-gray-150"></div>
                    <div className="flex gap-2 px-6 py-3 justify-end">
                      <AnchorButton
                        className="text-primary-font border-white w-[120px]"
                        prefix={<RefreshIcon fontSize="small" />}
                        outline
                        size="small"
                        variant="primary"
                        href={subPage[sub_tab]}
                      >
                        リセット
                      </AnchorButton>
                      <Button
                        className="text-white w-[120px]"
                        prefix={<SearchIcon fontSize="small" />}
                        size="small"
                        variant="primary"
                      >
                        検索
                      </Button>
                    </div>
                  </form>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  )
}
export default ProjectsModal
