/* tslint:disable */
/* eslint-disable */
//  This file was automatically generated and should not be edited.

export type ModelJobConditionInput = {
  and?: Array< ModelJobConditionInput | null > | null,
  createdAt?: ModelStringInput | null,
  execCount?: ModelIntInput | null,
  file?: ModelStringInput | null,
  format?: ModelStringInput | null,
  groupId?: ModelIntInput | null,
  jobResultId?: ModelIDInput | null,
  not?: ModelJobConditionInput | null,
  or?: Array< ModelJobConditionInput | null > | null,
  status?: ModelStatusInput | null,
};

export type ModelStringInput = {
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
  beginsWith?: string | null,
  between?: Array< string | null > | null,
  contains?: string | null,
  eq?: string | null,
  ge?: string | null,
  gt?: string | null,
  le?: string | null,
  lt?: string | null,
  ne?: string | null,
  notContains?: string | null,
  size?: ModelSizeInput | null,
};

export enum ModelAttributeTypes {
  _null = "_null",
  binary = "binary",
  binarySet = "binarySet",
  bool = "bool",
  list = "list",
  map = "map",
  number = "number",
  numberSet = "numberSet",
  string = "string",
  stringSet = "stringSet",
}


export type ModelSizeInput = {
  between?: Array< number | null > | null,
  eq?: number | null,
  ge?: number | null,
  gt?: number | null,
  le?: number | null,
  lt?: number | null,
  ne?: number | null,
};

export type ModelIntInput = {
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
  between?: Array< number | null > | null,
  eq?: number | null,
  ge?: number | null,
  gt?: number | null,
  le?: number | null,
  lt?: number | null,
  ne?: number | null,
};

export type ModelIDInput = {
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
  beginsWith?: string | null,
  between?: Array< string | null > | null,
  contains?: string | null,
  eq?: string | null,
  ge?: string | null,
  gt?: string | null,
  le?: string | null,
  lt?: string | null,
  ne?: string | null,
  notContains?: string | null,
  size?: ModelSizeInput | null,
};

export type ModelStatusInput = {
  eq?: Status | null,
  ne?: Status | null,
};

export enum Status {
  failed = "failed",
  processing = "processing",
  successed = "successed",
  waiting = "waiting",
}


export type CreateJobInput = {
  createdAt?: string | null,
  execCount?: number | null,
  file: string,
  format: string,
  groupId: number,
  id?: string | null,
  jobResultId?: string | null,
  status: Status,
};

export type Job = {
  __typename: "Job",
  createdAt: string,
  execCount?: number | null,
  file: string,
  format: string,
  groupId: number,
  id: string,
  jobResultId?: string | null,
  result?: Result | null,
  status: Status,
  updatedAt: string,
};

export type Result = {
  __typename: "Result",
  createdAt: string,
  id: string,
  result?: string | null,
  updatedAt: string,
};

export type ModelResultConditionInput = {
  and?: Array< ModelResultConditionInput | null > | null,
  not?: ModelResultConditionInput | null,
  or?: Array< ModelResultConditionInput | null > | null,
  result?: ModelStringInput | null,
};

export type CreateResultInput = {
  id?: string | null,
  result?: string | null,
};

export type DeleteJobInput = {
  id: string,
};

export type DeleteResultInput = {
  id: string,
};

export type UpdateJobInput = {
  createdAt?: string | null,
  execCount?: number | null,
  file?: string | null,
  format?: string | null,
  groupId?: number | null,
  id: string,
  jobResultId?: string | null,
  status?: Status | null,
};

export type UpdateResultInput = {
  id: string,
  result?: string | null,
};

export type ModelJobFilterInput = {
  and?: Array< ModelJobFilterInput | null > | null,
  createdAt?: ModelStringInput | null,
  execCount?: ModelIntInput | null,
  file?: ModelStringInput | null,
  format?: ModelStringInput | null,
  groupId?: ModelIntInput | null,
  id?: ModelIDInput | null,
  jobResultId?: ModelIDInput | null,
  not?: ModelJobFilterInput | null,
  or?: Array< ModelJobFilterInput | null > | null,
  status?: ModelStatusInput | null,
};

export enum ModelSortDirection {
  ASC = "ASC",
  DESC = "DESC",
}


export type ModelJobConnection = {
  __typename: "ModelJobConnection",
  items:  Array<Job | null >,
  nextToken?: string | null,
};

export type ModelJobJobsByGroupIdStatusCompositeKeyConditionInput = {
  beginsWith?: ModelJobJobsByGroupIdStatusCompositeKeyInput | null,
  between?: Array< ModelJobJobsByGroupIdStatusCompositeKeyInput | null > | null,
  eq?: ModelJobJobsByGroupIdStatusCompositeKeyInput | null,
  ge?: ModelJobJobsByGroupIdStatusCompositeKeyInput | null,
  gt?: ModelJobJobsByGroupIdStatusCompositeKeyInput | null,
  le?: ModelJobJobsByGroupIdStatusCompositeKeyInput | null,
  lt?: ModelJobJobsByGroupIdStatusCompositeKeyInput | null,
};

export type ModelJobJobsByGroupIdStatusCompositeKeyInput = {
  createdAt?: string | null,
  status?: Status | null,
};

export type ModelResultFilterInput = {
  and?: Array< ModelResultFilterInput | null > | null,
  id?: ModelIDInput | null,
  not?: ModelResultFilterInput | null,
  or?: Array< ModelResultFilterInput | null > | null,
  result?: ModelStringInput | null,
};

export type ModelResultConnection = {
  __typename: "ModelResultConnection",
  items:  Array<Result | null >,
  nextToken?: string | null,
};

export type ModelSubscriptionJobFilterInput = {
  and?: Array< ModelSubscriptionJobFilterInput | null > | null,
  createdAt?: ModelSubscriptionStringInput | null,
  execCount?: ModelSubscriptionIntInput | null,
  file?: ModelSubscriptionStringInput | null,
  format?: ModelSubscriptionStringInput | null,
  groupId?: ModelSubscriptionIntInput | null,
  id?: ModelSubscriptionIDInput | null,
  or?: Array< ModelSubscriptionJobFilterInput | null > | null,
  status?: ModelSubscriptionStringInput | null,
};

export type ModelSubscriptionStringInput = {
  beginsWith?: string | null,
  between?: Array< string | null > | null,
  contains?: string | null,
  eq?: string | null,
  ge?: string | null,
  gt?: string | null,
  in?: Array< string | null > | null,
  le?: string | null,
  lt?: string | null,
  ne?: string | null,
  notContains?: string | null,
  notIn?: Array< string | null > | null,
};

export type ModelSubscriptionIntInput = {
  between?: Array< number | null > | null,
  eq?: number | null,
  ge?: number | null,
  gt?: number | null,
  in?: Array< number | null > | null,
  le?: number | null,
  lt?: number | null,
  ne?: number | null,
  notIn?: Array< number | null > | null,
};

export type ModelSubscriptionIDInput = {
  beginsWith?: string | null,
  between?: Array< string | null > | null,
  contains?: string | null,
  eq?: string | null,
  ge?: string | null,
  gt?: string | null,
  in?: Array< string | null > | null,
  le?: string | null,
  lt?: string | null,
  ne?: string | null,
  notContains?: string | null,
  notIn?: Array< string | null > | null,
};

export type ModelSubscriptionResultFilterInput = {
  and?: Array< ModelSubscriptionResultFilterInput | null > | null,
  id?: ModelSubscriptionIDInput | null,
  or?: Array< ModelSubscriptionResultFilterInput | null > | null,
  result?: ModelSubscriptionStringInput | null,
};

export type CreateJobMutationVariables = {
  condition?: ModelJobConditionInput | null,
  input: CreateJobInput,
};

export type CreateJobMutation = {
  createJob?:  {
    __typename: "Job",
    createdAt: string,
    execCount?: number | null,
    file: string,
    format: string,
    groupId: number,
    id: string,
    jobResultId?: string | null,
    result?:  {
      __typename: "Result",
      createdAt: string,
      id: string,
      result?: string | null,
      updatedAt: string,
    } | null,
    status: Status,
    updatedAt: string,
  } | null,
};

export type CreateResultMutationVariables = {
  condition?: ModelResultConditionInput | null,
  input: CreateResultInput,
};

export type CreateResultMutation = {
  createResult?:  {
    __typename: "Result",
    createdAt: string,
    id: string,
    result?: string | null,
    updatedAt: string,
  } | null,
};

export type DeleteJobMutationVariables = {
  condition?: ModelJobConditionInput | null,
  input: DeleteJobInput,
};

export type DeleteJobMutation = {
  deleteJob?:  {
    __typename: "Job",
    createdAt: string,
    execCount?: number | null,
    file: string,
    format: string,
    groupId: number,
    id: string,
    jobResultId?: string | null,
    result?:  {
      __typename: "Result",
      createdAt: string,
      id: string,
      result?: string | null,
      updatedAt: string,
    } | null,
    status: Status,
    updatedAt: string,
  } | null,
};

export type DeleteResultMutationVariables = {
  condition?: ModelResultConditionInput | null,
  input: DeleteResultInput,
};

export type DeleteResultMutation = {
  deleteResult?:  {
    __typename: "Result",
    createdAt: string,
    id: string,
    result?: string | null,
    updatedAt: string,
  } | null,
};

export type UpdateJobMutationVariables = {
  condition?: ModelJobConditionInput | null,
  input: UpdateJobInput,
};

export type UpdateJobMutation = {
  updateJob?:  {
    __typename: "Job",
    createdAt: string,
    execCount?: number | null,
    file: string,
    format: string,
    groupId: number,
    id: string,
    jobResultId?: string | null,
    result?:  {
      __typename: "Result",
      createdAt: string,
      id: string,
      result?: string | null,
      updatedAt: string,
    } | null,
    status: Status,
    updatedAt: string,
  } | null,
};

export type UpdateResultMutationVariables = {
  condition?: ModelResultConditionInput | null,
  input: UpdateResultInput,
};

export type UpdateResultMutation = {
  updateResult?:  {
    __typename: "Result",
    createdAt: string,
    id: string,
    result?: string | null,
    updatedAt: string,
  } | null,
};

export type GetJobQueryVariables = {
  id: string,
};

export type GetJobQuery = {
  getJob?:  {
    __typename: "Job",
    createdAt: string,
    execCount?: number | null,
    file: string,
    format: string,
    groupId: number,
    id: string,
    jobResultId?: string | null,
    result?:  {
      __typename: "Result",
      createdAt: string,
      id: string,
      result?: string | null,
      updatedAt: string,
    } | null,
    status: Status,
    updatedAt: string,
  } | null,
};

export type GetResultQueryVariables = {
  id: string,
};

export type GetResultQuery = {
  getResult?:  {
    __typename: "Result",
    createdAt: string,
    id: string,
    result?: string | null,
    updatedAt: string,
  } | null,
};

export type JobsByDateQueryVariables = {
  createdAt: string,
  filter?: ModelJobFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type JobsByDateQuery = {
  jobsByDate?:  {
    __typename: "ModelJobConnection",
    items:  Array< {
      __typename: "Job",
      createdAt: string,
      execCount?: number | null,
      file: string,
      format: string,
      groupId: number,
      id: string,
      jobResultId?: string | null,
      status: Status,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type JobsByGroupIdDateStatusQueryVariables = {
  createdAtStatus?: ModelJobJobsByGroupIdStatusCompositeKeyConditionInput | null,
  filter?: ModelJobFilterInput | null,
  groupId: number,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type JobsByGroupIdDateStatusQuery = {
  jobsByGroupIdDateStatus?:  {
    __typename: "ModelJobConnection",
    items:  Array< {
      __typename: "Job",
      createdAt: string,
      execCount?: number | null,
      file: string,
      format: string,
      groupId: number,
      id: string,
      jobResultId?: string | null,
      status: Status,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListJobsQueryVariables = {
  filter?: ModelJobFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListJobsQuery = {
  listJobs?:  {
    __typename: "ModelJobConnection",
    items:  Array< {
      __typename: "Job",
      createdAt: string,
      execCount?: number | null,
      file: string,
      format: string,
      groupId: number,
      id: string,
      jobResultId?: string | null,
      status: Status,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListResultsQueryVariables = {
  filter?: ModelResultFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListResultsQuery = {
  listResults?:  {
    __typename: "ModelResultConnection",
    items:  Array< {
      __typename: "Result",
      createdAt: string,
      id: string,
      result?: string | null,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type OnCreateJobSubscriptionVariables = {
  filter?: ModelSubscriptionJobFilterInput | null,
};

export type OnCreateJobSubscription = {
  onCreateJob?:  {
    __typename: "Job",
    createdAt: string,
    execCount?: number | null,
    file: string,
    format: string,
    groupId: number,
    id: string,
    jobResultId?: string | null,
    result?:  {
      __typename: "Result",
      createdAt: string,
      id: string,
      result?: string | null,
      updatedAt: string,
    } | null,
    status: Status,
    updatedAt: string,
  } | null,
};

export type OnCreateResultSubscriptionVariables = {
  filter?: ModelSubscriptionResultFilterInput | null,
};

export type OnCreateResultSubscription = {
  onCreateResult?:  {
    __typename: "Result",
    createdAt: string,
    id: string,
    result?: string | null,
    updatedAt: string,
  } | null,
};

export type OnDeleteJobSubscriptionVariables = {
  filter?: ModelSubscriptionJobFilterInput | null,
};

export type OnDeleteJobSubscription = {
  onDeleteJob?:  {
    __typename: "Job",
    createdAt: string,
    execCount?: number | null,
    file: string,
    format: string,
    groupId: number,
    id: string,
    jobResultId?: string | null,
    result?:  {
      __typename: "Result",
      createdAt: string,
      id: string,
      result?: string | null,
      updatedAt: string,
    } | null,
    status: Status,
    updatedAt: string,
  } | null,
};

export type OnDeleteResultSubscriptionVariables = {
  filter?: ModelSubscriptionResultFilterInput | null,
};

export type OnDeleteResultSubscription = {
  onDeleteResult?:  {
    __typename: "Result",
    createdAt: string,
    id: string,
    result?: string | null,
    updatedAt: string,
  } | null,
};

export type OnUpdateJobSubscriptionVariables = {
  filter?: ModelSubscriptionJobFilterInput | null,
};

export type OnUpdateJobSubscription = {
  onUpdateJob?:  {
    __typename: "Job",
    createdAt: string,
    execCount?: number | null,
    file: string,
    format: string,
    groupId: number,
    id: string,
    jobResultId?: string | null,
    result?:  {
      __typename: "Result",
      createdAt: string,
      id: string,
      result?: string | null,
      updatedAt: string,
    } | null,
    status: Status,
    updatedAt: string,
  } | null,
};

export type OnUpdateResultSubscriptionVariables = {
  filter?: ModelSubscriptionResultFilterInput | null,
};

export type OnUpdateResultSubscription = {
  onUpdateResult?:  {
    __typename: "Result",
    createdAt: string,
    id: string,
    result?: string | null,
    updatedAt: string,
  } | null,
};
