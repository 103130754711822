import * as React from 'react'
import Base from '@/components/Layout/Base'
import { Table } from '@/components/Table'
import { Select } from '@/components/Select'
import { Button, AnchorButton } from '@/components/Button'
import { Breadcrumb } from '@/components/Breadcrumb'
import { format, parseISO } from 'date-fns'
import type { User } from '@/types/user'
import type { Property } from '@/types/property'
import type { PropertyDocument } from '@/types/propertyDocument'

type Props = {
  current_user: User
  authenticity_token: string
  property: Property
  error_messages: string[]
  property_document_list?: string[]
}

const PropertyPublicFilesSelectFormatsPage: React.FC<Props> = ({
  current_user,
  authenticity_token,
  property,
  error_messages,
  property_document_list,
}) => {
  const title = '公開ページ'
  const submit = '登録'
  const param = window.location.search
  const property_id = new URLSearchParams(param).get('property_id')
  const [selectedDocumentId, setSelectedDocumentId] = React.useState(property_document_list?.[0].value)

  return (
    <Base
      current_user={current_user}
      title={title}
      breadcrumb={
        <Breadcrumb
          link_texts={['物件管理', title]}
          property_id={property_id}
          tab="property_documents"
        />
      }
      submenuCategory="properties"
    >
      {error_messages.length > 0 && (
        <ul className="mb-6 text-sm text-red">
          {error_messages.map((message, i) => (
            <li key={i} className="mb-2 break-all">
              {message}
            </li>
          ))}
        </ul>
      )}
      <Table className="block h-[calc(100vh_-_182px)] md:h-[calc(100vh_-_195px)]">
        <tbody className="block md:table-row-group">
          <tr className="bg-[#F1F3F4] block md:table-row [&:nth-child(even)]:bg-white">
            <th
              scope="row"
              className="pt-4 pb-2 md:py-5 px-4 text-right whitespace-nowrap text-black-base font-medium w-1/4 block md:table-cell"
            >
              所在地
            </th>
            <td className="py-2 px-4 md:w-3/4 block md:table-cell">
              住所表示：{property.prefecture} {property.city}{' '}
              {property.town} {property.chome}　地番：{property.chiban}
            </td>
          </tr>
          <tr className="bg-[#F1F3F4] block md:table-row [&:nth-child(even)]:bg-white">
            <th
              scope="row"
              className="pt-4 pb-2 md:py-5 px-4 text-right whitespace-nowrap text-black-base font-medium w-1/4 block md:table-cell"
            >
              参照物件概要書フォーマット名
              <span className="ml-2 inline-block border border-red py-0.5 px-1 text-red leading-none text-[10px]">
                必須
              </span>
            </th>
              <td className="py-2 px-4 md:w-3/4 block md:table-cell">
              {property_document_list.length > 0 ?
                <Select
                  className="md:w-[300px]"
                  name="property_document_format_id"
                  id="property_document_format_id"
                  options={property_document_list}
                  onChange={(e) => {
                    setSelectedDocumentId(e.target.value)
                  }}
                />
              : '物件の帳票を作成してください'
              }
              </td>
          </tr>
        </tbody>
      </Table>
      <div className="bg-white flex justify-center items-center w-full left-0 md:gap-4 border-t border-gray-250 pt-2">
        <AnchorButton
          className="w-full md:w-40 md:h-auto text-primary-font border-white"
          outline
          variant="text"
          href={`/properties/${property.hashid}?tab=public_files`}
        >
          キャンセル
        </AnchorButton>
        <AnchorButton 
          className="w-full md:w-40 md:h-auto"
          disabled={property_document_list.length === 0}
          href={`new?property_id=${property.hashid}&property_document_format_id=${selectedDocumentId}`}
        >
          {submit}
        </AnchorButton>
      </div>
    </Base>
  )
}

export default PropertyPublicFilesSelectFormatsPage
