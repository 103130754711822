import * as React from 'react'

interface Props extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {
  id?: string
}

export const Textarea: React.FC<Props> = ({
  id,
  name,
  placeholder = '入力してください',
  required = false,
  className,
  readOnly,
  rows = 4,
  onChange,
  ...props
}) => (
  <textarea
    id={id}
    name={name}
    placeholder={placeholder}
    rows={rows}
    required={required}
    className={[
      styles.textarea,
      className,
      readOnly && 'bg-gray-100 pointer-events-none',
    ].join(' ')}
    onChange={onChange}
    {...props}
  >
    {props.children}
  </textarea>
)

const styles = {
  textarea: `
    appearance-none
    border
    rounded-sm
    bg-white
    outline-none
    w-full
    pt-2
    pb-4
    px-4
    text-sm
    resize-none
    leading-normal
    text-black-base
    border-gray-border
    placeholder-gray-300
    disabled:text-gray-300
    disabled:bg-gray-light
    disabled:cursor-not-allowed
    disabled:opacity-70
  `,
}
