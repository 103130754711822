import * as React from 'react'
import Base from '@/components/Layout/Base'
import { Table } from '@/components/Table'
import { Input } from '@/components/Input'
import { Button, AnchorButton } from '@/components/Button'
import { RadioButton } from '@/components/RadioButton'
import { User } from '@/types/user'
import { Department } from '@/types/department'
import { Breadcrumb } from '@/components/Breadcrumb'
import { propertyStatusOptions, propertyTypeOptions } from '@/models/property'
import { cammedFormat, dataPrecisionSetup } from '@/utils/cammedFormat'

type Props = {
  current_user: User
  instant_registration: Department
  authenticity_token: string
  error_messages: string[]
}

const formatNumber = (value: string) => {
  return value ? cammedFormat(dataPrecisionSetup(value, 12, 2), 2) : value
}

const InstantRegistrationsShowPage: React.FC<Props> = ({
  current_user,
  instant_registration,
  authenticity_token,
  error_messages,
}) => {
  const title = '即時登録設定'

  const [instantRegistration, setInstantRegistration] = React.useState({
    ...instant_registration,
    area_m3: formatNumber(instant_registration['area_m3']),
    occupied_area: formatNumber(instant_registration['occupied_area']),
  })
  const [timer, setTimer] = React.useState(null)

  const updateValue = (key: string, value: string) => {
    const newInstantRegistration = { ...instantRegistration }
    newInstantRegistration[key] = value
    setInstantRegistration(newInstantRegistration)
  }

  const updateAreaValue = (key: string, value: string) => {
    const newInstantRegistration = { ...instantRegistration }
    value = formatNumber(value)
    value = value !== '0.00' ? value : ''

    newInstantRegistration[key] = value
    setInstantRegistration(newInstantRegistration)
  }

  return (
    <Base
      current_user={current_user}
      title={title}
      breadcrumb={<Breadcrumb link_texts={[title]} />}
      submenuCategory="settings"
    >
      <form className="mt-8" action="/instant_registration" acceptCharset="UTF-8" method="post">
        <input type="hidden" name="_method" value="patch" />
        <input type="hidden" name="authenticity_token" value={authenticity_token} />

        {error_messages.length > 0 && (
          <ul className="mb-6 text-sm text-red">
            {error_messages.map((message, i) => (
              <li key={i} className="mb-2 break-all">
                {message}
              </li>
            ))}
          </ul>
        )}
        <div className="relative overflow-x-auto h-[calc(100vh_-_182px)] md:h-[calc(100vh_-_195px)]">
          <table className="w-full">
            <tbody className="block md:table-row-group ">
              <tr className="block md:table-row border-t md:border-b border-gray-150">
                <td className="block md:table-cell py-2 md:px-2 md:w-1/4 md:text-right">
                  <span className="py-[5px] md:py-[7px] px-4 text-sm text-black-base">
                    物件種目
                  </span>
                </td>
                <td className="block md:table-cell p-2 w-full">
                  <div className="flex gap-8 py-2 my-[1.5px]">
                    {propertyTypeOptions.map((item, i) => (
                      <RadioButton
                        key={i}
                        name="instant_registration[property_type]"
                        id={`instant_registration_property_type_${i}`}
                        text={item.text}
                        value={item.value}
                        checked={item.value === instantRegistration.property_type}
                        onChange={(e) => updateValue('property_type', e.target.value)}
                      />
                    ))}
                  </div>
                </td>
              </tr>
              <tr className="block md:table-row border-t md:border-b border-gray-150">
                <td className="block md:table-cell py-2 md:px-2 md:w-1/4 md:text-right">
                  <span className="py-[5px] md:py-[7px] px-4 text-sm text-black-base">
                    敷地面積
                  </span>
                  {(instantRegistration.property_type === '土地＋建物（一棟）' ||
                    instantRegistration.property_type === '土地＋建物（区分）') && (
                    <span className="inline-block border border-grey py-0.5 px-1 text-gray-300 leading-none text-[10px]">
                      任意
                    </span>
                  )}
                </td>
                <td className="block md:table-cell p-2 w-full">
                  <Input
                    className="w-full"
                    name="instant_registration[area_m3]"
                    placeholder="敷地面積を入力してください"
                    value={instantRegistration.area_m3}
                    onChange={(e) =>
                      setInstantRegistration({ ...instantRegistration, area_m3: e.target.value })
                    }
                    onBlur={(e) => updateAreaValue('area_m3', e.target.value)}
                  />
                </td>
              </tr>
              <tr className="block md:table-row border-t md:border-b border-gray-150">
                <td className="block md:table-cell py-2 md:px-2 md:w-1/4 md:text-right">
                  <span className="py-[5px] md:py-[7px] px-4 text-sm text-black-base">
                    建物賃貸可能面積（専有面積）
                  </span>
                  {instantRegistration.property_type === '土地' && (
                    <span className="inline-block border border-grey py-0.5 px-1 text-gray-300 leading-none text-[10px]">
                      任意
                    </span>
                  )}{' '}
                </td>
                <td className="block md:table-cell p-2 w-full">
                  <Input
                    className="w-full"
                    name="instant_registration[occupied_area]"
                    placeholder="建物賃貸可能面積（専有面積）を入力してください"
                    value={instantRegistration.occupied_area}
                    onChange={(e) =>
                      setInstantRegistration({
                        ...instantRegistration,
                        occupied_area: e.target.value,
                      })
                    }
                    onBlur={(e) => updateAreaValue('occupied_area', e.target.value)}
                  />
                </td>
              </tr>
              <tr className="block md:table-row border-t md:border-b border-gray-150">
                <td className="block md:table-cell py-2 md:px-2 md:w-1/4 md:text-right">
                  <span className="py-[5px] md:py-[7px] px-4 text-sm text-black-base">
                    ステータス
                  </span>
                </td>
                <td className="block md:table-cell p-2 w-full">
                  <div className="flex gap-8 py-2 my-[1.5px]">
                    {propertyStatusOptions.map((item, i) => (
                      <RadioButton
                        key={i}
                        name="instant_registration[ongoing]"
                        id={`instant_registration_ongoing_${i}`}
                        text={item.text}
                        value={item.value}
                        checked={item.value === instantRegistration.ongoing}
                        onChange={(e) => updateValue('ongoing', e.target.value)}
                      />
                    ))}
                  </div>
                </td>
              </tr>
              <tr className="block md:table-row border-t border-gray-150">
                <td className="block md:table-cell py-2 md:px-2 md:w-1/4 md:text-right">
                  <span className="py-[5px] md:py-[7px] px-4 text-sm text-black-base">入手先企業</span>
                </td>
                <td className="block md:table-cell p-2 w-full">
                  <Input
                    className="w-full"
                    name="instant_registration[source]"
                    placeholder="入手先企業を入力してください"
                    value={instantRegistration.source}
                    onChange={(e) => updateValue('source', e.target.value)}
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="bg-white flex justify-center items-center w-full left-0 md:gap-4 border-t border-gray-250 pt-2">
          <AnchorButton
            className="w-full md:w-40 md:h-auto text-primary-font border-white"
            variant="text"
            outline
            href="/instant_registration"
          >
            キャンセル
          </AnchorButton>
          <Button className="w-full md:w-40 md:h-auto">編集を保存</Button>
        </div>
      </form>
    </Base>
  )
}

export default InstantRegistrationsShowPage
