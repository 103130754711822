import * as React from 'react'
import Base from '@/components/Layout/Base'
import { Button, AnchorButton } from '@/components/Button'
import { Checkbox } from '@/components/Checkbox'
import { User } from '@/types/user'
import { RequiredItem } from '@/types/requiredItem'
import { Breadcrumb } from '@/components/Breadcrumb'
import Tooltip from '@mui/material/Tooltip'

type Props = {
  current_user: User
  required_items_data: RequiredItem[]
  authenticity_token: string
  error_messages: string[]
}

const RequiredItemsShowPage: React.FC<Props> = ({
  current_user,
  required_items_data,
  authenticity_token,
  error_messages,
}) => {
  const title = '必須項目設定'

  let main_required_items = {}
  required_items_data.forEach((obj) => {
    main_required_items[`${obj.required_item_id}`] = obj.value
  })

  const [checkedMainRequiredItem, setCheckedMainRequiredItem] = React.useState({
    ...main_required_items,
  })

  const updateValue = (item_type: string, key: string, value: boolean) => {
    if (item_type == 'main') {
      const newCheckedMainRequiredItem = { ...checkedMainRequiredItem }
      newCheckedMainRequiredItem[key] = value
      setCheckedMainRequiredItem(newCheckedMainRequiredItem)
    }

    const newRequiredItems = { ...requiredItems }
    newRequiredItems[key] = value
    setRequiredItems(newRequiredItems)
  }

  const required_items = {}
  required_items_data.forEach((obj) => {
    required_items[`${obj.required_item_id}`] = obj.value
    if (obj.items) {
      obj.items.forEach((item) => {
        item.choices.forEach((choice) => {
          required_items[`${choice.required_item_id}`] = choice.value
        })
      })
    }
  })

  const [requiredItems, setRequiredItems] = React.useState({
    ...required_items,
  })

  return (
    <Base
      current_user={current_user}
      title={title}
      breadcrumb={<Breadcrumb link_texts={[title]} />}
      submenuCategory="settings"
    >
      <form className="mt-8" action="/required_items" acceptCharset="UTF-8" method="post">
        <input type="hidden" name="_method" value="patch" />
        <input type="hidden" name="authenticity_token" value={authenticity_token} />

        {error_messages.length > 0 && (
          <ul className="mb-6 text-sm text-red">
            {error_messages.map((message, i) => (
              <li key={i} className="mb-2 break-all">
                {message}
              </li>
            ))}
          </ul>
        )}
        <div className="relative overflow-x-auto h-[calc(100vh_-_182px)] md:h-[calc(100vh_-_195px)]">
          <table className="w-full">
            <tbody className="block md:table-row-group ">
              {required_items_data.map((item, i) => (
                <>
                  <tr className="block md:table-row border-t md:border-b border-gray-150">
                    <td className="block md:table-cell py-2 md:px-2 md:w-1/4 md:text-right">
                      <span className="py-[5px] md:py-[7px] px-4 text-sm text-black-base">
                        <Checkbox
                          key={item.required_item_id}
                          id={`required_item_id_${item.required_item_id}`}
                          name="required_item[required_item_id][]"
                          text={item.name}
                          value={item.required_item_id}
                          checked={requiredItems[item.required_item_id]}
                          onChange={(e) =>
                            updateValue('main', item.required_item_id, e.target.checked)
                          }
                        />
                      </span>
                    </td>
                  </tr>
                  {item.items &&
                    item.items.map((sub_type) => (
                      <>
                        <tr className="block md:table-row">
                          <td className="block md:table-cell p-2">
                            <div className="flex gap-8 py-1 px-5 my-[1.5px]">{sub_type.name}</div>
                          </td>
                          {sub_type.choices.map((choice, ci) => (
                            <>
                              <td className="block md:table-cell p-2">
                                <Checkbox
                                  key={choice.required_item_id}
                                  id={`required_item_id_${choice.required_item_id}`}
                                  name="required_item[required_item_id][]"
                                  text={choice.name}
                                  value={choice.required_item_id}
                                  checked={requiredItems[choice.required_item_id]}
                                  disabled={!checkedMainRequiredItem[item.required_item_id]}
                                  onChange={(e) =>
                                    updateValue(null, choice.required_item_id, e.target.checked)
                                  }
                                />
                              </td>
                            </>
                          ))}
                        </tr>
                      </>
                    ))}
                </>
              ))}
            </tbody>
          </table>
        </div>
        <div className="bg-white flex justify-center items-center w-full left-0 md:gap-4 pt-2">
          <AnchorButton
            className="w-full md:w-40 md:h-auto text-primary-font border-white"
            variant="text"
            outline
            href="/required_items"
          >
            キャンセル
          </AnchorButton>
          <Button className="w-full md:w-40 md:h-auto">編集を保存</Button>
        </div>
      </form>
    </Base>
  )
}

export default RequiredItemsShowPage
