import * as React from "react";
import { FC, memo, useMemo } from 'react';
import { CanvasTransform } from '../../hooks/useCanvasTransform';

export type GridPatternProps = {
  transform?: CanvasTransform;
  divGridStrokeWidth: number;
  divGridUnit: number;
  divGridId: string;
  sectionalGridId: string;
  sectionalGridUnit: number;
  sectionalGridStrokeWidth: number;
  offset?: {
    x: number;
    y: number;
  };
};

const GridPattern: FC<GridPatternProps> = ({
  transform,
  divGridId,
  divGridUnit,
  divGridStrokeWidth,
  sectionalGridId,
  sectionalGridUnit,
  sectionalGridStrokeWidth,
  offset,
}) => {
  const patternTransform = useMemo(() => {
    const { position, scale } = transform ?? {};
    return `translate(${position?.x ?? 0} ${position?.y ?? 0}) scale(${
      scale ?? 1
    })`;
  }, [transform]);

  return (
    <defs>
      <pattern
        id={divGridId}
        patternUnits="userSpaceOnUse"
        width={divGridUnit}
        height={divGridUnit}
      >
        <path
          d={`M ${divGridUnit} 0 L 0 0 0 ${divGridUnit}`}
          fill="none"
          stroke="#999999a0"
          strokeWidth={divGridStrokeWidth}
          strokeOpacity={1.0}
        />
      </pattern>
      <pattern
        id={sectionalGridId}
        patternUnits="userSpaceOnUse"
        width={sectionalGridUnit}
        height={sectionalGridUnit}
        x={offset?.x ?? 0}
        y={offset?.y ?? 0}
        patternTransform={patternTransform}
      >
        <rect
          width={sectionalGridUnit}
          height={sectionalGridUnit}
          stroke="none"
          strokeOpacity={0.0}
          fill={`url(#${divGridId})`}
        />
        <path
          d={`M ${sectionalGridUnit} 0 L 0 0 0 ${sectionalGridUnit}`}
          fill="none"
          fillOpacity={0.0}
          stroke="#222222a0"
          strokeWidth={sectionalGridStrokeWidth}
          strokeOpacity={1.0}
        />
      </pattern>
    </defs>
  );
};

const memoized = memo(GridPattern);

export { memoized as GridPattern };
