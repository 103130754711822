import * as React from 'react'
import { ButtonInner } from './ButtonInner'
import type { BaseProps } from './types'

type ElementProps = Omit<React.ButtonHTMLAttributes<HTMLButtonElement>, keyof BaseProps>

// eslint-disable-next-line react/display-name
export const Button = React.forwardRef<HTMLButtonElement, BaseProps & ElementProps>(
  (
    { children, className, wide, square, outline, variant, size, prefix, suffix, ...props },
    ref
  ) => {
    return (
      <ButtonInner
        as="button"
        className={className}
        wide={wide}
        outline={outline}
        variant={variant}
        size={size}
        prefix={prefix}
        suffix={suffix}
        square={square}
        buttonref={ref}
        {...props}
      >
        {children}
      </ButtonInner>
    )
  }
)
