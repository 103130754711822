// パラメータ：
// - points: 平面座標点数値の配列。形式 [[x1, y1], [x2, y2], [x3, y3], ...]
// - latRef: 物件座標ピンの緯度数値
// - lngRef: 物件座標ピンの経度数値
// - actualDistance: 辺1の実際の長さの数値（メートル）（スケール計算に使用）
//
// 使用例：
// const points = [[100, 100], [140, 100], [140, 140], [100, 140]];  // 平面XY座標数値の配列
// const latRef = Number(propertyLatLng.lat);   // 物件座標ピンの緯度数値
// const lngRef = Number(propertyLatLng.lng);   // 物件座標ピンの経度数値
// const actualDistance = 20;                   // 辺1の実障の長さの数値指定（メートル）
//
// const resultJsonString = convertPointToLatlng(points, latRef, lngRef, actualDistance)
//
// 出力結果例：
// resultJsonString => [{"lat":35.724996,"lng":139.693063},{"lat":35.724996,"lng":139.69328430554984},{"lat":35.725175662234996,"lng":139.69328430554984},{"lat":35.725175662234996,"lng":139.693063}]
//
export const convertPointToLatlng = (
  points: number[][],
  latRef: number,
  lngRef: number,
  actualDistance: number
): string => {
  if (points.length < 3) {
    throw new Error('At least three points are required to render a shape.')
  }
  if (
    !points.every(
      (point) =>
        Array.isArray(point) &&
        point.length === 2 &&
        point.every((coord) => typeof coord === 'number')
    )
  ) {
    throw new Error('All points must be arrays of two numbers.')
  }
  if (typeof latRef !== 'number' || typeof lngRef !== 'number' || isNaN(latRef) || isNaN(lngRef)) {
    throw new Error('Latitude and longitude references must be valid numbers.')
  }
  if (typeof actualDistance !== 'number' || actualDistance <= 0) {
    throw new Error('Actual distance must be a positive number.')
  }

  const xBase = points[0][0]
  const yBase = points[0][1]
  const xSecond = points[1][0]
  const ySecond = points[1][1]

  const pointDistance: number = Math.sqrt(
    Math.pow(xSecond - xBase, 2) + Math.pow(ySecond - yBase, 2)
  )

  if (pointDistance === 0) {
    throw new Error('The distance between the first two points must not be zero.')
  }

  const scale: number = actualDistance / pointDistance
  const latDegreesPerMeter: number = 1 / 111320
  const lngDegreesPerMeter: number = 1 / (111320 * Math.cos((latRef * Math.PI) / 180))

  const coordinates = points.map(([x, y]) => {
    const deltaX: number = (x - xBase) * scale
    const deltaY: number = (y - yBase) * scale
    const deltaLat = deltaY * latDegreesPerMeter
    const deltaLng = deltaX * lngDegreesPerMeter
    const newLat: number = latRef + deltaLat
    const newLng: number = lngRef + deltaLng
    return { lat: newLat, lng: newLng }
  })

  return JSON.stringify(coordinates)
}
