import * as React from 'react'
import { useDragAndDrop } from '@/components/SortableList/useDragAndDrop'
import { ItemTypes } from './ItemTypes'
import { Input } from '@/components/Input'
import { Button, AnchorButton } from '@/components/Button'
import DragIndicatorOutlined from '@material-ui/icons/DragIndicatorOutlined'
import DeleteOutlined from '@material-ui/icons/DeleteOutlined'
import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined'

export interface CardProps {
  id: number
  name: string
  index: number
  moveCard: (dragIndex: number, hoverIndex: number) => void
  dropCard: () => void
  deleteCard: (id: number) => void
  updateCard: (id: number, name: string) => void
}

export const Card: React.FC<CardProps> = ({
  id,
  name,
  index,
  moveCard,
  dropCard,
  deleteCard,
  updateCard,
}) => {
  const [businessTypeName, setBusinessTypeName] = React.useState(name ?? '')
  const [timer, setTimer] = React.useState(null)
  const csrfToken: HTMLMetaElement = document.head.querySelector('meta[name="csrf-token"]')

  const updateBusinessTypeName = React.useCallback(
    (id: number, name: string) => {
      setBusinessTypeName(name)

      clearTimeout(timer)
      const newTimer = setTimeout(() => {
        fetch(`/business_types/${id}`, {
          method: 'PATCH',
          body: JSON.stringify({ name }),
          headers: {
            'Content-Type': 'application/json',
            'X-CSRF-Token': csrfToken.content,
          },
        })
          .then((res) => res.json())
          .then(() => {
            updateCard(id, name)
          })
          .catch(() => {
            location.href =
              '/business_types/index_with_notice?message=更新に失敗しました。同一の名称がすでに存在するようです。'
          })
      }, 500)
      setTimer(newTimer)
    },
    [timer, setTimer]
  )

  const [ref, isDragging, handlerId] = useDragAndDrop(index, moveCard, dropCard)
  const opacity = isDragging ? 0 : 1

  return (
    <tr
      key={id}
      ref={ref}
      style={{ opacity }}
      data-handler-id={handlerId}
      className={['bg-white border-t border-gray-150'].join(' ')}
    >
      <td className="py-2 md:px-2 w-12 md:w-24">
        <Button
          className="text-primary-font cursor-move"
          prefix={<DragIndicatorOutlined fontSize="small" />}
          outline
          size="small"
          variant="primary"
        >
          <span className="text-primary-font hidden md:block">移動</span>
        </Button>
      </td>
      <td className="p-2 w-full flex gap-2 md:gap-4 items-center">
        <Input
          className="w-full"
          placeholder="事業形態を入力してください"
          onChange={(e) => updateBusinessTypeName(id, e.target.value)}
          value={businessTypeName}
        />
        <AnchorButton
          prefix={<SettingsOutlinedIcon fontSize="small" />}
          outline
          size="small"
          variant="primary"
          href={`/business_types/${id}/layout`}
        ></AnchorButton>
        <Button
          className="text-primary-font"
          prefix={<DeleteOutlined fontSize="small" />}
          outline
          size="small"
          variant="primary"
          onClick={() => {
            deleteCard(id)
          }}
        >
          <span className="text-primary-font hidden md:block">削除</span>
        </Button>
      </td>
    </tr>
  )
}
