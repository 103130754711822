import * as React from 'react'
import { FC, useCallback, useMemo } from "react";
import { Vector2 } from 'three';
import { Drawing } from "../Drawing";
import { textReadableDegree } from '../../helpers/DrawingHelper';
import {RAD2DEG} from "three/src/math/MathUtils";

export type SkyFactorProps = {
  skyFactor: Drawing["sky_factor"];
  selected?: number[];
};

const SkyFactor: FC<SkyFactorProps> = ({
  skyFactor,
  selected,
}) => {

  const factors = useMemo(() => {
    if (skyFactor !== undefined) {
      const { factors } = skyFactor;
      return factors.filter((_, i) => {
        return selected?.includes(i) ?? true;
      }).map((f, i) => {
        return {
          points: f.points.map((p) => {
            const { point, ...rest } = p;
            return {
              ...rest,
              position: new Vector2(point[0], point[1])
            };
          })
        };
      });
    }
    return [];
  }, [skyFactor, selected]);

  const formatPercent = useCallback((n: number) => {
    const precision = 1e2;
    return Math.floor(n * precision) / precision;
  }, []);

  const textAngle = useCallback((a: Vector2, b: Vector2) => {
    const dir = new Vector2(b.x - a.x, b.y - a.y);
    return textReadableDegree(dir.normalize().angle() * RAD2DEG + 90)
  }, [])

  return <g name="delFromMap-SkyFactor">
    {
      factors.map((f, i) => {
        let angle = 0;
        if (f.points.length > 1) {
          angle = textAngle(f.points[0].position, f.points[1].position)
        }
        return <g key={`factor-${i}`}>
          {
            f.points.map((p, j) => {
              return <g key={`point-${i}-${j}`}>
                <circle cx={p.position.x} cy={p.position.y} r={100} stroke="black" strokeWidth={20} fill="none"/>
                <text
                  fontSize={120}
                  x={p.position.x}
                  y={p.position.y}
                  textAnchor="left"
                  style={{ fill: 'black' }}
                  alignmentBaseline="after-edge"
                  transform={`rotate(${angle}, ${p.position.x}, ${p.position.y})`}
                >
                  ({j}) P-C: {formatPercent(p.plan_building_percent - p.compatible_building_percent)}%,
                  C: {formatPercent(p.compatible_building_percent)}%, P: {formatPercent(p.plan_building_percent)}%
                </text>
              </g>;
            })
          }
        </g>;
      })
    }
  </g>;
};

export {
  SkyFactor
}