import * as React from 'react';
export const useInputValidator = (elementLength: Number): [
  React.MutableRefObject<React.MutableRefObject<HTMLInputElement>[]>,
    (ref: React.MutableRefObject<HTMLInputElement>, message?: string) => Boolean
  ] => {
  const ref = React.useRef<Array<React.RefObject<HTMLInputElement>>>([])
  Array.from(Array(elementLength)).map(() => ref.current.push(React.createRef<HTMLInputElement>()))

  const [, setRenderControl] = React.useState<Boolean>(true)
  const validate = (ref: React.MutableRefObject<HTMLInputElement>, message?: string): Boolean => {
    // 一個前の実行結果が残ってるのでチェック前に何もない状態にも戻す
    ref.current.setCustomValidity("")
    if (!ref.current.checkValidity()) {
      ref.current.setCustomValidity(message)
    }
    setRenderControl(ref.current.checkValidity())
    return ref.current.checkValidity()
  }

  return [ref, validate]
}