export const wards = {
  千代田区: 13101,
  中央区: 13102,
  港区: 13103,
  新宿区: 13104,
  文京区: 13105,
  台東区: 13106,
  墨田区: 13107,
  江東区: 13108,
  品川区: 13109,
  目黒区: 13110,
  大田区: 13111,
  世田谷区: 13112,
  渋谷区: 13113,
  中野区: 13114,
  杉並区: 13115,
  豊島区: 13116,
  北区: 13117,
  荒川区: 13118,
  板橋区: 13119,
  練馬区: 13120,
  足立区: 13121,
  葛飾区: 13122,
  江戸川区: 13123,
}
