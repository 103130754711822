import * as React from 'react'
import Base from '@/components/Layout/Base'
import { Button, AnchorButton } from '@/components/Button'
import { Checkbox } from '@/components/Checkbox'
import { User } from '@/types/user'
import { DisplayItem } from '@/types/displayItem'
import { RequiredItem } from '@/types/requiredItem'
import { Breadcrumb } from '@/components/Breadcrumb'
import { Table } from '@/components/Table'
import { Row, Td, Th } from '@/components/Page/Properties/Detail/Form'
import Tooltip from '@mui/material/Tooltip'
import type { Property } from '@/types/property'
import { cammedFormat, formatNumber } from '@/utils/cammedFormat'
import { buildingStructureType } from '@/config/langs/building_structure'
import { PropertyBusinessInfomation } from '@/components/Page/Properties/Detail/PropertyBusinessInfomation'
import { PropertyGetInfomation } from '@/components/Page/Properties/Detail/PropertyGetInfomation'
import { PropertyBiddingInfomaion } from '@/components/Page/Properties/Detail/PropertyBiddingInfomaion'



type Props = {
  current_user: User
  property?: Property
  display_item_data: DisplayItem[]
  required_item_data: RequiredItem[]
  authenticity_token: string
  error_messages: string[]
}

const DisplayItemsShowPage: React.FC<Props> = ({
  property,
  current_user,
  display_item_data,
  required_item_data,
  authenticity_token,
  error_messages,
}) => {
  const title = '表示項目設定';
  const [activeInfomationTab, setInfomationTab] = React.useState(0);
  const item_type = (() => {
    if (window.location.href.includes("for_property&land")) {
      return 0;
    } else if (window.location.href.includes("for_property&building")) {
      return 1;
    } else if (window.location.href.includes("for_property&classification")) {
      return 2;
    }
    return 0;
  })();
  const is_get_infomaion_items = display_item_data.filter(item => item.display_infomation_category === "0" && item.item_type == item_type);
  const is_bidding_infomaion_items = display_item_data.filter(item => item.display_infomation_category === "1" && item.item_type == item_type);
  const is_business_infomaion_items = display_item_data.filter(item => item.display_infomation_category === "2" && item.item_type == item_type);
    const initialDisplayItems = {
    0: is_get_infomaion_items.reduce((acc, item) => ({ ...acc, [item.display_item_id]: item.value }), {}),
    1: is_bidding_infomaion_items.reduce((acc, item) => ({ ...acc, [item.display_item_id]: item.value }), {}),
    2: is_business_infomaion_items.reduce((acc, item) => ({ ...acc, [item.display_item_id]: item.value }), {}),
  };
  const [tabDisplayItems, setTabDisplayItems] = React.useState(initialDisplayItems);
  const updateValue = (tabIndex, name, value, item_type) => {
    const matched_item = display_item_data.find(item => item.name === name && item.item_type === item_type);
    const key = matched_item ? matched_item.display_item_id : null;
    if (tabIndex === 2 && name === "売主希望総額" && value === false){
      const confirm_answer = confirm("「売主希望総額」をOFFにすると\n「売主希望坪単価」および「売主希望一種単価」の選択が\n自動でOFFに切り替わります。よろしいですか？")
      if (confirm_answer){
        setTabDisplayItems(prevState => {
          const newState = {
            ...prevState,[tabIndex]: {
              ...prevState[tabIndex], [key]: value,
            },
          };
          const matched_alignment_first_item = display_item_data.find(item => item.name === "売主希望坪単価" && item.item_type === item_type);
          const matched_alignment_second_item = display_item_data.find(item => item.name === "売主希望一種単価" && item.item_type === item_type);
          newState[tabIndex][matched_alignment_first_item.display_item_id] = newState[tabIndex][matched_alignment_second_item.display_item_id] = false;
          return newState;
        });
      }
    } else if (tabIndex === 2 && name === "売主希望総額" && value === true){
      const confirm_answer = confirm("「売主希望総額」をONにすると\n「売主希望坪単価」および「売主希望一種単価」の選択が\n自動でONに切り替わります。よろしいですか？")
      if (confirm_answer){
        setTabDisplayItems(prevState => {
          const newState = {
            ...prevState,[tabIndex]: {
              ...prevState[tabIndex], [key]: value,
            },
          };
          const matched_alignment_first_item = display_item_data.find(item => item.name === "売主希望坪単価" && item.item_type === item_type);
          const matched_alignment_second_item = display_item_data.find(item => item.name === "売主希望一種単価" && item.item_type === item_type);
          console.log("Updated State:", newState);
          newState[tabIndex][matched_alignment_first_item.display_item_id] = newState[tabIndex][matched_alignment_second_item.display_item_id] = true;
          return newState;
        });
      }
    } else if (tabIndex === 2 && name === "買取金額" && value === false){
      const confirm_answer = confirm("「買取金額」をOFFにすると\n「買取坪単価」および「買取一種単価」の選択が\n自動でOFFに切り替わります。よろしいですか？")
      if (confirm_answer){
        setTabDisplayItems(prevState => {
          const newState = {
            ...prevState,[tabIndex]: {
              ...prevState[tabIndex],
              [key]: value,
            },
          };
          const matched_alignment_first_item = display_item_data.find(item => item.name === "買取坪単価" && item.item_type === item_type);
          const matched_alignment_second_item = display_item_data.find(item => item.name === "買取一種単価" && item.item_type === item_type);

          newState[tabIndex][matched_alignment_first_item.display_item_id] = newState[tabIndex][matched_alignment_second_item.display_item_id] = false;
          return newState;
        });
      }
    } else if (tabIndex === 2 && name === "買取金額" && value === true){
      const confirm_answer = confirm("「買取金額」をONにすると\n「買取坪単価」および「買取一種単価」の選択が\n自動でONに切り替わります。よろしいですか？")
      if (confirm_answer){
        setTabDisplayItems(prevState => {
          const newState = {
            ...prevState,[tabIndex]: {
              ...prevState[tabIndex],
              [key]: value,
            },
          };
          const matched_alignment_first_item = display_item_data.find(item => item.name === "買取坪単価" && item.item_type === item_type);
          const matched_alignment_second_item = display_item_data.find(item => item.name === "買取一種単価" && item.item_type === item_type);

          newState[tabIndex][matched_alignment_first_item.display_item_id] = newState[tabIndex][matched_alignment_second_item.display_item_id] = true;
          return newState;
        });
      }
    } else if (tabIndex === 2 && name === "現況賃料（税込）/ 月額" && value === false){
      const confirm_answer = confirm("「現況賃料（税込）/ 月額」をOFFにすると\n「現況表面利回り」の選択が\n自動でOFFに切り替わります。よろしいですか？")
      if (confirm_answer){
        setTabDisplayItems(prevState => {
          const newState = {
            ...prevState,[tabIndex]: {
              ...prevState[tabIndex],
              [key]: value,
            },
          };
          const matched_alignment_first_item = display_item_data.find(item => item.name === "現況表面利回り" && item.item_type === item_type);
          newState[tabIndex][matched_alignment_first_item.display_item_id] = false;
          return newState;
        });
      }
    } else if (tabIndex === 2 && name === "現況賃料（税込）/ 月額" && value === true){
      const confirm_answer = confirm("「現況賃料（税込）/ 月額」をONにすると\n「現況表面利回り」の選択が\n自動でONに切り替わります。よろしいですか？")
      if (confirm_answer){
        setTabDisplayItems(prevState => {
          const newState = {
            ...prevState,[tabIndex]: {
              ...prevState[tabIndex],
              [key]: value,
            },
          };
          const matched_alignment_first_item = display_item_data.find(item => item.name === "現況表面利回り" && item.item_type === item_type);
          newState[tabIndex][matched_alignment_first_item.display_item_id] = true;
          return newState;
        });
      }
    } else if (tabIndex === 2 && name === "現況NOI / 月額" && value === false){
      const confirm_answer = confirm("「現況NOI / 月額」をOFFにすると\n「現況NOI利回り」の選択が\n自動でOFFに切り替わります。よろしいですか？")
      if (confirm_answer){
        setTabDisplayItems(prevState => {
          const newState = {
            ...prevState,[tabIndex]: {
              ...prevState[tabIndex],
              [key]: value,
            },
          };
          const matched_alignment_first_item = display_item_data.find(item => item.name === "現況NOI利回り" && item.item_type === item_type);
          newState[tabIndex][matched_alignment_first_item.display_item_id] = false;
          return newState;
        });
      }
    } else if (tabIndex === 2 && name === "現況NOI / 月額" && value === true){
      const confirm_answer = confirm("「現況NOI / 月額」をONにすると\n「現況NOI利回り」の選択が\n自動でONに切り替わります。よろしいですか？")
      if (confirm_answer){
        setTabDisplayItems(prevState => {
          const newState = {
            ...prevState,[tabIndex]: {
              ...prevState[tabIndex],
              [key]: value,
            },
          };
          const matched_alignment_first_item = display_item_data.find(item => item.name === "現況NOI利回り" && item.item_type === item_type);
          newState[tabIndex][matched_alignment_first_item.display_item_id] = true;
          return newState;
        });
      }
    } else if (tabIndex === 2 && name === "戸数" && value === false){
      const confirm_answer = confirm("「戸数」をOFFにすると\n「仕入戸当たり単価」および「仕入延床坪単価」の選択が\n自動でOFFに切り替わります。よろしいですか？")
      if (confirm_answer){
        setTabDisplayItems(prevState => {
          const newState = {
            ...prevState,[tabIndex]: {
              ...prevState[tabIndex],
              [key]: value,
            },
          };
          const matched_alignment_first_item = display_item_data.find(item => item.name === "仕入戸当たり単価" && item.item_type === item_type);
          const matched_alignment_second_item = display_item_data.find(item => item.name === "仕入延床坪単価" && item.item_type === item_type);

          newState[tabIndex][matched_alignment_first_item.display_item_id] = newState[tabIndex][matched_alignment_second_item.display_item_id] = false;
          return newState;
        });
      }
    } else if (tabIndex === 2 && name === "戸数" && value === true){
      const confirm_answer = confirm("「戸数」をONにすると\n「仕入戸当たり単価」及び「仕入延床坪単価」の選択が\n自動でONに切り替わります。よろしいですか？")
      if (confirm_answer){
        setTabDisplayItems(prevState => {
          const newState = {
            ...prevState,[tabIndex]: {
              ...prevState[tabIndex],
              [key]: value,
            },
          };
          const matched_alignment_first_item = display_item_data.find(item => item.name === "仕入戸当たり単価" && item.item_type === item_type);
          const matched_alignment_second_item = display_item_data.find(item => item.name === "仕入延床坪単価" && item.item_type === item_type);

          newState[tabIndex][matched_alignment_first_item.display_item_id] = newState[tabIndex][matched_alignment_second_item.display_item_id] = true;
          return newState;
        });
      }
    } else if (tabIndex === 2 && name === "販売建物価格" && value === false){
      const confirm_answer = confirm("「販売建物価格」をOFFにすると\n「税抜売上高」及び「税込売上高」の選択が\n自動でOFFに切り替わります。よろしいですか？")
      if (confirm_answer){
        setTabDisplayItems(prevState => {
          const newState = {
            ...prevState,[tabIndex]: {
              ...prevState[tabIndex],
              [key]: value,
            },
          };
          const matched_alignment_first_item = display_item_data.find(item => item.name === "税抜売上高" && item.item_type === item_type);
          const matched_alignment_second_item = display_item_data.find(item => item.name === "税込売上高" && item.item_type === item_type);
          const matched_alignment_third_item = display_item_data.find(item => item.name === "販売坪単価" && item.item_type === item_type);
          const matched_alignment_four_item = display_item_data.find(item => item.name === "販売一種単価" && item.item_type === item_type);
          newState[tabIndex][matched_alignment_first_item.display_item_id] = newState[tabIndex][matched_alignment_second_item.display_item_id] = newState[tabIndex][matched_alignment_third_item.display_item_id] = newState[tabIndex][matched_alignment_four_item.display_item_id] = false;
          return newState;
        });
      }
    } else if (tabIndex === 2 && name === "販売建物価格" && value === true){
      const confirm_answer = confirm("「販売建物価格」をONにすると\n「税抜売上高」及び「税込売上高」の選択が\n自動でONに切り替わります。よろしいですか？")
      if (confirm_answer){
        setTabDisplayItems(prevState => {
          const newState = {
            ...prevState,[tabIndex]: {
              ...prevState[tabIndex], [key]: value,
            },
          };
          const matched_alignment_first_item = display_item_data.find(item => item.name === "税抜売上高" && item.item_type === item_type);
          const matched_alignment_second_item = display_item_data.find(item => item.name === "税込売上高" && item.item_type === item_type);
          const matched_alignment_third_item = display_item_data.find(item => item.name === "販売坪単価" && item.item_type === item_type);
          const matched_alignment_four_item = display_item_data.find(item => item.name === "販売一種単価" && item.item_type === item_type);
          newState[tabIndex][matched_alignment_first_item.display_item_id] = newState[tabIndex][matched_alignment_second_item.display_item_id] = newState[tabIndex][matched_alignment_third_item.display_item_id] = newState[tabIndex][matched_alignment_four_item.display_item_id] = true;
          return newState;
        });
      }
    } else if (tabIndex === 2 && name === "事業収支" && value === false){
      const confirm_answer = confirm("「事業収支」をOFFにすると\n「事業収支率」の選択が\n自動でOFFに切り替わります。よろしいですか？")
      if (confirm_answer){
        setTabDisplayItems(prevState => {
          const newState = {
            ...prevState,[tabIndex]: {
              ...prevState[tabIndex], [key]: value,
            },
          };
          const matched_alignment_first_item = display_item_data.find(item => item.name === "事業収支率" && item.item_type === item_type);
          newState[tabIndex][matched_alignment_first_item.display_item_id] = false;
          return newState;
        });
      }
    } else if (tabIndex === 2 && name === "事業収支" && value === true){
      const confirm_answer = confirm("「事業収支」をONにすると\n「事業収支率」の選択が\n自動でONに切り替わります。よろしいですか？")
      if (confirm_answer){
        setTabDisplayItems(prevState => {
          const newState = {
            ...prevState,[tabIndex]: {
              ...prevState[tabIndex], [key]: value,
            },
          };
          const matched_alignment_first_item = display_item_data.find(item => item.name === "事業収支率" && item.item_type === item_type);
          newState[tabIndex][matched_alignment_first_item.display_item_id] = true;

          return newState;
        });
      }
    } else if (tabIndex === 2 && name === "総額賃料（税込）/ 月額" && value === false){
      const confirm_answer = confirm("「総額賃料（税込）/ 月額」をOFFにすると\n「販売表面利回り」の選択が\n自動でOFFに切り替わります。よろしいですか？")
      if (confirm_answer){
        setTabDisplayItems(prevState => {
          const newState = {
            ...prevState,[tabIndex]: {
              ...prevState[tabIndex], [key]: value,
            },
          };
          const matched_alignment_first_item = display_item_data.find(item => item.name === "販売表面利回り" && item.item_type === item_type);
          newState[tabIndex][matched_alignment_first_item.display_item_id] = false;
          return newState;
        });
      }
    } else if (tabIndex === 2 && name === "総額賃料（税込）/ 月額" && value === true){
      const confirm_answer = confirm("「総額賃料（税込）/ 月額」をONにすると\n「販売表面利回り」の選択が\n自動でONに切り替わります。よろしいですか？")
      if (confirm_answer){
        setTabDisplayItems(prevState => {
          const newState = {
            ...prevState,[tabIndex]: {
              ...prevState[tabIndex],
              [key]: value,
            },
          };
          const matched_alignment_first_item = display_item_data.find(item => item.name === "販売表面利回り" && item.item_type === item_type);
          newState[tabIndex][matched_alignment_first_item.display_item_id] = true;
          return newState;
        });
      }
    } else if (tabIndex === 2 && name === "想定NOI（税込）/ 月額" && value === false){
      const confirm_answer = confirm("「想定NOI（税込）/ 月額」をOFFにすると\n「販売想定NOI利回り」の選択が\n自動でOFFに切り替わります。よろしいですか？")
      if (confirm_answer){
        setTabDisplayItems(prevState => {
          const newState = {
            ...prevState,[tabIndex]: {
              ...prevState[tabIndex],
              [key]: value,
            },
          };
          const matched_alignment_first_item = display_item_data.find(item => item.name === "販売想定NOI利回り" && item.item_type === item_type);
          newState[tabIndex][matched_alignment_first_item.display_item_id] = false;
          return newState;
        });
      }
    } else if (tabIndex === 2 && name === "想定NOI（税込）/ 月額" && value === true){
      const confirm_answer = confirm("「想定NOI（税込）/ 月額」をONにすると\n「販売想定NOI利回り」の選択が\n自動でONに切り替わります。よろしいですか？")
      if (confirm_answer){
        setTabDisplayItems(prevState => {

          const newState = {
            ...prevState,[tabIndex]: {
              ...prevState[tabIndex],
              [key]: value,
            },
          };
          const matched_alignment_first_item = display_item_data.find(item => item.name === "販売想定NOI利回り" && item.item_type === item_type);
          newState[tabIndex][matched_alignment_first_item.display_item_id] = true;
          return newState;
        });
      }
    } else if (tabIndex === 2 && name === "本体工事費" && value === false){
      const confirm_answer = confirm("「本体工事費」をOFFにすると\n「工事費坪単価」の選択が\n自動でOFFに切り替わります。よろしいですか？")
      if (confirm_answer){
        setTabDisplayItems(prevState => {
          const newState = {
            ...prevState,[tabIndex]: {
              ...prevState[tabIndex], [key]: value,
            },
          };
          const matched_alignment_first_item = display_item_data.find(item => item.name === "工事費坪単価" && item.item_type === item_type);
          newState[tabIndex][matched_alignment_first_item.display_item_id] = false;
          return newState;
        });
      }
    } else if (tabIndex === 2 && name === "本体工事費" && value === true){
      const confirm_answer = confirm("「本体工事費」をONにすると\n「工事費坪単価」の選択が\n自動でONに切り替わります。よろしいですか？")
      if (confirm_answer){
        setTabDisplayItems(prevState => {
          const newState = {
            ...prevState,[tabIndex]: {
              ...prevState[tabIndex],
              [key]: value,
            },
          };
          const matched_alignment_first_item = display_item_data.find(item => item.name === "工事費坪単価" && item.item_type === item_type);
          newState[tabIndex][matched_alignment_first_item.display_item_id] = true;
          return newState;
        });
      }
    } else {
      setTabDisplayItems(prevState => {
        const newState = {
          ...prevState,
          [tabIndex]: {
            ...prevState[tabIndex],
            [key]: value,
          },
        };

        if(tabIndex === 0 && name === "入手先" && value === false && isRequired("入手先企業")){
          newState[tabIndex][key] = true;
          alert("必須項目のため入手先企業の選択は外せません")
        }
        if(tabIndex === 2 && name === "事業形態" && value === false && isRequired("事業形態")){
          newState[tabIndex][key] = true;
          alert("必須項目のため事業形態の選択は外せません")
        }

        const suggested_total_price_data = display_item_data.find(item => item.name === "売主希望総額" && item.item_type === item_type);
        const purchase_price_data = display_item_data.find(item => item.name === "買取金額" && item.item_type === item_type);
        const current_rent_data = display_item_data.find(item => item.name === "現況賃料（税込）/ 月額" && item.item_type === item_type);
        const net_income_data = display_item_data.find(item => item.name === "現況NOI / 月額" && item.item_type === item_type);
        const number_of_units_data = display_item_data.find(item => item.name === "戸数" && item.item_type === item_type);
        const building_price_data = display_item_data.find(item => item.name === "販売建物価格" && item.item_type === item_type);
        const business_income_data = display_item_data.find(item => item.name === "事業収支" && item.item_type === item_type);
        const total_rent_data = display_item_data.find(item => item.name === "総額賃料（税込）/ 月額" && item.item_type === item_type);
        const prospective_noi_data = display_item_data.find(item => item.name === "想定NOI（税込）/ 月額" && item.item_type === item_type);
        const building_construction_cost_data = display_item_data.find(item => item.name === "本体工事費" && item.item_type === item_type);

        if (tabIndex === 2 && name === "売主希望坪単価" && value === true && newState[tabIndex][suggested_total_price_data.display_item_id] === false) {
          newState[tabIndex][key] = false;
        }
        if (tabIndex === 2 && name === "売主希望一種単価" && value === true && newState[tabIndex][suggested_total_price_data.display_item_id] === false) {
          newState[tabIndex][key] = false;
        }
        if (tabIndex === 2 && name === "買取坪単価" && value === true && newState[tabIndex][purchase_price_data.display_item_id] === false) {
          newState[tabIndex][key] = false;
        }
        if (tabIndex === 2 && name === "買取一種単価" && value === true && newState[tabIndex][purchase_price_data.display_item_id] === false) {
          newState[tabIndex][key] = false;
        }
        if (tabIndex === 2 && name === "現況表面利回り" && value === true && newState[tabIndex][current_rent_data.display_item_id] === false) {
          newState[tabIndex][key] = false;
        }
        if (tabIndex === 2 && name === "現況NOI利回り" && value === true && newState[tabIndex][net_income_data.display_item_id] === false) {
          newState[tabIndex][key] = false;
        }
        if (tabIndex === 2 && name === "仕入戸当たり単価" && value === true && newState[tabIndex][number_of_units_data.display_item_id] === false) {
          newState[tabIndex][key] = false;
        }
        if (tabIndex === 2 && name === "仕入延床坪単価" && value === true && newState[tabIndex][number_of_units_data.display_item_id] === false) {
          newState[tabIndex][key] = false;
        }
        if (tabIndex === 2 && name === "税抜売上高" && value === true && newState[tabIndex][building_price_data.display_item_id] === false) {
          newState[tabIndex][key] = false;
        }
        if (tabIndex === 2 && name === "税込売上高" && value === true && newState[tabIndex][building_price_data.display_item_id] === false) {
          newState[tabIndex][key] = false;
        }
        if (tabIndex === 2 && name === "販売坪単価" && value === true && newState[tabIndex][building_price_data.display_item_id] === false) {
          newState[tabIndex][key] = false;
        }
        if (tabIndex === 2 && name === "販売一種単価" && value === true && newState[tabIndex][building_price_data.display_item_id] === false) {
          newState[tabIndex][key] = false;
        }
        if (tabIndex === 2 && name === "事業収支率" && value === true && newState[tabIndex][business_income_data.display_item_id] === false) {
          newState[tabIndex][key] = false;
        }
        if (tabIndex === 2 && name === "販売表面利回り" && value === true && newState[tabIndex][total_rent_data.display_item_id] === false) {
          newState[tabIndex][key] = false;
        }
        if (tabIndex === 2 && name === "販売想定NOI利回り" && value === true && newState[tabIndex][prospective_noi_data.display_item_id] === false) {
          newState[tabIndex][key] = false;
        }
        if (tabIndex === 2 && name === "工事費坪単価" && value === true && newState[tabIndex][building_construction_cost_data.display_item_id] === false) {
          newState[tabIndex][key] = false;
        }
        return newState;
      })
    }

    const isRequired = (targetName) => {
      const item = required_item_data.find(i => i.name === targetName);
      console.log(item)
      if (!item) {
        return true
      }
      return item.value
    }
  }
  return (
    <Base
      current_user={current_user}
      title={title}
      breadcrumb={<Breadcrumb link_texts={[title]} />}
      submenuCategory="settings"
    >
      <h1 className={`${window.location.href.includes("for_property&land") ? "mt-5 font-bold" : "hidden"}`}>土地</h1>
      <h1 className={`${window.location.href.includes("for_property&building") ? "mt-5 font-bold" : "hidden"}`}>土地＋建物（一棟）</h1>
      <h1 className={`${window.location.href.includes("for_property&classification") ? "mt-5 font-bold" : "hidden"}`}>土地＋建物（区分）</h1>
      {error_messages.length > 0 && (
        <ul className="mb-6 text-sm text-red">
          {error_messages.map((message, i) => (
            <li key={i} className="mb-2 break-all">
              {message}
            </li>
          ))}
        </ul>
      )}
      <div className="flex mt-6">
        <button
          className={`inline-flex outline-none select-none text-sm py-2 px-8 border border-gray-300 ${
            activeInfomationTab === 0 ? "bg-primary text-white border-primary" : "bg-white text-primary"
          }`}
          onClick={() => setInfomationTab(0)}
        >
          入手情報
        </button>
        {/* <button
          className={`inline-flex outline-none select-none text-sm py-2 px-8 border border-gray-300 ${
            activeInfomationTab === 1 ? "bg-primary text-white border-primary" : "bg-white text-primary"
          }`}
          onClick={() => setInfomationTab(1)}
        >
          入札情報
        </button> */}
        <button
          className={`inline-flex outline-none select-none text-sm py-2 px-8 border border-gray-300 ${
            activeInfomationTab === 2 ? "bg-primary text-white border-primary" : "bg-white text-primary"
          }`}
          onClick={() => setInfomationTab(2)}
        >
          事業情報
        </button>
      </div>
      <div className='xl:flex'>
        <form className="mt-8 xl:w-1/2" action="/display_items" acceptCharset="UTF-8" method="post">
          <input type="hidden" name="_method" value="patch" />
          <input type="hidden" name="authenticity_token" value={authenticity_token} />
          <input type="hidden" name="item_type" value={item_type} />

          <Table
            className={
              window.innerHeight > 920
                ? 'h-[500px] md:h-[500px]'
                : 'h-[calc(85vh_-_182px)] md:h-[calc(85vh_-_195px)]'
            }
            borderLine={false}
            topScrollbar={true}
          >
            <thead className="bg-gray-150 text-sm sticky top-1" style={{ zIndex: 100 }}>
              <tr className="whitespace-nowrap py-2">
                <th scope="col" className="py-2 px-4 font-medium">No.</th>
                <th scope="col" className="py-2 px-4 font-medium">画面名</th>
                <th scope="col" className="py-2 px-4 font-medium">カラム名</th>
                <th scope="col" className="py-2 px-4 w-1/12 font-medium">ON/OFF</th>
              </tr>
            </thead>
            <tbody className="whitespace-nowrap">
              {is_get_infomaion_items.map((item, i) => (
                <tr
                  key={i}
                  className={`${i % 2 ? 'bg-gray-light' : 'bg-white'} hover:bg-targetLine cursor-pointer ${activeInfomationTab !== 0 ? 'hidden' : ''}`}
                >
                  <td className="table-cell px-8 w-1/12">{i + 1}</td>
                  <td className="table-cell px-4 w-1/12">{item.display_name}</td>
                  <td className="table-cell px-4 w-1/2">{item.name}</td>
                  <td className="table-cell px-4 w-1/12">
                    <Checkbox
                      key={item.display_item_id}
                      id={`display_item_id${item.display_item_id}`}
                      name="display_item[display_item_id][]"
                      value={item.display_item_id}
                      checked={tabDisplayItems[0][item.display_item_id] || false}
                      className="py-2 px-4"
                      onChange={(e) => updateValue(0, item.name, e.target.checked,item.item_type)}
                    />
                  </td>
                </tr>
              ))}
              {is_bidding_infomaion_items.map((item, i) => (
                <tr
                  key={i}
                  className={`${i % 2 ? 'bg-gray-light' : 'bg-white'} hover:bg-targetLine cursor-pointer ${activeInfomationTab !== 1 ? 'hidden' : ''}`}
                >
                  <td className="table-cell px-8 w-1/12">{i + 1}</td>
                  <td className="table-cell px-4 w-1/12">{item.display_name}</td>
                  <td className="table-cell px-4 w-1/2">{item.name}</td>
                  <td className="table-cell px-4 w-1/12">
                    <Checkbox
                      key={item.display_item_id}
                      id={`display_item_id${item.display_item_id}`}
                      name="display_item[display_item_id][]"
                      value={item.display_item_id}
                      checked={tabDisplayItems[1][item.display_item_id] || false}
                      className="py-2 px-4"
                      onChange={(e) => updateValue(1, item.name, e.target.checked, item.item_type)}
                    />
                  </td>
                </tr>
              ))}
              {is_business_infomaion_items.map((item, i) => (
                <tr
                  key={i}
                  className={`${i % 2 ? 'bg-gray-light' : 'bg-white'} hover:bg-targetLine cursor-pointer ${activeInfomationTab !== 2 ? 'hidden' : ''}`}
                >
                  <td className="table-cell px-8 w-1/12">{i + 1}</td>
                  <td className="table-cell px-4 w-1/12">{item.display_name}</td>
                  <td className="table-cell px-4 w-1/2">{item.name}</td>
                  <td className="table-cell px-4 w-1/12">
                    <Checkbox
                      key={item.display_item_id}
                      id={`display_item_id${item.display_item_id}`}
                      name="display_item[display_item_id][]"
                      value={item.display_item_id}
                      checked={tabDisplayItems[2][item.display_item_id] || false}
                      className="py-2 px-4"
                      onChange={(e) => updateValue(2, item.name, e.target.checked, item.item_type)}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          <div className="bg-white flex justify-center items-center w-full left-0 md:gap-4 pt-2">
            <AnchorButton
              className="w-full md:w-40 md:h-auto text-primary-font border-white"
              variant="text"
              outline
              href="/display_items"
            >
              キャンセル
            </AnchorButton>
            <Button className="w-full md:w-40 md:h-auto">編集を保存</Button>
          </div>
        </form>
        <div className={` mt-8 xl:w-1/2 ${activeInfomationTab !== 0 ? 'hidden' : ''}`} style={{ marginLeft: '1%' }}>
        <p className='text-xs'>※ 以下テーブルプレビューはイメージです。</p>
          <PropertyGetInfomation
            // property={property}
            current_user={current_user}
            tab_display_items={tabDisplayItems[0]}
            display_item_data={is_get_infomaion_items}
            required_item_data={required_item_data}
            authenticity_token={authenticity_token}
            error_messages={error_messages}
            />
        </div>
          {/* { <div className={` mt-9 xl:w-1/2 ${activeInfomationTab !== 1 ? 'hidden' : ''}`}>

          </div> */}

        <div className={` mt-8 xl:w-1/2 ${activeInfomationTab !== 2 ? 'hidden' : ''}`} style={{ marginLeft: '1%' }}>
          <p className='text-xs'>以下テーブルプレビューはイメージです。</p>
          <PropertyBusinessInfomation
              // property={property}
              current_user={current_user}
              display_item_data={display_item_data}
              tab_display_items={tabDisplayItems[2]}
              required_item_data={required_item_data}
              authenticity_token={authenticity_token}
              error_messages={error_messages}
          ></PropertyBusinessInfomation>
        </div>
      </div>
    </Base>
  )
}

export default DisplayItemsShowPage
