import * as React from 'react'
import Base from '@/components/Layout/Base'
import { Table } from '@/components/Table'
import { Select } from '@/components/Select'
import { Input } from '@/components/Input'
import { Button, AnchorButton } from '@/components/Button'
import { Breadcrumb } from '@/components/Breadcrumb'
import { RadioButton } from '@/components/RadioButton'
import { Textarea } from '@/components/Textarea'
import { format, parseISO } from 'date-fns'
import type { User } from '@/types/user'
import type { Property } from '@/types/property'
import type { PropertyDocument } from '@/types/propertyDocument'

type Props = {
  current_user: User
  authenticity_token: string
  property: Property
  property_document: PropertyDocument
  error_messages: string[]
  property_document_format_list?: string[]
}

const PropertyDocumentsEditPage: React.FC<Props> = ({
  current_user,
  authenticity_token,
  property,
  property_document,
  error_messages,
  property_document_format_list,
}) => {
  const [propertyDocumentData, setPropertyDocumentData] = React.useState<PropertyDocument>(property_document)
  const title = property_document.id ? '帳票編集' : '帳票登録'
  const submit = property_document.id ? '編集を保存' : '登録'
  const param = window.location.search
  const property_id = new URLSearchParams(param).get('property_id')

  return (
    <Base
      current_user={current_user}
      title={title}
      breadcrumb={
        <Breadcrumb
          link_texts={['物件管理', '帳票作成', title]}
          property_id={property_id}
          tab="property_documents"
        />
      }
      submenuCategory="properties"
    >
      <form
        className="mt-8"
        action={property_document.id ? `/property_documents/${property_document.id}` : '/property_documents'}
        acceptCharset="UTF-8"
        method="post"
      >
        <input type="hidden" name="_method" value={property_document.id ? 'patch' : 'post'} />
        <input type="hidden" name="authenticity_token" value={authenticity_token} />

        {error_messages.length > 0 && (
          <ul className="mb-6 text-sm text-red">
            {error_messages.map((message, i) => (
              <li key={i} className="mb-2 break-all">
                {message}
              </li>
            ))}
          </ul>
        )}
        <Table className="block h-[calc(100vh_-_182px)] md:h-[calc(100vh_-_195px)]">
          <tbody className="block md:table-row-group">
            <tr className="bg-[#F1F3F4] block md:table-row [&:nth-child(even)]:bg-white">
              <th
                scope="row"
                className="pt-4 pb-2 md:py-5 px-4 text-right whitespace-nowrap text-black-base font-medium w-1/4 block md:table-cell"
              >
                所在地
              </th>
              <td className="py-2 px-4 md:w-3/4 block md:table-cell">
                住所表示：{property.prefecture} {property.city}{' '}
                {property.town} {property.chome}　地番：{property.chiban}
                <input
                  type="hidden"
                  name="property_id"
                  id="property_id"
                  value={property.id}
                />
              </td>
            </tr>
            <tr className="bg-[#F1F3F4] block md:table-row [&:nth-child(even)]:bg-white">
              <th
                scope="row"
                className="pt-4 pb-2 md:py-5 px-4 text-right whitespace-nowrap text-black-base font-medium w-1/4 block md:table-cell"
              >
                帳票名
                <span className="ml-2 inline-block border border-red py-0.5 px-1 text-red leading-none text-[10px]">
                  必須
                </span>
              </th>
              <td className="py-2 px-4 md:w-3/4 block md:table-cell">
                <Input
                  className="md:w-[300px]"
                  value={propertyDocumentData?.name ?? ''}
                  name="property_document[name]"
                  id="property_document_name"
                  onChange={(e) => setPropertyDocumentData({ ...propertyDocumentData, name: e.target.value })}
                />
              </td>
            </tr>
            <tr className="bg-[#F1F3F4] block md:table-row [&:nth-child(even)]:bg-white">
              <th
                scope="row"
                className="pt-4 pb-2 md:py-5 px-4 text-right whitespace-nowrap text-black-base font-medium w-1/4 block md:table-cell"
              >
                物件概要書フォーマット
                <span className="ml-2 inline-block border border-red py-0.5 px-1 text-red leading-none text-[10px]">
                  必須
                </span>
              </th>
              <td className="py-2 px-4 md:w-3/4 block md:table-cell">
                <Select
                  className="md:w-[300px]"
                  value={propertyDocumentData.property_document_format_id ?? ''}
                  name="property_document[property_document_format_id]"
                  id="property_document_format_id"
                  options={property_document_format_list}
                  onChange={(e) => setPropertyDocumentData({ ...propertyDocumentData, property_document_format_id: e.target.value })}
                />
              </td>
            </tr>
          </tbody>
        </Table>
        <div className="bg-white flex justify-center items-center w-full left-0 md:gap-4 border-t border-gray-250 pt-2">
          <AnchorButton
            className="w-full md:w-40 md:h-auto text-primary-font border-white"
            outline
            variant="text"
            href={`/properties/${property.hashid}?tab=property_documents`}
          >
            キャンセル
          </AnchorButton>
          <Button className="w-full md:w-40 md:h-auto">{submit}</Button>
        </div>
      </form>
    </Base>
  )
}

export default PropertyDocumentsEditPage
