import * as React from 'react'
import { useDragAndDrop } from '@/components/SortableList/useDragAndDrop'
import { Input } from '@/components/Input'
import { Button, AnchorButton } from '@/components/Button'
import DragIndicatorOutlined from '@material-ui/icons/DragIndicatorOutlined'
import DeleteOutlined from '@material-ui/icons/DeleteOutlined'
import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined'
import * as PropertyDocumentFormatAction from '@/actions/PropertyDocumentFormatAction'
import { redirect } from '@/utils/errors'

export interface CardProps {
  id: number
  name: string
  index: number
  moveCard: (dragIndex: number, hoverIndex: number) => void
  dropCard: () => void
  deleteCard: (id: number) => void
  updateCard: (id: number, name: string) => void
}

export const Card: React.FC<CardProps> = ({
  id,
  name,
  index,
  moveCard,
  dropCard,
  deleteCard,
  updateCard,
}) => {
  const [propertyDocumentFormatName, setPropertyDocumentFormatName] = React.useState(name ?? '')
  const [timer, setTimer] = React.useState(null)

  const updatePropertyDocumentFormatName = React.useCallback(
    (id: number, name: string) => {
      setPropertyDocumentFormatName(name)

      clearTimeout(timer)
      const newTimer = setTimeout(async () => {
        try {
          await PropertyDocumentFormatAction.updateFormat(id, {name})
          updateCard(id, name)
        } catch(error) {
          redirect(error, '/property_document_formats/index_with_notice',
                   '更新に失敗しました。同一の名称がすでに存在するようです。')
        }
      }, 500)
      setTimer(newTimer)
    },
    [timer, setTimer]
  )

  const [ref, isDragging, handlerId] = useDragAndDrop(index, moveCard, dropCard)
  const opacity = isDragging ? 0 : 1

  return (
    <tr
      key={id}
      ref={ref}
      style={{ opacity }}
      data-handler-id={handlerId}
      className={['bg-white border-t border-gray-150'].join(' ')}
    >
      <td className="py-2 md:px-2 w-12 md:w-24">
        <Button
          className="text-primary-font cursor-move"
          prefix={<DragIndicatorOutlined fontSize="small" />}
          outline
          size="small"
          variant="primary"
        >
          <span className="text-primary-font hidden md:block">移動</span>
        </Button>
      </td>
      <td className="p-2 w-full flex gap-2 md:gap-4 items-center">
        <Input
          className="w-full"
          placeholder="物件概要書フォーマット名を入力してください"
          onChange={(e) => updatePropertyDocumentFormatName(id, e.target.value)}
          value={propertyDocumentFormatName}
        />
        <AnchorButton
          prefix={<SettingsOutlinedIcon fontSize="small" />}
          outline
          size="small"
          variant="primary"
          href={`/property_document_formats/${id}/detail`}
        ></AnchorButton>
        <Button
          className="text-primary-font"
          prefix={<DeleteOutlined fontSize="small" />}
          outline
          size="small"
          variant="primary"
          onClick={() => {
            deleteCard(id)
          }}
        >
          <span className="text-primary-font hidden md:block">削除</span>
        </Button>
      </td>
    </tr>
  )
}
