export const directionOptions = [
  { value: '北', text: '北' },
  { value: '北東', text: '北東' },
  { value: '東', text: '東' },
  { value: '南東', text: '南東' },
  { value: '南', text: '南' },
  { value: '南西', text: '南西' },
  { value: '西', text: '西' },
  { value: '北西', text: '北西' },
]

export const directionOptionsForSearch  = [
  { value: 1, text: '北' },
  { value: 2, text: '北東' },
  { value: 3, text: '東' },
  { value: 4, text: '南東' },
  { value: 5, text: '南' },
  { value: 6, text: '南西' },
  { value: 7, text: '西' },
  { value: 8, text: '北西' },
]

export const roadTypeOptions = [
  { value: '公道', text: '公道' },
  { value: '私道', text: '私道' },
]

export const buildingStandardsLawArticlesOptions = [
  { value: '42条1項1号', text: '42条1項1号' },
  { value: '42条1項2号', text: '42条1項2号' },
  { value: '42条1項3号', text: '42条1項3号' },
  { value: '42条1項4号', text: '42条1項4号' },
  { value: '42条1項5号', text: '42条1項5号' },
  { value: '42条2項', text: '42条2項' },
  { value: '43条2項2号', text: '43条2項2号' },
  { value: '通路', text: '通路' },
]


export const buildingStandardsLawArticlesOptionsForSearch = [
  { value: 1, text: '42条1項1号' },
  { value: 2, text: '42条1項2号' },
  { value: 3, text: '42条1項3号' },
  { value: 4, text: '42条1項4号' },
  { value: 5, text: '42条1項5号' },
  { value: 6, text: '42条2項' },
  { value: 7, text: '43条2項2号' },
  { value: 8, text: '通路' },
]

