import * as tailwindConfig from '@/../../tailwind.config.js'
import * as React from 'react'
import { MultiValue, default as ReactSelect } from 'react-select'

export type Option = {
  label: string
  value: string | number
}

export type ChildOption = {
  parent_value: string
  label: string
  value: string | number
}

interface Props
  extends Omit<
    React.SelectHTMLAttributes<HTMLSelectElement>,
    'size' | 'value' | 'onChange' | 'defaultValue'
  > {
  id?: string
  size?: string
  options?: Option[]
  value?: Option
  defaultValue?: Option | Option[]
  hasBlank?: boolean
  blankLabel?: string
  clearable?: boolean
  isMulti?: boolean
  onChange?: (newValue: Option | MultiValue<Option>) => void
  closeMenuOnSelect?: boolean
}

export const DropDown: React.FC<Props> = ({
  className,
  name,
  id,
  options,
  value,
  defaultValue,
  onChange,
  placeholder,
  clearable,
  isMulti,
  disabled,
  closeMenuOnSelect = true,
  ...props
}) => {
  const style = {
    control: (base, { isDisabled }) => ({
      ...base,
      cursor: isDisabled ? 'not-allowed' : 'default',
      backgroundColor: isDisabled ? '#FAFBFB' : 'white',
      border: 0,
      boxShadow: 'none',
      minHeight: '37px',
    }),
    singleValue: (provided, { isDisabled }) => ({
      ...provided,
      color: isDisabled ? tailwindConfig.theme.extend.colors['gray']['300'] : 'black',
      paddingLeft: 'calc(0.5em - 1px)',
    }),
    menuPortal: (base) => ({ ...base, zIndex: 9999999, fontSize: '0.875rem' }),
  }

  return (
    <ReactSelect
      className={[styles.select, disabled ? 'bg-[#FAFBFB]' : 'bg-white', className].join(' ')}
      name={name}
      id={id}
      isDisabled={disabled}
      isMulti={isMulti ?? false}
      isClearable={clearable ?? true}
      isSearchable
      placeholder={placeholder ?? '---'}
      options={options}
      value={value}
      defaultValue={defaultValue}
      onChange={(newValue, _) => onChange(newValue)}
      styles={style}
      noOptionsMessage={() => '選択肢がありません'}
      menuPortalTarget={document.body}
      menuPosition={'fixed'}
      menuShouldScrollIntoView={true}
      closeMenuOnSelect={closeMenuOnSelect}
      {...props}
    />
  )
}

const styles = {
  select: `
    text-sm
    w-full
    appearance-none
    border
    rounded-sm
    outline-none
    leading-normal
    border-gray-border
    placeholder-gray-300
    disabled:text-gray-300
    disabled:bg-gray-light
    disabled:cursor-not-allowed
    disabled:opacity-70
  `,
}
