import * as React from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { Button, AnchorButton } from '@/components/Button'
import ClearIcon from '@material-ui/icons/Clear'
import { Input } from '@/components/Input'
import { RadioButton } from '@/components/RadioButton'
import { displayRadioOptions, displayDeffaultNumber } from '@/models/project'
import { cammedFormat, dataPrecisionSetup, intFormat } from '@/utils/cammedFormat'
import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined'

type Props = {
  title: string
  sub_tab: string
  department_options: { value: string; label: string }[]
  user_options: { value: string; label: string }[]
}

export const PDFSettingModal: React.FC<Props> = ({
  title,
  sub_tab,
  department_options,
  user_options,
}) => {
  const [isOpen, setIsOpen] = React.useState(false)
  const [projectsSearchParams, setProjectsSearchParams] = React.useState({
    businessTypes: [],
    tags: [],
    sourceUserDepartment: [],
    userDepartments: [],
    sourceUsers: [],
    users: [],
    proposalSourcedFrom: '',
    proposalSourcedTo: '',
    proposalSourcedBetween: '',
    purchaceAppliedAtGteq: '',
    propertyPurchaceAppliedAtLteq: '',
    purchaceContractAtGteq: '',
    propertyPurchaceContractAtLteq: '',
    memoUpdatedFrom: '',
    memoUpdatedTo: '',
    memoUpdatedBetween: '',
    groupType: '',
    sortQuery: '',
    displayOption: 'specify', //案件表示初期値は数指定にする
    displayNum: displayDeffaultNumber.toString(),
  })

  React.useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search)

    const sourceUserDepartment =
      searchParams
        .getAll('q[ongoing_proposal_source_user_department_name_eq_any][]')
        .filter((v) => v !== '')
        .map((v) => ({ value: v, label: v })) || []

    const userDepartments =
      searchParams
        .getAll('q[user_department_id_eq_any][]')
        .filter((v) => v !== '')
        .map((v) => department_options.find((d) => d.value.toString() === v)) || []

    const sourceUsers =
      searchParams
        .getAll('q[ongoing_proposal_source_user_id_eq_any][]')
        .filter((v) => v !== '')
        .map((v) => user_options.find((d) => d.value.toString() === v)) || []

    const users =
      searchParams
        .getAll('q[user_id_eq_any][]')
        .filter((v) => v !== '')
        .map((v) => user_options.find((d) => d.value.toString() === v)) || []

    setProjectsSearchParams({
      businessTypes: searchParams.getAll('q[business_type_id_eq_any][]') || [],
      tags: searchParams.getAll('q[tag_id_eq_any][]') || [],
      sourceUserDepartment: sourceUserDepartment,
      userDepartments: userDepartments,
      sourceUsers: sourceUsers,
      users: users,
      proposalSourcedFrom: searchParams.get('proposal_sourced_from') || '',
      proposalSourcedTo: searchParams.get('proposal_sourced_to') || '',
      proposalSourcedBetween: searchParams.get('q[proposal_sourced_between]') || '',
      purchaceAppliedAtGteq: searchParams.get('q[purchace_applied_at_gteq]') || '',
      propertyPurchaceAppliedAtLteq: searchParams.get('q[purchace_applied_at_lteq]') || '',
      purchaceContractAtGteq: searchParams.get('q[purchace_contract_at_gteq]') || '',
      propertyPurchaceContractAtLteq: searchParams.get('q[purchace_contract_at_lteq]') || '',
      memoUpdatedFrom: searchParams.get('memo_updated_from') || '',
      memoUpdatedTo: searchParams.get('memo_updated_to') || '',
      memoUpdatedBetween: searchParams.get('q[memo_updated_between]') || '',
      groupType: searchParams.get('type') || '',
      sortQuery: searchParams.get('q[s]') || '',
      displayOption: searchParams.get('display_option') || 'specify', //案件表示初期値は数指定にする
      displayNum: searchParams.get('display_num') || displayDeffaultNumber.toString(),
    })
  }, [])

  const subPage = {
    reset: '/projects_totals',
    business_type: '/projects_business_types',
    tag: '/projects_tags',
    department_name: '/projects_departments',
    user: '/projects_users',
  }

  function closeModal() {
    setIsOpen(false)
  }

  function openModal() {
    setIsOpen(true)
  }
  return (
    <>
      <div className="">
        <button type="button" onClick={openModal}>
          <AnchorButton
            className="text-primary-font"
            prefix={<SettingsOutlinedIcon fontSize="small" />}
            outline
            size="small"
            variant="primary"
          ></AnchorButton>
        </button>
      </div>
      <Transition appear show={isOpen} as={React.Fragment}>
        <Dialog className="relative z-10" onClose={closeModal}>
          <Transition.Child
            as={React.Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center text-center">
              <Transition.Child
                as={React.Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-screen md:max-w-[625px] transform overflow-hidden rounded-2xl bg-white text-left align-middle shadow-xl transition-all">
                  <Dialog.Title className="text-base font-medium p-4 leading-6 text-gray-700">
                    {title}
                    <ClearIcon className="float-right cursor-pointer" onClick={closeModal} />
                  </Dialog.Title>
                  <div className="border-t border-gray-150"></div>
                  <form
                    id="proposal_search"
                    action={subPage[sub_tab]}
                    acceptCharset="UTF-8"
                    method="get"
                  >
                    <div className="mt-2">
                      <div className="p-4">
                        <div>
                          <div className="flex items-center">
                            <div className="text-sm md:text-sm py-1 text-right whitespace-normal text-black-base font-medium w-1/4">
                              1グループごとの案件数
                            </div>
                            <div className="py-2 px-4 w-3/4">
                              <div className="flex items-center">
                                {displayRadioOptions.map((item, i) => (
                                  <RadioButton
                                    className="px-4"
                                    key={i}
                                    name="display_option"
                                    id={`display_option_${i}`}
                                    text={item.text}
                                    value={item.value}
                                    checked={
                                      item.value.toString() === projectsSearchParams.displayOption
                                    }
                                    onChange={(e) => {
                                      setProjectsSearchParams({
                                        ...projectsSearchParams,
                                        displayOption: e.target.value,
                                      })
                                    }}
                                  />
                                ))}
                              </div>
                            </div>
                          </div>
                          <div className="flex items-center">
                            <div className="py-1 px-4 w-1/4"></div>
                            <div className="py-2 px-4 w-3/4">
                              <div className="flex items-center">
                                <Input
                                  className="md:w-[300px]"
                                  value={projectsSearchParams.displayNum || ''}
                                  name="display_num"
                                  id="display_num"
                                  suffix="件"
                                  disabled={
                                    projectsSearchParams.displayOption === 'all' ? true : false
                                  }
                                  onChange={(e) =>
                                    setProjectsSearchParams({
                                      ...projectsSearchParams,
                                      displayNum: e.target.value,
                                    })
                                  }
                                  onBlur={(e) => {
                                    const value = e.target.value
                                      ? cammedFormat(dataPrecisionSetup(e.target.value, 10, 0), 0)
                                      : ''
                                    setProjectsSearchParams({
                                      ...projectsSearchParams,
                                      displayNum: value,
                                    })
                                  }}
                                />
                                <input
                                  type="hidden"
                                  name="display_num"
                                  id="display_num"
                                  value={
                                    projectsSearchParams.displayNum
                                      ? intFormat(String(projectsSearchParams.displayNum))
                                      : ''
                                  }
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="border-t border-gray-150"></div>
                    <div className="flex gap-2 px-6 py-3 justify-end">
                      <AnchorButton
                        className="text-primary-font border-white w-[120px]"
                        outline
                        size="small"
                        variant="primary"
                        onClick={closeModal}
                      >
                        キャンセル
                      </AnchorButton>
                      <Button className="text-white w-[120px]" size="small" variant="primary">
                        確定
                      </Button>
                    </div>
                  </form>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  )
}
export default PDFSettingModal
