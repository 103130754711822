import * as React from 'react'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import Base from '@/components/Layout/Base'
import { Breadcrumb } from '@/components/Breadcrumb'
import { Button, AnchorButton } from '@/components/Button'
import type { Tag } from '@/types/tag'
import { Select } from '@/components/Select'
import { User } from '@/types/user'
import { MarketDatumReport } from '@/types/marketDatumReport'
import { ErrorMessage } from '@/components/ErrorMessage'
import { Card } from '@/components/SortableList/MarketDatumReportLayouts/Card'

type Props = {
  current_user: User
  business_type: Tag
  columns_selected_values: { number: string }
  market_datum_report: MarketDatumReport
  column_list: { value: string; text: string }[]
  authenticity_token: string
  error_messages: string[]
}

const MarketDatumReportLayoutNewEditPage: React.FC<Props> = ({
  current_user,
  business_type = {},
  columns_selected_values = [],
  market_datum_report,
  column_list = [],
  authenticity_token,
  error_messages,
}) => {
  const title = '帳票フォーム設定'
  const action = market_datum_report.id ? 'patch' : 'post'

  const [layoutColumnValues, setLayoutColumnValues] = React.useState(
    Array.from({ length: 25 }, (_, index) => columns_selected_values[index] || '')
  )

  const handleLayoutColumnValues = (value, index) => {
    layoutColumnValues[index] = value
    setLayoutColumnValues([...layoutColumnValues])
  }
  const moveCard = React.useCallback(
    (dragIndex: number, hoverIndex: number) => {
      setLayoutColumnValues((prevCards: LayoutColumnValue[]) => {
        const dragCard = prevCards[dragIndex]
        const newCards = [...prevCards]
        newCards.splice(dragIndex, 1)
        newCards.splice(hoverIndex, 0, dragCard)
        return newCards
      })
    },
    [setLayoutColumnValues]
  )

  return (
    <Base
      current_user={current_user}
      title={title}
      breadcrumb={<Breadcrumb link_texts={[title]} />}
      submenuCategory="settings"
    >
      <form
        className="mt-8"
        action={actionPath(market_datum_report)}
        acceptCharset="UTF-8"
        method="post"
      >
        <input type="hidden" name="_method" value={action} />
        <input type="hidden" name="authenticity_token" value={authenticity_token} />

        <ErrorMessage error_messages={error_messages} />

        <div className="h-[calc(100vh_-_182px)] md:h-[calc(100vh_-_195px)]">
          <div>周辺事例：{market_datum_report.data_type_text}</div>
          <div className="py-6 whitespace-nowrap overflow-x-scroll">
            <div className="flex flex-row gap-1">
              <div className="flex border-solid border-[1px] border-gray-border rounded-sm px-3 items-center justify-center">
                No.
              </div>
              <div className="flex border-solid border-[1px] border-gray-border rounded-sm px-3 items-center justify-center">
                物件名
              </div>
              <div className="flex border-solid border-[1px] border-gray-border rounded-sm px-3 items-center justify-center">
                所在地
              </div>
              <DndProvider backend={HTML5Backend}>
                {[...Array(25)].map((_, i) => (
                  <Card
                    key={i}
                    index={i}
                    id={i}
                    column_list={column_list}
                    layoutColumnValues={layoutColumnValues}
                    onChangeLayoutColumnValues={handleLayoutColumnValues}
                    moveCard={moveCard}
                  />
                ))}
              </DndProvider>
            </div>
          </div>
        </div>
        <div className="bg-white flex justify-center items-center w-full left-0 md:gap-4 border-t border-gray-250 pt-2">
          <AnchorButton
            className="w-full md:w-40 md:h-auto text-primary-font border-white"
            outline
            variant="text"
            href={`/market_datum_report_layouts/new?data_type=${market_datum_report.data_type}`}
          >
            キャンセル
          </AnchorButton>
          <Button className="w-full md:w-40 md:h-auto">編集を保存</Button>
        </div>
      </form>
    </Base>
  )
}

const actionPath = (marketDatumReport: MarketDatumReport): string => {
  return marketDatumReport.id
    ? `/market_datum_report_layouts/${marketDatumReport.id}`
    : `/market_datum_report_layouts?data_type=${marketDatumReport.data_type}`
}

export default MarketDatumReportLayoutNewEditPage
