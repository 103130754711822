import * as React from 'react'
import type { Props } from './types'

const CaretDownIcon: React.FC<Props> = ({ size = 16, fill = '343A40', className }: Props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill={fill}
      className={className}
      viewBox="0 0 16 16"
    >
      <path
        d="M7.24743 11.1399L2.4512 5.6585C1.88544 5.01192 2.34461 4 3.20377 4H12.7962C13.6554 4 14.1146 5.01192 13.5488 5.6585L8.75258 11.1399C8.35417 11.5952 7.64584 11.5952 7.24743 11.1399Z"
        fill={fill}
      />
    </svg>
  )
}

export default CaretDownIcon
