import Header from '@/components/Layout/Header'
import Sidebar from '@/components/Layout/Sidebar'
import Submenu from '@/components/Layout/Submenu'
import type { User } from '@/types/user'
import * as React from 'react'

type Props = {
  children?: React.ReactNode
  breadcrumb?: React.ReactNode
  submenuCategory?: string
  current_user: User
  title: string
  customRef?: React.Ref<HTMLElement>
  city?: string
}

export const UserContext = React.createContext<{
  user: Partial<User>
}>({
  user: {
    id: '',
    company_id: null,
    department_id: null,
    name: '',
    email: '',
    role: 'general',
    pricing_type: '',
  },
})

const UsersIndexPage: React.FC<Props> = ({
  children,
  current_user,
  breadcrumb,
  submenuCategory,
  title,
  customRef,
  city,
}) => {
  const currentUser = {
    ...current_user,
    id: current_user.id,
    company_id: current_user.company_id ?? null,
    department_id: current_user.department_id ?? null,
    name: current_user.name ?? '',
    email: current_user.email ?? '',
    role: current_user.role ?? 'general',
    pricing_type: current_user.company.pricing_type ?? '',
  }

  const [user] = React.useState(currentUser)
  const [sidebarClicked, setSidebarClicked] = React.useState('')
  const [spMenuOpen, setSpMenuOpen] = React.useState(false)

  return (
    <UserContext.Provider value={{ user }}>
      <Header title={title} spMenuOpen={spMenuOpen} setSpMenuOpen={setSpMenuOpen} city={city} />
      <div className={styles.page}>
        <Sidebar sidebarClicked={sidebarClicked} />
        <div
          className={`w-full ${spMenuOpen && 'mt-[56px]'} z-0 md:mt-0 md:w-[calc(100vw_-_3rem)]`}
        >
          {breadcrumb}
          <div className="flex flex-grow h-[calc(100vh_-_103px)]">
            {submenuCategory && (
              <Submenu
                category={submenuCategory}
                setSidebarClicked={setSidebarClicked}
                city={city}
              />
            )}
            <main className="flex-1 px-4 py-0 md:px-10 overflow-y-scroll" ref={customRef}>
              {children}
            </main>
          </div>
        </div>
      </div>
    </UserContext.Provider>
  )
}

const styles = {
  page: `
    flex
    flex-auto
  `,
}

export default UsersIndexPage
