import * as React from 'react'

interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
  text: string
  id: string
}

export const RadioButton: React.FC<Props> = ({
  id,
  text,
  name,
  value,
  className,
  checked = false,
  disabled = false,
  onChange,
  ...props
}) => {
  return (
    <label
      className={['flex relative cursor-pointer', className, disabled && 'cursor-not-allowed'].join(
        ' '
      )}
      htmlFor={id}
    >
      <input
        type="radio"
        className="opacity-0 absolute w-0"
        value={value}
        name={name}
        id={id}
        disabled={disabled}
        checked={checked}
        {...props}
        onChange={(e) => {
          onChange(e)
        }}
      />
      <span
        className={[
          styles.radioButton,
          checked ? styles.checked : 'border-gray-200',
          disabled && styles.disabled,
        ].join(' ')}
      >
        <span className={[checked && styles.checkedInner].join(' ')} />
      </span>
      <span
        className={['inline-block text-gray-800 text-sm', disabled && 'text-gray-300'].join(' ')}
      >
        {text}
      </span>
    </label>
  )
}

const styles = {
  radioButton: `
    relative
    inline-block
    appearance-none
    h-3.5
    w-3.5
    focus:outline-none
    mt-1
    text-base
    align-top
    bg-no-repeat
    bg-center
    mr-2
    rounded-full
    border
    bg-white
  `,
  checked: `
    border-primary
  `,
  checkedInner: `
    bg-primary
    absolute
    w-2
    h-2
    block
    rounded-full
    top-1/2
    left-1/2
    -translate-x-1/2
    -translate-y-1/2
  `,
  disabled: `
    bg-gray-100
    border-gray-200
  `,
}
