import * as React from 'react'
import { Dialog, Transition } from '@headlessui/react'
import ClearIcon from '@material-ui/icons/Clear'

import { Button } from '@/components/Button'

type Props = {
  children?: React.ReactNode
  title?: string
  header?: React.ReactNode
  open: boolean
  onClose?: () => void
  ok?: boolean
  clear?: boolean
}

export const Modal: React.FC<Props> = ({
  children,
  title,
  header,
  open,
  onClose = () => {},
  ok = false,
  clear = true,
}) => {
  return (
    <Transition appear show={open} as={React.Fragment}>
      <Dialog as="div" className="relative z-30" onClose={onClose}>
        <Transition.Child
          as={React.Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center text-center">
            <Transition.Child
              as={React.Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-screen md:max-w-[625px] transform overflow-hidden rounded-2xl bg-white text-left align-middle shadow-xl transition-all">
                {header || title ? (
                  <>
                    <Dialog.Title
                      as="h3"
                      className="text-base font-medium p-4 leading-6 text-gray-700"
                    >
                      {header ? (
                        header
                      ) : (
                        <>
                          {title}
                          <ClearIcon className="float-right cursor-pointer" onClick={onClose} />
                        </>
                      )}
                    </Dialog.Title>
                    <div className="border-t border-gray-150"></div>
                    <div className="text-black-base text-sm text-center">{children}</div>
                  </>
                ) : (
                  <div className="p-4">
                    {clear && (
                      <ClearIcon className="float-right cursor-pointer" onClick={onClose} />
                    )}
                    <div className="text-black-base text-sm text-center">{children}</div>
                    {ok && (
                      <div className="flex justify-center">
                        <Button
                          className="text-white h-full w-40"
                          variant="primary"
                          onClick={() => {
                            if (onClose) {
                              onClose()
                            }
                          }}
                        >
                          OK
                        </Button>
                      </div>
                    )}
                  </div>
                )}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  )
}
