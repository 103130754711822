const cammedFormats = (data: any, columns: string[], exp) => {
  const values = {}

  columns.forEach((column) => {
    const value = data[column]
    values[column] = value ? cammedFormat(data[column], exp) : ''
  })

  return values
}

const cammedFormat = (value: number | '', exp = 0) => {
  if (value === '' || value === undefined) return ''

  const values = String(value).split(/\./)
  if (values.length < 2) values[1] = '0'
  const head = values[0].length % 3
  let integer_part: string | Array<string>
  let integer_str: string
  if (head === 0) {
    integer_part = []
    integer_str = values[0]
  } else {
    integer_part = [values[0].substring(0, head)]
    integer_str = values[0].substring(head)
  }

  // eslint-disable-next-line @typescript-eslint/no-unsafe-call
  const results = integer_str.matchAll(/([0-9]{3})/g)
  for (const result of results) {
    integer_part.push(result[1])
  }
  values[0] = integer_part.join(',')
  values[1] = (values[1] + '0000000000').substring(0, exp)
  if (exp == 0) {
    values.pop()
  } else {
    if (values[0] == '') values[0] = '0'
  }
  return values.join('.')
}

const intFormat = (value: string) => {
  return Number(value.replace(/[^0-9.]/g, '') ?? 0.0)
}

const formatNumber = (value: number | string) => {
  return Math.floor(Number(value)).toLocaleString()
}

// data_precision_setup_func
const dataPrecisionSetup = (v: string, percision, scale) => {
  if (v === '' || v === undefined) return ''
  let value: string | number = v
  let match = value.match(/[0-9\-\.０-９ーー―‐ー−．．]/g)
  value = match === null ? '' : match.join('')
  value = value.replace(/[０-９ーー―‐ー−．．]/g, function (c) {
    return c.match(/[ーー―‐ー−]/)
      ? '-'
      : c.match(/[．．]/)
      ? '.'
      : String.fromCharCode(c.charCodeAt(0) - 0xfee0)
  })
  value = value.replace(/,/g, '')
  match = value.match(/^[+0]*(([0-9]+)(\.)([0-9]*)|([0-9]*))$/)
  if (match) {
    if (typeof match[2] == 'string') {
      value = match[2].substr(0, percision - scale) + match[3] + match[4].substr(0, scale)
    } else if (typeof match[5] == 'string') {
      value = match[5].substr(0, percision - scale)
    }
  } else {
    value = value.replace(/^[+0]*([0-9]+(\.[0-9]+))?.*$/, '$1')
  }
  return intFormat(value || '0')
}

const searchYeildFormat = (value: string) => {
  const formatNumYeild = (Number(value) * 100)?.toLocaleString()
  return formatNumYeild !== '0' && formatNumYeild
    ? dataPrecisionSetup(formatNumYeild, 12, 2).toFixed(2)
    : ''
}

const formatDateToEndOfDay = (date) => {
  const d = new Date(date)
  d.setHours(23, 59, 59, 999)
  const year = d.getFullYear()
  const month = (d.getMonth() + 1).toString().padStart(2, '0')
  const day = d.getDate().toString().padStart(2, '0')
  const hours = d.getHours().toString().padStart(2, '0')
  const minutes = d.getMinutes().toString().padStart(2, '0')
  const seconds = d.getSeconds().toString().padStart(2, '0')
  const milliseconds = d.getMilliseconds().toString().padStart(3, '0')

  return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.${milliseconds}`
}

const formatDecimalToPercentage = (decimal) => {
  return Math.round(Number(decimal) * 10000) / 100
}

const formatPercentageToDecimal = (percentage) => {
  return Math.round(Number(percentage) * 100) / 10000
}

export {
  cammedFormats,
  cammedFormat,
  intFormat,
  formatNumber,
  dataPrecisionSetup,
  searchYeildFormat,
  formatDateToEndOfDay,
  formatDecimalToPercentage,
  formatPercentageToDecimal,
}
