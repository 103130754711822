import * as React from "react";
import { FC, memo, useMemo } from 'react';
import { DrawingRegion } from '../Drawing';
import {
  BoukaTypeCode,
  BoukaTypeCodeNameMap,
  KoudoTypeCode,
  KoudoTypeCodeNameMap,
  YoutoTypeCode,
  YoutoTypeCodeNameMap,
} from '../Zoning';
import { MathUtils, Vector2 } from 'three';
import { findClosestPointOnSegment } from '../../helpers/ClosestPointHelper';
import {
  shouldRotateDegree,
  textReadableDegree,
} from '../../helpers/DrawingHelper';

export type RegionProps = {
  regions: DrawingRegion[];
  boundingBox: {
    min: Vector2;
    max: Vector2;
  };
};

const Caption: FC<{
  region: DrawingRegion;
  fontSize?: number;
  rotated: boolean;
}> = (props) => {
  const lines = useMemo(() => {
    const { region } = props;
    const { id_youto, id_bouka, id_koudo, id_hikage } = region;
    const youto =
      id_youto !== undefined && id_youto !== null
        ? YoutoTypeCodeNameMap[id_youto.youto_chiiki as YoutoTypeCode] ?? '無'
        : '無';
    const bouka =
      id_bouka !== undefined && id_bouka !== null
        ? BoukaTypeCodeNameMap[id_bouka.bouka_chiiki as BoukaTypeCode] ?? '無'
        : '無';
    const koudo =
      id_koudo !== undefined && id_koudo !== null
        ? KoudoTypeCodeNameMap[id_koudo.koudo_chiku as KoudoTypeCode] ?? '無'
        : '無';
    const hikage =
      id_hikage !== undefined && id_hikage !== null
        ? `${id_hikage.shade_5}h-${id_hikage.shade_10}h(${id_hikage.shade_height}m)`
        : '無';
    return [
      `${youto}`,
      id_youto !== undefined && id_youto !== null
        ? `${id_youto.building_coverage_ratio}% / ${id_youto.floor_area_ratio}%`
        : '-',
      `${bouka}`,
      `${koudo}`,
      `${hikage}`,
    ];
  }, [props]);

  const fontSize = useMemo(() => {
    return props.fontSize ?? 120;
  }, [props]);

  const n = lines.length;

  return (
    <g>
      {props.rotated
        ? lines.map((line, i) => {
            return (
              <text
                key={`line-${i}`}
                fontSize={fontSize}
                x={0}
                y={fontSize * i - (n + 0.25) * fontSize}
                textAnchor="middle"
                alignmentBaseline="before-edge"
              >
                {line}
              </text>
            );
          })
        : lines.map((line, i) => {
            return (
              <text
                key={`line-${i}`}
                fontSize={fontSize}
                x={0}
                y={fontSize * i}
                textAnchor="middle"
                alignmentBaseline="before-edge"
              >
                {line}
              </text>
            );
          })}
    </g>
  );
};

type Polyline = Vector2[];

const RegionUnit: FC<{
  region: DrawingRegion;
  polyline: Polyline;
  others: Polyline[];
  boundingBox: RegionProps['boundingBox'];
}> = ({ region, polyline, others, boundingBox }) => {
  const strokeDashArray = useMemo(() => {
    const separation = 30;
    const ratio = 10;
    return `${
      separation * ratio
    } ${separation} ${separation} ${separation} ${separation} ${separation}`;
  }, []);

  const closests = useMemo(() => {
    const eps = 10;
    const segments = Array.from(Array(polyline.length - 1).keys()).map((i) => {
      const a = polyline[i];
      const b = polyline[i + 1];
      return [a, b] as [Vector2, Vector2];
    });

    const otherSegments = others.map((other) => {
      return Array.from(Array(other.length - 1).keys()).map((i) => {
        const c = other[i];
        const d = other[i + 1];
        return [c, d] as [Vector2, Vector2];
      });
    });

    const corners = [
      boundingBox.min,
      new Vector2(boundingBox.min.x, boundingBox.max.y),
      boundingBox.max,
      new Vector2(boundingBox.max.x, boundingBox.min.y),
    ];

    const commonEdges = otherSegments.map((other) => {
      return segments.filter(([a, b]) => {
        return other.some(([c, d]) => {
          return (
            (a.distanceToSquared(c) <= eps && b.distanceToSquared(d) <= eps) ||
            (a.distanceToSquared(d) <= eps && b.distanceToSquared(c) <= eps)
          );
        });
      });
    });

    return commonEdges
      .filter((edges) => edges.length > 0)
      .map((edges) => {
        const candidates = edges
          .flatMap((edge) => {
            return corners.map((point) => {
              const closest = findClosestPointOnSegment({
                point,
                segment: edge,
              });
              if (closest !== null) {
                return {
                  edge,
                  point: closest,
                  distance: closest.distanceToSquared(point),
                };
              }
              return null;
            });
          })
          .filter(
            (
              o,
            ): o is {
              edge: [Vector2, Vector2];
              point: Vector2;
              distance: number;
            } => o !== null,
          );
        const { point, edge } = candidates.reduce((pre, cur) => {
          if (pre.distance < cur.distance) {
            return pre;
          } else {
            return cur;
          }
        });

        const [a, b] = edge;
        const dir = b.clone().sub(a);
        const angle = dir.angle();
        const degree = MathUtils.radToDeg(angle);
        const rotated = shouldRotateDegree(degree);
        return {
          point,
          degree: textReadableDegree(degree),
          rotated,
        };
      });
  }, [polyline, others, boundingBox]);

  return (
    <g>
      <polyline
        stroke="#bbb"
        strokeWidth={15}
        fill="none"
        // strokeDasharray={strokeDashArray}
        points={polyline.map((p) => `${p.x},${p.y}`).join(' ')}
      />
      {closests.map((closest, i) => {
        return (
          <g
            key={`caption-${i}`}
            transform={`translate(${closest.point.x}, ${closest.point.y}), rotate(${closest.degree})`}
          >
            <Caption region={region} rotated={closest.rotated} />
          </g>
        );
      })}
    </g>
  );
};

const Region: FC<RegionProps> = ({ regions, boundingBox }) => {
  const polylines = useMemo(() => {
    return regions.map((region) => {
      const {
        polygon: { exterior },
      } = region;

      const polyline = exterior.map((p) => {
        return new Vector2(p.x, p.y);
      });

      let n = polyline.length;
      let s = 0.0;
      for (let i = 0; i < n - 1; i++) {
        s +=
          polyline[i].x * polyline[i + 1].y - polyline[i + 1].x * polyline[i].y;
      }
      if (s < 0) {
        // is clockwised
        polyline.reverse();
      }
      return polyline;
    });
  }, [regions]);

  return (
    <g>
      {polylines.map((polyline, i) => {
        return (
          <RegionUnit
            key={`region-${i}`}
            region={regions[i]}
            polyline={polyline}
            others={polylines.filter((r, j) => j !== i)}
            boundingBox={boundingBox}
          />
        );
      })}
    </g>
  );
};

const memoized = memo(Region);

export { memoized as Region };
