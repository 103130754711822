import { Vector2 } from "three";

const findClosestPointOnPolyline = (props: { point: Vector2; polyline: Vector2[] }): {
  point: Vector2;
  segment: [Vector2, Vector2];
  distance: number;
} | null => {
  const { point, polyline } = props;

  const closest: {
    point: Vector2 | null;
    segment: [Vector2, Vector2] | null;
    distance: number;
  } = {
    point: null,
    segment: null,
    distance: Infinity,
  };

  const isClosed = polyline[0].equals(polyline[polyline.length - 1]);
  const n = isClosed ? polyline.length - 1 : polyline.length;
  const m = polyline.length;

  for (let i = 0; i < n; i++) {
    const a = polyline[i];
    const b = polyline[(i + 1) % m];
    const segment: [Vector2, Vector2] = [a, b];
    const found = findClosestPointOnSegment({
      point,
      segment,
    });
    if (found !== null) {
      const distance = found.distanceToSquared(point);
      if (distance < closest.distance) {
        closest.point = found;
        closest.segment = segment;
        closest.distance = distance;
      }
    }
  }

  return closest.point !== null ? {
    point: closest.point,
    segment: closest.segment!,
    distance: Math.sqrt(closest.distance),
  } : null;
};

const findClosestPointOnSegment = (props: { point: Vector2; segment: [Vector2, Vector2] }): Vector2 | null => {
  const { point, segment } = props;
  const [a, b] = segment;
  const dir = b.clone().sub(a);
  if (dir.lengthSq() <= 0) return null;

  const toP = point.clone().sub(a);
  const t = toP.dot(dir) / dir.dot(dir);
  if (t <= 0) {
    return a.clone();
  } else if (t >= 1) {
    return b.clone();
  }

  const c = a.clone().add(dir.clone().multiplyScalar(t));
  return c;
}

const findSegmentSegmentIntersection = (
  s0: { a: Vector2 | [number, number]; b: Vector2 | [number, number]; },
  s1: { a: Vector2 | [number, number]; b: Vector2 | [number, number]; }
): Vector2 | null => {
  const { x: x0, y: y0 } = s0.a instanceof Vector2 ? s0.a : new Vector2(s0.a[0], s0.a[1]);
  const { x: x1, y: y1 } = s0.b instanceof Vector2 ? s0.b : new Vector2(s0.b[0], s0.b[1]);
  const { x: x2, y: y2 } = s1.a instanceof Vector2 ? s1.a : new Vector2(s1.a[0], s1.a[1]);
  const { x: x3, y: y3 } = s1.b instanceof Vector2 ? s1.b : new Vector2(s1.b[0], s1.b[1]);

  const dx10 = x1 - x0;
  const dy10 = y1 - y0;
  const dx32 = x3 - x2;
  const dy32 = y3 - y2;

  const s = (-dy10 * (x0 - x2) + dx10 * (y0 - y2)) / (-dx32 * dy10 + dx10 * dy32);
  const t = (dx32 * (y0 - y2) - dy32 * (x0 - x2)) / (-dx32 * dy10 + dx10 * dy32);

  if (0 <= s && s <= 1 && 0 <= t && t <= 1) {
    return new Vector2(
      x0 + (t * dx10),
      y0 + (t * dy10)
    );
  }

  return null;
}

export {
  findClosestPointOnPolyline,
  findClosestPointOnSegment,
  findSegmentSegmentIntersection,
}