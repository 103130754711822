export const areaOfUseCodes = [
  { value: 1, text: '第１種低層住居専用地域' },
  { value: 2, text: '第２種低層住居専用地域' },
  { value: 3, text: '第１種中高層住居専用地域' },
  { value: 4, text: '第２種中高層住居専用地域' },
  { value: 5, text: '第１種住居地域' },
  { value: 6, text: '第２種住居地域' },
  { value: 7, text: '準住居地域' },
  { value: 8, text: '近隣商業地域' },
  { value: 9, text: '商業地域' },
  { value: 10, text: '準工業地域' },
  { value: 11, text: '工業地域' },
  { value: 12, text: '工業専用地域' },
  { value: 13, text: '田園住居地域' },
  { value: 0, text: '指定なし' },
]

export const fireProtectionCodes = [
  { value: 10, text: '防火地域' },
  { value: 20, text: '準防火地域' },
  { value: 30, text: '法22条区域' },
  { value: 40, text: '新防火地域' },
  { value: 0, text: '指定なし' },
]

export const altitudeAreaCodes = [
  { value: 1, text: '第一種高度地区' },
  { value: 2, text: '第二種高度地区' },
  { value: 3, text: '第三種高度地区' },
  { value: 9, text: 'その他' },
  { value: 0, text: '指定なし' },
]

export const shadowAreaCodes = [
  { value: 0, text: '指定なし' },
  { value: 1, text: '3.0h-2.0h GL+1.5m' },
  { value: 2, text: '3.0h-2.0h GL+4.0m' },
  { value: 3, text: '4.0h-2.5h GL+1.5m' },
  { value: 4, text: '4.0h-2.5h GL+4.0m' },
  { value: 5, text: '4.0h-2.5h GL+6.5m' },
  { value: 6, text: '5.0h-3.0h GL+4.0m' },
  { value: 7, text: '5.0h-3.0h GL+6.5m' },
]

export const zoningAreaCodes = [
  { value: 1, text: '市街化区域' },
  { value: 2, text: '市街化調整区域' },
  { value: 3, text: '非線引き区域' },
  { value: 4, text: '準都市計画区域' },
  { value: 5, text: '都市計画区域外' },
]

type CodeMapping = { value: number | string; text: string }[]

export function getCodeFromText(text: string, mapping: CodeMapping): number | string {
  if (text.includes('指定なし')) {
    const unspecifiedItem = mapping.find((item) => item.text === '指定なし')
    return unspecifiedItem?.value ?? ''
  }

  const found = mapping.find((item) => item.text === text)
  return found?.value ?? ''
}

export function getTextFromCode(code: number | string, mapping: CodeMapping): string {
  const found = mapping.find((item) => item.value === code)
  return found ? found.text : ''
}

export function getUnspecifiedText(code, codeMapping) {
  if (code && code.includes && code.includes('指定なし')) {
    const unspecifiedItem = codeMapping.find((item) => item.text === '指定なし')
    const result = unspecifiedItem ? unspecifiedItem.text : code
    return result
  }

  const found = codeMapping.find((item) => item.value === code)
  const result = found ? found.text : code
  return result
}

export const shadeInfoSet = (shadowAreaData: any) => {
  if (!shadowAreaData) {
    return ''
  }
  let shadowArea = {
    shade_5: shadowAreaData?.shade_5,
    shade_10: shadowAreaData?.shade_10,
    shade_height: shadowAreaData?.shade_height,
  }
  if (Object.values(shadowArea).every((value) => value === null)) {
    return ''
  } else if (Object.values(shadowArea).every((value) => value === 0)) {
    return '指定なし'
  } else {
    return `${shadowArea.shade_5?.toFixed(1)}h-${shadowArea.shade_10?.toFixed(
      1
    )}h GL+${shadowArea.shade_height?.toFixed(1)}m`
  }
}
