import * as React from 'react'

type Props = {
  className?: string
}

const Footer: React.FC<Props> = ({ className }) => {
  return (
    <footer className={[className, styles.footer].join(' ')}>
      &copy; TASUKI Corporation . All Rights Reserved.
    </footer>
  )
}

const styles = {
  footer: `
      w-full
      border-t
      border-gray-100
      py-6
      leading-1
      text-center
      text-gray-700
      text-sm
    `,
}

export default Footer
