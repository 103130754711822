import * as React from "react";
import { Dialog, Transition } from '@headlessui/react'
import {AnchorButton, Button} from "@/components/Button";
import {GoogleMap} from "@react-google-maps/api";
import {createRoot} from "react-dom/client";
import {Loading} from "@/components/Loading";
import {UserContext} from "@/components/Layout/Base";
import FullscreenExitIcon from "@mui/icons-material/FullscreenExit";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import {useFullScreen} from "@/components/Page/VolumeCheckRequests/viewFullscreen";
import '@/components/GoogleMapVolume/style.css'
import './Loading.css'
import { Input } from '@/components/Input'

type Props = {
  isOpen
  closeModal
  polygons_api_base_url
  lat
  lng
  zoom
  mode
  property_id
  csrfToken
  authenticity_token
  volume_check_detail
  ref_volume_check_parameters
  plan_number
}
var markerRefresh = false
export const KouzuMapModal: React.FC<Props> =
    ({
       isOpen,
       closeModal,
       polygons_api_base_url = '',
       lat,
       lng,
       zoom,
       mode,
       property_id,
       csrfToken,
       authenticity_token,
       volume_check_detail,
       ref_volume_check_parameters,
       plan_number,
    }) => {
  const formRef = React.useRef(null)
  let url = `/properties/${volume_check_detail.property.hashid}/volume_check_requests/update_request_parameters`
  const ref = new URLSearchParams(window.location.search).get('ref')
  const mapRef = React.useRef(null)
  const isMapLoaded = React.useRef(false)
  const layerButtonStates = React.useRef({
    showKouzuMapClicked: true,
  })
  const polygonsHash = React.useRef({
    kouzu_map: {},
  })
  const selectedInfoWindows = React.useRef([])
  const centerCursors = React.useRef([])
  const chibanMarkers = React.useRef([])
  const infoMarkers = React.useRef([])
  const cadastralItems = ['kouzu_map']
  const loadingIconFlg = React.useRef(null)
  const [disabledFlg, setDisabledFlg] = React.useState(false)
  const areaMarkers = React.useRef([])
  const { user } = React.useContext(UserContext)
  const positionArea = React.useRef([])
  const [outLines, setOutLines] = React.useState(null)
  const markerList = []
  if (ref) {
    url += `?ref=${ref}`
  }
  const {
    elementRef: mapDivRef,
    triggerFullScreen: triggerMapFullScreen,
    exitFullScreen: exitMapFullScreen,
    isFullScreen: isMapFullScreen,
  } = useFullScreen()

  const onLoad = (map) => {
    mapRef.current = map

    const styledMapType = new google.maps.StyledMapType(
        [
          {
            elementType: 'labels.icon',
            stylers: [{ visibility: 'off' }],
          },
          {
            featureType: 'transit.line', // 交通機関の路線
            elementType: 'all', // ラベルを含めてすべて
            stylers: [{ visibility: 'on' }],
          },
          {
            featureType: 'transit.station.rail', // 電車の駅
            elementType: 'all', // ラベルを含めてすべて
            stylers: [{ visibility: 'on' }],
          },
        ],
        { name: 'Styled Map' }
    )
    map.mapTypes.set('styled_map', styledMapType)

    map.setMapTypeId('styled_map')

    google.maps.event.addListener(map, 'tilesloaded', () => {
      isMapLoaded.current = true
    })
    google.maps.event.addListener(map, 'bounds_changed', () => {
      if (map.getZoom() < 17) {
        clearCadastralItems()
        clearCityPlanItems()
      } else {
        if (!layerButtonStates.current.showKouzuMapClicked) {
          clearCadastralItems()
        }
      }
    })
    function addMarker(
        property: {
          id: number
          lat: number
          lng: number
          property_type?: string
        },
        map
    ) {
      const marker_params = {
        position: new google.maps.LatLng(Number(property.lat), Number(property.lng)),
        map: map,
        optimized: false,
      }
      if (mode == 'volume') {
        marker_params['draggable'] = true
      }
      const marker = new google.maps.Marker(marker_params)

      if (mode === 'volume') {
        let icon
        if (property_id == property.id) {
          icon = {
            url: '/target_ping.png',
            scaledSize: new google.maps.Size(37, 30),
          }
          marker.setOptions({ zIndex: 99999 })
        } else {
          marker.setVisible(false)
        }
        marker.setIcon(icon)
      }

      if (mode == 'volume' && isMapLoaded.current) {
        marker.addListener('dragend', () => {
          const lat = marker.getPosition().lat()
          const lng = marker.getPosition().lng()
          fetch('/api/property_latlng_update', {
            method: 'PATCH',
            body: JSON.stringify({
              id: property.id,
              lat: lat,
              lng: lng,
            }),
            headers: {
              'Content-Type': 'application/json',
              'X-CSRF-Token': csrfToken,
            },
          }).catch((err) => {
            console.log(err)
          })
        })
      }
      return marker
    }
    function createLayerButtons(
        type,
        imageSource,
        buttonText,
        buttonFunction,
        svgAttributes = {}
    ) {
      const button = document.createElement('button')
      button.classList.add('custom-map-control-button')
      button.setAttribute('type', 'button')
      button.style.fontSize = '14px'
      button.style.margin = '4px 4px 0 0'
      button.style.color = 'black'
      if (type === 'svg') {
        const svg = document.createElementNS('http://www.w3.org/2000/svg', 'svg')
        Object.entries(svgAttributes).forEach(([key, value]) => {
          svg.setAttribute(key, String(value))
        })
        const path = document.createElementNS('http://www.w3.org/2000/svg', 'path')
        path.setAttribute('d', imageSource)
        svg.appendChild(path)
        button.appendChild(svg)
      } else if (type === 'img') {
        const img = document.createElement('img')
        img.src = imageSource
        img.height = 15
        img.width = 15
        img.style.display = 'inline-block'
        img.style.verticalAlign = 'middle'
        img.style.marginRight = '5px'
        button.appendChild(img)
      }
      const span = document.createElement('span')
      span.textContent = buttonText
      button.appendChild(span)
      button.addEventListener('click', buttonFunction)
      return button
    }
    if (user.pricing_type !== 'basic' && mode != 'volume_detail') {
      const layerButtonSvgPath =
          'm11.99 18.54-7.37-5.73L3 14.07l9 7 9-7-1.63-1.27-7.38 5.74zM12 16l7.36-5.73L21 9l-9-7-9 7 1.63 1.27L12 16z'
      const svgAttributes = {
        class: 'MuiSvgIcon-root MuiSvgIcon-fontSizeSmall',
        focusable: 'false',
        'aria-hidden': 'true',
        viewBox: '0 0 24 24',
      }
      const layerButton = createLayerButtons(
          'svg',
          layerButtonSvgPath,
          'レイヤー',
          () => {
            document.querySelectorAll('.polygons-button2').forEach((elem) => {
              ;(elem as HTMLElement).style.display =
                  (elem as HTMLElement).style.display === 'none' ? 'block' : 'none'
            })
          },
          svgAttributes
      )
      layerButton.id = 'layerButton'
      layerButton.style.display = 'block'
      layerButton.style.marginTop = '4em'
      map.controls[google.maps.ControlPosition.RIGHT_TOP].push(layerButton)

      if (user.pricing_type === 'owner' && user.role === 'system_admin') {
        const kouzuMapSvgAttributes = {
          class: 'MuiSvgIcon-root MuiSvgIcon-fontSizeSmall',
          focusable: 'false',
          'aria-hidden': 'true',
          viewBox: '0 0 25 28',
        }
        const kouzuMapSvgPath =
            'M3 21h2v-2H3v2zm4 0h2v-2H7v2zM5 7H3v2h2V7zM3 17h2v-2H3v2zM9 3H7v2h2V3zM5 3H3v2h2V3zm12 0h-2v2h2V3zm2 6h2V7h-2v2zm0-6v2h2V3h-2zm-4 18h2v-2h-2v2zM13 3h-2v8H3v2h8v8h2v-8h8v-2h-8V3zm6 18h2v-2h-2v2zm0-4h2v-2h-2v2z'
        const kouzuMapButtonFunction = () => {
          let newLayerButtonStates = {
            ...layerButtonStates.current,
            showKouzuMapClicked: !layerButtonStates.current.showKouzuMapClicked,
          }
          handleLayerButtonStatesChange(newLayerButtonStates)

          kouzuMapButton.style.display = 'block'
          if (newLayerButtonStates.showKouzuMapClicked) {
            kouzuMapButton.style.color = 'red'
          } else {
            kouzuMapButton.style.color = 'black'
          }
        }
        const kouzuMapButton = createLayerButtons(
            'svg',
            kouzuMapSvgPath,
            '公図情報',
            kouzuMapButtonFunction,
            kouzuMapSvgAttributes
        )
        kouzuMapButton.id = 'kouzuMapButton2'
        kouzuMapButton.classList.add('polygons-button2')
        kouzuMapButton.style.color = layerButtonStates.current.showKouzuMapClicked ? 'red' : 'black'
        map.controls[google.maps.ControlPosition.RIGHT_TOP].push(kouzuMapButton)
      }
    }
    let params: any = {}

    google.maps.event.addListener(map, 'idle', () => {
      map.setCenter(map.getCenter())
      const latlngBounds = map.getBounds()
      const swLatlng = latlngBounds.getSouthWest()
      const neLatlng = latlngBounds.getNorthEast()
      if (
          params &&
          params.lat1 == swLatlng.lat() &&
          params.lng1 == swLatlng.lng() &&
          params.lat2 == neLatlng.lat() &&
          params.lng2 == neLatlng.lng()
      ) {
        if (!markerRefresh) return
      }
      params = {
        lat1: swLatlng.lat(),
        lng1: swLatlng.lng(),
        lat2: neLatlng.lat(),
        lng2: neLatlng.lng(),
        with_shape: map.getZoom() >= 17,
      }
      if (mode === 'volume') {
        if (markerList.length > 0) {
          // eslint-disable-next-line @typescript-eslint/no-extra-semi
          ;[...markerList].forEach((marker) => {
            marker.setMap()
          })
          markerList.length = 0
        }
        const query = new URLSearchParams(params)
        fetch(`/api/properties?${query}`)
            .then((res) => res.json())
            .then((json) => {
              json.res.forEach((marker) => {
                if (marker.lat < 0 || marker.lng < 0) return
                // マーカーを追加
                markerList.push(addMarker(marker, map, marker.tag_id, marker.tag_color))
              })
            })
            .catch((err) => {
              console.log(err)
            })
        markerRefresh = false
      }
      if (map.getZoom() >= 17) {
        if (layerButtonStates.current.showKouzuMapClicked) {
          renderPolygons()
        }
        if (!layerButtonStates.current.showKouzuMapClicked) {
          clearCadastralItems()
        }
      } else {
        clearCityPlanItems()
        clearCadastralItems()
      }
    })
    fullscreenHandler()
  }

  function fullscreenHandler() {
    const elementToSendFullscreen = mapDivRef.current
    document.onwebkitfullscreenchange =
        document.onmsfullscreenchange =
            document.onmozfullscreenchange =
                document.onfullscreenchange =
                    function () {
                      if (isFullscreen(elementToSendFullscreen)) {
                        document.getElementById('layerButton').style.display = 'block'
                      } else {
                        document.getElementById('layerButton').style.display = 'none'
                        document.querySelectorAll('.polygons-button2').forEach((elem) => {
                          ;(elem as HTMLElement).style.display = 'none'
                        })
                      }
                    }
  }
  function isFullscreen(element: HTMLElement) {
    return (
        (document.fullscreenElement ||
            document.webkitFullscreenElement ||
            document.mozFullScreenElement ||
            document.msFullscreenElement) == element
    )
  }

  function clearCadastralItems() {
    clearItems(cadastralItems)
    chibanMarkers.current.forEach((chibanMarker) => {
      chibanMarker.setMap(null)
    })
  }

  function clearItems(areas) {
    areas.forEach((area) => {
      Object.keys(polygonsHash.current[area]).forEach((key) => {
        polygonsHash.current[area][key].setMap()
        delete polygonsHash.current[area][key]
      })
    })
  }

  function handleLayerButtonStatesChange(newLayerButtonStates) {
    const oldLayerButtonStates = { ...layerButtonStates.current }
    layerButtonStates.current = newLayerButtonStates

    if (newLayerButtonStates.showKouzuMapClicked !== oldLayerButtonStates.showKouzuMapClicked) {
      if (newLayerButtonStates.showKouzuMapClicked) {
        renderPolygons()
      } else {
        clearCityPlanItems()
        clearCadastralItems()
      }
    }
  }

  async function renderPolygons() {
    let loadingRoot

    clearCityPlanItems()
    if (polygons_api_base_url == '' || mapRef.current.getZoom() < 17) {
      clearCityPlanItems()
      return
    }

    infoMarkers.current.forEach((infoMarker) => {
      infoMarker.setMap(null)
    })
    chibanMarkers.current.forEach((chibanMarker) => {
      chibanMarker.setMap(null)
    })
    selectedInfoWindows.current.forEach((infoWindow) => {
      infoWindow.close()
    })
    selectedInfoWindows.current = []
    centerCursors.current.forEach((centerCursor) => {
      centerCursor.setMap(null)
    })
    centerCursor()
    mapRef.current.setOptions({
      draggable: false,
      scrollwheel: false,
      disableDoubleClickZoom: true,
      zoomControl: false,
      scaleControl: false,
      panControl: false,
      minZoom: mapRef.current.getZoom(),
      maxZoom: mapRef.current.getZoom(),
    })

    const center = mapRef.current.getCenter()

    let polygonTypes = []
    if (layerButtonStates.current.showKouzuMapClicked) {
      polygonTypes = polygonTypes.concat(cadastralItems)
      if (loadingIconFlg.current !== null) {
        removeLoadingIcon(loadingIconFlg.current, 'kouzuMapButtonLoadingIcon')
      }
      loadingRoot = createLoadingIcon('kouzuMapButton2', 'kouzuMapButtonLoadingIcon')
      loadingIconFlg.current = loadingRoot
      setDisabledFlg(true)
    }

    let selectedPolygons = []
    let originalColors = []
    let selectedChibanInfos = []
    try {
      await Promise.all(
          polygonTypes.map(async (area) => {
            const query2 = new URLSearchParams({
              lat: center.lat(),
              lng: center.lng(),
              polygon_type: area,
            })

            try {
              const res = await fetch(`${polygons_api_base_url}?${query2}`)

              if (!res.ok) {
                throw new Error(`HTTP error! ${res.status} ${res.statusText}`)
              }

              const json = await res.json()

              if (Object.keys(json).indexOf('polygons') === -1) {
                return
              }

              if (json.layer === 'cadastral' && json.polygons.length === 0) {
                removeLoadingIcon(loadingRoot, 'kouzuMapButtonLoadingIcon')
              }

              Object.keys(polygonsHash.current[area]).forEach((key) => {
                polygonsHash.current[area][key].setMap()
                delete polygonsHash.current[area][key]
              })
              json.polygons.forEach((data) => {
                let points = data.lines.map((point) => {
                  return new google.maps.LatLng(Number(point.lat), Number(point.lng))
                })
                let framePoints = points
                const innerPoints = data.innerlines.map((path) => {
                  return path.map((point) => {
                    return new google.maps.LatLng(Number(point.lat), Number(point.lng))
                  })
                })
                if (innerPoints.length > 0) {
                  innerPoints.unshift(points)
                  points = innerPoints
                }

                let option = {
                  paths: points,
                  strokeColor: '#000000',
                  strokeOpacity: 0,
                  strokeWeight: 0,
                  fillColor: '#000000',
                  fillOpacity: 0,
                }

                if (area == 'kouzu_map') {
                  option.strokeColor = '#125690'
                  option.strokeOpacity = 0.3
                  option.strokeWeight = 1
                  option.fillColor = '#e1f0fc'
                  option.fillOpacity = 0.1
                }
                const polygon = new google.maps.Polygon(option)

                let minLatLng = mapRef.current.getBounds().getSouthWest()
                let maxLatLng = mapRef.current.getBounds().getNorthEast()
                let pad_lat_range = (Number(maxLatLng.lat()) - Number(minLatLng.lat())) * 0.1
                let pad_lng_range = (Number(maxLatLng.lng()) - Number(minLatLng.lng())) * 0.1

                const processPolygons = (displayMarkerFunc) => {
                  const targetPoints = []
                  framePoints.forEach((point) => {
                    if (
                        Number(point.lat()) > Number(minLatLng.lat()) &&
                        Number(point.lat()) < Number(maxLatLng.lat()) &&
                        Number(point.lng()) > Number(minLatLng.lng()) &&
                        Number(point.lng()) < Number(maxLatLng.lng())
                    ) {
                      targetPoints.push(point)
                    }
                  })
                  // 地図枠内にパスがあるポリゴンのみ表示
                  if (targetPoints.length > 0) {
                    let bounds = new google.maps.LatLngBounds()
                    targetPoints.forEach((point) => {
                      bounds.extend(point)
                    })
                    let center = bounds.getCenter()
                    // ポリゴンの中心が地図の外側に出ている場合、地図の内側に収まるように調整
                    if (center.lat() < minLatLng.lat()) {
                      center = new google.maps.LatLng(minLatLng.lat() + pad_lat_range, center.lng())
                    }
                    if (center.lat() > maxLatLng.lat()) {
                      center = new google.maps.LatLng(
                          maxLatLng.lat() - pad_lat_range * 2,
                          center.lng()
                      )
                    }
                    if (center.lng() < minLatLng.lng()) {
                      center = new google.maps.LatLng(center.lat(), minLatLng.lng() + pad_lng_range)
                    }
                    if (center.lng() > maxLatLng.lng()) {
                      center = new google.maps.LatLng(
                          center.lat(),
                          maxLatLng.lng() - pad_lng_range * 2
                      )
                    }
                    // ポリゴンの中心点がポリゴンの外側にある場合（L状のようなポリゴンとか）、ポリゴンの内側に収まるように調整
                    if (google.maps.geometry.poly.containsLocation(center, polygon)) {
                      displayMarkerFunc(data, center)
                    } else {
                      pad_lat_range = pad_lat_range / 2
                      pad_lng_range = pad_lng_range / 2
                      let minLat = minLatLng.lat() + pad_lat_range
                      let minLng = minLatLng.lng() + pad_lng_range
                      lng = Number(minLng)
                      lat = Number(minLat)
                      while (!google.maps.geometry.poly.containsLocation(center, polygon)) {
                        if (center.lng() > mapRef.current.getBounds().getNorthEast().lng()) {
                          lat = lat + Number(pad_lat_range)
                          lng =
                              mapRef.current.getBounds().getSouthWest().lng() + Number(pad_lng_range)
                        }
                        center = new google.maps.LatLng(Number(lat), Number(lng))
                        if (
                            center.lat() > mapRef.current.getBounds().getNorthEast().lat() &&
                            center.lng() > mapRef.current.getBounds().getNorthEast().lng()
                        ) {
                          break
                        }
                        lng = Number(lng) + Number(pad_lng_range)
                      }
                      displayMarkerFunc(data, center)
                    }
                  }
                }

                switch (area) {
                  case 'kouzu_map':
                    if (positionArea.current.length > 0 && selectedPolygons.length === 0) {
                      chibanMarkers.current.forEach((chibanMarker) => {
                        chibanMarker.setMap(null)
                      })
                      showAreaMarkers1(selectedPolygons)
                      positionArea.current = []
                    }
                    if (mapRef.current.getZoom() == 20) {
                      processPolygons(displayChibanMarker)
                      google.maps.event.addListener(polygon, 'click', function (mouseEvent) {
                        let index = selectedPolygons.indexOf(this)
                        if (index !== -1) {
                          this.setOptions({ fillColor: originalColors[index] })
                          selectedPolygons.splice(index, 1)
                          originalColors.splice(index, 1)
                          selectedChibanInfos.splice(index, 1)
                          if (selectedInfoWindows.current[index]) {
                            selectedInfoWindows.current[index].close()
                            selectedInfoWindows.current.splice(index, 1)
                          }
                        } else {
                          originalColors.push(this.get('fillColor'))
                          this.setOptions({ fillColor: '#FF0000' })
                          selectedPolygons.push(this)
                          const chomeMatch = data.types['丁目名']?.match(/[\uFF10-\uFF19]+/)
                          const chomeNumber = chomeMatch
                              ? `${convertFullwidthToHalfwidth(chomeMatch[0])}-`
                              : ''
                          const chibanInfo = `${chomeNumber}${data.types['地番']}`
                          selectedChibanInfos.push(chibanInfo)
                          const infoWindow = new google.maps.InfoWindow({
                            content: `地番：${chibanInfo}`,
                            position: mouseEvent.latLng,
                          })
                          infoWindow.open(mapRef.current)
                          selectedInfoWindows.current.push(infoWindow)
                        }
                        updateSelectedPolygonsLatLng(selectedPolygons);
                        showAreaMarkers1(selectedPolygons)
                      })
                    } else {
                      chibanMarkers.current.forEach((chibanMarker) => {
                        chibanMarker.setMap(null)
                      })
                    }
                    break

                  default:
                    console.log('Unknown area: ', area)
                }

                polygon.setMap(mapRef.current)
                polygonsHash.current[area][data.id] = polygon
              })
            } catch (err) {
              console.error(`Failed to fetch polygon for area ${area}: ${err}`)
            } finally {
              removeLoadingIcon(loadingRoot, 'kouzuMapButtonLoadingIcon')
              mapRef.current.setOptions({
                draggable: true,
                scrollwheel: true,
                disableDoubleClickZoom: false,
                zoomControl: true,
                scaleControl: true,
                panControl: true,
                minZoom: 0,
                maxZoom: 20,
              })
            }
          })
      )
    } catch (error) {
      console.error(`Failed to complete all polygon fetch requests: ${error}`)
    }
  }
  function convertFullwidthToHalfwidth(str) {
    return str.replace(/[\uFF10-\uFF19]/g, function (m) {
      return String.fromCharCode(m.charCodeAt(0) - 0xfee0)
    })
  }

  function clearCityPlanItems() {
    infoMarkers.current.forEach((infoMarker) => {
      infoMarker.setMap(null)
    })
    centerCursors.current.forEach((centerCursor) => {
      centerCursor.setMap(null)
    })
  }

  function updateSelectedPolygonsLatLng(selectedPolygons) {
    const selectedPolygonsLatLng = selectedPolygons.map(polygon => {
      const path = polygon.getPath();
      return path.getArray().map(latLng => ({
        lat: latLng.lat(),
        lng: latLng.lng()
      }));
    });
    positionArea.current = selectedPolygonsLatLng
  }
  function showAreaMarkers1(polygon = null) {
    areaMarkers.current.forEach((marker) => {
      marker.setMap()
    })
    areaMarkers.current.length = 0
    if (polygon.length > 0) {
      let i = 1
      polygon.forEach((selectPolygon) => {
        selectPolygon.getPath().forEach((latlng, index) => {
          if (index !== selectPolygon.getPath().length - 1) {
            areaMarkers.current.push(
                new google.maps.Marker({
                  position: latlng,
                  map: mapRef.current,
                  label: {
                    text: `${i++}`,
                    color: '#ffffff',
                    fontWeight: 'bold',
                    fontSize: '14',
                  },
                  icon: {
                    url: '/map_flag.png',
                    anchor: new google.maps.Point(2, 30),
                    labelOrigin: new google.maps.Point(8, 9),
                    scaledSize: new google.maps.Size(15, 30),
                  },
                  optimized: false,
                })
            )
          }
        })
      })
    }
  }
  function displayChibanMarker(data, center) {
    displayLayerMarker(
        20,
        [40, 40],
        '11px',
        (d) => {
          const chomeMatch = d.types['丁目名']?.match(/[\uFF10-\uFF19]+/)
          const chomeNumber = chomeMatch ? `${convertFullwidthToHalfwidth(chomeMatch[0])}-` : ''
          return `${chomeNumber}${d.types['地番']}`
        },
        chibanMarkers.current,
        data,
        center,
        '/info_label2.png'
    )
  }
  function displayLayerMarker(
      zoomLevel,
      markerSize,
      fontSize,
      contentFunc,
      markerArray,
      data,
      center,
      iconURL
  ) {
    if (mapRef.current.getZoom() >= zoomLevel) {
      const contentString = contentFunc(data)
      if (typeof contentString === 'undefined') {
        return
      }
      let marker = new google.maps.Marker({
        position: center,
        map: mapRef.current,
        icon: {
          url: iconURL,
          scaledSize: new google.maps.Size(markerSize[0], markerSize[1]),
        },
        label: {
          text: contentString,
          color: '#125690',
          fontSize: fontSize,
          fontWeight: 'bold',
        },
        clickable: false,
      })
      markerArray.push(marker)
    }
  }
  function createLoadingIcon(buttonId, iconId) {
    const button = document.getElementById(buttonId)
    if (!button) return

    let loadingIconContainer = document.createElement('span')
    loadingIconContainer.id = iconId
    loadingIconContainer.style.display = 'inline-block'
    loadingIconContainer.style.marginLeft = '5px'
    button.appendChild(loadingIconContainer)

    let loadingRoot = createRoot(loadingIconContainer)
    loadingRoot.render(<Loading height={12} width={12} />)

    return loadingRoot
  }
  function centerCursor() {
    let centerCursor = new google.maps.Marker({
      position: mapRef.current.getCenter(),
      map: mapRef.current,
      icon: {
        url: '/center_cursor.png',
        scaledSize: new google.maps.Size(35, 35),
      },
    })
    centerCursors.current.push(centerCursor)
  }

  const containerStyle = React.useMemo(() => {
    return (
        {
          width: '100%',
          height: isMapFullScreen ? '100%' : '450px',
        }
    )
  }, [isMapFullScreen])

  function removeLoadingIcon(loadingRoot, iconId) {
    let loadingIconContainer = document.getElementById(iconId)
    if (loadingIconContainer) {
      loadingRoot.unmount()
      loadingIconContainer.parentNode.removeChild(loadingIconContainer)
    }
    setDisabledFlg(false)
  }

  const mapOptions = React.useMemo(() => {
    return {
      center: { lat: Number(lat), lng: Number(lng) },
      zoom: zoom,
      minZoom: 1,
      zoomControl: true,
      mapTypeControl: false,
      scaleControl: false,
      streetViewControl: true,
      rotateControl: false,
      fullscreenControl: false,
      tilt: 0,
      mapTypeId: 'roadmap',
    }
  }, [lat, lng, mode])

  const handleSubmit = () => {

    const parameters = ref_volume_check_parameters ? JSON.parse(ref_volume_check_parameters) : null
    const ref_unit_area = parameters ? parameters?.unit_setting?.unit_area : null
    const ref_plan_name = parameters ? parameters?.plan_setting?.plan_name : null
    const ref_plan_number = parameters ? parameters.plan_number : null
    const ref_building_use = parameters ? parameters.building_use : null
    const ref_building_structure = parameters ? parameters.building_structure : null
    const ref_manager_name = parameters ? parameters.manager_name : null
    const ref_project_name = parameters ? parameters.project_name : null
    const params = {
      center: {
        lat: Number((document.getElementById('center_lat') as HTMLInputElement).value),
        lng: Number((document.getElementById('center_lng') as HTMLInputElement).value),
      },
      borders: [],
      plan_setting: {
        chome: (document.getElementById('chome') as HTMLInputElement).value,
        prefecture_name: (document.getElementById('prefecture_name') as HTMLInputElement).value,
        city_name: (document.getElementById('city_name') as HTMLInputElement).value,
        plan_name: ref_plan_name ? ref_plan_name : '',
        administrative_name: (document.getElementById('administrative_name') as HTMLInputElement)
            .value,
        plan_number: ref_plan_number ? ref_plan_number : plan_number,
        building_use: ref_building_use ? ref_building_use : '共同住宅',
        building_structure: ref_building_structure ? ref_building_structure : 'RC',
        manager_name: ref_manager_name ? ref_manager_name : user.name,
        project_name: ref_project_name ? ref_project_name : volume_check_detail.property.name || volume_check_detail.property.city + volume_check_detail.property.town + volume_check_detail.property.chome,
        customization: {
          shade_regulation: 'false',
          compact_residence: 'false',
        },
      },
      city_planning_setting: {
        guideline: 1,
        district_planning: 1,
        city_planning_road: 1,
      },
      surroundings: [],
      building_setting: {
        floors: [],
        floors_above_ground: 15,
        floors_under_ground: 0,
        max_height: 50,
        floor_height: 3,
        gf_slab_level: 0,
        design_ground_level: 0,
        min_offset_road: 0.6,
        min_offset_neighbor: 0.6,
        sky_factor: 1,
        inverse_shadow: 2,
        number_of_units: 20,
        setting_building_area_ratio: '0, -10, -20, -30',
      },
      unit_setting: {
        unit_area: ref_unit_area ? ref_unit_area : '25',
        dwelling_unit_frontage: 3,
        balcony_width: 1.1,
        hallway_width: 1.2,
        entrance_area: 0,
        admin_room: 1,
        waste_storage: 1,
        elevator_number: 1,
      },
    }
    const arr = outLines.split(",").map(Number)
    const positionAreaTmp = []
    positionArea.current.forEach((item) => {
      for (let j = 0; j < item.length - 1; j++) {
        positionAreaTmp.push(item[j])
      }
    })
    arr.forEach((item, index: number) => {
      if (index === arr.length - 1) {
        params.borders.push({
          id: index + 1,
          to: { lat: Number(positionAreaTmp[arr[0] - 1].lat), lng: Number(positionAreaTmp[arr[0] - 1].lng) },
          from: {lat: Number(positionAreaTmp[item - 1].lat), lng: Number(positionAreaTmp[item - 1].lng), height: 0 },
          border_type_code:4,
          set_back_method: 0,
          width: 0,
        })
      } else {
        params.borders.push({
          id: index + 1,
          to: { lat: Number(positionAreaTmp[arr[index + 1] - 1].lat), lng: Number(positionAreaTmp[arr[index + 1] - 1].lng) },
          from: {lat: Number(positionAreaTmp[item - 1].lat), lng: Number(positionAreaTmp[item - 1].lng), height: 0 },
          border_type_code:4,
          set_back_method: 0,
          width: 0,
        })
      }
      params.surroundings.push({
        border_id: index + 1,
        border_type_code: 4,
        width: 0,
      })
    })
    for (let i = 0; i < 15; i++) {
      params.building_setting.floors.push({
        floor_number: i + 1,
        max_floor_height: 3,
      })
    }
    const updatedParamsValue = JSON.stringify(params)
    const adjustedParametersInput = document.getElementById('parameters')
    if (adjustedParametersInput) {
      ;(adjustedParametersInput as HTMLInputElement).value = updatedParamsValue
    }

    // closeModal()
  }

  return (
      <Transition appear show={isOpen} as={React.Fragment}>
        <Dialog className="relative z-10" onClose={() => {
        }}>
          <Transition.Child
              as={React.Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25"/>
          </Transition.Child>
          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center text-center">
              <Transition.Child
                  as={React.Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 scale-95"
                  enterTo="opacity-100 scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 scale-100"
                  leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel
                    className="w-screen md:max-w-[825px] transform rounded-2xl bg-white text-left align-middle shadow-xl transition-all">
                  <Dialog.Title
                      as="h3"
                      className="text-base font-medium p-6 leading-6 text-gray-700"
                  >
                    <div className="">
                      <div className="relative" ref={mapDivRef}>
                        <GoogleMap mapContainerStyle={containerStyle} options={mapOptions} onLoad={onLoad}/>
                        <AnchorButton
                            className="absolute right-2 top-2 w-8 h-8 z-10"
                            onClick={isMapFullScreen ? exitMapFullScreen : triggerMapFullScreen}
                        >
                          {isMapFullScreen ? (
                              <FullscreenExitIcon fontSize="small" />
                          ) : (
                              <FullscreenIcon fontSize="small" />
                          )}
                        </AnchorButton>
                      </div>
                      <form
                          ref={formRef}
                          action={url}
                          acceptCharset="UTF-8"
                          method="post"
                      >
                        <input type="hidden" name="parameters" id="parameters"/>
                        <input type="hidden" name="authenticity_token" value={authenticity_token}/>
                        <div className={positionArea.current.length === 0 ? 'loading-container' : 'row_hidden'}>
                          <span>
                          公図表示画面
                        </span>
                          <AnchorButton
                              type="button"
                              outline={true}
                              onClick={() => {
                                closeModal()
                              }}
                          >
                            キャンセル
                          </AnchorButton>
                        </div>
                        <div className={positionArea.current.length === 0 ? 'row_hidden' : 'loading-container'}>
                          <span>データから形状を選択</span>
                          <div className='flex items-center'>
                            <span className="mr-4">形状輪郭指定</span>
                            <Input
                                className="mr-4 md:w-[320px]"
                                value={outLines}
                                placeholder="フラッグ番号により輪郭を指定してください"
                                onChange={(e) => {
                                  const value = e.target.value
                                  const regex = /^[0-9,\-]*$/
                                  const lastChar = value.charAt(value.length - 1)
                                  if (regex.test(value)) {
                                    setOutLines(e.target.value)
                                  } else if (!lastChar || !regex.test(lastChar)) {
                                    e.target.value = value.slice(0, -1)
                                  }
                                }}
                                onBlur={(e) => {
                                  let value = e.target.value
                                  value = value.replace(/,+/g, ',').replace(/^,|,$/g, '')
                                  value = value.replace(/-+/g, '-')
                                  value = value.replace(/-$/, '')
                                  value = value.trim()
                                  setOutLines(value)
                                }}
                            />
                            <Button
                                className=""
                                disabled={disabledFlg || !outLines}
                                onClick={(e) => {
                                  e.preventDefault()
                                  handleSubmit()
                                  formRef.current?.submit()
                                }}
                            >
                              形状確定
                            </Button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </Dialog.Title>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
  )
    }

export default KouzuMapModal
