import * as React from 'react'
import { useDragAndDrop } from '@/components/SortableList/useDragAndDrop'
import { Input } from '@/components/Input'
import { Select } from '@/components/Select'
import { Button } from '@/components/Button'
import DragIndicatorOutlined from '@material-ui/icons/DragIndicatorOutlined'

export interface CardProps {
  id: number
  index: number
  column_name_1: string
  column_name_2: string
  element_name: string
  column_list: { value: string; text: string }[]
  formatDetailData: string[]
  onChangeFormatDetailData: (value: string, index: number, column: string) => void
  onClickDeleteFormatDetailData: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, index: number) => void
  moveCard: (dragIndex: number, hoverIndex: number) => void
}

export const DetailCard: React.FC<CardProps> = ({
  id,
  index,
  column_name_1,
  column_name_2,
  element_name,
  column_list,
  formatDetailData,
  onChangeFormatDetailData,
  onClickDeleteFormatDetailData,
  moveCard,
}) => {
  const [ref, isDragging, handlerId] = useDragAndDrop(index, moveCard)
  const opacity = isDragging ? 0 : 1

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault()
    }
  }

  return (
    <div
      key={id}
      ref={ref}
      style={{ opacity }}
      data-handler-id={handlerId}
      className="flex gap-[6px]"
    >
      <div className="flex gap-2">
        <Button
          className="text-primary-font cursor-move"
          prefix={<DragIndicatorOutlined fontSize="small" />}
          outline
          size="small"
          variant="primary"
          type="button"
        >
          <span className="text-primary-font hidden md:block">移動</span>
        </Button>
      </div>
      <div className="w-full">
        <Input
          value={column_name_1}
          name={`format_detail[${index}][column_name_1]`}
          id={`format_detail_${index}_column_name_1`}
          placeholder="(未設定)"
          onChange={(e) => onChangeFormatDetailData(e.target.value, index, 'column_name_1')}
          onKeyDown={(e) => handleKeyDown(e)}
        />
      </div>
      <div className="w-full">
        <Input
          value={column_name_2}
          name={`format_detail[${index}][column_name_2]`}
          id={`format_detail_${index}_column_name_2`}
          placeholder="(未設定)"
          onChange={(e) => onChangeFormatDetailData(e.target.value, index, 'column_name_2')}
          onKeyDown={(e) => handleKeyDown(e)}
        />
      </div>
      <div className="w-full">
        <Select
          className="text-sm"
          value={element_name}
          name={`format_detail[${index}][element_name]`}
          id={`format_detail_${index}_element_name`}
          options={column_list}
          onChange={(e) => onChangeFormatDetailData(e.target.value, index, 'element_name')}
        />
      </div>
      <input type="hidden" name={`format_detail[${index}][order]`} value={index + 1} />
      <div className="flex gap-2">
        {formatDetailData.length !== 1 && (
          <Button
            outline
            variant="red"
            size="small"
            onClick={(e) => onClickDeleteFormatDetailData(e, index)}
          >
            削除
          </Button>
        )}
      </div>
    </div>
  )
}
