import * as React from 'react'

type Props = {}

const StaticPageTermsOfServicePage: React.FC<Props> = ({}) => {
  const title = '利用規約'

  return (
    <div>
      <header className="hidden md:flex items-center pl-14 pr-6 py-1">
        <img
          className="w-[7.5rem] h-auto mr-16"
          src="/packs/media/images/tasuki_tech_logo-8a313e2c796da53b8c2ec442ad7e47eb.png"
          alt="Tasuki Tech"
        />
        <span className="text-gray-300 text-lg">{title}</span>
        <div className="ml-auto relative z-10 text-left"></div>
      </header>
      <div className="md:hidden w-full false">
        <header className="flex md:hidden items-center px-4 py-3">
          <img
            className="w-[5.5rem] h-auto mr-4"
            src="/packs/media/images/tasuki_tech_logo-8a313e2c796da53b8c2ec442ad7e47eb.png"
            alt="Tasuki Tech"
          />
          <span className="text-gray-300 text-lg pl-2">利用規約</span>
        </header>
      </div>
      <div className="flex flex-auto">
        <div className="pt-1 hidden md:block bg-primary transition-[width] z-0 w-12"></div>
        <div className="w-full false z-0 md:mt-0 md:w-[calc(100vw_-_3rem)]">
          <div className="w-full border-t border-t-gray-200 bg-gray-light pl-6 p-2 md:pl-10 md:p-3">
            <ul className="text-sm select-none">
              <li className="inline-block">
                <a className="font-medium text-primary-font" href="/">
                  HOME
                </a>
                <span className="mx-1.5">/</span>
              </li>
            </ul>
          </div>
          <div className="h-full w-full flex flex-col">
            <main className="flex-1 px-4 py-0 md:px-10 overflow-y-scroll">
              <div className="h-full flex items-center">
                <div className="mx-auto p-4 flex flex-col relative z-10 mb-40">
                  <div className="h-[calc(100vh_-_182px)] md:h-[calc(100vh_-_195px)]">
                    <div className="py-2 md:flex item-center">
                      <div>
                        <h3 className="text-center">TASUKI TECHサービス　利 用 規 約</h3>
                        <h4 className="text-center">第1章 総則</h4>
                        <p>（利用規約の適用）</p>
                        <p>
                          第１条　株式会社ZISEDAI（以下「当社」といいます。）は、この利用規約（以下単に「利用規約」といいます。）に基づき、本サービスを提供します。
                        </p>
                        <p>
                          ２．　当社は、本サービスの利用に関する条件について、利用規約によるほか、本サービスの内容等に応じた個別のルール、規約その他の諸規程（以下総称して「個別規約」といいます。）により定めることができるものとします。個別規約は利用規約の一部を構成するものとし、利用規約の内容と個別規約の内容が矛盾抵触する場合、個別規約の内容が優先して適用されるものとします。
                        </p>
                        <p>
                          ３．　利用規約の規定と当社及び契約者の間で締結される個別の利用契約の規定が異なるときは、個別の利用契約が利用規約に優先して適用されるものとします。
                        </p>
                        <br />
                        <p>（定義）</p>
                        <p>第２条　利用規約においては、次の用語はそれぞれ次の意味で使用します。</p>
                        <p>
                          　（１） 本サービス
                          当社がアプリケーション・サービス・プロバイダとして提供するWebアプリケーション・不動産プラットフォームサービス「TASUKI　TECH」
                        </p>
                        <p>　（２） 契約者 利用契約を当社と締結し、本サービスの提供を受ける者</p>
                        <p>
                          　（３） 利用契約
                          当社と契約者との間に締結される本サービスの提供に関する契約
                        </p>
                        <p>　（４） 利用契約等 利用契約、利用規約及び個別規約の総称</p>
                        <p>
                          　（５） 契約者設備
                          本サービスの提供を受けるため契約者が設置するコンピュータ、電気通信設備、電気通信回線、その他の機器及びソフトウェア
                        </p>
                        <p>
                          　（６） 本サービス用設備
                          本サービスに関し、当社が設置するコンピュータ、電気通信設備、電気通信回線、その他の機器及びソフトウェア
                        </p>
                        <p>
                          　（７） 本サービス用設備等
                          本サービス用設備及び本サービスを提供するために当社が電気通信事業者から提供を受ける電気通信回線等
                        </p>
                        <p>
                          　（８） 消費税等
                          消費税法及び同法に関連する法令の規定に基づき課税される消費税の額並びに地方税法及び同法に関する法令の規定に基づき課税される地方消費税の額その他の契約者が支払に際して負担すべき公租公課
                        </p>
                        <p>
                          　（９） ユーザＩＤ 契約者とその他の者を個別に識別するために用いられる符号
                        </p>
                        <p>
                          　（１０） パスワード
                          契約者とその他の者を識別するためにユーザＩＤと組み合わせて用いられる符号
                        </p>
                        <p>
                          　（１１） ＡＰＩ
                          アプリケーション・プログラミング・インターフェース（Application
                          Programming
                          Interface）のうち、これを経由することにより、本サービスの特定の機能、情報にアクセスすることのできるものをいいます。
                        </p>
                        <p>
                          　（１２） ＡＰＩキー
                          ユーザＩＤ及びパスワードによりログインした者に対して発行する、アクセスキー、トークン等の認証情報であって、これを利用することにより、本サービスのＡＰＩを利用できるようになるものといいます。
                        </p>
                        <p>
                          　（１３）
                          反社会的勢力　暴力団、暴力団員、暴力団員でなくなった時から5年を経過しない者、暴力団準構成員、暴力団関係企業、総会屋等、社会運動等標ぼうゴロ又は特殊知能暴力集団等その他これらに準ずる者
                        </p>
                        <br />
                        <p>（通知）</p>
                        <p>
                          第３条　当社から契約者への通知は、利用契約等に特段の定めのない限り、通知内容を電子メール、書面又は当社のホームページに掲載するなど、当社が適当と判断する方法により行います。
                        </p>
                        <p>
                          ２．　前項の規定に基づき、当社から契約者への通知を電子メールの送信又は当社のホームページへの掲載の方法により行う場合には、契約者に対する当該通知は、それぞれ電子メールの送信又はホームページへの掲載がなされた時点から効力を生じるものとします。
                        </p>
                        <br />
                        <p>（利用規約の変更）</p>
                        <p>
                          第４条　当社は、次の各号のいずれかに該当する場合、その効力発生日よりも前に、利用規約を変更する旨及びその内容並びに効力発生日を明示し、かつ、契約者への電子メールの送信又は当社のホームページへ掲載することをもって契約者に通知することにより、契約者の事前の個別の承諾を得ることなく、当該効力発生日をもって、利用規約の内容を改定することができるものとします。
                        </p>
                        <p>　（１） 利用規約の変更が契約者の一般の利益に適合するとき</p>
                        <p>
                          　（２）
                          利用規約の変更が、契約をした目的に反せず、かつ、変更の必要性、変更後の内容の相当性、変更の内容その他の変更に係る事情に照らして合理的なものであるとき
                        </p>
                        <p>
                          ２．　当社は、前項に定める場合以外の利用規約の変更を行う場合は、10日以上の予告期間をおいて、契約者への電子メールの送信又は当社のホームページへ掲載することをもって利用規約を変更する旨及び変更後の新利用規約の内容並びに効力発生日を契約者に通知するものとします。この場合、当該効力発生日をもって利用規約の変更の効力を生ずるものとし、当該効力発生日以降の契約者の利用条件その他の利用契約の内容は、変更後の新利用規約を適用するものとします。
                        </p>
                        <p>
                          ３．　前二項の規定にかかわらず、契約者が、利用規約の変更内容について認識し得る状態で本サービスを利用した場合は、当該時点で変更後の利用規約に異議なく同意したものとみなされます。
                        </p>
                        <p>
                          ４．　契約者は、変更後の利用規約の内容に不服がある場合、当該変更の効力発生日までに、当社に対し、書面により通知することにより、当該変更の効力発生日をもって、利用契約を将来に向かって解除することができるものとします。
                        </p>
                        <br />
                        <p>（権利義務譲渡の禁止）</p>
                        <p>
                          第５条　契約者は、あらかじめ当社の書面による承諾がない限り、利用契約上の地位、利用契約に基づく権利又は義務の全部又は一部を他に譲渡してはならないものとします。
                        </p>
                        <br />
                        <p>（合意管轄等）</p>
                        <p>
                          第６条　利用契約等に関連して契約者と当社の間で利用契約等に関連する紛争が生じた場合には、東京地方裁判所をもって第一審の専属的合意管轄裁判所とします。
                        </p>
                        <p>
                          ２．　利用契約等の成立、効力、履行及び解釈に関する準拠法は、日本法とします。
                        </p>
                        <br />
                        <p>（存続条項）</p>
                        <p>
                          第７条
                          利用契約の終了後も、前二条、第11条第4項、第17条、第21条第2項、第22条、第23条、第24条第4項、第27条、第29条、第30条、第31条、第33条及び第34条の規定は効力を有するものとします。
                        </p>
                        <br />
                        <p>（協議等）</p>
                        <p>
                          第８条
                          利用契約等に規定のない事項及び規定された項目の解釈について疑義が生じた場合は両者誠意をもって協議の上解決することとします。なお、利用契約等のいずれかの条項又はその一部分が無効である場合でも、利用契約等全体の有効性及び当該無効とされた部分以外の部分には影響がないものとし、当該無効の部分については、法令上許容される範囲で当該部分の趣旨に最も近い有効な規定に解釈するものとします。
                        </p>
                        <br />
                        <h4 className="text-center">第２章 契約の締結等</h4>
                        <br />
                        <p>（利用契約の締結等）</p>
                        <p>
                          第９条
                          利用契約は、本サービスの利用申込者が、当社所定の利用申込書（以下「利用申込書」といいます。）を当社に提出し、当社がこれに対して書面、FAX又は電子メールにより承諾の通知を発信したときに成立するものとします。なお、本サービスの利用申込者は利用規約の内容を承諾の上で申込を行うものとし、本サービスの利用申込者が申込を行った時点で、当社は、本サービスの利用申込者が利用規約の内容を承諾しているものとみなします。
                        </p>
                        <p>
                          ２．
                          利用変更契約は、第4条の規定による場合を除くほか、契約者が当社所定の利用変更申込書を当社に提出し、当社がこれに対して書面、FAX又は電子メールにより承諾の通知を発信したときに成立するものとします。
                        </p>
                        <p>
                          ３．
                          当社は、前各項その他利用規約の規定にかかわらず、本サービスの利用申込者及び契約者が次の各号のいずれかに該当する場合には、利用契約又は利用変更契約を締結しないことができます。
                        </p>
                        <p>
                          　（１）利用契約等に違反したことを理由として利用契約を解除されたことがあるとき
                        </p>
                        <p>
                          　（２）利用申込書又は利用変更申込書に虚偽の記載、誤記があったとき又は記入もれがあったとき
                        </p>
                        <p>
                          　（３）金銭債務その他利用契約等に基づく債務の履行を怠るおそれがあるとき
                        </p>
                        <p>
                          　（４）反社会的勢力である場合、反社会的勢力が経営を支配していると認められる関係を有する場合、自己若しくは第三者の不正の利益を図る目的若しくは第三者に損害を加える目的をもってするなど不当に反社会的勢力を利用していると認められる関係を有する場合、反社会的勢力に対して資金等を提供し、若しくは便宜を供与するなどの関与をしていると認められる関係を有する場合、又は役員若しくは経営に実質的に関与している者が反社会的勢力と社会的に非難されるべき関係を有する場合
                        </p>
                        <p>　（５）その他当社が不適当と判断したとき</p>
                        <br />
                        <p>（変更通知）</p>
                        <p>
                          第１０条
                          契約者は、その商号又は名称、本店所在地又は住所、連絡先その他利用申込書の契約者にかかわる事項に変更があるときは、当社の定める方法により変更予定日の30日前までに当社に通知するものとします。
                        </p>
                        <p>
                          ２．
                          当社は、契約者が前項に従った通知を怠ったことにより契約者が通知の不到達その他の事由により損害を被った場合であっても、一切責任を負わないものとします。
                        </p>
                        <br />
                        <p>（一時的な中断及び提供停止）</p>
                        <p>
                          第１１条
                          当社は、次の各号のいずれかに該当する場合には、契約者への事前の通知又は承諾を要することなく、本サービスの提供を中断することができるものとします。
                        </p>
                        <p>
                          　（１）
                          本サービス用設備等の故障、障害等により本サービスを提供できない場合
                        </p>
                        <p>　（２） 定期的又は緊急に本サービス用設備等の保守点検を行う場合</p>
                        <p>　（３） 運用上又は技術上の理由でやむを得ない場合</p>
                        <p>
                          　（４）
                          天災地変、火災、感染症の流行（流行拡大防止のために必要な措置を含みます。）、法令の改正、戦争、騒乱、暴動、通信又は電力供給のひっ迫、労働争議等不可抗力により本サービスを提供できない場合
                        </p>
                        <p>
                          　（５）
                          本サービスに利用されている第三者の提供するＡＩ又はソフトウェア等（以下「第三者ソフトウェア」といいます。）が変更され、又は第三者ソフトウェアを利用することができなくなることにより、本サービスを提供できない場合
                        </p>
                        <p>
                          　（６）
                          契約者による本サービスの利用に関し、本サービスの運用上のリスクが高い状態にあると当社が合理的に判断する場合
                        </p>
                        <p>　（７）その他前各号に類する正当な理由がある場合</p>
                        <p>
                          {' '}
                          ＜２＞．
                          当社は、事前に契約者に通知することにより、本サービスの提供を一時的に中断できるものとします。
                        </p>
                        <p>
                          {' '}
                          ＜３＞．
                          当社は、契約者が第15条（当社からの利用契約の解約）各号のいずれかに該当する場合又は契約者が利用契約等に違反した場合には、契約者への事前の通知又は催告を要することなく本サービスの全部又は一部の提供を停止することができるものとします。
                        </p>
                        <p>
                          {' '}
                          ＜４＞．
                          当社は、前三項に定める事由のいずれかにより本サービスの提供を中断又は停止したことに関して契約者等又はその他の第三者が損害を被った場合であっても、一切責任を負わないものとします。
                        </p>
                        <br />
                        <p>（利用期間）</p>
                        <p>
                          第１２条
                          本サービスの利用期間は、第9条第1項に定める利用承諾の通知に記載の利用開始日より1年間とします。ただし、利用期間満了の日の3ヶ月前までに、当社又は契約者のいずれからも異議がないときには、利用契約は期間満了の翌日から起算して、同一内容にて更に1年間延長されるものとし、それ以後も同様とします。
                        </p>
                        <br />
                        <p>（最短利用期間）</p>
                        <p>
                          第１３条
                          本サービスの最短利用期間は、契約者に本サービスの提供を開始した日の属する月の翌々月末日までとします。
                        </p>
                        <p>
                          ２．
                          契約者は、前項の最短利用期間内に利用契約の解約を行う場合は、第14条（契約者からの利用契約の解約）に従うことに加え、当社が定める期限までに、利用申込書記載の初期費用、最短利用期間終了日の属する月までの月額利用料金に相当する額及びその消費税相当額を一括して当社に支払うものとします。
                        </p>
                        <br />
                        <p>（契約者からの利用契約の解約）</p>
                        <p>
                          第１４条
                          契約者は、解約希望日の3ヶ月前までに当社が定める方法により当社に通知することにより、解約希望日をもって利用契約を解約することができるものとします。ただし、契約者は、解約希望日の属する月の月額利用料金について支払う義務を負うものとし、当該月額利用料金の日割計算は行わないものとします。
                        </p>
                        <p>
                          ２．
                          契約者は、前項に定める通知が当社に到達した時点において未払いの利用料金等（期限の到来していないものを含みます。）又は支払遅延損害金がある場合には、直ちにこれらを支払うものとします。
                        </p>
                        <br />
                        <p>（当社からの利用契約の解約）</p>
                        <p>
                          第１５条
                          当社は、契約者が次の各号のいずれかに該当すると判断した場合、契約者への事前の通知若しくは催告を要することなく利用契約の全部若しくは一部を解約することができるものとします。
                        </p>
                        <p>
                          　（１）
                          利用申込書、利用変更申込書その他通知内容等に虚偽記入又は記入もれがあった場合
                        </p>
                        <p>　（２） 支払停止又は支払不能となった場合</p>
                        <p>　（３） 手形又は小切手が不渡りとなった場合</p>
                        <p>
                          　（４）
                          差押え、仮差押え若しくは競売の申立があったとき又は公租公課の滞納処分を受けた場合
                        </p>
                        <p>
                          　（５）
                          破産、会社更生手続開始若しくは民事再生手続開始の申立があったとき又は信用状態に重大な不安が生じた場合
                        </p>
                        <p>　（６） 監督官庁から営業許可の取消、停止等の処分を受けた場合</p>
                        <p>
                          　（７）
                          利用契約等に違反し、当社が当該違反の是正を催告した後合理的な期間内に是正されない場合
                        </p>
                        <p>　（８） 解散、減資、事業の全部又は重要な一部の譲渡等の決議をした場合</p>
                        <p>　（９） 利用契約等を履行することが困難となる事由が生じた場合</p>
                        <p>　（１０）利用契約等に対する重大な違反があった場合</p>
                        <p>　（１１）その他前各号に準じる事由が生じた場合</p>
                        <p>
                          ２．
                          契約者は、前項による利用契約の解約があった時点において未払いの利用料金等（期限の到来していないものを含みます。）又は支払遅延損害金がある場合には、当社の定める日までにこれらを全て支払うものとします。
                        </p>
                        <br />
                        <p>（本サービスの廃止）</p>
                        <p>
                          第１６条
                          当社は、次の各号のいずれかに該当する場合、本サービスの全部又は一部を廃止することができるものとし、廃止日をもって利用契約の全部又は一部を解約することができるものとします。
                        </p>
                        <p>　（１） 廃止日の3ヶ月前までに契約者に通知した場合</p>
                        <p>
                          　（２）
                          天災地変、火災、感染症の流行（流行拡大防止のために必要な措置を含みます。）、法令の改正、戦争、騒乱、暴動、労働争議等不可抗力により本サービスを提供できない場合
                        </p>
                        <p>
                          　（３）
                          本サービスに利用されている第三者ソフトウェアが変更され、又は第三者ソフトウェアを利用することができなくなることにより、本サービスを提供できない場合
                        </p>
                        <p>　（４） その他当社が止むを得ないと判断した場合</p>
                        <p>
                          ２．
                          前項に基づき本サービスの全部又は一部を廃止する場合、当社は、既に支払われている利用料金等のうち、廃止する本サービスについて提供しない日数に対応する額を日割計算にて契約者に返還するものとします。
                        </p>
                        <br />
                        <p>（契約終了後の処理）</p>
                        <p>
                          第１７条
                          契約者は、利用契約が終了した場合、本サービスの利用にあたって当社から提供を受けた機器、ソフトウェア及びそれに関わる全ての資料等（当該ソフトウェア及び資料等の全部又は一部の複製物を含みます。以下同じとします。）を利用契約終了後直ちに当社の指示に従い、当社に返還し、又は廃棄するものとし、契約者設備等に格納されたソフトウェア及び資料等については、契約者の責任で消去するものとします。
                        </p>
                        <p>
                          ２．
                          当社は、利用契約が終了した場合、本サービスの利用にあたって契約者から提供を受けた資料等及び本サービスに入力され、又は本サービスにより生成若しくは出力された情報等（これらの資料等及び情報等の全部又は一部の複製物を含み、以下本項において「資料等」といいます。）を利用契約終了後直ちに廃棄することができるとともに、本サービス用設備等に記録された資料等を消去できるものとし、契約者に対して資料等を返還する義務を負わず、本サービスの利用契約の終了後の、資料等の消去について一切責任を負いません。契約者は、本サービスの利用契約の終了後に資料等を利用することを希望する場合、本サービスの利用契約の終了前であって、かつ、当社が別途定める期日までに、所定の方法により当社に申し出るものとします。この場合、契約者及び当社は、資料等の引渡しの可否、方法、費用等について協議するものとし、別途書面により合意した場合に限り、当該合意に従って資料等の引渡しが行われるものとします。
                        </p>
                        <p>
                          3．　契約者又は当社は、相手方が前二項の廃棄又は消去について証明書の提出を要請する場合、これに応じるものとします。
                        </p>
                        <br />
                        <h4 className="text-center">第３章 サービス</h4>
                        <br />
                        <p>（本サービスの種類と内容）</p>
                        <p>
                          第１８条
                          当社が一般的に提供する本サービスの種類及びその内容は、サービス仕様書に定めるとおりとし、契約者が具体的に利用できる本サービスの種類は、利用申込書にて定めるものとします。
                        </p>
                        <p>
                          ２．
                          契約者は、次の各号に定める全ての事項を承諾の上、本サービスを利用するものとします。
                        </p>
                        <p>
                          　（１）
                          第34条（免責）第1項各号に掲げる場合を含め、本サービスに当社に起因しない不具合が生じる場合があること
                        </p>
                        <p>
                          　（２）
                          当社に起因しない本サービスの不具合について、当社は一切その責を免れること
                        </p>
                        <p>
                          　（３）
                          本サービス用設備等に起因する契約者設備の通信速度の低下や不具合の可能性があること
                        </p>
                        <p>
                          　（４）
                          本サービスが第三者からのあらゆる不正なアクセスを遮断するものではないこと
                        </p>
                        <p>
                          　（５）
                          契約者が正当なアクセスと判断する第三者のアクセスであっても本サービスにより遮断される場合があること
                        </p>
                        <p>
                          ３．
                          次の事項については、利用申込書において明示的に追加されている場合を除き、契約者へ提供されないものとします。
                        </p>
                        <p>
                          　（１） ソフトウェア及びハ－ドウェアに関する問い合わせ並びに障害対応等
                        </p>
                        <p>
                          　（２）
                          磁気テープ媒体、フロッピィディスク媒体、インクリボン、用紙等の消耗品の供給
                        </p>
                        <p>　（３） 本サービスに関するデータの内容、変更等に関する問い合わせ</p>
                        <p>
                          ４．
                          契約者は、利用契約等に基づいて本サービスを利用することができるものであり、本サービスに関する知的財産権その他の権利を取得するものでないことを承諾します。
                        </p>
                        <br />
                        <h4 className="text-center">第４章 利用料金</h4>
                        <br />
                        <p>（本サービスの利用料金、算定方法等）</p>
                        <p>
                          第１９条
                          本サービスの利用料金、算定方法等は、利用申込書の料金表に定めるとおりとします。
                        </p>
                        <br />
                        <p>（利用料金の支払義務）</p>
                        <p>
                          第２０条
                          契約者は、利用期間（原因を問わず、利用期間満了前に利用契約が終了した場合には当該終了の日までの期間とします。以下本条について同じ。）について、利用申込書の料金表に定める利用料金及びこれにかかる消費税等を利用申込書に基づき支払うものとします。ただし、第9条第1項に定める利用承諾の通知に記載の利用開始日又は本契約の満了日が月の途中である場合、利用規約に別段の定めのない限り、日割計算は行わないものとします。なお、契約者が本条に定める支払を完了しない場合、当社は、第11条（一時的な中断及び提供停止）第3項の定めに従い、本サービスの提供を停止することができるものとします。
                        </p>
                        <p>
                          ２．
                          利用期間において、第11条(一時的な中断及び提供停止)に定める本サービスの提供の中断、停止その他の事由により本サービスを利用することができない状態が生じたときであっても、契約者は、利用期間中の利用料金及びこれにかかる消費税等の支払を要します。ただし、当社の責に帰すべき事由により本サービスを全く利用できない状態（以下「利用不能」といいます。）が連続して24時間以上となる場合、当社は、契約者に対し、当該利用不能の期間に応じて日割計算（１日未満は切り捨て）により利用料金及びこれにかかる消費税相当額を返金するものとします。
                        </p>
                        <br />
                        <p>（利用料金の支払方法）</p>
                        <p>
                          第２１条
                          契約者は、本サービスの利用料金及びこれにかかる消費税等を、次の各号のいずれかの方法で支払うものとします。なお、次の各号の支払に必要な振込手数料その他の費用は、契約者の負担とします。
                        </p>
                        <p>
                          　（１）
                          請求書により決済する場合、当社からの請求書に従い当社が指定する期日までに当社の指定する方法により、当社又は当社指定の金融機関に支払うか、当社が別途指定する集金代行業者を通じて当社が指定する期日までに、契約者が指定する預金口座から自動引き落しにより支払うものとします。
                        </p>
                        <p>　（２） その他当社が定める支払方法により支払うものとします。</p>
                        <p>
                          ２．
                          契約者と前項第1号に定める金融機関との間で利用料金の決済をめぐって紛争が発生した場合、契約者が自らの責任と負担で解決するものとし、当社は一切の責任を負わないものとします。
                        </p>
                        <br />
                        <p>（遅延利息）</p>
                        <p>
                          第２２条
                          契約者が、本サービスの利用料金その他の利用契約等に基づく債務を所定の支払期日が過ぎてもなお履行しない場合、契約者は、所定の支払期日の翌日から支払日の前日までの日数に、年14.6％の利率で計算した金額を延滞利息として、本サービスの料金その他の債務（期限の到来していないものを含みます。）と一括して、当社が指定する期日までに当社の指定する方法により支払うものとします。
                        </p>
                        <p>
                          ２． 前項の支払に必要な振込手数料その他の費用は、契約者の負担とします。
                        </p>
                        <br />
                        <h4 className="text-center">第５章 契約者の義務等</h4>
                        <br />
                        <p>（自己責任の原則）</p>
                        <p>
                          第２３条
                          契約者は、本サービスの利用に伴い、自己の責に帰すべき事由で第三者（国内外を問いません。本条において以下同じとします。）に対して損害を与えた場合、又は第三者からクレーム等の請求がなされた場合、自己の責任と費用をもって処理、解決するものとします。契約者が本サービスの利用に伴い、第三者から損害を被った場合、又は第三者に対してクレーム等の請求を行う場合においても同様とします。
                        </p>
                        <p>
                          ２．
                          契約者は、本サービス及び本サービスにより得られた情報（本サービスの提供に伴い取得される登記情報を含みますがこれに限られません。以下同じとします。）を自らの判断の下に利用するものとし、利用により生じた結果について自ら責任を負うものとし、契約者に適用のある法令、規則等に違反するか否かを自らの責任及び費用に基づいて調査するものとします。本サービスを利用して契約者等が第三者に提供又は伝送する情報（コンテンツ）については、契約者の責任で提供されるものであり、当社はその内容等についていかなる保証も行わず、また、それに起因する損害についてもいかなる責任も負わないものとします。
                        </p>
                        <p>
                          ３．
                          契約者は、本サービスの利用期間中、自らの責任と負担において、契約者により本サービスに入力され、又は本サービスにより生成若しくは出力された情報等について保存、バックアップ等を行うものとします。当社は、当該情報等の保存、バックアップ等を行う義務を負わず、当該情報等の欠落に関して生じた損害（契約者が当該情報等の保存、バックアップ等を怠ったことにより生じた損害を含みますがこれに限られません。）についていかなる責任も負わないものとします。
                        </p>
                        <p>
                          ４．
                          契約者は、契約者等がその故意又は過失により当社に損害を与えた場合、当社に対して当該損害の賠償を行うものとします。
                        </p>
                        <br />
                        <p>（本サービス利用のための設備設定・維持等）</p>
                        <p>
                          第２４条
                          契約者は、自己の費用と責任において、当社が定める条件にて契約者設備を設定し、契約者設備及び本サービス利用のための環境を維持するものとします。
                        </p>
                        <p>
                          ２．
                          契約者は、本サービスを利用するにあたり自己の責任と費用をもって、電気通信事業者等の電気通信サービスを利用して契約者設備をインターネットに接続するものとします。
                        </p>
                        <p>
                          ３．
                          契約者設備、前項に定めるインターネット接続及び本サービス利用のための環境に不具合がある場合、当社は、本サービスの利用不能、障害その他の当該不具合に起因する損害について責任を負わないものとします。
                        </p>
                        <p>
                          ４．
                          当社は、契約者等（契約者が本サービスを利用して提供するサービスの顧客等を含みますがこれに限られません。以下、同じとします。）が本サービスにおいて提供、伝送するデータ等について、次の各号に定める目的の範囲で利用することができます。ただし、本サービスの提供に伴い当社が契約者に代わって取得する登記情報その他のデータ等に含まれる個人、契約者が本サービスを利用して提供するサービスの顧客等の契約者並びに本サービスを利用する契約者の役員及び従業員（以下「ユーザ」といいます。）以外の個人に係る個人情報については、本サービスの保守、運用、監視その他の提供のために必要な範囲に限って利用するものとし、本サービスの提供以外の目的に利用しないものとします。
                        </p>
                        <p>
                          　（１）
                          本サービスの保守、円滑な運用、監視、又は機能、サービス若しくは利便性の分析、調査、改善、向上
                        </p>
                        <p>
                          　（２）
                          本サービスの利用状況及びマーケティング・ニーズの調査、分析及び活用（本サービスの提供以外のための利活用を含みます。以下本項において同じ。）
                        </p>
                        <p>
                          　（３）
                          個人を識別できないように加工した統計情報の作成、及びその統計情報の分析及び活用
                        </p>
                        <p>　（４） 上記の利用目的に付随する目的への利用</p>
                        <br />
                        <p>（ユーザＩＤ及びパスワード）</p>
                        <p>
                          第２５条
                          契約者は、ユーザＩＤ、パスワード又はＡＰＩキーを第三者に開示、貸与、共有しないとともに、第三者に漏洩することのないよう厳重に管理（パスワードの適宜変更を含みます。）するものとします。ユーザＩＤ、パスワード又はＡＰＩキーの管理不備、使用上の過誤、第三者の使用等により契約者その他の者が損害を被った場合、当社は一切の責任を負わないものとします。
                        </p>
                        <p>
                          ２．
                          契約者のユーザＩＤ又はパスワードを利用して行われる本サービスの利用その他の行為は、全て当該ユーザＩＤ又はパスワードにより識別される契約者による行為とみなすものとします。
                        </p>
                        <p>
                          ３．
                          前項の行為により当社又は第三者が損害を被った場合には、契約者は当該損害を補填するものとします。ただし、当社の故意又は過失によりユーザＩＤ又はパスワードが第三者に利用された場合はこの限りではありません。
                        </p>
                        <p>
                          ４．
                          契約者のユーザＩＤ及びパスワードを用いてログインした者に対し、当社が利用を許諾するＡＰＩに関するＡＰＩキーを発行した場合における、当該ＡＰＩキーを利用してなされた行為についても、前二項と同様とします。
                        </p>
                        <br />
                        <p>（禁止事項）</p>
                        <p>
                          第２６条
                          契約者は、本サービスの利用に関し、次の各号のいずれの行為も行わないものとします。
                        </p>
                        <p>
                          　（１）
                          当社若しくは第三者の著作権、商標権等の知的財産権その他の権利を侵害する行為、又は侵害するおそれのある行為
                        </p>
                        <p>
                          　（２）
                          本サービスの内容や本サービスにより利用し得る情報を本サービスの利用以外の目的で利用し、改ざん、複製、翻案、公衆送信又は消去する行為
                        </p>
                        <p>
                          　（３）
                          当社の承諾なく、第三者に本サービスを利用させる行為、第三者への複製、頒布、貸与、公衆送信その他本サービスの利用を再許諾する行為
                        </p>
                        <p>
                          　（４）
                          法令若しくは公序良俗に違反し、若しくは当社、本サービスを利用する他の契約者若しくは第三者に不利益若しくは損害を与える行為、又はそのおそれのある行為
                        </p>
                        <p>
                          　（５）
                          当社若しくは第三者を差別若しくは誹謗中傷し、若しくはその名誉若しくは信用を毀損する行為、又は第三者のプライバシーに関する権利若しくは肖像権を侵害する行為
                        </p>
                        <p>　（６） 詐欺等の犯罪に結びつく又は結びつくおそれがある行為</p>
                        <p>
                          　（７）
                          わいせつ、児童ポルノ又は児童虐待にあたる画像、文書等を送信又は掲載する行為
                        </p>
                        <p>　（８） 無限連鎖講を開設し、又はこれを勧誘する行為</p>
                        <p>　（９） 第三者になりすまして本サービスを利用する行為</p>
                        <p>
                          　（１０）
                          ウィルス等の有害なコンピュータプログラム等を送信又は掲載する行為
                        </p>
                        <p>
                          　（１１）
                          本サービス用設備等のリバースエンジニアリング、逆コンパイル、逆アセンブルその他これらに類する行為
                        </p>
                        <p>
                          　（１２）
                          第三者の設備等又は本サービス用設備等の利用若しくは運営に支障を与える行為、又は与えるおそれのある行為
                        </p>
                        <p>
                          　（１３）
                          その行為が前各号のいずれかに該当することを知りつつ、その行為を助長する態様・目的でリンクをはる行為
                        </p>
                        <p>　（１４） クローリングその他の本サービス用設備等に負担のかかる行為</p>
                        <p>　（１５） 本サービスの運営に支障をきたし、又はそのおそれのある行為</p>
                        <p>　（１６） その他当社が不適切と判断する行為</p>
                        <p>
                          ２．
                          契約者は、前項各号のいずれかに該当する行為がなされたことを知った場合、又は該当する行為がなされるおそれがあると判断した場合は、直ちに当社に通知するものとします。
                        </p>
                        <p>
                          ３．
                          当社は、本サービスの利用に関して、契約者の行為が第１項各号のいずれかに該当するものであること又は契約者等の提供した情報が第１項各号のいずれかの行為に関連する情報であることを知った場合、事前に契約者に通知することなく、本サービスの全部又は一部の提供を一時停止し、又は第１項各号に該当する行為に関連する情報を削除することができるものとします。ただし、当社は、契約者等の行為又は契約者等が提供又は伝送する（契約者の利用とみなされる場合も含みます。）情報（データ、コンテンツを含みます。）を監視する義務を負うものではありません。
                        </p>
                        <br />
                        <h4 className="text-center">第６章 当社の義務等</h4>
                        <br />
                        <p>（非保証）</p>
                        <p>
                          第２７条
                          当社は、本サービス及び本サービスにより得られた情報の正確性、完全性、適時性、最新性、契約者が予定している利用目的への適合性、適法性、安全性等について何ら保証をするものではありません。
                        </p>
                        <p>
                          ２．
                          当社は、当社がその提供時において保有する状態で本サービスを提供するものであり、本サービスが前提とした法令上の諸条件等の正確性、完全性、適合性及び適法性、契約者が本サービスにより得られた情報に基づいて適法な事業を実現できることその他契約者による本サービスの運用について何ら保証をするものではありません。
                        </p>
                        <p>
                          ３．
                          当社は、法律で許容される最大の限度で、本サービス及び本サービスにより得られた情報を利用したことにより契約者又は第三者に生じた損害について一切責任を負いません。
                        </p>
                        <br />
                        <p>（本サービス用設備等の障害等）</p>
                        <p>
                          第２８条
                          当社は、本サービス用設備等に障害があることを知ったときは、相当な期間内に契約者にその旨を通知するものとし、速やかに障害の回復に努めるものとします。
                        </p>
                        <p>
                          ２．
                          当社は、本サービス用設備等のうち、当社が電気通信事業者から提供を受けた電気通信回線等について障害があることを知ったときは、当該電気通信回線を提供する電気通信事業者に修理又は復旧を指示するものとします。
                        </p>
                        <p>
                          ３．
                          上記のほか、本サービスに不具合が発生したときは、契約者及び当社はそれぞれ相当な期間内に相手方に通知し、両者協議の上各自の行うべき対応措置を合意し、それを実施するものとします。
                        </p>
                        <br />
                        <h4 className="text-center">第７章 秘密情報等</h4>
                        <br />
                        <p>（秘密情報）</p>
                        <p>
                          第２９条
                          契約者及び当社は、本サービスに関して相手方より提供を受けた技術上又は営業上その他業務上の情報のうち、相手方が特に秘密である旨あらかじめ書面で指定した情報で、提供の際に秘密情報の範囲を特定し、秘密情報である旨の表示を明記した情報（以下「秘密情報」といいます。）を第三者に開示又は漏洩しないものとします。ただし、相手方からあらかじめ書面による承諾を受けた場合及び次の各号のいずれかに該当する情報についてはこの限りではありません。
                        </p>
                        <p>　（１） 相手方から提供を受ける前に既に保有していた情報</p>
                        <p>　（２） 秘密保持義務を負うことなく第三者から正当に入手した情報</p>
                        <p>　（３） 相手方から提供を受けた情報によらず、独自に開発した情報</p>
                        <p>
                          　（４）
                          利用契約等に違反することなく、かつ、受領の前後を問わず公知となった情報
                        </p>
                        <p>
                          ２．
                          前項の定めにかかわらず、契約者及び当社は、秘密情報のうち法令の定めに基づき又は権限ある官公署からの要求により開示すべき情報を、当該法令の定めに基づく開示先又は当該官公署に対して開示することができるものとします。この場合、契約者及び当社は、関連法令に反しない限り、当該開示前に開示する旨を相手方に通知するものとし、開示前に通知を行うことができない場合は開示後速やかに通知するものとします。
                        </p>
                        <p>
                          ３．
                          秘密情報の提供を受けた当事者は、当該秘密情報の管理に必要な措置を講ずるものとします。
                        </p>
                        <p>
                          ４．
                          秘密情報の提供を受けた当事者は、相手方より提供を受けた秘密情報を本サービス遂行目的の範囲内でのみ使用し、本サービス遂行上必要な範囲内で秘密情報を化体した資料等（以下本条において「資料等」といいます。）を複製又は改変（以下本項においてあわせて「複製等」といいます。）することができるものとします。この場合、契約者及び当社は、当該複製等された秘密情報についても、本条に定める秘密情報として取り扱うものとします。なお、本サービス遂行上必要な範囲を超える複製等が必要な場合は、あらかじめ相手方から書面による承諾を受けるものとします。
                        </p>
                        <p>
                          ５．
                          秘密情報の提供を受けた当事者は、相手方の要請があったときは、相手方の指示に従い、資料等（前項に基づき相手方の承諾を得て複製、改変した秘密情報を含みます。）を相手方に返還又は廃棄し、秘密情報が契約者設備又は本サービス用設備に蓄積されている場合はこれを完全に消去するものとします。この場合において、契約者及び当社は、相手方が当該廃棄又は消去について証明書の提出を要請する場合、これに応じるものとします。
                        </p>
                        <p>
                          ６． 本条の規定は、本サービス終了後、3年間有効に存続するものとします。
                        </p>
                        <br />
                        <p>（個人情報）</p>
                        <p>
                          第３０条
                          契約者は、本サービスに関して当社より提供を受けた個人情報（個人情報の保護に関する法律に定める「個人情報」をいいます。以下同じとします。）を本サービスの利用に必要な範囲内でのみ使用し、第三者に開示又は漏洩しないものとするとともに、個人情報の保護に関する法律を含め関連法令を遵守するものとします。
                        </p>
                        <p>
                          ２．
                          当社は、本サービスに関して契約者から取得し、又は提供を受けた個人情報の取扱いについては、別紙1のTASUKI
                          TECH プライバシーポリシーに従うものとします。
                        </p>
                        <br />
                        <p>（公表）</p>
                        <p>
                          第３１条
                          第29条の定めにかかわらず、当社は、当社ウェブサイトその他の広告媒体及びIR資料等にて、当社が適当と判断する態様により、契約者が本サービスの利用者であることを第三者に開示し又は公表することができるものとします。
                        </p>
                        <br />
                        <p>（電子メールの送信）</p>
                        <p>
                          第３２条
                          当社は、契約者並びにユーザとして電子メールアドレスの登録を行われた契約者の役員及び従業員に対し、サービスの機能改善、仕様変更、保守その他の本サービスに関するご案内及び当社の商品又はサービスに関する広告宣伝を内容とする電子メールを送信できるものとし、契約者は、これに同意するものとします。
                        </p>
                        <p>
                          ２．
                          契約者は、自らの責任と負担において、前項に定める電子メールアドレスの登録前に、当社による電子メールの送信について前項の役員及び従業員から同意を得るものとします。
                        </p>
                        <br />
                        <h4 className="text-center">第８章 損害賠償等</h4>
                        <br />
                        <p>（損害賠償の制限）</p>
                        <p>
                          第３３条
                          債務不履行責任、不法行為責任、その他法律上の請求原因の如何を問わず、本サービス又は利用契約等に関して、当社が契約者に対して負う損害賠償責任の範囲は、当社の責に帰すべき事由により又は当社が利用契約等に違反したことが直接の原因で契約者に現実に発生した直接かつ通常の損害に限定され、損害賠償の額は次の各号に定める額を超えないものとします。ただし、契約者の当社に対する損害賠償請求は、契約者による対応措置が必要な場合には契約者が第28条（本サービス用設備等の障害等）第3項等に従い対応措置を実施したときに限り行えるものとします。なお、当社は、法律で許容される最大の限度で、当社の責に帰すことができない事由から生じた損害、当社の予見の有無を問わず特別の事情から生じた損害（契約者の事業機会の損失を含みます。）、間接損害、逸失利益について賠償責任を負わないものとします。
                        </p>
                        <p>
                          　（１）
                          当該事由が生じた月の前月末日から初日算入にて起算して、過去12ヶ月間に発生した当該本サービスに係わる料金の平均月額料金（1ヶ月分）
                        </p>
                        <p>
                          　（２）当該事由が生じた月の前月末日から初日算入にて起算して本サービスの開始日までの期間が１ヶ月以上ではあるが12ヶ月に満たない場合には、当該期間（1月未満は切捨て）に発生した当該本サービスに係わる料金の平均月額料金（1ヶ月分）
                        </p>
                        <p>
                          　（３）前各号に該当しない場合には、当該事由が生じた日の前日までの期間に発生した当該本サービスに係わる料金の平均日額料金（1日分）に30を乗じた額
                        </p>
                        <br />
                        <p>（免責）</p>
                        <p>
                          第３４条
                          本サービス又は利用契約等に関して当社が負う責任は、理由の如何を問わず前条の範囲に限られるものとし、当社は、以下の事由により契約者等に発生した損害については、債務不履行責任、不法行為責任、その他の法律上の請求原因の如何を問わず賠償の責任を負わないものとします。
                        </p>
                        <p>　（１） 第18条第2項第3号から第5号に起因する損害</p>
                        <p>
                          　（２）
                          天災地変、火災、感染症の流行（流行拡大防止のために必要な措置を含みます。）、法令の改正、戦争、騒乱、暴動、労働争議等の不可抗力
                        </p>
                        <p>
                          　（３）
                          契約者設備の障害又は本サービス用設備までのインターネット接続サービスの不具合等の契約者の接続環境の障害
                        </p>
                        <p>
                          　（４）
                          本サービス用設備等からの応答時間等のインターネット接続サービスの性能値に起因する損害
                        </p>
                        <p>
                          　（５）
                          当社が第三者から導入しているコンピュータウィルス対策ソフトについて当該第三者からウィルスパターン、ウィルス定義ファイル等を提供されていない種類のコンピュータウィルスの本サービス用設備等への侵入
                        </p>
                        <p>
                          　（６）
                          善良なる管理者の注意をもってしても防御し得ない本サービス用設備等への第三者による不正アクセス又はアタック、通信経路上での傍受
                        </p>
                        <p>
                          　（７）
                          当社が定める手順・セキュリティ手段等を契約者等が遵守しないことに起因して発生した損害
                        </p>
                        <p>
                          　（８）
                          本サービス用設備等のうち当社の製造に係らないソフトウェア(OS、ミドルウェア、DBMS(データベース管理システム))及びデータベースに起因して発生した損害
                        </p>
                        <p>
                          　（９）
                          本サービス用設備等のうち、当社の製造に係らないハードウェア又は機器に起因して発生した損害
                        </p>
                        <p>
                          　（１０）
                          電気通信事業者の提供する電気通信役務の不具合に起因して発生した損害
                        </p>
                        <p>
                          　（１１）
                          刑事訴訟法第218条（令状による差押え・捜索・検証）、犯罪捜査のための通信傍受に関する法律の定めに基づく強制の処分その他の裁判所の命令若しくは法令に基づく強制的な処分
                        </p>
                        <p>　（１２） 当社の責に帰すべからざる事由による事故</p>
                        <p>
                          　（１３）
                          再委託先の業務に関するもので、再委託先の選任・監督につき当社に帰責事由がない場合
                        </p>
                        <p>
                          　（１４）
                          当社が本サービスを提供するために利用する第三者の提供するサービス（一般社団法人民事法務協会の提供する登記情報提供サービスを含みますがこれに限られません。以下「第三者サービス」といいます。）の停止又は接続障害、第三者サービスにおける情報の滅失、毀損又は漏えいその他第三者サービスに起因して発生した損害
                        </p>
                        <p>　（１５） その他当社の責に帰すべからざる事由</p>
                        <p>
                          ２．
                          当社は、契約者等が本サービスを利用することにより契約者と第三者との間で生じた紛争等について一切責任を負わないものとします。
                        </p>
                        <br />
                        <p>改定日：2024年4月8日</p>
                        <p>株式会社ZISEDAI</p>
                        <br />
                        <p>所在地：　〒107-0061東京都港区北青山2-7-9日昭ビル2F</p>
                        <p>会社名：　株式会社ZISEDAI</p>
                        <p>代表者：　代表取締役社長　柏村　雄</p>
                        <br />
                        <br />
                        <div className="text-center">
                          <ul className="text-sm select-none">
                            <li className="inline-block">
                              <a className="font-medium text-primary-font" href="/">
                                トップ
                              </a>
                              <span className="mx-1.5">/</span>
                            </li>
                            <li className="inline-block">
                              <a
                                className="font-medium text-primary-font"
                                href="/static_pages/privacy"
                              >
                                プライバシーポリシー
                              </a>
                              <span className="mx-1.5">/</span>
                            </li>
                            <li className="inline-block">
                              <a
                                className="font-medium text-primary-font"
                                href="/static_pages/terms_of_service"
                              >
                                利用規約
                              </a>
                              <span className="mx-1.5">/</span>
                            </li>
                            <li className="inline-block">
                              <a className="font-medium text-primary-font" href="https://zised.ai/">
                                会社情報
                              </a>
                            </li>
                          </ul>
                          <small className="text-muted">&copy; zised.ai</small>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </main>
          </div>
        </div>
      </div>
    </div>
  )
}

export default StaticPageTermsOfServicePage
