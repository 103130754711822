import * as React from 'react'
import { Grid, Line, OrbitControls } from "@react-three/drei";
import { Canvas, useLoader, useThree } from "@react-three/fiber";
import { FC, useMemo } from "react";
import { TextureLoader, Vector2, Vector3 } from "three";
import { GLTFExporter } from 'three/examples/jsm/exporters/GLTFExporter';
import { computeBoundingBox, Drawing } from "../Drawing";
import { Floor } from "./Floor";
import { SkyFactor } from "./SkyFactor";
import { BirdCage } from "./BirdCage";

type Drawing3dViewProps = {
  drawing: Drawing;
  selectedSkyFactor?: number[];
  handleSetGltfJson;
  dxf_model: string;
  isBirdCage: boolean;
  underGroundBuildingHeight: number;
};

const Drawing3dView: FC<Drawing3dViewProps> = ({
  drawing,
  selectedSkyFactor,
  handleSetGltfJson,
  dxf_model,
  isBirdCage,
  underGroundBuildingHeight
}) => {

  const floors = useMemo(() => {
    const { 
      floors
    } = drawing;
    let accumlatedHeight = 0;
    return floors.map((f) => {
      const height = accumlatedHeight - underGroundBuildingHeight * 1e3;

      const { constructable_area: { shapes }, height: h }  = f;
      const polylines = shapes.map(({ polyline: { points } }) => {
        return points.map(([x, y]) => {
          return new Vector3(x, y, height);
        });
      });
      accumlatedHeight += h;
      return {
        polylines,
        height,
        floorHeight: h,
      };
    });
  }, [drawing]);

  const balcony = useMemo(() => {
    return drawing.balcony?.map(({ points }) => {
      return points.map(([x, y]) => {
        return new Vector2(x, y);
      });
    }) ?? [];
  }, [drawing]);

  const border = useMemo(() => {
    const { border_lines } = drawing;
    const enclosed = border_lines.flatMap((line) => {
      if ('Road' in line) {
        return [line.Road.inner_segment.a, line.Road.inner_segment.b];
      }
      return [line.Neighbor.a, line.Neighbor.b];
    }).map(([x, y]) => new Vector3(x, y, 0));
    return enclosed;
  }, [drawing]);

  const azimith = useLoader(TextureLoader, '/images/azimith.png')
  const boundingBox = useMemo(() => {
    return computeBoundingBox(drawing.border_lines);
  }, [drawing]);
  const azimithSize = 2500;
  const azimithPadding = 500;
  const azimithPosition = useMemo(() => {
    return new Vector2(
      boundingBox.min.x - azimithSize - azimithPadding,
      boundingBox.min.y - azimithSize - azimithPadding,
    );
  }, [boundingBox, azimithSize, azimithPadding]);

  //Get 3D build Scene
  const Scene = () => {
    useThree(({ scene }) => {
      exportGltf(scene)
    }); // Get 3D build Scene
    return null;
  };

  //Export GltfJson data
  const exportGltf = (scene:THREE.Scene) => {
    const exporter = new GLTFExporter(); // Creating a GLTFExporter Instance
    const options = {
      trs: true,//Export each node's position, rotation and scale instead of matrices. Defaults to false.
      onlyVisible: true,//Export only visible objects. Defaults to true.
      binary: false,//Export in binary (.glb) format, returning an ArrayBuffer. Defaults to false.
      includeCustomExtensions: true//Export custom glTF extensions defined on object properties (userData.gltfExtensions). Defaults to false.
    };

    exporter.parse(
      scene, 
      (gltf) => {
        handleSetGltfJson(JSON.stringify(gltf));
      }, 
      (error) => {
        console.log( 'An error happened on GLTFExporter' );
      },
      options
    );
  }

  return <Canvas linear={false} shadows camera={{ position: [-30, 30, 30], fov: 25, up: [0, 0, 1] }}>
    <color attach="background" args={[1, 1, 1]} />
    <ambientLight intensity={0.1} />
    <OrbitControls
      autoRotate
      autoRotateSpeed={0.05}
      enableZoom={true}
      minDistance={1}
      maxDistance={100}
      makeDefault
    />
    <group name='delFromMap-Grid'>
      <Grid
        rotation={[Math.PI / 2, 0, 0]}
        args={[10, 10]}
        infiniteGrid
        followCamera
        cellColor={'#e0e0e0'}
        sectionColor={'#f0f0f0'}
      />
    </group>
    <group scale={1e-3}>
      { 
        isBirdCage && (<BirdCage dxfContent={dxf_model}/>)
      }
      <SkyFactor skyFactor={drawing.sky_factor} selected={selectedSkyFactor} />
      <Line points={border} color="black" />
      <group name="delFromMap-Icon">
        <mesh position={[azimithPosition.x, azimithPosition.y, 50]}>
          <planeGeometry args={[azimithSize, azimithSize]}/>
          <meshBasicMaterial map={azimith} transparent color={'#f0f0f0'}/>
        </mesh>
      </group>
      {
        drawing.border_lines.map((line, i) => {
          if ('Road' in line) {
            const road = line.Road;
            const { a, b } = road.set_back_outer_segment ?? road.outer_segment;
            return <Line key={`offset-road-border-${i}`} points={[
              new Vector3(a[0], a[1], 0),
              new Vector3(b[0], b[1], 0),
            ]} color={'black'} />;
          }
          return undefined;
        })
      }
      {
        floors.map((props, i) => {
          return <Floor key={`floor-${i}`} {...props} balcony={balcony} floorIndex={i + 1} maxFloor={floors.length} border={border}/>;
        })
      }
    </group>
    <Scene/>
  </Canvas>;
};

export {
  Drawing3dView
}