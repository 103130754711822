import * as React from 'react';

type LoadSpinnerProps = {
  isLoading: boolean;
  message?: string;
  width?: string;
  height?: string;
  animation_spinner_time?: string;
  animation_dot_time?: string;
  animation_spinner_finish?: string;
  animation_dot_finish?: string;
  font_size?: string;
  border?: string;
  border_top?: string;
  dot_first?: string;
  dot_second?: string;
  dot_third?: string;
  showSpinner?: boolean;
  showDots?: boolean;
};

export const LoadSpinner: React.FC<LoadSpinnerProps> = ({
  message = "通信中",
  isLoading,
  width = "40px",
  height = "40px",
  animation_spinner_time = "1s",
  animation_dot_time = "0.6s",
  animation_spinner_finish = "infinite",
  animation_dot_finish = "infinite",
  font_size = "24px",
  border = '4px solid #ccc',
  border_top = '4px solid #333',
  dot_first = '0s',
  dot_second = '0.2s',
  dot_third = '0.4s',
  showSpinner = true,
  showDots = true
}) => {
  //////////////////////////////////////////
  // 通信の遅延時間が気になるポイントに主に利用。//
  // ドットとスピナー片方だけ表示可能          //
  /////////////////////////////////////////
  const spinnerStyle: React.CSSProperties = {
    width: width,
    height: height,
    border: border,
    borderTop: border_top,
    borderRadius: '50%',
    animation: `spin ${animation_spinner_time} linear ${animation_spinner_finish}`,
  };

  const globalStyle = `
    @keyframes spin {
      0% { transform: rotate(0deg); }
      100% { transform: rotate(360deg); }
    }
    @keyframes bounce {
      0%, 100% { transform: translateY(0); }
      50% { transform: translateY(-10px); }
    }
    .dot {
      display: inline-block;
      font-size: ${font_size};
      animation: bounce ${animation_dot_time} ${animation_dot_finish};
    }
    .dot:nth-child(1) { animation-delay: ${dot_first}; }
    .dot:nth-child(2) { animation-delay: ${dot_second}; }
    .dot:nth-child(3) { animation-delay: ${dot_third}; }
  `;

  return (
    <div>
      <style>{globalStyle}</style>
      {isLoading && (
        <div className='flex'>
          {showSpinner && <div style={spinnerStyle}></div>}
          {showDots && (
            <p>{message}
              <span className="dot">.</span>
              <span className="dot">.</span>
              <span className="dot">.</span>
            </p>
          )}
        </div>
      )}
    </div>
  );
};
