import { cammedFormat, dataPrecisionSetup } from '@/utils/cammedFormat'

const m3_tsubo_ratio = 0.3025

export const calculateTsubo = (value: number): string => {
  return cammedFormat(Math.floor(value * m3_tsubo_ratio * 100) / 100, 2)
}

export const calculateUnitPrice = (price: string, area: string, exp = 0): string => {
  if (!price || !area) {
    return
  }

  const priceValue = dataPrecisionSetup(price, 12, 0)
  const areaValue = dataPrecisionSetup(area, 12, 2)

  return cammedFormat(Math.floor(priceValue / areaValue), exp)
}
