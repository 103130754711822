export function generateCircularIconDataURI(color: string, text: string) {
  return (
    'data:image/svg+xml;charset=UTF-8;base64,' +
    btoa(`<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="32" height="32" viewBox="0 0 32 32">
  <defs>
    <clipPath id="b">
      <rect width="32" height="32" />
    </clipPath>
  </defs>
  <g id="a" clip-path="url(#b)">
    <g fill="${color}" stroke="#fff" stroke-width="3">
      <circle cx="16" cy="16" r="16" stroke="none" />
      <circle cx="16" cy="16" r="14.5" fill="none" />
    </g>
    <text fill="#fff" font-size="14" font-family="ArialMT, Arial">
      <tspan x="50%" y="50%" text-anchor="middle" dominant-baseline="central"  >${text}</tspan>
    </text>
  </g>
</svg>`)
  )
}
