import * as React from 'react'
import InputForm from './InputForm'
import { formatLatLngs } from './formatLatLngs'
import VolumeCheckModal from './VolumeCheckModal'

type Props = {
  property: any
  propertyShape: any
  onChangeParameters?: () => void
  ref_volume_check_parameters?: any
  authenticity_token: string
}

const borderTypeCodeOptions = [
  {
    value: '4',
    text: '隣地',
  },
  {
    value: '2',
    text: '私道(隣地)',
  },
  {
    value: '1',
    text: '公道',
  },
  {
    value: '3',
    text: '私道(道路)',
  },
  {
    value: '5',
    text: '線路',
  },
  {
    value: '6',
    text: '水路',
  },
  {
    value: '7',
    text: '公園',
  },
]

const setBackMethodOptions = [
  {
    value: '0',
    text: '適用外',
  },
  {
    value: '1',
    text: '中心後退',
  },
  {
    value: '2',
    text: '一方後退(敷地内)',
  },
  {
    value: '3',
    text: '一方後退(敷地外)',
  },
]

type LatLng = number[]

const BordersForm: React.FC<Props> = ({
  property,
  propertyShape,
  onChangeParameters,
  ref_volume_check_parameters,
  authenticity_token,
}) => {
  let latlngs: { latlng: LatLng; isChecked: boolean }[]

  if (propertyShape) {
    const latlngsFromPropertyShape = JSON.parse(propertyShape)
    latlngs = formatLatLngs(latlngsFromPropertyShape, 'BordersForm')
  } else {
    const parameters = ref_volume_check_parameters ? JSON.parse(ref_volume_check_parameters) : null

    if (parameters && parameters.borders) {
      latlngs = parameters.borders
        .sort((a, b) => a.id - b.id)
        .map((border) => {
          const surrounding = parameters.surroundings.find((sur) => sur.border_id === border.id)
          const isChecked = surrounding && surrounding.width !== 0

          return {
            latlng: [border.to.lat, border.to.lng],
            isChecked: isChecked,
          }
        })
    } else {
      latlngs = (property?.shape?.latlngs ?? [])
        .sort((a, b) => a.no - b.no)
        .map((latlng) => {
          return {
            latlng: [latlng.lat, latlng.lng],
            isChecked: false,
          }
        })
    }
  }

  const [isCheckedStatus, setIsCheckedStatus] = React.useState(() => {
    const initialStatus = {}
    latlngs.forEach((latlng, index) => {
      initialStatus[index] = latlng.isChecked
    })
    return initialStatus
  })

  const handleCheckboxChange = (index) => (event) => {
    setIsCheckedStatus((prevStatus) => ({ ...prevStatus, [index]: event.target.checked }))
    onChangeParameters()
  }

  return (
    <div>
      <input type="hidden" id="count_borders" name="count_borders" value={latlngs.length} />
      <div className="">
        {latlngs.map((item, i) => {
          const next_i = i == latlngs.length - 1 ? 0 : i + 1
          return (
            <div key={i} className="py-4">
              <div className="my-2 flex justify-between items-center gap-4">
                <label htmlFor={`length_${i}`} className="w-1/3">
                  <strong className="text-base">
                    点{i + 1} - 点{next_i + 1}
                  </strong>
                </label>
                <div className="w-2/3 flex items-center">
                  <div className="flex-grow flex items-center">
                    <input
                      type="text"
                      id={`length_${i}`}
                      name={`length_${i}`}
                      className="py-1 px-3 w-full text-sm"
                      onChange={onChangeParameters}
                    />
                  </div>
                  <span
                    className="text-sm"
                    style={{ flexShrink: 0, whiteSpace: 'nowrap', flexDirection: 'row' }}
                  >
                    <VolumeCheckModal
                      title="寸法修正"
                      property={property}
                      idx={i}
                      nextIdx={next_i}
                      latlngA={item.latlng}
                      latlngB={latlngs[next_i].latlng}
                      authenticity_token={authenticity_token}
                      changeParameters={onChangeParameters}
                    />
                  </span>
                  <input
                    type="hidden"
                    id={`from_${i}`}
                    name={`from_${i}`}
                    value={item.latlng.join(',')}
                  />
                  <input
                    type="hidden"
                    id={`to_${next_i}`}
                    name={`to_${next_i}`}
                    value={latlngs[next_i].latlng.join(',')}
                  />
                </div>
              </div>
              <div className="my-2 flex justify-between items-center gap-4">
                <label htmlFor={`type_${i}`} className="w-1/3">
                  <span className="text-xs">タイプ</span>
                </label>
                <div className="w-2/3">
                  <select
                    id={`border_${i}`}
                    name={`border_${i}`}
                    className={[styles.select].join(' ')}
                    onChange={onChangeParameters}
                  >
                    {borderTypeCodeOptions.map((option, i) => (
                      <option key={i} value={option.value}>
                        {option.text}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="hidden [&.show]:block" id={`width_${i}_collapse`}>
                <InputForm
                  name={`width_${i}`}
                  title="幅員"
                  unit="m"
                  onChange={onChangeParameters}
                />
              </div>
              <div className="hidden [&.show]:block" id={`setback_${i}_collapse`}>
                <div className="my-2 flex justify-between items-center gap-4">
                  <label htmlFor={`setback_${i}`} className="w-1/3">
                    <span className="text-xs">後退方法</span>
                  </label>
                  <div className="w-2/3">
                    <select
                      id={`setback_${i}`}
                      name={`setback_${i}`}
                      className={[styles.select].join(' ')}
                      onChange={onChangeParameters}
                    >
                      {setBackMethodOptions.map((option, i) => (
                        <option key={i} value={option.value}>
                          {option.text}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
              <div id={`surroundings_${i}_border`} className="[&.border]:py-2 [&.border]:px-3">
                <div>
                  <label
                    htmlFor={`surroundings_${i}_check`}
                    className="flex relative cursor-pointer"
                  >
                    <input
                      className="opacity-0 absolute w-0"
                      type="checkbox"
                      id={`surroundings_${i}_check`}
                      name={`surroundings_${i}_check`}
                      onChange={handleCheckboxChange(i)}
                      checked={isCheckedStatus[i] || false}
                    />
                    <span
                      className={[
                        styles.checkbox,
                        "[input:checked+&]:bg-primary [input:checked+&]:border-primary [input:checked+&]:bg-[url('~icons/checkbox_icon.svg')]",
                      ].join(' ')}
                    >
                      <span />
                    </span>
                    <span className="text-xs">周辺環境の設定</span>
                  </label>
                </div>
                <div className="hidden [&.show]:block" id={`surroundings_${i}_collapse`}>
                  <div className="my-2 flex justify-between items-center gap-4">
                    <label htmlFor={`surroundings_${i}`} className="w-1/3">
                      <span className="text-xs">タイプ</span>
                    </label>
                    <div className="w-2/3">
                      <select
                        id={`surroundings_${i}`}
                        name={`surroundings_${i}`}
                        className={[styles.select].join(' ')}
                        onChange={onChangeParameters}
                      >
                        {borderTypeCodeOptions.map((option, i) => (
                          <option key={i} value={option.value}>
                            {option.text}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div id={`surroundings_width_${i}_collapse`}>
                    <InputForm
                      name={`surroundings_${i}_width`}
                      title="幅員"
                      unit="m"
                      onChange={onChangeParameters}
                    />
                  </div>
                </div>
              </div>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default BordersForm

const styles = {
  select: `
    w-full
    py-2
    pl-4
    pr-10
    text-sm
    appearance-none
    bg-no-repeat
    bg-center
    border
    rounded-sm
    bg-white
    outline-none
    leading-normal
    text-black-base
    border-gray-border
    placeholder-gray-300
    bg-[url('~icons/caret_down.svg')]
    bg-[center_right_12px]
    disabled:text-gray-300
    disabled:bg-gray-light
    disabled:cursor-not-allowed
    disabled:opacity-70
  `,
  checkbox: `
    relative
    inline-block
    appearance-none
    h-3.5
    w-3.5
    border
    focus:outline-none
    mt-0.5
    text-base
    align-top
    bg-no-repeat
    bg-center
    mr-2
    cursor-pointer
    rounded-sm
  `,
  checked: `
    bg-primary
    border-primary
    bg-[url('~icons/checkbox_icon.svg')]
  `,
}
