import * as React from 'react'

type Option = {
  text: string
  value: string | number
}

interface Props extends Omit<React.SelectHTMLAttributes<HTMLSelectElement>, 'size'> {
  id?: string
  suffix?: React.ReactNode
  prefix?: React.ReactNode
  size?: string
  options?: Option[]
  hasBlank?: boolean
  blankLabel?: string
}

export const Select: React.FC<Props> = ({
  id,
  name,
  placeholder,
  required,
  className,
  suffix,
  prefix,
  size = 'default',
  hasBlank = false,
  blankLabel = '選択してください',
  options = [],
  value,
  onChange,
  ...props
}) => {
  const sizeStyle = styles.size[size]

  return (
    <div className={suffix || prefix ? [styles.inputWrap, className].join(' ') : ''}>
      {prefix && <span className={[styles.box, 'rounded-r-none'].join(' ')}>{prefix}</span>}
      <select
        id={id}
        name={name}
        placeholder={placeholder}
        value={value}
        required={required}
        className={[
          styles.select,
          sizeStyle,
          className,
          prefix && styles.prefix,
          suffix && styles.suffix,
        ].join(' ')}
        onChange={onChange}
        {...props}
      >
        {hasBlank && <option value="">{blankLabel}</option>}
        {options.map((option, i) => (
          <option key={i} value={option.value}>
            {option.text}
          </option>
        ))}
      </select>
      {suffix && <span className={[styles.box, 'rounded-l-none'].join(' ')}>{suffix}</span>}
    </div>
  )
}

const styles = {
  size: {
    default: `
      py-2
      pl-4
      pr-10
      text-sm
    `,
    small: `
      py-2
      pl-3
      pr-10
      text-xs
    `,
  },
  select: `
    w-full
    appearance-none
    bg-no-repeat
    bg-center
    border
    rounded-sm
    bg-white
    outline-none
    leading-normal
    text-black-base
    border-gray-border
    placeholder-gray-300
    bg-[url('~icons/caret_down.svg')]
    bg-[center_right_12px]
    disabled:text-gray-300
    disabled:bg-gray-light
    disabled:cursor-not-allowed
    disabled:opacity-70
  `,
  inputWrap: `
    relative
    flex
    items-strech
  `,
  prefix: `
    rounded-l-none
    border-l-0
  `,
  suffix: `
    rounded-r-none
    border-r-0
    flex
    flex-auto
    placeholder-gray-250
    placeholder:text-sm
  `,
  box: `
    flex
    items-center
    border
    border-gray-border
    rounded-sm
    bg-gray-100
    py-2
    px-4
    text-sm
    leading-normal
    text-black-base
    whitespace-nowrap
  `,
}
