import * as React from 'react'
import { dataPrecisionSetup } from '@/utils/cammedFormat'
import { Table, Row, Th, Td } from '@/components/Table'
import { Input } from '@/components/Input'
import { Select } from '@/components/Select'
import { Button } from '@/components/Button'
import {
  directionOptions,
  roadTypeOptions,
  buildingStandardsLawArticlesOptions
} from '@/models/road_access'

type RoadAccessRowProps = {
  index: number
  road_access: object
  data: any
  setData: (data: object) => void
  dataLatLng: LatLng
  defaultRoadAccessData: object
  requestParamName: string
  projectsLayouts?: string[]
}

export const RoadAccessRow: React.FC<RoadAccessRowProps> = (props) => {
  const {
    index, road_access, data, setData, dataLatLng, defaultRoadAccessData,
    requestParamName, projectsLayouts
  } = props
  const onChangeData = (value, index, column) => {
    const road_accesses = [...data.road_accesses]
    road_accesses.splice(index, 1, {
      ...road_accesses[index],
      [column]: value,
    })
    setData({...data, road_accesses})
  }
  const onClickAddRoadAccessBlock = (e) => {
    e.preventDefault()
    setData({
      ...data,
      road_accesses: [...data.road_accesses, defaultRoadAccessData],
    })
  }
  const onCLickDeleteRoadAccessBlock = (e) => {
    e.preventDefault()
    const newRoadAccesses = [...data.road_accesses]
    newRoadAccesses.splice(index, 1)
    setData({
      ...data,
      road_accesses: newRoadAccesses,
    })
  }
  // TEMPORARY: replacing "mins" because after OCR released, "mins" automatically attached as suffix in form value.
  const replaceOcrInsertString = (value) => {
    return value ? value.toString().replace(/mins|min/, '').trim() : ''
  }

  return (
    <Row label={projectsLayouts ?
      <Th projects_layouts={projectsLayouts}>道路{index > 0 && ` ${index + 1}`}</Th>:
      <Th>道路{index > 0 && ` ${index + 1}`}</Th>
    }>
      <Td>
        <div className="py-1 flex gap-4 flex-col md:flex-row">
          <Select
            className="md:w-[300px]"
            value={road_access.direction || ''}
            name={`${requestParamName}[direction][]`}
            id={`${requestParamName}_direction`}
            options={directionOptions}
            hasBlank
            blankLabel="(未設定)"
            suffix="側"
            onChange={(e) => onChangeData(e.target.value, index, 'direction')}
          />
          <Select
            className="md:w-[300px]"
            value={road_access.road_type || ''}
            name={`${requestParamName}[road_type][]`}
            id={`${requestParamName}_road_type`}
            options={roadTypeOptions}
            hasBlank
            blankLabel="(未設定)"
            suffix="道"
            onChange={(e) => onChangeData(e.target.value, index, 'road_type')}
          />
        </div>
        <div className="py-1 flex gap-4 flex-col md:flex-row">
          <Input
            multiple
            className="md:w-[300px]"
            value={road_access.width || ''}
            name={`${requestParamName}[width][]`}
            id={`${requestParamName}_width`}
            prefix="幅員 約"
            suffix="ｍ"
            onChange={(e) => onChangeData(e.target.value, index, 'width')}
            onBlur={(e) => onChangeData(dataPrecisionSetup(e.target.value, 8, 2), index, 'width')}
          />
          <Input
            multiple
            className="md:w-[300px]"
            value={road_access.frontage || ''}
            name={`${requestParamName}[frontage][]`}
            id={`${requestParamName}_frontage`}
            prefix="間口 約"
            suffix="ｍ"
            onChange={(e) => onChangeData(e.target.value, index, 'frontage')}
            onBlur={(e) => onChangeData(dataPrecisionSetup(e.target.value, 8, 2), index, 'frontage')}
          />
        </div>
        <div className="py-1 flex gap-4 flex-col md:flex-row">
          <Select
            className="md:w-[300px]"
            value={road_access.building_standards_law_articles || ''}
            name={`${requestParamName}[building_standards_law_articles][]`}
            id={`${requestParamName}_building_standards_law_articles`}
            options={buildingStandardsLawArticlesOptions}
            hasBlank
            blankLabel="(未設定)"
            prefix="道路種別"
            onChange={(e) => onChangeData(e.target.value, index, 'building_standards_law_articles')}
          />
        </div>
        <div className="flex gap-2 mt-2">
          {data?.road_accesses.length !== 1 && (
            <Button
              outline
              variant="red"
              size="small"
              onClick={(e) => onCLickDeleteRoadAccessBlock(e)}
            >
              削除
            </Button>
          )}
          {data?.road_accesses.length === index + 1 && (
            <Button
              outline
              size="small"
              onClick={(e) => onClickAddRoadAccessBlock(e)}
            >
              追加
            </Button>
          )}
        </div>
      </Td>
    </Row>
  )
}
