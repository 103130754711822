export const downloadText = (name: string, text: string) => {
  downloadBlob(name, new Blob([text], { type: 'text/plain' }));
};

export const downloadBlob = (name: string, blob: Blob) => {
  const url = window.URL.createObjectURL(blob);
  downloadHref(name, url);
  window.URL.revokeObjectURL(url);
};

export const downloadHref = (name: string, url: string) => {
  const a = document.createElement('a');
  const e = document.createEvent('MouseEvent');
  a.download = name;
  a.href = url;

  e.initEvent('click', true, true);
  a.dispatchEvent(e);
};
