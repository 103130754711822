import * as React from 'react'
import { formatLatLngs } from './formatLatLngs'
import { Row, Th, Td } from '@/components/Table'
import { Input } from '@/components/Input'
import { styled } from '@mui/system'
import Link from '@mui/icons-material/Link'
import LinkOff from '@mui/icons-material/LinkOff'

type Props = {
  property: any
  propertyShape: any
  onChangeParameters?: () => void
  ref_volume_check_parameters?: any
}

type LatLng = number[]

const roadNumber = [
  '①',
  '②',
  '③',
  '④',
  '⑤',
  '⑥',
  '⑦',
  '⑧',
  '⑨',
  '⑩',
  '⑪',
  '⑫',
  '⑬',
  '⑭',
  '⑮',
  '⑯',
  '⑰',
  '⑱',
  '⑲',
  '⑳',
]
const StyledLink = styled(Link)({
  transform: 'rotate(90deg)',
})
const StyledLinkOff = styled(LinkOff)({
  transform: 'rotate(90deg)',
})
const LatLngForm3: React.FC<Props> = ({
  property,
  propertyShape,
  onChangeParameters,
  ref_volume_check_parameters,
}) => {
  let latlngs: LatLng[]

  if (propertyShape) {
    const latlngsFromPropertyShape = JSON.parse(propertyShape)
    latlngs = formatLatLngs(latlngsFromPropertyShape, 'ExtendsForm')
  } else {
    const parameters = ref_volume_check_parameters ? JSON.parse(ref_volume_check_parameters) : null

    if (parameters && parameters.borders) {
      latlngs = parameters.borders
        .sort((a, b) => a.id - b.id)
        .map((border) => [border.to.lat, border.to.lng])
    } else {
      latlngs = (property?.shape?.latlngs ?? [])
        .sort((a, b) => a.no - b.no)
        .map((latlng) => [latlng.lat, latlng.lng])
    }
  }

  return (
    <>
      {latlngs.map((item, i) => (
        <Row key={i} label={<Th>隣地</Th>}>
          <Td>
            <div className="flex gap-4 flex-col md:flex-row">
              <Input
                className="md:w-[300px]"
                id={`height_${i}`}
                name={`height_${i}`}
                prefix={`点${i + 1}`}
                suffix="m"
                onChange={onChangeParameters}
              />
              <div className="flex items-center">
                <StyledLink fontSize="small" />
              </div>
              {i !== latlngs.length - 1 ? (
                <Input
                  className="md:w-[300px]"
                  id={`height_${i}`}
                  name={`height_${i}`}
                  prefix={`点${i + 2}`}
                  suffix="m"
                  onChange={onChangeParameters}
                />
              ) : (
                <Input
                  className="md:w-[300px]"
                  id={`height_${i}`}
                  name={`height_${i}`}
                  prefix="点1"
                  suffix="m"
                  onChange={onChangeParameters}
                />
              )}
            </div>
          </Td>
        </Row>
      ))}
      {latlngs.map((item, i) => (
        <Row key={i} label={<Th>道路</Th>}>
          <Td>
            <div className="flex gap-4 flex-col md:flex-row">
              <Input
                className="md:w-[300px]"
                id={`height_${i}`}
                name={`height_${i}`}
                prefix={roadNumber[i]}
                suffix="m"
                onChange={onChangeParameters}
              />
              <div className="flex items-center">
                <StyledLinkOff fontSize="small" />
              </div>
              {i !== latlngs.length - 1 ? (
                <Input
                  className="md:w-[300px]"
                  id={`height_${i}`}
                  name={`height_${i}`}
                  prefix={roadNumber[i + 1]}
                  suffix="m"
                  onChange={onChangeParameters}
                />
              ) : (
                <Input
                  className="md:w-[300px]"
                  id={`height_${i}`}
                  name={`height_${i}`}
                  prefix={roadNumber[0]}
                  suffix="m"
                  onChange={onChangeParameters}
                />
              )}
            </div>
          </Td>
        </Row>
      ))}
    </>
  )
}

export default LatLngForm3
