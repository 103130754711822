import * as React from 'react'
import { Billboard, Line, LineProps, Text } from "@react-three/drei";
import { FC, useCallback, useMemo } from "react";
import { Vector3 } from "three";
import { Drawing } from "../Drawing";

export type SkyFactorProps = {
  skyFactor: Drawing["sky_factor"];
  selected?: number[];
};

const Circle: FC<{
  radius?: number;
  resolution?: number;
} & Omit<LineProps, 'points'>> = ({
  radius,
  resolution,
  ...rest
}) => {
  const points = useMemo(() => {
    const resol = resolution ?? 32;
    const rad = radius ?? 1;
    return Array.from({ length: resol }).map((_, i) => {
      const t = i / (resol - 1);
      const theta = t * Math.PI * 2;
      const x = Math.cos(theta) * rad;
      const y = Math.sin(theta) * rad;
      return new Vector3(x, y, 0);
    });
  }, [radius, resolution]);
  return <Line points={points} {...rest} />
};

const SkyFactor: FC<SkyFactorProps> = ({
  skyFactor,
  selected,
}) => {

  const factors = useMemo(() => {
    if (skyFactor !== undefined) {
      const { factors } = skyFactor;
      return factors.filter((_, i) => {
        return selected?.includes(i) ?? true;
      }).map((f, i) => {
        return {
          points: f.points.map((p) => {
            const { point, ...rest } = p;
            return {
              ...rest,
              position: new Vector3(point[0], point[1], 0)
            };
          })
        };
      });
    }
    return [];
  }, [skyFactor, selected]);

  const formatPercent = useCallback((n: number) => {
    const precision = 1e2;
    return Math.floor(n * precision) / precision;
  }, []);

  return <group name="delFromMap-SkyFactor">
    {
      factors.map((f, i) => {
        return <group key={`factor-${i}`}>
          {
            f.points.map((p, j) => {
              return <group key={`point-${i}-${j}`} position={p.position}>
                <Circle color={'black'} radius={1e2 * 1} />
                <Billboard>
                  <Text position={new Vector3(1e2 * 2, 0, 0)} color='black' anchorX={'left'} anchorY={'middle'} scale={1e2 * 2}>
                    ({ j }) P-C: {formatPercent(p.plan_building_percent - p.compatible_building_percent)}%, C: {formatPercent(p.compatible_building_percent)}%, P: {formatPercent(p.plan_building_percent)}%
                  </Text>
                </Billboard>
              </group>;
            })
          }
        </group>;
      })
    }
  </group>;
};

export {
  SkyFactor
}