import * as React from "react";
import { FC, useMemo } from 'react';
import { Surrounding } from '../Drawing';
import { Vector2 } from 'three';
import { RAD2DEG } from 'three/src/math/MathUtils';
import {
  formatMeasurementValue,
  textReadableDegree,
} from '../../helpers/DrawingHelper';
import { findSegmentSegmentIntersection } from '../../helpers/ClosestPointHelper';

export type SurroundingsProps = {
  surrounding: Surrounding;
  label:string,
  strokeWidth?: number;
  fontSize?: number;
  displayText?: boolean;
  displayWidth?: boolean;
};

type measurement = {
  start: Vector2,
  end: Vector2,
  center: Vector2,
  angle: number,
  label: string
}

const Surroundings: FC<SurroundingsProps> = ({
  surrounding,
  label,
  strokeWidth,
  fontSize,
  displayText,
  displayWidth,
}) => {

  const surroundInnerSegment = useMemo(() => {
    return surrounding.inner_segment;
  }, [surrounding]);

  const centerSegmentPoint = (innerSegment, outerSegment) => {
    let centerSegment = {a:[],b:[]}
    centerSegment.a = [(innerSegment.a[0] + outerSegment.a[0])/2, (innerSegment.a[1] + outerSegment.a[1])/2];
    centerSegment.b = [(innerSegment.b[0] + outerSegment.b[0])/2, (innerSegment.b[1] + outerSegment.b[1])/2];
    return centerSegment;
  }

  const surroundCenterSegment = useMemo(() => {
    return centerSegmentPoint(surrounding.inner_segment, surrounding.outer_segment);
  }, [surrounding]);

  const surroundOuterSegment = useMemo(() => {
    return surrounding.outer_segment;
  }, [surrounding]);

  const width = strokeWidth ?? 20;

  // 一点鎖線
  const strokeDashArray = useMemo(() => {
    const separation = 30;
    const ratio = 10;
    return `${separation * ratio} ${separation} ${separation} ${separation}`;
  }, []);

  const surroundLabel = useMemo(() => {

    const { a, b } = surroundCenterSegment;
    const dir = new Vector2(b[0] - a[0], b[1] - a[1]);
    const len = dir.length();
    const offset = dir
      .clone()
      .normalize()
      .multiplyScalar(len * 9 / 20);
    const center = new Vector2(a[0], a[1]).add(offset);
    return {
      center: center,
      angle: textReadableDegree(dir.normalize().angle() * RAD2DEG + 90),
    };

  }, [surroundCenterSegment]);

  const surroundMeasurements :measurement[] = useMemo(() => {

    const { a: ca, b: cb } = surroundCenterSegment;
    const dir = new Vector2(cb[0] - ca[0], cb[1] - ca[1]);
    const len = dir.length();
    const offset = dir
      .clone()
      .normalize()
      .multiplyScalar(len * 8 / 20);
    const center = new Vector2(ca[0], ca[1]).add(offset);
    const normal = new Vector2(-dir.y, dir.x);

    const measurements :measurement[] = [];
    if (
      surroundInnerSegment !== null &&
      surroundOuterSegment !== null &&
        surrounding.distance !== null
    ) {
      const orth = {
        a: center.clone().sub(normal.clone().multiplyScalar(1e4)),
        b: center.clone().add(normal.clone().multiplyScalar(1e4)),
      };
      const start = findSegmentSegmentIntersection(orth, surroundInnerSegment);
      const end = findSegmentSegmentIntersection(orth, surroundOuterSegment);
      if (start !== null && end !== null) {
        measurements.push({
          start,
          end,
          center,
          angle: textReadableDegree(dir.angle() * RAD2DEG + 90),
          label: `${formatMeasurementValue(surrounding.distance ?? 0)}`,
        });
      }
    }

    return measurements;
  }, [
    surrounding,
    surroundInnerSegment,
    surroundCenterSegment,
    surroundOuterSegment,
  ]);

  return (
    <g>
      <circle
        cx={surroundInnerSegment.a[0]}
        cy={surroundInnerSegment.a[1]}
        r={80}
        stroke="black"
        strokeWidth={width}
        fill="none"
      />

      <line
        type="inner-segment"
        x1={surroundInnerSegment.a[0]}
        y1={surroundInnerSegment.a[1]}
        x2={surroundInnerSegment.b[0]}
        y2={surroundInnerSegment.b[1]}
        stroke="black"
        strokeWidth={width}
        strokeDasharray={strokeDashArray}
      />

      <line
        type="outer-segment"
        x1={surroundOuterSegment.a[0]}
        y1={surroundOuterSegment.a[1]}
        x2={surroundOuterSegment.b[0]}
        y2={surroundOuterSegment.b[1]}
        stroke="blue"
        strokeWidth={width}
      />

      {displayText && (
        <text
          fontSize={fontSize ?? 120}
          x={surroundLabel.center.x}
          y={surroundLabel.center.y}
          style={{ fill: 'blue' }}
          textAnchor="middle"
          alignmentBaseline="after-edge"
          transform={`rotate(${surroundLabel.angle}, ${surroundLabel.center.x}, ${surroundLabel.center.y})`}
        >
          {label}
        </text>
      )}
      {displayWidth && (
        <g>
          {surroundMeasurements.map((m, idx) => {
            return (
              <g key={`measurement-${idx}`}>
                <line
                  x1={m.start.x}
                  y1={m.start.y}
                  x2={m.end.x}
                  y2={m.end.y}
                  stroke="blue"
                  strokeWidth={width}
                />
                <circle cx={m.start.x} cy={m.start.y} r={60} fill="aqua" />
                <circle cx={m.end.x} cy={m.end.y} r={60} fill="aqua" />
                {displayText && (
                  <text
                    fontSize={fontSize ?? 120}
                    x={m.center.x}
                    y={m.center.y}
                    textAnchor="middle"
                    style={{ fill: 'blue' }}
                    alignmentBaseline="after-edge"
                    transform={`rotate(${m.angle}, ${m.center.x}, ${m.center.y})`}
                  >
                    {m.label}
                  </text>
                )}
              </g>
            );
          })}
        </g>
      )}
    </g>
  );
};

export { Surroundings };
