const error_message = (error: object, message: string): string => {
  return error.message == '403: Forbidden' ? 'アクセス権がありません': message
}

export const redirect = (error: object, location_path: string, message: string, params: object): void => {
  let urlSearchParam = ''
  if (params) {
    urlSearchParam = new URLSearchParams(params).toString()
  }
  const path = `${location_path}?message=${error_message(error, message)}`
  location.href = urlSearchParam ? `${path}&${urlSearchParam}` : path
}
