import * as React from 'react'

type Props = {
  name: string
  title: string
  unit?: string
  value?: string
  placeholder?: string
  onChange?: (e?: React.ChangeEvent<HTMLInputElement>) => void
  onBlur?: (e?: React.FocusEvent<HTMLInputElement>) => void
  disabled?: boolean
  readOnly?: boolean
}

const InputForm: React.FC<Props> = ({
  name,
  title,
  unit,
  value,
  placeholder,
  onChange,
  onBlur,
  disabled = false,
  readOnly = false,
}) => {
  const inputClass = unit
    ? [styles.input, styles.suffix, readOnly ? 'bg-gray-100' : ''].join(' ')
    : [styles.input, styles.suffix, styles.withoutUnit, readOnly ? 'bg-gray-100' : ''].join(' ')

  return (
    <div className="my-2 flex justify-between items-center gap-4">
      <label htmlFor={name} className="w-1/3">
        <span className="text-xs">{title}</span>
      </label>
      <div className={[styles.inputWrap, 'w-2/3'].join(' ')}>
        <input
          type="text"
          id={name}
          name={name}
          value={value}
          className={inputClass}
          onChange={onChange}
          onBlur={onBlur}
          placeholder={placeholder}
          disabled={disabled}
          readOnly={readOnly}
        />
        {unit && (
          <span id={`${name}_append`} className={[styles.box, 'rounded-l-none'].join(' ')}>
            {unit}
          </span>
        )}
      </div>
    </div>
  )
}

export default InputForm

const styles = {
  inputWrap: `
    relative
    flex
    items-strech
  `,
  input: `
    flex
    flex-auto
    border
    rounded-sm
    bg-white
    outline-none
    py-2
    px-4
    text-sm
    leading-normal
    text-black-base
    border-gray-border
    placeholder-gray-250
    placeholder:text-sm
    disabled:text-gray-300
    disabled:bg-gray-light
    disabled:cursor-not-allowed
    disabled:opacity-70
  `,
  suffix: `
    w-full
    rounded-r-none
    border-r-0
    text-sm
  `,
  prefix: `
    w-full
    rounded-l-none
    border-l-0
    text-sm
  `,
  box: `
    flex
    items-center
    border
    border-gray-border
    rounded-sm
    bg-gray-100
    py-2
    px-4
    text-sm
    leading-normal
    text-black-base
    whitespace-nowrap
  `,
  withoutUnit: `
    border-r
  `,
}
