import * as React from 'react'
import { AnchorButton } from '@/components/Button'
import { Modal } from '@/components/Modal'
import { UserContext } from '@/components/Layout/Base'

type Props = {
  open: boolean
  onClose: () => void
  path: string
  query?: string
  title?: string
  onPropertyDocumentLink?: boolean
}

export const PropertyDataModal: React.FC<Props> = ({
  open,
  onClose,
  path,
  query,
  title,
  onPropertyDocumentLink = true,
}) => {
  const { user } = React.useContext(UserContext)
  const getPropertyHashid = () => {
    if (path.includes('/properties/')) {
      return path.match(/^[a-zA-Z0-9]$/)
    }
    if (path.includes('/property_public_files/')) {
      return new URLSearchParams(window.location.search).get('property_id')
    }
    // if (path.includes('/display_items')) {
        
    // }
  }
  const replacePath = () => {
    if(path.includes('/display_items')){
      return `/display_items`
    } else {
      return path
    }
  }
  return (
    <Modal open={open} onClose={onClose}>
      <div className="px-6 py-6 leading-10">{title ? title : '物件種目'}を選択してください</div>
        <>
          <div className="flex justify-center gap-4 mb-3">
            <div className="flex justify-center gap-4 mb-3">
              <AnchorButton
                className="text-white min-w-[160px] h-[36px]"
                size="small"
                variant="primary"
                href={addQuery(`${replacePath()}?data_type=for_property`, 'land')}
              >
                土地
              </AnchorButton>
            </div>
            <div className="flex justify-center flex-col gap-4">
              <AnchorButton
                className="text-white min-w-[160px] h-[36px]"
                size="small"
                href={addQuery(`${replacePath()}?data_type=for_property`, 'building')}
              >
                土地＋建物（一棟）
              </AnchorButton>
              <AnchorButton
                className="text-white min-w-[160px] h-[36px]"
                size="small"
                variant="primary"
                href={addQuery(`${replacePath()}?data_type=for_property`, 'classification')}
              >
                土地＋建物（区分）
              </AnchorButton>
            </div>
        </div>
        </>
    </Modal>
  )
}

const addQuery = (path: string, query?: string): string => {
  return query ? `${path}&${query}` : path
}
