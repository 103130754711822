import * as React from 'react'
import Base from '@/components/Layout/Base'
import { Table } from '@/components/Table'
import type { User } from '@/types/user'
import type { Department } from '@/types/department'
import { Breadcrumb } from '@/components/Breadcrumb'

type Props = {
  current_user: User
  department: Department
}

const DepartmentsDetailPage: React.FC<Props> = ({ current_user, department }) => {
  const title = '部署詳細'

  return (
    <Base
      current_user={current_user}
      title={title}
      breadcrumb={<Breadcrumb link_texts={['部署設定', '部署詳細']} />}
      submenuCategory="settings"
    >
      <Table className="mt-8">
        <tbody>
          <tr className="bg-gray-light">
            <th
              scope="row"
              className="py-5 px-4 md:text-right whitespace-nowrap text-black-base font-medium w-1/4"
            >
              部署名
            </th>
            <td className="py-2 px-4 w-3/4">{department.name}</td>
          </tr>
        </tbody>
      </Table>
    </Base>
  )
}

export default DepartmentsDetailPage
