import { AnchorButton, Button } from '@/components/Button'
import { Input } from '@/components/Input'
import Base from '@/components/Layout/Base'
import { ListUlIcon, MapIcon, PlusIcon } from '@/components/SvgIcon'
import { Table } from '@/components/Table'
import DeleteOutlined from '@material-ui/icons/DeleteOutlined'
import RefreshIcon from '@material-ui/icons/Refresh'
import SearchIcon from '@material-ui/icons/Search'
import * as React from 'react'

import { CaretDownIcon } from '@/components/SvgIcon'

import type { Property, LatLng } from '@/types/property'
import type { User } from '@/types/user'
import { cammedFormat } from '@/utils/cammedFormat'
import { colors } from '@/utils/colors'

import { Breadcrumb } from '@/components/Breadcrumb'
import { DropDown } from '@/components/DropDown/Index'
import { GoogleMapViewer, MapHandles } from '@/components/GoogleMapViewer/Properties'
import { PropertiesFilterModal } from '@/components/Page/Properties/PropertiesFilterModal'
import { Pagination } from '@/components/Pagination'
import { Select } from '@/components/Select'
import { prefectureOptions } from '@/models/prefecture'
import { Pagy } from '@/types/pagy'
import { useURLParams } from '@/utils/useURLParams'
import { Libraries, useJsApiLoader } from '@react-google-maps/api'
import './../Proposals/proposals.css'

type CreatePropertyParam = {
  prefecture?: string
  city?: string
  town?: string
  chome?: string
  lat?: number
  lng?: number
}

type Props = {
  google_maps_api_key: string
  polygons_api_base_url: string
  current_user: User
  properties: Property[]
  pagy: Pagy
  show_table: boolean
  prefecture_eq?: number
  instant_create: boolean
  department_options: { value: string; label: string }[]
  user_options: { value: string; label: string }[]
  tag_options: { value: string; text: string }[]
  business_type_options: { value: string; text: string }[]
}

const prefectureOptionsWithNumber = prefectureOptions.map((option, index) => ({
  value: (index + 1).toString(),
  label: option.label,
}))

const formatChome = (value: string): string => {
  const match = value.match(/[0-9\-０-９ーー―‐ー−－]/g)
  value = match === null ? '' : match.join('')
  value = value.replace(/[０-９ーー―‐ー−－]/g, function (c) {
    return c.match(/[ーー―‐ー−－]/) ? '-' : String.fromCharCode(c.charCodeAt(0) - 0xfee0)
  })
  return value
}

const PropertiesIndexPage: React.FC<Props> = ({
  google_maps_api_key,
  polygons_api_base_url,
  current_user,
  properties,
  pagy,
  show_table,
  prefecture_eq,
  instant_create,
  department_options,
  user_options,
  tag_options,
  business_type_options,
}) => {
  const libraries = React.useRef<Libraries>(['geometry', 'drawing', 'places'])

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: google_maps_api_key,
    libraries: libraries.current,
  })

  const [isOpenedSearchPanel, setIsOpenedSearchPanel] = React.useState(!show_table)
  const csrfToken: HTMLMetaElement = document.head.querySelector('meta[name="csrf-token"]')

  const title = '物件管理'
  const DEFAULT_ZOOM_LEVEL = 13
  const HIGH_ZOOM_LEVEL = 17

  const [showMap, setShowMap] = useURLParams<boolean>(false, 'showMap')
  const mapRef = React.useRef<MapHandles>(null)
  const bodyRef = React.useRef<HTMLElement>(null)
  const [showFixedHeader, setShowFixedHeader] = React.useState(false)

  const searchParams = new URLSearchParams(location.search)
  const [prefecture, setPrefecture] = React.useState<string>(
    (searchParams.get('q[prefecture_eq]')?.toString() || null) ??
    (prefecture_eq?.toString() || '13')
  )
  const [city, setCity] = React.useState('')
  const [town, setTown] = React.useState('')
  const [chomeStart, setChomeStart] = React.useState(
    searchParams.has('q[chome_start]') ? searchParams.get('q[chome_start]') : ''
  )
  const [cityOptions, setCityOptions] = React.useState([])
  const [townOptions, setTownOptions] = React.useState([])
  const [mapInformation, setMapInformation] = React.useState(
    searchParams.get('q[map_information_cont]') || ''
  )
  const theadRef = React.useRef<HTMLTableSectionElement>(null)
  const tableFixedRef = React.useRef<HTMLDivElement>(null)
  const tableContainerRef = React.useRef<HTMLDivElement>(null)

  const [mapPositionData, setMapPositionData] = React.useState<string>(
    searchParams.get('q[map_position_data_cont]') || ''
  )

  const hendleMainMapChange = (newCenter: LatLng, newZoom: number) => {
    const mapPosition = `lat=${newCenter.lat}&lng=${newCenter.lng}&zoom=${newZoom}`
    setMapPositionData(mapPosition)
  }

  const updateMapLocation = React.useCallback(() => {
    const mapPositionData = searchParams.get('q[map_position_data_cont]')

    if (mapPositionData) {
      const decodedValue = decodeURIComponent(mapPositionData)
      const params = new URLSearchParams(decodedValue)
      const lat = parseFloat(params.get('lat'))
      const lng = parseFloat(params.get('lng'))
      const zoom = parseInt(params.get('zoom'), 10)
      mapRef.current.setPositionLocation({ lat, lng }, zoom)
    } else {
      const localCity = searchParams.get('q[city_eq]') || ''
      const localTown = searchParams.get('q[town_eq]') || ''
      const localChomeStart = searchParams.get('q[chome_start]') || ''

      const address = `${getPrefectureName(prefecture)}${localCity}${localTown}${localChomeStart}`
      const zoomLevel =
        localCity || localTown || localChomeStart ? HIGH_ZOOM_LEVEL : DEFAULT_ZOOM_LEVEL
      mapRef.current.setLocation(address, zoomLevel)
    }
  }, [location.search, isLoaded, mapRef])

  const getPrefectureName = (prefecture: string) => {
    if (prefecture === '') {
      return '---'
    }
    return prefectureOptionsWithNumber.find((option) => option.value === prefecture).label
  }

  const handleTableFixedScroll = React.useCallback(() => {
    tableContainerRef.current.scrollLeft = tableFixedRef.current.scrollLeft
  }, [theadRef])

  React.useEffect(() => {
    tableContainerRef.current.addEventListener('scroll', () => {
      tableFixedRef.current.scrollLeft = tableContainerRef.current.scrollLeft
    })
    return () => {
      tableContainerRef.current.removeEventListener('scroll', () => { })
    }
  }, [tableContainerRef.current])

  React.useEffect(() => {
    bodyRef.current.addEventListener('scroll', () => {
      setShowFixedHeader(bodyRef.current.scrollTop > 320)
    })
    return () => bodyRef.current.removeEventListener('scroll', () => { })
  }, [])

  // 新規物件登録
  const createProperty = async () => {
    const params: CreatePropertyParam = {}
    params.prefecture = getPrefectureName(prefecture)
    params.city = city
    params.town = town
    params.chome = chomeStart
    const geocoder = new google.maps.Geocoder()
    const results = await geocoder.geocode({
      address: `${params.prefecture}${params.city}${params.town}${params.chome}`,
      language: 'ja',
    })
    if (results.results.length > 0) {
      params.lat = results.results[0].geometry.location.lat()
      params.lng = results.results[0].geometry.location.lng()
    }
    location.href = `/properties/new?${Object.keys(params)
      .map((key: keyof CreatePropertyParam) => `${key}=${params[key]}`)
      .join('&')}`
  }

  React.useEffect(() => {
    fetchCityOptions(getPrefectureName(prefecture), true)

    if (instant_create) {
      setShowMap(true)
    }
  }, [])

  React.useEffect(() => {
    if (isLoaded && mapRef.current) {
      updateMapLocation()
    }
  }, [isLoaded, updateMapLocation])

  const fetchCityOptions = React.useCallback(
    (prefecture, isInit = false) => {
      if (prefecture === '') {
        setCity('')
        setTown('')
        setCityOptions([])
        setTownOptions([])
        return
      }

      fetch('/japanese-addresses/ja.json')
        .then((response) => response.json())
        .then((json) => {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-call
          const options = json[prefecture].map((cityName) => {
            return { value: cityName, label: cityName }
          })

          setCityOptions(options)
        })
        .catch(() => {
          setCityOptions([])
          setTownOptions([])
        })
        .finally(() => {
          if (prefecture !== '') {
            if (isInit && searchParams.has('q[city_eq]')) {
              setCity(searchParams.get('q[city_eq]'))
              fetchTownOptions(prefecture, searchParams.get('q[city_eq]'))
            }
          }
          setTown('')
        })
    },
    [setCity, setTown, setCityOptions, setTownOptions]
  )

  const fetchTownOptions = React.useCallback(
    (prefecture: string, city: string) => {
      if (city === '') {
        setTown('')
        setTownOptions([])
        return
      }

      const uri = `/japanese-addresses/ja/${prefecture}/${city}.json`
      fetch(uri)
        .then((response) => response.json())
        .then((json) => {
          if (!json) {
            setTown('')
            setTownOptions([])
            return
          }
          // eslint-disable-next-line @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-return
          const options = json.map((data) => ({
            value: data.town,
            label: data.town,
          }))
          setTownOptions(options)
        })
        .catch(() => {
          setTownOptions([])
        })
        .finally(() => {
          if (prefecture !== '' && city !== '') {
            if (searchParams.has('q[town_eq]')) {
              setTown(searchParams.get('q[town_eq]'))
            }
          }
        })
    },
    [setTown, setTownOptions]
  )

  const onChangePrefecture = React.useCallback(
    (pref) => {
      if (prefecture != pref.toString()) {
        setCity('')
        setTown('')
        setCityOptions([])
        setTownOptions([])
        setPrefecture(pref)
        fetchCityOptions(getPrefectureName(pref))
      }
    },
    [setPrefecture, prefecture, fetchCityOptions, setCity, setTown]
  )

  const onChangeCity = React.useCallback(
    (newCity) => {
      if (newCity != city) {
        fetchTownOptions(getPrefectureName(prefecture), newCity)
        setCity(newCity)
      }
    },
    [prefecture, setCity, city, fetchTownOptions]
  )

  const onChangeTown = React.useCallback(
    (newTown) => {
      setTown(newTown)
    },
    [setTown]
  )

  const formRef = React.useRef<HTMLFormElement>(null)

  return (
    <Base
      current_user={current_user}
      title={title}
      breadcrumb={<Breadcrumb link_texts={['物件管理']} />}
      customRef={bodyRef}
    >
      <div className="my-4 border border-primary md:border-none rounded bg-white md:rounded-none">
        <div
          className="flex w-full items-center px-4 py-3 text-left cursor-pointer md:hidden"
          onClick={() => {
            setIsOpenedSearchPanel(!isOpenedSearchPanel)
          }}
        >
          <CaretDownIcon
            fill={colors.primary}
            className={`${isOpenedSearchPanel
              ? 'rotate-0 transition duration-100 transform'
              : '-rotate-90 transition duration-100 transform'
              }`}
          />
          <div className="ml-2 text-primary text-sm leading-none">住所検索</div>
        </div>
        <div className={[!isOpenedSearchPanel && 'hidden md:block'].join(' ')}>
          <form
            encType="multipart/form-data"
            action="/properties"
            acceptCharset="UTF-8"
            method="get"
            ref={formRef}
          >
            <div className="flex flex-wrap md:flex-nowrap items-center">
              <div className="p-4 w-full">
                {/* TODO: Edit.tsxと同じようにAddressコンポーネントで共通化したい */}
                <div className="mb-3 text-sm leading-none text-black-base whitespace-nowrap">
                  都道府県
                </div>
                <DropDown
                  className="w-full"
                  name="q[prefecture_eq]"
                  id="q_prefecture_eq"
                  placeholder="---"
                  options={prefectureOptionsWithNumber}
                  defaultValue={prefectureOptionsWithNumber?.find(
                    (i) => i.value == prefecture_eq?.toString()
                  )}
                  onChange={(e) => {
                    mapRef.current.setLocation(e ? e.label : '')
                    onChangePrefecture(e ? e.value : '')
                  }}
                />
              </div>
              <div className="p-4 w-full">
                <div className="mb-3 text-sm leading-none text-black-base whitespace-nowrap">
                  市区町村
                </div>
                <DropDown
                  className="w-full"
                  name="q[city_eq]"
                  id="q_city_eq"
                  placeholder="---"
                  options={cityOptions}
                  value={city && { label: city, value: city }}
                  onChange={(e) => {
                    if (e) {
                      mapRef.current.setLocation(
                        getPrefectureName(prefecture) + (e ? e.label : ''),
                        HIGH_ZOOM_LEVEL
                      )
                    } else {
                      mapRef.current.setLocation(
                        getPrefectureName(prefecture) + (e ? e.label : ''),
                        DEFAULT_ZOOM_LEVEL
                      )
                    }
                    onChangeCity(e ? e.value : '')
                  }}
                />
              </div>
              <div className="p-4 w-full">
                <div className="mb-3 text-sm leading-none text-black-base whitespace-nowrap">
                  町名
                </div>
                <DropDown
                  className="w-full"
                  name="q[town_eq]"
                  id="q_town_eq"
                  placeholder="---"
                  options={townOptions}
                  value={
                    townOptions.map((e) => e.label).includes(town) && { label: town, value: town }
                  }
                  onChange={(e) => {
                    mapRef.current.setLocation(
                      getPrefectureName(prefecture) + city + (e ? e.label : ''),
                      HIGH_ZOOM_LEVEL
                    )
                    onChangeTown(e?.label ?? '')
                  }}
                />
              </div>
              <div className="p-4 w-full">
                <div className="mb-3 text-sm leading-none text-black-base whitespace-nowrap">
                  丁目以降（住居表示）
                </div>
                <Input
                  className="w-full"
                  value={chomeStart}
                  placeholder="1-2-3 等（半角数字）"
                  name="q[chome_start]"
                  id="q_chome_start"
                  onChange={(e) => {
                    mapRef.current.setLocation(
                      getPrefectureName(prefecture) + city + town + (e.target.value ?? ''),
                      HIGH_ZOOM_LEVEL
                    )
                    setChomeStart(e.target.value)
                  }}
                  onBlur={() => {
                    const value = formatChome(chomeStart)
                    setChomeStart(value)
                  }}
                />
              </div>
              <div className="p-4 w-full">
                <div className="mb-3 text-sm leading-none text-black-base whitespace-nowrap">
                  建物名
                </div>
                <Input
                  className="w-full"
                  value={mapInformation}
                  name="q[map_information_cont]"
                  id="q_map_information_cont"
                  onChange={(e) => {
                    setMapInformation(e.target.value)
                  }}
                />
              </div>
            </div>
            <div className="flex gap-4 p-4 md:p-0 items-center justify-center">
              <AnchorButton
                href="/properties"
                className="text-primary-font border-white w-[160px]"
                prefix={<RefreshIcon fontSize="small" />}
                outline
                size="small"
                variant="primary"
              >
                リセット
              </AnchorButton>
              <Button
                className="text-primary-font w-[160px]"
                prefix={<SearchIcon fontSize="small" />}
                outline
                size="small"
                variant="primary"
              >
                検索
              </Button>
            </div>
          </form>
        </div>
      </div>
      <div className="my-6 border-t border-gray-200"></div>
      <div className="flex justify-end">
        {isLoaded && (
          <Button
            prefix={<PlusIcon fill={colors.primary} />}
            outline
            size="small"
            disabled={prefecture === ''}
            onClick={() => {
              void createProperty()
            }}
          >
            新規物件登録
          </Button>
        )}
      </div>
      <div className="mt-2">
        <div className="flex flex-wrap items-center justify-between">
          {show_table && (
            <div className="flex flex-wrap items-center gap-1 whitespace-nowrap">
              <span className="text-primary text-xl font-medium">
                {pagy.count.toLocaleString()}
              </span>
              <span className="text-gray-800 text-sm mt-0.5">
                件中 {pagy.from}～{pagy.to}件 表示
              </span>
              <span className="text-gray-800 text-sm mt-0.5 ml-2">表示件数：</span>
              <Select
                className="w-[100px] h-9"
                options={[
                  {
                    text: '20件',
                    value: 20,
                  },
                  {
                    text: '50件',
                    value: 50,
                  },
                  {
                    text: '100件',
                    value: 100,
                  },
                ]}
                value={searchParams.get('page_length') || '20'}
                onChange={(e) => {
                  const searchParams = new URLSearchParams(location.search)
                  searchParams.set('page_length', e.target.value)
                  location.search = searchParams.toString()
                }}
              />
              <div className="ml-2">
                <Pagination pagy={pagy} />
              </div>
            </div>
          )}
          <div className="flex flex-wrap items-center gap-6 ml-auto">
            {/* 初期リリースでは非表示 */}
            {/* <div className="flex gap-2 items-center">
              <span className="text-xs text-gray-800 whitespace-nowrap">並び替え</span>
              <Select
                className="min-w-24"
                size="small"
                options={[
                  {
                    text: 'option1',
                    value: 1,
                  },
                  {
                    text: 'option2',
                    value: 2,
                  },
                  {
                    text: 'option3',
                    value: 3,
                  },
                ]}
                onChange={(e) => console.log(e.target.value)}
              />
            </div>
            <div className="flex gap-2 items-center">
              <span className="text-xs text-gray-800 whitespace-nowrap">表示建物数</span>
              <Select
                className="min-w-24"
                size="small"
                options={[
                  {
                    text: 'option1',
                    value: 1,
                  },
                  {
                    text: 'option2',
                    value: 2,
                  },
                  {
                    text: 'option3',
                    value: 3,
                  },
                ]}
                onChange={(e) => console.log(e.target.value)}
              />
            </div> */}
            <div className="flex pt-1 md:pt-0">
              {showMap && (
                <PropertiesFilterModal
                  department_options={department_options}
                  user_options={user_options}
                  tag_options={tag_options}
                  business_type_options={business_type_options}
                  prefecture_options={[{ value: '', label: '---' }].concat(
                    prefectureOptionsWithNumber
                  )}
                  title={'物件管理絞り込み'}
                  target_prefecture={prefecture}
                  target_city={city}
                  target_town={town}
                  target_chome={chomeStart}
                  target_map_information={mapInformation}
                  target_map_position_data={mapPositionData}
                  showMap={showMap}
                />
              )}
              <Button
                onClick={() => {
                  setShowMap(false)
                }}
                outline
                size="small"
                variant={showMap ? 'primary' : 'primaryLight'}
                className={['px-2.5 border rounded-r-none', showMap && 'border-[#D9D9D9]'].join(
                  ' '
                )}
              >
                <ListUlIcon fill={showMap ? '#ADB5BD' : colors.primary} />
              </Button>
              <Button
                onClick={() => {
                  setShowMap(true)
                }}
                outline
                size="small"
                variant={showMap ? 'primaryLight' : 'primary'}
                className={[
                  'px-2.5 rounded-l-none',
                  showMap ? 'border-primary' : 'border-[#D9D9D9]',
                ].join(' ')}
              >
                <MapIcon fill={showMap ? colors.primary : '#ADB5BD'} />
              </Button>
            </div>
          </div>
        </div>
        <div className={`my-2${showMap ? ' hidden' : ''}`}>
          {/* ヘッダー固定 */}
          <div
            className={'absolute top-[102px] overflow-x-scroll'}
            style={{
              maxWidth: `${theadRef.current?.parentElement?.parentElement?.clientWidth}px`,
              zIndex: showFixedHeader ? '10' : '-1',
              opacity: showFixedHeader ? '1' : '0',
            }}
            ref={tableFixedRef}
            onScroll={handleTableFixedScroll}
          >
            <div className={''} style={{ width: `${theadRef.current?.clientWidth}px` }}>
              <thead className="bg-gray-150 text-sm text-left overflow-x-scroll">
                <tr className="whitespace-nowrap">
                  {Array.from(theadRef.current?.getElementsByTagName('th') || []).map((th) => {
                    return (
                      <th
                        scope="col"
                        className="py-2 px-4 font-medium"
                        style={{ width: `${th.offsetWidth}px` }}
                      >
                        {th.innerText}
                      </th>
                    )
                  })}
                </tr>
              </thead>
            </div>
          </div>

          <Table topScrollbar={true} tableContainerRef={tableContainerRef}>
            <thead className="bg-gray-150 text-sm" ref={theadRef}>
              <tr className="whitespace-nowrap">
                <th scope="col" className="py-2 px-4 font-medium">
                  No.
                </th>
                <th scope="col" className="py-2 px-4 font-medium">
                  所在地（住居表示）
                </th>
                <th scope="col" className="py-2 px-4 font-medium">
                  丁目以降（地番）
                </th>
                <th scope="col" className="py-2 px-4 font-medium">
                  建物名
                </th>
                <th scope="col" className="py-2 px-4 font-medium">
                  区分の場所
                </th>
                <th scope="col" className="py-2 px-4 font-medium">
                  物件種目
                </th>
                <th scope="col" className="py-2 px-4 font-medium">
                  敷地面積
                </th>
                <th scope="col" className="py-2 px-4 font-medium">
                  建物賃貸可能面積（専有面積）
                </th>
                <th scope="col" className="py-2 px-4 font-medium">
                  入手件数
                </th>
                <th scope="col" className="py-2 px-4 font-medium"></th>
              </tr>
            </thead>
            {show_table && properties.length > 0 && (
              <tbody className="whitespace-nowrap">
                {properties.map((property, index) => (
                  <tr
                    key={index}
                    className={`${index % 2 ? 'bg-gray-light' : 'bg-white'
                      } hover:bg-targetLine cursor-pointer`}
                  >
                    <td>
                      <a
                        href={`/properties/${property.hashid}?tab=proposals`}
                        className="block w-full h-full py-2 px-4 text-right"
                      >
                        {pagy.from + index}
                      </a>
                    </td>
                    <td>
                      <a
                        href={`/properties/${property.hashid}?tab=proposals`}
                        className="block w-full h-full py-2 px-4"
                      >
                        {property.prefecture} {property.city} {property.town} {property.chome}
                      </a>
                    </td>
                    <td>
                      <a
                        href={`/properties/${property.hashid}?tab=proposals`}
                        className="block w-full h-full py-2 px-4 text-ellipsis"
                      >
                        {property.chiban || '　'}
                      </a>
                    </td>
                    <td>
                      <a
                        href={`/properties/${property.hashid}?tab=proposals`}
                        className="block w-full h-full py-2 px-4"
                      >
                        {property.map_information || '　'}
                      </a>
                    </td>
                    <td>
                      <a
                        href={`/properties/${property.hashid}?tab=proposals`}
                        className="block w-full h-full py-2 px-4"
                      >
                        {property.location_of_division || '　'}
                      </a>
                    </td>
                    <td>
                      <a
                        href={`/properties/${property.hashid}?tab=proposals`}
                        className="block w-full h-full py-2 px-4"
                      >
                        {property.property_type}
                      </a>
                    </td>
                    <td>
                      <a
                        href={`/properties/${property.hashid}?tab=proposals`}
                        className="block w-full h-full py-2 px-4"
                      >
                        {cammedFormat(Number(property.area_m3) ?? '', 2)}㎡
                      </a>
                    </td>
                    <td>
                      <a
                        href={`/properties/${property.hashid}?tab=proposals`}
                        className="block w-full h-full py-2 px-4"
                      >
                        {property.occupied_area
                          ? `${cammedFormat(Number(property.occupied_area) ?? '', 2)}㎡`
                          : '　'}
                      </a>
                    </td>
                    <td>
                      <a
                        href={`/properties/${property.hashid}?tab=proposals`}
                        className="block w-full h-full py-2 px-4"
                      >
                        {property.proposals.length}件
                      </a>
                    </td>
                    <td className="py-2 px-4 text-right">
                      {(current_user.role === 'admin' || current_user.role === 'system_admin') && (
                        <a
                          className="inline-block w-[30px] hover:text-deleteHover"
                          data-confirm={`${property.proposals.length}件の入手情報も同時に削除されます。よろしいですか？`}
                          rel="nofollow"
                          data-method="delete"
                          href={`/properties/${property.hashid}`}
                        >
                          <DeleteOutlined fontSize="small" />
                        </a>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            )}
          </Table>
        </div>
        <div className={`mt-6${showMap ? '' : ' hidden'}`}>
          {isLoaded && mapRef && (
            <GoogleMapViewer
              polygons_api_base_url={polygons_api_base_url}
              current_user={current_user}
              csrfToken={csrfToken.content}
              mode="list"
              zoom={DEFAULT_ZOOM_LEVEL}
              lat={35.6808404}
              lng={139.7614134}
              ref={mapRef}
              handleListModeMapChange={hendleMainMapChange}
            />
          )}
        </div>
      </div>
    </Base>
  )
}

export default PropertiesIndexPage
