import * as React from 'react'
import Logo from 'land_logo.png'

type Props = {
  authenticity_token: string
}

const Login: React.FC<Props> = ({ authenticity_token }) => {
  return (
    <div className="h-screen min-h-login-form relative">
      <div className={styles.bg}></div>
      <div className="h-full w-full flex flex-col">
        <div className="h-full flex items-center">
          <div className="mx-auto p-4 flex flex-col max-w-login-form relative z-10 mb-40">
            <form id="new_user" action="/users/sign_in" acceptCharset="UTF-8" method="post">
              <input type="hidden" name="authenticity_token" value={authenticity_token} />
              <img src={Logo} alt="land" className="w-36 m-10 mx-auto" />
              <div className="relative mb-8">
                <input
                  type="email"
                  id="email"
                  name="user[email]"
                  placeholder="メールアドレス"
                  required
                  className={styles.input + styles.inputEmail}
                />
                <input
                  type="password"
                  id="password"
                  name="user[password]"
                  placeholder="パスワード"
                  className={styles.input + styles.inputPassword}
                />
                <div className="text-center mt-4">
                  <input name="user[remember_me]" type="hidden" value="0" />
                  <input
                    type="checkbox"
                    className={styles.checkbox}
                    value="1"
                    name="user[remember_me]"
                    id="user_remember_me"
                  />
                  <label className="inline-block text-gray-800 text-sm" htmlFor="user_remember_me">
                    ログインを記憶する
                  </label>
                </div>
              </div>
              <button className={styles.button} type="submit">
                ログイン
              </button>
            </form>
            <br />
            <div className="text-center">
              <ul className="text-sm select-none">
                <li className="inline-block">
                  <a className="font-medium text-primary-font" href="/static_pages/privacy">
                    プライバシーポリシー
                  </a>
                  <span className="mx-1.5">/</span>
                </li>
                <li className="inline-block">
                  <a
                    className="font-medium text-primary-font"
                    href="/static_pages/terms_of_service"
                  >
                    利用規約
                  </a>
                  <span className="mx-1.5">/</span>
                </li>
                <li className="inline-block">
                  <a className="font-medium text-primary-font" href="https://zised.ai/">
                    会社情報
                  </a>
                </li>
              </ul>
              <small className="text-muted">&copy; zised.ai</small>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const styles = {
  bg: `
    absolute
    inset-0
    bg-center
    bg-no-repeat
    bg-cover
    bg-[url('~signin_bg.jpg')]
  `,
  input: `
    w-full
    bg-white
    rounded
    border
    border-gray-200
    outline-none
    text-gray-700
    py-3.5
    px-5
    leading-normal
    text-sm
  `,
  inputEmail: `
    rounded-b-none
  `,
  inputPassword: `
    rounded-t-none
  `,
  button: `
    w-full
    text-white
    bg-primary
    text-base
    leading-none
    border-0
    py-4
    px-6
    focus:outline-none
    hover:opacity-80
    rounded text-x
    duration-200
    transition-opacity
  `,
  footer: `
    w-full
    z-10
    border-t
    border-white
    text-center
    py-3
    text-white
    text-sm
  `,
  checkbox: `
    checked:bg-[url('~icons/checkbox_icon.svg')]
    appearance-none
    h-3.5
    w-3.5
    mr-2
    mt-1.5
    border
    border-gray-800
    rounded-sm
    checked:bg-primary
    checked:border-primary
    focus:outline-none
    align-top
    bg-no-repeat
    bg-center
    cursor-pointer
  `,
}

export default Login
