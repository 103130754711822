import * as React from 'react';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';

function not(a: readonly number[], b: readonly number[]) {
  return a.filter((value) => b.indexOf(value) === -1);
}

function intersection(a: readonly number[], b: readonly number[]) {
  return a.filter((value) => b.indexOf(value) !== -1);
}

function union(a: readonly number[], b: readonly number[]) {
  return [...a, ...not(b, a)];
}

type Props = {
  attachments: Object,
  selected_attachments: Object
}
const SelectAllTransferList: React.FC<Props> = ({
  attachments,
  selected_attachments
}) => {
  const attachmentList = attachments.concat(selected_attachments)
  const [checked, setChecked] = React.useState<readonly number[]>([]);
  const [left, setLeft] = React.useState<readonly number[]>(attachments);
  const [right, setRight] = React.useState<readonly number[]>(selected_attachments || []);

  const leftChecked = intersection(checked, left.map((item) => item.id));
  const rightChecked = intersection(checked, right.map((item) => item.id));

  const handleToggle = (value: number) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setChecked(newChecked);
  };

  const numberOfChecked = (ids: readonly number[]) =>
    intersection(checked, ids).length;

  const handleToggleAll = (ids: readonly number[]) => () => {
    if (numberOfChecked(ids) === ids.length) {
      setChecked(not(checked, ids));
    } else {
      setChecked(union(checked, ids));
    }
  };

  const findAttachment = (ids) => {
    return ids.map(
      (id) => attachmentList.find(
        (attachment) => attachment.id == id
      )
    )
  }
  const handleCheckedRight = () => {
    setRight([...right, ...findAttachment(leftChecked)])
    setLeft([...findAttachment(not(left.map((item) => item.id), leftChecked))])
  };

  const handleCheckedLeft = () => {
    setLeft([...left, ...findAttachment(rightChecked)])
    setRight([...findAttachment(not(right.map((item) => item.id), rightChecked))])
  };

  const customList = (title: React.ReactNode, items: object, ids: readonly number[]) => (
    <Card>
      <CardHeader
        sx={{ px: 2, py: 1 }}
        avatar={
          <Checkbox
            onClick={handleToggleAll(ids)}
            checked={numberOfChecked(ids) === ids.length && ids.length !== 0}
            indeterminate={
              numberOfChecked(ids) !== ids.length && numberOfChecked(ids) !== 0
            }
            disabled={ids.length === 0}
            inputProps={{
              'aria-label': 'all items selected',
            }}
          />
        }
        title={title}
        subheader={`${numberOfChecked(ids)}/${ids.length} selected`}
      />
      <Divider />
      <List
        sx={{
          width: 320,
          minHeight: 320,
          bgcolor: 'background.paper',
          overflow: 'auto',
        }}
        dense
        component="div"
        role="list"
      >
        {items.map((value: object) => {
          const labelId = `transfer-list-all-item-${value.id}-label`;

          return (
            <ListItem
              key={value.id}
              role="listitem"
              button
              onClick={handleToggle(value.id)}
            >
              <ListItemIcon>
                <Checkbox
                  checked={checked.indexOf(value.id) !== -1}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{
                    'aria-labelledby': labelId,
                  }}
                />
              </ListItemIcon>
              <ListItemText id={labelId} primary={value.name} />
              {(title == "選択済み") && <input type="hidden" name="selected_attachments[]" value={value.id} />}
            </ListItem>
          );
        })}
      </List>
    </Card>
  );

  return (
    <Grid container spacing={2} justifyContent="center" alignItems="center">
      <Grid item>{customList('関連資料', left, left.map((item) => item.id))}</Grid>
      <Grid item>
        <Grid container direction="column" alignItems="center">
          <Button
            sx={{ my: 0.5 }}
            variant="outlined"
            size="small"
            onClick={handleCheckedRight}
            disabled={leftChecked.length === 0}
            aria-label="move selected right"
          >
            &gt;
          </Button>
          <Button
            sx={{ my: 0.5 }}
            variant="outlined"
            size="small"
            onClick={handleCheckedLeft}
            disabled={rightChecked.length === 0}
            aria-label="move selected left"
          >
            &lt;
          </Button>
        </Grid>
      </Grid>
      <Grid item>{customList('選択済み', right, right.map((item) => item.id))}</Grid>
    </Grid>
  );
}

export default SelectAllTransferList
