import { Breadcrumb } from '@/components/Breadcrumb'
import Base from '@/components/Layout/Base'
import { Pagination } from '@/components/Pagination'
import { Table } from '@/components/Table'
import { Pagy } from '@/types/pagy'
import { User } from '@/types/user'
import { Tab } from '@headlessui/react'
import * as React from 'react'

type JobResult = {
  id: number
  company_id: number
  system: boolean
  job_class: string
  message: string
  start_at: string
  end_at: string
  status: 'pending' | 'in_progress' | 'success' | 'failure'
}

type Props = {
  current_user: User
  job_results: JobResult[]
  pagy: Pagy
}

const dateToString = (date: Date) => {
  // JSTに変換
  date.setHours(date.getHours() + 9)
  const iso = date.toISOString()
  return `${iso.slice(0, 10)} ${iso.slice(11, 19)}`
}
type TabType = 'PropertyLatLngJob' | 'HazardLinkJob'

const JobResultsShowPage: React.FC<Props> = ({ current_user, job_results, pagy }) => {
  const title = 'ジョブ結果'
  const allTabs: TabType[] = ['HazardLinkJob', 'PropertyLatLngJob']
  const searchParams = new URLSearchParams(window.location.search)
  const tab = searchParams.get("job_class") || "HazardLinkJob"

  const onChangeTab = (index: number) => {
    location.href = `${window.location.pathname}?job_class=${allTabs[index]}`
  }
  return (
    <>
      <Base
        current_user={current_user}
        title={title}
        breadcrumb={<Breadcrumb link_texts={[title]} />}
        submenuCategory="settings"
      >
        <Tab.Group defaultIndex={allTabs.indexOf(tab)} onChange={onChangeTab} className={'mt-4'}>
          <div className="flex text-sm overflow-x-auto whitespace-nowrap">
            <Tab.List className="flex items-center border-b-[1px] border-primary mb-2 md:w-full">
              <Tab
                className={`${styles['tab']} ${styles[tab === 'HazardLinkJob' ? 'true' : 'false']
                  } border-l-[1px]`}
              >
                ハザードマップリンク更新
              </Tab>
              <Tab
                className={`${styles['tab']} ${styles[tab === 'PropertyLatLngJob' ? 'true' : 'false']
                  }`}
              >
                緯度経度取得
              </Tab>
            </Tab.List>
          </div>
        </Tab.Group>

        <div className="flex flex-wrap items-center gap-1 whitespace-nowrap my-2">
          <span className="text-primary text-xl font-medium">{pagy.count.toLocaleString()}</span>
          <span className="text-gray-800 text-sm mt-0.5">
            件中 {pagy.from}～{pagy.to}件 表示
          </span>
          <span className="text-gray-800 text-sm mt-0.5 ml-2">表示件数：</span>
          <div className="ml-2">
            <Pagination
              pagy={pagy}
              navigate={(page) => {
                const searchParams = new URLSearchParams(window.location.search)
                searchParams.delete('page')
                const query = searchParams.toString() !== '' ? `&${searchParams.toString()}` : ''
                const path = `${window.location.pathname.match(/^\/\w*/)[0]}?page=${page}`
                window.location.href = query ? `${path}${query}` : path
              }}
            />
          </div>
        </div>

        <Table topScrollbar={true}>
          <thead className="bg-gray-150 text-sm">
            <tr className="whitespace-nowrap">
              <th scope="col" className="py-2 px-4 font-medium">
                ステータス
              </th>
              <th scope="col" className="py-2 px-4 font-medium">
                開始時刻
              </th>
              <th scope="col" className="py-2 px-4 font-medium">
                終了時刻
              </th>
              <th scope="col" className="py-2 px-4 font-medium">
                メッセージ
              </th>
            </tr>
          </thead>
          <tbody className="whitespace-nowrap">
            {job_results
              .filter((res) => res.job_class === tab)
              .map((result, index) => {
                return (
                  <tr
                    key={index}
                    className={`${index % 2 ? 'bg-gray-light' : 'bg-white'
                      } hover:bg-targetline py-2`}
                  >
                    <td className={'py-2 px-4'}>{result.status}</td>
                    <td className={'py-2 px-4'}>{dateToString(new Date(result.start_at))}</td>
                    <td className={'py-2 px-4'}>{dateToString(new Date(result.end_at))}</td>
                    <td className={'py-2 px-4'}>{result.message}</td>
                  </tr>
                )
              })}
          </tbody>
        </Table>
      </Base>
    </>
  )
}

const styles = {
  tab: `
    inline-flex
    outline-none
    select-none
    text-sm
    py-2
    px-8
    border-t-[1px]
    border-r-[1px]
    border-primary
  `,
  true: `
    bg-primary
    text-white
  `,
  false: `
    text-primary-font
    border-t-[1px]
    border-r-[1px]
    border-primary
  `,
}

export default JobResultsShowPage
