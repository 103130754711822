export default function setupAreaSaveButton(map, setWideAreaZoom, setNarrowAreaZoom) {
  // 広域として保存ボタンを追加
  const wideAreaButton = document.createElement('button')

  wideAreaButton.textContent = '広域として保存'
  wideAreaButton.classList.add('custom-map-control-button')
  wideAreaButton.setAttribute('type', 'button')
  wideAreaButton.style.fontSize = '14px'
  wideAreaButton.style.margin = '4px 8px'

  map.controls[google.maps.ControlPosition.TOP_CENTER].push(wideAreaButton)

  wideAreaButton.addEventListener('click', () => {
    const zoom = map.getZoom()
    setWideAreaZoom(zoom)
  })

  // 狭域として保存ボタンを追加
  const narrowAreaButton = document.createElement('button')

  narrowAreaButton.textContent = '狭域として保存'
  narrowAreaButton.classList.add('custom-map-control-button')
  narrowAreaButton.setAttribute('type', 'button')
  narrowAreaButton.style.fontSize = '14px'
  narrowAreaButton.style.margin = '4px 8px'

  map.controls[google.maps.ControlPosition.TOP_CENTER].push(narrowAreaButton)

  narrowAreaButton.addEventListener('click', () => {
    const zoom = map.getZoom()
    setNarrowAreaZoom(zoom)
  })
}