import { Breadcrumb } from '@/components/Breadcrumb'
import { AnchorButton, Button } from '@/components/Button'
import { OldGoogleMapViewer as GoogleMapViewer } from '@/components/GoogleMapViewer'
import Base from '@/components/Layout/Base'
import { CaretDownIcon } from '@/components/SvgIcon'
import { wards } from '@/models/wards'
import type { User } from '@/types/user'
import type { VolumeCheckDetail } from '@/types/volumeCheckDetail'
import { colors } from '@/utils/colors'
import { useJsApiLoader } from '@react-google-maps/api'
import * as React from 'react'
import quickVolumeCheck from '../Properties/quickVolumeCheck'
import { quickVolumeResult } from '../Properties/quickVolumeResult'
import Accordion from './Accordion'
import BordersForm from './BordersForm'
import ExtendsForm from './ExtendsForm'
import FundamentalsForm from './FundamentalsForm'
import { unit_m2 } from './volumeUnitFormat'

type Props = {
  current_user: User
  volume_check_detail: VolumeCheckDetail
  ref_volume_check_parameters?: any
  ref_volume_check_area?: any
  google_maps_api_key: string
  polygons_api_base_url: string
  authenticity_token: string
  plan_number: string
  env_debug?: boolean
}

interface PropertyData {
  area_info: string
  shape: any[]
}

const VolumeCheckRequestEditV2Page: React.FC<Props> = ({
  current_user,
  volume_check_detail,
  ref_volume_check_parameters,
  ref_volume_check_area,
  google_maps_api_key,
  polygons_api_base_url,
  authenticity_token,
  plan_number,
  env_debug,
}) => {
  const title = 'Volume Check登録'
  const property_id = Number(volume_check_detail.property.id)

  const [activePanel, setActivePanel] = React.useState<
    'fundametals' | 'borders' | 'extends' | null
  >('fundametals')

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: google_maps_api_key,
    libraries: ['geometry', 'drawing', 'places'],
  })

  const csrfToken: HTMLMetaElement = document.head.querySelector('meta[name="csrf-token"]')
  const isFirstRender = React.useRef(true)
  const [property, setProperty] = React.useState(volume_check_detail.property)
  const [propertyShape, setPropertyShape] = React.useState<string>(null)
  const [responseArea, setResponseArea] = React.useState('　')
  const [isPolygonRemoved, setIsPolygonRemoved] = React.useState(false)
  const formRef = React.useRef(null)

  const [checkboxValues, setCheckboxValues] = React.useState({
    compactResidence: ref_volume_check_parameters
      ? JSON.parse(ref_volume_check_parameters).compact_residence === 'true'
      : false,
    shadeRegulation: ref_volume_check_parameters
      ? JSON.parse(ref_volume_check_parameters).shade_regulation === 'true'
      : false,
  })

  const handleCheckboxChange = (name, newValue) => {
    setCheckboxValues({
      ...checkboxValues,
      [name]: newValue,
    })
  }

  const [inverseShadow, setInverseShadow] = React.useState('0')

  const handleInverseShadowChange = (newValue: string) => {
    setInverseShadow(newValue)
  }

  React.useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false
    } else {
      setResponseArea('')
    }
  }, [ref_volume_check_area, property])

  const [propertyData, setPropertyData] = React.useState<PropertyData>({
    area_info: '',
    shape: [],
  })

  const [propertyLatLng, setPropertyLatLng] = React.useState<{
    lat: string | number
    lng: string | number
  }>({
    lat: property?.lat || '',
    lng: property?.lng || '',
  })
  const [existdAreaValue, setExistdAreaValue] = React.useState('')

  React.useEffect(() => {
    if (ref_volume_check_area) {
      setExistdAreaValue(ref_volume_check_area || '')
    }
  }, [ref_volume_check_area, csrfToken.content])

  const currentUrl = window.location.href
  const isAdjusted = currentUrl.includes('adjusted')

  React.useEffect(() => {
    if (!ref_volume_check_area && !isPolygonRemoved) {
      let existdArea = property?.area_info
        ? unit_m2(JSON.parse(property?.area_info)?.['site']?.['area'])
        : ' '

      if (isAdjusted) {
        existdArea = ''
      }
      setExistdAreaValue(existdArea)
    }
  }, [ref_volume_check_area, property, isAdjusted])

  const [requestUUID, setRequestUUID] = React.useState('')
  const [polygonChanged, setPolygonChanged] = React.useState(isAdjusted ? true : false)
  const [areaChecked, setAreaChecked] = React.useState(false)

  React.useEffect(() => {
    if (isAdjusted) {
      setActivePanel('borders')
    } else {
      if (!polygonChanged) {
        setActivePanel('fundametals')
      } else {
        setActivePanel('borders')
      }
    }
  }, [propertyShape, isAdjusted, polygonChanged])

  const isclickDisabled = polygonChanged && !areaChecked

  // パラメーターが変更されたときのイベントハンドラ
  const parametersChanged = () => {
    const params = {
      center: {
        lat: Number((document.getElementById('center_lat') as HTMLInputElement).value),
        lng: Number((document.getElementById('center_lng') as HTMLInputElement).value),
      },
      borders: [],
      plan_setting: {
        chome: '',
        prefecture_name: '',
        city_name: '',
        plan_name: '',
        administrative_name: '',
        plan_number: 0,
        building_use: '',
        building_structure: '',
        manager_name: '',
        project_name: '',
        customization: {
          shade_regulation: '',
          compact_residence: '',
        },
      },
      city_planning_setting: {
        guideline: 0,
        district_planning: 0,
        city_planning_road: 0,
      },
      surroundings: [],
      building_setting: {
        floors: [],
        floors_above_ground: 0,
        floors_under_ground: 0,
        max_height: 0,
        floor_height: 0,
        gf_slab_level: 0,
        design_ground_level: 0,
        min_offset_road: 0,
        min_offset_neighbor: 0,
        sky_factor: 0,
        inverse_shadow: 0,
        number_of_units: 0,
        setting_building_area_ratio: '0',
      },
      unit_setting: {
        unit_area: 0,
        dwelling_unit_frontage: 0,
        balcony_width: 0,
        hallway_width: 0,
        entrance_area: 0,
        admin_room: 0,
        waste_storage: 0,
        elevator_number: 0,
      },
    }
    params.plan_setting = {
      chome: (document.getElementById('chome') as HTMLInputElement).value,
      prefecture_name: (document.getElementById('prefecture_name') as HTMLInputElement).value,
      city_name: (document.getElementById('city_name') as HTMLInputElement).value,
      plan_name: (document.getElementById('plan_name') as HTMLInputElement).value,
      administrative_name: (document.getElementById('administrative_name') as HTMLInputElement)
        .value,
      plan_number: Number((document.getElementById('plan_number') as HTMLInputElement).value),
      building_use: (document.getElementById('building_use') as HTMLInputElement).value,
      building_structure: (document.getElementById('building_structure') as HTMLInputElement).value,
      manager_name: (document.getElementById('manager_name') as HTMLInputElement).value,
      project_name: (document.getElementById('project_name') as HTMLInputElement).value,
      customization: {
        compact_residence: checkboxValues.compactResidence ? 'true' : 'false',
        shade_regulation: checkboxValues.shadeRegulation ? 'true' : 'false',
      },
    }
    params.city_planning_setting = {
      guideline: Number((document.getElementById('guideline') as HTMLInputElement).checked ? 1 : 0),
      district_planning: Number(
        (document.getElementById('district_planning') as HTMLInputElement).checked ? 1 : 0
      ),
      city_planning_road: Number(
        (document.getElementById('city_planning_road') as HTMLInputElement).checked ? 1 : 0
      ),
    }
    params.unit_setting = {
      unit_area: Number((document.getElementById('unit_area') as HTMLInputElement).value),
      dwelling_unit_frontage: Number(
        (document.getElementById('dwelling_unit_frontage') as HTMLInputElement).value
      ),
      balcony_width: Number((document.getElementById('balcony_width') as HTMLInputElement).value),
      hallway_width: Number((document.getElementById('hallway_width') as HTMLInputElement).value),
      entrance_area: Number((document.getElementById('entrance_area') as HTMLInputElement).value),
      admin_room: Number(
        (document.getElementById('admin_room') as HTMLInputElement).checked ? 1 : 0
      ),
      waste_storage: Number(
        (document.getElementById('waste_storage') as HTMLInputElement).checked ? 1 : 0
      ),
      elevator_number: Number(
        (document.getElementById('elevator_number') as HTMLInputElement).checked ? 1 : 0
      ),
    }
    let next_i = 0
    const count_borders = parseInt(
      (document.getElementById('count_borders') as HTMLInputElement).value,
      10
    )
    for (let i = 0; i < count_borders; i++) {
      if (i == count_borders - 1) {
        next_i = 0
      } else {
        next_i = i + 1
      }
      const from = (document.getElementById(`from_${i}`) as HTMLInputElement).value.split(',')
      const to = (document.getElementById(`to_${next_i}`) as HTMLInputElement).value.split(',')
      const border_type_code = (document.getElementById(`border_${i}`) as HTMLInputElement).value
      let set_back_method = (document.getElementById(`setback_${i}`) as HTMLInputElement).value
      let width = (document.getElementById(`width_${i}`) as HTMLInputElement).value
      const height = (document.getElementById(`height_${i}`) as HTMLInputElement).value
      const surroundings_type = (document.getElementById(`surroundings_${i}`) as HTMLInputElement)
        .value
      const surroundings_width = (
        document.getElementById(`surroundings_${i}_width`) as HTMLInputElement
      ).value
      if (['1', '2', '3', '5', '6', '7'].indexOf(border_type_code) !== -1) {
        if (Number(width) === 0) {
          width = '6'
        }
      } else {
        width = '0'
        set_back_method = '0'
      }
      params.borders.push({
        id: i + 1,
        from: { lat: Number(from[0]), lng: Number(from[1]), height: Number(height) },
        to: { lat: Number(to[0]), lng: Number(to[1]) },
        border_type_code: Number(border_type_code),
        set_back_method: Number(set_back_method),
        width: Number(width),
      })
      params.surroundings.push({
        border_id: i + 1,
        border_type_code: Number(surroundings_type),
        width: Number(surroundings_width),
      })
      document.getElementById(`width_${i}_collapse`).classList.remove('show')
      if (['1', '2', '3', '5', '6', '7'].indexOf(border_type_code) !== -1) {
        document.getElementById(`width_${i}_collapse`).classList.add('show')
      }
      if (['1', '2', '3', '5', '6', '7'].indexOf(surroundings_type) !== -1) {
        document.getElementById(`surroundings_width_${i}_collapse`).classList.add('show')
      }
      document.getElementById(`setback_${i}_collapse`).classList.remove('show')
      if (['1', '2', '3'].indexOf(border_type_code) !== -1) {
        document.getElementById(`setback_${i}_collapse`).classList.add('show')
      }
      document.getElementById(`surroundings_${i}_collapse`).classList.remove('show')
      document.getElementById(`surroundings_${i}_border`).classList.remove('border')
      if ((document.getElementById(`surroundings_${i}_check`) as HTMLInputElement).checked) {
        document.getElementById(`surroundings_${i}_collapse`).classList.add('show')
        document.getElementById(`surroundings_${i}_border`).classList.add('border')
      }
    }
    let floors_above_ground = Number(
      (document.getElementById(`floors_above_ground`) as HTMLInputElement).value
    )
    if (floors_above_ground === 0) {
      floors_above_ground = 15
    }
    const floors_under_ground = Number(
      (document.getElementById(`floors_under_ground`) as HTMLInputElement).value
    )
    params.building_setting = {
      floors: [],
      floors_above_ground: floors_above_ground,
      floors_under_ground: floors_under_ground,
      max_height:
        Number((document.getElementById(`max_height`) as HTMLInputElement).value) === 0
          ? 50
          : Number((document.getElementById(`max_height`) as HTMLInputElement).value),
      floor_height: Number((document.getElementById(`max_floor_height`) as HTMLInputElement).value),
      gf_slab_level: Number((document.getElementById(`gf_slab_level`) as HTMLInputElement).value),
      design_ground_level: Number(
        (document.getElementById(`design_ground_level`) as HTMLInputElement).value
      ),
      min_offset_road: Number(
        (document.getElementById('min_offset_road') as HTMLInputElement).value
      ),
      min_offset_neighbor: Number(
        (document.getElementById('min_offset_neighbor') as HTMLInputElement).value
      ),
      sky_factor: Number(
        (document.getElementById('sky_factor') as HTMLInputElement).checked ? 1 : 0
      ),
      inverse_shadow: Number(inverseShadow),
      number_of_units: Number(
        (document.getElementById('number_of_units') as HTMLInputElement).value
      ),
      setting_building_area_ratio: (
        document.getElementById('setting_building_area_ratio') as HTMLInputElement
      ).value,
    }
    let max_floor_height = Number(
      (document.getElementById(`max_floor_height`) as HTMLInputElement).value
    )
    if (max_floor_height === 0) {
      max_floor_height = 3
    }
    for (let i = 0; i < floors_above_ground; i++) {
      params.building_setting.floors.push({
        floor_number: i + 1,
        max_floor_height: max_floor_height,
      })
    }
    for (let i = 0; i < floors_under_ground; i++) {
      params.building_setting.floors.push({
        floor_number: i - 1,
        max_floor_height: max_floor_height,
      })
    }
    ; (document.getElementById('volume_check_request_parameters') as HTMLInputElement).value =
      JSON.stringify(params)
  }

  const { loading, loadingIcon } = quickVolumeResult(
    requestUUID,
    csrfToken.content,
    setResponseArea,
    propertyData,
    setPropertyData,
    null,
    displayNoAreaInfoMessage
  )

  const handlequickVolumeCheck = () => {
    setResponseArea('')
    setExistdAreaValue('')
    setAreaChecked(true)
    setPolygonChanged(false)
    quickVolumeCheck({
      isLoaded,
      propertyLatLng,
      propertyShape,
      setRequestUUID,
      csrfToken: csrfToken.content,
    })
  }

  const [showNoAreaInfoMessage, setShowNoAreaInfoMessage] = React.useState(false)

  function displayNoAreaInfoMessage() {
    setShowNoAreaInfoMessage(true)
  }

  return (
    <Base
      current_user={current_user}
      title={title}
      breadcrumb={
        <Breadcrumb
          link_texts={['物件管理', 'Volume Check', '登録']}
          property_id={volume_check_detail.property.hashid}
          tab="volume_check"
        />
      }
      submenuCategory="properties"
    >
      <div className="mt-8 pb-20 h-[calc(100vh-135px)]">
        {(!property || (!property?.shape?.latlngs && !propertyShape)) && (
          <p className="mb-4 text-sm text-red">敷地形状を作成して下さい</p>
        )}
        <div className="flex gap-4 h-full">
          <div className="w-2/3 h-full overflow-y-auto">
            {isLoaded && (
              <GoogleMapViewer
                current_user={current_user}
                polygons_api_base_url={polygons_api_base_url}
                csrfToken={csrfToken.content}
                mode="volume"
                property_id={property_id}
                lat={volume_check_detail.property.address_geocoding[0]}
                lng={volume_check_detail.property.address_geocoding[1]}
                ref_volume_check_parameters={ref_volume_check_parameters}
                existdAreaValue={existdAreaValue}
                areaTextFromResponse={responseArea}
                areaShape={propertyShape}
                requestUUID={requestUUID}
                onChangePolygon={setPolygonChanged}
                onChangeAreaCheck={setAreaChecked}
                onRemovePolygon={setIsPolygonRemoved}
                checkboxValues={checkboxValues}
                zoom={20}
                onChangeParameters={(property) => {
                  setProperty(property)
                }}
                onChangeShape={(shape) => {
                  setPropertyShape(shape)
                }}
                onChangeLatLng={(latLng) => {
                  setPropertyLatLng(latLng)
                }}
                style={{
                  width: '100%',
                  height: '600px',
                }}
              />
            )}
            <span
              className={`mt-4 inline-block py-0.5 px-0.5 leading-none text-[10px] border ${propertyShape === null || propertyShape === ''
                  ? 'border-[#d4c5c5]'
                  : 'border-primary'
                }`}
            >
              <Button
                className="w-[102px] self-center"
                size="small"
                type="button"
                disabled={propertyShape === null || propertyShape === '' || loading}
                title={
                  propertyShape === null || propertyShape === '' ? '敷地形状を作成してください' : ''
                }
                onClick={handlequickVolumeCheck}
              >
                {loading ? loadingIcon : 'area check'}
              </Button>
            </span>
            {showNoAreaInfoMessage && (
              <>
                <span className="text-primary-font">
                  <br />
                  この地域の情報は提供しておりません
                </span>
              </>
            )}
          </div>
          <div className="w-1/3 h-full overflow-y-auto">
            <form
              ref={formRef}
              action={`/properties/${volume_check_detail.property.hashid}/volume_check_requests`}
              acceptCharset="UTF-8"
              method="post"
            >
              <input type="hidden" name="authenticity_token" value={authenticity_token} />
              {property && (property?.shape?.latlngs || !!propertyShape) ? (
                <>
                  <Accordion
                    title="① 基本設定"
                    body={
                      <div id="borders">
                        <FundamentalsForm
                          property={property}
                          plan_number={plan_number}
                          onChangeParameters={parametersChanged}
                          ref_volume_check_parameters={ref_volume_check_parameters}
                        />
                      </div>
                    }
                    show={activePanel === 'fundametals'}
                    onClick={() => {
                      activePanel === 'fundametals'
                        ? setActivePanel(null)
                        : setActivePanel('fundametals')
                    }}
                  />
                  <div className="py-1" />
                  <Accordion
                    title="② 敷地境界条件"
                    body={
                      <div id="borders">
                        <BordersForm
                          property={property}
                          propertyShape={propertyShape}
                          onChangeParameters={parametersChanged}
                          ref_volume_check_parameters={ref_volume_check_parameters}
                          authenticity_token={authenticity_token}
                        />
                      </div>
                    }
                    show={activePanel === 'borders'}
                    onClick={() => {
                      activePanel === 'borders' ? setActivePanel(null) : setActivePanel('borders')
                    }}
                  />
                  <div className="py-1" />
                  <Accordion
                    title="③ 拡張設定"
                    body={
                      <div id="extends">
                        <ExtendsForm
                          property={property}
                          propertyShape={propertyShape}
                          onChangeParameters={parametersChanged}
                          ref_volume_check_parameters={ref_volume_check_parameters}
                          checkboxValues={checkboxValues}
                          onCheckboxChange={handleCheckboxChange}
                          inverseShadow={inverseShadow}
                          onInverseShadowChange={handleInverseShadowChange}
                          env_debug={env_debug}
                        />
                      </div>
                    }
                    show={activePanel === 'extends'}
                    onClick={() => {
                      activePanel === 'extends' ? setActivePanel(null) : setActivePanel('extends')
                    }}
                  />
                  <div className="bg-white flex flex-wrap justify-center items-center w-full left-0 md:gap-4 border-t border-gray-150 md:py-2 fixed bottom-0 md:left-12 z-20">
                    <AnchorButton
                      className="w-full md:w-40 md:h-auto text-gray-300 mx-2"
                      variant="text"
                      href={`/properties/${volume_check_detail.property.hashid}/?tab=volume_check`}
                    >
                      キャンセル
                    </AnchorButton>
                    <div className="flex items-center mx-2 gap-4 my-2">
                      <input
                        type="checkbox"
                        id="update_required"
                        name="update_required"
                        value="true"
                      />
                      <label
                        htmlFor="update_required"
                        className="text-sm text-primary-font select-none"
                      >
                        物件の形状を更新する
                      </label>
                    </div>
                    <Button
                      className="w-full md:w-40 md:h-auto mx-2"
                      disabled={
                        propertyShape === null || propertyShape === '' || loading || isclickDisabled
                      }
                      onClick={(e) => {
                        e.preventDefault()
                        parametersChanged()
                        formRef.current.submit()
                      }}
                    >
                      ボリュームチェック
                    </Button>
                  </div>
                </>
              ) : (
                <>
                  <div className="border rounded flex w-full items-center p-4 text-left opacity-50">
                    <CaretDownIcon fill={colors.gray} className="-rotate-90" />
                    <div className="ml-3 leading-none">① 基本設定</div>
                  </div>
                  <div className="py-1" />
                  <div className="border rounded flex w-full items-center p-4 text-left opacity-50">
                    <CaretDownIcon fill={colors.gray} className="-rotate-90" />
                    <div className="ml-3 leading-none">② 敷地境界条件</div>
                  </div>
                  <div className="py-1" />
                  <div className="border rounded flex w-full items-center p-4 text-left opacity-50">
                    <CaretDownIcon fill={colors.gray} className="-rotate-90" />
                    <div className="ml-3 leading-none">③ 拡張設定</div>
                  </div>
                  <div className="bg-white flex justify-center items-center w-full left-0 md:gap-4 border-t border-gray-150 md:py-2 fixed bottom-0 md:left-12 z-20">
                    <AnchorButton
                      className="w-full md:w-40 md:h-auto text-gray-300"
                      variant="text"
                      href={`/properties/${volume_check_detail.property.hashid}/?tab=volume_check`}
                    >
                      キャンセル
                    </AnchorButton>
                    <Button className="w-full md:w-40 md:h-auto opacity-50" disabled>
                      ボリュームチェック
                    </Button>
                  </div>
                </>
              )}
              <input
                type="hidden"
                name="volume_check_request[parameters]"
                id="volume_check_request_parameters"
                value={volume_check_detail.parameters}
              />
              <input
                type="hidden"
                name="prefecture_name"
                id="prefecture_name"
                value={volume_check_detail.property.prefecture_before_type_cast}
              />
              <input
                type="hidden"
                name="city_name"
                id="city_name"
                value={wards[volume_check_detail.property.city]}
              />
              <input
                type="hidden"
                name="chome"
                id="chome"
                value={`${volume_check_detail.property.town}${volume_check_detail.property.chome}`}
              />
              <input
                type="hidden"
                name="administrative_name"
                id="administrative_name"
                value="atr:"
              />
              <input
                type="hidden"
                name="center_lat"
                id="center_lat"
                value={volume_check_detail.property.lat}
              />
              <input
                type="hidden"
                name="center_lng"
                id="center_lng"
                value={volume_check_detail.property.lng}
              />
              <input type="hidden" name="area_info" id="area_info" value={propertyData.area_info} />
              <input type="hidden" name="shape" id="shape" value={propertyData.shape} />
            </form>
          </div>
        </div>
      </div>
    </Base>
  )
}

export default VolumeCheckRequestEditV2Page
