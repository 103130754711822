import * as React from 'react'
import Base from '@/components/Layout/Base'
import type { User } from '@/types/user'
import type { Tag } from '@/types/tag'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { Container } from '@/components/SortableList/BusinessType/Container'
import { Breadcrumb } from '@/components/Breadcrumb'

type Props = {
  current_user: User
  business_types: Tag[]
}

const BusinessTypesIndexPage: React.FC<Props> = ({ current_user, business_types }) => {
  const title = '事業形態一覧'

  return (
    <Base
      current_user={current_user}
      title={title}
      breadcrumb={<Breadcrumb link_texts={['事業形態設定']} />}
      submenuCategory="settings"
    >
      <div>
        <DndProvider backend={HTML5Backend}>
          <Container items={business_types} />
        </DndProvider>
      </div>
    </Base>
  )
}

export default BusinessTypesIndexPage
