export const displayRadioOptions = [
  { value: 'specify', text: '件数を指定して出力' },
  { value: 'all', text: '全件出力' },
]
// デフォルトの案件表示数
export const displayDeffaultNumber = 30

// pdfダウンロードボタンにカーソルが重なった時の補足説明
export const PDFDownloadSupplement =
  '1グループあたりの案件出力数を、隣接する歯車アイコンをクリックして設定することができます。'

export const sortOptionsDeffault = [
  { value: '', text: '（なし）' },
  { value: 'tag_name', text: 'タグ' },
  { value: 'ongoing_proposal_source_user_id', text: '情報入手者' },
  { value: 'user_id', text: '案件担当者' },
  { value: 'ongoing_proposal_sourced_at', text: '入手日（昇順）' },
  { value: 'ongoing_proposal_sourced_at desc', text: '入手日（降順）' },
  { value: 'purchace_applied_at', text: '買付提出日（昇順）' },
  { value: 'purchace_applied_at desc', text: '買付提出日（降順）' },
  { value: 'purchace_contract_at', text: '仕入契約日（昇順）' },
  { value: 'purchace_contract_at desc', text: '仕入契約日（降順）' },
  { value: 'area_m3', text: '敷地面積（昇順）' },
  { value: 'area_m3 desc', text: '敷地面積（降順）' },
  { value: 'effective_area', text: '有効敷地面積（昇順）' },
  { value: 'effective_area desc', text: '有効敷地面積（降順）' },
  { value: 'total_floor_area', text: '延床面積(昇順）' },
  { value: 'total_floor_area desc', text: '延床面積（降順）' },
  { value: 'occupied_area', text: '建物賃貸可能面積（専有面積）（昇順）' },
  { value: 'occupied_area desc', text: '建物賃貸可能面積（専有面積）（降順）' },
  { value: 'ongoing_proposal_suggested_total_price', text: '売主希望総額（昇順）' },
  { value: 'ongoing_proposal_suggested_total_price desc', text: '売主希望総額（降順）' },
  { value: 'current_prospective_yield', text: '現況表面利回り（昇順）' },
  { value: 'current_prospective_yield desc', text: '現況表面利回り（降順）' },
]
