import * as React from "react";
import { FC, useMemo } from 'react';
import { DrawingBorderLine } from '../Drawing';
import { Vector2 } from 'three';
import { RAD2DEG } from 'three/src/math/MathUtils';
import {
  formatMeasurementValue,
  textReadableDegree,
} from '../../helpers/DrawingHelper';
import { findSegmentSegmentIntersection } from '../../helpers/ClosestPointHelper';

export type BorderLineProps = {
  borderLine: DrawingBorderLine;
  strokeWidth?: number;
  fontSize?: number;
  displayText?: boolean;
  displayRoadWidth?: boolean;
};

const BorderLine: FC<BorderLineProps> = ({
  borderLine,
  strokeWidth,
  fontSize,
  displayText,
  displayRoadWidth,
}) => {
  const neighbor = useMemo(() => {
    if ('Neighbor' in borderLine) {
      return borderLine.Neighbor;
    }
    return undefined;
  }, [borderLine]);

  const road = useMemo(() => {
    if ('Road' in borderLine) {
      return borderLine.Road;
    }
    return undefined;
  }, [borderLine]);

  const setBackInnerSegment = useMemo(() => {
    return road?.set_back_inner_segment ?? null;
  }, [road]);

  const innerSegment = useMemo(() => {
    return road?.inner_segment ?? null;
  }, [road]);

  const centerSegment = useMemo(() => {
    return road?.center_segment ?? null;
  }, [road]);

  const outerSegment = useMemo(() => {
    return road?.outer_segment ?? null;
  }, [road]);

  const setBackOuterSegment = useMemo(() => {
    return road?.set_back_outer_segment ?? null;
  }, [road]);

  const width = strokeWidth ?? 20;

  // 一点鎖線
  const strokeDashArray = useMemo(() => {
    const separation = 30;
    const ratio = 10;
    return `${separation * ratio} ${separation} ${separation} ${separation}`;
  }, []);

  const uniformDashArray = useMemo(() => {
    const separation = 30;
    return `${separation} ${separation}`;
  }, []);

  const mostInnerSegment = useMemo(() => {
    return (neighbor ?? setBackInnerSegment ?? innerSegment)!;
  }, [neighbor, setBackInnerSegment, innerSegment]);

  const borderMeasurement = useMemo(() => {
    const { a, b } = mostInnerSegment;
    const dir = new Vector2(b[0] - a[0], b[1] - a[1]);
    const len = dir.length();
    const angle = dir.angle() * RAD2DEG;
    return {
      position: new Vector2(a[0] + b[0], a[1] + b[1]).divideScalar(2),
      label: `${
        'Road' in borderLine ? '道路境界線' : '隣地境界線'
      } ${formatMeasurementValue(len)}`,
      // angle: textReadableDegree(angle),
      angle: angle,
    };
  }, [borderLine, mostInnerSegment]);

  const roadLabel = useMemo(() => {
    if (centerSegment !== null) {
      const { a, b } = centerSegment;
      const normal = new Vector2(b[0] - a[0], b[1] - a[1]).normalize();
      return {
        center: new Vector2(a[0] + b[0], a[1] + b[1]).divideScalar(2),
        angle: textReadableDegree(normal.angle() * RAD2DEG + 90),
      };
    }
    return null;
  }, [centerSegment]);

  const roadMeasurements = useMemo(() => {
    if (centerSegment === null) return [];

    const { a: ca, b: cb } = centerSegment;
    const dir = new Vector2(cb[0] - ca[0], cb[1] - ca[1]);
    const len = dir.length();
    const offset = dir
      .clone()
      .normalize()
      .multiplyScalar(len / 4);
    const center = new Vector2(ca[0], ca[1]).add(offset);
    const normal = new Vector2(-dir.y, dir.x);
    const orth = {
      a: center.clone().sub(normal.clone().multiplyScalar(1e4)),
      b: center.clone().add(normal.clone().multiplyScalar(1e4)),
    };

    const measurements = [];
    if (
      innerSegment !== null &&
      outerSegment !== null &&
      road?.road_width !== null
    ) {
      const orth = {
        a: center.clone().sub(normal.clone().multiplyScalar(1e4)),
        b: center.clone().add(normal.clone().multiplyScalar(1e4)),
      };
      const start = findSegmentSegmentIntersection(orth, innerSegment);
      const end = findSegmentSegmentIntersection(orth, outerSegment);
      if (start !== null && end !== null) {
        measurements.push({
          start,
          end,
          center,
          angle: textReadableDegree(dir.angle() * RAD2DEG + 90),
          label: `${formatMeasurementValue(road?.road_width ?? 0)}`,
        });
      }
    }

    if (
      innerSegment !== null &&
      setBackInnerSegment !== null &&
      road?.set_back_inner_distance !== null
    ) {
      const start = findSegmentSegmentIntersection(orth, innerSegment);
      const end = findSegmentSegmentIntersection(orth, setBackInnerSegment);
      if (start !== null && end !== null) {
        measurements.push({
          start,
          end,
          center: end,
          angle: textReadableDegree(dir.angle() * RAD2DEG + 90),
          label: `${formatMeasurementValue(
            road?.set_back_inner_distance ?? 0,
          )}`,
        });
      }
    }

    if (
      outerSegment !== null &&
      setBackOuterSegment !== null &&
      road?.set_back_outer_distance !== null
    ) {
      const start = findSegmentSegmentIntersection(orth, outerSegment);
      const end = findSegmentSegmentIntersection(orth, setBackOuterSegment);
      if (start !== null && end !== null) {
        measurements.push({
          start,
          end,
          center: end,
          angle: textReadableDegree(dir.angle() * RAD2DEG + 90),
          label: `${formatMeasurementValue(
            road?.set_back_outer_distance ?? 0,
          )}`,
        });
      }
    }

    const inner = setBackInnerSegment ?? innerSegment;
    const outer = setBackOuterSegment ?? outerSegment;
    if (
      inner !== null &&
      outer !== null &&
      road?.set_back_outer_distance !== null
    ) {
      const setBackOffset = dir
        .clone()
        .normalize()
        .multiplyScalar(len / 4 + 1e3);
      const setBackCenter = new Vector2(ca[0], ca[1]).add(setBackOffset);
      const setBackOrth = {
        a: setBackCenter.clone().sub(normal.clone().multiplyScalar(1e4)),
        b: setBackCenter.clone().add(normal.clone().multiplyScalar(1e4)),
      };

      const start = findSegmentSegmentIntersection(setBackOrth, inner);
      const end = findSegmentSegmentIntersection(setBackOrth, outer);
      if (start !== null && end !== null) {
        measurements.push({
          start,
          end,
          center: setBackCenter,
          angle: textReadableDegree(dir.angle() * RAD2DEG + 90),
          label: `${formatMeasurementValue(road?.set_back_road_width ?? 0)}`,
        });
      }
    }

    return measurements;
  }, [
    road,
    setBackInnerSegment,
    innerSegment,
    centerSegment,
    outerSegment,
    setBackOuterSegment,
  ]);

  return (
    <g>
      <circle
        cx={mostInnerSegment.a[0]}
        cy={mostInnerSegment.a[1]}
        r={80}
        stroke="black"
        strokeWidth={width}
        fill="none"
      />
      {neighbor !== undefined && (
        <line
          x1={neighbor.a[0]}
          y1={neighbor.a[1]}
          x2={neighbor.b[0]}
          y2={neighbor.b[1]}
          stroke="black"
          strokeWidth={width}
          strokeDasharray={strokeDashArray}
        />
      )}
      {setBackInnerSegment !== null && (
        <line
          type="set-back-inner-segment"
          x1={setBackInnerSegment.a[0]}
          y1={setBackInnerSegment.a[1]}
          x2={setBackInnerSegment.b[0]}
          y2={setBackInnerSegment.b[1]}
          stroke="black"
          strokeWidth={width}
          strokeDasharray={strokeDashArray}
        />
      )}
      {innerSegment !== null && (
        <line
          type="inner-segment"
          x1={innerSegment.a[0]}
          y1={innerSegment.a[1]}
          x2={innerSegment.b[0]}
          y2={innerSegment.b[1]}
          stroke="black"
          strokeWidth={width}
          strokeDasharray={
            setBackInnerSegment !== null ? uniformDashArray : strokeDashArray
          }
        />
      )}
      {centerSegment !== null && (
        <line
          type="center-segment"
          x1={centerSegment.a[0]}
          y1={centerSegment.a[1]}
          x2={centerSegment.b[0]}
          y2={centerSegment.b[1]}
          stroke="pink"
          strokeWidth={width}
          strokeDasharray={strokeDashArray}
        />
      )}
      {outerSegment !== null && (
        <line
          type="outer-segment"
          x1={outerSegment.a[0]}
          y1={outerSegment.a[1]}
          x2={outerSegment.b[0]}
          y2={outerSegment.b[1]}
          stroke="black"
          strokeWidth={width}
        />
      )}
      {setBackOuterSegment !== null && (
        <line
          type="set-back-outer-segment"
          x1={setBackOuterSegment.a[0]}
          y1={setBackOuterSegment.a[1]}
          x2={setBackOuterSegment.b[0]}
          y2={setBackOuterSegment.b[1]}
          stroke="black"
          strokeWidth={width}
          strokeDasharray={uniformDashArray}
        />
      )}
      {displayText && (
        <text
          fontSize={fontSize ?? 120}
          x={borderMeasurement.position.x}
          y={borderMeasurement.position.y}
          textAnchor="middle"
          alignmentBaseline="after-edge"
          transform={`rotate(${borderMeasurement.angle}, ${borderMeasurement.position.x}, ${borderMeasurement.position.y})`}
        >
          {borderMeasurement.label}
        </text>
      )}
      {roadLabel && displayText && (
        <text
          fontSize={fontSize ?? 120}
          x={roadLabel.center.x}
          y={roadLabel.center.y}
          textAnchor="middle"
          alignmentBaseline="after-edge"
          transform={`rotate(${roadLabel.angle}, ${roadLabel.center.x}, ${roadLabel.center.y})`}
        >
          道 路
        </text>
      )}
      {road && displayRoadWidth && (
        <g>
          {roadMeasurements.map((m, idx) => {
            return (
              <g key={`measurement-${idx}`}>
                <line
                  x1={m.start.x}
                  y1={m.start.y}
                  x2={m.end.x}
                  y2={m.end.y}
                  stroke="pink"
                  strokeWidth={width}
                />
                <circle cx={m.start.x} cy={m.start.y} r={60} fill="aqua" />
                <circle cx={m.end.x} cy={m.end.y} r={60} fill="aqua" />
                {displayText && (
                  <text
                    fontSize={fontSize ?? 120}
                    x={m.center.x}
                    y={m.center.y}
                    textAnchor="middle"
                    alignmentBaseline="after-edge"
                    transform={`rotate(${m.angle}, ${m.center.x}, ${m.center.y})`}
                  >
                    {m.label}
                  </text>
                )}
              </g>
            );
          })}
        </g>
      )}
    </g>
  );
};

export { BorderLine };
