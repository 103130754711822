import * as React from 'react'
import { useDragAndDrop } from '@/components/SortableList/useDragAndDrop'
import { Input } from '@/components/Input'
import { Select } from '@/components/Select'
import { Button } from '@/components/Button'
import DragIndicatorOutlined from '@material-ui/icons/DragIndicatorOutlined'

export interface CardProps {
  id: number
  index: number
  column_list: { value: string; text: string }[]
  layoutColumnValues: string[]
  onChangeLayoutColumnValues: (value: string, index: number) => void
  moveCard: (dragIndex: number, hoverIndex: number) => void
}

export const Card: React.FC<CardProps> = ({
  id,
  index,
  column_list,
  layoutColumnValues,
  onChangeLayoutColumnValues,
  moveCard,
}) => {
  const [ref, isDragging, handlerId] = useDragAndDrop(index, moveCard)
  const opacity = isDragging ? 0 : 1

  return (
    <div
      key={id}
      ref={ref}
      style={{ opacity }}
      data-handler-id={handlerId}
      className="flex gap-[6px]"
    >
      <div className="flex flex-col gap-[6px]" key={index}>
        <Button
          className="text-primary-font cursor-move"
          prefix={<DragIndicatorOutlined fontSize="small" />}
          outline
          size="small"
          variant="primary"
          type="button"
        >
          <span className="text-primary-font hidden md:block">移動</span>
        </Button>
        <div className="w-[170px]">
          <Select
            className="text-sm"
            value={layoutColumnValues[index]}
            name={`column_${index}`}
            id={`column_${index}`}
            options={column_list}
            onChange={(e) => onChangeLayoutColumnValues(e.target.value, index)}
          />
        </div>
      </div>
    </div>
  )
}
