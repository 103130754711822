import * as React from 'react'

export const RowAreaInfo: React.FC<{
  label?: React.ReactNode
  children?: React.ReactNode
  className?: string
}> = ({ label, children, className }) => (
  <div className={['flex md:flex-nowap border-b border-[#C3DEEE]', className].join(' ')}>
    {label}
    {children}
  </div>
)

export const ThAreaInfo: React.FC<{
  className?: string
  children?: React.ReactNode
  column?: number
  left?: boolean
}> = ({ className, children, column = 1, left = false }) => {
  const width = styles.th[`column${column}`]

  return (
    <div
      className={[
        'flex items-center bg-[#eff9ff] py-0 px-0 md:py-0 md:px-0 text-black-base text-sm',
        left ? 'md:text-left' : 'md:text-right',
        width,
        className,
      ].join(' ')}
    >
      {children}
    </div>
  )
}

export const TdAreaInfo: React.FC<{
  children?: React.ReactNode
  column?: number
  multiLine?: boolean
}> = ({ children, column = 1, multiLine = false }) => {
  const width = styles.td[`column${column}`]

  return (
    <div
      className={[
        'border-l border-[#C3DEEE] py-0 px-0 md:py-0 md:px-0',
        width,
        multiLine ? '' : 'flex flex-wrap items-center',
      ].join(' ')}
    >
      {children}
    </div>
  )
}

export const TdAreaInfoData: React.FC<{
  children?: React.ReactNode
  column?: number
  multiLine?: boolean
}> = ({ children, column = 1, multiLine = false }) => {
  const width = styles.td[`column${column}`]

  return (
    <div
      className={[
        'border-l border-[#C3DEEE] py-0 px-0 md:py-0 md:px-4',
        width,
        multiLine ? '' : 'flex flex-wrap items-center',
      ].join(' ')}
    >
      {children}
    </div>
  )
}

const styles = {
  th: {
    column1: `w-1/3 md:w-1/6`,
    column2: `w-1/3`,
    column3: `w-1/3 md:w-1/2`,
    column4: `w-1/2 md:w-1/4`,
    column5: `w-1/2`,
  },
  td: {
    column1: `w-2/3 md:w-5/6`,
    column2: `w-2/3`,
    column3: `w-2/3 md:w-1/2`,
    column4: `w-1/6 md:w-5/24`,
    column5: `w-1/3 md:w-5/12`,
    column6: `w-2/15 md:w-1/6`,
    column7: `w-1/2 md:w-3/4`,
    column8: `w-1/2`,
  },
}
