import * as React from "react";
import { FC } from 'react';
import { Color, Vector2 } from 'three';

type BalconyProps = {
  path: Vector2[][];
};

const Balcony: FC<BalconyProps> = ({ path }) => {
  return (
    <g name="balcony">
      {path.map((points, i) => {
        return (
          <polyline
            key={`balcony-${i}`}
            points={points
              .map((p) => {
                return `${p.x},${p.y}`;
              })
              .join(' ')}
            fill={`#${new Color(
              186 / 255,
              213 / 255,
              58 / 255,
            ).getHexString()}`}
            fillOpacity={0.65}
          />
        );
      })}
    </g>
  );
};

export { Balcony };
