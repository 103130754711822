import '@/components/GoogleMapViewer/style.css'
import { UserContext } from '@/components/Layout/Base'
import { Loading } from '@/components/Loading'
import { Row, Td, Th } from '@/components/Page/Properties/Detail/Form'
import { Table } from '@/components/Table'
import type { MarketDatum } from '@/types/marketDatum'
import type { LatLng } from '@/types/property'
import type { User } from '@/types/user'
import { cover_enterprise_pricing_type } from '@/utils/policy'
import { GoogleMap } from '@react-google-maps/api'
import * as React from 'react'
import { marketDataReportSetup } from '../marketDataReportSetup'
import { marketDataSetup } from '../marketDataSetup'
import { LayerButton } from '../setupButtons/Layer/LayerButton'
import { setup3Dbutton } from '../setupButtons/setup3Dbutton'
import { setupLocationButton } from '../setupButtons/setupLocationButton'
import { setupResetButton } from '../setupButtons/setupResetButton'

interface MapProps {
  polygons_api_base_url?: string
  zoom?: number
  current_user: User
  csrfToken: string
  property_id?: number
  volume_check_request_id?: number
  lat?: number | string
  lng?: number | string
  propertyLat?: number
  propertyLng?: number
  propertyShape?: LatLng[]
  existdAreaValue?: string
  areaTextFromResponse?: string
  areaShape?: string
  requestUUID?: string
  onChangePolygon?: (changed: boolean) => void
  onChangeAreaCheck?: (changed: boolean) => void
  onRemovePolygon?: (removed: boolean) => void
  ref_volume_check_parameters?: any
  setChibanInfo?: (info: any) => void
  onChangeLatLng?: (latLng: LatLng) => void
  onChangeShape?: (shape: any) => void
  onChangeParameters?: (property: any) => void
  setWideAreaZoom?: (zomm: number) => void
  setNarrowAreaZoom?: (zomm: number) => void
  setShowYoutoInfo?: (show: boolean) => void
  setYoutoInfo?: (key: string, info: any) => void
  setOnLoadComplete?: (complete: boolean) => void
  style?: React.CSSProperties
}

export interface MapHandles {
  setLocation(address: string, zoom?: number): void
  setPing(address: string): void
  setMarketData(
    marketData: MarketDatum[],
    clickMarker: (marketDatum: MarketDatum) => void,
    filteredMarketDatum: MarketDatum
  ): void
  setMarketDataReport(marketData: MarketDatum[]): void
  clearMarketData(): void
}

export const GoogleMapViewer = React.forwardRef<MapHandles, MapProps>(
  (
    {
      polygons_api_base_url = '',
      zoom = 19,
      current_user,
      lat,
      lng,
      propertyLat,
      propertyLng,
      propertyShape,
      existdAreaValue = '',
      onChangePolygon,
      onChangeAreaCheck,
      setChibanInfo,
      onChangeLatLng,
      onChangeShape,
      setShowYoutoInfo,
      setYoutoInfo,
      setOnLoadComplete,
      style,
    }: MapProps,
    ref
  ) => {
    const mapOptions = React.useMemo(() => {
      return {
        center: { lat: Number(lat), lng: Number(lng) },
        zoom: zoom,
        minZoom: 1,
        zoomControl: true,
        mapTypeControl: false,
        scaleControl: false,
        streetViewControl: true,
        rotateControl: false,
        fullscreenControl: true,
        tilt: 0,
        mapTypeId: 'roadmap',
      }
    }, [lat, lng])

    const { user } = React.useContext(UserContext)

    const [showInfoTable, setShowInfoTable] = React.useState(false)
    const [marketDataMarkers, setMarketDataMarkers] = React.useState([])
    const [shadeInfos, setShadeInfos] = React.useState({
      shade5m: '-',
      shade10m: '-',
      shadeHeight: '-',
    })
    const [usageInfos, setUsageInfos] = React.useState({
      usageArea: '-',
      buildingCoverageRatio: '-',
      floorAreaRatio: '-',
    })
    const [heightInfos, setHeightInfos] = React.useState({
      heightInfo: '-',
      heightMax: '-',
      heightMin: '-',
    })
    const [antifireInfo, setAntifireInfo] = React.useState('-')

    const mapRef = React.useRef(null)

    const propertyPingRef = React.useRef(null)

    React.useImperativeHandle(ref, () => ({
      async setLocation(address, zoom) {
        const geocoder = new google.maps.Geocoder()
        const results = await geocoder.geocode({ address: address, language: 'ja' })
        mapRef?.current.setCenter(results.results[0].geometry.location)
        if (zoom !== undefined) {
          mapRef?.current.setZoom(zoom)
        }
      },
      async setPing(address) {
        const geocoder = new google.maps.Geocoder()
        const results = await geocoder.geocode({ address: address, language: 'ja' })
        const location = results.results[0].geometry.location
        const lat = location.lat()
        const lng = location.lng()
        if (location !== undefined) {
          // 物件のピンを追加
          const icon = {
            url: '/target_ping.png',
            scaledSize: new google.maps.Size(37, 30),
          }
          propertyPingRef.current && propertyPingRef.current.setMap(null)
          propertyPingRef.current = new google.maps.Marker({
            position: location,
            map: mapRef.current,
            optimized: false,
            draggable: true,
          })

          propertyPingRef.current.setOptions({ zIndex: 99999 })
          propertyPingRef.current.setIcon(icon)
          onChangeLatLng({ lat, lng })
        }
      },
      setMarketData(
        marketData: MarketDatum[],
        clickMarker: (marketDatum: MarketDatum) => void,
        filteredMarketDatum: MarketDatum
      ) {
        // Mapに周辺事例のピンを表示する
        const markers = marketDataSetup(
          mapRef?.current,
          marketData,
          marketDataMarkers,
          clickMarker,
          filteredMarketDatum
        )

        // タブ切替時に表示していたピンのクリアを行うため保持しておく
        setMarketDataMarkers(markers)
      },
      setMarketDataReport(marketData: MarketDatum[]) {
        // Mapに周辺事例のピンを表示する
        const markers = marketDataReportSetup(mapRef?.current, marketData)

        // タブ切替時に表示していたピンのクリアを行うため保持しておく
        setMarketDataMarkers(markers)
      },
      clearMarketData() {
        // タブ切り替え前に表示していた周辺事例のピンをクリアする
        marketDataMarkers.forEach((marker) => {
          marker.setMap(null)
        })

        setMarketDataMarkers([])
      },
    }))

    const [currentLocationMarker, setCurrentLocationMarker] = React.useState(null)
    const showAreaTextRef = React.useRef(null)
    const loading = <Loading height={20} width={20} />

    const onLoad = (map) => {
      mapRef.current = map

      if (setOnLoadComplete) {
        // mapRef.currentが取得できることを通知
        setOnLoadComplete(true)
      }

      let currentPolygon = null // 図形のポリゴン
      let areaText = null
      const areaMarkers = []
      const styledMapType = new google.maps.StyledMapType(
        [
          {
            elementType: 'labels.icon',
            stylers: [{ visibility: 'off' }],
          },
          {
            featureType: 'transit.line', // 交通機関の路線
            elementType: 'all', // ラベルを含めてすべて
            stylers: [{ visibility: 'on' }],
          },
          {
            featureType: 'transit.station.rail', // 電車の駅
            elementType: 'all', // ラベルを含めてすべて
            stylers: [{ visibility: 'on' }],
          },
        ],
        { name: 'Styled Map' }
      )

      map.mapTypes.set('styled_map', styledMapType)
      let drawingManager

      map.setMapTypeId('styled_map')

      // MARK: リセットボタン
      setupResetButton(map, () => {
        // ピンや敷地形状をクリア
        clearProperty()
        // ピンや敷地形状を初期表示に戻す
        renderProperty()
        // 呼び出し側の値も初期値に戻す
        if (propertyLat && propertyLng) {
          onChangeLatLng({ lat: propertyLat, lng: propertyLng })
        }
        if (propertyShape) {
          onChangeShape(JSON.stringify(propertyShape))
        }
      })

      // MARK: 3D表示ボタン
      setup3Dbutton(map)

      // MARK: 物件ピンの追加
      renderProperty()

      // 地図をクリック
      google.maps.event.addListener(map, 'click', (event) => {
        // 物件のピンを追加
        putNewProperty(event.latLng, map)
        const lat = propertyPingRef.current.position.lat()
        const lng = propertyPingRef.current.position.lng()
        onChangeLatLng({ lat, lng })
        google.maps.event.addListener(propertyPingRef.current, 'dragend', () => {
          const lat = propertyPingRef.current.position.lat()
          const lng = propertyPingRef.current.position.lng()
          onChangeLatLng({ lat, lng })
          onChangePolygon(true)
        })
      })

      // MARK: 現在地ボタン
      setupLocationButton(map, () => {
        navigator.geolocation.getCurrentPosition((position) => {
          const gpslat = position.coords.latitude
          const gpslng = position.coords.longitude
          const latlng = new google.maps.LatLng(gpslat, gpslng)
          map.setCenter(latlng)

          if (cover_enterprise_pricing_type(current_user)) {
            if (currentLocationMarker) {
              currentLocationMarker.setMap(null)
            }

            const marker = new google.maps.Marker({
              map: map,
              position: new google.maps.LatLng(latlng),
              icon: {
                url: '/flag_icon/current_location.svg',
                scaledSize: new google.maps.Size(30, 60),
              },
              optimized: false,
            })

            setCurrentLocationMarker(marker)
          }
        })
      })

      const csrfToken = ''
      const property_id = 0
      const layerButton = new LayerButton({
        map,
        mode: 'market_datum',
        user: current_user,
        lat: Number(lat),
        lng: Number(lng),
        polygons_api_base_url,
        property_id,
        csrfToken,
      })
      layerButton.emitter.on('setShowInfoTable', setShowInfoTable)
      layerButton.emitter.on('setUsageInfos', setUsageInfos)
      layerButton.emitter.on('setAntifireInfo', setAntifireInfo)
      layerButton.emitter.on('setHeightInfos', setHeightInfos)
      layerButton.emitter.on('setShadeInfos', setShadeInfos)
      layerButton.emitter.on('setYoutoInfo', setYoutoInfo ? setYoutoInfo : (f) => {})
      layerButton.emitter.on('setShowYoutoInfo', setShowYoutoInfo ? setShowYoutoInfo : (f) => {})
      layerButton.emitter.on('setChibanInfo', setChibanInfo ? setChibanInfo : (f) => {})

      google.maps.event.addListener(map, 'idle', () => {
        layerButton.idle()
      })

      // 敷地の面積を表示する
      function showAreaText(polygon, areaValue = '') {
        const bounds = new google.maps.LatLngBounds()
        let area
        let labelColor = '#691a11'
        if (areaValue) {
          if (!polygon) {
            return
          }
          if (areaValue.endsWith('gmap')) {
            areaValue = areaValue.replace('gmap', '㎡')
            labelColor = '#0000ff'
          }
          const polygonShape = JSON.parse(polygon)
          const parsedShape = polygonShape.map((coord) => {
            return {
              lat: Number(coord.lat),
              lng: Number(coord.lng),
            }
          })
          parsedShape.forEach((latlng) => {
            bounds.extend(latlng)
          })
        } else {
          const roundedPath = []
          polygon.getPath().forEach((latlng) => {
            bounds.extend(latlng)

            roundedPath.push({
              lat: Math.round(latlng.lat() * 1000000) / 1000000,
              lng: Math.round(latlng.lng() * 1000000) / 1000000,
            })
          })
          area = google.maps.geometry.spherical.computeArea(roundedPath)
        }
        if (areaText) {
          areaText.setMap()
        }
        areaText = new google.maps.Marker({
          position: bounds.getCenter(),
          map,
          icon: {
            url: '',
            size: new google.maps.Size(1, 1),
          },
          label: {
            text: areaValue || ' ',
            color: labelColor,
            fontFamily: 'sans-serif',
            fontWeight: 'bold',
            fontSize: '14px',
          },
        })
      }
      showAreaTextRef.current = showAreaText

      function renderProperty() {
        if (propertyLat && propertyLng) {
          putNewProperty(new google.maps.LatLng(propertyLat, propertyLng), map)
          google.maps.event.addListener(propertyPingRef.current, 'dragend', () => {
            const lat = propertyPingRef.current.position.lat()
            const lng = propertyPingRef.current.position.lng()
            onChangeLatLng({ lat, lng })
          })
        }
        const shape = propertyShape
        if (shape) {
          const polygon = new google.maps.Polygon({
            paths: shape.map((latlng) => {
              return new google.maps.LatLng(Number(latlng.lat), Number(latlng.lng))
            }),
            draggable: true,
            fillColor: '#55ee55',
            fillOpacity: 0.5,
            strokeWeight: 2,
            clickable: true,
            editable: true,
            zIndex: 1,
          })
          polygon.setMap(map)
          showAreaMarkers(polygon)
          google.maps.event.addListener(polygon, 'mouseup', () => {
            const shape = []
            polygon.getPath().forEach((latlng) => {
              shape.push({ lat: latlng.lat(), lng: latlng.lng() })
            })
            onChangeShape(JSON.stringify(shape))
            if (onChangePolygon) {
              onChangePolygon(true)
            }
            if (onChangeAreaCheck) {
              onChangeAreaCheck(false)
            }
            showAreaText(polygon)
            showAreaMarkers(polygon)
          })
          currentPolygon = polygon
          showAreaText(currentPolygon)
          drawingManager.setDrawingMode(null)
        }
      }

      // polygonの各頂点にピン番号を表示する(nullを与えるとピン番号を隠す)
      function showAreaMarkers(polygon = null) {
        areaMarkers.forEach((marker) => {
          marker.setMap()
        })
        areaMarkers.length = 0
        if (polygon) {
          let i = 1
          polygon.getPath().forEach((latlng) => {
            areaMarkers.push(
              new google.maps.Marker({
                position: latlng,
                map: map,
                label: {
                  text: `${i++}`,
                  color: '#ffffff',
                  fontWeight: 'bold',
                  fontSize: '14',
                },
                icon: {
                  url: '/map_flag.png',
                  anchor: new google.maps.Point(2, 30),
                  labelOrigin: new google.maps.Point(8, 9),
                  scaledSize: new google.maps.Size(15, 30),
                },
                optimized: false,
              })
            )
          })
        }
      }

      // レイヤーボタン群の描画
      layerButton.setupView()

      function putNewProperty(position, map) {
        propertyPingRef.current && propertyPingRef.current.setMap(null)
        const icon = {
          url: '/target_ping.png',
          scaledSize: new google.maps.Size(37, 30),
        }
        propertyPingRef.current = new google.maps.Marker({
          position: position,
          map: map,
          optimized: false,
          draggable: true,
          icon,
          zIndex: 99999,
        })

        propertyPingRef.current.setOptions({ zIndex: 99999 })
        propertyPingRef.current.setIcon(icon)
      }

      function clearProperty() {
        if (currentPolygon) {
          currentPolygon.setMap(null)
          currentPolygon = null
        }
        if (areaText) {
          areaText.setMap(null)
          areaText = null
        }
        showAreaMarkers()
        if (propertyPingRef.current) {
          propertyPingRef.current.setMap(null)
          propertyPingRef.current = null
        }
        if (onChangeShape) {
          onChangeShape('')
        }
        if (onChangeLatLng) {
          onChangeLatLng({ lat: '', lng: '' })
        }
      }
    }

    const containerStyle = React.useMemo(() => {
      return (
        style ?? {
          width: '100%',
          height: '600px',
        }
      )
    }, [style])

    React.useEffect(() => {
      const element = document.getElementById('showInfoTable')
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' })
      }
    }, [showInfoTable])

    React.useEffect(() => {
      const interval = setInterval(() => {
        const pinElement = document.querySelector<HTMLButtonElement>("button[title='描画をやめる']")
        if (pinElement) {
          pinElement.ariaLabel = 'ピンで示す'
          pinElement.title = 'ピンで示す'
        }

        const polygonElement =
          document.querySelector<HTMLButtonElement>("button[title='図形を描画']")
        if (polygonElement) {
          polygonElement.ariaLabel = '敷地を描画'
          polygonElement.title = '敷地を描画'
        }
      }, 1000)
      return () => {
        clearInterval(interval)
      }
    }, [])

    const [siteArea, setSiteArea] = React.useState('')

    React.useEffect(() => {
      setSiteArea(existdAreaValue)
    }, [existdAreaValue])

    return (
      <>
        <GoogleMap mapContainerStyle={containerStyle} options={mapOptions} onLoad={onLoad} />

        {showInfoTable && (
          <>
            <Table className="border-t border-b border-[#3885B0]">
              <div id="showInfoTable">
                <Row label={<Th left={true}>用途地域</Th>}>
                  <Td>{usageInfos.usageArea == '取得中' ? loading : usageInfos.usageArea}</Td>
                </Row>
                <div className="flex flex-wrap">
                  <Row
                    className="w-full md:w-1/2"
                    label={
                      <Th left={true} column={2}>
                        建ぺい率
                      </Th>
                    }
                  >
                    <Td column={2}>
                      {usageInfos.buildingCoverageRatio == '取得中'
                        ? loading
                        : usageInfos.buildingCoverageRatio}
                    </Td>
                  </Row>
                  <Row
                    className="w-full md:w-1/2"
                    label={
                      <Th left={true} column={2}>
                        容積率
                      </Th>
                    }
                  >
                    <Td column={2}>
                      {usageInfos.floorAreaRatio == '取得中' ? loading : usageInfos.floorAreaRatio}
                    </Td>
                  </Row>
                </div>
                <Row label={<Th left={true}>防火地域</Th>}>
                  <Td>{antifireInfo == '取得中' ? loading : antifireInfo}</Td>
                </Row>
                <Row label={<Th left={true}>高度地区</Th>}>
                  <Td>{heightInfos.heightInfo == '取得中' ? loading : heightInfos.heightInfo}</Td>
                </Row>
                <div className="flex flex-wrap">
                  <Row
                    className="w-full md:w-1/2"
                    label={
                      <Th left={true} column={2}>
                        最高高度
                      </Th>
                    }
                  >
                    <Td column={2}>
                      {heightInfos.heightMax == '取得中' ? loading : heightInfos.heightMax}
                    </Td>
                  </Row>
                  <Row
                    className="w-full md:w-1/2"
                    label={
                      <Th left={true} column={2}>
                        最低高度
                      </Th>
                    }
                  >
                    <Td column={2}>
                      {heightInfos.heightMin == '取得中' ? loading : heightInfos.heightMin}
                    </Td>
                  </Row>
                </div>
                <div className="flex flex-wrap">
                  <Row
                    className="w-full md:w-1/2"
                    label={
                      <Th left={true} column={2}>
                        日影範囲5M超
                      </Th>
                    }
                  >
                    <Td column={2}>
                      {heightInfos.heightMax == '取得中' ? loading : shadeInfos.shade5m}
                    </Td>
                  </Row>
                  <Row
                    className="w-full md:w-1/2"
                    label={
                      <Th left={true} column={2}>
                        日影範囲10M超
                      </Th>
                    }
                  >
                    <Td column={2}>
                      {heightInfos.heightMin == '取得中' ? loading : shadeInfos.shade10m}
                    </Td>
                  </Row>
                </div>
                <Row label={<Th left={true}>日影測定水平面</Th>}>
                  <Td>{heightInfos.heightInfo == '取得中' ? loading : shadeInfos.shadeHeight}</Td>
                </Row>
              </div>
            </Table>
            <div className="block text-xs px-4 pt-1 pb-2 whitespace-normal text-primary font-small">
              ※ 情報データが提供されていない地域の場合は、表内に"N/A"と表示されます。
            </div>
          </>
        )}
      </>
    )
  }
)
