export const tagIcons = [
    "/flag_icon/01.png",
    "/flag_icon/02.png",
    "/flag_icon/03.png",
    "/flag_icon/04.png",
    "/flag_icon/05.png",
    "/flag_icon/06.png",
    "/flag_icon/07.png",
    "/flag_icon/08.png",
    "/flag_icon/09.png",
    "/flag_icon/10.png",
    "/flag_icon/11.png",
    "/flag_icon/12.png",
  ]
  