export const tagColorCodes = [
  { bg: 'bg-[#C72424]', border: 'border-[#C72424]' },
  { bg: 'bg-[#D1501A]', border: 'border-[#D1501A]' },
  { bg: 'bg-[#EDAD0C]', border: 'border-[#EDAD0C]' },
  { bg: 'bg-[#A4C521]', border: 'border-[#A4C521]' },
  { bg: 'bg-[#37AC0D]', border: 'border-[#37AC0D]' },
  { bg: 'bg-[#349E8C]', border: 'border-[#349E8C]' },
  { bg: 'bg-[#2C7FB1]', border: 'border-[#2C7FB1]' },
  { bg: 'bg-[#5D639E]', border: 'border-[#5D639E]' },
  { bg: 'bg-[#744199]', border: 'border-[#744199]' },
  { bg: 'bg-[#A52175]', border: 'border-[#A52175]' },
  { bg: 'bg-[#BF1F56]', border: 'border-[#BF1F56]' },
  { bg: 'bg-[#A18082]', border: 'border-[#A18082]' },
]
