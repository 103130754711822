import * as React from 'react';
import { Modal } from '@/components/Modal'
import ClearIcon from '@material-ui/icons/Clear'

type OcrModalProps = {
  open: boolean
  onClose: () => void
}
export const OcrModal: React.FC<OcrModalProps> = ({ open, onClose }) => {
  return (
    <Modal open={open} onClose={onClose}>
      <div>aaaaaaaaaa</div>
      {imagePreview && (
        <div className="relative">
          <ClearIcon
            className="absolute right-0 top-[-10px] bg-white rounded-full cursor-pointer"
            onClick={onClear}
          />
          <embed
            className="mt-4"
            alt="画像プレビュー"
            src={imagePreview}
            style={{ width: '100%', height: 'auto' }}
          />
        </div>
      )}
      {pdfPreview && (
        <div className="relative">
          <ClearIcon
            className="absolute right-0 top-[-10px] bg-white rounded-full cursor-pointer"
            onClick={onClear}
          />
          <embed
            className="mt-4 border-b border-b-[3px] border-gray-700"
            alt="PDFプレビュー"
            src={pdfPreview}
            style={{ width: '100%', aspectRatio: '1/1.5' }}
          />
        </div>
      )}
    </Modal>
  )
}