import * as React from 'react'
import { Table } from '@/components/Table'
import type { Property } from '@/types/property'
import type { ResultContents } from '@/types/property'
import ContentPasteSearchIcon from '@mui/icons-material/ContentPasteSearch'
import { AnchorButton } from '@/components/Button'
import UploadFileIcon from '@mui/icons-material/UploadFile'
import CloudDownloadIcon from '@mui/icons-material/CloudDownload'
import { Loading } from '@/components/Loading'
import { unit_pct, unit_m2 } from '../../VolumeCheckRequests/volumeUnitFormat'
import DeleteOutlined from '@material-ui/icons/DeleteOutlined'
import type { User } from '@/types/user'
import {Pagination} from "@/components/Pagination";
import {Pagy} from "@/types/pagy";

const PropetyVolumes: React.FC<{
  property: Property
  current_user: User
  result_contents: ResultContents[]
  pagy: Pagy
  is_enable_edit_detail: boolean
}> = ({ property, current_user, result_contents, pagy, is_enable_edit_detail }) => {
  const titles = [
    '依頼日時',
    'プラン名',
    '作成者',
    '敷地面積',
    '有効敷地面積',
    '建物用途',
    '住戸数',
    '建物規模',
    '建物構造',
    '許容容積率',
    '計画容積率',
    '施工床面積',
    '総専有面積',
  ]
  const loadings = []
  for (let i = 0; i < 10; i++) {
    loadings.push(
      <td className="py-2 px-4 text-sm">
        <Loading />
      </td>
    )
  }

  function StatusCheckTimer({ token }) {
    const [remainingTime, setRemainingTime] = React.useState('現在ボリューム計算待ちです、しばらくお待ちください。')

    React.useEffect(() => {
      const get_request_status = async () => {
        const response = await fetch(`/api/volume_check_requests/check_status?token=${token}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        })
        const data = await response.json()

        if (data.status === 0) {
          setRemainingTime(`現在ボリューム計算待ちです、しばらくお待ちください。`)
        } else if (data.status === 1) {
          setRemainingTime(`現在ボリューム計算中ですので少々お待ちください。`)
        } else if (data.status === 2) {
          clearInterval(intervalId)
          window.location.reload()
        } else {
          console.log('Unknown status')
        }
      }

      const intervalId = setInterval(get_request_status, 10000)
      return () => clearInterval(intervalId)
    }, [token])

    return <span className="text-gray-400">　{remainingTime}</span>
  }

  function disableClick(volume: ResultContents): boolean {
    return volume.statusMessage == 0 || volume.statusMessage == 1 || volume.area == 'ERR'
  }

  if (!is_enable_edit_detail) {
    return (
      <div className="mt-10 mb-10">
        <h3 className="border-b-[1px] border-primary mb-2">
          <span className="inline-flex bg-primary text-white text-sm py-2 px-8">Volume Check</span>
        </h3>
        <div
          className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative"
          role="alert"
        >
          <strong className="font-bold">権限範囲外のため、ボリュームチェックできません。</strong>
        </div>
      </div>
    )
  }

  return (
    <div className="mt-10">
      <h3 className="border-b-[1px] border-primary mb-2">
        <span className="inline-flex bg-primary text-white text-sm py-2 px-8">Volume Check</span>
      </h3>
      <div className="flex flex-wrap items-end justify-between m-1">
        <div className="flex flex-wrap items-center gap-1 whitespace-nowrap">
          <span className="text-primary-font text-xl font-medium">
            {pagy.count.toLocaleString()}
          </span>
          <span className="text-gray-800 text-sm mt-0.5">
            件中 {pagy.from}～{pagy.to}件 表示
          </span>
          <span className="ml-2">
            <Pagination
              pagy={pagy}
              navigate={(page) => {
                const path = `${window.location.pathname.match(/^\/\w*\/\w*/)[0]}`
                window.location.href = `${path}?tab=volume_check&page=${page}`
              }}
            />
          </span>
        </div>

        <div className="text-right mb-2">
          <AnchorButton
            prefix={<ContentPasteSearchIcon fontSize="small"/>}
            outline
            size="small"
            variant="primary"
            href={`/properties/${property.hashid}/volume_check_requests/new`}
          >
            Volume Check
          </AnchorButton>
        </div>
      </div>

      <Table
        className={
          window.innerHeight > 920
            ? 'h-[760px] md:h-[760px]'
            : 'h-[calc(100vh_-_145px)] md:h-[calc(100vh_-_155px)]'
        }
        borderLine={false}
      >
        <thead className="bg-gray-150 text-sm sticky top-0">
          <tr className="whitespace-nowrap">
            {titles.map((title, index) => (
              <th key={index} scope="col" className="py-2 px-4 font-medium">
                {title}
              </th>
            ))}
            {current_user.role === 'system_admin' && (
              <>
                <th scope="col" className="py-2 px-4 font-medium">
                  リクエスト
                </th>
                <th scope="col" className="py-2 px-4 font-medium">
                  レスポンス
                </th>
                <th scope="col" className="py-2 px-4 font-medium">
                  モデルファイル
                </th>
                <th scope="col" className="py-2 px-4 font-medium">
                  フォルダ名
                </th>
              </>
            )}
            <th scope="col" className="py-2 px-4 font-medium">
              削除
            </th>
          </tr>
        </thead>
        <tbody className="whitespace-nowrap">
          {result_contents.map((volume, index) => (
            <tr
              key={index}
              className={`${
                index % 2 ? 'bg-gray-light' : 'bg-white'
              } hover:bg-targetLine select-none ${!disableClick(volume) && 'cursor-pointer'}`}
              onClick={(e) => {
                const element = e.target as HTMLElement
                if (element.tagName !== 'TD' || disableClick(volume)) return
                location.href = `/properties/${property.hashid}/volume_check_requests/${volume.id}?tab=volume_check&path_info=${volume.path_info}`
              }}
            >
              {volume.statusMessage == 0 || volume.statusMessage == 1 ? (
                <>
                  <td className="py-2 px-4 text-sm">{volume.created_at}</td>
                  <td className="py-2 px-4 text-sm">{volume.plan_name}</td>
                  <td className="py-2 px-4 text-sm">{volume.creator}</td>
                  <td className="py-2 px-4 text-sm" colSpan={10}>
                    <div className="flex items-center">
                      <Loading />
                      <span className="ml-2">
                        <StatusCheckTimer token={volume.token} />
                      </span>
                    </div>
                  </td>
                  <td className="py-2 px-4 text-sm">
                    <a
                      className="inline-block w-[30px] hover:text-deleteHover"
                      target="_blank"
                      rel="nofollow noopener noreferrer"
                      href={`/properties/${property.hashid}/volume_check_requests/${volume.id}/request_json`}
                    >
                      <UploadFileIcon fontSize="small" /> req
                    </a>
                  </td>
                  <td></td>
                  <td></td>
                  <td className="py-2 px-4 text-sm">{volume.token}</td>
                  <td className="py-2 px-4 text-right">
                    <a
                      className="inline-block w-[30px] hover:text-deleteHover"
                      data-confirm={`削除しますか？`}
                      rel="nofollow"
                      data-method="delete"
                      href={`/properties/${property.hashid}/volume_check_requests/${volume.id}`}
                    >
                      <DeleteOutlined fontSize="small" />
                    </a>
                  </td>
                </>
              ) : (
                <>
                  <td className="py-2 px-4 text-sm">{volume.created_at}</td>
                  <td className="py-2 px-4 text-sm">{volume.plan_name}</td>
                  <td className="py-2 px-4 text-sm">{volume.creator}</td>

                  {
                    volume.area == 'ERR' ? (
                      <td className="py-2 px-4 text-sm" colSpan={10}>
                        <div className="flex items-center">
                          <span className="ml-2">
                            {volume.plan_name.toString().slice(-3, -1)}プランでは表示可能なプランを生成出来ませんでした
                          </span>
                        </div>
                      </td>
                    ) : (
                      <>
                        <td className="py-2 px-4 text-sm">
                          {unit_m2(volume.area)}
                        </td>

                        <td className="py-2 px-4 text-sm">
                          {unit_m2(volume.effective_site_area)}
                        </td>

                        <td className="py-2 px-4 text-sm">{volume.buiding_usage}</td>
                        <td className="py-2 px-4 text-sm">{volume.number_of_units}</td>
                        <td className="py-2 px-4 text-sm">{volume.building_floors}</td>
                        <td className="py-2 px-4 text-sm">{volume.building_structure}</td>
                        <td className="py-2 px-4 text-sm">
                          {unit_pct(volume.allowable_floor_area_ratio)}
                        </td>
                        <td className="py-2 px-4 text-sm">
                          {unit_pct(volume.floor_area_ratio)}
                        </td>
                        <td className="py-2 px-4 text-sm">
                          {unit_m2(volume.total_construction_floor_area)}
                        </td>
                        <td className="py-2 px-4 text-sm">
                          {unit_m2(volume.total_exclusive_area)}
                        </td>
                      </>
                    )
                  }

                  {current_user.role === 'system_admin' && (
                    <>
                      <td className="py-2 px-4 text-sm">
                        <a
                          className="inline-block w-[30px] hover:text-deleteHover"
                          target="_blank"
                          rel="nofollow noopener noreferrer"
                          href={`/properties/${property.hashid}/volume_check_requests/${volume.id}/request_json`}
                        >
                          <UploadFileIcon fontSize="small" /> req
                        </a>
                      </td>
                      <td className="py-2 px-4 text-sm">
                        <a
                          className="inline-block w-[30px] hover:text-deleteHover"
                          target="_blank"
                          rel="nofollow noopener noreferrer"
                          href={`/properties/${property.hashid}/volume_check_requests/${volume.id}/response_json`}
                        >
                          <CloudDownloadIcon fontSize="small" /> res（{volume.success_rate}）
                        </a>
                      </td>
                      <td className="py-2 px-4 text-sm">
                        <a
                          className="inline-block w-[30px] hover:text-deleteHover"
                          target="_blank"
                          rel="nofollow noopener noreferrer"
                          href={`/properties/${property.hashid}/volume_check_requests/${volume.id}/result_file?filename=block.dxf&path_info=${volume.path_info}`}
                        >
                          <CloudDownloadIcon fontSize="small" /> dxf file
                        </a>
                      </td>
                      <td className="py-2 px-4 text-sm">{volume.token}</td>
                    </>
                  )}
                  <td className="py-2 px-4 text-right">
                    <a
                      className="inline-block w-[30px] hover:text-deleteHover"
                      data-confirm={`削除しますか？`}
                      rel="nofollow"
                      data-method="delete"
                      href={`/properties/${property.hashid}/volume_check_requests/${volume.id}`}
                    >
                      <DeleteOutlined fontSize="small" />
                    </a>
                  </td>
                </>
              )}
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  )
}

export default PropetyVolumes
