import * as React from 'react'
import Logo from 'land_logo.png'

type Props = {
  authenticity_token: string
  confirmation_token: string
  error_messages: string[]
}

const Confirmation: React.FC<Props> = ({
  authenticity_token,
  confirmation_token,
  error_messages,
}) => {
  const formRef = React.useRef<HTMLFormElement>(null)
  const [disabled, setDisabled] = React.useState(false)

  return (
    <div className="h-screen min-h-login-form relative">
      <div className={styles.bg}></div>
      <div className="h-full w-full flex flex-col">
        <div className="h-full flex items-center">
          <div className="mx-auto p-4 flex flex-col max-w-login-form relative z-10 mb-40">
            <form
              id="confirm_user"
              action="/users/confirmations/password"
              acceptCharset="UTF-8"
              method="post"
              ref={formRef}
            >
              <input type="hidden" name="authenticity_token" value={authenticity_token} />
              <input type="hidden" name="confirmation_token" value={confirmation_token} />
              <img src={Logo} alt="land" className="w-36 m-10 mx-auto" />
              <div className="relative mb-8">
                <input
                  type="password"
                  id="password"
                  name="user[password]"
                  placeholder="パスワード"
                  className={styles.input + styles.inputPassword}
                />
                <input
                  type="password"
                  id="password_confirmation"
                  name="user[password_confirmation]"
                  placeholder="パスワード（確認用）"
                  className={styles.input + styles.inputPasswordConfirmation}
                />
                {error_messages.length > 0 && (
                  <ul className="mt-4 p-2 bg-white rounded text-sm text-red">
                    {error_messages.map((message, i) => (
                      <li key={i} className="mb-2 break-all">
                        {message}
                      </li>
                    ))}
                  </ul>
                )}
                <div className="text-center mt-4">
                  <p className="font-semibold font-medium">ユーザー登録を完了するために</p>
                  <p className="font-semibold font-medium">パスワードを設定してください</p>
                </div>
              </div>
              <button
                className={styles.button}
                disabled={disabled}
                onClick={() => {
                  setDisabled(true)
                  formRef.current.submit()
                }}
              >
                設定
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}

const styles = {
  bg: `
    absolute
    inset-0
    bg-center
    bg-no-repeat
    bg-cover
    bg-[url('~signin_bg.jpg')]
  `,
  input: `
    w-full
    bg-white
    rounded
    border
    border-gray-200
    outline-none
    text-gray-700
    py-3.5
    px-5
    leading-normal
    text-sm
  `,
  inputPassword: `
    rounded-b-none
  `,
  inputPasswordConfirmation: `
    rounded-t-none
  `,
  button: `
    w-full
    text-white
    bg-primary
    text-base
    leading-none
    border-0
    py-4
    px-6
    focus:outline-none
    hover:opacity-80
    rounded text-x
    duration-200
    transition-opacity
    disabled:opacity-70
    disabled:cursor-not-allowed
  `,
  footer: `
    w-full
    z-10
    border-t
    border-white
    text-center
    py-3
    text-white
    text-sm
  `,
}

export default Confirmation
