import { Accordion } from '@/components/Accordion'
import { UserContext } from '@/components/Layout/Base'
import { MenuAccordion } from '@/components/MenuAccordion'
import { MarketDatumModal } from '@/components/Page/MarketData/MarketDatumModal'
import { mobileMenusPricingType, subMenuOptionsPricingType } from '@/models/menu'
import HomeWorkOutlinedIcon from '@material-ui/icons/HomeWorkOutlined'
import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined'
import * as React from 'react'

interface Props {
  className?: string
  isOpen?: boolean
  city: string
}

export const MobileMenu: React.FC<Props> = ({ className, isOpen = false, city }) => {
  const { user } = React.useContext(UserContext)
  const [moblieMenus, setMoblieMenus] = React.useState([])
  const [modalOpen, setModalOpen] = React.useState<boolean>(false)
  const [modalLayoutOpen, setModalLayoutOpen] = React.useState<boolean>(false)
  const [modalReportOpen, setModalReportOpen] = React.useState<boolean>(false)
  const [pathReport, setPathReport] = React.useState<string>('')
  const [queryReport, setQueryReport] = React.useState<string>('')
  const tempArray = ["東京都千代田区","東京都中央区","東京都港区","東京都新宿区","東京都文京区","東京都台東区","東京都墨田区","東京都江東区","東京都品川区","東京都目黒区","東京都大田区","東京都世田谷区","東京都渋谷区","東京都中野区","東京都杉並区","東京都豊島区","東京都北区","東京都荒川区","東京都板橋区","東京都練馬区","東京都足立区","東京都葛飾区","東京都江戸川区"]

  const param = window.location.search
  const tab = new URLSearchParams(param).get('tab')
  const property_id = new URLSearchParams(param).get('property_id')

  React.useEffect(() => {
    const { properties } = subMenuOptionsPricingType(user.company.pricing_type, user.company.is_volume_check, user.is_volume_check, city)

    let mobileMenues = [...mobileMenusPricingType(user.company.pricing_type, user.company, user.company.is_volume_check, user.is_volume_check, city)]
    const matchPropertyPath =
      window.location.pathname !== '/properties/new' &&
      window.location.pathname.match(/^\/properties\/.+/)

    if (user.role === 'general') {
      mobileMenues = mobileMenues.filter((menu) => menu.title !== '分析情報')
    }

    if (user.role === 'leader' || user.role === 'general') {
      mobileMenues = mobileMenues.filter((menu) => menu.title !== 'CSVインポート')
      mobileMenues = mobileMenues.map((menu) => {
        if (menu.title === '設定') {
          return {
            title: '設定',
            icon: <SettingsOutlinedIcon fontSize="small" />,
            submenu: [{ title: 'ユーザー設定', path: '/users' }],
          }
        } else {
          return menu
        }
      })
    }

    if (user.company.touki_enabled !== true && user.role !== 'system_admin') {
      mobileMenues = mobileMenues.map((menu) => {
        if (menu.title === '設定') {
          return {
            title: '設定',
            icon: <SettingsOutlinedIcon fontSize="small" />,
            submenu: menu.submenu.filter((submenu) => submenu.title !== '登記情報利用明細'),
          }
        }
        return menu
      })
    }

    if (user.company.ocr_enabled !== true && user.role !== 'system_admin') {
      mobileMenues = mobileMenues.map((menu) => {
        if (menu.title === '設定') {
          return {
            title: '設定',
            icon: <SettingsOutlinedIcon fontSize="small" />,
            submenu: menu.submenu.filter((submenu) => submenu.title !== 'OCR利用明細'),
          }
        }
        return menu
      })
    }

    // 物件詳細の場合はメニュー追加
    if (matchPropertyPath || tab === 'proposals') {
      const propertyId = property_id
        ? property_id
        : window.location.pathname.match(/\/\w+/g)[1].replace('/', '')
      const propertyDetailMenu = {
        title: '物件管理',
        icon: <HomeWorkOutlinedIcon fontSize="small" />,
        submenu: [{ title: '物件登録', path: '/properties' }].concat(
          properties.menus.map((menu) => {
            return {
              title: menu.title,
              path: `/properties/${propertyId}${menu.path}`,
              pathname: `/properties/${propertyId}`,
              query: menu.path,
            }
          })
        ),
      }

      if (user.company.pricing_type === "owner") {
        if (!tempArray.includes(city)) {
          propertyDetailMenu.submenu = propertyDetailMenu.submenu.filter(
              (menu) => menu.title !== 'Volume Check'
          )
        }
      } else if (user.company.pricing_type === "enterprise") {
        if (user.is_volume_check) {
          if (!tempArray.includes(city)) {
            propertyDetailMenu.submenu = propertyDetailMenu.submenu.filter(
                (menu) => menu.title !== 'Volume Check'
            )
          }
        } else {
          propertyDetailMenu.submenu = propertyDetailMenu.submenu.filter(
              (menu) => menu.title !== 'Volume Check'
          )
        }
      } else {
        propertyDetailMenu.submenu = propertyDetailMenu.submenu.filter(
            (menu) => menu.title !== 'Volume Check'
        )
      }

      if (user.company.touki_enabled !== true && user.role !== 'system_admin') {
        propertyDetailMenu.submenu = propertyDetailMenu.submenu.filter(
          (menu) => menu.title !== '登記情報'
        )
      }

      mobileMenues.splice(0, 1, propertyDetailMenu)
    }

    setMoblieMenus([...mobileMenues])
  }, [setMoblieMenus])

  function subMenuSet(subMenu, index) {
    if (subMenu.title === '物件詳細') {
      return (
        <li
          key={index}
          className={`py-3 pl-[68px] hover:bg-primary-light
        ${
          (window.location.pathname +
            (window.location.search ? window.location.search.match(/^\?[\w=]+/)[0] : '') ===
            subMenu.path ||
            tab === 'property_details') &&
          'bg-primary-light'
        }`}
          onClick={() => {
            window.location.href = subMenu.path
          }}
        >
          {subMenu.title}
        </li>
      )
    } else if (subMenu.title === '入手情報') {
      return (
        <li
          key={index}
          className={`py-3 pl-[68px] hover:bg-primary-light
        ${
          (window.location.pathname +
            (window.location.search ? window.location.search.match(/^\?[\w=]+/)[0] : '') ===
            subMenu.path ||
            tab === 'proposals') &&
          'bg-primary-light'
        }`}
          onClick={() => {
            window.location.href = subMenu.path
          }}
        >
          {subMenu.title}
        </li>
      )
    } else {
      return (
        <li
          key={index}
          className={`py-3 pl-[68px] hover:bg-primary-light
        ${
          window.location.pathname +
            (window.location.search ? window.location.search.match(/^\?[\w=]+/)[0] : '') ===
            subMenu.path && 'bg-primary-light'
        }`}
          onClick={() => {
            if (subMenu.title === '帳票フォーム設定') {
              setModalLayoutOpen(true)
            } else if (subMenu.title === '帳票作成') {
              setPathReport(subMenu.pathname)
              setQueryReport(subMenu.query.substring(1)) // ?tab=market_datum_report ⇒ tab=market_datum_report
              setModalReportOpen(true)
            } else {
              window.location.href = subMenu.path
            }
          }}
        >
          {subMenu.title}
        </li>
      )
    }
  }

  return (
    <div
      className={`${
        isOpen ? 'right-0' : '-right-[270px]'
      } fixed md:hidden bg-white w-[270px] transition-[right] z-20`}
    >
      <div className="bg-gradient-to-r from-primary to-white py-[4px]"></div>
      <ul className={`${className} text-[15px] pb-32`}>
        {moblieMenus.map((menu, index) =>
          'submenu' in menu ? (
            <li className="items-center cursor-pointer" key={index}>
              <MenuAccordion icon={menu.icon} menu={menu.title}>
                <ul className="bg-white text-primary-font text-sm">
                  {menu.submenu.map((subMenu, index) => subMenuSet(subMenu, index))}
                </ul>
              </MenuAccordion>
            </li>
          ) : (
            <li
              key={index}
              className={`py-[15px] items-center cursor-pointer hover:bg-primary-light
              ${
                window.location.pathname.match(/^\/\w*/)[0] === menu.path &&
                tab !== 'proposals' &&
                'bg-primary-light'
              }`}
              onClick={() => {
                if (menu.title === '周辺事例登録') {
                  setModalOpen(true)
                } else {
                  window.location.href = menu.path
                }
              }}
            >
              <div className="flex items-center text-primary-font">
                <div className="pl-5">{menu.icon}</div>
                <div className="pl-2.5">{menu.title}</div>
              </div>
            </li>
          )
        )}

        <li className="border-t border-b border-gray-150 cursor-pointer">
          <Accordion>
            <ul className="bg-white text-primary-font font-medium text-sm">
              <li
                className="py-3 pl-[68px] hover:bg-primary-light"
                onClick={() => {
                  window.location.href = `/users/${user.id}/edit`
                }}
              >
                アカウント設定
              </li>
              {user.pricing_type !== 'owner' && (
                <li
                  className="py-3 pl-[68px] hover:bg-primary-light"
                  onClick={() => {
                    window.location.href = `/file_capacities`
                  }}
                >
                  ファイル容量確認
                </li>
              )}
              <li
                className="py-3 pl-[68px] hover:bg-primary-light"
                onClick={() => {
                  window.location.href = `/static_pages/privacy`
                }}
              >
                プライバシーポリシー
              </li>
              <li
                className="py-3 pl-[68px] hover:bg-primary-light"
                onClick={() => {
                  window.location.href = `/static_pages/terms_of_service`
                }}
              >
                利用規約
              </li>
              <li
                className="py-3 pl-[68px] hover:bg-primary-light"
                onClick={() => {
                  window.location.href = `/static_pages/user_manual`
                }}
              >
                利用マニュアル
              </li>
              <li
                className="py-3 pl-[68px] hover:bg-primary-light"
                onClick={() => {
                  window.location.href = `https://zised.ai/`
                }}
              >
                運営会社
              </li>
              <li className=" hover:bg-primary-light" data-method="delete">
                <a
                  data-method="delete"
                  href="/users/sign_out"
                  className="py-3 pl-[68px] w-full inline-block"
                >
                  ログアウト
                </a>
              </li>
            </ul>
          </Accordion>
        </li>
      </ul>
      <div className="bg-gradient-to-r from-primary to-white py-[4px]"></div>
      <MarketDatumModal open={modalOpen} onClose={() => setModalOpen(false)} path={path} />
      <MarketDatumModal
        open={modalReportOpen}
        onClose={() => setModalReportOpen(false)}
        path={pathReport}
        title="帳票"
      />
      <MarketDatumModal
        open={modalLayoutOpen}
        onClose={() => setModalLayoutOpen(false)}
        path={pathLayout}
        query={queryReport}
        title="帳票"
      />
    </div>
  )
}

const path = '/market_data/new'
const pathLayout = '/market_datum_report_layouts/new'
