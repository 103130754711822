import * as React from 'react'
import type { Props } from './types'

const ArrowClockwiseIcon: React.FC<Props> = ({ size = 16, fill = '343A40', className }: Props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill={fill}
      className={className}
      viewBox="0 0 16 16"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 3.00187C5.23858 3.00187 3 5.24045 3 8.00187C3 10.7633 5.23858 13.0019 8 13.0019C10.7614 13.0019 13 10.7633 13 8.00187C13 7.25644 12.8372 6.5504 12.5457 5.91621C12.4304 5.66531 12.5403 5.36841 12.7912 5.25308C13.0421 5.13775 13.339 5.24766 13.4543 5.49857C13.8048 6.2611 14 7.10938 14 8.00187C14 11.3156 11.3137 14.0019 8 14.0019C4.68629 14.0019 2 11.3156 2 8.00187C2 4.68817 4.68629 2.00187 8 2.00187V3.00187Z"
        fill={fill}
      />
      <path
        d="M8 4.46811V0.535634C8 0.323675 8.24721 0.207886 8.41005 0.343579L10.7695 2.30982C10.8895 2.40977 10.8895 2.59398 10.7695 2.69393L8.41005 4.66017C8.24721 4.79586 8 4.68007 8 4.46811Z"
        fill={fill}
      />
    </svg>
  )
}

export default ArrowClockwiseIcon
