import * as React from 'react'
import { Grid } from 'react-loader-spinner'

export const Loading = ({ height = 25, width = 25 }) => {
  return (
    <Grid
      height={height}
      width={width}
      color="#3885B0"
      ariaLabel="grid-loading"
      radius="12.5"
      wrapperStyle={{}}
      wrapperClass=""
      visible={true}
    />
  )
}
