import * as React from 'react'
import { CommentForm } from '@/components/CommentForm'
import { Dialog, Transition } from '@headlessui/react'
import ClearIcon from '@mui/icons-material/Clear'
import type { Property, Memo } from '@/types/property'

type Props = {
  memos: Memo[]
  property: Property
  is_open: boolean
  on_close: () => void
  set_list_memos: (memos: Memo[]) => void
}

export const AddMemoModal: React.FC<Props> = ({ memos = [], property, is_open, on_close, set_list_memos }) => {
  const modalTitle = `${property.prefecture} ${property.city} ${property.town} ${property.chome}`

  return (
    <>
      <Transition appear show={is_open} as={React.Fragment}>
        <Dialog className="relative z-10" onClose={on_close}>
          <Transition.Child
            as={React.Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center text-center">
              <Transition.Child
                as={React.Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-screen md:max-w-[625px] transform overflow-hidden rounded-2xl bg-white text-left align-middle shadow-xl transition-all">
                  <Dialog.Title className="text-base font-bold p-4 leading-6 text-gray-700">
                    {modalTitle}
                    <ClearIcon className="float-right cursor-pointer" onClick={on_close} />
                  </Dialog.Title>
                  <div className="border-t border-gray-150"></div>
                  <CommentForm
                    memosData={memos}
                    property={property}
                    setListMemos={set_list_memos}
                    commentFormType="modal"
                  />
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  )
}

export default AddMemoModal
