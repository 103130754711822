import * as React from "react";
import {
  useCallback,
  useEffect,
  useRef,
  useState
} from "react";
import { Vector2 } from "three";
import keycode from "keycode";

export type CanvasTransformProps = {
  element: HTMLElement | null;
  zoomSpeed: number;
  minScale: number;
  maxScale: number;
  initial?: {
    position?: Vector2;
    scale?: number;
  };
  onDragStart?: (e: MouseEvent) => void;
  onDragEnd?: (e: MouseEvent) => void;
};

export type CanvasTransform = {
  position: Vector2;
  scale: number;
};

export const transformDOM2Canvas = (
  position: {
    x: number;
    y: number;
  },
  transform: CanvasTransform
): {
  x: number;
  y: number;
} => {
  return {
    x: (position.x - transform.position.x) / transform.scale,
    y: (position.y - transform.position.y) / transform.scale,
  };
};

const useCanvasTransform = ({
  element,
  zoomSpeed,
  minScale,
  maxScale,
  initial,
  onDragStart,
  onDragEnd,
}: CanvasTransformProps) => {
  const [transform, setTransform] = useState<CanvasTransform>({
    position: new Vector2(),
    scale: 1,
  });
  const transformRef = useRef(transform);
  transformRef.current = transform;

  const [key, setKey] = useState<string | null>(null);
  const [dragging, setDragging] = useState(false);
  const [start, setStart] = useState({
    transform: {
      position: new Vector2(),
      scale: 1,
    },
    pointer: new Vector2(),
  });

  useEffect(() => {
    const { position, scale } = initial ?? {};
    const { current } = transformRef;
    if (current !== null) {
      const tr = {
        position: position ?? current.position,
        scale: scale ?? current.scale,
      };
      setTransform(tr);
    }
  }, [initial]);

  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      setKey(keycode(e));
    };
    const handleKeyUp = () => {
      setKey(null);
    };
    window.addEventListener("keydown", handleKeyDown);
    window.addEventListener("keyup", handleKeyUp);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
      window.removeEventListener("keyup", handleKeyUp);
    };
  }, []);

  useEffect(() => {
    const handleMouseDown = (e: MouseEvent) => {
      const draggable = key === "space" || e.button === 1;
      if (draggable) {
        setStart({
          transform,
          pointer: new Vector2(e.clientX, e.clientY),
        });
        setDragging(draggable);
        if (onDragStart !== undefined) {
          onDragStart(e);
        }
      }
    };
    element?.addEventListener("mousedown", handleMouseDown, {
      passive: false,
    });
    return () => {
      element?.removeEventListener("mousedown", handleMouseDown);
    };
  }, [element, key, transform, dragging, onDragStart]);

  useEffect(() => {
    if (dragging) {
      const handleMouseMove = (e: MouseEvent) => {
        const current = new Vector2(e.clientX, e.clientY);
        const delta = current.clone().sub(start.pointer);
        const position = start.transform.position.clone().add(delta);
        setTransform({
          ...start.transform,
          position,
        });

        e.preventDefault();
        e.stopPropagation();
      };
      const handleClick = (e: MouseEvent) => {
        setDragging(false);
        if (onDragEnd !== undefined) {
          onDragEnd(e);
        }
      };
      element?.addEventListener("mousemove", handleMouseMove, {
        passive: false,
      });
      element?.addEventListener("click", handleClick, {
        passive: false,
        once: true,
      });
      return () => {
        element?.removeEventListener("mousemove", handleMouseMove);
        element?.removeEventListener("click", handleClick);
      };
    }
    return () => { };
  }, [element, start, dragging, onDragEnd]);

  const zoom = useCallback(
    (zoomDelta: number, x: number, y: number) => {
      const ns = transform.scale * (1 + zoomDelta);
      if (ns < minScale || maxScale < ns) {
        return;
      }

      const a = new Vector2(); // local position of (x, y)
      a.x = (x - transform.position.x) / transform.scale;
      a.y = (y - transform.position.y) / transform.scale;
      const p1 = new Vector2(); // new position
      p1.x = a.x * transform.scale + transform.position.x - a.x * ns;
      p1.y = a.y * transform.scale + transform.position.y - a.y * ns;

      setTransform({
        ...transform,
        position: p1,
        scale: ns,
      });
    },
    [transform, minScale, maxScale]
  );

  useEffect(() => {
    const handleWheel = (e: WheelEvent) => {
      const r = element?.getBoundingClientRect();
      if (r !== undefined) {
        const x = e.clientX - r.x;
        const y = e.clientY - r.y;
        const delta = -e.deltaY * zoomSpeed;
        zoom(delta, x, y);
      }
      e.preventDefault();
    };
    element?.addEventListener("wheel", handleWheel, {
      passive: false,
    });
    return () => {
      element?.removeEventListener("wheel", handleWheel);
    };
  }, [element, zoom, zoomSpeed]);

  return { transform, dragging };
};

export { useCanvasTransform };
