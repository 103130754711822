/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-call */
import * as React from 'react'

import Chart from 'chart.js'
import 'chartjs-plugin-colorschemes'
import 'chartjs-plugin-datalabels'

type Props = {
  year_from: number
  month_from: number
  year_to: number
  month_to: number
  source_department_id: number
  source_user_id: number
  target_period_type: string
  property_type: string
  business_type_id: number
}

const AnalysesPurchaceAppliedContractChart: React.FC<Props> = ({
  year_from,
  month_from,
  year_to,
  month_to,
  source_department_id,
  source_user_id,
  target_period_type,
  property_type,
  business_type_id,
}) => {
  React.useEffect(() => {
    function buildQuery(type) {

      const query = new URLSearchParams(window.location.search)

      const params: Record<string, string> = {
        format: 'json',
        type: type,
        year_from: year_from.toString(),
        month_from: month_from.toString(),
        year_to: year_to.toString(),
        month_to: month_to.toString(),
        source_department_id: source_department_id?.toString() ?? '',
        source_user_id: source_user_id?.toString() ?? '',
        target_period_type: target_period_type,
        property_type: property_type ?? '',
        business_type_id: business_type_id ? business_type_id.toString() : '',
      }

      for (const [key, value] of Object.entries(params)) {
        query.set(key, value)
      }

      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
      return `/analyses?${query}`
    }

    async function init() {
      const res = await fetch(buildQuery('purchace_applied_contract'))
      const json = await res.json()

      const chartDataMonthly = json.body

      const labels = Object.keys(chartDataMonthly[0].data).map((month) => {
        return month.substr(0, 4) + '年' + month.substr(4, 2) + '月'
      })

      const chartjsPluginTotalLabels = {
        /**
         * 各項目の合計を取得
         */
        calcSums: function (datasets) {
          const sums = []
          datasets.forEach(function (dataset) {
            /**
            // 非表示の項目は処理しない
            if (dataset._meta[0].hidden) {
              return;
            }
            */
            dataset.data.forEach(function (value, i) {
              if (typeof sums[i] === 'undefined') {
                sums[i] = 0
              }
              sums[i] += value
            })
          })

          return sums
        },

        /**
         * 各棒最後の項目のメタ情報を取得
         * (非表示のものは除く)
         */
        getLastMeta: function (chart) {
          let i = chart.data.datasets.length - 1
          let meta = undefined
          do {
            meta = chart.getDatasetMeta(i)
            i--
          } while (meta.hidden && i >= 0)

          return meta
        },

        /**
         * ラベル情報を取得
         */
        makeLabels: function (meta, sums) {
          const labels = []
          sums.forEach(function (sum, i) {
            const lastModel = meta.data[i]._model
            labels.push({
              value: sum !== 0 ? sum.toString() : '',
              x: lastModel.x,
              y: lastModel.y,
            })
          })

          return labels
        },

        /**
         * 書式設定
         */
        setTextStyle: function (ctx) {
          const fontSize = 14
          const fontStyle = 'bold'
          const fontFamily = "'Helvetica Neue', Helvetica, Arial, sans-serif"
          ctx.font = Chart.helpers.fontString(fontSize, fontStyle, fontFamily)
          ctx.fillStyle = '#666'
          ctx.textAlign = 'center'
          ctx.textBaseline = 'bottom'

          return ctx
        },
      }

      // 上記プラグインの有効化
      Chart.plugins.register(chartjsPluginTotalLabels)

      const lbs: string[] = Array.from(new Set(chartDataMonthly.map((data) => Object.values(data.data).filter((v) => v !== 0).map(fv => Object.keys(fv)).flat()).flat()))

      const datasets_monthly_ = chartDataMonthly.map((data) => {
        return {
          label: data.label,
          data: Object.values(data.data),
          datalabels: {
            font: {
              weight: 'bold',
            },
          },
          stack: 'proposal',
        }
      })

      const datasets_monthly = lbs.map(label => (chartDataMonthly.map((data) => {
        return {
          label: label,
          data: Object.values(data.data).map((v) => (typeof v === 'object' ? Object.values(v)[Object.keys(v).indexOf(label)] : 0)),
          datalabels: {
            font: {
              weight: 'bold',
            },
          },
          stack: 'proposal',
        }
      })))

      const data_monthly = {
        labels,
        datasets: lbs.length === 0 ? datasets_monthly_ : datasets_monthly.flat(),
      }

      const config_monthly = {
        type: 'bar',
        data: data_monthly,
        options: {
          layout: {
            padding: {
              top: 20,
            },
          },
          responsive: true,
          maintainAspectRatio: false,
          scales: {
            x: {
              stacked: true,
            },
            y: {
              stacked: true,
            },
          },
          legend: {
            position: 'right',
            reverse: true,
          },
          plugins: {
            colorschemes: {
              scheme: 'brewer.YlGn3',
            },
            datalabels: {
              formatter: function (value, context) {
                if (value != 0) {
                  return value
                } else {
                  return ''
                }
              },
            },
          },
        },
      }

      new Chart(document.getElementById('purchaceAppliedContractChart'), config_monthly)
    }

    void init()
  }, [])

  return <canvas id="purchaceAppliedContractChart" />
}

export default AnalysesPurchaceAppliedContractChart
