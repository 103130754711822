import { tagColorCodes } from '@/models/tagColorCodes'
import { Tag } from '@/types/tag'
import * as React from 'react'
import { AnchorButton, Button } from '../Button'
import { Modal } from '../Modal'

const TagItem = ({
  tags,
  currentTagName,
  path,
}: {
  tags: Tag[]
  currentTagName: string
  path: string
}) => {
  const [tagChangeModal, setTagChangeModal] = React.useState(false)
  const tagColor = '#FFF'
  const [tag, setTag] = React.useState<Tag | undefined>()
  const tagChangeFormRef = React.useRef<HTMLFormElement>(null)

  const changeTagCallback = React.useCallback(
    (event: React.ChangeEvent<HTMLSelectElement>) => {
      const tagId = Number(event.target.value)
      const tag = tags.find((t) => t.id === tagId)
      setTag(tag)
      setTagChangeModal(true)
    },
    []
  )

  const onTagChangeModalClose = React.useCallback(() => {
    setTagChangeModal(false)
    const tag = tags.find((t) => t.name === currentTagName)
    setTag(tag)
  }, [tagChangeModal])

  return (
    <td className="my-auto">
      <select
        name="property[tag_id]"
        onChange={changeTagCallback}
        className={[
          'empty:hidden inline-flex text-sm ml-2 px-3 py-[4px] rounded-full border-[1px] border-[#EEE] border-transparent text-black',
          tagColor,
        ].join(' ')}
        value={tag?.id}
      >
        <option value="">タグ未設定</option>
        {tags.map((t) => (
          <option
            key={t.id}
            value={t.id}
            className={tagColorCodes[t.id]?.bg}
            selected={currentTagName == t.name}
          >
            {t.name}
          </option>
        ))}
      </select>

      <Modal open={tagChangeModal} onClose={onTagChangeModalClose}>
        <div className="px-6 py-6 leading-10">
          タグを{currentTagName && `${currentTagName}から`}
          {tag?.name}変更します。よろしいですか？
        </div>

        <form
          action={path}
          acceptCharset="UTF-8"
          method="post"
          encType="multipart/form-data"
          ref={tagChangeFormRef}
        >
          <input
            type="hidden"
            name="authenticity_token"
            value={document.querySelector<HTMLMetaElement>('meta[name=csrf-token]')?.content}
          />
          <input type="hidden" name="_method" value={'patch'} />
          <input type="hidden" name="property[tag_id]" value={tag?.id} />
          <input
            type="hidden"
            name="redirect_to"
            value={window.location.pathname + window.location.search}
          />
        </form>

        <AnchorButton
          className="text-white md:w-[120px] w-full h-[36px]"
          size="small"
          variant="primary"
          type="submit"
          onClick={() => {
            tagChangeFormRef.current?.submit()
          }}
        >
          はい
        </AnchorButton>
        <Button
          className="text-primary-deep border-white md:w-[120px] w-full h-[36px]"
          outline
          size="small"
          variant="primary"
          onClick={onTagChangeModalClose}
        >
          閉じる
        </Button>
      </Modal>
    </td>
  )
}

export default TagItem
