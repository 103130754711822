import * as React from 'react'
import { AnchorButton } from '@/components/Button'
import { Modal } from '@/components/Modal'
import { UserContext } from '@/components/Layout/Base'

type Props = {
  open: boolean
  onClose: () => void
  path: string
  query?: string
  title?: string
  onPropertyDocumentLink?: boolean
}

export const MarketDatumModal: React.FC<Props> = ({
  open,
  onClose,
  path,
  query,
  title,
  onPropertyDocumentLink = true,
}) => {
  const { user } = React.useContext(UserContext)
  const getPropertyHashid = () => {
    if (path.includes('/properties/')) {
      return path.match(/^[a-zA-Z0-9]$/)
    }
    if (path.includes('/property_public_files/')) {
      return new URLSearchParams(window.location.search).get('property_id')
    }
  }
  const replacePath = () => {
    if(path.includes('/property_public_files/')){
      return `/properties/${getPropertyHashid()}`
    } else {
      return path
    }
  }
  return (
    <Modal open={open} onClose={onClose}>
      <div className="px-6 py-6 leading-10">{title ? title : '事例種別'}を選択してください</div>
      {onPropertyDocumentLink &&
      (user.pricing_type == 'owner' || user.pricing_type == 'enterprise') ? (
        <>
          <div className="flex justify-center gap-4 mb-3">
            <div className="flex justify-center gap-4 mb-3">
              <AnchorButton
                className="text-white min-w-[160px] h-[36px]"
                size="small"
                variant="primary"
                href={
                  replacePath().includes('/properties/')
                    ? `${replacePath()}?tab=property_documents`
                    : '/property_document_formats'
                }
              >
                物件概要書
              </AnchorButton>
            </div>
            <div className="flex justify-center flex-col gap-4">
              <AnchorButton
                className="text-white min-w-[160px] h-[36px]"
                size="small"
                variant="primary"
                href={addQuery(`${replacePath()}?data_type=for_sale`, query)}
              >
                周辺売買事例
              </AnchorButton>
              <AnchorButton
                className="text-white min-w-[160px] h-[36px]"
                size="small"
                variant="primary"
                href={addQuery(`${replacePath()}?data_type=for_rent`, query)}
              >
                周辺賃貸事例
              </AnchorButton>
            </div>
        </div>
        </>
      ) : (
        <>
          <div className="flex justify-center gap-4 mb-3">
            <AnchorButton
              className="text-white min-w-[160px] h-[36px]"
              size="small"
              variant="primary"
              href={addQuery(`${replacePath()}?data_type=for_sale`, query)}
            >
              周辺売買事例
            </AnchorButton>
            <AnchorButton
              className="text-white min-w-[160px] h-[36px]"
              size="small"
              variant="primary"
              href={addQuery(`${replacePath()}?data_type=for_rent`, query)}
            >
              周辺賃貸事例
            </AnchorButton>
          </div>
        </>
      )}
    </Modal>
  )
}

const addQuery = (path: string, query?: string): string => {
  return query ? `${path}&${query}` : path
}
