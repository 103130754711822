import * as React from 'react'
import Base from '@/components/Layout/Base'
import { Table } from '@/components/Table'
import type { User } from '@/types/user'
import { roleType } from '@/config/langs/user'
import { Breadcrumb } from '@/components/Breadcrumb'

type Props = {
  current_user: User
  user: User
}

const formatDateTime = (date: string): string => {
  const d = new Date(date)
  return `${d.toLocaleString()}`
}

const UsersDetailPage: React.FC<Props> = ({ current_user, user }) => {
  const title = 'ユーザー詳細'
  const proxyUser = user.proxy_company

  return (
    <Base
      current_user={current_user}
      title={title}
      breadcrumb={<Breadcrumb link_texts={['ユーザー設定', 'ユーザー詳細']} />}
      submenuCategory="settings"
    >
      <Table className="mt-8">
        <tbody>
        <tr className="bg-gray-light">
          <th
              scope="row"
              className="py-5 px-4 md:text-right whitespace-nowrap text-black-base font-medium w-1/4"
          >
            所属会社
          </th>
          <td className="py-2 px-4 w-3/4">
            {proxyUser ? (
                <>
                  <p>
                    {`${user.proxy_company.name}（代理）`}
                    <span className="text-xs">
                      ※システム管理者のみZISEDAIのユーザー一覧から確認できます
                    </span>
                  </p>
                </>
            ) : (
                user.company.name
            )}
          </td>
        </tr>
        <tr className="bg-white">
          <th
              scope="row"
              className="py-5 px-4 md:text-right whitespace-nowrap text-black-base font-medium w-1/4"
          >
            部署
          </th>
          <td className="py-2 px-4 w-3/4">{user.department?.name}</td>
        </tr>
        <tr className="bg-gray-light">
          <th
              scope="row"
              className="py-5 px-4 md:text-right whitespace-nowrap text-black-base font-medium w-1/4"
          >
            氏名
          </th>
          <td className="py-2 px-4 w-3/4">{user.name}</td>
        </tr>
        <tr className="bg-white">
          <th
              scope="row"
              className="py-5 px-4 md:text-right whitespace-nowrap text-black-base font-medium w-1/4"
          >
            氏名（カナ）
          </th>
          <td className="py-2 px-4 w-3/4">{user.name_kana}</td>
        </tr>
        <tr className="bg-gray-light">
          <th
              scope="row"
              className="py-5 px-4 md:text-right whitespace-nowrap text-black-base font-medium w-1/4"
          >
            メールアドレス
          </th>
          <td className="py-2 px-4 w-3/4">{user.email}</td>
        </tr>
        <tr className="bg-white">
          <th
              scope="row"
              className="py-5 px-4 md:text-right whitespace-nowrap text-black-base font-medium w-1/4"
          >
            権限
          </th>
          <td className="py-2 px-4 w-3/4">{roleType[user.role]}</td>
        </tr>
        <tr className="bg-gray-light">
          <th
              scope="row"
              className="py-5 px-4 md:text-right whitespace-nowrap text-black-base font-medium w-1/4"
          >
            登録日時
          </th>
          <td className="py-2 px-4 w-3/4">{formatDateTime(user.created_at)}</td>
        </tr>
        <tr className="bg-white">
          <th
              scope="row"
              className="py-5 px-4 md:text-right whitespace-nowrap text-black-base font-medium w-1/4"
          >
            更新日時
          </th>
          <td className="py-2 px-4 w-3/4">{formatDateTime(user.updated_at)}</td>
        </tr>
        <tr className="bg-gray-light">
          <th
              scope="row"
              className="py-5 px-4 md:text-right whitespace-nowrap text-black-base font-medium w-1/4"
          >
            volume check
          </th>
          <td className="py-2 px-4 w-3/4">{user.is_volume_check ? 'あり' : 'なし'}</td>
        </tr>
        <tr className="bg-white-light">
          <th
              scope="row"
              className="py-5 px-4 md:text-right whitespace-nowrap text-black-base font-medium w-1/4"
          >
            ピンの自動表示
          </th>
          <td className="py-2 px-4 w-3/4">{user.is_auto_ping ? 'あり' : 'なし'}</td>
        </tr>
        <tr className="bg-white">
          <th
              scope="row"
              className="py-5 px-4 md:text-right whitespace-nowrap text-black-base font-medium w-1/4"
          >
            アカウントロック
          </th>
          <td className="py-2 px-4 w-3/4">
            {user.locked_at ? formatDateTime(user.locked_at) : null}
          </td>
        </tr>
        </tbody>
      </Table>
    </Base>
  )
}

export default UsersDetailPage
