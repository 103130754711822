import * as React from 'react'
import { Table } from '@/components/Table'
import { Button, AnchorButton } from '@/components/Button'
import { Input } from '@/components/Input'

type Props = {
  property_public_file_id: string
  authenticity_token: string
  error_messages: string[]
}

const PropertyPublicFilesEnterPasswordPage: React.FC<Props> = ({
  property_public_file_id,
  authenticity_token,
  error_messages,
}) => {
  const submit = '入力完了'

  return (
    <>
      <div className="text-gray-300 text-lg mt-2 text-center">パスワード入力画面</div>
      <form
        className="mt-2"
        action={`/property_public_files/${property_public_file_id}/authenticate_password`}
        acceptCharset="UTF-8"
        method="post"
      >

        <input type="hidden" name="authenticity_token" value={authenticity_token} />
        {error_messages.length > 0 && (
          <ul className="mb-6 text-sm text-red">
            {error_messages.map((message, i) => (
              <li key={i} className="mb-2 break-all">
                {message}
              </li>
            ))}
          </ul>
        )}
        <Table className="block h-[calc(100vh_-_182px)] md:h-[calc(100vh_-_195px)]">
          <tbody className="block md:table-row-group">
            <tr className="bg-[#F1F3F4] block md:table-row [&:nth-child(even)]:bg-white">
              <th
                scope="row"
                className="pt-4 pb-2 md:py-5 px-4 text-right whitespace-nowrap text-black-base font-medium w-1/4 block md:table-cell"
              >
                パスワード
                <span className="ml-2 inline-block border border-red py-0.5 px-1 text-red leading-none text-[10px]">
                  必須
                </span>
              </th>
              <td className="py-2 px-4 md:w-3/4 block md:table-cell">
                <Input
                  className="md:w-[300px]"
                  name="password"
                  id="password"
                  type="password"
                />
              </td>
            </tr>
          </tbody>
        </Table>
        <div className="bg-white flex justify-center items-center w-full left-0 md:gap-4 border-t border-gray-250 pt-2">
          <Button className="w-full md:w-40 md:h-auto">{submit}</Button>
        </div>
      </form>
    </>
  )
}

export default PropertyPublicFilesEnterPasswordPage
