import { useRef, useState } from 'react'
import type { Identifier, XYCoord } from 'dnd-core'
import { useDrag, useDrop } from 'react-dnd'
import type { DragItem } from '@/types/dragItem'

export function useDragAndDrop(
  index: number,
  moveCard: (dragIndex: number, hoverIndex: number) => void,
  dropCard?: () => void
): [React.RefObject<HTMLDivElement>, boolean] {

  const ref = useRef<HTMLDivElement>(null)
  const [originalIndex, setOriginalIndex] = useState(index)
  const ItemTypes = {
    CARD: 'card',
  }

  const [{ handlerId }, drop] = useDrop<DragItem, void, { handlerId: Identifier | null }>({
    accept: ItemTypes.CARD,
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      }
    },
    hover(item: DragItem, monitor) {
      if (!ref.current) {
        return
      }
      const dragIndex = item.index
      const hoverIndex = index

      if (dragIndex === hoverIndex) {
        return
      }

      const hoverBoundingRect = ref.current?.getBoundingClientRect()
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2
      const clientOffset = monitor.getClientOffset()
      const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top

      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return
      }

      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return
      }

      moveCard(dragIndex, hoverIndex)
      item.index = hoverIndex
    },
    drop(item: DragItem) {
      if (dropCard) dropCard()
      setOriginalIndex(item.index)
    },
  })

  const [{ isDragging }, drag] = useDrag({
    type: ItemTypes.CARD,
    item: { type: ItemTypes.CARD, id: 'some-id', index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
    end: (item, monitor) => {
      const didDrop = monitor.didDrop()
      if (!didDrop) {
        moveCard(item.index, originalIndex)
      }
    },
  })

  const dragRef = drag(drop(ref))

  return [ref, isDragging, handlerId]
}
