import * as React from 'react'
import { Dialog, Transition } from '@headlessui/react'
import ClearIcon from '@material-ui/icons/Clear'
import MarkEmailUnreadIcon from '@mui/icons-material/MarkEmailUnread'
import MarkEmailReadIcon from '@mui/icons-material/MarkEmailRead'
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf'
import FiberNewIcon from '@mui/icons-material/FiberNew'
import DraftsIcon from '@mui/icons-material/Drafts'

type Props = {
  refresh?: boolean
  setRefresh?: (value: boolean) => void
}

export const AnnouncementsModal: React.FC<Props> = ({ refresh, setRefresh }) => {
  const title = 'お知らせ'
  const csrfToken: HTMLMetaElement = document.head.querySelector('meta[name="csrf-token"]')
  const [isOpen, setIsOpen] = React.useState(false)
  const [announcements, setAnnouncements] = React.useState([])
  const [readAnnouncements, setReadAnnouncements] = React.useState(new Set())
  const hasUnreadAnnouncements = announcements.some(
    (announcement) => !readAnnouncements.has(announcement.id)
  )
  const [isLoading, setIsLoading] = React.useState(true)
  const modalContentRef = React.useRef(null)

  React.useEffect(() => {
    const timer = setTimeout(() => {
      if (isOpen && modalContentRef.current) {
        modalContentRef.current.scrollTop = 0
      }
    }, 50)
    return () => clearTimeout(timer)
  }, [isOpen, announcements])

  React.useEffect(() => {
    if (refresh) {
      fetchAnnouncements()
      setRefresh(false)
    }
  }, [refresh, setRefresh])

  React.useEffect(() => {
    fetchReadAnnouncements()
  }, [])

  function closeModal() {
    setIsOpen(false)
  }

  function openModal() {
    setIsOpen(true)
    fetchAnnouncements()
  }

  const fetchAnnouncements = () => {
    fetch('/announcements.json')
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok')
        }
        return response.json()
      })
      .then((data) => {
        setAnnouncements(data)
      })
      .catch((error) => {
        console.error('Error fetching announcements:', error)
      })
  }

  const handleAnnouncementClick = async (announcement) => {
    if (announcement.announcement_pdf && announcement.announcement_pdf.url) {
      window.open(announcement.announcement_pdf.url, '_blank', 'noopener,noreferrer')
    }

    const announcementId = announcement.id
    if (readAnnouncements.has(announcementId)) {
      return
    }

    try {
      const response = await fetch(`/announcements/${announcementId}/user_announcements`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': csrfToken.content,
        },
      })

      if (!response.ok) {
        throw new Error('Failed to mark announcement as read')
      }

      setReadAnnouncements((prevReadAnnouncements) => {
        const updated = new Set(prevReadAnnouncements)
        updated.add(announcementId)
        return updated
      })
    } catch (error) {
      console.error('Error:', error)
    }
  }

  const handleMarkAllAsRead = async () => {
    if (!hasUnreadAnnouncements) {
      return
    }
    try {
      const response = await fetch('/user_announcements/mark_all_as_read', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': csrfToken.content,
        },
      })
      if (!response.ok) {
        throw new Error('Failed to mark all announcements as read')
      }
      fetchAnnouncements()
      fetchReadAnnouncements()
    } catch (error) {
      console.error('Error:', error)
    }
  }

  const fetchReadAnnouncements = async () => {
    setIsLoading(true)
    try {
      const announcementsResponse = await fetch('/announcements.json')
      const announcementsData = await announcementsResponse.json()
      setAnnouncements(announcementsData)

      const response = await fetch('/user_announcements')
      const readAnnouncementIds = await response.json()
      setReadAnnouncements(new Set(readAnnouncementIds.map((ua) => ua.id)))
    } catch (error) {
      console.error('Error during fetch:', error)
    } finally {
      setIsLoading(false)
    }
  }

  const unreadCount = announcements.filter(
    (announcement) => !readAnnouncements.has(announcement.id)
  ).length

  return (
    <>
      <div className="">
        <button type="button" onClick={openModal}>
          {isLoading ? (
            <span></span>
          ) : hasUnreadAnnouncements ? (
            <MarkEmailUnreadIcon color="error" fontSize="medium" />
          ) : (
            <MarkEmailReadIcon style={{ color: '#0A689D' }} fontSize="medium" />
          )}
        </button>
      </div>

      <Transition appear show={isOpen} as={React.Fragment}>
        <Dialog className="relative z-10" onClose={closeModal}>
          <Transition.Child
            as={React.Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center text-center">
              <Transition.Child
                as={React.Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-screen md:max-w-[625px] transform overflow-hidden rounded-2xl bg-white text-left align-middle shadow-xl transition-all">
                  <Dialog.Title className="text-base font-medium p-4 leading-6 text-gray-700">
                    <div className="text-base font-medium leading-6 text-gray-700 flex items-center">
                      <span>{title}</span>
                      <span className="ml-4 flex items-center">
                        <span
                          style={{ backgroundColor: unreadCount > 0 ? '#ed3737' : '#ccc' }}
                          className="text-white text-xs rounded-full px-2 py-0.5"
                        >
                          {unreadCount > 0 ? unreadCount : '0'}
                        </span>
                        <span className="ml-2 text-xs text-[#888]">件未読</span>

                        <div
                          className="ml-4 flex items-center cursor-pointer border text-[#888] border-[#888] px-2 py-1 rounded hover:border-primary hover:text-primary-font"
                          onClick={handleMarkAllAsRead}
                        >
                          <DraftsIcon color="inherit" style={{ fontSize: '1rem' }} />
                          <span className="ml-1 text-xs">全て既読</span>
                        </div>
                      </span>
                      <ClearIcon className="ml-auto cursor-pointer" onClick={closeModal} />
                    </div>
                  </Dialog.Title>
                  <div className="border-t border-gray-150"></div>

                  <div className="h-[80vh] overflow-y-auto" ref={modalContentRef}>
                    {announcements.length === 0 ? (
                      <div className="flex h-full items-center justify-center">
                        <div className="flex flex-col items-center text-[#ddd]">
                          <div className="text-center text-xl py-2">
                            現在掲載中のお知らせはありません
                          </div>
                          <hr className="w-full border-[#eee]" />
                          <div className="text-center text-sm py-2">
                            — Powered by{' '}
                            <a
                              href="https://zised.ai"
                              target="_blank"
                              rel="noopener noreferrer"
                              className="font-semibold"
                            >
                              zised.ai
                            </a>{' '}
                            —
                          </div>
                        </div>
                      </div>
                    ) : (
                      announcements.map((announcement, index) => (
                        <React.Fragment key={index}>
                          <div
                            className={`hover:bg-gray-100 cursor-pointer px-8 py-4 ${
                              readAnnouncements.has(announcement.id) ? 'text-[#888]' : 'text-[#333]'
                            }`}
                            onClick={() => handleAnnouncementClick(announcement)}
                          >
                            <div className="flex justify-between items-center">
                              <div className="flex items-center flex-grow">
                                <h3
                                  className={`${
                                    readAnnouncements.has(announcement.id)
                                      ? 'font-normal'
                                      : 'font-bold text-[#4a4a4a]'
                                  }`}
                                >
                                  {announcement.title}
                                </h3>
                                {announcement.announcement_pdf &&
                                  announcement.announcement_pdf.url && (
                                    <a
                                      href={announcement.announcement_pdf.url}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                    >
                                      <PictureAsPdfIcon
                                        className="ml-2"
                                        fontSize="small"
                                        style={{ color: '#4a7891' }}
                                      />
                                    </a>
                                  )}
                                {!readAnnouncements.has(announcement.id) && (
                                  <FiberNewIcon fontSize="small" color="error" className="ml-2" />
                                )}
                              </div>
                              <span className="text-xs text-right flex-none">
                                {new Date(announcement.created_at).toLocaleDateString()}
                              </span>
                            </div>
                            <div
                              className="pt-[2px] text-sm"
                              dangerouslySetInnerHTML={{
                                __html: announcement.body.replace(/\n/g, '<br>'),
                              }}
                            ></div>
                          </div>
                          <hr className="w-9/10 mx-auto border-[#e7e7e7]" />
                        </React.Fragment>
                      ))
                    )}
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  )
}

export default AnnouncementsModal
