import * as React from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { Button, AnchorButton } from '@/components/Button'
import SearchIcon from '@material-ui/icons/Search'
import ClearIcon from '@material-ui/icons/Clear'
import RefreshIcon from '@material-ui/icons/Refresh'
import { Select } from '@/components/Select'

type Props = {
  company_options: { value: string; text: string }[]
  title: string
}

type TownJson = {
  town: string
  town_kana: string
}[]

export const UsersModal: React.FC<Props> = ({
  title,
  company_options,
}) => {
  const [isOpen, setIsOpen] = React.useState(false)

  const [userSearchParams, setUserSearchParams] = React.useState({
    userCompanyId: '',
  })

  React.useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search)
    setUserSearchParams({
      userCompanyId: searchParams.get('q[company_id_eq]') || '',
    })
  }, [])

  function closeModal() {
    setIsOpen(false)
  }

  function openModal() {
    setIsOpen(true)
  }

  return (
    <>
      <div className="">
        <button type="button" onClick={openModal}>
          <AnchorButton
            className="text-primary-font"
            prefix={<SearchIcon fontSize="small" />}
            outline
            size="small"
            variant="primary"
          >
            <span className="hidden md:inline">絞り込み</span>
          </AnchorButton>
        </button>
      </div>

      <Transition appear show={isOpen} as={React.Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModal}>
          <Transition.Child
            as={React.Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center text-center">
              <Transition.Child
                as={React.Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-screen md:max-w-[625px] transform overflow-hidden rounded-2xl bg-white text-left align-middle shadow-xl transition-all">
                  <Dialog.Title
                    as="h3"
                    className="text-base font-medium p-4 leading-6 text-gray-700"
                  >
                    {title}
                    <ClearIcon className="float-right cursor-pointer" onClick={closeModal} />
                  </Dialog.Title>
                  <div className="border-t border-gray-150"></div>

                  <form id="proposal_search" action="/users" acceptCharset="UTF-8" method="get">
                    <div className="mt-2">
                      <div className="p-4">
                        <div>
                          <div className="bg-primary text-white text-sm font-medium py-2 px-4">
                            条件
                          </div>

                          <div className="flex items-center">
                            <div className="text-sm md:text-sm py-5 px-4 text-right whitespace-normal text-black-base font-medium w-1/4">
                              会社
                            </div>
                            <div className="py-2 px-4 w-3/4">
                              <Select
                                className="text-sm"
                                value={userSearchParams.userCompanyId}
                                name="q[company_id_eq]"
                                id="q_company_id_eq"
                                options={company_options}
                                onChange={(e) =>
                                  setUserSearchParams({
                                    ...userSearchParams,
                                    userCompanyId: e.target.value,
                                  })
                                }
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="border-t border-gray-150"></div>
                    <div className="flex gap-2 px-6 py-3 justify-end">
                      <AnchorButton
                        className="text-primary-font border-white w-[120px]"
                        prefix={<RefreshIcon fontSize="small" />}
                        outline
                        size="small"
                        variant="primary"
                        href="/users"
                      >
                        リセット
                      </AnchorButton>
                      <Button
                        className="text-white w-[120px]"
                        prefix={<SearchIcon fontSize="small" />}
                        size="small"
                        variant="primary"
                      >
                        検索
                      </Button>
                    </div>
                  </form>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  )
}

export default UsersModal
