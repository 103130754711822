import * as React from 'react'

import { AnchorButton, Button } from '@/components/Button'
import { Select } from '@/components/Select'
import { BusinessType } from '@/types/businessType'
import { colors } from '@/utils/colors'
import { Dialog, Transition } from '@headlessui/react'
import CalendarToday from '@material-ui/icons/CalendarToday'
import ClearIcon from '@material-ui/icons/Clear'
import RefreshIcon from '@material-ui/icons/Refresh'
import SearchIcon from '@material-ui/icons/Search'

import ja from 'date-fns/locale/ja'
import DatePicker, { registerLocale } from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'

import { DropDown } from '@/components/DropDown/Index'
import { propertyTypeOptions } from '@/models/property'
import { Params } from './Index'

type Props = {
  title: string
  type: PageType
  target_period_types: Array<[string, string]>
  params: Params
  setParams: (Params) => void
  departments: Array<[string, number]>
  users: Array<[string, number]>
  business_types: BusinessType[]
}

type PageType =
  | 'monthly'
  | 'purchace_applied_contract'
  | 'departments_comparing_monthly'
  | 'departments_monthly'
  | 'departments_monthly'
  | 'users_totalling'
  | 'source_ranking'

export const SearchButton: React.FC<Props> = (props: Props) => {
  const [isOpen, setIsOpen] = React.useState(false)

  registerLocale('ja', ja)

  const openModal = () => {
    setIsOpen(true)
  }

  const closeModal = () => {
    setIsOpen(false)
  }

  return (
    <>
      <button type="button" onClick={openModal}>
        <AnchorButton
          className="text-primary-font"
          prefix={<SearchIcon fontSize="small" />}
          outline
          size="small"
          variant="primary"
        >
          <span className="hidden md:inline">絞り込み</span>
        </AnchorButton>
      </button>

      <Transition appear show={isOpen} as={React.Fragment}>
        <Dialog className="relative z-10" onClose={closeModal}>
          <Transition.Child
            as={React.Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center text-center">
              <Transition.Child
                as={React.Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-screen md:max-w-[625px] transform overflow-hidden rounded-2xl bg-white text-left align-middle shadow-xl transition-all">
                  <Dialog.Title
                    as="h3"
                    className="text-base font-medium p-4 leading-6 text-gray-700"
                  >
                    {props.title}
                    <ClearIcon className="float-right cursor-pointer" onClick={closeModal} />
                  </Dialog.Title>
                  <div className="border-t border-gray-150"></div>

                  <form id="period" action="/analyses" acceptCharset="UTF-8" method="get">
                    <input type="hidden" value={props.type} name="type" id="type" />
                    <div className="py-4 items-center">

                      {/* 集計期間 */}
                      {<TotalPeriod {...props} />}

                      {props.type === "purchace_applied_contract" && <>
                        {/* 集計区分 */}
                        <AggregationClass {...props} />
                        {/* 集計対象 */}
                        <AggregationTarget {...props} />
                        {/* 情報入手部署 */}
                        {props.params.aggregation_target === 'department' && <MultipleSourceDepartment {...props} />}
                        {/* 情報入手者 */}
                        {props.params.aggregation_target === 'individual' && <SourceUser {...props} />}
                      </>}

                      {props.type === 'monthly' ? (
                        <>
                          {/* 情報入手部署 */}
                          {<SourceDepartment {...props} />}
                          {/* 情報入手者 */}
                          {<SourceUser {...props} />}
                        </>
                      ) : (
                        <>
                          <input type="hidden" value="" name="source_department_id" id="source_department_id" />
                          <input type="hidden" value="" name="source_user_id" id="source_user_id" />
                        </>
                      )}

                      {/* 物件種目 */}
                      {<PropertyType  {...props} />}
                      {/* 事業形態 */}
                      {<BusinessTypeItem  {...props} />}

                      {/* Datepickerが隠れないように余白 */}
                      {(props.type === 'purchace_applied_contract' ||
                        props.type === 'departments_comparing_monthly' ||
                        props.type === 'departments_monthly' ||
                        props.type === 'users_totalling' ||
                        props.type === 'source_ranking') && <div className={'p-[50px]'} />}
                    </div>

                    <div className="border-t border-gray-150" />

                    <div className="flex gap-2 px-6 py-3 justify-end">
                      <AnchorButton
                        href={`/analyses?type=${props.type}`}
                        className="text-primary-font border-white w-[120px]"
                        prefix={<RefreshIcon fontSize="small" />}
                        outline
                        size="small"
                        variant="primary"
                      >
                        リセット
                      </AnchorButton>
                      <Button
                        className="text-white w-[120px]"
                        prefix={<SearchIcon fontSize="small" />}
                        size="small"
                        variant="primary"
                      >
                        検索
                      </Button>
                    </div>
                  </form>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  )
}

const customInputStyle = `
  className="appearance-none
  bg-no-repeat
  bg-center
  border
  rounded-sm
  bg-white
  outline-none
  leading-normal
  text-black-base
  border-gray-border
  placeholder-gray-300
  w-full
  py-2
  pl-4
  pr-3
  text-sm
  flex
  items-center
`
// eslint-disable-next-line react/display-name
const CustomInput = React.forwardRef(
  (
    {
      value,
      onClick,
    }: {
      value?: string
      onClick?: React.MouseEventHandler<HTMLButtonElement>
    },
    ref
  ) => {
    return (
      <button
        className={customInputStyle}
        onClick={(e) => {
          e.preventDefault()
          onClick(e)
        }}
      >
        {value}
        <CalendarToday className="ml-auto" fontSize="small" htmlColor={colors.gray} />
      </button>
    )
  }
)

type ItemProps = { title: string, children: React.ReactNode }
const Item = (props: ItemProps) => {
  return <div className="px-4 w-full">
    <div className="flex items-center">
      <div className="text-sm md:text-sm py-5 px-4 text-right whitespace-normal text-black-base font-medium w-1/4">
        {props.title}
      </div>
      <div className="py-2 px-4 w-3/4">
        {props.children}
      </div>
    </div>
  </div>
}

const TotalPeriod = (props: Props) => {
  const Today = new Date()

  return <div className="px-4 w-full">
    <div className="flex items-center">
      <div className="text-sm md:text-sm py-5 px-4 text-right whitespace-normal text-black-base font-medium w-1/4">
        集計期間
      </div>
      <div className="py-2 px-4 w-3/4">
        {props.type === 'purchace_applied_contract' ? (
          <Select
            className="w-full my-4"
            name="target_period_type"
            id="target_period_type"
            value={props.params.target_period_type}
            options={
              props.target_period_types
                ? props.target_period_types.map(([text, value]) => ({
                  text,
                  value,
                }))
                : []
            }
            onChange={(e) => {
              props.setParams({
                ...props.params,
                target_period_type: e.target.value,
              })
            }}
          />
        ) : (
          <input type="hidden" name="target_period_type" id="target_period_type" />
        )}

        <div className="flex flex-wrap md:flex-nowrap items-center gap-5">
          <div className="w-full">
            <style>
              {`
.react-datepicker__navigation-icon {
  top: 3px;
}
.react-datepicker__navigation-icon::before {
  box-sizing: content-box;
}
.react-datepicker__month-text {
  padding: 8px;
}
.react-datepicker-wrapper {
  width: 100%;
}
`}
            </style>
            <DatePicker
              selected={props.params.from}
              locale="ja"
              maxDate={props.params.to}
              dateFormat="yyyy年MM月"
              showMonthYearPicker
              onChange={(selectedDate) => {
                props.setParams({
                  ...props.params,
                  from: selectedDate,
                  year_from: selectedDate.getFullYear(),
                  month_from: selectedDate.getMonth() + 1,
                })
              }}
              customInput={<CustomInput />}
            />
          </div>
          <div className="text-sm">~</div>
          <div className="w-full">
            <DatePicker
              selected={props.params.to}
              locale="ja"
              minDate={props.params.from}
              maxDate={Today}
              dateFormat="yyyy年MM月"
              showMonthYearPicker
              onChange={(selectedDate) => {
                props.setParams({
                  ...props.params,
                  to: selectedDate,
                  year_to: selectedDate.getFullYear(),
                  month_to: selectedDate.getMonth() + 1,
                })
              }}
              customInput={<CustomInput />}
            />
          </div>
        </div>
      </div>
    </div>

    <input type="hidden" value={props.params.year_from} name="year_from" id="year_from" />
    <input type="hidden" value={props.params.month_from} name="month_from" id="month_from" />
    <input type="hidden" value={props.params.year_to} name="year_to" id="year_to" />
    <input type="hidden" value={props.params.month_to} name="month_to" id="month_to" />
  </div>
}


const SourceDepartment = (props: Props) => {
  return <Item title="情報入手部署">
    <Select
      className="w-full"
      name="source_department_id"
      id="source_department_id"
      value={props.params.source_department_id}
      options={
        props.departments
          ? props.departments.map(([text, value]) => ({
            text,
            value,
          }))
          : []
      }
      onChange={(e) => {
        props.setParams({
          ...props.params,
          source_department_id: parseInt(e.target.value ?? '0', 10),
        })
      }}
    />
  </Item>
}

const MultipleSourceDepartment = (props: Props) => {
  const type = React.useMemo(() => {
    if (props.type === 'purchace_applied_contract') {
      if (props.params.aggregation_target === 'individual') {
        return 'source_user_department_id_in[]'
      } else if (props.params.aggregation_target === 'department') {
        return 'property_user_department_id_in[]'
      }
    }
    return 'source_department_id'
  }, [props.type])

  const departments = React.useMemo(() => {
    let departments = props.departments
    departments.push(["部署なし", 0])
    return departments
  }, [props.departments])

  const defaultValue = React.useMemo(() => {
    const sp = new URLSearchParams(window.location.search)

    if (props.type === 'purchace_applied_contract') {
      if (props.params.aggregation_target === 'individual') {
        return departments.filter(user => sp.getAll('source_user_department_id_in[]').includes(user[1].toString()))
          .map(([text, value]) => ({ value: value, label: text }))
          .filter(d => d.label !== "(すべて)")
      } else if (props.params.aggregation_target === 'department') {
        return departments.filter(user => sp.getAll('property_user_department_id_in[]').includes(user[1].toString()))
          .map(([text, value]) => ({ value: value, label: text }))
          .filter(d => d.label !== "(すべて)")
      }
    }
  }, [props.type, type])

  return <Item title="情報入手部署">
    <DropDown
      className="w-full"
      name={type}
      id={type}
      placeholder="(すべて)"
      defaultValue={defaultValue}
      options={departments ?
        departments
          .filter(d => d[0] !== "(すべて)")
          .map(([text, value]) => ({ value: value, label: text })) : []}
      isMulti
      closeMenuOnSelect={false}
      onChange={(e) => {
        if (e instanceof Array) {
          const source_department_ids = e.map((v) => Number(v.value))
          props.setParams({
            ...props.params,
            source_department_ids
          })
        }
      }}
    />
  </Item>
}

const SourceUser = (props: Props) => {
  const type = React.useMemo(() => {
    if (props.type === 'purchace_applied_contract') {
      if (props.params.aggregation_target === 'individual') {
        return 'property_user_id_in[]'
      } else if (props.params.aggregation_target === 'department') {
        return 'source_user_id_in[]'
      }
    }
    return 'source_user_id'
  }, [props.type])

  const defaultValue = React.useMemo(() => {
    const sp = new URLSearchParams(window.location.search)

    if (props.type === 'purchace_applied_contract') {
      if (props.params.aggregation_target === 'individual') {
        return props.users
          .filter(user => sp.getAll('property_user_id_in[]').includes(user[1].toString()))
          .map(([text, value]) => ({ value: value, label: text }))
      } else if (props.params.aggregation_target === 'department') {
        return props.users
          .filter(user => sp.getAll('source_user_id_in[]').includes(user[1].toString()))
          .map(([text, value]) => ({ value: value, label: text }))
      }
    } else {
      const user = props.users?.find((v) => v?.[1]?.toString() === props.params.source_user_id?.toString())
      if (!user) return null
      return { value: user[1], label: user[0] }
    }
  }, [props.type, type])

  return <Item title="情報入手者">
    <DropDown
      className="w-full"
      name={type}
      id={type}
      placeholder="(すべて)"
      defaultValue={defaultValue}
      options={props.users ?
        props.users
          .filter(d => d[0] !== "(すべて)")
          .map(([text, value]) => ({ value: value, label: text })) : []}
      isMulti={props.type === 'purchace_applied_contract'}
      closeMenuOnSelect={props.type != 'purchace_applied_contract'}
      onChange={(e) => {
        if (e instanceof Option) {
          props.setParams({
            ...props.params,
            source_user_id: e.value,
          })
        } else if (e instanceof Array) {
          const source_user_ids = e.map((v) => Number(v.value))
          console.log(e, source_user_ids)
          props.setParams({
            ...props.params,
            source_user_ids
          })
        }
      }}
    />
  </Item>
}

const PropertyType = (props: Props) => {
  return <Item title="物件種目">
    <Select
      className="w-full"
      name="property_type"
      id="property_type"
      value={props.params.property_type}
      options={[{ value: '', text: 'すべて' }].concat(propertyTypeOptions)}
      onChange={(e) => {
        props.setParams({
          ...props.params,
          property_type: e.target.value,
        })
      }}
    />
  </Item>
}

// importと被ってしまうのでItemとしている
const BusinessTypeItem = (props: Props) => {
  return <Item title="事業形態">
    <Select
      className="w-full"
      name="business_type_id"
      id="business_type_id"
      value={props.params.business_type_id}
      options={props.business_types.map(([text, value]) => ({ text, value }))}
      onChange={(e) => {
        props.setParams({
          ...props.params,
          business_type_id: e.target.value,
        })
      }}
    />
  </Item>
}

const AggregationClass = (props: Props) => {
  return <Item title="集計区分">
    <Select
      className="w-full"
      name="aggregation_category"
      id="aggregation_category"
      defaultValue={new URLSearchParams(window.location.search).get('aggregation_category') ?? "all"}
      options={[
        { text: "案件担当", value: "project_manager" },
        { text: "情報担当", value: "infomation_manager" },
      ]}
      onChange={(e) => {
        props.setParams({
          ...props.params,
          aggregation_category: e.target.value,
        })
      }}
    />
  </Item>
}

const AggregationTarget = (props: Props) => {
  return <Item title="集計対象">
    <Select
      className="w-full"
      name="aggregation_target"
      id="aggregation_target"
      defaultValue={props.params.aggregation_target}
      options={[
        { text: "全体", value: "all" },
        { text: "部門別", value: "department" },
        { text: "個人別", value: "individual" },
      ]}
      onChange={(e) => {
        props.setParams({
          ...props.params,
          aggregation_target: e.target.value,
        })
      }}
    />
  </Item>
}