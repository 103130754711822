import * as React from 'react'

type Props = {
  error_messages: string[]
  className?: string
}

export const ErrorMessage: React.FC<Props> = ({ error_messages, className, ...props }) => {
  return error_messages.length === 0 ? (
    <></>
  ) : (
    <ul className={['mb-6 text-sm text-red', className].join(' ')}>
      {error_messages.map((message, i) => (
        <li key={i} className="mb-2 break-all">
          {message}
        </li>
      ))}
    </ul>
  )
}
