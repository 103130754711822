/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createJob = /* GraphQL */ `
  mutation CreateJob(
    $condition: ModelJobConditionInput
    $input: CreateJobInput!
  ) {
    createJob(condition: $condition, input: $input) {
      createdAt
      execCount
      file
      format
      groupId
      id
      jobResultId
      result {
        createdAt
        id
        result
        updatedAt
        __typename
      }
      status
      updatedAt
      __typename
    }
  }
`;
export const createResult = /* GraphQL */ `
  mutation CreateResult(
    $condition: ModelResultConditionInput
    $input: CreateResultInput!
  ) {
    createResult(condition: $condition, input: $input) {
      createdAt
      id
      result
      updatedAt
      __typename
    }
  }
`;
export const deleteJob = /* GraphQL */ `
  mutation DeleteJob(
    $condition: ModelJobConditionInput
    $input: DeleteJobInput!
  ) {
    deleteJob(condition: $condition, input: $input) {
      createdAt
      execCount
      file
      format
      groupId
      id
      jobResultId
      result {
        createdAt
        id
        result
        updatedAt
        __typename
      }
      status
      updatedAt
      __typename
    }
  }
`;
export const deleteResult = /* GraphQL */ `
  mutation DeleteResult(
    $condition: ModelResultConditionInput
    $input: DeleteResultInput!
  ) {
    deleteResult(condition: $condition, input: $input) {
      createdAt
      id
      result
      updatedAt
      __typename
    }
  }
`;
export const updateJob = /* GraphQL */ `
  mutation UpdateJob(
    $condition: ModelJobConditionInput
    $input: UpdateJobInput!
  ) {
    updateJob(condition: $condition, input: $input) {
      createdAt
      execCount
      file
      format
      groupId
      id
      jobResultId
      result {
        createdAt
        id
        result
        updatedAt
        __typename
      }
      status
      updatedAt
      __typename
    }
  }
`;
export const updateResult = /* GraphQL */ `
  mutation UpdateResult(
    $condition: ModelResultConditionInput
    $input: UpdateResultInput!
  ) {
    updateResult(condition: $condition, input: $input) {
      createdAt
      id
      result
      updatedAt
      __typename
    }
  }
`;
