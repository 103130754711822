import * as React from 'react'
import type { Props } from './types'

const ArrowLeftIcon: React.FC<Props> = ({ size = 16, fill = '343A40', className }: Props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill={fill}
      className={className}
      viewBox="0 1 24 24"
      stroke={fill}
      strokeWidth="1.5"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M19.5 12h-15m0 0l6.75 6.75M4.5 12l6.75-6.75"
        fill={fill}
      />
    </svg>
  )
}

export default ArrowLeftIcon
