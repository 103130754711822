import * as React from 'react'
import { useState, useEffect } from 'react'
import { Loading } from '@/components/Loading'
import { cammedFormat, intFormat, formatNumber, dataPrecisionSetup } from '@/utils/cammedFormat'
import {
  areaOfUseCodes,
  fireProtectionCodes,
  altitudeAreaCodes,
  shadowAreaCodes,
  getCodeFromText,
  getTextFromCode,
  shadeInfoSet,
} from '@/models/areaInfoCodeshashToArray'

export const quickVolumeResult = (
  requestUUID: string,
  csrfToken: string,
  setResponseArea: (value: string) => void,
  checkRetryTime: number,
  setCheckRetryTime: (value: number) => void,
  propertyData?: any,
  setPropertyData?: (value: any) => void,
  propertyShape?: string,
  displayNoAreaInfoMessage?: () => void
) => {
  const [loading, setLoading] = useState(false)
  const [retryTimerId, setRetryTimerId] = useState('')

  const loadingIcon = <Loading height={12} width={12} />

  const handleKoudoChiku = (value) => {
    const initialText = value?.koudo_chiku
      ? getTextFromCode(Number(value.koudo_chiku), altitudeAreaCodes)
      : 'N/A'

    if (initialText === '') {
      return getTextFromCode(0, altitudeAreaCodes)
    }
    return initialText
  }

  useEffect(() => {
    let retryId
    const MAX_RETRY_SECONDS = 10
    const getResponseJson = async () => {
      setLoading(true)
      const response = await fetch(`/quick_check_response?token=${requestUUID}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': csrfToken,
        },
      })
      const data = await response.json()
      if (data.status === 'OK') {
        const results = JSON.parse(data.results)
        const res_area_m3 = results?.site?.area || ''
        const area_m3 = cammedFormat(res_area_m3, 2)

        results.city_planning.merged_areas.sort((a, b) => {
          return parseFloat(b.area) - parseFloat(a.area)
        })

        results.city_planning.merged_areas = results.city_planning.merged_areas.filter((item) => {
          const area = parseFloat(item.area)
          return area !== 0
        })

        const totalMergedAreas = results.city_planning.merged_areas.reduce(
          (acc, item) => acc + parseFloat(item.area),
          0
        )

        if (Math.abs(totalMergedAreas - parseFloat(results.site.area)) >= 1) {
          const remainingArea = parseFloat(results.site.area) - totalMergedAreas
          results.city_planning.merged_areas.push({
            area: remainingArea,
            area_of_use: null,
            fire_protection: null,
            altitude_area: null,
            shadow_area: null,
          })
        }

        setResponseArea(area_m3 ? `${area_m3}㎡` : '')

        const updatedAreaInfo = JSON.stringify(results)
        const borders = results.borders
        const newShape = borders.map((border) => ({
          lat: border.from.lat.toString(),
          lng: border.from.lng.toString(),
        }))

        const newShapeJson = JSON.stringify(newShape)

        const areaInfohandJson = JSON.stringify(results)

        const maxItems = 4
        const areaInfoHand = results.city_planning.merged_areas.slice(0, maxItems)

        const newAreaInfoHandJson = areaInfoHand.map((areaInfo) => ({
          youto_chiiki_hand: areaInfo.area_of_use?.youto_chiiki
            ? getTextFromCode(Number(areaInfo.area_of_use.youto_chiiki), areaOfUseCodes)
            : 'N/A',
          bouka_chiiki_hand: areaInfo.fire_protection?.bouka_chiiki
            ? getTextFromCode(Number(areaInfo.fire_protection.bouka_chiiki), fireProtectionCodes)
            : 'N/A',
          koudo_chiku_hand: handleKoudoChiku(areaInfo.altitude_area),
          shadow_area_hand: shadeInfoSet(areaInfo.shadow_area ?? 'N/A'),
          building_coverage_ratio_hand: areaInfo.area_of_use?.building_coverage_ratio ?? 'N/A',
          floor_area_ratio_hand: areaInfo.area_of_use?.floor_area_ratio ?? 'N/A',
        }))

        if (setPropertyData) {
          setPropertyData({
            ...propertyData,
            area_info: updatedAreaInfo,
            area_info_hands: newAreaInfoHandJson,
            shape: newShapeJson,
          })
        }
        clearTimeout(retryTimerId)
        setRetryTimerId('')
        setLoading(false)
      } else if (data.status === 'NG' || checkRetryTime >= MAX_RETRY_SECONDS) {
        console.log('Error:', data.error)
        if (propertyShape) {
          const polygonShape = JSON.parse(propertyShape).map((coord) => {
            return {
              lat: Number(coord.lat),
              lng: Number(coord.lng),
            }
          })
          const area = google.maps.geometry.spherical.computeArea(polygonShape)
          const areaFormatted = `${cammedFormat(area, 2)}gmap`
          setResponseArea(areaFormatted)
        }
        displayNoAreaInfoMessage()
        if (propertyShape && setPropertyData) {
          setPropertyData({
            ...propertyData,
            shape: propertyShape,
          })
        }
        clearInterval(retryTimerId)
        setRetryTimerId('')
        setLoading(false)
      } else if (data.status === 'PENDING') {
        // 秒数で制御し、秒数の加算イベントでquickVolumeResultをリトライする
        // TODO: 最後に投げたAPIが成功していても、10秒経過していた場合はその結果を破棄している
        // このケースはほぼ発生しないが、areacheckのレスポンスタイムや仕様の変化によっては
        // 上記の条件を考慮した実装が必要になる
        if (retryTimerId === '') {
          retryId = setInterval(() => {
            setCheckRetryTime((prevCount) => prevCount + 2)
          }, 2000)
          setRetryTimerId(retryId)
        }
      } else {
        console.log('Unknown status')
        clearInterval(retryTimerId)
        setRetryTimerId('')
        setLoading(false)
      }
    }
    if (requestUUID !== null && requestUUID !== '') {
      getResponseJson().catch((error) => {
        console.error('Error:', error)
        clearInterval(retryTimerId)
        setRetryTimerId('')
        setLoading(false)
      })
    }

    return () => {
      setLoading(false)
    }
  }, [requestUUID, csrfToken, checkRetryTime])

  return { loading, loadingIcon }
}
