export async function isTokyoArea(map: google.maps.Map): Promise<boolean> {
  const center = map.getCenter()
  const latlng = new google.maps.LatLng(center.lat(), center.lng())
  const geocoder = new google.maps.Geocoder()

  try {
    const results = await geocoder.geocode({ location: latlng, language: 'ja' })

    const addressComponents = results[0]?.address_components
    const prefecture = addressComponents?.find((c) =>
      c.types.includes('administrative_area_level_1')
    )?.long_name

    return prefecture === '東京都'
  } catch (error) {
    console.error(error.message)
    return false
  }
}