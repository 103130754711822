export const textLinkMap = {
  物件管理: '/properties',
  入手情報: '/properties',
  物件詳細: '/properties',
  'Volume Check': '/properties',
  案件一覧: '/projects_totals',
  利用企業設定: '/companies',
  部署設定: '/departments',
  ユーザー設定: '/users',
  タグ設定: '/tags',
  事業形態設定: '/business_types',
  物件概要書フォーマット設定: '/property_document_formats',
  帳票作成: '/properties',
  公開ページ: '/properties',
}
