import * as React from 'react'
import { UserContext } from '@/components/Layout/Base'
import { ocrExecution } from '@/components/Ocr'

type PropertiesPageProps = {
  children: React.ReactNode
  previewZone: React.ReactNode
  ocrStatus: ocrExecution['jobStatus']
  isEdit: boolean
}

export const OCRLayoutPropertiesPage: React.FC<PropertiesPageProps> = (props) => {
  const { children, ocrStatus, previewZone, isEdit } = props

  const { user } = React.useContext(UserContext)
  // 表示制御：登録画面とOCR有効エンタープライズのみで表示させる
  if (isEdit || (!isEdit && !user.company.ocr_enabled)) {
    return children
  }

  return (
    <>
      {ocrStatus == 'finish' || ocrStatus == 'validationError' ? (
        <>
          <div className="flex-1 w-full justify-center items-start lg:flex hidden">
            {/* PC */}
            <div className="w-2/3 overflow-x-auto">{children}</div>
            <div className="px-4 md:min-w-[500px] sticky top-0">{previewZone}</div>
          </div>
          {/* PC以下 */}
          <div className="mt-8 w-full h-[260px]  sticky top-0 z-10 lg:hidden">{previewZone}</div>
          <div className="mt-8 w-full lg:hidden">{children}</div>
        </>
      ) : (
        <>
          <div className="mt-8 flex w-full">{previewZone}</div>
          <div className="mt-8 w-full">{children}</div>
        </>
      )}
    </>
  )
}

type MarketDataPageProps = {
  children: React.ReactNode
  previewZone: React.ReactNode
  mapViewer: React.ReactNode
  ocrStatus: ocrExecution['jobStatus']
  isEdit: boolean
  isLoaded: boolean
  largeScreen: boolean
}

export const OCRLayoutMarketData: React.FC<MarketDataPageProps> = (props) => {
  const { children, ocrStatus, previewZone, mapViewer, isEdit, isLoaded, largeScreen } = props

  const { user } = React.useContext(UserContext)
  // 表示制御：登録画面とOCR有効エンタープライズのみで表示させる
  if (isEdit || (!isEdit && !user.company.ocr_enabled)) {
    return <div className="mt-8">{children}</div>
  }
  console.log(ocrStatus)

  return (
    <>
      {ocrStatus != 'finish' && ocrStatus != 'validationError' && previewZone}
      {/* スマホサイズのOCRの表示 */}
      {ocrStatus === 'finish' && (
        <div className="mt-2 lg:hidden h-[260px] sticky top-0 z-10">
          {previewZone}
        </div>
      )}
      <div className="mt-8 flex-1 flex w-full justify-center items-start gap-4">
        <div className="lg:w-2/3 w-full">{children}</div>
        {isLoaded && largeScreen && (
          <div
            className="lg:w-1/3 hidden lg:block px-1 sticky top-0"
            style={{ maxHeight: '80vh', overflowY: 'auto' }}
          >
            {mapViewer}
            {/* スマホサイズのOCRの表示 */}
            {(ocrStatus == 'finish' || ocrStatus == 'validationError') && (
              <div className="mt-2">{previewZone}</div>
            )}
          </div>
        )}
      </div>
    </>
  )
}
