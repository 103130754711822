import * as React from 'react'
import Base from '@/components/Layout/Base'
import { Table } from '@/components/Table'
import { Input } from '@/components/Input'
import { Button, AnchorButton } from '@/components/Button'
import { User } from '@/types/user'
import { KintoneSetting } from '@/types/kintone_setting'
import { Breadcrumb } from '@/components/Breadcrumb'

type Props = {
  current_user: User
  kintone_setting: KintoneSetting
  authenticity_token: string
  error_messages: string[]
}

const KintoneSettingsShowPage: React.FC<Props> = ({
  current_user,
  kintone_setting,
  authenticity_token,
  error_messages,
}) => {
  const title = 'kintone連携設定'

  const [kintoneSetting, setKintoneSetting] = React.useState({
    ...kintone_setting,
  })

  return (
    <Base
      current_user={current_user}
      title={title}
      breadcrumb={<Breadcrumb link_texts={[title]} />}
      submenuCategory="settings"
    >
      <form className="mt-8" action="/kintone_setting" acceptCharset="UTF-8" method="post">
        <input type="hidden" name="_method" value="patch" />
        <input type="hidden" name="authenticity_token" value={authenticity_token} />

        {error_messages.length > 0 && (
          <ul className="mb-6 text-sm text-red">
            {error_messages.map((message, i) => (
              <li key={i} className="mb-2 break-all">
                {message}
              </li>
            ))}
          </ul>
        )}
        <div className="relative overflow-x-auto h-[calc(100vh_-_182px)] md:h-[calc(100vh_-_195px)]">
          <table className="w-full">
            <tbody className="block md:table-row-group ">
              <tr className="block md:table-row border-t border-gray-150">
                <td className="block md:table-cell py-2 md:px-2 md:w-1/4 md:text-right">
                  <span className="py-[5px] md:py-[7px] px-4 text-sm text-black-base">URL</span>
                </td>
                <td className="block md:table-cell p-2 w-full">
                  <Input
                    className="w-full"
                    name="kintone_setting[url]"
                    placeholder="https://xxx.cybozu.com"
                    value={kintoneSetting.url}
                    onChange={(e) => {
                      setKintoneSetting({
                        ...kintoneSetting,
                        url: e.target.value,
                      })
                    }}
                  />
                </td>
              </tr>
              <tr className="block md:table-row border-t border-gray-150">
                <td className="block md:table-cell py-2 md:px-2 md:w-1/4 md:text-right">
                  <span className="py-[5px] md:py-[7px] px-4 text-sm text-black-base">APIトークン</span>
                </td>
                <td className="block md:table-cell p-2 w-full">
                  <Input
                    className="w-full"
                    name="kintone_setting[token]"
                    placeholder="APIトークンを入力してください"
                    value={kintoneSetting.token}
                    onChange={(e) => {
                      setKintoneSetting({
                        ...kintoneSetting,
                        token: e.target.value,
                      })
                    }}
                  />
                </td>
              </tr>
              <tr className="block md:table-row border-t border-gray-150">
                <td className="block md:table-cell py-2 md:px-2 md:w-1/4 md:text-right">
                  <span className="py-[5px] md:py-[7px] px-4 text-sm text-black-base">アプリ ID</span>
                </td>
                <td className="block md:table-cell p-2 w-full">
                  <Input
                    className="w-full"
                    name="kintone_setting[app_id]"
                    placeholder="アプリ IDを入力してください"
                    value={kintoneSetting.app_id}
                    onChange={(e) => {
                      setKintoneSetting({
                        ...kintoneSetting,
                        app_id: e.target.value,
                      })
                    }}
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="bg-white flex justify-center items-center w-full left-0 md:gap-4 border-t border-gray-250 pt-2">
          <AnchorButton
            className="w-full md:w-40 md:h-auto text-primary-font border-white"
            variant="text"
            outline
            href="/kintone_setting"
          >
            キャンセル
          </AnchorButton>
          <Button className="w-full md:w-40 md:h-auto">編集を保存</Button>
        </div>
      </form>
    </Base>
  )
}

export default KintoneSettingsShowPage
