import { Amplify } from '@aws-amplify/core';

export default function Init(endpoint = process.env.REACT_APP_OCR_API_ENDPOINT) {
  const config: config = {
    aws_project_region: 'ap-northeast-1',
    aws_appsync_graphqlEndpoint: endpoint,
    aws_appsync_region: 'ap-northeast-1',
    aws_appsync_authenticationType: 'AWS_LAMBDA',
    aws_appsync_apiKey: null
  }
  Amplify.configure(config);
}

type config = {
  aws_project_region: string
  aws_appsync_graphqlEndpoint: string
  aws_appsync_region: string
  aws_appsync_authenticationType: string
  aws_appsync_apiKey: string
}
