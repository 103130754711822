export const toukiTargetOptions = [
  { value: 'land', text: '土地' },
  { value: 'building', text: '建物' },
]

export const toukiRequestOptions = [
  { value: 'no', text: '不要' },
  { value: 'yes', text: '要' },
  { value: 'yes_current', text: '要（現在事項）' },
]

export const getToukiRequestOptions = (closureRequest) => {
  if (closureRequest) {
    return toukiRequestOptions.filter(option => option.value !== 'yes_current');
  } else {
    return toukiRequestOptions;
  }
}

export const getToukiTypeOptions = (toukiTarget, closureRequest) => {
  if (closureRequest) {
    return [{ value: 'all', text: '全部事項' }]
  }

  if (toukiTarget === 'land') {
    return [
      { value: 'all', text: '全部事項' },
      { value: 'owner', text: '所有者事項' },
      { value: 'map', text: '地図' },
      { value: 'land_diagram', text: '土地所在地／地積測量図' },
      { value: 'easement', text: '地役権図面' },
    ]
  }

  if (toukiTarget === 'building') {
    return [
      { value: 'all', text: '全部事項' },
      { value: 'owner', text: '所有者事項' },
      { value: 'building_floor', text: '建物図面／各階平面図' },
    ]
  }

  return []
}
