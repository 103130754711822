import * as React from "react";
import { Table } from "@/components/Table";
import type { timeFrame } from "@/types/timeFrame";
import AddOutlinedIcon from '@material-ui/icons/AddOutlined'
import DeleteOutlined from "@material-ui/icons/DeleteOutlined";
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import TextField from '@mui/material/TextField';
import { parse, format, isValid } from 'date-fns';

type Props = {
  title: string
  time_frames: timeFrame[]
  setTimeFrames: (index: number, type: string, value: string | Date) => void
  setIsError: (error: boolean) => void
}
const TimeTable: React.FC<Props> = ({ time_frames, title, setTimeFrames, setIsError }) => {
  const onError = () => {
    const elements = document.querySelectorAll('.MuiInputBase-root');
    const elementsWithClass = Array.from(elements).filter(element =>
      element.classList.contains('Mui-error')
    );
    if (elementsWithClass.length == 0) {
      setIsError(false)
    } else {
      setIsError(true)
    }
  }
  return (
    <div>
      <Table className='w-[850px]'>
        <thead>
          <tr>
            <td colSpan={9} className='bg-gray-150 py-2 px-2'>{title}</td>
            <td className='bg-gray-150 px-2 text-right'>
              <a className="inline-block hover:text-blue-600" onClick={() => setTimeFrames(0, 'add', '')}>
                <AddOutlinedIcon fontSize="small" />
              </a>
            </td>
          </tr>
        </thead>
        <tbody className="whitespace-nowrap">
          {time_frames.map((item, index) => (
            <tr key={index}>
              <td width='20px' className='py-2 px-2'></td>
              <td width='70px' className='py-2'>開始時間</td>
              <td width='200px' className='py-2'>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <TimePicker
                    ampm={false}
                    maxTime={parse(item.end_time, 'HH:mm:ss', new Date())}
                    className='time-frame-picker'
                    value={parse(item.start_time, 'HH:mm:ss', new Date())}
                    onError={() => onError()}
                    onChange={(newValue) => setTimeFrames(index, 'start', isValid(newValue) ? format(newValue, 'HH:mm:ss') : newValue)}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </LocalizationProvider>
              </td>
              <td width='70px' className='py-2 text-center'>～</td>
              <td width='70px' className='py-2'>終了時間</td>
              <td width='200px' className='py-2'>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <TimePicker
                    ampm={false}
                    minTime={parse(item.start_time, 'HH:mm:ss', new Date())}
                    className='time-frame-picker'
                    value={parse(item.end_time, 'HH:mm:ss', new Date())}
                    onError={() => onError()}
                    onChange={(newValue) => setTimeFrames(index, 'end', isValid(newValue) ? format(newValue, 'HH:mm:ss') : newValue)}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </LocalizationProvider>
              </td>
              <td width='20px'></td>
              <td width='70px' className='py-2 text-center'>並列数</td>
              <td width='100px' className='py-2'>
                <input
                  className="max-w-[70px] border pl-4 py-2 rounded-sm bg-white outline-none"
                  value={item.max_task_count}
                  min="0"
                  type="number"
                  name={`${title}_task_count${index}`}
                  id={`${title}_task_count${index}`}
                  onChange={(e) => setTimeFrames(index, 'max', e.target.value)}
                />
              </td>
              <td width='30px' className='px-2 text-right'>
                <a
                  className="inline-block hover:text-deleteHover"
                  onClick={() => setTimeFrames(index, 'del', '')}
                >
                  <DeleteOutlined fontSize="small" />
                </a>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      <style>
        {`
          .time-frame-picker .MuiInputBase-input {
              padding: 0.5rem 1rem;
          }
          .time-frame-picker .MuiInputBase-root .MuiOutlinedInput-notchedOutline {
              border-color: #CED4DA;
              border-radius: 0.125rem;
              border-width: 1px;
          }
          .time-frame-picker .MuiInputBase-root:hover .MuiOutlinedInput-notchedOutline {
              border-color: #CED4DA;
          }
          .time-frame-picker .MuiInputBase-root.Mui-error .MuiOutlinedInput-notchedOutline {
              border-color: #d32f2f
          }
        `}
      </style>
    </div>

  )
}

export default TimeTable