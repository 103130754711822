const csrfToken: HTMLMetaElement = document.head.querySelector('meta[name="csrf-token"]')
const basePath = '/property_public_files'

// 削除
export const deletePublicFileDetail = async (id: number) => {
  const response = await fetch(`${basePath}/${id}`, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      'X-CSRF-Token': csrfToken.content,
    },
  })
  if (!response.ok) {
    return Promise.reject(Error(`${response.status}: ${response.statusText}`))
  } else {
    return response.json()
  }
}
