const filenameFromHeaders = (res): string => {
  // attachment; filename="%3F%3F%3F%3F%3F%3F_%3F%3F%3F%3F%3F%3F%3F%3F2-7-9_20230919.xlsx"; filename*=UTF-8''%E5%91%A8%E8%BE%BA%E5%A3%B2%E8%B2%B7%E4%BA%8B%E4%BE%8B_%E6%9D%B1%E4%BA%AC%E9%83%BD%E6%B8%AF%E5%8C%BA%E5%8C%97%E9%9D%92%E5%B1%B12-7-9_20230919.xlsx
  const contentDisposition = res.headers.get('content-disposition')

  if (contentDisposition && contentDisposition.indexOf('attachment') !== -1) {
    const filenameRegex = /filename\*=UTF-8''([^']+)/
    const matches = filenameRegex.exec(contentDisposition)

    if (matches != null && matches[1]) {
      const filename = matches[1].replace(/['"]/g, '')
      return decodeURI(filename)
    }
  }

  return
}

export { filenameFromHeaders }
