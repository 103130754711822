export default function addSearchBox(map: google.maps.Map) {
  const input = document.createElement("input")
  input.id = "pac-input"
  input.placeholder = "場所を検索"
  const searchBox = new google.maps.places.SearchBox(input)

  map.controls[google.maps.ControlPosition.TOP_LEFT].push(input)

  map.addListener('bounds_changed', () => {
    searchBox.setBounds(map.getBounds())
  })

  searchBox.addListener('places_changed', () => {
    const places = searchBox.getPlaces()

    if (places.length == 0) {
      return
    }

    const bounds = new google.maps.LatLngBounds()

    places.forEach((place) => {
      if (!place.geometry || !place.geometry.location) {
        console.log('Returned place contains no geometry')
        return
      }

      if (place.geometry.viewport) {
        bounds.union(place.geometry.viewport)
      } else {
        bounds.extend(place.geometry.location)
      }
    })
    map.fitBounds(bounds)
  })
}

