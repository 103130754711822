/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onCreateJob = /* GraphQL */ `
  subscription OnCreateJob($filter: ModelSubscriptionJobFilterInput) {
    onCreateJob(filter: $filter) {
      createdAt
      execCount
      file
      format
      groupId
      id
      jobResultId
      result {
        createdAt
        id
        result
        updatedAt
        __typename
      }
      status
      updatedAt
      __typename
    }
  }
`;
export const onCreateResult = /* GraphQL */ `
  subscription OnCreateResult($filter: ModelSubscriptionResultFilterInput) {
    onCreateResult(filter: $filter) {
      createdAt
      id
      result
      updatedAt
      __typename
    }
  }
`;
export const onDeleteJob = /* GraphQL */ `
  subscription OnDeleteJob($filter: ModelSubscriptionJobFilterInput) {
    onDeleteJob(filter: $filter) {
      createdAt
      execCount
      file
      format
      groupId
      id
      jobResultId
      result {
        createdAt
        id
        result
        updatedAt
        __typename
      }
      status
      updatedAt
      __typename
    }
  }
`;
export const onDeleteResult = /* GraphQL */ `
  subscription OnDeleteResult($filter: ModelSubscriptionResultFilterInput) {
    onDeleteResult(filter: $filter) {
      createdAt
      id
      result
      updatedAt
      __typename
    }
  }
`;
export const onUpdateJob = /* GraphQL */ `
  subscription OnUpdateJob($filter: ModelSubscriptionJobFilterInput) {
    onUpdateJob(filter: $filter) {
      createdAt
      execCount
      file
      format
      groupId
      id
      jobResultId
      result {
        createdAt
        id
        result
        updatedAt
        __typename
      }
      status
      updatedAt
      __typename
    }
  }
`;
export const onUpdateResult = /* GraphQL */ `
  subscription OnUpdateResult($filter: ModelSubscriptionResultFilterInput) {
    onUpdateResult(filter: $filter) {
      createdAt
      id
      result
      updatedAt
      __typename
    }
  }
`;
