import * as React from 'react'
import type { Props } from './types'

const CloseIcon: React.FC<Props> = ({ size = 16, fill = '343A40', className }: Props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      className={className}
      fill="none"
      viewBox="0 0 15 15"
    >
      <path
        d="M10.0921 3.39169C10.2163 3.26751 10.3637 3.169 10.5259 3.1018C10.6882 3.03459 10.8621 3 11.0377 3C11.2133 3 11.3872 3.03459 11.5495 3.1018C11.7117 3.169 11.8591 3.26751 11.9833 3.39169C12.1075 3.51586 12.206 3.66329 12.2732 3.82554C12.3404 3.98778 12.375 4.16168 12.375 4.3373C12.375 4.51291 12.3404 4.68681 12.2732 4.84906C12.206 5.01131 12.1075 5.15873 11.9833 5.28291L5.60043 11.6658L3 12.375L3.70921 9.77457L10.0921 3.39169Z"
        stroke={fill}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default CloseIcon
