import { getBoundsOfDistance, getDistance } from 'geolib'

export const boundingBox = (lat: string | number, lng: string | number, distance: number) => {
  const center = { latitude: Number(lat), longitude: Number(lng) }

  // 南西と北東の緯度経度を取得
  const boundingBox = getBoundsOfDistance(center, distance)
  const southWest = boundingBox[0]
  const northEast = boundingBox[1]

  // geolibとMySQLの東京都 港区 北青山 2-7-9との距離確認用
  // console.log(getDistance(center, { latitude: 35.67095, longitude: 139.718321 }))
  // SELECT ST_Distance(latlng, ST_PointFromText('POINT(35.670950 139.718321)', 4326)), market_data.* FROM market_datum_latlngs JOIN market_data ON market_data.id = market_datum_latlngs.market_datum_id;

  return {
    lat_center: center.latitude,
    lng_center: center.longitude,
    lat_north: northEast.latitude,
    lat_south: southWest.latitude,
    lng_west: southWest.longitude,
    lng_east: northEast.longitude,
  }
}
