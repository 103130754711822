import * as React from 'react'
import Base from '@/components/Layout/Base'
import type { User } from '@/types/user'
import type { Tag } from '@/types/tag'
import { Input } from '@/components/Input'
import { Button, AnchorButton } from '@/components/Button'
import { Breadcrumb } from '@/components/Breadcrumb'
import { tagColorCodes } from '@/models/tagColorCodes'

type Props = {
  current_user: User
  tag: Tag
  tags: Tag[]
  authenticity_token: string
  error_messages: string[]
}

const TagEditPage: React.FC<Props> = ({
  current_user,
  tag,
  tags,
  authenticity_token,
  error_messages,
}) => {
  const title = 'タグ編集'

  const [tagName, setTagName] = React.useState(tag.name || '')
  const [colorValue, setColorValue] = React.useState(tag.color_before_type_cast || '')

  return (
    <Base
      current_user={current_user}
      title={title}
      breadcrumb={<Breadcrumb link_texts={['タグ設定', 'タグ編集']} />}
      submenuCategory="settings"
    >
      <form
        className="mt-8"
        encType="multipart/form-data"
        action={tag.id ? `/tags/${tag.id}` : '/tags'}
        acceptCharset="UTF-8"
        method="post"
      >
        <input type="hidden" name="_method" value={tag.id ? 'patch' : 'post'} />
        <input type="hidden" name="authenticity_token" value={authenticity_token} />

        {error_messages.length > 0 && (
          <ul className="mb-6 text-sm text-red">
            {error_messages.map((message, i) => (
              <li key={i} className="mb-2 break-all">
                {message}
              </li>
            ))}
          </ul>
        )}

        <div className="h-[calc(100vh_-_182px)] md:h-[calc(100vh_-_195px)]">
          <div className="py-2 md:flex item-center">
            <div>
              <div className="pb-2 text-sm">タグ名</div>
              <Input
                value={tagName}
                name="tag[name]"
                id="tag_name"
                onChange={(e) => setTagName(e.target.value)}
              />
            </div>
            <div className="pb-2 md:ml-8 overflow-x-scroll">
              <div className="pt-5 md:pt-0 pb-2 text-sm">背景色選択</div>
              <div className="flex gap-3">
                {tagColorCodes.map((colorCode, index) => (
                  <div key={index}>
                    <div
                      className={`px-2 py-1 text-white text-sm rounded-lg cursor-pointer whitespace-nowrap ${colorCode.bg}`}
                      onClick={(e) => {
                        document.querySelectorAll('.selected-line').forEach((element) => {
                          element.classList.add('hidden')
                        })
                        ;(e.target as HTMLInputElement).nextElementSibling.classList.remove(
                          'hidden'
                        )
                        setColorValue(index + 1)
                      }}
                    >
                      {tagName || 'タグ名'}
                    </div>
                    <div
                      className={`${
                        tag.color_before_type_cast != index + 1 && 'hidden'
                      } selected-line mt-1 border-b-2 ${colorCode.border}`}
                    ></div>
                  </div>
                ))}
                <input type="hidden" name="tag[color]" value={colorValue} />
              </div>
            </div>
          </div>
          <div className="pt-5 md:pt-10 md:ml-[220px]">
            <div className="mt-1 border-b border-gray-200"></div>
            <div className="pt-5 md:pt-9">
              <div className="pb-2 text-sm">登録済み</div>
              <div className="pb-2 flex gap-3 overflow-x-scroll">
                {tags.map((tag, index) => (
                  <div
                    key={index}
                    className={`px-2 py-1 text-white text-sm whitespace-nowrap rounded-lg ${
                      tag?.color_before_type_cast
                        ? tagColorCodes[tag.color_before_type_cast - 1].bg
                        : tagColorCodes[0].bg
                    }`}
                  >
                    {tag.name}
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
        <div className="bg-white flex justify-center items-center w-full left-0 md:gap-4 border-t border-gray-250 pt-2">
          <AnchorButton
            className="w-full md:w-40 md:h-auto text-primary-font border-white"
            outline
            variant="text"
            href="/tags"
          >
            キャンセル
          </AnchorButton>
          <Button className="w-full md:w-40 md:h-auto">編集を保存</Button>
        </div>
      </form>
    </Base>
  )
}

export default TagEditPage
